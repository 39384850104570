import React, { useRef, useState } from 'react';
import type { Dispatch, FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  SubscriptionResource, 
  SubscriptionResourceForEdit
} from 'src/common/types';
import {
  Box,
  CardContent
} from '@mui/material';
import {
  Formik,
  handleChangeCheckBox,
  handleChangeDateChange,
  SbGroup,
  CaijButtonReset,
  CaijButtonSubmit,
  SubscriptionModel,
  Authorize,
  Mode,
  CaijCard,
  FormikProps,
  ACCOUNT_TITLE,
  btnSubmit,
  handleChangeImageUrl,
  Other,
  SUBCRIPTION_CONTACT_ERROR,
  CaijTextarea,
  updateGroup,
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import SubscriptionForm from 'src/components/subscription/Forms';
import MemberAccessForm from 'src/components/subscription/Forms/MemberAccessForm';
import AccountForm from 'src/components/subscription/Forms/AccountForm';
import Contact from 'src/components/subscription/Forms/Contact';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';
import { Action } from 'src/store/reducers/SubscriptionReducer';

export interface DetailsEditFormProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  onHandleRefreshSubscription: (value: SubscriptionResource) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: SubscriptionResource) => void;
  dispatch: Dispatch<Action>;
}

const B2B = SubscriptionModel.getSbGroupByVal(SbGroup.B2B);

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  subscription,
  onHandleRefreshSubscription,
  onSwitchMode,
  onSubmit,
  dispatch
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const formRef = useRef<FormikProps<SubscriptionResource>>(null);
  const [isExpireOnValid, setIsExpireOnValid] = useState<boolean>(true);

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: SubscriptionResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new SubscriptionModel();
    const submitData = Object.assign({},values) as SubscriptionResourceForEdit;
    if (submitData && submitData.id) {
      let { subscriptionGroup } = submitData;
      submitData.documentCollections = values.documentCollections.map(({id}) => id);
      submitData.databanks = values.databanks.map(({id}) => id);
      submitData.products = values.products;
      if(!model.validateOnApprovalRenewMode(subscriptionGroup.accountRenewMode))
        subscriptionGroup.formUrl = "";
      if(model.validateContact(values, B2B, () => printMessage({status: Other, message: SUBCRIPTION_CONTACT_ERROR}))){
        const response = await model.updateSubscription(submitData);
        if (!model.error) {
          printMessage({
            status: response.status,
            message: response.message
          });
          await onHandleRefreshSubscription(values);
          dispatch(updateGroup(values.subscriptionGroup.id, values.subscriptionGroup));
        }
      }
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={SubscriptionModel.getInitialValues(subscription)}
      validationSchema={validateSchema(model, formRef.current?.values.startDate)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, handleChange, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Root>
                  <SubscriptionForm
                    model={model}
                    subscription={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    onHandleStartDateChange={(d, s) => handleChangeDateChange('startDate', d, s)}
                    onHandleEndDateChange={(d, s) => handleChangeDateChange('endDate', d, s)}
                    onHandleChangeImageUrl={handleChangeImageUrl}
                    setFieldValue={setFieldValue}
                  />
                </Root>
                <Root>
                  <MemberAccessForm
                    subscription={values}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    setFieldValue={setFieldValue}
                  />
                </Root>
                {values.group == B2B && (
                  <Root>
                    <Contact 
                      model={model}
                      subscription={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  </Root>
                )}
                <Root>
                  <CaijCard title={ACCOUNT_TITLE}>
                    <AccountForm
                      model={model}
                      subscription={values}
                      onHandleChange={handleChange}
                      onHandleChangeCheckBox={handleChangeCheckBox}
                      setFieldValue={setFieldValue}
                      setIsExpireOnValid={setIsExpireOnValid}
                    />
                  </CaijCard>
                  <CaijTextarea
                    name={model.Note.Name}
                    id={model.Note.Name}
                    rows="4"
                    margin="none"
                    value={values.note}
                    label={model.Note.Label}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{'aria-label': 'Note', 'data-testid': 'note' }}
                    onHandleChange={handleChange}
                    sx={{mt: 2}}
                  />
                </Root>
              </Box>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={Mode.edit}
                onIsAuth={(l) => setIsAuth(l)}
              >
                <CaijButtonSubmit disabled={!isAuth || isSubmitting || !isExpireOnValid} sx={btnSubmit} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  onHandleRefreshSubscription: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func 
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
