import { UxpertiseModel } from 'src/common';
import type { UxpertiseDto } from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchUxpertises = (
  uxpertises: UxpertiseDto[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean
): {
  type: 'FETCH_UXPERTISES';
  payload: { uxpertises: UxpertiseDto[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean, model?: UxpertiseModel; };
} => ({
  type: actionTypes.FETCH_UXPERTISES,
  payload: {
    uxpertises,
    isLoading,
    isEmptyList,
    isAuthorize
  },
});
