import React, { FC, useEffect } from 'react';
import {
  Box,
  Card,
  Typography,
  Button,
  SvgIcon,
} from '@mui/material';
import Label from 'src/components/Label';
import { ArrowLeftCircle as LeftIcon } from 'react-feather';
import { Print, Transaction } from 'src/common/types';
import { formatDate, formatDateHours } from 'src/common';
import { bigAndBold, bold, box2, row } from 'src/styles/customer';
import dayjs from 'dayjs';

interface TransactionDetailsProps {
  transaction?: Transaction;
  print?: Print;
  invoice?: {};
  switchMode: () => void;
}

const icon = {
  marginRight: '-0.5em',
  height: '1.5em',
  width: '1.5em',
};

const TransactionDetails: FC<TransactionDetailsProps> = ({ transaction, print, invoice, switchMode }): JSX.Element => {
  useEffect(() => {});

  //   const costRender = () => {
  //     if (transaction.amount) return;
  //   };

  const valuesRender = () => {
    if (transaction) {
    }
  };
  const colorRender = (status: string) => {
    if (status === 'Imprimé') {
      return 'success';
    }
    if (status === '') {
      return 'warning';
    }
    if (status === 'Cancelé') {
      return 'error';
    }
    return 'error';
  };

  const renderContent = (): JSX.Element => {
    const { created } = transaction;
    if (transaction)
      return (
        <Card>
          <Box sx={box2}>
            <Box sx={row}>
              <Button
                onClick={() => switchMode()}
                startIcon={
                  <SvgIcon sx={icon} fontSize='inherit'>
                    <LeftIcon />
                  </SvgIcon>
                }
              >
                {' '}
                <Typography sx={bigAndBold}>
                  {transaction.label} #{transaction.id.slice(0, 12)}
                </Typography>
              </Button>
            </Box>
            <Box  sx={row}>
              <Typography sx={bold}>Date et heure:</Typography>
              <Typography>
                {formatDate(created)} à {formatDateHours(dayjs(created).toDate())}
              </Typography>
            </Box>
            <Box sx={row}>
              {' '}
              <Typography sx={bold}>Type de transaction:</Typography>
              <Typography>{transaction.operation}</Typography>
            </Box>
            <Box  sx={row}>
              {' '}
              <Typography  sx={bold}>Transaction:</Typography>
              <Typography>{transaction.label}</Typography>
            </Box>
            <Box  sx={row}>
              {' '}
              <Typography  sx={bold}>Montant {transaction.amount > 0 ? 'crédité' : 'débité'}:</Typography>
              <Typography>{transaction.amount.toFixed(2)} $</Typography>
            </Box>
            <Box sx={row}>
              {' '}
              <Typography  sx={bold}>Transaction effectuée par:</Typography>
              <Typography>{transaction.createdBy}</Typography>
            </Box>
          </Box>
        </Card>
      );
    if (print){
      const { created } = print;
      return (
        <Card>
          <Box sx={box2}>
            <Box  sx={row}>
              <Button
                onClick={() => switchMode()}
                startIcon={
                  <SvgIcon sx={icon} fontSize='inherit'>
                    <LeftIcon />
                  </SvgIcon>
                }
              >
                <Typography sx={bigAndBold}>
                  {print.label} #{print.id.slice(0, 12)}
                </Typography>
              </Button>
            </Box>
            <Box sx={row}>
              <Typography sx={bold}>Date et heure:</Typography>
              <Typography>
                {formatDate(created)} à {formatDateHours(dayjs(created).toDate())}
              </Typography>
            </Box>
            <Box  sx={row}>
              {' '}
              <Typography sx={bold}>Type de transaction:</Typography>
              <Typography>{print.label}</Typography>
            </Box>
            <Box sx={row}>
              {' '}
              <Typography sx={bold}>Montant {print.cost > 0 ? 'crédité' : 'débité'}:</Typography>
              <Typography>{print.cost.toFixed(2)} $</Typography>
            </Box>
            <Box sx={row}>
              {' '}
              <Typography sx={bold}>Statut:</Typography>
              <Typography>
                <Label color={colorRender(print.status)}>{print.status ? print.status : 'Aucun statut'}</Label>
              </Typography>
            </Box>
            <Box sx={row}>
              {' '}
              <Typography sx={bold}>Date d'impression:</Typography>
              <Typography>{`${print.created}`}</Typography>
            </Box>
          </Box>
        </Card>
      );
    }
    return <Box>loading...</Box>;
  };
  return renderContent();
};

// TransactionDetails.propTypes = {
//   transaction: PropTypes.object,
// };
// TransactionDetails.defaultProps = {
//   transaction: {
//     created: '',
//     label: '',
//     id: '',
//     status: '',
//     amount: 0,
//     cost: 0,
//     refunded: false,
//   },
// };

export default TransactionDetails;


