import { Avatar, Box, Link } from "@mui/material";
import { FC, useState } from "react";
import { CustomerResource } from "src/common/types";
import getInitials from "src/utils/getInitials";
import { CustomerModel } from "src/common";
import printMessage from "src/views/errors/MessageError";
import CaijDialogs from "src/components/dialog";
import { BtnOk } from "src/components/dialog/CaijDialog";

interface CaijAvatarProps {
  model?: CustomerModel;
  customer: CustomerResource;
  logo: string;
  sx: any;
  allowedDelete?: boolean;
  onHandleRefreshCustomer?: () => Promise<void>;
}

const borderColor = 'background.paper';
const color = 'background.dark';
const paddingLink = {
  paddingBottom:{
    md:"10px",
    lg:"10px"
  }
};

const CaijAvatar: FC<CaijAvatarProps> = ({
  model,
  customer, 
  logo, 
  allowedDelete, 
  onHandleRefreshCustomer, 
  sx
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const deletePicture = async () => {
    const response = await model.deleteCustomerPicture(customer.id);
    const { error } = model;
    if (!error) {
      const { status, message } = response;
      printMessage({status, message});
      await onHandleRefreshCustomer();
    }
  }

  const renderContent = () => {
    if(allowedDelete){
      model.Dialog.BtnText = BtnOk.DELETED
      model.Dialog.Header = 'Supprimer la photo';
      model.Dialog.Name = 'la photo';
      return (
        <Box sx={{display: {xs: 'block',sm: 'block',md:"flex",lg:"flex"}, alignItems: 'center'}} mb="17px">
          <Avatar sx={{...sx, borderColor, color, marginBottom:{xs:'10px',sm: '10px'}, marginRight:'15px'}} src={logo} />
          <Box sx={{...paddingLink}}>
            <Link href='#' underline="none" onClick={() => setOpenDialog(true)}>
              Supprimer la photo du profil
            </Link>
          </Box>
          <CaijDialogs  
            dialog={model.Dialog}
            isOpen={openDialog}
            onSubmit={deletePicture}
            close={() => {
              setOpenDialog(false);
            }}
          />
        </Box>
      )
    }
    return (
      <Avatar sx={{...sx, borderColor, color}} src={logo} />
    );
  }

  if(customer.logoUrl){
    return renderContent();
  }
  return (
    <Avatar sx={{...sx, borderColor, color}} src='/static/images/avatars/genericAvatar.png'>
      {getInitials(customer.fullname)}
    </Avatar>
  );
}

export default CaijAvatar;