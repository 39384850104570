import { Theme } from "@mui/material";

export const tableCell = {
    width: 1/5,
    fontWeight: (theme: Theme) => theme.typography.fontWeightMedium
};

export const tableCell2 = {
    width: 1/4,
    fontWeight: (theme: Theme) => theme.typography.fontWeightMedium
};



