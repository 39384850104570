import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type { DocumentTypeResource } from 'src/common/types';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  useParams,
  useLocation,
  fetchDetailsDocumentType,
  _documentType,
  DocumentTypeModel,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';
import { DocumentTypeProvider } from 'src/contexts/DocumentTypeContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

interface TabsDocumentType {
  value: string, label: string
};

const initialTabs: TabsDocumentType[] = [
  { value: 'details', label: 'Détails' }
];

const DocumentTypeDetailsView: FC = () => {
  const model = DocumentTypeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_documentType, { documentType: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const [tabs, setTabs] = useState(initialTabs);
  const { code } = useParams();

  const getDocumentTypeById = useCallback(async () : Promise<void> => {
    const model = new DocumentTypeModel();
    if(access.canRead()){
      model.PathName = location.pathname;
      const documentType = await model.getDocumentTypeById(code);
      if (!model.error) {
        dispatch(fetchDetailsDocumentType(documentType));
      }
    }
  }, [code, location]);

  useEffect(() => {
    (async () => {
      await getDocumentTypeById();
    })();
  }, []);

  const { documentType } = state;

  if (Object.keys(documentType).length === 0) {
    return <LoadingScreen />
  }

  model.Title = `${documentType.nameFr} - ${documentType.code}`;

  const setEditModeDocumentType = (isEdit: boolean, value?: string) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeDocumentType(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
    setEditModeDocumentType(false, value);
  };

  const handleRefreshDocumentType = async (documentType: DocumentTypeResource, value?: string): Promise<void> => {
    dispatch(fetchDetailsDocumentType(documentType));
    setEditModeDocumentType(false, value);
  };

  const editDocumentType = () => {
    setEditModeDocumentType(true);
  };

  const renderDocumentType = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <DocumentTypeProvider>
              <DetailsEditForm
                model={model}
                documentType={documentType}
                onHandleRefreshDocumentType={handleRefreshDocumentType}
                onSwitchMode={(l) => switchMode(l)}
              />
            </DocumentTypeProvider>
          );
        }
        return (
          <Details model={model} documentType={documentType} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={code} 
          model={model} 
          headerTitle={documentType.nameFr} 
          actions={['editDetail','delete']}
          editMode={editMode.details}
          titleBadge={{label: documentType.code}}
          onEditDetail={() => editDocumentType()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderDocumentType() }
        </Box>
      </Container>
    </Page>
  );
};

export default DocumentTypeDetailsView;
