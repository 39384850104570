import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { EmailTemplateDto } from 'src/common/types';
import {
  Box,
  Button,
  CardContent,
  Grid,
  SvgIcon
} from '@mui/material';
import {
  Formik,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  Mode,
  EmailTemplateModel,
  MSG_MESSAGE_TEMPLATE_INVALID,
  Other,
  btnSubmit
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import EmailTemplateForm from 'src/components/customer/emailTemplate/Forms';
import useAuth from 'src/hooks/useAuth';
import { Send as SendIcon } from 'react-feather';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: EmailTemplateModel;
  emailTemplate: EmailTemplateDto;
  onHandleRefreshEmailTemplate: (value: EmailTemplateDto) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: EmailTemplateDto) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  emailTemplate,
  onHandleRefreshEmailTemplate,
  onSwitchMode,
  onSubmit
}) => {
  const ref = useRef(null);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isSending, setIsSending] = useState<boolean>(false);
  let { user } = useAuth();
  
  const initSending = () => {
    setIsSending(false);
    ref.current = null;
  }

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values:EmailTemplateDto) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new EmailTemplateModel();
    if(ref.current){//Send email test
      setIsSending(true);
      if(!model.verifyMessage(values.contentFr) && !model.verifyMessage(values.contentEn)){
        printMessage({
          status: 404,
          message: MSG_MESSAGE_TEMPLATE_INVALID
        });
        initSending();
        return;
      }
      const response = await model.testEmail(user.email, values);
      const { error } = model;
      if(!error){
        if(response.data){
          printMessage({
            status: response.status,
            message: response.message
          });
        }else{
          printMessage({
            status: Other,
            message: 'Courriel est envoyé sans succès'
          });
        }
      }
      initSending();
    }else if (values && values.id) {
      const response = await model.updateEmailTemplate(values);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        const emailTemplate = await model.getEmailTemplateById(values.id.toString());
        await onHandleRefreshEmailTemplate(emailTemplate);
      }
    }
  };

  return (
    <Formik
      initialValues={{...emailTemplate}}
      validationSchema={validateSchema(model, emailTemplate.id)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <EmailTemplateForm
                  model={model}
                  emailTemplate={values}
                  errors={errors}
                  touched={touched}
                  onHandleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Box mt={2}>
                <Authorize 
                  resourceCode={model.ResourceCode} 
                  mode={emailTemplate.id ? Mode.edit : Mode.add}
                  onIsAuth={l => setIsAuth(l)}
                >
                  <Button
                    type="button" 
                    variant="outlined" 
                    color="primary"
                    disabled={isSending}
                    startIcon={
                      <SvgIcon>
                        <SendIcon />
                      </SvgIcon>
                    }
                    onClick={(e) => {
                      ref.current = true;
                      handleSubmit();
                    }}
                  >
                    Test courriel
                  </Button>
                </Authorize>
              </Box>
              <Box mt={2}>
                <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  emailTemplate: PropTypes.object.isRequired,
  onHandleRefreshEmailTemplate: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
