import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  AccessModel,
  setEditHash,
  setHash,
  useLocation,
  useNavigate,
  useParams,
  root,
  DocCollectionsModel,
  _docCollections,
  fetchCollection
} from 'src/common';
import Page from 'src/components/Page';
import Details from './Details';
import DetailsEditForm from './Forms/DetailsEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

interface TabsDocCollection {
  value: string, label: string
}

const tabs: TabsDocCollection[] = [
  { value: 'details', label: 'Détails' }
];

const DocCollectionsDetailsView: FC = () => {
  const model = DocCollectionsModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_docCollections, { collection: {}, isLoading: true });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({details: false});
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  
  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsDocCollection) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }

  const getCollectionDocument = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)){
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const collection = await model.getCollectionDocument(id);
      if (!model.error){
        dispatch(fetchCollection(collection));
      }
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      handleBrowserRefresh();
      await getCollectionDocument();
    })();
  }, []);

  useEffect(() => {
    if(access.canRead()){
      const { hash, pathname } = location;
      model.PathName = pathname + hash;
    }
  },[location]);

  const { collection } = state;

  if (Object.keys(collection).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${collection.title} - ${collection.collectionCode}`;

  const setEditModeDocCollection = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeDocCollection(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    event.preventDefault();
    setHash(value, navigate);
    setCurrentTab(value);
    setEditModeDocCollection(false);
  };

  const handleRefreshDocCollection = async (value: string) : Promise<void> => {
    setHash(value, navigate);
    await getCollectionDocument();
    setEditModeDocCollection(false);
  };

  const editDocCollection = () => {
    setEditHash('edit', navigate);
    setEditModeDocCollection(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <DetailsEditForm
              model={model}
              collection={collection}
              reloadCollection={() => handleRefreshDocCollection(tabs[0].value)}
              onSwitchMode={(l) => switchMode(l, tabs[0].value)}
            />
          );
        }
        return (
          <Details model={model} collection={collection} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={collection.title} 
          titleBadge={{label: collection.collectionCode}} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editDocCollection()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default DocCollectionsDetailsView;
