import React, { useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import * as Yup from 'yup';
import type { 
  CoveoSourceDto, 
  DocumentCollectionResource, 
  Error 
} from 'src/common/types';
import { 
  Autocomplete,
  Button,
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  SvgIcon, 
  TextField
} from '@mui/material';
import { 
  Authorize,
  CaijButtonSubmit, 
  CaijInput,
  Mode,
  dialog, 
  dialogTitle, 
  useFormik,
  SearchEngineModel,
  jurisdictionConfig
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import { XSquare as CancelIcon } from 'react-feather';
import { BtnAction } from 'src/types/content/coveo';

export interface CoveoSourceEditFormProps {
  model: SearchEngineModel;
  action: BtnAction;
  coveoSource: CoveoSourceDto;
  collections: DocumentCollectionResource[];
  onHandleRefresh?: () => Promise<void>;
  close: () => void;
}

const CoveoSourceEditForm: FC<CoveoSourceEditFormProps> = ({
  model,
  action,
  coveoSource,
  collections,
  onHandleRefresh,
  close
}) => {
  const { CoveoIdentifier, Name, Juridiction } = model;
  const [isAuth, setIsAuth] = useState<boolean>();

  const searchEngineEnginPostSchema = Yup.object().shape({
    coveoIdentifier: CoveoIdentifier.required(true),
    juridiction: Juridiction.nullable(true)
  });

  const searchEngineEnginPutSchema = Yup.object().shape({
    name: Name.required(true),
    juridiction: Juridiction.nullable(true)
  });

  const getValidationSchema = () => {
     switch(action){
        case 'Add':
          return searchEngineEnginPostSchema;
        case 'Edit':
          return searchEngineEnginPutSchema;
     }
  }

  const { errors, setFieldValue, handleSubmit, handleBlur, handleChange, values, touched, isSubmitting } = useFormik({
    initialValues: {...coveoSource},
    validationSchema: getValidationSchema(),
    validateOnChange: true,
    onSubmit: async values => {
      const model = new SearchEngineModel();
      const submitData = values as CoveoSourceDto;
      let response: Error;
      if(action === 'Add'){
        response = await model.post(submitData);
      }else{
        response = await model.put(submitData.id, submitData);
      }
      if(!model.error){
        printMessage({...response});
        await onHandleRefresh();
        close();
      }
    },
  });
 
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle sx={dialogTitle}>{action === 'Edit' ? 'Éditer une source coveo' : 'Créer une source coveo'}</DialogTitle>
      <DialogContent sx={{...dialog, paddingBottom: '5px', width: 'auto'}} dividers >
        <CaijInput
          required
          name={CoveoIdentifier.Name}
          id={CoveoIdentifier.Name}
          value={values.coveoIdentifier}
          label={CoveoIdentifier.Label}
          sx={{marginBottom: '10px'}}
          disabled={action !== 'Add'}
          error={Boolean(touched.coveoIdentifier && errors.coveoIdentifier)}
          helperText={touched.coveoIdentifier && errors.coveoIdentifier}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: CoveoIdentifier.MaxLength, 'data-testid': CoveoIdentifier.Name}}
          onHandleChange={handleChange}
          onHandleBlur={handleBlur}
        />
        <Autocomplete
          id="Collections"
          options={collections}
          sx={{marginBottom: '15px'}}
          value={collections?.find(({code}) => code === values.collection)}
          getOptionLabel={({nameFr}) => nameFr}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              variant="outlined"
              label="Classement du contenu"
              placeholder="Rechercher..."
              InputLabelProps={{shrink: true, required: true}}
            />
          )}
          onChange={(event, value, reason) => {
            setFieldValue('name', '');
            setFieldValue('collection', '');
            if(value){
              const collection = value as DocumentCollectionResource;
              setFieldValue('name', collection.nameFr);
              setFieldValue('collection', collection.code);
            }
          }}
        />
        <CaijInput
          label={Juridiction.Label}
          name={Juridiction.Name}
          value={values.juridiction}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setFieldValue(Juridiction.Name,e.target.value)}
          select
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          helperText={touched.juridiction && errors.juridiction}
          error={Boolean(touched.juridiction && errors.juridiction)}
          sx={{mb: 2}}
        >
          <option value="">Sélectionner une {Juridiction.Label.toLowerCase()}</option>
          {
            jurisdictionConfig && Object.keys(jurisdictionConfig).map((key) => (
              <option value={key} key={key}>
                {jurisdictionConfig[key]}
              </option>
            ))
          }
      </CaijInput>
      </DialogContent>
      <DialogActions sx={dialog}>
        <Authorize
          resourceCode={model.ResourceCode}
          mode={action === 'Add' ? Mode.add : Mode.edit}
          onIsAuth={l => setIsAuth(l)}
        >
          <CaijButtonSubmit disabled={isSubmitting || !isAuth} value="Sauvegarde" />
        </Authorize>
        <Button  
          variant="outlined"
          type="reset"
          onClick={() => close()}
          disabled={isSubmitting}
          startIcon={(
            <SvgIcon fontSize="small">
              <CancelIcon />
            </SvgIcon>
        )}>Annuler</Button>
      </DialogActions>
    </form>
  );
};

export default CoveoSourceEditForm;
