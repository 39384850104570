import { DocumentOptionResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';
import { Paged } from 'src/types/pagination';

export const fetchSpecialFiles = (specialFiles: DocumentOptionResource[], paged: Paged, isLoading?: boolean, isEmptyList?: boolean): {
    type: 'FETCH_SPECIAL_FILES',
    payload: {
        specialFiles: DocumentOptionResource[],
        paged,
        isLoading?: boolean,
        isEmptyList: boolean
    }
} => ({
    type: actionTypes.FETCH_SPECIAL_FILES,
    payload: {
        specialFiles,
        paged,
        isLoading,
        isEmptyList
    }
});

export const deleteSpecialFile = (id: string) : {
    type: 'DELETE_SPECIAL_FILE';
    payload: {
        id: string;
    };
} => ({
    type: actionTypes.DELETE_SPECIAL_FILE,
    payload: { id },
})