import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  fetchProduct,
  _product,
  ProductModel,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import ProductEditForm from './ProductEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const ProductEditView: FC = () => {
  const model = ProductModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_product, { isLoading: false, product: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () => {
      if(access.canEdit(id)){
        const model = new ProductModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const product = await model.getProductById(id);
          if (!model.error) {
            dispatch(fetchProduct(product, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchProduct({
            subscriptions: []
          },true));
        }
      }
    };
    initialise();
  }, [location, id]);

  const { product, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = product.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <ProductEditForm model={model} product={product} />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductEditView;
