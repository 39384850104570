import type { MessagePlaceholderResource } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchMessagePlaceholdersAction = {
  type: 'FETCH_MESSAGE_PLACEHOLDERS';
  payload: {
    placeholders: MessagePlaceholderResource[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
};

type FetchMessagePlaceholderAction = {
  type: 'FETCH_MESSAGE_PLACEHOLDER';
  payload: {
    placeholder: MessagePlaceholderResource;
  };
};

type FetchDetailsMessagePlaceholderAction = {
  type: 'FETCH_DETAILS_MESSAGE_PLACEHOLDER';
  payload: {
    placeholder: MessagePlaceholderResource;
  };
};

type Action = FetchMessagePlaceholderAction | FetchMessagePlaceholdersAction | FetchDetailsMessagePlaceholderAction;

interface MessagePlaceholdersReturnState {
  placeholders?: MessagePlaceholderResource[];
  placeholder?: MessagePlaceholderResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
}

interface MessagePlaceholdersState {
  placeholders: MessagePlaceholderResource[];
  placeholder: MessagePlaceholderResource;
  isEmptyList: boolean;
  isLoading: boolean;
}

const reducer = (state: MessagePlaceholdersState, action: Action): MessagePlaceholdersReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_MESSAGE_PLACEHOLDERS: {
      const { placeholders, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        placeholders,
        isEmptyList,
        isLoading
      };
    }
    case actionTypes.FETCH_MESSAGE_PLACEHOLDER: {
      const { placeholder } = action.payload;
      return {
        ...state,
        placeholder,
      };
    }
    case actionTypes.FETCH_DETAILS_MESSAGE_PLACEHOLDER: {
      const { placeholder } = action.payload;
      return {
        ...state,
        placeholder,
      };
    }
    default: {
      return state;
    }
  }
};
export { reducer };
export type { Action };
