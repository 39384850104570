import React, { useCallback, useEffect, useReducer, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { 
  LibraryServiceResource,
  SubscriptionResource,
  SubscriptionProductResource,
  ProductResource,
  ProductItemResource,
  LibraryResource,
  LibraryServiceLibraryOptionsDto,
  LibraryServiceLibraryDto
} from 'src/common/types';
import { 
  ListType, 
  ListInfos,
  Result,
  TypeList,
  _product,
  ProductModel,
  fetchProducts,
  AccessPermissions,
  Unauthorized,
  Forbidden,
  MSG_NOT_EXLCUS,
  MSG_NOT_INCLUS,
  MSG_FORBIDEN_ERROR,
  Size,
  LibraryServiceModel,
  SubscriptionModel,
  PathModel,
  LibraryModel
} from 'src/common';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { IListInfo } from 'src/types/listInfo';

export type TypeListArray = LibraryServiceLibraryOptionsDto[] | SubscriptionProductResource[] | ProductItemResource[];
export type TypeDataArray = LibraryServiceResource[] | SubscriptionResource[] | ProductResource[] | LibraryResource[]

interface ListInfoProps {
  listType : ListType;
  data: TypeDataArray;
  lists: TypeListArray;
  isAuthorize: boolean;
}

const listItemStyle = {
  '&:hover': {
    cursor: 'hand',
  }
};

const initialProduct = {
  products: [],
  isEmptyList: true,
  isLoading: true,
  isAuthorize: false,
};

const ListInfo: FC<ListInfoProps> = ({
  listType,
  data,
  lists,
  isAuthorize
}) => {
  const isMountedRef = useIsMountedRef();
  const [availableList, setAvailableList] = useState<IListInfo[]>([]);
  const [inclusList, setInclusList] = useState<IListInfo[]>([]);
  const [state, dispatch] = useReducer(_product, initialProduct);
  const list = ListInfos();

  const getProducts = useCallback(async () : Promise<void> => {
    const model = new ProductModel();
    model.skipHandleError = true;
    let products = await model.getProducts();
    const { error } = model;
    if (error) {
      if (error.status === Forbidden || error.status === Unauthorized) {
        dispatch(fetchProducts(products, false, false, false));
      }else{
        dispatch(fetchProducts(products, false, true, true));
      }
    } else {
      products = products.filter(({access}) => (access === AccessPermissions.Authentication || access === AccessPermissions.Public)); 
      dispatch(fetchProducts(products, false, !Array.isArray(products) || products.length === 0, true));
    }
  },[]);
  
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(isMountedRef.current){
        let Ids: number[] = [];
        switch(listType){
          case ListType.LibraryService:
            const libraryServices = lists as LibraryServiceLibraryOptionsDto[];
            const lbServices = data as LibraryServiceResource[];
            if(libraryServices){
              Ids = libraryServices.map((libraryService:LibraryServiceLibraryOptionsDto) => libraryService.service.id);
              if(lbServices){
                setAvailableList(lbServices.filter(({id}) => !Ids.includes(id)));
                setInclusList(libraryServices);
              }
            }else if(lbServices){
              setAvailableList(lbServices);
            }
            break;
          case ListType.Subscription:
            const subscriptions = lists as SubscriptionProductResource[];
            const sbs = data as SubscriptionResource[];
            if(subscriptions){
              Ids = subscriptions.map((subscription:SubscriptionProductResource) => subscription.id);
              if(sbs){
                setAvailableList(sbs.filter(({id}) => !Ids.includes(id)));
                setInclusList(subscriptions);
              }
            }else if(sbs){
              setAvailableList(sbs);
            }
            break;
          case ListType.CustomerProduct:
          case ListType.Product:
            const products = lists as ProductItemResource[];
            const prods = data as ProductResource[];
            if(products){
              Ids = products.map((product:ProductItemResource) => product.id);
              if(prods){
                setAvailableList(prods.filter(({id}) => !Ids.includes(id)));
                setInclusList(products);
              }
            }else if(prods){
              setAvailableList(prods);
            }
            await getProducts();
            break;
          case ListType.LibraryServiceLibrary:
            const libraryServiceLibrary = lists as LibraryServiceLibraryDto[];
            const libraries = data as LibraryResource[];
            if(libraryServiceLibrary){
              Ids = libraryServiceLibrary.map(({libraryId}) => libraryId);
              if(libraries){
                setAvailableList(libraries.filter(({id}) => !Ids.includes(id)));
                setInclusList(libraryServiceLibrary);
              }
            }else if(libraries){
              setAvailableList(libraries);
            }
            break;
        }
      }
    };
    (async () => {
      await initialise();
    })();
  }, [isMountedRef, listType, data, lists]);

  const getExclusList = (listType: ListType): JSX.Element | JSX.Element[] => {
    if(availableList){
        const avList: TypeList = { sx: listItemStyle };
        let path: PathModel;
        switch(listType){
          case ListType.LibraryService:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsLibraryService: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsLibraryService.success){
                return rsLibraryService.message;
            }
            path = LibraryServiceModel.getInstance().Path;
            return availableList.map(({ id, nameFr }) => {
              return list.getList({...avList,id,path:path.getDetail(id),name: nameFr});
            });
          case ListType.Subscription:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsSubscription: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsSubscription.success){
                return rsSubscription.message;
            }
            path = SubscriptionModel.getInstance().Path;
            return availableList.map(({id, name}) => list.getList({...avList,id,path:path.getDetail(id),name}));
          case ListType.CustomerProduct://Access or Access group
          case ListType.Product:
            if(state.isLoading) return;
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsProduct: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsProduct.success){
              return listType === ListType.CustomerProduct ? null : rsProduct.message;
            }
            path = ProductModel.getInstance().Path;
            return availableList && availableList.sort((a,b) => a.nameFr.localeCompare(b.nameFr))
                                                 .map(({id, nameFr}) => list.getList({
                ...avList,
                id,
                path: path.getDetail(id),
                name: nameFr
              })
            );
          case ListType.LibraryServiceLibrary:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsLibrary: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsLibrary.success){
              return rsLibrary.message;
            }
            path = LibraryModel.getInstance().Path;
            return availableList.map(({id, nameFr}) => list.getList({
              ...avList,
              id,
              path: path.getDetail(id),
              name: nameFr
            }));
        } 
    }
  };

  const getInclusList = (listType: ListType): JSX.Element | JSX.Element[] => {
    if(inclusList){
        const incList: TypeList = { sx: listItemStyle };
        let path: PathModel;
        switch(listType){
          case ListType.LibraryService:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsLibraryService: Result = list.isListValid(inclusList, MSG_NOT_INCLUS);
            if(!rsLibraryService.success){
                return rsLibraryService.message;
            }
            path = LibraryServiceModel.getInstance().Path;
            return inclusList.map(({parameters, service}) => {
              return list.getRequiredList({...incList,id:service.id,path:path.getDetail(service.id),name:service.nameFr,parameters});
            });
          case ListType.Subscription:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsSubscription: Result = list.isListValid(inclusList, MSG_NOT_INCLUS);
            if(!rsSubscription.success){
              return rsSubscription.message;
            }
            path = SubscriptionModel.getInstance().Path;
            const subscriptions = data as SubscriptionResource[];
            return inclusList && inclusList.map(({id, parameter}) => {
              const {name, active} = subscriptions.find((subscription: SubscriptionResource) => subscription.id === id);
              return list.getRequiredList({
              ...incList, 
              id, 
              path:path.getDetail(id), 
              name, 
              parameters: parameter,
              active
            })
          });
          case ListType.CustomerProduct://Access or Access group
          case ListType.Product:
            if(state.isLoading) return;
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const values: IListInfo[] = [...inclusList,...state.products];
            const rsProduct: Result = list.isListValid(values, MSG_NOT_INCLUS);
            if(!rsProduct.success){
              return listType === ListType.CustomerProduct ? null : rsProduct.message;
            }
            path = ProductModel.getInstance().Path;
            return values && values.sort((a,b) => a.nameFr.localeCompare(b.nameFr))
                                   .map(({id, nameFr, access, parameter, active}) => {
              return listType === ListType.Product ? list.getRequiredList({
                ...incList, 
                id, 
                path: path.getDetail(id), 
                accessType: access,
                allowedAccessType: true, 
                name: nameFr, 
                parameters: parameter, 
                active 
              }) : 
              list.getRequiredList({
                ...incList, 
                id, 
                path: path.getDetail(id), 
                name: nameFr, 
                parameters: parameter, 
                active 
              })
            });
          case ListType.LibraryServiceLibrary:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsLibrary: Result = list.isListValid(inclusList, MSG_NOT_INCLUS);
            if(!rsLibrary.success){
              return rsLibrary.message;
            }
            path = LibraryModel.getInstance().Path;
            const library = data as LibraryResource[];
            return inclusList.map(({libraryId, parameters}) => {
              const {id, nameFr, enabled} = library.find(({id}) => id === libraryId);
              return list.getRequiredList({
                ...incList, 
                id, 
                path:path.getDetail(id), 
                name: nameFr, 
                parameters,
                active: enabled
            })});
        }
    }
  }
   
  const renderContent = (listType: ListType) => {
    let size: Size ={md: 12,xs: 12,lg: 12};
    const listExclus = getExclusList(listType);
    const listInclus = getInclusList(listType);
    if(listType === ListType.CustomerProduct){
      let listInc: IListInfo[] = [...inclusList];
      if(state.products && state.products.length > 0){
        listInc = [...inclusList,...state.products];
      }
      if(availableList.length === 0 && listInc.length > 0){
        return list.renderInclusList(listInclus,size);
      }else if(availableList.length > 0 && listInc.length == 0) {
        return list.renderExclusList(listExclus,size);
      }else if(availableList.length > 0 && listInc.length > 0){
        return list.renderBothList(listExclus,listInclus);
      }
    }else{
      return list.renderBothList(listExclus,listInclus);
    }
  }; 

  return (
    <Grid container spacing={3}>
      { renderContent(listType) }
    </Grid>
  );
};

ListInfo.propTypes = {
  listType: PropTypes.oneOf([
    ListType.LibraryService, 
    ListType.Product,
    ListType.CustomerProduct,
    ListType.Subscription,
    ListType.LibraryServiceLibrary
  ]),
  data: PropTypes.array,
  lists: PropTypes.array,
  isAuthorize: PropTypes.bool.isRequired
};

export default ListInfo;
