import React from 'react';
import { Box, Typography } from '@mui/material';
import { Authorize, Mode, SearchEngineModel } from 'src/common';
import CoveoSource from './CoveoSource';
import CoveoLogsHome from './CoveoLogs';

const Title = (props:{ value: string}) => <Box display="flex" justifyContent="center" mt={2}>
  <Typography variant="body1">{props.value}</Typography>
</Box>

const SearchEngineSource = () => {
  const model = SearchEngineModel.getInstance();
  return (
    <>
      <Authorize mode={Mode.read} resourceCode={model.ResourceCode}>
        <CoveoSource 
          title={() => <Title value='Coveo' />}
          headCell={model.CoveoSourceHomeHeadCells}
        />
      </Authorize>
      <Authorize mode={Mode.read} resourceCode={model.SearchIndexResourceCode}>
        <CoveoLogsHome
          maxCount={20}
          title={() => <Title value='Logs' />}
          headCell={model.CoveoLogsHomeHeadCells} 
        />
      </Authorize>
    </>
  )  
}

export default SearchEngineSource;
