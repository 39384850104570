import { Box, Container, Divider, Grid, Tab, Table, TableHead, TableBody, TableCell, TableRow, Tabs, SvgIcon, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
    AccessModel,
    useLocation,
    useNavigate,
    useParams,
    ReperageModel,
    _reperage,
    fetchReperage,
    loadingReperage,
    btnSubmit,
    root,
    CaijButton,
    Authorize,
    Mode
} from 'src/common';
import type { UserContentItemsResource, ReperageResourceForEdit, UserContentItemsResourceForEdit } from 'src/common/types';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import FileUploader from 'src/components/fileUploader';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingOverlay from 'src/components/loading/LoadingOverlay';
import printMessage from 'src/views/errors/MessageError';
import LoadingButton from '@mui/lab/LoadingButton';
import { CheckSquare } from 'react-feather';
import ClientInfo from './Details/ClientInfo';
import MessageInfo from './Details/MessageInfo';
import ReperageInfo from './Details/ReperageInfo';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';
import ContentItem from './Details/ContentItem';

const container = {
    position: 'relative'
}

const initialState = {
    reperage: {}
};

interface TabsReperage {
    value: string, label: string
}

const tabs: TabsReperage[] = [
    { value: 'details', label: 'Détails' },
];

const ReperageDetailsView: FC = () => {
    const model = ReperageModel.getInstance();
    const access = new AccessModel(model.ResourceCode);
    const navigate = useNavigate();
    const location = useLocation();
    const [state, dispatch] = useReducer(_reperage, initialState);
    const [isAddAuth, setIsAddAuth] = useState<boolean>(false);
    const [showCopyModal, setShowCopyModal] = useState<boolean>(false);
    const { id } = useParams();
    model.Dialog.Header = 'Supprimer le dossier de repérage';
    model.Dialog.BtnText = BtnOk.DELETED;
    model.Dialog.Name = 'le dossier de repérage';

    const getReperageById = useCallback(async (): Promise<void> => {
        if (!id || isNaN(+id)) {
            navigate(model.Path.Home);
        } else if (access.canRead()) {
            dispatch(loadingReperage());
            model.PathName = location.pathname;
            const reperage = await model.getReperageById(id);
            if (!model.error) {
                dispatch(fetchReperage(reperage));
            }
        }
    }, [id, location]);

    const onCopyReperage = async () => {
        dispatch(loadingReperage());
        const result = await model.copyReperage(id);
        if (result) {
            // Seulement envoyer status et message pour éviter de montrer le data dans le snackbar
            printMessage({ status: result.status, message: result.message });
            setShowCopyModal(false);
            navigate(model.Path.Home + `/${result.data}`);
        }
    }

    const onAddContentItem = useCallback(async (file: FormData): Promise<void> => {
        const result = await model.addDocumentFile(id, file);
        if (result) {
            getReperageById();
            printMessage({ status: result.status, message: result.message });
        }
    }, [])

    const onChangeContentItemName = useCallback(async (item: UserContentItemsResource, title: string): Promise<void> => {
        const modifiedItem: UserContentItemsResourceForEdit = { id: item.id, note: item.note, title, userId: state.reperage?.userId }
        const result = await model.editContentItem(item.id.toString(), modifiedItem)
        if (result) {
            getReperageById();
            printMessage({ status: result.status, message: result.message });
        }
    }, [state.reperage]);

    const onDeleteContentItem = useCallback(async (item: UserContentItemsResource): Promise<void> => {
        const result = await model.deleteContentItem(item)
        if (result) {
            getReperageById();
            printMessage({ status: result.status, message: result.message });
        }
    }, []);

    const onChangeReperageStatus = async (status: string, reason?: string) => {
        const modifiedReperage = {
            ...reperage,
            status,
            reason: reason || reperage.reason
        } as ReperageResourceForEdit;
        const result = await model.updateReperage(modifiedReperage);
        if (result) {
            getReperageById();
            printMessage({ status: result.status, message: result.message });
        }
    }

    const onChangeReperageName = async (name: string) => {
        const modifiedReperage = { ...reperage, name } as ReperageResourceForEdit;
        const result = await model.updateReperage(modifiedReperage);
        if (result) {
            getReperageById();
            printMessage({ status: result.status, message: result.message });
        }
    }


    const getActions = () => {
        if(reperage.archived)
            return ['extra','delete'];
        return ['edit','extra','delete'];
    }

    const renderExtraButtons = () => {
        const archived = state.reperage.archived;
        return (
            <CaijButton
                color='secondary'
                variant='contained'
                onHandleClick={() => archived ? setShowCopyModal(true) : navigate(`${model.PathName}/send`)}
                disabled={!access.Edition}
                sx={{margin: 'none', mr:2}}
                startIcon={archived ? <ContentCopyIcon /> : <SendIcon />}
            >
                { archived ? 'Dupliquer' : 'Transmettre' }
            </CaijButton>
        )
    }

    useEffect(() => {
        (async () => {
            await getReperageById();
        })();
    }, [getReperageById]);

    const { reperage, isLoading } = state;

    if (Object.keys(reperage).length === 0) {
        return <LoadingScreen />;
    }

    model.Title = reperage.name;

    return (
        <Page sx={root} title={model.DetailPageTitle}>
            <Container sx={container} maxWidth={false}>
                {!isLoading && <LoadingOverlay />}
                <GenericHeader 
                    id={id} 
                    model={model} 
                    actions={getActions()} 
                    extraButtons={renderExtraButtons()}
                    titleBadge={state.reperage.archived ? { label: "Archivé", color: "warning" } : null}
                    />
                <Box mt={2}>
                    <Tabs
                        scrollButtons="auto"
                        value={tabs[0].value}
                        variant="scrollable"
                        textColor="secondary"
                    >
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        ))}
                    </Tabs>
                    <Divider />
                </Box>
                <Dialog open={showCopyModal} onClose={() => setShowCopyModal(false)}>
                    <DialogTitle>Copie de dossier repérage</DialogTitle>
                    <DialogContent>«{state.reperage.name}» et tout le contenu s'y rattachant seront recopier.</DialogContent>
                    <DialogActions>
                        <LoadingButton
                            type="submit"
                            onClick={onCopyReperage}
                            startIcon={<SvgIcon fontSize='small'><CheckSquare /></SvgIcon>}
                            loading={!isLoading}
                            disabled={!access.Edition}
                            loadingPosition="start"
                            variant="contained"
                            sx={btnSubmit}
                        >Dupliquer</LoadingButton>
                        <Button
                            variant="outlined"
                            type="reset"
                            onClick={() => setShowCopyModal(false)}
                            disabled={!isLoading}
                            startIcon={(
                                <SvgIcon fontSize="small">
                                    <CancelIcon />
                                </SvgIcon>
                            )}
                        >Annuler</Button>
                    </DialogActions>
                </Dialog>
                <Box mt={3}>
                    <Grid container spacing={3} >
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xl={6}
                            xs={12}
                        >
                            <ReperageInfo
                                reperage={reperage}
                                onChangeReperageStatus={onChangeReperageStatus}
                                onChangeReperageName={onChangeReperageName}
                                model={model}
                                isLoading={isLoading}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xl={6}
                            xs={12}
                        >
                            <ClientInfo reperage={state.reperage} />
                            <Box mt={3} mb={3}>
                                <MessageInfo note={reperage.note} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {!reperage.archived &&
                <Authorize
                    resourceCode={model.ResourceCode}
                    mode={Mode.add}
                    onIsAuth={setIsAddAuth}
                >
                    <Box mt={2}>
                        <FileUploader
                            disabled={!isAddAuth}
                            style={{ height: '3em' }}
                            btnText={"Ajout de document"}
                            onHandleChangeImageUrl={null}
                            setFieldValue={null}
                            extensionAccepted={['.gif', '.jpg', '.jpeg', '.png', '.svg', '.pdf', '.doc', '.docx']}
                            onUpload={(file: FormData) => onAddContentItem(file)}
                        />
                    </Box>
                </Authorize>
                }
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Titre</TableCell>
                            <TableCell>Date Créé</TableCell>
                            <TableCell>Date Modifié</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reperage.contentItems.map((item, i) => {
                            return <ContentItem 
                                key={i} 
                                onDelete={onDeleteContentItem} 
                                onChangeContentItemName={onChangeContentItemName} 
                                item={item} 
                                model={model} 
                                archived={reperage.archived} 
                                isLoading={isLoading}
                            />
                        })}
                    </TableBody>
                </Table>
            </Container>
        </Page >
    );
};

export default ReperageDetailsView;
