import { useEffect, useReducer } from "react";
import { 
    fetchLibraryTypes, 
    Forbidden, 
    LibraryTypeModel, 
    Unauthorized, 
    _employee, 
    _libraryType 
} from "src/common";
import { FETCH_LIBRARY_TYPES } from "src/store/actions/ActionTypes";

export function useLibraryTypes() {
    const initialState = {
        libraryTypes: [],
        isLoading: false,
        isEmptyList: true,
        isAuthorize: false
    };
    const [state, dispatch] = useReducer(_libraryType, initialState);
    useEffect(() => {
        (async () => {
            const model = new LibraryTypeModel();
            model.skipHandleError = true;
            const libraryTypes = await model.getLibraryTypes();
            const { error } = model;
            if (error) {
                if (error.status === Forbidden || error.status === Unauthorized) {
                    dispatch(fetchLibraryTypes(libraryTypes, true, false, false));
                }else{
                    dispatch(fetchLibraryTypes(libraryTypes, true, true, true));
                } 
            }else{
                dispatch(fetchLibraryTypes(libraryTypes, true, !Array.isArray(libraryTypes) || libraryTypes.length === 0, true));
            }
        })();
        return () => {
            dispatch({ type: FETCH_LIBRARY_TYPES, payload: initialState });
        };
    },[]); 
    const { libraryTypes, isEmptyList, isLoading, isAuthorize } = state;
    return { libraryTypes, isEmptyList, isLoading, isAuthorize };
}