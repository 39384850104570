import { LibraryModel } from 'src/common';
import type { 
  DatabankResource, 
  LibraryResource, 
  LibraryServiceResource, 
  TabsLibrary 
} from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchLibrariesAction = {
  type: 'FETCH_LIBRARIES';
  payload: {
    libraries: LibraryResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
    model?: LibraryModel;
  };
};

type FetchLibraryAction = {
  type: 'FETCH_LIBRARY';
  payload: {
    library: LibraryResource;
    isLoading?: boolean;
  };
};

type FetchDetailsLibraryAction = {
  type: 'FETCH_DETAILS_LIBRARY';
  payload: {
    library: LibraryResource;
  };
};

type DeleteLibraryAction = {
  type: 'DELETE_LIBRARY';
  payload: {
    id: number;
  };
};

export type LibraryAction = FetchLibrariesAction |
                            FetchLibraryAction |
                            FetchDetailsLibraryAction |
                            DeleteLibraryAction;

interface LibraryReturnState {
  libraries?: LibraryResource[];
  library?: LibraryResource;
  libraryDatabanks?: DatabankResource[];
  libraryLibraryLibraryServices?: LibraryServiceResource[];
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  tabs?: TabsLibrary[];
  model?: LibraryModel;
}

interface LibraryState {
  libraries: LibraryResource[];
  library: LibraryResource;
  libraryDatabanks: DatabankResource[];
  libraryLibraryLibraryServices: LibraryServiceResource[];
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  tabs: TabsLibrary[];
  model:LibraryModel;
}

const reducer = (state: LibraryState, action: LibraryAction): LibraryReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_LIBRARIES: {
      const { libraries, isEmptyList, isLoading, isAuthorize, model } = action.payload;
      return {
        ...state,
        libraries,
        isLoading,
        isEmptyList,
        isAuthorize,
        model
      };
    }
    case actionTypes.FETCH_LIBRARY: {
      const { library, isLoading } = action.payload;
      return {
        ...state,
        library,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_LIBRARY: {
      const { library } = action.payload;
      return {
        ...state,
        library
      };
    }
    case actionTypes.DELETE_LIBRARY: {
      return {
        ...state,
        libraries: [...state.libraries].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
