import { useEffect, useReducer } from 'react';
import { 
	fetchLibrariesList, 
	fetchProfilesList,
	SymphonyModel,
	_symphony } from 'src/common';
import { WfLibraryDto, WfProfileDto } from 'src/common/types';
import { 
	FETCH_LIBRARIES_SYMPHONY, 
	FETCH_PROFILES_SYMPHONY 
} from 'src/store/actions/ActionTypes';

interface Data {
	isEmptyList: boolean;
	isLoading: boolean;
}
    
export interface SymLibrary extends Data {
	libraries: WfLibraryDto[];
}

export interface SymProfile extends Data {
	profiles: WfProfileDto[];
}

const initialOthersState = {
  isEmptyList: true,
	isLoading: false
};

export function useEnabledSymLibraries(): SymLibrary {
	const initialState = {
		libraries: [],
		...initialOthersState
	};
	const [state, dispatch] = useReducer(_symphony, initialState);
	useEffect(() => {
		const initialise = async () => {
			const model = new SymphonyModel();
			const libraries = await model.getActiveLibrariesList();
			const { error } = model;
			if (error) {
				dispatch(fetchLibrariesList(libraries, true, true));
			} else {
				dispatch(fetchLibrariesList(libraries, true, !Array.isArray(libraries) || libraries.length == 0));
			}
		};
		initialise();
		return () => {
			dispatch({ type: FETCH_LIBRARIES_SYMPHONY, payload: initialState });
		};
	}, []);
	const { libraries, isEmptyList, isLoading } = state;
	return { libraries, isEmptyList, isLoading };
}

export function useEnabledSymProfiles(): SymProfile {
	const initialState = {
		profiles: [],
		...initialOthersState
	};
	const [state, dispatch] = useReducer(_symphony, initialState);
	useEffect(() => {
		const initialise = async () => {
			const model = new SymphonyModel();
			const profiles = await model.getActiveProfilesList();
			const { error } = model;
			if (error) {
				dispatch(fetchProfilesList(profiles, true, true));
			} else {
				dispatch(fetchProfilesList(profiles, true, !Array.isArray(profiles) || profiles.length == 0));
			}
		};
		initialise();
		return () => {
			dispatch({ type: FETCH_PROFILES_SYMPHONY, payload: initialState });
		};
	}, []);
	const { profiles, isEmptyList, isLoading } = state;
	return { profiles, isEmptyList, isLoading };
}

export function useSymLibraries() {
	const initialState = {
		libraries: [],
		...initialOthersState
	};
	const [state, dispatch] = useReducer(_symphony, initialState);
	useEffect(() => {
		const initialise = async () => {
			const model = new SymphonyModel();
			const libraries = await model.getLibrariesList();
			const { error } = model;
			if (error) {
				dispatch(fetchLibrariesList(libraries, true, true));
			} else {
				dispatch(fetchLibrariesList(libraries, true, !Array.isArray(libraries) || libraries.length == 0));
			}
		};
		initialise();
		return () => {
			dispatch({ type: FETCH_LIBRARIES_SYMPHONY, payload: initialState });
		};
	}, []);
	const { libraries, isEmptyList, isLoading } = state;
	return { libraries, isEmptyList, isLoading, dispatch };
}

export function useSymProfiles() {
	const initialState = {
		profiles: [],
		...initialOthersState
	};
	const [state, dispatch] = useReducer(_symphony, initialState);
	useEffect(() => {
		const initialise = async () => {
			const model = new SymphonyModel();
			const profiles = await model.getProfilesList();
			const { error } = model;
			if (error) {
				dispatch(fetchProfilesList(profiles, true, true));
			} else {
				dispatch(fetchProfilesList(profiles, true, !Array.isArray(profiles) || profiles.length == 0));
			}
		};
		initialise();
		return () => {
			dispatch({ type: FETCH_PROFILES_SYMPHONY, payload: initialState });
		};
	}, []);
	const { profiles, isEmptyList, isLoading } = state;
	return { profiles, isEmptyList, isLoading };
}

export function useSymphony() {
	const { dispatch } = useSymLibraries();
  return {
    libraryState : useSymLibraries(),
		dispatch,
    profileState: useSymProfiles()
  }
};

