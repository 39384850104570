import type { CoveoSourceDto, DocumentCollectionResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchDocCollections = (docCollections: DocumentCollectionResource[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
  type: 'FETCH_DOCUMENT_COLLECTIONS',
  payload: {
    docCollections: DocumentCollectionResource[],
    isEmptyList?: boolean,
    isLoading?: boolean,
    isAuthorize?: boolean;
  }
} => ({
  type: actionTypes.FETCH_DOCUMENT_COLLECTIONS,
  payload: {
    docCollections,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchDocCollection = (docCollection: DocumentCollectionResource, coveoSources: CoveoSourceDto[], isLoading?: boolean): {
  type: 'FETCH_DOCUMENT_COLLECTION',
  payload: {
    docCollection: DocumentCollectionResource,
    coveoSources: CoveoSourceDto[];
    isLoading?: boolean,
  }
} => ({
  type: actionTypes.FETCH_DOCUMENT_COLLECTION,
  payload: {
    docCollection,
    coveoSources,
    isLoading
  }
});

export const deleteDocCollection = (id: number): {
  type: 'DELETE_DOCUMENT_COLLECTION',
  payload: {
    id: number,
  }
} => ({
  type: actionTypes.DELETE_DOCUMENT_COLLECTION,
  payload: {
    id
  }
});

