import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { CaijTooltip } from 'src/common';

interface EnhancedTableHeadProps {
  order?: any,
  orderBy?: string,
  onRequestSort?: (event: any, property: any) => void,
  headCells: any[];
  backgroundColor?: any;
  notAllowedSort?: boolean
}

const EnhancedTableHead: FC<EnhancedTableHeadProps> = ({
  order,
  orderBy,
  headCells,
  onRequestSort,
  backgroundColor,
  notAllowedSort
}) => {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow sx={{backgroundColor}}>
        {headCells.map((headCell, index: number) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ width: headCell.width ? headCell.width : '' }}
          >
            { headCell.id === '' || notAllowedSort ? headCell.label
              : (
                <CaijTooltip title={`Trier ${(headCell.label as string)?.toLowerCase()}`}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </CaijTooltip>
              )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  headCells: PropTypes.array.isRequired
};

export default EnhancedTableHead;
