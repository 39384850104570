import { FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  DocumentCollectionResource, 
  HandleBlur, 
  HandleChange,
  SetFieldValue 
} from 'src/common/types';
import {
  Grid,
  Box,
  Checkbox,
  MenuItem,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';
import {
  CaijInput,
  CaijTextarea,
  FormikErrors,
  FormikTouched,
  DocCollectionModel,
  CaijCheckbox,
  validateCode
} from 'src/common';
import { Root } from 'src/components/styled';
import CaijMultipleSelectCheckmarks from 'src/components/inputs/CaijMultipleSelectCheckmarks';
import useDocumentType from 'src/hooks/useDocumentType';

interface DocCollectionFormProps {
  model: DocCollectionModel;
  collection: DocumentCollectionResource;
  errors: FormikErrors<DocumentCollectionResource>;
  touched: FormikTouched<DocumentCollectionResource>;
  handleBlur: HandleBlur;
  onHandleChange: HandleChange;
  onHandleChangeCheckBox: HandleChange;
  setFieldValue: SetFieldValue;
}

const DocCollectionForm: FC<DocCollectionFormProps> = ({
  model,
  collection,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  setFieldValue
}) => {
  const { isLoading, isEmptyList, isAuthorize, documentTypes: lstDocumentTypes } = useDocumentType();
  const { id, nameFr, nameEn, code, descriptionFr, descriptionEn, note, active, documentTypes } = collection;
  const { Code, NameFr, NameEn, DescriptionFr, DescriptionEn, Note, Active } = model;
  const isDocumentTypesValid = isLoading && !isEmptyList && isAuthorize;
  return (
    <>
      <Grid item md={6} xs={12} mt={2}>
        <CaijInput
          required
          name={NameFr.Name}
          id={NameFr.Name}
          margin='none'
          value={nameFr}
          helperText={touched.nameFr && errors.nameFr}
          error={Boolean(touched.nameFr && errors.nameFr)}
          label={NameFr.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12} mt={2}>
        <CaijInput
          required
          name={NameEn.Name}
          id={NameEn.Name}
          margin='none'
          value={nameEn}
          label={NameEn.Label}
          helperText={touched.nameEn && errors.nameEn}
          error={Boolean(touched.nameEn && errors.nameEn)}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: NameEn.MaxLength, 'data-testid': NameEn.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={(e) => {
            if(!id) {
              const { value } = e.target;
              if(value.length <= Code.MaxLength){
                collection.code = validateCode(value.toLowerCase());
              }
            }
            onHandleChange(e, setFieldValue);
          }}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <Box>
          <CaijInput
            required
            name={Code.Name}
            id={Code.Name}
            margin="none"
            value={code}
            label={Code.Label}
            disabled={id ? true : false}
            helperText={touched.code && errors.code}
            error={Boolean(touched.code && errors.code)}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{ maxLength: Code.MaxLength, 'data-testid': Code.Name }}
            onHandleBlur={handleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijTextarea
          name={DescriptionFr.Name}
          id={DescriptionFr.Name}
          rows="4"
          margin="none"
          value={descriptionFr}
          label={DescriptionFr.Label}
          helperText={touched.descriptionFr && errors.descriptionFr}
          error={Boolean(touched.descriptionFr && errors.descriptionFr)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: DescriptionFr.MaxLength, 'data-testid': DescriptionFr.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijTextarea
          name={DescriptionEn.Name}
          id={DescriptionEn.Name}
          rows="4"
          margin="none"
          value={descriptionEn}
          label={DescriptionEn.Label}
          InputLabelProps={{ shrink: true }}
          helperText={touched.descriptionEn && errors.descriptionEn}
          error={Boolean(touched.descriptionEn && errors.descriptionEn)}
          inputProps={{ maxLength: DescriptionEn.MaxLength, 'data-testid': DescriptionEn.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12} display='flex' flexDirection='column'>
        <CaijTextarea
          name={Note.Name}
          id={Note.Name}
          rows="4"
          margin="none"
          value={note}
          label={Note.Label}
          InputLabelProps={{ shrink: true }}
          inputProps={{'data-testid': Note.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
        <Root>
          <CaijMultipleSelectCheckmarks
            required
            label={model.DocumentType.Label}
            data={isDocumentTypesValid ? lstDocumentTypes.map(({code, nameFr}) => ({id: code, name: nameFr})) : []}
            onHandleChange={(event: SelectChangeEvent<any>) => setFieldValue(model.DocumentType.Name, typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
            values={documentTypes}
            sx={{margin: '20px 0', width: '100%'}}
          >
            {isDocumentTypesValid && lstDocumentTypes.map(({code, nameFr}) => (
              <MenuItem key={code} value={code}>
                <Checkbox checked={documentTypes.indexOf(code) > -1} />
                <ListItemText primary={nameFr} />
              </MenuItem>
              ))
            }
          </CaijMultipleSelectCheckmarks>
        </Root>
        <CaijCheckbox
          name={Active.Name}
          label={Active.Label}
          checked={active}
          value={active}
          inputProps={{'data-testid': Active.Label}}
          onHandleChangeCheckBox={onHandleChangeCheckBox}
          setFieldValue={setFieldValue}
          />
      </Grid>
    </>
  )
};

DocCollectionForm.propTypes = {
  collection: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default DocCollectionForm;
