import { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  CaijInput,
  DatabankModel,
  FormikErrors,
  FormikTouched
} from 'src/common';
import type { 
  DatabankResource, 
  HandleBlur, 
  HandleChange,
  SetFieldValue 
} from 'src/common/types';
import { Grid } from '@mui/material';

interface DatabankNameProps {
  model: DatabankModel;
  databank: DatabankResource, 
  errors: FormikErrors<DatabankResource>;
  touched: FormikTouched<DatabankResource>;
  setFieldValue: SetFieldValue;
  handleBlur: HandleBlur;
  handleChange: HandleChange;
}

const DatabankName: FC<DatabankNameProps> = ({ 
  model,
  databank,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue 
}) => {
  const { NameFr, NameEn } = model;
  const { nameFr, nameEn } = databank;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={NameFr.Name}
          required
          value={nameFr}
          label={NameFr.Label}
          helperText={touched.nameFr && errors.nameFr}
          error={Boolean(touched.nameFr && errors.nameFr)}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: NameFr.MaxLength,'data-testid': NameFr.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={NameEn.Name}
          required
          value={nameEn}
          label={NameEn.Label}
          helperText={touched.nameEn && errors.nameEn}
          error={Boolean(touched.nameEn && errors.nameEn)}
          inputAttr={{maxLength: NameEn.MaxLength,'data-testid': NameEn.Name}}
          InputLabelProps={{ shrink: true, required: true }}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
}

DatabankName.propTypes = {
  databank: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default DatabankName;