import React, { useState } from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { Outlet } from 'src/common';
import { Box } from '@mui/material';
import { withAppContext, PropsWithContext } from 'src/functions/withAppContext';

const root = {
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
};

const wrapper = {
  display: 'flex',
  flex: '1 1 auto',
  paddingTop: 64,
  overflow: 'hidden'
};

const contentContainer = {
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
};

const content = {
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
};

const DashboardLayout: FC<PropsWithContext> = ({context}) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  return (
    <Box sx={root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Box style={wrapper} sx={{paddingLeft:{lg: context.isDesktopNavOpen ? 32 : 4}}}>
        <div style={contentContainer}>
          <div id='dashBoardContent' style={content}>
            <Outlet />
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default withAppContext(DashboardLayout);
