import type { TribunalTypeResource } from 'src/common/types';
import * as actionTypes from '../../ActionTypes';

export const fetchTribunalTypes = (
  tribunalTypes: TribunalTypeResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
): {
  type: 'FETCH_TRIBUNAL_TYPES';
  payload: {
    tribunalTypes: TribunalTypeResource[];
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
  };
} => ({
  type: actionTypes.FETCH_TRIBUNAL_TYPES,
  payload: {
    tribunalTypes,
    isLoading,
    isEmptyList,
    isAuthorize,
  },
});

export const fetchTribunalType = (tribunalType: TribunalTypeResource,isLoading?: boolean) : {
  type: 'FETCH_TRIBUNAL_TYPE';
  payload: {
    tribunalType: TribunalTypeResource,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_TRIBUNAL_TYPE,
  payload: {
    tribunalType,
    isLoading
  },
});

export const deleteTribunalType = (id: number) : {
  type: 'DELETE_TRIBUNAL_TYPE';
  payload: {
    id: number
  };
} => ({
  type: actionTypes.DELETE_TRIBUNAL_TYPE,
  payload: {
    id
  },
});
