import { FC, ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import type { EmailTemplateDto, HandleChange, SetFieldValue } from 'src/common/types';
import { 
  Grid, 
  Box,  
  ListItemIcon, 
  ListItemText, 
  List, 
  Collapse,
  Card 
} from '@mui/material';
import {
  labelConfig,
  CaijInput,
  FormikErrors,
  FormikTouched,
  CaijCard,
  DraftEditor,
  EmailTemplateModel,
  CaijTextarea
} from 'src/common';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { CheckSquare as CheckIcon } from 'react-feather';
import { useTags } from 'src/functions/hooks/tags';
import ListItemButton from '@mui/material/ListItemButton';

interface EmailTemplateFormProps {
  model: EmailTemplateModel;
  emailTemplate: EmailTemplateDto;
  errors: FormikErrors<EmailTemplateDto>;
  touched: FormikTouched<EmailTemplateDto>;
  onHandleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: HandleChange;
  setFieldValue: SetFieldValue;
}

export function Tags(props:{tags: Array<string>}) {
  const { tags } = props;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      <ListItemButton onClick={handleClick} style={{border: '1px solid rgb(80,80,80)'}}>
        <ListItemText primary="Le/Les variable(s) disponibles" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Card>
            <List component="div" disablePadding style={{margin:'0.5em 0 0.5em 0.5em'}}>
              { tags && tags.map((tag: string, index: number) => (
                  <Box display="flex" alignItems="center" key={index}>
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                    <ListItemText primary={tag} />
                  </Box>
                )) 
              }
            </List>
        </Card>
        </Collapse>
    </List>
  )
};

const EmailTemplateForm: FC<EmailTemplateFormProps> = ({
  model,
  emailTemplate,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const { id, code, name, note, subjectFr, subjectEn, contentFr, contentEn } = emailTemplate;
  const { Code, Name, SubjectFr, SubjectEn, ContentFr, ContentEn, Note } = model;
  const { tags } = useTags();
 
  return (
    <>
        <Grid item md={12} xs={12}>
            <CaijInput
              required
              name={Code.Name}
              id={Code.Name}
              value={code}
              helperText={touched.code && errors.code}
              error={Boolean(touched.code && errors.code)}
              label={Code.Label}
              inputProps={{ readOnly : id > 0 }}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{maxLength: Code.MaxLength, 'data-testid': Code.Name}}
              onHandleBlur={onHandleBlur}
              onHandleChange={(e: ChangeEvent<HTMLInputElement>) => {
                const {value} = e.target;
                if(EmailTemplateModel.verifyWord(value))
                  setFieldValue(Code.Name, value.toUpperCase());
              }}
            />
            <CaijInput
              required
              name={Name.Name}
              id={Name.Name}
              value={name}
              helperText={touched.name && errors.name}
              error={Boolean(touched.name && errors.name)}
              label={Name.Label}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{maxLength: Name.MaxLength, 'data-testid': Name.Name}}
              onHandleBlur={onHandleBlur}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
            />
            <CaijTextarea
              name={Note.Name}
              id={Note.Name}
              rows="4"
              label={Note.Label}
              value={note}
              inputProps={{'data-testid': Note.Name}}
              InputLabelProps={{ shrink: true }}
              onHandleBlur={onHandleBlur}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
            />
        </Grid>
        <Grid item md={6} xs={12} sx={{paddingRight: '15px'}}>
            <Box mb={2}>
                <CaijInput
                  required
                  name={SubjectFr.Name}
                  id={SubjectFr.Name}
                  value={subjectFr}
                  label={SubjectFr.Label}
                  helperText={touched.subjectFr && errors.subjectFr}
                  error={Boolean(touched.subjectFr && errors.subjectFr)}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputAttr={{ maxLength: SubjectFr.MaxLength, 'data-testid': SubjectFr.Name}}
                  onHandleBlur={onHandleBlur}
                  onHandleChange={onHandleChange}
                  setFieldValue={setFieldValue}
                />
            </Box>
            <CaijCard title={labelConfig.messageFr}>
                <Box mb={1}>
                  <Tags tags={tags} />
                </Box>
                <DraftEditor
                    dataTestId={ContentFr.Name}
                    value={contentFr}
                    className='demo-wrapper'
                    onEditorChange={l => setFieldValue(ContentFr.Name, l, true)}
                />
            </CaijCard>
        </Grid>
        <Grid item md={6} xs={12}>
            <Box mb={2}>
                <CaijInput
                    required
                    name={SubjectEn.Name}
                    id={SubjectEn.Name}
                    value={subjectEn}
                    label={SubjectEn.Label}
                    helperText={touched.subjectEn && errors.subjectEn}
                    error={Boolean(touched.subjectEn && errors.subjectEn)}
                    InputLabelProps={{ shrink: true, required: true }}
                    inputAttr={{maxLength: SubjectEn.MaxLength, 'data-testid': SubjectEn.Name}}
                    onHandleBlur={onHandleBlur}
                    onHandleChange={onHandleChange}
                    setFieldValue={setFieldValue}
                />
            </Box>
            <CaijCard title={labelConfig.messageEn}>
                <Box mb={1}>
                  <Tags tags={tags} />
                </Box>
                <DraftEditor
                    dataTestId={ContentEn.Name}
                    value={contentEn}
                    className='demo-wrapper'
                    onEditorChange={l => setFieldValue(ContentEn.Name, l, true)}
                />
            </CaijCard>
        </Grid>
    </>
  );
};

EmailTemplateForm.propTypes = {
  emailTemplate: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default EmailTemplateForm;
