import { ApiKeyDto } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchApiKeysAction = {
    type: 'FETCH_API_KEYS';
    payload: {
      apiKeys: ApiKeyDto[];
      isLoading?: boolean;
      isEmptyList?: boolean;
    }
};

type FetchApiKeyAction = {
  type: 'FETCH_API_KEY';
  payload: {
    apiKey: ApiKeyDto;
    isLoading?: boolean;
  }
};

type DeleteApiKeyAction = {
  type: 'DELETE_API_KEY';
  payload: {
    id: string;
  }
};

type Action = FetchApiKeysAction |
              FetchApiKeyAction |
              DeleteApiKeyAction;

interface ApiKeyReturnState {
  apiKeys?: ApiKeyDto[];
  apiKey?: ApiKeyDto;
  isLoading?: boolean;
  isEmptyList?: boolean;
}

interface ApiKeyState {
  apiKeys: ApiKeyDto[];
  apiKey: ApiKeyDto;
  isLoading: boolean;
  isEmptyList: boolean;
}

const reducer = (state: ApiKeyState, action: Action): ApiKeyReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_API_KEYS: {
      const { apiKeys, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        apiKeys,
        isLoading,
        isEmptyList
      };
    }
    case actionTypes.FETCH_API_KEY: {
      const { apiKey, isLoading } = action.payload;
      return {
        ...state,
        apiKey,
        isLoading
      };
    }
    case actionTypes.DELETE_API_KEY: {
      return {
        ...state,
        apiKeys: [...state.apiKeys].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
