import { Success, NoContent, MSG_NO_CONTENT_ERROR } from 'src/common';
import { GoogleMaps } from 'src/common/types';
import AppModel from './App';
import axios from 'src/utils/axios';

export default class GeolocationModel extends AppModel 
{
  constructor(){
    super('/geolocation');
  }

  async getGeolocation(address: string): Promise<GoogleMaps>{
    let result: GoogleMaps;
    await axios.get<GoogleMaps>(`${this.route}/${address}`).then(async (response) => {
      const { data, status } = response;
      try {
        if (status === Success) {
          result = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
      }
    }, async error => {
      this.error = error;
    });
    return result;
  }
}
