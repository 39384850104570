import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import type { 
  CreateResponse, 
  MessagePlaceholderResource, 
  MessageResource, 
  MessageResourceForEdit,
  Error
} from 'src/common/types';
import {
  Formik,
  Authorize,
  MessageModel,
  Mode,
  FormikProps,
  formatDateTime
} from 'src/common';
import { Play as PublishIcon, Square as DepublishIcon } from 'react-feather';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import CaijDialogs from 'src/components/dialog';

interface MessagePublishFormProps {
  holder: MessagePlaceholderResource;
  message: MessageResource;
  onSubmit?: (values: MessageResource) => void;
}

const publish = {
  border: '1px solid rgba(80, 80, 80 ,0.7)',
}

const publishOrUnpublish = (message: MessageResource) => {
  if (MessageModel.activeCheck(message) === 'En cours') {
    return false;
  }
  if (MessageModel.activeCheck(message) === 'À venir' || MessageModel.activeCheck(message) === 'Archives') {
    return true;
  }
};

async function redirect(model: MessageModel, response: Error | CreateResponse, id: number) : Promise<void>{
  await model.redirect(model.Path.getDetail(id),{
    status: response.status,
    message: response.message,
  });
};

async function submit(values: MessageResource, isPublish: boolean) : Promise<void> {
  const model = new MessageModel();
  let submitData = values as MessageResourceForEdit;
  const messageId = submitData.id;
  if (submitData && messageId) {
    if(isPublish){
      let pastDate = new Date();
      pastDate.setHours(-10, 0, 0, 0);
      submitData.startDate = formatDateTime(pastDate);
    }
    const response = await model.updateMessage(submitData);
    if(!model.error){
      await redirect(model, response, messageId);
    }
  }
};

const MessagePublishForm: FC<MessagePublishFormProps> = ({ holder, message, onSubmit }) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [open, setOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formRef = useRef<FormikProps<MessageResource>>(null);
  const model = new MessageModel();
  model.Dialog.Header = 'Publier le message';
  model.Dialog.BtnText = BtnOk.PUBLIER;
  model.Dialog.Name = 'le message';

  useEffect(() => {
    const initialise = async () => {
      if(isSubmitting) {
       const { values } = formRef.current;
       values.visible = true;
       await submit(values, true);
       setIsSubmitting(false);
      }
    }
    initialise();
  },[isSubmitting, setIsSubmitting]);

  const publishSubmitHandler = async (values: MessageResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const isPublish = publishOrUnpublish(values);
    if(isPublish){
      MessageModel.EndDate = values.endDate;
      if(MessageModel.EndDate > MessageModel.TodayTime){
        values.visible = true;
        submit(values, isPublish);
      }else if(MessageModel.EndDate < MessageModel.TodayTime){
        setOpen(true);
      }
    }else{
      values.visible = false;
      submit(values, isPublish);
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          ...message,
          rank: message.rank || 0,
          placeholderId: holder.id,
          placeholder: message.placeholder || holder,
        }}
        onSubmit={publishSubmitHandler}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
              <Button
                type='submit'
                variant='outlined'
                sx={publish}
                startIcon={<SvgIcon>{publishOrUnpublish(values) ? <PublishIcon /> : <DepublishIcon />} </SvgIcon>}
                disabled={!isAuth || isSubmitting}
              >
                {publishOrUnpublish(values) ? 'Publier' : 'Dépublier'}
              </Button>
            </Authorize>
            <CaijDialogs
              dialog={model.Dialog}
              isOpen={open}
              endDate={values.endDate}
              startDate={values.startDate}
              onSubmitPublisherMessage={(date: string) => {
                if (date) {
                  setFieldValue('endDate', date);
                  setIsSubmitting(true);
                }
                setOpen(false);
              }}
              close={() => setOpen(false)}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

MessagePublishForm.propTypes = {
  holder: PropTypes.any.isRequired,
  message: PropTypes.object,
  onSubmit: PropTypes.func
};

MessagePublishForm.defaultProps = {
  onSubmit: null
};

export default MessagePublishForm;
