import { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent } from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  DocumentTypeModel,
  Mode,
  button
} from 'src/common';
import type {
  DocumentTypeResource,
  DocumentTypeResourceForCreate,
  DocumentTypeResourceForEdit,
  CreateResponse,
  Error
} from 'src/common/types';
import DocumentTypeForm from 'src/components/content/documentType/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';
import { Location } from 'src/common';

export interface DocumentTypeEditFormProps {
  model: DocumentTypeModel;
  documentType: DocumentTypeResource;
  location?: Location;
  onSubmit?: (values: DocumentTypeResource) => void;
}

async function redirect(model: DocumentTypeModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message,
  });
};

const DocumentTypeEditForm: FC<DocumentTypeEditFormProps> = ({ model, documentType, location, onSubmit }) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const handleSubmit = async (values : DocumentTypeResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    let submitData = values as DocumentTypeResourceForEdit;
    const model = new DocumentTypeModel();
    if (submitData && location.pathname?.indexOf('edit') > 0) {
      const response = await model.updateDocumentType(submitData);
      if (!model.error) {
        await redirect(model,response);
      }
    } else {
      submitData = values as DocumentTypeResourceForCreate;
      const response = await model.insertDocumentType(submitData);
      if (!model.error) {
        await redirect(model,response);
      }
    }
  };

  return (
    <Formik
      initialValues={DocumentTypeModel.getInitialValues(documentType)}
      validationSchema={validateSchema(model, documentType)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <DocumentTypeForm 
                 model={model}
                 documentType={values}
                 errors={errors}
                 touched={touched}
                 onHandleBlur={handleBlur}
                 onHandleChange={handleChange}
                 setFieldValue={setFieldValue}
              />
              <Box mt={2}>
                  <Authorize 
                    resourceCode={model.ResourceCode} 
                    mode={documentType.code ? Mode.edit : Mode.add} 
                    onIsAuth={l => setIsAuth(l)}
                  >
                    <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={button} />
                  </Authorize>
                  <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DocumentTypeEditForm.propTypes = {
  documentType: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  location: PropTypes.any,
};

DocumentTypeEditForm.defaultProps = {
  onSubmit: null
}

export default DocumentTypeEditForm;
