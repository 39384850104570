import { Alert, Box, CardContent, Container, Typography } from "@mui/material";
import { FC, useEffect, useReducer, useCallback } from "react";
import Page from "src/components/Page";
import LoadingScreen from "src/components/loading/LoadingScreen";
import CommunauteJuridiqueModel from "src/model/content/CommunauteJuridique";
import { AccessModel, CaijButton, EmployeeRole, _editableDocument, btnSubmit, root } from "src/common";
import { fetchHtmlContent, fetchHtmlContentLock } from "src/store/actions/content/EditableDocumentAction";
import HtmlContentEditor from "../../../components/content/HtmlContentEditor";
import { EditableDocumentModel } from "src/model/EditableDocument";
import MyCard from "src/components/card/MyCard";
import useAuth from "src/hooks/useAuth";
import GenericHeader from "src/components/header/GenericHeader";

const initialState = {
    content: null,
    isLoading: true,
    documentLock: null
}

const CommunauteJuridiqueView: FC = () => {
    const model = CommunauteJuridiqueModel.getInstance();
    const documentContentModel = new EditableDocumentModel(model);
    const access = new AccessModel(model.ResourceCode);
    const [state, dispatch] = useReducer(_editableDocument, initialState);
    const docId = "specialcollaboration_contenu";
    const authContext = useAuth();
    const employee = authContext.user.employee;

    const { documentLock, content, isLoading } = state;

    const getDocumentContent = useCallback(async () => {
        if (access.canRead()) {
            const [lockedResult, content] = await Promise.all([documentContentModel.getDocumentLockStatus(docId), documentContentModel.getContent(docId)]);
            if (lockedResult) {
                dispatch(fetchHtmlContentLock(lockedResult));
            }
            if(content){
                dispatch(fetchHtmlContent(content, false));
            }else{
                dispatch(fetchHtmlContent("", false));  
            }
        }
    }, [docId]);

    const lockDocument = async () => {
        await documentContentModel.lockDocumentContentEditing(docId);
        if (!model.error) {
            const lockedResult = await documentContentModel.getDocumentLockStatus(docId);
            const data = lockedResult;
            dispatch(fetchHtmlContentLock(data));
        }
        model.resetError();
    }

    const removeDocumentLock = async () => {
        const result = await documentContentModel.removeDocumentLock(docId);
        if (!model.error) {
            dispatch(fetchHtmlContentLock(null));
            if (content == null) {
                await getDocumentContent();
            }
        }
        model.resetError();
    }

    useEffect(() => {
        (async () => {
            await getDocumentContent();
        })();
    }, [getDocumentContent]);

    if (isLoading) return <LoadingScreen />

    return (
        <>
            <Page sx={root} title={model.PageTitle}>
                <Container maxWidth={false}>
                    <GenericHeader model={model} headerTitle={model.HeaderTitle} />
                    <Box mt={5}>
                        <MyCard>
                            <CardContent>
                                {documentLock && employee.identifier !== documentLock.lockedBy ?
                                    <>
                                        <Typography variant='h6' sx={{ color: 'text.primary' }}>
                                            <span>Document verrouillé</span>
                                        </Typography>
                                        <Box mt={2}>
                                            <Alert severity="warning">
                                                <p>Le contenu de ce document est verrouillé, car un(e) autre utilisateur ({documentLock.lockedBy}) a apporté des modifications non enregistrées. Veuillez soit attendre que les modifications soient sauvegardées ou contacter un admin pour déverrouiller ce document.</p>
                                            </Alert>
                                        </Box>
                                        <Box mt={2}>
                                            {documentLock && employee.role === EmployeeRole.Admin && <CaijButton
                                                sx={btnSubmit}
                                                onHandleClick={removeDocumentLock}
                                            >Déverrouiller</CaijButton>}
                                        </Box>
                                    </>
                                    :
                                    <HtmlContentEditor
                                        docId={docId}
                                        pageModel={model}
                                        initialContent={content}
                                        documentModel={documentContentModel}
                                        documentLock={documentLock}
                                        lockDocument={lockDocument}
                                        removeDocumentLock={removeDocumentLock}
                                        redirectPath={model.Path.Home}
                                    />
                                }
                            </CardContent>
                        </MyCard>
                    </Box>
                </Container>
            </Page>
        </>
    )
}

export default CommunauteJuridiqueView;
