import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import type {
  Theme,
  WfLibraryDto,
  WfProfileDto,
  CreateResponse,
  WfTypeDto,
  Submitting
} from 'src/common/types';
import { Box, Container, Divider, Tab, Tabs, useTheme } from '@mui/material';
import {
  useLocation,
  useNavigate,
  _symphony,
  SymphonyModel,
  setHash,
  AccessModel,
  fetchProfilesList,
  fetchLibrariesList,
  root,
} from 'src/common';
import {  Error } from 'src/common/types'
import Page from 'src/components/Page';
import LibrarySymphony from './Details/Library';
import ProfilSymphony from './Details/Profils';
import printMessage from 'src/views/errors/MessageError';
import { useSymphony } from 'src/functions/hooks/symphony';
import { SymphonyAction } from 'src/store/reducers/customer/SymphonyReducer';
import GenericHeader from 'src/components/header/GenericHeader';

const box = {
  padding: '1em',
};

interface TabsSymphony {
  value: string, label: string
};

const initialTabs: TabsSymphony[] = [
  { value: 'library', label: 'Bibliothèques' },
  { value: 'profile', label: 'Profils' }
];

enum CurrentTabs {
  library = 'library',
  profile = 'profile',
};

interface WfSymphonyDto {
  id?: number;
  name?: string;
  activated?: boolean;
  deleted?: boolean;
}

async function deactivate(model: SymphonyModel, name: string, currentTab: string) : Promise<Error> {
  if(currentTab === CurrentTabs.library){
    return await model.deleteLibrary(name);
  }else{
    return await model.deleteProfile(name);
  }
};

async function activate(model: SymphonyModel, value: WfTypeDto, currentTab: string) : Promise<CreateResponse> {
  if(currentTab === CurrentTabs.library){
    return await model.insertLibrary(value as WfLibraryDto);
  }else{
    return await model.insertProfile(value as WfProfileDto);
  }
};

function reload(currentTab : string, name: string,  libraries: WfLibraryDto[], profiles: WfProfileDto[], dispatch:React.Dispatch<SymphonyAction>/*setSymphony: Dispatch<SetStateAction<Symphony>>*/) : void {
  if(currentTab === CurrentTabs.library){
    const newLibraries = libraries.map((library: WfSymphonyDto) => {
      if(library.name === name) {
        library.activated = !library.activated;
      }
     return library;
    })
    dispatch(fetchLibrariesList(newLibraries, true, !Array.isArray(newLibraries) || newLibraries.length == 0));
  }else{
    const newProfiles = profiles.map((profile: WfSymphonyDto) => {
      if(profile.name === name) {
        profile.activated = !profile.activated;
      }
      return profile;
    })
    dispatch(fetchProfilesList(newProfiles, true, !Array.isArray(newProfiles) || newProfiles.length == 0));
  }
};

function setIsSubmitting(name: string, isSubmitting: boolean, setSubmitting: Dispatch<SetStateAction<Submitting>>) : void {
  setSubmitting((prevState: Submitting) => {
    return { ...prevState, isSubmitting, name }
  });
};

const SymphonyView: FC = () => {
  const model = SymphonyModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [tabs, setTabs] = useState<TabsSymphony[]>(initialTabs);
  const [editMode, setEditMode] = useState({
    library: false,
    profile: false,
  });
  const [submitting, setSubmitting] = useState<Submitting>( {
    isSubmitting: false,
    name: ''
  });
  const [currentTab, setCurrentTab] = useState<string>('library');
  const navigate = useNavigate();
  let { libraryState, profileState, dispatch } = useSymphony();
  const theme = useTheme();

  const setEditModeSymphony = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit,
    });
  };

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      if(tabVal){
        setCurrentTab(tabVal);
        removedHashSymbol.indexOf('edit') !== -1 && setEditMode({...editMode, [tabVal]:true});
      }
    }
  }

  useEffect(() => {
    handleBrowserRefresh();
    const { hash, pathname } = location;
    model.PathName = pathname + hash;
  },[location]);

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    event.persist();
    setHash(value, navigate);
    setCurrentTab(value);
    setEditModeSymphony(false);
  };

  const handleActivate = async (value: WfTypeDto) => {
    const { name } = value;
    setIsSubmitting(name, true, setSubmitting);
    const model = new SymphonyModel();
    const response = await activate(model, value, currentTab);
    if (!model.error) {
      printMessage({
        status: response.status,
        message: response.message
      });
      reload(currentTab, value.name, libraryState.libraries, profileState.profiles, dispatch);
    }
    setIsSubmitting(name, false, setSubmitting);
  } 

  const handleDeActivate = async (name: string) => {
    setIsSubmitting(name, true, setSubmitting);
    const model = new SymphonyModel();
    const response = await deactivate(model, name, currentTab);
    if (!model.error) {
      printMessage({
        status: response.status,
        message: response.message
      });
      reload(currentTab, name, libraryState.libraries, profileState.profiles, dispatch);
    }
    setIsSubmitting(name, false, setSubmitting);
  } 
  
  const renderContent = () => {
    switch (currentTab) {
      case 'library':
        return (  
          <LibrarySymphony 
            model={model}
            state={libraryState} 
            onActivate={(l: WfTypeDto) => handleActivate(l)}
            onDeActivate={(l: string) => handleDeActivate(l)}
            submitting={submitting}
          />
        )
      case 'profile':
        return (
          <ProfilSymphony 
            model={model}
            state={profileState} 
            onActivate={(l: WfTypeDto) => handleActivate(l)}
            onDeActivate={(l: string) => handleDeActivate(l)}
            submitting={submitting}
          />
        )
      default:
        return '';
    }
  };

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3} p={1} sx={{ backgroundColor: (theme: Theme) => theme.palette.background.paper}}>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='standard' textColor='secondary'>
            {tabs.map(tab => (
                <Tab key={tab.value} 
                    label={tab.label} 
                    value={tab.value}
                />
              )
            )}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{
          ...box, 
          color: theme.palette.text.primary,
          backgroundColor: (theme: Theme) => theme.palette.background.paper
        }}>{access.canRead() && renderContent()}</Box>
      </Container>
    </Page>
  );
};

export default SymphonyView;
