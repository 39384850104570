import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  CreateResponse,
  EmailTemplateDto,
  Error
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { NavigateFunction } from 'react-router';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

export default class EmailTemplateModel extends AppModel 
{
  private _navigate: NavigateFunction;
  private static _instance: EmailTemplateModel;

  readonly Code = new FormModel('code','Code',45);
  readonly Name = new FormModel('name','Nom',45);
  readonly SubjectFr = new FormModel('subjectFr','Sujet',300);
  readonly SubjectEn = new FormModel('subjectEn','Subject',300);
  readonly ContentFr = new FormModel('contentFr','Message (Français)');
  readonly ContentEn = new FormModel('contentEn','Message (English)');
  readonly Note = new FormModel('note','Note');

  constructor(){
    super('/emailTemplate');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'EMAIL_TEMPLATE';
    this._headerTitle = 'Liste des gabarits courriel';
    this._btnAddText = 'Ajouter un gabarit courriel';
    this.Path.PathName = '/email-templates';

    //Dialog
    this.Dialog.Header = 'Supprimer le gabarit courriel';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le gabarit courriel';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Gabarits courriel',
      href: this.Path.Home,
      visible: true
    }
  }

  set Navigate(navigate: NavigateFunction){
    this._navigate = navigate;
  }

  get Navigate(){
    return this._navigate;
  }

  getHeadCells(){
    return [
      {
        id: this.Code.Name, width: '45%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.Name.Name, width: '45%', numeric: false, disablePadding: false, label: this.Name.Label
      },
      {
        id: '', width: '10%'
      }
    ];
  };

  static getInstance(): EmailTemplateModel {
    if (!EmailTemplateModel._instance) {
      EmailTemplateModel._instance = new EmailTemplateModel();
    }
    return EmailTemplateModel._instance;
  }

  static getInitialValues(values: EmailTemplateDto) : EmailTemplateDto {
    return {
        ...values,
        name: values.name || '',
        note: values.note || '',
        subjectFr: values.subjectFr || '',
        subjectEn: values.subjectEn || '',
        contentFr: values.contentFr || '',
        contentEn: values.contentEn || ''
      }
  }

  async getEmailTemplates(): Promise<EmailTemplateDto[]> {
    let emailTemplates: EmailTemplateDto[];
    await axios.get<EmailTemplateDto[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            emailTemplates = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return emailTemplates;
  }

  async getEmailTemplateById(id: string): Promise<EmailTemplateDto> {
    let emailTemplate: EmailTemplateDto;
    await axios.get<EmailTemplateDto>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            emailTemplate = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return emailTemplate;
  }

  async getTags(): Promise<string[]> {
    let tags: string[];
    await axios.get<string[]>(`${this.route}/tags`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            tags = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return tags;
  }

  async insertEmailTemplate(submitData: EmailTemplateDto): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<EmailTemplateDto, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Gabarit courriel créé.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async testEmail(email: string, submitData: EmailTemplateDto): Promise<Error> {
    let result: Error;
    await axios.post<EmailTemplateDto, Error>(`${this.route}/${email}`, submitData).then(
      response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Courriel est envoyé.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async updateEmailTemplate(submitData: EmailTemplateDto): Promise<Error> {
    let result: Error;
    await axios.put<EmailTemplateDto, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Gabarit courriel modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Gabarit courriel supprimé.'
					});
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  public verifyMessage(message: string){
    if(!message) return false;
    const newMessage = message.replace(/(<([^>]+)>)/ig,'')
                              .replace(/&nbsp;/ig,'');
     const regex = new RegExp(/^\s+$/,'g');
    if(regex.test(newMessage)) return false;
    return true;
  };
}
