import { FC, useState } from "react";
import * as Yup from 'yup';
import { Authorize, button, CaijButtonReset, CaijButtonSubmit, CaijInput, CaijTextarea, Formik, Mode, ReperageModel, handleChange, CaijCard, EmptyList, formatDateTime } from "src/common";
import { Error, CreateResponse, ReperageResource, ReperageResourceForEdit } from "src/common/types";
import { Box, CardContent, Grid, Stack } from "@mui/material";
import MyCard from "src/components/card/MyCard";
import CaijTypography from "src/components/typography";
import type { Theme } from 'src/common/types';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

export interface ReperageSendFormProps {
    model: ReperageModel;
    reperage: ReperageResource;
};

async function redirect(model: ReperageModel, response: Error | CreateResponse): Promise<void> {
    const createResponse: CreateResponse = response as CreateResponse;
    await model.redirect((createResponse.data?.id ? model.Path.getDetail(createResponse.data.id) : model.Path.Home), {
      status: response.status,
      message: response.message
    });
};

const listItemStyle = {
    display: 'flex',
    color: (theme: Theme) => theme.palette.text.primary,
    alignItems: 'center',
    backgroundColor: (theme: Theme) => theme.palette.background.dark,
    paddingLeft: '0.5em',
    margin: '0 1em 1em 0',
    height: '2em',
    borderRadius: '0.4em',
    whiteSpace: 'nowrap',
    transition: '0.2s',
    '&:hover': {
      transition: '0.2s',
      backgroundColor: (theme: Theme) => theme.palette.divider,
    },
    paddingRight: '5px'
};

const columns: GridColDef[] = [
    { field: 'title', headerName: 'Titre', sortable: false, flex: 440, disableColumnMenu: true },
    { field: 'dateCreated', headerName: 'Date Créé', sortable: false, disableColumnMenu: true, width: 150, valueFormatter: (param) => formatDateTime(param.value)},
    { field: 'dateModified', headerName: 'Date Modifié', sortable: false, disableColumnMenu: true, width: 150, valueFormatter: (param) => formatDateTime(param.value) },
];

const ReperageSendForm: FC<ReperageSendFormProps> = ({ model, reperage }) => {
    const [isAuth, setIsAuth] = useState<boolean>();
    const { Name, Infos, Types, ClientLabel, ClientId, Courriel, Note } = model;
    
    const handleSubmit = async (values: ReperageResource): Promise<void> => {
        const model = new ReperageModel();
        let submitData = values as ReperageResourceForEdit;
        if (submitData && submitData.id) {
            const response = await model.sendReperage(submitData);
            if (!model.error) {
                await redirect(model, response);
            }
        }
      };
    
    return (
        <Formik
            initialValues={ReperageModel.getInitialValues(reperage, null)}
            validationSchema={Yup.object().shape({
                courriel: Courriel.required(true)
            })}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting, setValues }) => {
                return (<>
                    <form onSubmit={handleSubmit}>
                        <MyCard>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <CaijInput
                                            name={Name.Name}
                                            label={Name.Label}
                                            value={values.name}
                                            disabled={true}
                                        />
                                        <CaijTextarea
                                            name={Infos.Name}
                                            label={Infos.Label}
                                            value={values.infos}
                                            rows={5}
                                            disabled
                                        />
                                        <CaijCard title={model.Types} allowedBackgroundColor={false}>
                                            { values.types.length === 0 ? <EmptyList noCard /> : (
                                                    <Box display="flex" flexWrap='wrap'>
                                                    { values.types.map(value => (
                                                            <Box sx={listItemStyle} >
                                                                <CaijTypography variant='body2' sx={{color:'text.primary'}}>{value}</CaijTypography>
                                                            </Box>
                                                        ))
                                                    }
                                                    </Box>
                                               )
                                            }
                                        </CaijCard>
                                        <CaijCard title="Liste des documents" allowedBackgroundColor={false}>
                                            <div style={{ height: values.contentItems.length > 0 ? 400 : 150, width: '100%' }}>
                                                <DataGrid
                                                    rows={values.contentItems}
                                                    columns={columns}
                                                    slots={{
                                                        noRowsOverlay: () => (
                                                        <Stack height="100%" alignItems="center" justifyContent="center">
                                                            <EmptyList noCard />
                                                        </Stack>
                                                        ),
                                                    
                                                    }}
                                                    hideFooter
                                                />
                                            </div>
                                        </CaijCard>
                                        <CaijInput
                                            name={ClientLabel.Name}
                                            label={ClientLabel.Label}
                                            value={values.clientLabel}
                                            required
                                            helperText={touched.name && errors.name}
                                            error={Boolean(touched.name && errors.name)}
                                            InputLabelProps={{ shrink: true, required: true }}
                                            inputAttr={{ maxLength: Courriel.MaxLength, 'aria-label': 'courriel', 'data-testid': 'courriel' }}
                                            disabled={true}
                                        />
                                        <CaijInput
                                            name={ClientId.Name}
                                            label={ClientId.Label}
                                            value={values.clientId}
                                            required
                                            helperText={touched.name && errors.name}
                                            error={Boolean(touched.name && errors.name)}
                                            InputLabelProps={{ shrink: true, required: true }}
                                            inputAttr={{ maxLength: Courriel.MaxLength, 'aria-label': 'courriel', 'data-testid': 'courriel' }}
                                            disabled={true}
                                        />
                                        <CaijInput
                                            name={Courriel.Name}
                                            label={Courriel.Label}
                                            value={values.courriel}
                                            required
                                            helperText={touched.name && errors.name}
                                            error={Boolean(touched.name && errors.name)}
                                            InputLabelProps={{ shrink: true, required: true }}
                                            inputAttr={{ maxLength: Courriel.MaxLength, 'aria-label': 'courriel', 'data-testid': 'courriel' }}
                                            onHandleBlur={handleBlur}
                                            onHandleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            sx={{mb: 2}}
                                        />
                                        <CaijTextarea
                                            name={Note.Name}
                                            id={Note.Name}
                                            rows="4"
                                            margin="none"
                                            label={Note.Label}
                                            value={values.note}
                                            inputProps={{ 'aria-label': 'note', 'data-testid': 'note' }}
                                            InputLabelProps={{ shrink: true }}
                                            onHandleBlur={handleBlur}
                                            onHandleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <Box>
                                            <Authorize
                                                resourceCode={model.ResourceCode}
                                                mode={Mode.edit}
                                                onIsAuth={l => setIsAuth(l)}>
                                                <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={button} value={"Envoyer et archiver"} />
                                            </Authorize>
                                            <CaijButtonReset disabled={isSubmitting} pathName={reperage.id ? model.Path.getDetail(reperage.id) : model.Path.Home} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </MyCard>
                    </form>
                </>
                )
            }}
        </Formik>
    );
}

export default ReperageSendForm;

