import ResourceModel from 'src/model/employee/Resource';
import * as Yup from 'yup';

const validateSchema = (model: ResourceModel) => {
  const { Name, Code } = model;
  return Yup.object().shape({
    name: Name.required(true),
    code: Code.required(true)
  });
}
export default validateSchema;