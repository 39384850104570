import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  fetchTribunal,
  _tribunal,
  _tribunalType,
  useLocation,
  useParams,
  TribunalModel,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import TribunalEditForm from './TribunalEditForm';
import { TribunalProvider } from 'src/contexts/TribunalContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const TribunalEditView: FC = () => {
  const model = TribunalModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_tribunal, {isLoading: false, tribunal: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new TribunalModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          await model.redirect(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const tribunal = await model.getTribunalById(id);
          if (!model.error) {
            dispatch(fetchTribunal(tribunal, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchTribunal({}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { tribunal, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = tribunal.labelFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <TribunalProvider>
            <TribunalEditForm model={model} tribunal={tribunal} />
          </TribunalProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default TribunalEditView;
