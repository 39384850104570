import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { EmployeesResource } from 'src/common/types';
import EmployeeInfo from './EmployeeInfo';
import { EmployeeModel } from 'src/common';

interface DetailsProps {
  model: EmployeeModel;
  employee?: EmployeesResource;
}

const Details: FC<DetailsProps> = ({
  model,
  employee
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <EmployeeInfo model={model} employee={employee} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  employee: PropTypes.object
};

Details.defaultProps = {
  employee: {}
};

export default Details;
