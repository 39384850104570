import React, { FC, ReactNode } from 'react';
import { Box, Typography, Grid, SvgIcon, SxProps, Theme, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import type { 
	CustomerResource, 
	CustomerAddressDto
} from 'src/common/types';
import CustomerLawPraticeFieldInfo from './CustomerLawPraticeFieldInfo';
import {
  User as UserIcon,
  Gift as BirthdayIcon,
  Mail as MailIcon,
  Smartphone as CellPhoneIcon,
  Phone as PhoneIcon,
  Briefcase as JobIcon,
  Home as AddressIcon,
  Layers as SymphonyIcon
} from 'react-feather';
import { 
  AccountExpiryMode, 
  CaijCheckbox, 
  CustomerModel,
  Label,
  formatDate, 
  labelConfig, 
  link, 
} from 'src/common';
import {$enum} from "ts-enum-util";
import Link from '@mui/material/Link';
import { greyed } from '../../../../styles/customer';
import CustomerStatusDetail from 'src/components/customer/customer/Details/CustomerStatusDetail';
import EditButton from 'src/components/customer/customer/Details/EditButton';
import Main from 'src/components/customer/customer/Details/Main';
import CaijAvatar from 'src/components/customer/customer/Details/CaijAvatar';
import CustomerPendingEmail from 'src/components/customer/customer/Details/CustomerPendingEmail';
import SplitName from 'src/components/customer/customer/splitName';

const neverExpire = $enum(AccountExpiryMode).getKeyOrDefault(AccountExpiryMode.NeverExpire);

interface CustomerProfileProps {
	model: CustomerModel;
  customer: CustomerResource;
	isEditing: boolean;
	logo: string;
	onEditCustomer: () => void;
  onHandleRefreshCustomer?: () => Promise<void>;
}

interface Params{
	label?: string; 
	allowedIcon?: boolean;
	icon?: JSX.Element; 
	children: ReactNode;
	sx?: SxProps<Theme>
};

const Profile = (props: Params) => {
	const { allowedIcon, icon, label, children, sx } = props;
	return (
		<>
			{allowedIcon ? <SvgIcon fontSize="small" sx={sx || null}>{icon}</SvgIcon> : <Typography sx={greyed}>{label}</Typography>}
			{children}
		</>
  )
};

const CustomerProfile: FC<CustomerProfileProps> = ({
	model,
  customer,
	logo,
	isEditing,
	onEditCustomer,
  onHandleRefreshCustomer
}) => {
	const { MemberAdditionalInfo, MemberAccessBetaCaij, MemberExtIdentifier } = model;
  return (
		<Main>
			<EditButton model={model} isEditing={isEditing} onEditCustomer={onEditCustomer} />
			<Grid container spacing={3}>
				<Grid item xs={6}>
					<Box display='flex' flexDirection='column'>
						{ customer.logoUrl && (
								<CaijAvatar allowedDelete model={model} customer={customer} logo={logo} sx={{height:50,width:50}} onHandleRefreshCustomer={onHandleRefreshCustomer} />
							)
						}
						<Profile allowedIcon icon={<UserIcon />}>
              <Typography sx={{ marginTop: '0.5em' }}>{`${customer.firstname} ${customer.lastname}`}</Typography>
              <SplitName firstName={customer.firstname} lastName={customer.lastname} />
					  </Profile>
						{customer.birthdate && (
							<Profile allowedIcon sx={{ marginTop: '1em' }} icon={<BirthdayIcon />}>
              	<Typography sx={{ marginTop: '0.5em' }}>{formatDate(customer.birthdate)}</Typography>
							</Profile>
						)}
						<Profile allowedIcon sx={{marginTop: '1em'}} icon={<MailIcon />}>
							<Link underline='none' sx={{ ...link, mt: '0.5em', color: 'text.primary' }} href={`mailto:${customer.email}`}>
								<Typography>{customer.email}</Typography>
							</Link>
						</Profile>
						{customer.customerPendingChanges && (
							<CustomerPendingEmail  
								customer={customer} 
								model={model}
								onHandleRefreshCustomer={onHandleRefreshCustomer}
							/>
						)}
						{customer.cellPhone && (
							<Profile allowedIcon sx={{ marginTop: '1em' }} icon={<CellPhoneIcon />}>
								<Typography sx={{ marginTop: '0.5em' }}> {customer.cellPhone}</Typography>
						 	</Profile>
						)}
						{customer.phone && (
							<Profile allowedIcon sx={{ marginTop: '1em' }} icon={<PhoneIcon />}>
								<Box display='flex'>
									<Typography sx={{ marginTop: '0.5em' }}> {customer.phone}</Typography>
									{customer.phoneExt && (
										<>
											<Typography sx={{ marginTop: '0.5em' }}>&nbsp;/ #{customer.phoneExt}</Typography>
										</>
									)}
								</Box>
							</Profile>
						)}
						{(customer.organization || customer.job) && (
							<Profile allowedIcon sx={{ marginTop: '1em' }} icon={<JobIcon />}>
								<Typography sx={{ marginTop: '0.5em' }}> {customer.organization}</Typography>
								<Typography sx={{ marginTop: '0.5em' }}> {customer.job}</Typography>
							</Profile>
						)}
						{(customer.addresses && customer.addresses.length > 0) && (
							<Profile allowedIcon sx={{ marginTop: '1em' }} icon={<AddressIcon />}>
								{customer.addresses.map((address: CustomerAddressDto, index: number) => (
									<Box key={index}>
										<Box display="flex" alignItems="baseline" gap={1} marginTop="0.5em">
											<Typography sx={{ fontWeight: 'bold' }}>{`Adresse ${++index}`}</Typography>
											{address.imported && <Label color="warning5" >Importée</Label>}
										</Box>
										<CaijCheckbox
											name='preferred'
											checked={address.preferred}
											label='Par défault'
											disabled
											key={index}
										/>
										<Typography sx={{ fontStyle: 'italic' }}>{address.name}</Typography>
										{ CustomerModel.getCustomerAddress(address).map((address: string, index: number) => <Typography key={index}>{address}</Typography>)}
									</Box>
								))}
							</Profile>
						)}
						{(customer.wfLibrary || customer.wfProfile) && (
							<>
								<Box display='flex' sx={{ marginTop: '1em' }}>
									<Box mr={1}>
										<SvgIcon fontSize="small">
											<SymphonyIcon />
										</SvgIcon>
									</Box>
									<Typography sx={{ fontWeight: 'bold' }}>Profils Symphony</Typography>
								</Box>
								{customer.wfLibrary && (
									<Profile label={labelConfig.wfLibrary}>
										<Typography>{customer.wfLibrary}</Typography>
									</Profile>
								)}
								{customer.wfProfile && (
									<Profile label={labelConfig.wfProfile}>
										<Typography>{customer.wfProfile}</Typography>
									</Profile>
								)}
							</>
						)}
					</Box>
				</Grid>
				<Grid item xs={6}>
					<CustomerStatusDetail customer={customer} showTitle />
					{(customer.subscriptionGroup?.accountExpiryMode !== neverExpire || customer.expiration !== null || customer.endorsed) && (
						<Profile label={labelConfig.expiryDate}>
							<Typography>{formatDate(customer.expiration)}</Typography>
					  </Profile>
					)}
					{customer.language && (
            <Profile label={labelConfig.language}>
							<Typography>{CustomerModel.getLanguageProfileByKey(customer.language)}</Typography>
						</Profile>
					)}
					{customer.noCaij && (
					  <Profile label={labelConfig.noCaij}>
							<Typography>{customer.noCaij}</Typography>
						</Profile>
					)}
					{customer.extIdentifier && (
						<Profile label={MemberExtIdentifier.Label}>
						 	<Typography>{customer.extIdentifier}</Typography>
						</Profile>
					  )
					}
					{customer.statusInfo && (
						<Profile label={labelConfig.statusInfo}>
							<Typography>{customer.statusInfo}</Typography>
						</Profile>
					)}
					{customer.groupCategory && (
						<Profile label={labelConfig.groupCategory}>
							<Typography>{customer.groupCategory}</Typography>
					  </Profile>
					)}
					{customer.additionalInfo && (
						<Profile label={MemberAdditionalInfo.Label}>
							<Typography>{customer.additionalInfo}</Typography>
						</Profile>
					)}
					<Profile label={MemberAccessBetaCaij.Label}>
					  <Typography>{customer.accessBetaCaij ? 'Oui' : 'Non'}</Typography>
					</Profile>
					<Box sx={{ marginTop: '2em' }}>
						<CustomerLawPraticeFieldInfo model={model} customer={customer} />
					</Box>
				</Grid>
			</Grid>
		</Main>
  );
};

CustomerProfile.propTypes = {
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func,
	isEditing: PropTypes.bool.isRequired,
	onEditCustomer: PropTypes.func.isRequired
};

export default CustomerProfile;
