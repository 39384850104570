import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  fetchResources,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import ResourceModel from 'src/model/employee/Resource';
import reducer from 'src/store/reducers/employee/ResourceReducer';
import printMessage from 'src/views/errors/MessageError';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  resources: [],
  isLoading: false,
  isEmptyList: true
};

const ResourceListView: FC = () => {
  const model = ResourceModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const getResources = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const resourceModel = new ResourceModel();
      resourceModel.PathName = location.pathname;
      const resources = await resourceModel.getResources();
      if (!resourceModel.error) {
        dispatch(fetchResources(resources, true, !Array.isArray(resources) || resources.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getResources();
    })();
  },[]);

  const deleteResource: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      const response = await model.deleteResource(id);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await getResources();
      }
    }
  };

  const { resources, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page
      sx={root}
      title={model.PageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            resources={resources}
            onDeleteResource={deleteResource}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ResourceListView;
