import { useEffect, useReducer } from 'react';
import {
	AccessPermissions,
	fetchLibraries,
	fetchLibraryServices,
	fetchProducts,
	Forbidden,
	LibraryModel,
	LibraryServiceModel,
	ListType,
	ProductModel,
	Unauthorized,
	_library,
	_libraryService,
	_product,
	_subscription,
} from 'src/common';
import { ProductResource, LibraryServiceResource, LibraryResource } from 'src/common/types';
import { FETCH_LIBRARIES, FETCH_LIBRARY_SERVICES, FETCH_PRODUCTS } from 'src/store/actions/ActionTypes';
import { Subscription, useSubscriptions } from '../listInfo/hook';

interface Data {
	isLoading: boolean;
	isEmptyList: boolean;
	isAuthorize: boolean;
}

interface Product extends Data {
	products: ProductResource[];
}

interface LibraryService extends Data {
	libraryServices: LibraryServiceResource[];
}

interface Library extends Data {
	libraries: LibraryResource[];
}

const initialOthersState = {
  isLoading: false,
	isEmptyList: true,
	isAuthorize: false,
};

export function useLibraryServices(): LibraryService {
	const initialState = {
		...initialOthersState,
		libraryServices: []
	};
	const [state, dispatch] = useReducer(_libraryService, initialState);
	useEffect(() => {
		const initialise = async () : Promise<void> => {
			const model = new LibraryServiceModel();
			model.skipHandleError = true;
			const libraryServices = await model.getLibraryServices();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchLibraryServices(libraryServices, true, false, false));
				} else {
					dispatch(fetchLibraryServices(libraryServices, true, true, true));
				}
			} else {
				dispatch(fetchLibraryServices(libraryServices, true, !Array.isArray(libraryServices) || libraryServices.length === 0, true));
			}
		};
		(async () => {
      await initialise();
    })();
		return () => {
			dispatch({ type: FETCH_LIBRARY_SERVICES, payload: initialState });
		};
	}, []);
	const { libraryServices, isLoading, isEmptyList, isAuthorize } = state;
	return { libraryServices, isLoading, isEmptyList, isAuthorize };
}

export function useProducts(): Product {
	const initialState = {
		...initialOthersState,
		products: []
	};
	const [state, dispatch] = useReducer(_product, initialState);
	useEffect(() => {
		const initialise = async () : Promise<void> => {
			const model = new ProductModel();
			model.skipHandleError = true;
			let products = await model.getProducts();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchProducts(products, true, false, false));
				} else {
					dispatch(fetchProducts(products, true, true, true));
				}
			} else {
				products = products.filter(({access}) => access === AccessPermissions.Private);
				dispatch(fetchProducts(products, true, !Array.isArray(products) || products.length === 0, true));
			}
		};
		(async () => {
      await initialise();
    })();
		return () => {
			dispatch({ type: FETCH_PRODUCTS, payload: initialState });
		};
	}, []);
	const { products, isLoading, isEmptyList, isAuthorize } = state;
	return { products, isLoading, isEmptyList, isAuthorize };
}

export function useLibraries() : Library {
	const initialState = {
		...initialOthersState,
		libraries: []
	};
	const [state, dispatch] = useReducer(_library, initialState);
	useEffect(() => {
		const initialise = async () : Promise<void> => {
			const model = new LibraryModel();
			model.skipHandleError = true;
			const libraries = await model.getLibraries();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchLibraries(libraries, true, false, false));
				} else {
					dispatch(fetchLibraries(libraries, true, true, true));
				}
			} else {
				dispatch(fetchLibraries(libraries, true, !Array.isArray(libraries) || libraries.length === 0, true));
			}
		};
		(async () => {
      await initialise();
    })();
		return () => {
			dispatch({ type: FETCH_LIBRARIES, payload: initialState });
		};
	}, []);
	const { libraries, isLoading, isEmptyList, isAuthorize } = state;
	return { libraries, isLoading, isEmptyList, isAuthorize };
}

interface UseLists {
	sLibraryService: LibraryService;
	sProduct: Product;
	sSubscription: Subscription;
	sLibrary: Library;
}

export function useLists(listType: ListType) {
	let lists: UseLists = {
		sLibraryService: null,
		sProduct: null,
		sSubscription: null,
		sLibrary: null
	};
	if (listType === ListType.LibraryService) {
		lists.sLibraryService = useLibraryServices();
	} else if (listType === ListType.Product) {
		lists.sProduct = useProducts();
	} else if (listType === ListType.Subscription) {
		lists.sSubscription = useSubscriptions();
	}else if(listType === ListType.LibraryServiceLibrary){
		lists.sLibrary = useLibraries();
	}
	return lists;
}
