import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box
} from '@mui/material';
import {
  _contentMostViewed,
  _subscription,
  _customer,
  CustomerModel,
  AccessModel
} from 'src/common';
import type { CustomerResource } from 'src/common/types';
import CustomerEndorsedEditForm from './CustomerEndorsedEditForm';
import { fetchCustomerEndorsed } from '../../../../../../store/actions/customer/CustomerAction';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';

export interface CustomerEndorsedEditViewProps {
  model: CustomerModel;
  customerEndorsedId?: number;
  limitEndorsed?: number;
  totalEndorsed?: number;
  customer: CustomerResource;
  onHandleRefreshCustomer: () => Promise<void>;
  onSwitchMode: (isEdit: boolean) => void;
}

const CustomerEndorsedEditView: FC<CustomerEndorsedEditViewProps> = ({
  model,
  customerEndorsedId,
  limitEndorsed,
  totalEndorsed,
  customer,
  onHandleRefreshCustomer,
  onSwitchMode
}) => {
  const access = new AccessModel(model.ResourceCode);
  const [state, dispatch] = useReducer(_customer, { customerEndorsed: {} });

  useEffect(() => {
    if(access.canEditionGestion()){
      if (customerEndorsedId) {
        //Update nothing for moment
      } else {
        dispatch(fetchCustomerEndorsed({},true,false));
      }
    }
  }, [customerEndorsedId]);

  const { customerEndorsed } = state;

  if (customerEndorsedId) {
    if (Object.keys(customerEndorsed).length === 0) {
      return <LoadingScreen />
    }
  }

  return (
    <Box>
      <SubscriptionProvider>
        <CustomerEndorsedEditForm 
          model={model}
          customerEndorsed={customerEndorsed} 
          customer={customer}
          limitEndorsed={limitEndorsed}
          totalEndorsed={totalEndorsed}
          onHandleRefreshCustomer={onHandleRefreshCustomer}
          onSwitchMode={(l) => onSwitchMode(l)}
        />
      </SubscriptionProvider>
    </Box>
  );
};

CustomerEndorsedEditView.propTypes = {
  limitEndorsed: PropTypes.number,
  totalEndorsed: PropTypes.number,
  customerEndorsedId: PropTypes.number,
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired,
  onSwitchMode:  PropTypes.func.isRequired
};

export default CustomerEndorsedEditView;
