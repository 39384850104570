import { Box, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useState } from 'react';
import {
  Authorize,
  btnSubmit,
  CaijButtonReset,
  CaijButtonSubmit,
  Formik,
  handleChange,
  LawPracticeFieldModel,
  Mode
} from 'src/common';
import type { LawPracticeFieldResource, LawPracticeFieldForEdit } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import LawPraticeFieldForm from 'src/components/lawPraticeField/Forms';
import printMessage from 'src/views/errors/MessageError';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: LawPracticeFieldModel;
  lawPracticeField: LawPracticeFieldResource;
  onHandleRefreshLawPraticeField: () => void;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: LawPracticeFieldResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  lawPracticeField,
  onHandleRefreshLawPraticeField,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: LawPracticeFieldResource): Promise<void> => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LawPracticeFieldModel();
    const submitData = values as LawPracticeFieldForEdit;
    const lawDomainId = submitData.id;
    if (submitData && lawDomainId) {
      const response = await model.updateLawPracticeField(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        onHandleRefreshLawPraticeField();
      }
    }
  };

  return (
    <Formik
      initialValues={LawPracticeFieldModel.getInitialValues(lawPracticeField)}
      validationSchema={validateSchema(model, lawPracticeField)}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <LawPraticeFieldForm 
                model={model}
                lawPraticeField={values}
                errors={errors}
                touched={touched}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  lawPracticeField: PropTypes.object.isRequired,
  onHandleRefreshLawPraticeField: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
