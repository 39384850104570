import * as React from 'react';
import type { FC, ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import { tooltipClasses } from '@mui/material/Tooltip';

interface CaijTooltipProps {
  title: string;
  disabled?: boolean;
  marginTop?: string;
  marginBottom?: string;
  [x: string]: any;
  children?: ReactNode;
}

const CaijTooltip: FC<CaijTooltipProps> = ({
  title,
  disabled,
  marginTop,
  marginBottom = "0px",
  children,
  ...rest
}) => (
  <Tooltip TransitionComponent={Zoom} title={disabled ? "" : title} {...rest}
    slotProps={{
      popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
            {
              marginTop: marginTop || '5px',
            },
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
            {
              marginBottom,
            },
          }
      }
    }}
  >
    <span>
      {children}
    </span>
  </Tooltip>
);

export default CaijTooltip;
