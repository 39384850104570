import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link, ListItem, Collapse} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import type { Theme } from 'src/theme';
import { Link as RouterLink } from 'src/common';
import { useMatches } from 'react-router';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  open?: boolean;
  title: string;
  pathName?: string;
  visible?: boolean;
}

const icon = {
   // display: 'flex',
   // alignItems: 'center',
    marginRight: '8px'
};
  
const spanTitle = {
  marginRight: 'auto'
};

const paddingTop = '0.6em';
const fontSize = '14px';

const itemLeaf = {
  display: 'flex',
  padding: 0,
  fontSize
};

const item = {
  display: 'block',
  padding: 0,
  fontSize
};

const  getActiveLink = (href: string) => {
  const matches: {pathname: string}[] = useMatches(); 
  const { pathname } = window.location;
  if(pathname == '/app')
    return matches.filter(({pathname}) => pathname === '/app').some(({pathname}) => pathname === href)
  else
    return matches.filter(({pathname}) => pathname !== '/app').some(({pathname}) => pathname === href)
};

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  visible,
  pathName,
  ...rest
}) => {
  //const classes = useStyles();
  const [open, setOpen] = useState<boolean>(openProp);
  //const accessCust = new AccessModel(resourceCodeConfig.CUSTOMER);
  
  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 30 + 8 * depth;
  }

  const link =  { 
    paddingLeft: `${paddingLeft}px`, 
    letterSpacing: 0, 
    margin: 0, 
    width: '100%', 
    color: 'text.secondary', 
    display:'flex', 
    alignItems: 'center',
    paddingTop: paddingTop,
    paddingBottom: paddingTop,
    borderRadius: '3%',
    '&:hover': {
      backgroundColor: 'background.menu',
      textDecoration: 'none',
      cursor: 'pointer'
    },
    fontWeight: (theme:Theme) => theme.typography.fontWeightRegular,
    '& $title': {
        fontWeight: (theme:Theme) => theme.typography.fontWeightMedium
    },
  };
  //TODO: Visible pour le menu parent Resources
  if (children && title !== 'Resources') {
    return (
      <ListItem
        disableGutters
        key={title}
        {...rest}
        sx={{...item, display: 'block'}}
      >
        <Link
          onClick={handleToggle}
          sx={link}
          underline="none"
          component="a"
        >
          {Icon && (
            <span style={icon}>
              <Icon size='20' />
            </span>
          )}
          <span style={spanTitle}>
            {title}
          </span>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Link>
        <Collapse in={open} sx={{paddingTop:open ? '0.4em' : 0}}>
          {children}
        </Collapse>
      </ListItem>
    );
  }
  
  if(visible /*&& (title !== UPLOAD_CUSTOMER || (title === UPLOAD_CUSTOMER && accessCust.Gestion)*/) {
      return (
        <ListItem
          sx={itemLeaf}
          disableGutters
          key={title}
          {...rest}
        >
          <Link
            underline="none" 
            sx={{...link, color:getActiveLink(href) ? theme => theme.palette.secondary.main : 'text.secondary'}}
            to={href}
            component={RouterLink}
          >
            {Icon && (
              <span style={icon}>
                <Icon size='20' />
              </span>
            )}
            <span style={spanTitle}>
              {title}
            </span>
            {Info && <Info />}
          </Link>
        </ListItem>
      );
   }
   return null;
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
