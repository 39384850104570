import React from 'react';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import type { SubscriptionResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  Link
} from '@mui/material';
import { tableRow, link, SubscriptionModel } from 'src/common';
import { tableCell } from 'src/styles/subscription';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow } from 'src/components/table/CaijTableRow';

export interface ContactInfoProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
}

const width = {
  width: '145px'
};

const ContactInfo: FC<ContactInfoProps> = ({model,subscription}) => {
  const { ContactName, ContactEmail, ContactPhone, AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry} = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Personne contact" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow label={ContactName.Label} sxTableCell={{...tableCell, width}}>
            {subscription.contact.name}
          </CaijTableRow>  
          <CaijTableRow label={ContactEmail.Label} sxTableCell={{...tableCell, width}}>
            <Link
              underline="none"
              href={`mailto=${subscription.contact.email}`}
              sx={{...link, color:'text.secondary'}}   
            >
              {subscription.contact.email}
            </Link>
          </CaijTableRow>  
          <CaijTableRow label={ContactPhone.Label} sxTableCell={{...tableCell, width}}>
            {subscription.contact.phone}
          </CaijTableRow>  
          <CaijTableRow label={AddressLine1.Label} sxTableCell={{...tableCell, width}}>
            {subscription.contact.address?.line1}
          </CaijTableRow>  
          <CaijTableRow label={AddressLine2.Label} sxTableCell={{...tableCell, width}}>
            {subscription.contact.address?.line2}
          </CaijTableRow>  
          <CaijTableRow label={AddressCity.Label} sxTableCell={{...tableCell, width}}>
            {subscription.contact.address?.city}
          </CaijTableRow>  
          <CaijTableRow label={AddressState.Label} sxTableCell={{...tableCell, width}}>
            {subscription.contact.address?.state}
          </CaijTableRow>  
          <CaijTableRow label={AddressZip.Label} sxTableCell={{...tableCell, width}}>
            {subscription.contact.address?.zip}
          </CaijTableRow> 
          <CaijTableRow label={AddressCountry.Label} sxTableCell={{...tableCell, width}} sxTableRow={tableRow}>
            {subscription.contact.address?.country}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ContactInfo.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default ContactInfo;
