import React from 'react';
import type { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Outlet } from 'src/common';
import { Theme } from 'src/theme';
import ScrollReset from 'src/components/ScrollReset';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      //backgroundColor: theme.palette.background.paper,
      backgroundColor: theme.palette.background.dark,
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
    }
  }
});

const MainLayout: FC = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <ScrollReset />
      { /* <TopBar /> */ }
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
