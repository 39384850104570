import React, { MutableRefObject, ReactNode } from 'react';
import type { FC, ChangeEvent, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { InputAttr, InputProps, Margin, Size, Variant } from 'src/types/input';

interface CaijInputProps {
  value?: string | number | null;
  type?: 'text' | 'number' | 'hidden';
  select?: boolean;
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  error?: any;
  disabled?: boolean;
  size?: Size;
  fullWidth?: boolean;
  variant?: Variant;
  margin?: Margin;
  helperText?: any;
  InputLabelProps?: { shrink?: boolean, required?: boolean };
  inputProps?: InputProps;
  inputAttr?: InputAttr;
  required?: boolean;
  sx?: object;
  inputRef?: MutableRefObject<any>;
  autoFocus?: boolean;
  children?: ReactNode;
  onHandleBlur?: (e: any) => void;
  onHandleChange?: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onKeyDownChange?: (event: KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: (event:  React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijInput: FC<CaijInputProps> = ({
  value,
  variant,
  inputRef,
  inputProps,
  inputAttr,
  select,
  children,
  onHandleBlur,
  onHandleChange,
  onKeyDownChange,
  onFocus,
  onClick,
  setFieldValue,
  ...rest
} : CaijInputProps) => {
  if(select){
    return (
      <TextField
        {...rest}
        value={value}
        select
        autoComplete="off"
        variant={variant}
        SelectProps={{
          native: true,
        }}
        inputRef={inputRef}
        onBlur={onHandleBlur}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onHandleChange(event, setFieldValue)}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => onKeyDownChange(event)}
        onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocus(event)}
        onClick={(event: React.MouseEvent<HTMLInputElement>) => onClick(event)}
        InputProps={inputProps}
        inputProps={inputAttr}
      >
        {children}
      </TextField>
    )
  }
  return (
    <TextField
      {...rest}
      autoComplete="off"
      variant={variant}
      fullWidth
      inputRef={inputRef}
      onBlur={onHandleBlur}
      onChange={(event: ChangeEvent<HTMLInputElement>) => onHandleChange(event, setFieldValue)}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => onKeyDownChange(event)}
      onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocus(event)}
      onClick={(event: React.MouseEvent<HTMLInputElement>) => onClick(event)}
      value={value || ''}
      InputProps={inputProps}
      inputProps={inputAttr}
    />
  );
};
CaijInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'hidden']),
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  select: PropTypes.bool,
  sx: PropTypes.object,
  inputRef: PropTypes.any,
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  margin: PropTypes.oneOf(['normal', 'dense', 'none']),
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  inputAttr: PropTypes.object,
  onHandleBlur: PropTypes.func,
  onHandleChange: PropTypes.func,
  onKeyDownChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  setFieldValue: PropTypes.func,
};

CaijInput.defaultProps = {
  type: 'text',
  id: '',
  name: '',
  select: false,
  required: false,
  disabled: false,
  InputLabelProps: { shrink: true, required: false },
  inputProps: { readOnly: false },
  inputAttr:{ autoComplete: 'off' },
  variant: 'outlined',
  margin: 'normal',
  size: 'medium',
  placeholder: '',
  sx:{ marginBottom: 0},
  inputRef:null,
  onHandleBlur: () => {},
  onHandleChange: () => {},
  onKeyDownChange: () => {},
  onFocus:() => {},
  onClick: () => {},
  setFieldValue: () => {},
  helperText: ' '
};

export default CaijInput;
