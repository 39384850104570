import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  useParams,
  fetchLibraryService,
  _libraryService,
  LibraryServiceModel,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import LibraryServiceEditForm from './LibraryServiceEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const LibraryServiceEditView: FC = () => {
  const model = LibraryServiceModel.getInstance()
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_libraryService, { isLoading: false, libraryService: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new LibraryServiceModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const libraryService = await model.getLibraryServiceById(id);
          if (!model.error) {
            dispatch(fetchLibraryService(libraryService, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchLibraryService({}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { libraryService, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = libraryService.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <LibraryServiceEditForm model={model} libraryService={libraryService} />
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryServiceEditView;
