import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { DocumentTypeResource } from 'src/common/types';
import DocumentTypeFrInfo from './DocumentTypeFrInfo';
import DocumentTypeEnInfo from './DocumentTypeEnInfo';
import { DocumentTypeModel } from 'src/common';

interface DetailsProps {
  model: DocumentTypeModel;
  documentType: DocumentTypeResource;
}

const Details: FC<DetailsProps> = ({
  model,
  documentType
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={4}
      md={6}
      xl={4}
      xs={12}
    >
      <DocumentTypeFrInfo model={model} documentType={documentType} />
    </Grid>
    <Grid
      item
      lg={4}
      md={6}
      xl={4}
      xs={12}
    >
      <DocumentTypeEnInfo model={model} documentType={documentType} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  documentType: PropTypes.object.isRequired
};

export default Details;
