import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  _emailTemplate,
  useParams,
  EmailTemplateModel,
  fetchEmailTemplate,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import EmailTemplateEditForm from './EmailTemplateEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const EmailTemplateEditView: FC = () => {
  const model = EmailTemplateModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_emailTemplate, { isLoading: false, emailTemplate: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new EmailTemplateModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if(id) {
          model.PathName = pathName;
          const emailTemplate = await model.getEmailTemplateById(id);
          if (!model.error) {
            dispatch(fetchEmailTemplate(emailTemplate, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchEmailTemplate({}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { emailTemplate, isLoading } = state;

  model.Title = emailTemplate.name;

  if (!isLoading) return <LoadingScreen />;

  return (
    <Page sx={root} title={model.getEditPageTitle(id)}>
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
            <EmailTemplateEditForm 
              model={model}
              emailTemplate={emailTemplate}
            />
        </Box>
      </Container>
    </Page>
  );
};

export default EmailTemplateEditView;
