import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, SvgIcon } from '@mui/material';
import type { CustomerResource, GroupDto, SubscriptionDto } from 'src/common/types';
import { Award as AwardIcon, Edit2 as NoteIcon } from 'react-feather';
import { formatDate, Label, labelConfig, SubscriptionModel } from 'src/common';

export interface SubscriptionInfoProps {
  customer: CustomerResource;
  allowedNote?: boolean;
}

type NoteInfo = {
  model: SubscriptionModel;
  subscription?: SubscriptionDto;
  subscriptionGroup?: GroupDto;
};

function NoteInfo(props: NoteInfo){
  const { subscription, subscriptionGroup, model } = props;
  const title = subscription ? `${model.Note.Label} - Abonnement` : `${model.AccessGroupNote.Label} - Groupe d'accès`;
  const note = subscription ? subscription?.note : subscriptionGroup?.note;
  const getNote = (title: string, note: string) => {
    return (
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <Box alignItems='center' display='flex'>
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
        </Box>
        <Box>
          <Typography variant='body2' sx={{color:'text.secondary', whiteSpace: 'pre-wrap'}}>{note}</Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box display='flex' sx={{mb:2}}>
      <SvgIcon fontSize='small'>
        <NoteIcon />
      </SvgIcon>
      { getNote(title, note) }
    </Box>
  )
};

const SubscriptionInfo: FC<SubscriptionInfoProps> = ({ allowedNote, customer }) => {
  const { subscription, subscriptionGroup, inGroupSince } = customer;
  const subscriptionModel = SubscriptionModel.getInstance();

  return (
    <>
      <Box display='flex' sx={{mb:2}}>
        <SvgIcon fontSize='small'>
          <AwardIcon />
        </SvgIcon>
        <Box display='flex' marginLeft='0.5em' flexDirection='column'>
          <Box alignItems='center' display='flex'>
            <Typography sx={{ fontWeight: 'bold' }}>Abonnement</Typography>
            {
              !subscription.active && (
                <Box sx={{margin:'0 0 0 30px'}}>
                  <Label color={'error'}>
                    {labelConfig.inactive}
                  </Label>
                  {
                    subscription.endDate && (
                      <span style={{paddingLeft: '15px'}}>{formatDate(subscription.endDate)}</span>
                    )
                  }
                </Box>
              )
            }
          </Box>
          <Box mt={2}>
          { subscription && subscription.name && (<Typography>{subscription.name}</Typography>) }
          { subscriptionGroup && (<Typography sx={{mt:1}}>{subscriptionGroup.name}</Typography>) }
          { inGroupSince && (<Typography sx={{mt:1, fontStyle: 'italic'}}>Depuis {formatDate(inGroupSince)}</Typography>) }
          </Box>
        </Box>
      </Box>
      { allowedNote && (
          <>
            {subscription.note && <NoteInfo subscription={subscription} model={subscriptionModel}/>}
            {subscriptionGroup.note && <NoteInfo subscriptionGroup={subscriptionGroup} model={subscriptionModel} />}
          </>
        )
      }
    </>
  );
};

SubscriptionInfo.propTypes = {
  customer: PropTypes.object.isRequired
};

export default SubscriptionInfo;
