import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {$enum} from "ts-enum-util";
import { Box,TableBody } from '@mui/material';
import {
  labelConfig,
  EmptyList,
  AccessType,
  ProductModel,
  getEmptyListSearch,
  CaijInput,
  getOpacity,
  NavigateFunction
} from 'src/common';
import type { ProductResource, SearchFilter } from 'src/common/types';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { filters } from 'src/styles/product';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  products: ProductResource[];
  isEmptyList: boolean;
  model?: ProductModel;
  onDeleteProduct: (id: number) => Promise<void>;
  navigate: NavigateFunction;
}

const Results: FC<ResultsProps> = ({
  products,
  isEmptyList,
  model,
  onDeleteProduct,
  navigate
}) => {
  const path = model.Path;
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const headCell = model.getHeadCells(labelConfig.accessType, labelConfig.status);
  const [filter, setFilter] = useState<SearchFilter>({
    status: '', 
    accessType: '',
    doSearch: false,
    page
  });
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();

  useEffect(() => setOrderBy('nameFr'),[]);

  useEffect(() => {
    const { Filters: { status, accessType, page } } = model;
    const statusVal = model.getQueryParam(status);
    const accessTypeVal = model.getQueryParam(accessType);
    const pageVal = +model.getQueryParam(page);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        status: statusVal,
        accessType: accessTypeVal,
        doSearch: statusVal || accessTypeVal ? true : false,
        page: pageVal
      }
    });
    setPage(pageVal);
  },[model]);

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>) => {
    model.resetPageValue(filter);
    model.getConfigParameters(filter,3,+e.target.value);
    navigate(model.getUrlEncode.apply(model, model.getParams()));
  };

  const handleAccessTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    model.resetPageValue(filter);
    model.getConfigParameters(filter,9,e.target.value)
    navigate(model.getUrlEncode.apply(model,model.getParams()));
  };

  const renderTableCell = (row: ProductResource) => {
    const opacity = getOpacity(row.active);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.code}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.nameFr}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{ProductModel.getAccessTypeByKey(row.access)}</CaijTableCell>
        <CaijTableCellActive active={row.active} />
        <CaijTableCellAction
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          handleToggle={() => ProductModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.id) !== -1}
            onSubmit={async () => {
              await onDeleteProduct(row.id)
              setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
        </CaijTableCellAction>
      </>
    )
  }

  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      <Box sx={{margin:'20px 15px 0 0'}} minHeight={56} display='flex' alignItems='center' justifyContent='right'>
          <CaijResetFilterButton path={path.Home} navigate={navigate} />
          <Box>
            <CaijInput
              label="Type d'accès"
              name='accessType'
              id='accessType'
              onHandleChange={handleAccessTypeChange}
              select
              value={filter.accessType}
              sx={filters}
              InputLabelProps={{ shrink: true }}
            >
              <option key='0' value=''>Tous</option>
              {
                $enum(AccessType).map((value, key) => <option key={key} value={key}>{value}</option>)
              }
            </CaijInput>
          </Box>
          <Box>
            <CaijInput
              label='Statut'
              name='status'
              id='status'
              onHandleChange={handleStatusChange}
              select
              value={filter.status}
              sx={filters}
              InputLabelProps={{ shrink: true }}
            >
              <option key='0' value=''>
                Tous
              </option>
              <option key='1' value={1}>
                {labelConfig.active}
              </option>
              <option key='2' value={2}>
                {labelConfig.inactive}
              </option>
            </CaijInput>
          </Box>
      </Box>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCell}
        />
        <TableBody>
          { products.length === 0 ? getEmptyListSearch(headCell.length) :
            sort<ProductResource>(products, page).map((row: ProductResource) => (
              <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                {renderTableCell(row)}
              </CaijTableRowClickable>
            ))
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={{totalCount: products.length}} 
        model={model} 
        filter={filter} 
        page={page}
        navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
      />
    </MyCard>
  );
};

//
// TODO: utiliser Typescript au lieu des PropTypes
//
Results.propTypes = {
  products: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  onDeleteProduct:  PropTypes.func.isRequired,
  model: PropTypes.instanceOf(ProductModel)
};

export default Results;
