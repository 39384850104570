import { createRef, useCallback } from 'react';
import type { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { SvgIconComponent } from '@mui/icons-material';
import printMessage from 'src/views/errors/MessageError';
import Dropzone from 'react-dropzone';
import { BadRequest } from 'src/views/errors/ErrorTypes';

interface FileUploaderProps {
    extensionAccepted: string[];
    multiple?: boolean;
    startIcon?: SvgIconComponent;
    maxFileSize?: number;
    style?: {};
    btnText: string;
    onUpload: any;
    disabled?: boolean;
    onHandleChangeImageUrl: (imgFieldName: string, imageUrl: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const root = {
    padding: '10px 0',
    borderStyle: 'dotted',
    borderWidth: 'solid',
    textAlign: 'center',
    borderColor: 'rgba(60, 60, 60, 0.5)'
};

const FileUploader: FC<FileUploaderProps> = ({
    btnText,
    extensionAccepted,
    maxFileSize = 5000000,
    disabled = false,
    multiple,
    startIcon,
    style,
    onUpload,
    setFieldValue,
    ...rest
}) => {
    const dropzoneRef = createRef<any>();
    
    const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onabort = () => printMessage({ status: BadRequest, message: 'la lecture du fichier a été abandonnée' })
        reader.onerror = () => printMessage({ status: BadRequest, message: 'la lecture du fichier a échoué' })
        reader.onload = async () => {
            const formData = new FormData();
            formData.append('file', file, file.name);
            onUpload(formData);
        }
        reader.readAsArrayBuffer(file);
    })
    }, [])

    return (
        <Dropzone 
            {...rest}
            multiple={multiple}
            accept={extensionAccepted} 
            ref={dropzoneRef} 
            maxSize={maxFileSize}
            noClick
            noDrag={disabled}
            onDrop={onDrop}>
            {({getRootProps, getInputProps}) => {
            return (
            <Typography color='text.primary' sx={root}>
                <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Typography color='text.primary'>Glisser-déposer le fichier ici</Typography>
                    <Typography color='text.primary' sx={{marginBottom: '10px'}}>{`Fichiers autorisés : ${extensionAccepted.join(',')}`}</Typography>
                    <Button 
                        style={style}
                        variant='contained'
                        color='primary'
                        startIcon={startIcon ? <>startIcon</>: <UploadFileIcon />}
                        disabled={disabled}
                        onClick={() => {
                            if (dropzoneRef.current) {
                                dropzoneRef.current.open()
                            }
                        }}
                    >
                    { btnText }
                    </Button>
                </Box>
            </Typography>
            );
        }}
        </Dropzone>
    )
};

export default FileUploader;
