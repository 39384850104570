import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { EmailTemplateDto } from 'src/common/types';
import EmailTemplateFrInfo from './EmailTemplateFrInfo';
import EmailTemplateEnInfo from './EmailTemplateEnInfo';
import { EmailTemplateModel } from 'src/common';
import Note from 'src/components/note';

interface DetailsProps {
  model: EmailTemplateModel;
  emailTemplate: EmailTemplateDto;
}

const Details: FC<DetailsProps> = ({
  model,
  emailTemplate
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={12}
      md={12}
      xl={12}
      xs={12}
    >
      <Note label={model.Note.Label} note={emailTemplate.note} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <EmailTemplateFrInfo model={model} emailTemplate={emailTemplate} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <EmailTemplateEnInfo model={model} emailTemplate={emailTemplate} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  emailTemplate: PropTypes.object.isRequired
};

export default Details;
