import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { CaijTextarea, DatabankModel } from 'src/common';
import type { DatabankResource, HandleChange } from 'src/common/types';

export interface DatabankStatusFormProps {
  model: DatabankModel;
  databank: DatabankResource;
  onHandleChange: HandleChange;
}

const DatabankStatusForm: FC<DatabankStatusFormProps> = ({
  model,
  databank,
  onHandleChange
}) => {
  const { unavailableMessage } = databank;
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <CaijTextarea 
          id={model.UnavailableMessageFr.Name}
          name={model.UnavailableMessageFr.Name}
          rows="4" 
          label={model.UnavailableMessageFr.Label}
          value={(unavailableMessage?.fr || "")}
          inputProps={{ 'data-testid': model.UnavailableMessageFr.Name}}
          onHandleChange={onHandleChange}
        />
        
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijTextarea 
          id={model.UnavailableMessageEn.Name}
          name={model.UnavailableMessageEn.Name}
          rows="4" 
          label={model.UnavailableMessageEn.Label} 
          value={(unavailableMessage?.en || "")}
          inputProps={{ 'data-testid': model.UnavailableMessageEn.Name}}
          onHandleChange={onHandleChange}
        />
      </Grid>
    </Grid>
  )
};

DatabankStatusForm.propTypes = {
  databank: PropTypes.object.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default DatabankStatusForm;
