import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { PartnerResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  Link
} from '@mui/material';
import { PartnersModel, link, tableRow } from 'src/common';
import { tableCell } from 'src/styles/contentPublishers';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow, CaijTableRowImage } from 'src/components/table/CaijTableRow';

export interface PartnersEnInfoProps {
  model: PartnersModel;
  partner: PartnerResource;
}

const PartnersEnInfo: FC<PartnersEnInfoProps> = ({
  model,
  partner
}) => {
  const { nameEn, logoUrlEn, homeUrlEn, contentUrlEn } = partner;
  const { NameEn, HomeUrlEn, ContentUrlEn, LogoUrlEn } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Partner" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableCell={tableCell} label={NameEn.Label}>
            {nameEn}
          </CaijTableRow>
          <CaijTableRowImage sxTableCell={tableCell} label={LogoUrlEn.Label} logoUrl={logoUrlEn} />
          <CaijTableRow sxTableCell={tableCell} label={HomeUrlEn.Label}>
            { homeUrlEn && (
                <Link
                  underline="none"
                  href={homeUrlEn}
                  sx={{ ...link, color: 'text.secondary' }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {homeUrlEn}
                </Link>
              )
            }
          </CaijTableRow>
          <CaijTableRow sxTableRow={tableRow} sxTableCell={tableCell} label={ContentUrlEn.Label}>
            { contentUrlEn && (
                <Link
                  underline="none"
                  href={contentUrlEn}
                  sx={{ ...link, color: 'text.secondary' }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {contentUrlEn}
                </Link>
              )
            }
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

PartnersEnInfo.propTypes = {
  partner: PropTypes.object.isRequired
};

export default PartnersEnInfo;
