import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  Resource, 
  Error, 
  CreateResponse,
  ResourceForCreate, 
  ResourceForEdit
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';

type TypeResourceEdit = ResourceForCreate | ResourceForEdit;

export default class ResourceModel extends AppModel 
{
  private static _instance: ResourceModel;
  readonly Name = new FormModel('name','Nom',45);
  readonly Code = new FormModel('code','Code',45);
  readonly Description = new FormModel('description','Description');

  constructor(){
    super('/employee/resource');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'RESOURCE';
    this._headerTitle = 'Liste des resources';
    this._btnAddText = 'Ajouter une Ressource';
    this.Path.PathName = '/resource';

    //Dialog
    this.Dialog.Header = "Supprimer la resource";
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = "la resource";
  }
  
  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Permisions',
      href: this.Path.Home,
      visible: false
    }
  }
  
  static getInstance(): ResourceModel {
    if (!ResourceModel._instance) {
      ResourceModel._instance = new ResourceModel();
    }
    return ResourceModel._instance;
  }

  static getInitialValues(values: Resource) : Resource {
    return {
      id: values.id,
      name: values.name || '',
      code: values.code || '',
      description: values.description || ''
    }
  }

  async getResources(): Promise<Resource[]> {
    let resource: Resource[];
    await axios.get<Resource[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            resource = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return resource;
  }

  async getResourceById(id: string): Promise<Resource> {
    let resource: Resource;
    await axios.get<Resource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            resource = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return resource;
  }

  async updateResource(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<ResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Ressource modifié.'
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertResource(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<ResourceForCreate, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Ressource créé.'
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async deleteResource(id: number): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Ressource supprimé.'
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async getRefreshResource(id: string): Promise<Resource> {
    let newResource : Resource;
    const resource = await this.getResourceById(id);
    if(!this.error){
      newResource = resource;
    }
    return newResource;
  }
}
