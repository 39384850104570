import type { LawPracticeFieldResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchLawPracticeFieldsAction = {
  type: 'FETCH_LAW_PRATICE_FIELDS';
  payload: {
    lawPracticeFields: LawPracticeFieldResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

type FetchLawPracticeFielAction = {
  type: 'FETCH_LAW_PRATICE_FIELD';
  payload: {
    lawPracticeField: LawPracticeFieldResource;
    isLoading?: boolean;
  };
};

type FetchDetailsLawPracticeFielAction = {
  type: 'FETCH_DETAILS_LAW_PRATICE_FIELD';
  payload: {
    lawPracticeField: LawPracticeFieldResource;
  };
};

type DeleteLawPracticeFielAction = {
  type: 'DELETE_LAW_PRATICE_FIELD';
  payload: {
    id: number;
  };
};

type Action = FetchLawPracticeFieldsAction |
              FetchLawPracticeFielAction |
              FetchDetailsLawPracticeFielAction |
              DeleteLawPracticeFielAction;

export interface LawPracticeFieldReturnState {
  lawPracticeFields?: LawPracticeFieldResource[];
  lawPracticeField?: LawPracticeFieldResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface LawPracticeFieldState {
  lawPracticeFields: LawPracticeFieldResource[];
  lawPracticeField: LawPracticeFieldResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: LawPracticeFieldState, action: Action): LawPracticeFieldReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_LAW_PRATICE_FIELDS: {
      const { lawPracticeFields, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        lawPracticeFields,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_LAW_PRATICE_FIELD: {
      const { lawPracticeField, isLoading } = action.payload;
      return {
        ...state,
        lawPracticeField,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_LAW_PRATICE_FIELD: {
      const { lawPracticeField } = action.payload;
      return {
        ...state,
        lawPracticeField
      };
    }
    case actionTypes.DELETE_LAW_PRATICE_FIELD: {
      return {
        ...state,
        lawPracticeFields: [...state.lawPracticeFields].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
