import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  DocumentCollectionsResource,
  DocumentCollectionsResourceForEdit
} from 'src/common/types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  _docCollection,
  Mode,
  btnSubmit,
  handleChangeCheckBox,
  DocCollectionsModel
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';
import DocCollectionsForm from 'src/components/content/docCollections/Forms';

export interface DetailsEditFormProps {
  model: DocCollectionsModel;
  collection: DocumentCollectionsResource;
  reloadCollection: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: DocumentCollectionsResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  collection,
  reloadCollection,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  
  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values:DocumentCollectionsResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const submitData = values as DocumentCollectionsResourceForEdit;
    if (submitData && submitData.id) {
      const model = new DocCollectionsModel();
      const response = await model.updateCollectionDocument(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await reloadCollection();
      }
    }
  };

  return (
    <Formik
      initialValues={DocCollectionsModel.getInitialValues(collection)}
      validationSchema={validateSchema(model)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <DocCollectionsForm
                  model={model}
                  collection={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                />
              </Grid>
              <Box mt={2}>
                <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  collection: PropTypes.object.isRequired,
  reloadCollection: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
