import React from 'react';
import type { FC } from 'react';
import { Box, Button, SvgIcon } from '@mui/material';
import { CheckSquare as CheckIcon } from 'react-feather';
import { NavigateFunction } from 'react-router';

interface CaijResetFilterButtonProps {
  path: string;
  navigate: NavigateFunction;
  sx?: any;
  extraParam?: string;
  callBack?: () => void;
}

const CaijResetFilterButton: FC<CaijResetFilterButtonProps> = ({
  path,
  navigate,
  sx,
  extraParam,
  callBack
}) => (
  <Box sx={{minWidth: '217px', height: "56", marginBottom: '20px', ...sx}}>
    <Button
      startIcon={
        <SvgIcon>
          <CheckIcon />
        </SvgIcon>
      }
      onClick={() => {
        navigate(`${path}${extraParam || ''}`, { replace: true });
        if(callBack) callBack();
      }}
    >
      RÉINITIALISER LES FILTRES
    </Button>
  </Box>
);

export default CaijResetFilterButton;
