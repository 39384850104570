import { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { ProductResource } from 'src/common/types';
import { Grid, Box, Typography } from '@mui/material';
import {
  CaijCheckbox,
  CaijTextarea,
  CaijInput,
  FormikErrors,
  FormikTouched,
  ProductModel
} from 'src/common';
import CaijDialogs from 'src/components/dialog';
import { BtnOk } from 'src/components/dialog/CaijDialog';

interface ProductFormProps {
  model: ProductModel;
  product: ProductResource;
  errors: FormikErrors<ProductResource>;
  touched: FormikTouched<ProductResource>;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: (e: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeCheckBox: (e: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ProductForm: FC<ProductFormProps> = ({
  model,
  product,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  setFieldValue,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { id, nameFr, nameEn, code, descriptionFr, descriptionEn, parametersRequired, active } = product;
  const { Code, NameFr, NameEn, DescriptionFr, DescriptionEn, Active, ParametersRequired } = model;
  model.Dialog.Header = 'Désactiver le produit et service';
  model.Dialog.BtnText = BtnOk.DISABLED;
  model.Dialog.Name = 'le produit et service';
  
  return (
    <>
       <Grid item md={6} xs={12}>
        <CaijInput
          required
          name={NameFr.Name}
          id={NameFr.Name}
          value={nameFr}
          helperText={touched.nameFr && errors.nameFr}
          error={Boolean(touched.nameFr && errors.nameFr)}
          label={NameFr.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          required
          name={NameEn.Name}
          id={NameEn.Name}
          value={nameEn}
          label={NameEn.Label}
          helperText={touched.nameEn && errors.nameEn}
          error={Boolean(touched.nameEn && errors.nameEn)}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: NameEn.MaxLength, 'data-testid': NameEn.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          required
          name={Code.Name}
          id={Code.Name}
          margin="none"
          value={code}
          label={Code.Label}
          helperText={touched.code && errors.code}
          error={Boolean(touched.code && errors.code)}
          InputLabelProps={{ shrink: true, required: true }}
          disabled={id > 0}
          inputAttr={{ maxLength: Code.MaxLength, 'data-testid': Code.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={(e: ChangeEvent<HTMLInputElement>) => {
            const code = e.target.value;
            if(!ProductModel.verifyWord(code)){
              const codes = Object.assign([], code);
              const newCodes = codes.filter((c: string) => ProductModel.verifyWord(c));
              setFieldValue('code', newCodes.join('').toUpperCase());
            }else{
              setFieldValue('code', code.toUpperCase());
            }
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
          <CaijCheckbox
            name={Active.Name}
            label={Active.Label}
            checked={active}
            value={active}
            inputProps={{'data-testid': Active.Name}}
            onHandleChangeCheckBox={(e:ChangeEvent<HTMLInputElement>) => {
              e.target.checked ?  setOpenDialog(false) : setOpenDialog(true);
              onHandleChangeCheckBox(e, setFieldValue);
            }}
          />
          {
            (!active && !openDialog) && (
              <Box>
                <Typography sx={{color:"text.error"}} variant="body2">
                  Le produit ou service ne sera pas disponible pour les membres
                </Typography>
              </Box>
            )
          }
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={openDialog}
            onSubmit={() => (async () => {
              setFieldValue('active', false);
              setOpenDialog(false);
            })()}
            close={() => { 
              !active ? setFieldValue('active', true) : '';
              setOpenDialog(false);
            }}
          />
       </Grid>
      <Grid item md={6} xs={12}>
          <CaijTextarea
            name={DescriptionFr.Name}
            id={DescriptionFr.Name}
            rows="4"
            margin="none"
            label={DescriptionFr.Label}
            value={descriptionFr}
            inputProps={{ 'data-testid': DescriptionFr.Name }}
            InputLabelProps={{ shrink: true }}
            onHandleBlur={handleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
           <CaijCheckbox
            name={ParametersRequired.Name}
            label={ParametersRequired.Label}
            checked={parametersRequired}
            value={parametersRequired}
            inputProps={{'data-testid': ParametersRequired.Name}}
            onHandleChangeCheckBox={onHandleChangeCheckBox}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CaijTextarea
            name={DescriptionEn.Name}
            id={DescriptionEn.Name}
            rows="4"
            margin="none"
            label={DescriptionEn.Label}
            value={descriptionEn}
            inputProps={{ 'data-testid': DescriptionEn.Name }}
            InputLabelProps={{ shrink: true }}
            onHandleBlur={handleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
    </>
  );
};

ProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func.isRequired
};

export default ProductForm;
