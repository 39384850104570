import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { Resource } from 'src/common/types';
import {
  Box,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import {
  Mode,
  CaijButtonDeleteOtherAction,
  AccessModel
} from 'src/common';
import ResourceModel from 'src/model/employee/Resource';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import CaijDialogs from 'src/components/dialog';

interface OtherActionsProps {
  resource: Resource;
}

const OtherActions: FC<OtherActionsProps> = ({
  resource
}) => {
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const model = new ResourceModel();
  const access = new AccessModel(model.ResourceCode);

  const deleteResource: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      const model = new ResourceModel();
      const response = await model.deleteResource(id);
      if (!model.error) {
        await model.redirect(model.Path.Home,{
          status: response.status,
          message: response.message
        });
      }
    }
  };

  return (
    <MyCard>
      <CardHeaderDetail title="Autres actions" />
      <Divider />
      <CardContent>
        <Box
          mt={1}
          mb={2}
        >
          <Typography
            variant="body2"
            sx={{color:"text.secondary"}}
          >
            Supprimez les données de cette ressource s'il l'a demandé, sinon veuillez
            sachez que ce qui a été supprimé ne peut jamais être récupéré
          </Typography>
        </Box>
        <Authorize
          resourceCode={model.ResourceCode}
          mode={Mode.delete}
          onIsAuth={(l) => setIsDeleteAuth(l)}
        >
          <CaijButtonDeleteOtherAction disabled={!isDeleteAuth} onDelete={() => deleteResource(resource.id)} >
            Supprimer Resource
          </CaijButtonDeleteOtherAction>
        </Authorize>
        <CaijDialogs 
          dialog={model.Dialog} 
          isOpen={openDialog}
          onSubmit={() =>deleteResource(resource.id)}
          close={() => setOpenDialog(false)}
        />
      </CardContent>
    </MyCard>
  );
};

OtherActions.propTypes = {
  resource: PropTypes.object.isRequired
};

export default OtherActions;
