import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  fetchTribunalTypes,
  _tribunalType,
  TribunalTypeModel,
  AccessModel,
  deleteTribunalType,
  root,
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  tribunalTypes: [],
  isLoading: false,
  isEmptyList: true
};

const TribunalTypeListView: FC = () => {
  const model = TribunalTypeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_tribunalType, initialState);

  const getTribunalTypes = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new TribunalTypeModel();
      model.PathName = location.pathname;
      const tribunalTypes = await model.getTribunalTypes();
      if (!model.error) {
        dispatch(fetchTribunalTypes(tribunalTypes, true, !Array.isArray(tribunalTypes) || tribunalTypes.length === 0, true));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getTribunalTypes();
    })();
  },[]);

  const handleDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteTribunalType(id));
    }
  };

  const { tribunalTypes, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            tribunalTypes={tribunalTypes}
            onDeleteTribunalType={handleDelete}
            isEmptyList={isEmptyList}
          /> 
        </Box>
      </Container>
    </Page>
  );
};

export default TribunalTypeListView;
