import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentResource } from 'src/common/types';
import { Box, SvgIcon } from '@mui/material';
import { DocumentModel, labelConfig } from 'src/common';
import { FilePlus as FileIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import CaijLabel from 'src/components/label/CaijLabel';
import SpecialFilesModel from 'src/model/content/SpecialFiles';
import parse from 'html-react-parser';

export interface GeneralInfoProps {
  document: DocumentResource;
  model: SpecialFilesModel;
}

const GeneralInfo: FC<GeneralInfoProps> = ({ model, document }) => {
  const { documentId, title, identifier, documentType, filepath, lang, otherLangDocId, metadatas } = document;
  return (
    <Box sx={{ mb: '2em' }} display='flex'>
      <SvgIcon fontSize='small'>
        <FileIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <CaijTypography sx={{ fontWeight: 'bold' }}>Informations générale</CaijTypography>
        {documentId && <CaijLabel label={model.DocumentId}>{documentId}</CaijLabel>}
        {title && <CaijLabel label={model.Titre.Label}>{title.trim()}</CaijLabel>}
        {identifier && <CaijLabel label={model.Identifier.Label}>{identifier}</CaijLabel>}
        {filepath && <CaijLabel label={model.Filepath.Label}>{filepath}</CaijLabel>}
        {lang && <CaijLabel label={labelConfig.docLanguage}>{DocumentModel.getDocLanguageByKey(lang)}</CaijLabel>}
        {metadatas && <CaijLabel label={labelConfig.description}>{parse(JSON.parse(metadatas).description)}</CaijLabel>}
      </Box>
    </Box>
  );
};

GeneralInfo.propTypes = {
  document: PropTypes.object.isRequired
};

export default GeneralInfo;