import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, Typography, Box } from '@mui/material';
import { getAmount } from 'src/common';
import { DollarSign as FeeIcon } from 'react-feather';
import CaijLabel from 'src/components/label/CaijLabel';

export interface LibraryFeeInfoProps {
  libraryFees: number;
};

const LibraryFeeInfo: FC<LibraryFeeInfoProps> = ({libraryFees}) => (
  <Box sx={{mb:'1.5em'}} display='flex'>
    <SvgIcon fontSize='small'>
      <FeeIcon />
    </SvgIcon>
    <Box display='flex' marginLeft='0.5em' flexDirection='column'>
      <Box display='flex' alignItems='center'>
        <Typography sx={{ fontWeight: 'bold' }}>Frais</Typography>
      </Box>
      <CaijLabel label="Frais de bibliothèque">{getAmount(libraryFees)}$</CaijLabel>
    </Box>
  </Box>
);

LibraryFeeInfo.propTypes = {
  libraryFees: PropTypes.number.isRequired
};

export default LibraryFeeInfo;
