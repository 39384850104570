import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  fetchLibraryServices,
  _libraryService,
  LibraryServiceModel,
  AccessModel,
  deleteLibraryService,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  libraryServices: [],
  isLoading: false,
  isEmptyList: true,
};

const LibraryServiceListView: FC = () => {
  const model = LibraryServiceModel.getInstance()
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_libraryService, initialState);

  const getLibraryServices = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const libraryServiceMdel = new LibraryServiceModel();
      libraryServiceMdel.PathName = location.pathname;
      const libraryServices = await libraryServiceMdel.getLibraryServices();
      if (!libraryServiceMdel.error){
        dispatch(fetchLibraryServices(libraryServices, true, !Array.isArray(libraryServices) || libraryServices.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getLibraryServices();
    })();
  },[]);

  const handleDelete : (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteLibraryService(id));
    }
  };

  const { libraryServices, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            libraryServices={libraryServices}
            onDeleteLibraryService={handleDelete}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryServiceListView;
