import { useEffect, useReducer } from 'react';
import {
	AccessPermissions,
	DatabankModel,
	DocCollectionModel,
	fetchDatabanks,
	fetchDocCollections,
	fetchLibraries,
	fetchSubscriptions,
	Forbidden,
	LibraryModel,
	ListType,
	SubscriptionModel,
	Unauthorized,
	_databank,
	_docCollection,
	_library,
	_libraryService,
	_product,
	_subscription,
} from 'src/common';
import { 
	DatabankResource, 
	DocumentCollectionResource, 
	DocumentTypeResource, 
	LibraryResource, 
	SubscriptionResource 
} from 'src/common/types';
import useDocumentTypes from 'src/functions/hooks/documentType';
import { 
	FETCH_DATABANKS, 
	FETCH_DOCUMENT_COLLECTIONS, 
	FETCH_LIBRARIES, 
	FETCH_SUBSCRIPTIONS 
} from 'src/store/actions/ActionTypes';

interface Data {
	isEmptyList?: boolean;
	isLoading?: boolean;
	isAuthorize?: boolean;
}

export interface Subscription extends Data {
	subscriptions: SubscriptionResource[];
};

interface Collection extends Data {
	docCollections: DocumentCollectionResource[];
};

interface Databank extends Data {
	databanks: DatabankResource[];
};

interface Library extends Data {
	libraries?: LibraryResource[];
};

interface DocumentType extends Data {
	documentTypes: DocumentTypeResource[];
}

enum List {
	list,
	checkList,
}

const initialOthersState = {
  isLoading: false,
	isEmptyList: true,
	isAuthorize: false,
};

function useCollections(section: List): Collection {
	const initialState = {
		docCollections: [],
		isEmptyList: true,
		isLoading: false,
		isAuthorize: false,
	};
	const [state, dispatch] = useReducer(_docCollection, initialState);
	useEffect(() => {
		const initialise = async () : Promise<void> => {
			const model = new DocCollectionModel();
			model.skipHandleError = true;
			const collections = await model.getDocCollections();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchDocCollections(collections, true, false, false));
				} else {
					dispatch(fetchDocCollections(collections, true, true, true));
				}
			} else {
				if (section === List.list) {
					const otherAccessTypeCollection = collections.filter(
						(collection: DocumentCollectionResource) =>
							collection.access === AccessPermissions.Authentication || collection.access === AccessPermissions.Public,
					);
					dispatch(
						fetchDocCollections(
							otherAccessTypeCollection,
							true,
							!Array.isArray(otherAccessTypeCollection) || otherAccessTypeCollection.length === 0,
							true,
						),
					);
				} else if (section === List.checkList) {
					const accessCollections = collections.filter(
						(collection: DocumentCollectionResource) => collection.access === AccessPermissions.Private,
					);
					dispatch(
						fetchDocCollections(
							accessCollections,
							true,
							!Array.isArray(accessCollections) || accessCollections.length === 0,
							true,
						),
					);
				}
			}
		};
		(async () => {
      await initialise();
    })();
		return () => {
			dispatch({ type: FETCH_DOCUMENT_COLLECTIONS, payload: initialState });
		};
	}, []);
	const { docCollections, isLoading, isEmptyList, isAuthorize } = state;
	return { docCollections, isLoading, isEmptyList, isAuthorize };
}

function useDatabanks(): Databank {
	const initialState = {
		databanks: [],
		isEmptyList: true,
		isLoading: false,
		isAuthorize: false,
	};
	const [state, dispatch] = useReducer(_databank, initialState);
	useEffect(() => {
		const initialise = async () : Promise<void> => {
			const model = new DatabankModel();
			model.skipHandleError = true;
			const databanks = await model.getDatabanks();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchDatabanks(databanks, true, false, false));
				} else {
					dispatch(fetchDatabanks(databanks, true, true, true));
				}
			} else {
				dispatch(fetchDatabanks(databanks, true, !Array.isArray(databanks) || databanks.length === 0, true));
			}
		};
		(async () => {
      await initialise();
    })();
		return () => {
			dispatch({ type: FETCH_DATABANKS, payload: initialState });
		};
	}, []);
	const { databanks, isLoading, isEmptyList, isAuthorize } = state;
	return { databanks, isLoading, isEmptyList, isAuthorize };
}

export function useSubscriptions(): Subscription {
	const initialState = {
		subscriptions: [],
		isLoading: false,
		isEmptyList: true,
		isAuthorize: true,
	};
	const [state, dispatch] = useReducer(_subscription, initialState);
	useEffect(() => {
		const initialise = async () : Promise<void> => {
			const model = new SubscriptionModel();
			model.skipHandleError = true;
			const subscriptions = await model.getSubscriptions();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchSubscriptions(subscriptions, true, false, false));
				} else {
					dispatch(fetchSubscriptions(subscriptions, true, true, true));
				}
			} else {
				dispatch(
					fetchSubscriptions(
						subscriptions,
						true,
						!Array.isArray(subscriptions) || subscriptions.length === 0,
						true,
					),
				);
			}
		};
		(async () => {
      await initialise();
    })();
		return () => {
			dispatch({ type: FETCH_SUBSCRIPTIONS, payload: initialState });
		};
	}, []);
	const { subscriptions, isLoading, isEmptyList, isAuthorize } = state;
	return { subscriptions, isLoading, isEmptyList, isAuthorize };
}

export function useLibraries(): Library {
	const initialState = {
		...initialOthersState,
		libraries: []
	};
	const [state, dispatch] = useReducer(_library, initialState);
	useEffect(() => {
		const initialise = async () : Promise<void> => {
			const model = new LibraryModel();
			model.skipHandleError = true;
			const libraries = await model.getLibraries();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchLibraries(libraries, true, false, false));
				} else {
					dispatch(fetchLibraries(libraries, true, true, true));
				}
			} else {
				dispatch(fetchLibraries(libraries, true, !Array.isArray(libraries) || libraries.length === 0, true));
			}
		};
		(async () => {
      await initialise();
    })();
		return () => {
			dispatch({ type: FETCH_LIBRARIES, payload: initialState });
		};
	}, []);
	return { ...state };
}

//#region Lists
interface UseLists {
	sCollection: Collection;
	sDatabank: Databank;
}

export function useLists(listType: ListType) {
	let lists: UseLists = {
		sCollection: null,
		sDatabank: null
	};
	if (listType === ListType.Collection || listType === ListType.CustomerCollection) {
		lists.sCollection = useCollections(List.list);
	} else if (listType === ListType.Databank || listType === ListType.CustomerDatabank) {
		lists.sDatabank = useDatabanks();
	}
	return lists;
}
//#endregion Lists

//#region checkList
interface UseCheckLists {
	sCollection: Collection;
	sSubscription: Subscription;
	sDatabankLibrary: Databank;
	sLibrary: Library;
	sDocumentType: DocumentType;
}

export function useCheckLists(listType: ListType) {
	let lists: UseCheckLists = {
		sCollection: null,
		sSubscription: null,
		sDatabankLibrary: null,
		sLibrary: null,
		sDocumentType: null
	};
	if (listType === ListType.Collection) {
		lists.sCollection = useCollections(List.checkList);
	} else if (listType === ListType.Subscription || listType === ListType.CollectionSubscription) {
		lists.sSubscription = useSubscriptions();
	} else if (listType === ListType.DatabankLibrary) {
		lists.sDatabankLibrary = useDatabanks();
	} else if(listType === ListType.LibraryServiceLibrary) {
		lists.sLibrary = useLibraries();
	}else if(listType === ListType.DocumentType){
		lists.sDocumentType = useDocumentTypes();
	}
	return lists;
}
//#endregion CheckList
