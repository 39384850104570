import { useEffect, useReducer } from "react";
import { EmployeeModel, fetchEmployees, Forbidden, Unauthorized, _employee } from "src/common";
import { EmployeesResource } from "src/common/types";
import { FETCH_EMPLOYEES } from "src/store/actions/ActionTypes";

export interface Employees {
  isLoading: boolean;
	isEmptyList: boolean;
	isAuthorize: boolean;
	employees: EmployeesResource[];
}

const initialOthersState = {
  isLoading: false,
	isEmptyList: true,
	isAuthorize: false,
};

export function useEmployees() : Employees {
	const initialState = {
		...initialOthersState,
		employees: []
	};
	const [state, dispatch] = useReducer(_employee, initialState);
	useEffect(() => {
		const initialise = async () => {
			const model = new EmployeeModel();
			model.skipHandleError = true;
			const employees = await model.getEmployees();
			const { error } = model;
			if (error) {
				if (error.status === Forbidden || error.status === Unauthorized) {
					dispatch(fetchEmployees(employees, true, false, false));
				} else {
					dispatch(fetchEmployees(employees, true, true, true));
				}
			} else {
				dispatch(fetchEmployees(employees, true, !Array.isArray(employees) || employees.length === 0, true));
			}
		};
		initialise();
		return () => {
			dispatch({ type: FETCH_EMPLOYEES, payload: initialState });
		};
	}, []);
	const { employees, isLoading, isEmptyList, isAuthorize } = state;
	return { employees, isLoading, isEmptyList, isAuthorize };
}
