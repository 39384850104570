import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { LibraryServiceResource } from 'src/common/types';
import { Grid } from '@mui/material';
import { 
  CaijCheckbox, 
  CaijInput, 
  FormikErrors, 
  FormikTouched, 
  LibraryServiceModel 
} from 'src/common';

interface LibraryServiceFormProps {
  allowedRequireQty?: boolean;
  model: LibraryServiceModel;
  libraryService: LibraryServiceResource;
  errors: FormikErrors<LibraryServiceResource>;
  touched: FormikTouched<LibraryServiceResource>;
  handleBlur: (e: any) => void;
  onHandleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeCheckBox: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const LibraryServiceForm: FC<LibraryServiceFormProps> = ({
  allowedRequireQty,
  model,
  libraryService,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  setFieldValue,
}) => {
  const { 
    nameFr, nameEn, detailsFr, detailsEn, requireParameters
  } = libraryService;
  const { NameFr, NameEn, DetailsFr, DetailsEn, RequiredParameter } = model;

  return (
    <>
     <Grid item md={6} xs={12}>
        <CaijInput
          name={NameFr.Name}
          id={NameFr.Name}
          required
          value={nameFr}
          error={Boolean(touched.nameFr && errors.nameFr)}
          helperText={touched.nameFr && errors.nameFr}
          label={NameFr.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
        <CaijInput
          name={DetailsFr.Name}
          id={DetailsFr.Name}
          value={detailsFr}
          error={Boolean(touched.detailsFr && errors.detailsFr)}
          helperText={touched.detailsFr && errors.detailsFr}
          label={DetailsFr.Label}
          InputLabelProps={{ shrink: true }}
          inputAttr={{maxLength: DetailsFr.MaxLength,'data-testid': DetailsFr.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
        { allowedRequireQty && (
            <CaijCheckbox
              name={RequiredParameter.Name}
              value={requireParameters}
              checked={requireParameters}
              label={RequiredParameter.Label}
              inputProps={{'data-testid': RequiredParameter.Name}}
              onHandleChangeCheckBox={onHandleChangeCheckBox}
              setFieldValue={setFieldValue}
            />
          )
        }
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <CaijInput
          name={NameEn.Name}
          id={NameEn.Name}
          required
          value={nameEn}
          error={Boolean(touched.nameEn && errors.nameEn)}
          helperText={touched.nameEn && errors.nameEn}
          label={NameEn.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: NameEn.MaxLength, 'data-testid': NameEn.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
        <CaijInput
          name={DetailsEn.Name}
          id={DetailsEn.Name}
          value={detailsEn}
          error={Boolean(touched.detailsEn && errors.detailsEn)}
          helperText={touched.detailsEn && errors.detailsEn}
          label={DetailsEn.Label}
          InputLabelProps={{ shrink: true }}
          inputAttr={{maxLength: DetailsEn.MaxLength, 'data-testid': DetailsEn.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
};

LibraryServiceForm.propTypes = {
  allowedRequireQty: PropTypes.bool,
  libraryService: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func.isRequired
};

export default LibraryServiceForm;
