import type { FC } from 'react';
import {
    Box,
    Chip,
    ChipPropsColorOverrides,
    Grid,
    Stack,
    Typography,
    useTheme
} from '@mui/material';
import Breadcrumb from '../breadcrumb';
import AppModel from 'src/model/App';
import { Mode, firstLetterCap } from 'src/common';
import HeaderButton from './buttons';
import { OverridableStringUnion } from '@mui/types';

class TitleBadge {
    label: string;
    color?: OverridableStringUnion<"default" | "primary" | "secondary" | "error" | "info" | "success" | "warning", ChipPropsColorOverrides>;
}
  
export interface IMessage {
    id: number;
    label: string;
}

interface HeaderProps {
    id?: string;
    model?: AppModel;
    headerTitle?: string;
    actions?: string[];
    extraButtons?: any;
    titleBadge?: TitleBadge;
    mode?: Mode;
    editMode?: boolean;
    deleteMode?: Mode;
    message?: IMessage; 
    onEditDetail?: () => void;
}

const GenericHeader: FC<HeaderProps> = ({
    id,
    model,
    headerTitle,
    actions,
    extraButtons,
    titleBadge,
    editMode,
    deleteMode = Mode.delete,
    mode = Mode.add,
    message,
    onEditDetail
}) => {
    const { Title } = model;
    const theme = useTheme();

    return (
        <Box>
            <Grid container spacing={2} justifyContent='space-between'>
                <Grid item>
                    <Breadcrumb title={Title} message={message}/>
                    <Box mt={2}>
                        <Stack direction="row" alignItems="center" mt={theme.spacing(2)}>
                            <Typography variant='h5' sx={{ color: 'text.primary' }}>
                                <span>{headerTitle && firstLetterCap(headerTitle ? headerTitle : Title)}</span>
                            </Typography>
                            {titleBadge && <Chip size='small' sx={{ marginLeft: theme.spacing(1) }} label={titleBadge.label} color={titleBadge.color || "default"} />}
                        </Stack>
                    </Box>
                </Grid>
                {actions && actions.length > 0 &&
                    <Grid item>
                        {actions.map((action, index) => {
                            let callback = null;
                            if(action == 'extra')
                                return extraButtons;
                            else if(action == 'delete')
                                callback = model.delete;
                            else if(action == 'editDetail')
                                callback = onEditDetail;
                            return <HeaderButton mode={mode} editMode={editMode} deleteMode={deleteMode} key={index} id={id} model={model} type={action} callback={callback} />;
                        })}
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

GenericHeader.propTypes = {
};

export default GenericHeader;
