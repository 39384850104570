import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import {
  ITEMS_PER_PAGE,
  EmptyList,
  ListType,
  useNavigate,
  getEmptyListSearch,
  useLocation,
  UxpertiseModel,
  Mode,
  Authorize,
  PaginationModel,
  getOpacity
} from 'src/common';
import type { 
  SearchFilter,
  UxpertiseDto
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import Search from 'src/components/search';
import { Root } from 'src/components/styled';
import { 
  CaijTableCell, 
  CaijTableCellActive 
} from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';

export interface ResultsProps {
  uxpertises: UxpertiseDto[];
  isEmptyList: boolean;
  model: UxpertiseModel;
  onDeactivate: (id: number, name: string) => Promise<void>;
  onActivate: (uxpertise: UxpertiseDto) => Promise<void>
}
 
export interface ISubmitting {
  isSubmitting: boolean,
  id?: number
};

const Results: FC<ResultsProps> = ({
  uxpertises,
  isEmptyList,
  model,
  onDeactivate,
  onActivate,
}) => {
  const pagination = new PaginationModel();
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const [isCreateAuth, setIsCreateAuth] = useState<boolean>();
  const [page, setPage] = useState<number>(0);
  const [submitting, setSubmitting] = useState<ISubmitting>({isSubmitting: false});
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query");
  const [filter, setFilter] = useState<SearchFilter>({query,doSearch: false});

  useEffect(() => {
    const { Filters: { query, page} } = model;
    const queryVal = model.getQueryParam(query);
    const pageVal = +model.getQueryParam(page);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        doSearch: queryVal ? true : false,
      }
    });
    setPage(pageVal);
  },[]);

  const deactivate = async (id: number, name: string) => {
    await onDeactivate(id, name);
    setSubmitting({...submitting,isSubmitting: false,id});
  }

  const activate = async (uxpertise: UxpertiseDto) => {
    await onActivate(uxpertise);
    setSubmitting({...submitting,isSubmitting: false,id: uxpertise.id});
  }

  const renderTableCell = (row: UxpertiseDto, isSubmitting: boolean) => (
    <>
      <CaijTableCell opacity={getOpacity(row.activated)}>{row.name}</CaijTableCell>
      <CaijTableCellActive active={row.activated} />
      <TableCell width="10%">
        {
          row.activated ? (
            <Authorize resourceCode={model.ResourceCode} mode={Mode.delete} onIsAuth={l => setIsDeleteAuth(l)}>
              <Button variant='outlined' disabled={isSubmitting || !isDeleteAuth} onClick={() => {
                setSubmitting({isSubmitting: true, id: row.id});
                deactivate(row.id, row.name);
              }}>Désactiver</Button>
            </Authorize>
          ) 
          : (
            <Authorize resourceCode={model.ResourceCode} mode={Mode.add} onIsAuth={(l) => setIsCreateAuth(l)}>
              <Button sx={{ width: '8.5em' }} variant='contained' color='primary' disabled={isSubmitting || !isCreateAuth} onClick={() => {
                setSubmitting({isSubmitting: true, id: row.id});
                activate(row)
              }}>Activer</Button>
            </Authorize>
          )
        }
      </TableCell>
    </>
  )

  const renderContent = () => (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      <Root>
        <Box p={2} width="100%">
          <Search 
            listType={ListType.Uxpertise}
            placeholder='Recherche la/les groupe Uxpertise' 
            width="400px"
            filter={filter}
            path={model.Path.Home}
          />
        </Box>
        <CaijTable>
          <TableHead>
            <TableRow>
              <TableCell>{model.Name}</TableCell>
              <TableCell>{model.Status}</TableCell>
              <TableCell align='center'>{model.Action}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              uxpertises.length === 0 ? getEmptyListSearch(3) :
              pagination.applyPagination(uxpertises, page, ITEMS_PER_PAGE)
              .map((row: UxpertiseDto) => {
                  const isSubmitting = submitting.isSubmitting && submitting.id === row.id;
                  return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        {renderTableCell(row, isSubmitting)}
                      </TableRow>
                    )
                }
              )
            }
          </TableBody>
        </CaijTable>
        <CaijTablePagination 
          paged={{totalCount: uxpertises.length}} 
          model={model} 
          filter={filter} 
          page={page}
          navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
        />
      </Root>
    </MyCard>
  );

  return (
    <>
      { renderContent() }
    </>
  );
};

Results.propTypes = {
  uxpertises: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  model: PropTypes.instanceOf(UxpertiseModel).isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired
};

export default Results;
