import type { ApiKeyDto } from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchApiKeys = (
  apiKeys: ApiKeyDto[],
  isLoading?: boolean,
  isEmptyList?: boolean
): {
  type: 'FETCH_API_KEYS';
  payload: { apiKeys: ApiKeyDto[]; isLoading?: boolean; isEmptyList?: boolean};
} => ({
  type: actionTypes.FETCH_API_KEYS,
  payload: {
    apiKeys,
    isLoading,
    isEmptyList
  },
});

export const fetchApiKey = (apiKey: ApiKeyDto, isLoading?: boolean) : { 
  type: 'FETCH_API_KEY', 
  payload: { 
    apiKey: ApiKeyDto,
    isLoading?: boolean;
  } } => ({
  type: actionTypes.FETCH_API_KEY,
  payload: {
    apiKey,
    isLoading
  }
});

export const deleteApiKey = (id: string) : {
  type: 'DELETE_API_KEY';
  payload: {
    id: string;
  };
} => ({
  type: actionTypes.DELETE_API_KEY,
  payload: {id},
});
