import { ErrorType, labelConfig, TribunalModel } from 'src/common';
import { TribunalResource } from 'src/common/types';
import * as Yup from 'yup';

const validateSchema = (model: TribunalModel, tribunal: TribunalResource, onSubmit: (values: TribunalResource) => void ) => {
  const { CodeFr, CodeEn, LabelFr, LabelEn, Coverage } = model;
  return Yup.object().shape({
    codeFr: CodeFr.required(true)
    .test(CodeFr.Label, TribunalModel.formatError(ErrorType.exist, CodeFr.Label),async (value: string) => {
      if (value && !onSubmit) {
        return await model.verifyTribunalCode(value,tribunal.id);
      }
      return true;
    }),
    codeEn: CodeEn.required(true)
      .test(CodeEn.Label, TribunalModel.formatError(ErrorType.exist,CodeEn.Label),async (value: string) => {
        if (value && !onSubmit) {
          return await model.verifyTribunalCode(value,tribunal.id);
        }
        return true;
      }),
    juridiction: Yup.string()
      .required(TribunalModel.formatError(ErrorType.required, labelConfig.juridiction)),
    labelFr: LabelFr.required(true),
    labelEn: LabelEn.required(true),
    coverage: Coverage.max(),
    tribunalType: Yup.string()
      .required(TribunalModel.formatError(ErrorType.required, labelConfig.type))
  });
}
export default validateSchema;