import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  useParams,
  fetchDetailsPartner,
  _partners,
  AccessModel,
  root,
  PartnersModel,
  ListType
} from 'src/common';
import Page from 'src/components/Page';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import DocumentTypeInfo from 'src/components/listInfo';
import useDocumentTypes from 'src/functions/hooks/documentType';
import PartnerDocumentTypeForm from './Forms/PartnerDocumentTypeForm';

const PartnersDetailsView: FC = () => {
  const sDocumentType = useDocumentTypes();
  const model = PartnersModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const [state, dispatch] = useReducer(_partners, { partner: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    documentType: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { code } = useParams();

  const tabs = [
    { value: 'details', label: 'Détails' },
    { value: 'documentType', label: 'Types de document' }
  ];

  const getPartnerByCode = useCallback(async () : Promise<void> => {
    if (access.canRead()) {
      const model = new PartnersModel();
      model.PathName = location.pathname;
      const partner = await model.getPartnerByCode(code);
      if (!model.error) {
        dispatch(fetchDetailsPartner(partner));
      }
    }
  }, [code, location]);

  useEffect(() => {
    (async () => {
      await getPartnerByCode();
    })();
  }, []);

  const setEditModePartner = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModePartner(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setEditModePartner(false);
    setCurrentTab(value);
  };

  const reloadPartner = async () => {
    await getPartnerByCode();
    setEditModePartner(false);
  };

  const editContentPublisher = () => {
    setEditModePartner(true);
  };

  const { partner } = state;

  if (Object.keys(partner).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${partner.nameFr} - ${partner.code}`;

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
              <DetailsEditForm
                model={model}
                partner={partner}
                reloadPartner={reloadPartner}
                onSwitchMode={switchMode}
              />
          );
        }
        return (
          <Details partner={partner} model={model} />
        );
      case 'documentType':
        if (editMode.documentType) {
          return (
              <PartnerDocumentTypeForm
                model={model}
                partner={partner}
                reload={reloadPartner}
                onSwitchMode={(l) => switchMode(l)}
              />
          );
        }
        return (
            <DocumentTypeInfo
              isAuthorize={sDocumentType.isAuthorize}
              listType={ListType.DocumentType}
              data={sDocumentType.documentTypes}
              lists={partner.partnerDocumentTypes}
            />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={code} 
          model={model} 
          headerTitle={partner.nameFr} 
          actions={['editDetail','delete']}
          editMode={editMode.details}
          titleBadge={{label: partner.code}}
          onEditDetail={() => editContentPublisher()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default PartnersDetailsView;
