import type { LibraryResource, LibraryServiceResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchLibraryServicesAction = {
    type: 'FETCH_LIBRARY_SERVICES';
    payload: {
      libraryServices: LibraryServiceResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchLibraryServiceAction = {
  type: 'FETCH_LIBRARY_SERVICE';
  payload: {
    libraryService: LibraryServiceResource;
    isLoading?: boolean;
  }
};

type FetchDetailsLibraryServiceAction = {
  type: 'FETCH_DETAILS_LIBRARY_SERVICE';
  payload: {
    libraryService: LibraryServiceResource;
  };
};

type DeleteLibraryServiceAction = {
  type: 'DELETE_LIBRARY_SERVICE';
  payload: {
    id: number;
  };
};

type Action = FetchLibraryServicesAction |
              FetchLibraryServiceAction |
              FetchDetailsLibraryServiceAction |
              DeleteLibraryServiceAction;

export interface LibraryServiceReturnState {
  libraryServices?: LibraryServiceResource[];
  libraryService?: LibraryServiceResource;
  libraries?: LibraryResource[];
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface LibraryServiceState {
  libraryServices: LibraryServiceResource[];
  libraryService: LibraryServiceResource;
  libraries: LibraryResource[];
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: LibraryServiceState, action: Action): LibraryServiceReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_LIBRARY_SERVICES: {
      const { libraryServices, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        libraryServices,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_LIBRARY_SERVICE: {
      const { libraryService, isLoading } = action.payload;
      return {
        ...state,
        libraryService,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_LIBRARY_SERVICE: {
      const { libraryService } = action.payload;
      return {
        ...state,
        libraryService
      };
    }
    case actionTypes.DELETE_LIBRARY_SERVICE: {
      return {
        ...state,
        libraryServices: [...state.libraryServices].filter(({id}) => id !== action.payload.id) 
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
