import { CoveoSourceDto } from 'src/common/types';
import * as actionTypes from '../../../actions/ActionTypes';

type FetchCoveoSourcesAction = {
    type: 'FETCH_COVEO_SOURCES';
    payload: {
      coveoSources: CoveoSourceDto[];
      isLoading?: boolean;
      isEmptyList?: boolean;
    }
};

type DeleteCoveoSourceAction = {
  type: 'DELETE_COVEO_SOURCE';
  payload: {
    id: number;
  }
};

type Action = FetchCoveoSourcesAction
              | DeleteCoveoSourceAction;

interface CoveoSourceState {
  coveoSources: CoveoSourceDto[];
  isLoading: boolean;
  isEmptyList: boolean;
}

const reducer = (state: CoveoSourceState, action: Action) => {
  switch (action.type) {
    case actionTypes.FETCH_COVEO_SOURCES: {
      const { coveoSources, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        coveoSources,
        isLoading,
        isEmptyList
      };
    }
    case actionTypes.DELETE_COVEO_SOURCE: {
      return {
        ...state,
        coveoSources: [...state.coveoSources].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
