import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  Chip
} from '@mui/material';
import {
  EmptyList,
  ListType,
  getEmptyListSearch,
  ReperageModel,
  formatDateTime,
  ReperageFolderStatus,
  ReperageFolderStatusColor,
  NavigateFunction,
  labelConfig,
  FIRST_PAGE,
  EnhancedTableHead,
} from 'src/common';
import { Paged } from 'src/types/pagination';
import { ReperageResource, SearchFilter, Sort } from 'src/common/types';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  reperages: ReperageResource[];
  paged: Paged;
  isEmptyList: boolean;
  model?: ReperageModel;
  onDeleteReperage: (id: number) => Promise<void>;
  tab: string;
  navigate: NavigateFunction;
  location: {search: string};
}

const Results: FC<ResultsProps> = ({
  reperages,
  paged,
  isEmptyList,
  model,
  onDeleteReperage,
  tab,
  navigate,
  location
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  let currentPage = paged.currentPage - 1;
  const query = new URLSearchParams(location?.search).get("query");
  const [filter, setFilter] = useState<SearchFilter>({
    query,
    doSearch: false,
    page: currentPage,
    sortedBy: '',
    sort: null
  });
  const headCells = model.getHeadCells(labelConfig.status);
  const { order, setOrder, orderBy, setOrderBy, handleRequestSort } = useSort(tab);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const { Filters: { query, page, sortedBy, sort } } = model;
    let queryVal = '', sortVal = '', sortedByVal = '', pageVal = FIRST_PAGE ;
    if (tab === "archived") {
      for (const [key, value] of urlParams.entries()) {
        switch(key){
          case page:
            pageVal = +value;
            break;
          case query:
            queryVal = value;
            break;
          case sortedBy:
            sortedByVal = value;
            break;
          case sort:
            sortVal = value;
            break;
        }
      }
      setFilter((prevState: SearchFilter) => {
        return {
          ...prevState,
          query: queryVal,
          doSearch: queryVal ? true : false,
          page: pageVal,
          sortedBy: sortedByVal,
          sort: sortVal as Sort
        }
      });
    }else{
      for (const [key, value] of urlParams.entries()) {
        switch(key){
          case page:
            pageVal = +value;
            break;
          case sortedBy:
            sortedByVal = value;
            break;
          case sort:
            sortVal = value;
            break;
        }
      }
      setFilter((prevState: SearchFilter) => {
        return {
          ...prevState,
          doSearch: false,
          sort: sortVal as Sort,
          page: pageVal,
          sortedBy: sortedByVal,
          query: ''
        }
      });
    }
    sortVal ? setOrder(sortVal) : '';
    sortedBy ? setOrderBy(sortedByVal) : '';
  }, [location]);

  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      {tab === "archived" &&
        <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between' flexGrow={1}>
          <Search
            listType={ListType.Reperage}
            placeholder='Recherche la/les repérage(s)'
            filter={filter}
            width="400px"
            path={path.Home}
          />
        </Box>
      }
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={(e,p) => handleRequestSort<ReperageModel>(e,p,model,filter,navigate)}
          headCells={headCells}
        />
        <TableBody>
          { reperages.length === 0 ? getEmptyListSearch(headCells.length) :
            reperages.map((row: ReperageResource) => (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  <CaijTableCell>{row.id}</CaijTableCell>
                  <CaijTableCell>{row.name}</CaijTableCell>
                  <CaijTableCell>{row.createdByLabel}</CaijTableCell>
                  <CaijTableCell>{row.clientLabel}</CaijTableCell>
                  <CaijTableCell>{formatDateTime(row.dateCreated)}</CaijTableCell>
                  <CaijTableCell>{formatDateTime(row.dateModified)}</CaijTableCell>
                  <CaijTableCell>
                    <Chip sx={{ padding: "0 1rem" }} color={ReperageFolderStatusColor[row.status]} label={ReperageFolderStatus[row.status]} />
                  </CaijTableCell>
                  <CaijTableCellAction 
                    pageEditUrl={path.getEdit(row.id)}
                    resourceCode={model.ResourceCode}
                    handleToggle={() => ReperageModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                    disableEdit={row.archived}
                  >
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(row.id) !== -1}
                      onSubmit={async () => {
                        await onDeleteReperage(row.id);
                        setSelectedRow([]);
                      }}
                      setSelectedRow={setSelectedRow}
                    />
                  </CaijTableCellAction>
                </CaijTableRowClickable>
              )
            )
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={paged} 
        model={model} 
        filter={filter} 
        page={currentPage}
        navigate={(model) => navigate(model.getUrlEncode.apply(model, model.getParams()) + (tab ? `#${tab}` : ""))}
      />
    </MyCard>
  );
};

Results.propTypes = {
  reperages: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  onDeleteReperage: PropTypes.func.isRequired,
  model: PropTypes.instanceOf(ReperageModel)
};

export default Results;
