import { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  fetchProducts,
  useLocation,
  _product,
  ProductModel,
  SelectedStatus,
  useNavigate,
  AccessModel,
  deleteProduct,
  root
} from 'src/common';
import type { ProductResource } from 'src/common/types';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

function getStatus(statut: string, products: ProductResource[]) : ProductResource[]{
  if(statut === SelectedStatus.Active){
    return products.filter((product:ProductResource) => product.active);
  }else if(statut === SelectedStatus.Inactive){
    return products.filter((product:ProductResource) => !product.active);
  }
  return products;
}

function getAccessType(accessType: string, products: ProductResource[]){
  if(accessType){
    return products.filter((product:ProductResource) => product.access === accessType);
  }
  return products;
}

const initialState = {
  products: [],
  isLoading: false,
  isEmptyList: true,
  model: null
};

const ProductListView: FC = () => {
  const access = new AccessModel(ProductModel.getInstance().ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_product, initialState);
  const navigate = useNavigate();

  const getProducts = useCallback(async (model: ProductModel) : Promise<void> => {
    model.PathName = location.pathname;
    const products = await model.getProducts();
    if (!model.error) {
      dispatch(fetchProducts(products, true, !Array.isArray(products) || products.length === 0, true, model));
    }
  },[location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const model = new ProductModel();
        const { search, pathname} = location;
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            let products = await model.getProducts();
            if (!model.error) {
              const { Filters: { status, accessType } } = model;
              const statusTerm = model.getQueryParam(status);
              const accessTypeTerm = model.getQueryParam(accessType);
              products = getStatus(statusTerm, products);
              products = getAccessType(accessTypeTerm, products);
              dispatch(fetchProducts(products, true, !Array.isArray(products) || products.length === 0, true, model));
            }
          }
        }else{
          await getProducts(model);
        }
      }
    })();
  },[location]);

  const handleDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteProduct(id));
    }
  };

  const { products, isEmptyList, isLoading, model } = state;

  if (!isLoading) {
    return <LoadingScreen />
  }

  model.Dialog.Header = 'Supprimer le produit et service';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'le produit et service';

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            products={products}
            isEmptyList={isEmptyList}
            model={model}
            onDeleteProduct={handleDelete}
            navigate={navigate}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ProductListView;
