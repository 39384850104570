import { LawModel, TribunalModel } from 'src/common';
import { ContentMostViewedResource, LawResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchLawsAction = {
    type: 'FETCH_LAWS';
    payload: {
      laws: LawResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
      model?: LawModel;
    }
};

type FetchLawAction = {
  type: 'FETCH_LAW';
  payload: {
    law: LawResource;
    isLoading?: boolean;
  };
};

type Action = FetchLawsAction |
              FetchLawAction;

interface LawReturnState {
  laws?: LawResource[];
  law?: LawResource;
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
  model?: LawModel;
}

interface LawState {
  laws: LawResource[];
  law: LawResource;
  isLoading: boolean;
  isEmptyList: boolean;
  isAuthorize: boolean;
  model: LawModel;
}

const reducer = (state: LawState, action: Action): LawReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_LAWS: {
      const { laws, isEmptyList, isLoading, isAuthorize, model } = action.payload;
      return {
        ...state,
        laws,
        isLoading,
        isEmptyList,
        isAuthorize,
        model
      };
    }
    case actionTypes.FETCH_LAW: {
      const { law, isLoading } = action.payload;
      return {
        ...state,
        law,
        isLoading
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
