import React, { useCallback, useEffect, useReducer, useState } from 'react';
import type { Dispatch, FC } from 'react';
import type { 
  CoveoSourceDto, 
  CoveoTaskDto, 
  DocumentCollectionResource, 
  SearchFilter
} from 'src/common/types';
import MyCard from '../card/MyCard';
import { 
  Box, 
  Dialog, 
  IconButton, 
  TableBody, 
  TableHead, 
  TableRow 
} from '@mui/material';
import { 
  CaijButton, 
  EnhancedTableHead,  
  _docCollection, 
  fetchDocCollections, 
  formatDateTime,
  getEmptyListSearch, 
  SearchEngineModel,
  CoveoTaskStatus,
  CoveoTaskTypeTaskType,
  AccessModel,
  useNavigate,
  PaginationModel,
  ITEMS_PER_PAGE,
  DocCollectionModel,
  ListType,
  tableRow,
  Juridictions
} from 'src/common';
import CaijTable from '../table/CaijTable';
import { CaijTableCell } from '../table/CaijTableCell';
import { BtnAction } from 'src/types/content/coveo';
import CoveoSourceEditForm from './forms/CoveoSourceEditForm';
import CaijDialogs from '../dialog';
import { BtnOk } from '../dialog/CaijDialog';
import { SvgIcon } from '@mui/material';
import { 
  PlayArrow as StartIcon,  
  StopCircle as StopIcon
} from '@mui/icons-material';
import {Edit as EditIcon} from 'react-feather';
import CaijTableHeaderFilter from '../table/CaijTableHeaderFilter';
import CaijTablePagination from '../pagination/CaijTablePagination';
import CaijTableCellAction from '../table/CaijTableCellAction';
import CaijResetFilterButton from '../buttons/CaijResetFilterButton';

interface SearchEngineSourceProps {
  coveoSources: CoveoSourceDto[];
  docCollection?: DocumentCollectionResource;
  showCoveoSourcePageList?: boolean;
  setIsRunning?: Dispatch<React.SetStateAction<boolean>>;
  onHandleRefresh?: (value?: number) => Promise<void>
}

export const getModifiedBy = (task: CoveoTaskDto) => {
  if(task.modifiedBy)
    return <div>{`Responsable : ${task.modifiedBy}`}</div>
}

export const getTask = (item: CoveoTaskDto) => {
  let task = item.task;
  if(item.task === 'Rebuild')
    task += ` ${item.processed ?? 0}/${item.total ?? 0}`;
  return <span>{item.status} {task ? '-' : ''} {task}</span>
}

export const getTaskDate = (task: CoveoTaskDto) => {
  if(task.status === CoveoTaskStatus.OnHold && task.created)
    return <div>{`Demander le ${formatDateTime(task?.created)}`}</div>
  else if(task.started)
    return <div>{`Démarré le ${formatDateTime(task.started)}`}</div>
}

const SearchEngineSource: FC<SearchEngineSourceProps> = ({
  coveoSources,
  docCollection,
  showCoveoSourcePageList,
  setIsRunning,
  onHandleRefresh
}) => {
  const navigate = useNavigate();
  const pagination = new PaginationModel();
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRow2, setSelectedRow2] = useState([]);
  const [action, setAction] = useState<BtnAction>();
  const model = SearchEngineModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const accessTask = new AccessModel(model.SearchIndexResourceCode);
  const headCell = model.getHeadCells();
  const headCellFilter = model.getCoveoSourceHeadCells();
  const [state, dispatch] = useReducer(_docCollection,{docCollections: [], isLoading: false, isEmptyList: true});
  model.Dialog.Header = 'Supprimer la source coveo';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'la source coveo';
  const [filter, setFilter] = useState<SearchFilter>({ 
    page,
    collection: '',
    name: '',
    coveoIdentifier: '',
    jurisdiction: ''
  });

  const getDocCollections = useCallback(async () => {
    const model = DocCollectionModel.getInstance();
    const docCollections = await model.getDocCollections();
    if(!model.error)
      dispatch(fetchDocCollections(docCollections, true, !Array.isArray(docCollections) || docCollections?.length === 0));
  }, []);

  useEffect(() => {
    (async () => await getDocCollections())();
  },[]);

  useEffect(() => {
    if(showCoveoSourcePageList){
      const urlParams = new URLSearchParams(location.search);
      const { Filters: { page, collection, coveoIdentifier, name, jurisdiction } } = model;
      let nameVal = '', pageVal = '', collectionVal = '', coveoIdentifierVal = '', jurisdictionVal = '';
      for (const [key, value] of urlParams.entries()) {
        switch(key){
          case page:
            pageVal = value;
            break;
          case name:
            nameVal = value;
            break;
          case collection:
            collectionVal = value;
            break;
          case coveoIdentifier:
            coveoIdentifierVal = value;
            break;
          case jurisdiction:
            jurisdictionVal = value;
            break;
        }
      }
      setFilter((prevState: SearchFilter) => {
        return {
          ...prevState,
          page: +pageVal,
          collection: collectionVal,
          name: nameVal,
          coveoIdentifier: coveoIdentifierVal,
          jurisdiction: jurisdictionVal
        }
      });
      setPage(+pageVal);
    }
  },[location.search]);
  
  const handleSubmitTask = async (coveoSource: CoveoSourceDto) => {
    if(coveoSource?.coveoTask){
      const { coveoTask } = coveoSource;
      coveoTask.task = '';
      if(coveoTask.status === CoveoTaskStatus.Processing || coveoTask.status === CoveoTaskStatus.OnHold){//En traitement
        coveoTask.status = CoveoTaskStatus.Interrupted;
        coveoTask.started = null;
        await model.updateCoveoTask(coveoTask.id, coveoTask); 
      }
    }else{//Réindexer
      const coveoTask = {
        coveoSourceId: coveoSource.id,
        status: CoveoTaskStatus.OnHold,
        task: CoveoTaskTypeTaskType.Rebuild
      };
      await model.createCoveoTask(coveoTask);
    }
    await onHandleRefresh();
  }

  const handleDeleteCoveoSource = async (id: number) => {
    if(id && !isNaN(id)){
      setIsRunning(false);
      if(await model.delete(id))
        await onHandleRefresh(id);
      setIsRunning(true);
    }
  }

  const renderBtnProcess = (coveoSource: CoveoSourceDto) => coveoSource.coveoTask?.status !== CoveoTaskStatus.Interrupted && (
    <Box>
      { !coveoSource.coveoTask ? ( 
          <IconButton onClick={() => handleSubmitTask(coveoSource)} disabled={!accessTask.Add}>
            <SvgIcon fontSize='small' color='success'>
              <StartIcon />
            </SvgIcon>
          </IconButton>
        ) : (
          <IconButton onClick={() => handleSubmitTask(coveoSource)} disabled={!accessTask.Edition}>
            <SvgIcon fontSize='small' color='error'>
              <StopIcon />
            </SvgIcon>
          </IconButton>
        )
      }
    </Box>  
  )

  const renderContent = (row: CoveoSourceDto, index: number) => (
    <TableRow hover tabIndex={-1} key={index} sx={!showCoveoSourcePageList ? tableRow: null}>
      <CaijTableCell>{renderBtnProcess(row)}</CaijTableCell>
      <CaijTableCell>{row.name}</CaijTableCell>
      <CaijTableCell>{row.collection}</CaijTableCell>
      <CaijTableCell>{row.coveoIdentifier}</CaijTableCell>
      <CaijTableCell>{Juridictions[row.juridiction]}</CaijTableCell>
      <CaijTableCell>
        { row.coveoTask && (
            <>
              <div>{getTaskDate(row.coveoTask)}</div>
              <div>{getTask(row.coveoTask)}</div>
              <div>{getModifiedBy(row.coveoTask)}</div>  
            </>
          )
        }
      </CaijTableCell>
      <CaijTableCell>{formatDateTime(row.lastRefresh)}</CaijTableCell>
      <CaijTableCellAction 
        coveoHistoryUrl={`${model.CoveoTaskHistoryHome}?coveoSourceId=${row.id}`}
        resourceCode={model.ResourceCode}
        handleToggle={() => SearchEngineModel.handleToggle(row.id, selectedRow, setSelectedRow)}
      >
        <CaijDialogs
          dialog={model.Dialog}
          isOpen={selectedRow.indexOf(row.id) !== -1}
          onSubmit={async () => {
              await handleDeleteCoveoSource(row.id);
              setSelectedRow([]);
          }}
          setSelectedRow={setSelectedRow}
        /> 
        <Box id="coveoSource">
          <IconButton onClick={() =>{ setAction('Edit'); SearchEngineModel.handleToggle(row.id, selectedRow2, setSelectedRow2)}} disabled={!access.Edition || !!row?.coveoTask?.status}>
            <SvgIcon fontSize='small'>
              <EditIcon />
            </SvgIcon>
          </IconButton>
          <Dialog maxWidth='sm' fullWidth open={selectedRow2.indexOf(row.id) !== -1}>
            <CoveoSourceEditForm
              model={model} 
              action={action}
              coveoSource={row}
              collections={state.docCollections}
              close={() => setSelectedRow2([])} 
              onHandleRefresh={async () => {
                await onHandleRefresh();
              }} 
            />
          </Dialog>
        </Box>
      </CaijTableCellAction>
    </TableRow>
  )

  const tablePagination = <CaijTablePagination 
    paged={{totalCount: coveoSources.length}}
    model={model}
    page={page}
    filter={filter}
    navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
  />

  const btnAdd = <CaijButton
    type='add'
    color='secondary'
    variant='contained'
    disabled={!access.Add}
    onHandleClick={() => setAction('Add')}
  >
    Ajouter
  </CaijButton>
  
  return (
    <MyCard>
      <Box sx={{margin: '20px 20px 0 0', display: 'flex', justifyContent: 'right'}}>
      { showCoveoSourcePageList && <CaijResetFilterButton path={model.Path.Home} navigate={navigate} sx={{marginRight: '10px'}}/> }
      { <Box>{btnAdd}</Box> }
      </Box>
      <CaijTable>
        { showCoveoSourcePageList ? (
            <TableHead>
              <TableRow>
                 { headCellFilter.map(({colName, width, type, property}, index: number, headers) => (
                      <CaijTableHeaderFilter
                        listType={ListType.CoveoSource}  
                        headers={headers}
                        colName={colName} 
                        width={width} 
                        type={type} 
                        filter={filter} 
                        property={property}
                        key={index}
                      />
                    ))
                  }
              </TableRow>
            </TableHead>
          ) : <EnhancedTableHead headCells={headCell} />
        }
        <TableBody>
         { coveoSources?.length === 0 ? getEmptyListSearch(showCoveoSourcePageList ? headCellFilter.length : headCell.length) :
           showCoveoSourcePageList ? pagination.applyPagination<CoveoSourceDto>(coveoSources, page, ITEMS_PER_PAGE)
                                          .map((row: CoveoSourceDto, index: number) => renderContent(row, index))
           : coveoSources.map((row: CoveoSourceDto, index: number) => renderContent(row, index))
         }
         </TableBody>
      </CaijTable>
      { showCoveoSourcePageList && tablePagination }
      <Dialog maxWidth='sm' fullWidth open={action === 'Add'}>
        <CoveoSourceEditForm
          model={model} 
          action={action}
          coveoSource={{
            name: docCollection?.nameFr, 
            collection: docCollection?.code
          }}
          collections={state.docCollections}
          close={() => setAction(null)} 
          onHandleRefresh={async () => {
            await onHandleRefresh();
          }}
        />
      </Dialog>
    </MyCard>
  )
};

export default SearchEngineSource;
