import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  ITEMS_PER_PAGE
} from 'src/common';
import type {
  Error,
  TribunalResourceForCreate,
  TribunalResourceForEdit,
  TribunalResource,
  CreateResponse,
  GetTribunalsListOptionalParams,
  TribunalCodesResource
} from 'src/common/types';
import AppModel from '../../App';
import { PagedList } from 'src/types/pagination';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../../Form';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceForEdit = TribunalResourceForCreate | TribunalResourceForEdit;

export default class TribunalModel extends AppModel 
{
  private static _instance: TribunalModel;

  readonly CodeFr = new FormModel('codeFr','Code (Français)',15);
  readonly CodeEn = new FormModel('codeEn','Code (English)',15);
  readonly LabelFr = new FormModel('labelFr','Nom',250);
  readonly LabelEn = new FormModel('labelEn','Name',250);
  readonly Coverage = new FormModel('coverage','Couverture',45);
  readonly OtherCodesInput = new FormModel('otherCodesInput','Autres Codes',20);

  constructor(){
    super('/content/caselaw/tribunal');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'TRIBUNAL';
    this._headerTitle = 'Liste des tribunaux';
    this._btnAddText = 'Ajouter un tribunal';
    this.Path.PathName = '/courts/list-of-courts';

    //Dialog
    this.Dialog.Header = 'Supprimer le tribunal';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le tribunal';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Tribunaux',
      href: this.Path.Home,
      visible: true
    }
  }

  get Juridiction() {
    return "Juridiction";
  }

  get Type(){
    return "Type";
  }

  static getInstance(): TribunalModel {
    if (!TribunalModel._instance) {
      TribunalModel._instance = new TribunalModel();
    }
    return TribunalModel._instance;
  }

  static getInitialValues(values: TribunalResource) : TribunalResource {
    return {
      id: values.id,
      codeFr: values.codeFr || '',
      codeEn: values.codeEn || '',
      labelFr: values.labelFr || '',
      labelEn: values.labelEn || '',
      tribunalType: values.tribunalType || 0,
      juridiction: values.juridiction || '',
      coverage: values.coverage || '',
      otherCodes: values.otherCodes || [],
    }
  }
 
  getHeadCells(){
    return [
      {
        width: '5%', label: this.CodeFr.Label
      },
      {
        width: '5%', label: this.CodeEn.Label
      },
      {
        label: this.LabelFr.Label
      },
      {
        label: this.LabelEn.Label
      },
      {
        width:'15%', label: this.Juridiction
      },
      {
        width:'12%',label: this.Type
      },
      {
        width:'5%', label: this.Coverage.Label
      },
      {
        width:'10%', align:'right' as 'right'
      }
    ];
  };

  async getTribunalsPaginated(params: GetTribunalsListOptionalParams): Promise<PagedList<TribunalResource>> {  
    let pagedList: PagedList<TribunalResource>;
    await axios.get<PagedList<TribunalResource>>(this.route,{ params }).then(
      async response => {
        const { status, data } = response;
        if (status === Success) {
          pagedList = data;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return pagedList;
  }

  async getTribunalById(id: string) {
    let tribunal: TribunalResource;
    await axios.get<TribunalResource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            tribunal = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return tribunal;
  }

  async updateTribunal(submitData: TribunalResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<TribunalResourceForEdit, Error>(`${this.route}/${submitData.id}`,submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response
            result.message = 'Tribunal modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertTribunal(submitData: TypeResourceForEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<TribunalResourceForCreate, CreateResponse>(this.route,submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Tribunal créé.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Tribunal supprimé.'
					});
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  async verifyTribunalCode(value: string, id: number) : Promise<boolean> {
		const results = await this.getTribunalsPaginated({pageNumber: 1, pageSize: ITEMS_PER_PAGE});
		if(results){
			const index = results?.list?.findIndex((tribunal: TribunalResource) => (tribunal.codeFr.toLowerCase().trim() === value.toLowerCase().trim() || tribunal.codeEn.toLowerCase().trim() === value.toLowerCase().trim()) && tribunal.id !== id);
      if (index >= 0) {
			   return false;
			}
		}
		return true;
	}

  async getTribunalCodesNotAssigned() {
    let tribunalCodesNotAssigned: TribunalCodesResource[];
    await axios.get<TribunalCodesResource[]>(`${this.route}/tribunal-codes-not-assigned`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            tribunalCodesNotAssigned = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return tribunalCodesNotAssigned;
  }
}

