import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { CardHeader } from '@mui/material';

interface CardHeaderDetailProps {
  children?: ReactNode;
  title?: String | ReactNode;
}

export const cardHeaderTitle = {
  fontWeight: 'bold'
};

const CardHeaderDetail: FC<CardHeaderDetailProps> = ({ title }) => <CardHeader title={title} titleTypographyProps={{...cardHeaderTitle, variant: 'subtitle1'}} />

CardHeaderDetail.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default CardHeaderDetail;
