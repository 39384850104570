import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import CaijTypography from '../typography';
import { Link } from '@mui/material';

interface CaijLabelProps {
  label: string;
  sx?: any;
  allowedLink?: boolean;
  href?: string;
  children: ReactNode;
}

const greyedColor = '#808080';

export const greyed = {
  marginTop: '1em',
  fontSize: '0.9em',
  color: greyedColor,
};

export const link = {
  color:'text.primary',
  ':hover': {
    textDecoration:'none'
  }
};

const CaijLabel: FC<CaijLabelProps> = ({label,sx,allowedLink,href, children}) => {
  return (
    <>
      <CaijTypography sx={{...greyed,...sx}}>{label}</CaijTypography> 
      { allowedLink ? (
          <Link 
            underline="none"
            href={`mailto:${href}`} 
            sx={link}   
          >
            <CaijTypography>{children}</CaijTypography>
          </Link>
        ) : (
          <CaijTypography>{children}</CaijTypography>
        )
      }
    </>
  ) 
};

CaijLabel.propTypes = {
  label: PropTypes.string.isRequired
};

export default CaijLabel;