import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { DocumentCollectionResource, ProductResource } from 'src/common/types';
import { RadioGroup, FormControl, FormHelperText, Typography, Box} from '@mui/material';
import { 
  AccessPermissions, 
  AccessType, 
  CaijCard, 
  FormikErrors, 
  FormikTouched, 
  labelConfig, 
  PRIVATE_PERMISSION_MSG 
} from 'src/common';
import CaijRadio from 'src/components/inputs/CaijRadio';
import {$enum} from "ts-enum-util";

export interface AccessTypeFormProps {
  disabledPublicAccess?: boolean;
  data: DocumentCollectionResource | ProductResource;
  errors?: FormikErrors<DocumentCollectionResource | ProductResource>;
  touched?: FormikTouched<DocumentCollectionResource | ProductResource>;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AccessTypeForm: FC<AccessTypeFormProps> = ({
  data,
  disabledPublicAccess,
  errors,
  touched,
  onHandleChange,
  setFieldValue,
}) => {
  return (
    <>
      <CaijCard title={labelConfig.permissions}>
      <FormControl component='fieldset'>
        <RadioGroup
          aria-label='access'
          name='access'
          value={data.access.toString()}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onHandleChange(event, setFieldValue)}
        >
          {
            $enum(AccessType).map((value, key) => (
              (!disabledPublicAccess || key !== AccessPermissions.Public) ? <CaijRadio name='access' key={key} label={value} value={key} inputProps={{'data-testid': key}}/> : ''
            ))
          }
          {data.access == $enum(AccessType).getKeyOrDefault(AccessType.PRIVATE) && (
            <Box>
              <Typography variant='body2' sx={{ color: 'text.error'}}>
                {PRIVATE_PERMISSION_MSG}
              </Typography>
            </Box>
          )}
        </RadioGroup>
      </FormControl>
      <FormHelperText sx={{ color: 'text.error' }}>{touched?.access && errors.access}</FormHelperText>
      </CaijCard>
    </>
  );
};

AccessTypeForm.propTypes = {
  data: PropTypes.object.isRequired,
  disabledPublicAccess: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

AccessTypeForm.defaultProps = {
  disabledPublicAccess: false
};

export default AccessTypeForm;
