import { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  LibraryModel, 
  CaijImage,
  labelConfig
} from 'src/common';
import type { LibraryResource } from 'src/common/types';
import { Box, Grid } from '@mui/material';
import UploadImage from 'src/components/image/UploadImage';

interface LogoProps {
  model: LibraryModel;
  library: LibraryResource;
  handleChangeImageUrl: (name: string, imageUrl: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const Logo: FC<LogoProps> = ({ 
  model,
  library,
  setFieldValue ,
  handleChangeImageUrl
}) => {
  const { ImageUrlFr, ImageUrlEn } = model;
  return (
    <>
      <Grid item md={6} xs={12}>
        { library.imgUrlFr && (
            <Box mb={2}>
              <CaijImage id='imgUrlFr' src={library.imgUrlFr} alt={library.imgUrlFr} />
            </Box>
          )
        }
        <Box mb={4}>
          <UploadImage
            imgLabel={ImageUrlFr.Label}
            maxLength={ImageUrlFr.MaxLength}
            resourceCode={model.ResourceCode}
            btnText={library.imgUrlFr ? labelConfig.imageEditBtnFr : labelConfig.imageBtnFr}
            imgName={ImageUrlFr.Name}
            onHandleChangeImageUrl={handleChangeImageUrl}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        { library.imgUrlEn && (
            <Box mb={2}>
              <CaijImage id='imgUrlEn' src={library.imgUrlEn} alt={library.imgUrlEn} />
            </Box>
          )
        }
        <Box mb={4}>
          <UploadImage
            imgLabel={ImageUrlEn.Label}
            maxLength={ImageUrlEn.MaxLength}
            resourceCode={model.ResourceCode} 
            btnText={library.imgUrlEn ? labelConfig.imageEditBtnEn : labelConfig.imageBtnEn}
            imgName={ImageUrlEn.Name}
            onHandleChangeImageUrl={handleChangeImageUrl}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Grid>
    </>
  );
}

Logo.propTypes = {
  library: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default Logo;