import React, { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress
} from '@mui/material';
import type { Theme } from 'src/theme';

const root = {
  alignItems: 'center',
  backgroundColor: (theme: Theme) => theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  minHeight: '100%',
  padding: (theme: Theme) => theme.spacing(3)
}

const LoadingScreenCard: FC = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box sx={root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default LoadingScreenCard;
