import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  fetchLibraries,
  _library,
  LibraryModel,
  useNavigate,
  AccessModel,
  deleteLibrary,
  root
} from 'src/common';
import Page from 'src/components/Page';
import printMessage from 'src/views/errors/MessageError';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  libraries: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false,
  model: null
};

const LibraryListView: FC = () => {
  const access = new AccessModel(LibraryModel.getInstance().ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_library, initialState);
  const navigate = useNavigate();
  
  const getLibraries = useCallback(async (model: LibraryModel) : Promise<void> => {
    model.PathName = location.pathname;
    const libraries = await model.getLibraries();
    if (!model.error) {
      dispatch(fetchLibraries(libraries, true, !Array.isArray(libraries) || libraries.length === 0, true, model));
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname} = location;
        const model = new LibraryModel();
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            let libraries = await model.getLibraries();
            if (!model.error) {
              const { Filters: { query } } = model;
              const searchTerm = model.getQueryParam(query).toLowerCase();
              libraries = libraries.filter(({ nameFr, libraryType }) => nameFr.toLowerCase().search(searchTerm) >= 0 || libraryType?.nameFr.toLowerCase().search(searchTerm) >= 0);
              dispatch(fetchLibraries(libraries, true, !Array.isArray(libraries) || libraries.length === 0, true, model));
            }
          }
        }else{
          await getLibraries(model);
        }
      }
    })();
  }, [location.search]);

  const handleDelete : (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteLibrary(id));
    }
  };

  const synchronize = async () : Promise<void> => {
    const model = new LibraryModel;
    const response = await model.synchronize();
    if (!model.error) {
      printMessage({
        status: response.status,
        message: response.message
      });
    } 
  }

  const { libraries, isEmptyList, isLoading, model } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  //Dialog
  model.Dialog.Header = 'Supprimer la bibliothèque';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'la bibliothèque';

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            libraries={libraries}
            onDeleteLibrary={handleDelete}
            onSychronize={synchronize}
            isEmptyList={isEmptyList}
            model={model}
            navigate={navigate}
            location={location}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryListView;
