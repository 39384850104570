import React from 'react';
import type { Theme } from 'src/common/types';
import { IconButton } from '@mui/material';
import {ArrowLeftCircle as CollapseIcon, ArrowRightCircle as ExpandIcon}  from 'react-feather';
import { PropsWithContext, withAppContext } from 'src/functions/withAppContext';
import { CaijTooltip, DESKTOP_NAV_CLOSED_PERSIST } from 'src/common';

class CaijCollapseIcon extends React.Component<PropsWithContext> {
  render() {
    const {context} = this.props;
    return (
      <div style={{ 
          position:'fixed',
          top:88,
          zIndex: 999,
          display: context.showIcon ? 'block' : 'none',
          left: context.isDesktopNavOpen || context.isDesktopNavOverlapOpen ? 237 : 10
        }}
      >
        <CaijTooltip title="Augmenter ou réduire la navigation" marginTop="18px" disabled={!context.showIcon}>
          { context.isDesktopNavOpen ? (
              <IconButton
                onClick={() => {
                  context.setDesktopNavOpen(false);
                  if(context.isDesktopNavOverlapOpen) 
                    context.setDesktopNavOverlapOpen(false);
                  context.localStorage.add(DESKTOP_NAV_CLOSED_PERSIST,"1");
                }}
                sx={{background: (theme: Theme) => theme.palette.background.paper}}
              >
                <CollapseIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  context.setDesktopNavOpen(true);
                  context.localStorage.remove(DESKTOP_NAV_CLOSED_PERSIST);
                }}
                sx={{background: (theme: Theme) => theme.palette.background.paper}}
                onMouseEnter={() => {
                  if(!context.isDesktopNavOverlapOpen)
                    context.setDesktopNavOverlapOpen(false);
                }}
              >
                <ExpandIcon fontSize="small" />
              </IconButton>
            )
          }
        </CaijTooltip>
      </div>
    );
  }
}

export default withAppContext(CaijCollapseIcon);