import React, { useCallback, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  SvgIcon,
  Box,
} from '@mui/material';
import {
  ContentMostViewedModel,
  CaijTooltip,
  Authorize,
  CaijButtonSubmit,
  Mode,
  btnSubmit,
  AccessModel,
  THEMES
} from 'src/common';
import type { ContentMostViewedResource } from 'src/common/types';
import CaijDialogs from 'src/components/dialog';
import { AgGridReact, CustomNoRowsOverlayProps } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import printMessage from 'src/views/errors/MessageError';
import useSettings from 'src/hooks/useSettings';
import { 
  CellClickedEvent, 
  CellValueChangedEvent, 
  IRowNode, 
  RowDragEndEvent, 
  ValueFormatterParams
} from 'ag-grid-community';

enum TextAlign {
  left = 'left',
  center = 'center',
  right = 'right'
};

const cellStyle = {
  paddingTop: '7px',
  textAlign: TextAlign.left
};

type UpdatedValue = {
  id: number,
  secured: boolean
};

const CustomNoRowsOverlay = (props: CustomNoRowsOverlayProps & { noRowsMessageFunc: () => string }) => {
  return (
    <div style={{ backgroundColor: 'background.paper', height: '9%', color: 'red' }}>
      {props.noRowsMessageFunc()}
    </div>
  );
};

const noRowsOverlayComponentParams = () => {
  return {
    noRowsMessageFunc: () => 'Aucune donnée trouvée'
  };
};

export interface ResultsProps {
  model: ContentMostViewedModel;
  contentMostVieweds: ContentMostViewedResource[];
  onDeleteContentMostViewed: (id: number) => Promise<void>;
}

const Results: FC<ResultsProps> = ({
  model,
  contentMostVieweds,
  onDeleteContentMostViewed
}) => {
  const { settings } = useSettings();
  const access = new AccessModel(model.ResourceCode);
  const gridRef = useRef<AgGridReact>(null);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [rowData, setRowData] = useState<ContentMostViewedResource[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<number>(null);
  let updatedValues: UpdatedValue[] = [];
  let dragAndDropRows: ContentMostViewedResource[] = [];
  const theme = settings.theme === THEMES.ONE_DARK ? "ag-theme-quartz-dark" : "ag-theme-quartz";
  
  const RenderDeleteIcon = () => {
    return (
      <CaijTooltip title="Supprimer" disabled={!access.Deleted} marginTop="10px">
        <IconButton disabled={!access.Deleted} onClick={() => setOpen(true)}>
          <SvgIcon fontSize='small'>
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </CaijTooltip>
    )
  }
  
//#region ColumnDef
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      filter: false
    };
  }, []);

  const columnDefs = [
    { field: "id", hide: true},
    { 
      field: "title", 
      headerName: 'Titre', 
      cellDataType: 'text', 
      rowDrag: true, 
      flex: 1,
      cellStyle,
      sortable: true
    },
    { 
      field: "secured", 
      headerName: 'Sécurisé', 
      cellDataType: 'boolean',  
      width: 90,
      editable: access.Edition ? true : false, 
      cellEditor: 'agCheckboxCellEditor', 
      cellStyle,
      sortable: false
    },
    { 
      field: "rank" ,
      headerName: 'Rang', 
      cellDataType: 'number', 
      width: 100, 
      cellStyle: {...cellStyle, textAlign: TextAlign.center}, 
      sortable: true
    },
    {
      field: 'button',
      type: 'rightAligned',
      headerName: 'Supprimer',
      width: 100,
      cellStyle: {...cellStyle, textAlign: TextAlign.center},
      cellRenderer: RenderDeleteIcon,
      sortable: false
    },
  ];
//#endregion

  const onGridReady = useCallback(() => {
    setRowData(contentMostVieweds);
  }, [contentMostVieweds]);

  const onCellClicked = (e: CellClickedEvent<ContentMostViewedResource>) => {
    if(open){
      const data = e.api.getRowNode(String(e.rowIndex)).data;
      if(data.id){
        setId(data.id);
      }
    }
  };

  const updateRowData = (dragAndDropRows: ContentMostViewedResource[]) => {
    const newRowData = dragAndDropRows.map(row => {
      const value = updatedValues.find(value => value.id === row.id)
      if(value){
        row.secured = value.secured;
      }
      return row;
    });
    setRowData(newRowData);
  }

  const updateSecuredData = (id: number, secured: boolean) => {
    const value = updatedValues.find(s => s.id === id); 
    if(!value){
      updatedValues.push({id, secured});
    }else{
      updatedValues = updatedValues.map(value => {
        if(value.id === id){
          value.secured = secured;
        }
        return value;
      });
    }
  }

  const onCellValueChanged = async (e: CellValueChangedEvent<ContentMostViewedResource>) => {
    const { id, secured } = e.data;
    if (id) {
      const response = await model.updateContentMostViewed(id, secured);
      if (!model.error) {
        const { status, message } = response;
        printMessage({status, message});
        updateSecuredData(id, secured);
      }
    }
  };

//#region Drag and Drop rows
  const onRowDragEnd = (e: RowDragEndEvent<ContentMostViewedResource>) => {
    const rowValues: ContentMostViewedResource[] = [];
    e.api.forEachNode((rowNode: IRowNode<ContentMostViewedResource>, index: number) => {
      const { id, title, secured } = rowNode.data;
      rowValues.push({ id, title, secured, rank: rowNode.rowIndex + 1 });
    });
    dragAndDropRows = rowValues.slice();
  };

  const saveDragAndDropRows = async () => {
    if((dragAndDropRows && dragAndDropRows.length == 0)) return;
    const isTheSame = rowData.every(s => dragAndDropRows.some(x => x.id == s.id && x.rank == s.rank));
    if(!isTheSame){
      try {
        setIsSubmitting(true);
        const submitData = [...dragAndDropRows].map(s => ({ id: s.id, rank: s.rank }));
        const response = await model.updateContentMostViewedRanking(JSON.stringify(submitData));
        if (!model.error) {
          const { status, message } = response;
          printMessage({status, message});
          updateRowData(dragAndDropRows);
          dragAndDropRows = [];
          updatedValues = [];
        }
      }catch(e){console.error(e);}
      finally{
        setIsSubmitting(false);
      }
    }
  }
//#endregion

  const reset = () => {
    setOpen(false);
    setId(null);
  };

  return  (
    <>
      <div>
        <Box sx={{mb:2}}>
          <Authorize resourceCode={model.ResourceCode} mode={Mode.edit}  onIsAuth={l => setIsAuth(l)}>
            <CaijButtonSubmit disabled={isSubmitting || !isAuth} sx={btnSubmit} onHandleClick={saveDragAndDropRows} />
          </Authorize>
        </Box>
      </div>
      <div style={{width: "100%"}}>
        <div style={{width: "100%"}} className={theme}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowDragManaged={true}
            onGridReady={onGridReady}
            stopEditingWhenCellsLoseFocus={true}
            onRowDragEnd={onRowDragEnd}
            onCellClicked={onCellClicked}
            onCellValueChanged={onCellValueChanged}
            rowHeight={60}
            noRowsOverlayComponent={CustomNoRowsOverlay}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            domLayout='autoHeight'
          />
        </div>
      </div>
      <div>
        <CaijDialogs
          dialog={model.Dialog}
          isOpen={open}
          onSubmit={async () => {
            await onDeleteContentMostViewed(id);
            setRowData(prev => {
              prev = rowData.filter(x => x.id != id);
              return prev;
            });
            reset();
          }}
          close={() => reset()}
        />
      </div>
    </>
  );
};

Results.propTypes = {
  contentMostVieweds: PropTypes.array,
  onDeleteContentMostViewed: PropTypes.func.isRequired
};

export default Results;
