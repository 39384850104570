import React, { useState, FC, useEffect} from 'react';
import {
  Box,
  Collapse,
  IconButton,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NumberFormat from 'react-number-format'
import { tableRow } from 'src/styles';
import { StatsDto } from 'src/common/types';
import { CaijTooltip, CustomerModel, EmptyList, EnhancedTableHead } from 'src/common';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import { Theme } from 'src/theme';
import CaijTable from 'src/components/table/CaijTable';

interface StatRowProps {
  model: CustomerModel;
  stats: StatsDto;
  activeCustomersByGroups: StatsDto[];
};

const StatsRow : FC<StatRowProps> = ({ model, stats, activeCustomersByGroups }) => {
  const [open, setOpen] = React.useState(false);
  const [statsGroups, setStatsGroups] = useState<StatsDto[]>([]);
  let headerCells = model.getStatsHeadCells();
  headerCells = headerCells.splice(1,headerCells.length - 1);

  useEffect(() => {
    (() => {
      if(open && stats.subscriptionId && activeCustomersByGroups)
        setStatsGroups(activeCustomersByGroups.filter(({subscriptionId}) => subscriptionId === stats.subscriptionId));
    })()
  },[open]);

  return (
    <>
      <TableRow hover tabIndex={-1} sx={{'& > *': { borderBottom: 'none' }}}>
        <TableCell>
          <CaijTooltip title="Augmenter ou réduire la liste" marginTop='18px'>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </CaijTooltip>
        </TableCell>
        <CaijTableCell>{stats.subscriptionName}</CaijTableCell>
        <CaijTableCell>Tous</CaijTableCell>
        <CaijTableCell cellAlign="right">
          <NumberFormat value={stats.active} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
        </CaijTableCell> 
        <CaijTableCell cellAlign="right">
          <NumberFormat value={stats.activeOnLogin} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
        </CaijTableCell>
        <CaijTableCell cellAlign="right">
          <NumberFormat value={stats.blocked} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
        </CaijTableCell>
        <CaijTableCell cellAlign="right">
          <NumberFormat value={stats.active + stats.activeOnLogin + stats.blocked} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
        </CaijTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 3 }}>
                <CaijTable>
                  <EnhancedTableHead
                    notAllowedSort
                    backgroundColor={(theme: Theme) => theme.palette.background.dark}
                    headCells={headerCells}
                  />
                  <TableBody>
                      { statsGroups.length === 0 ? (
                          <>
                           <TableRow sx={tableRow}>
                              <CaijTableCell colSpan={headerCells.length}>
                                <EmptyList noCard />
                              </CaijTableCell>
                            </TableRow>
                          </>
                          ) : statsGroups.map((row: StatsDto, index: number) => 
                          <TableRow hover tabIndex={-1} key={index} sx={tableRow}>
                            <CaijTableCell>
                              {stats.subscriptionName}
                            </CaijTableCell>
                            <CaijTableCell>
                              {row.groupName}
                            </CaijTableCell>
                            <CaijTableCell cellAlign="right">
                              <NumberFormat value={row.active} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
                            </CaijTableCell>
                            <CaijTableCell cellAlign="right">
                              <NumberFormat value={row.activeOnLogin} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
                            </CaijTableCell>
                            <CaijTableCell cellAlign="right">
                              <NumberFormat value={row.blocked} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
                            </CaijTableCell>
                            <CaijTableCell cellAlign="right">
                              <NumberFormat value={row.active + row.activeOnLogin + row.blocked} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
                            </CaijTableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </CaijTable>
                </Box>
              </Collapse>
          </TableCell>
      </TableRow>
    </>
  )
}

export default StatsRow;
