import React, { useEffect, useCallback, useReducer, useState } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  LawModel,
  _law,
  fetchLaws,
  useNavigate,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import printMessage from 'src/views/errors/MessageError';

const initialState = {
  laws: [],
  isLoading: false,
  isEmptyList: true,
};

const LawView: FC = () => {
  const access = new AccessModel(LawModel.getInstance().ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [deleted, setDeleted] = useState<number>(0);
  const [state, dispatch] = useReducer(_law, initialState);

  const getLaws = useCallback(async (model: LawModel) : Promise<void> => {
    model.PathName = location.pathname;
    const laws = await model.getLaws();
    if (!model.error) {
      dispatch(fetchLaws(laws, true,!Array.isArray(laws) || laws.length === 0, true, model));
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname} = location;
        const model = new LawModel();
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            let laws = await model.getLaws();
            if (!model.error) {
              const { Filters: { query, type } } = model;
              const searchTerm = model.getQueryParam(query).toLowerCase();
              const typeTerm = model.getQueryParam(type);
              if(searchTerm || typeTerm){
                if(searchTerm){
                  laws = laws.filter(({code,titleFr}) => (code.toLowerCase().search(searchTerm) >= 0) || (titleFr.toLowerCase().search(searchTerm) >= 0));
                }
                if(typeTerm){
                  laws = laws.filter(({type}) => type && type.search(typeTerm) >= 0);
                }
                dispatch(fetchLaws(laws, true,!Array.isArray(laws) || laws.length === 0, true, model));
              }else{
                dispatch(fetchLaws(laws, true,!Array.isArray(laws) || laws.length === 0, true, model));
              }
            }
          }
        }else{
          await getLaws(model);
        }
      }
    })();
  }, [deleted, location]);
  
  const handelDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      const model = LawModel.getInstance();
      const response = await model.deleteLaw(id);
      if (!model.error) {
        const { status, message } = response;
        printMessage({status,message});
        setDeleted(deleted + 1);
      }
    }
  };

  const { laws, isEmptyList, isLoading, model} = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            laws={laws}
            isEmptyList={isEmptyList}
            model={model}
            navigate={navigate}
            location={location}
            onDeleteLaw={l => handelDelete(l)}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LawView;
