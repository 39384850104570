import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, SvgIcon, Grid, ListItem, MenuItem, Link } from '@mui/material';
import type { EndorsedCustomerResource, Theme } from 'src/common/types';
import { 
  CustomerModel,
  Link as RouterLink,
  _customer
} from 'src/common';
import { Users as UsersIcon } from 'react-feather';
import CaijDialogs from 'src/components/dialog';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import printMessage from 'src/views/errors/MessageError';

interface CustomerEndorsedInfoProps {
  model: CustomerModel;
  endorsedBy?: EndorsedCustomerResource;
  customerId: number;
  onHandleRefreshCustomer: () => Promise<void>;
}

const listItemStyle = {
  display: 'flex',
  color: (theme: Theme) => theme.palette.text.primary,
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  paddingLeft: '1em',
  paddingRight: '1em',
  marginRight: '1em',
  height: '2em',
  borderRadius: '0.2em',
  width: '100%',
  whiteSpace: 'nowrap',
  transition: '0.2s',
  '&:hover': {
  transition: '0.2s',
  backgroundColor: (theme: Theme) => theme.palette.divider,
  cursor: 'pointer',
  },
}

async function saveCustomerEndorsed(model: CustomerModel,customerId: number, endorsedId: number, callback: () => Promise<void> ){
  const response = await model.modifyCustomerEndorsed(customerId, endorsedId);
  if (!model.error) {
    printMessage({
      status: response.status,
      message: response.message
    });
    await callback();
  }
}

const CustomerEndorsedInfo: FC<CustomerEndorsedInfoProps> = ({ 
  customerId,
  model, 
  endorsedBy, 
  onHandleRefreshCustomer 
}) => {
  const [open, setOpen] = useState<boolean>(false);
  model.Dialog.Header = 'Transfert de caution';
  model.Dialog.Name = 'la caution';
  model.Dialog.Endorser = endorsedBy;
  model.Dialog.BtnText = BtnOk.MODIFY_CUSTOMER_ENDORSED;

  return (
    <>
     <Box marginTop='0em' display='flex'>
        <SvgIcon fontSize='small'>
            <UsersIcon />
        </SvgIcon>
        <Box marginLeft='0.5em' display='flex' flexDirection='column'>
            <Typography sx={{ fontWeight: 'bold', marginBottom: '1em' }}>Cautionneur</Typography>
            <Grid container spacing={1}>
                {
                  endorsedBy && (
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item>
                              <Box display="flex" alignItems="center" justifyContent='flex-start'>
                                <ListItem 
                                  sx={listItemStyle}
                                  key={endorsedBy.id}
                                  component={RouterLink} 
                                  to={model.Path.getDetail(endorsedBy.id)}
                                  target="_blank"
                                >
                                    <Typography variant='body2' sx={{color:'text.primary'}}>
                                    {endorsedBy.fullname}
                                    </Typography>
                                </ListItem>
                                <Link href='#' underline="none" onClick={() => setOpen(true)}>
                                  <MenuItem component="div">
                                    <Typography variant="body1">
                                      Modifier
                                    </Typography>
                                  </MenuItem>
                                </Link>
                                <CaijDialogs
                                  dialog={model.Dialog}
                                  isOpen={open}
                                  onSubmitCustomerEndorsed={(endorserId: number) => (async () => {
                                    if(endorserId){
                                      await saveCustomerEndorsed(model, customerId, endorserId, onHandleRefreshCustomer)
                                    }
                                    setOpen(false);
                                  })()}
                                  close={() => setOpen(false)}
                                />
                              </Box>
                            </Grid>
                        </Grid>
                    </Box>
                  )
                }
            </Grid>
          </Box>
        </Box>
    </>
  );
};

CustomerEndorsedInfo.propTypes = {
  endorsedBy: PropTypes.object
};

export default CustomerEndorsedInfo;


