import { useEffect, useReducer } from "react";
import { EmailTemplateModel, fetchTags, _emailTemplate } from "src/common";
import { EmailTemplateReturnState } from "src/store/reducers/customer/EmailTemplateReducer";

export function useTags() : EmailTemplateReturnState {
    const [state, dispatch] = useReducer(_emailTemplate, { tags: null });
    useEffect(() => {
      let isCurrent = true;
      const initialise = async () => {
        const model = new EmailTemplateModel();
        const tags = isCurrent && await model.getTags();
        const { error } = model;
        if (!error) {
          dispatch(fetchTags(tags));
        }
      }
      initialise();
      return () => { isCurrent = false; }
    },[dispatch, fetchTags]);
    return state;
};
  