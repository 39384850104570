import React from 'react';
import type { FC } from 'react';
import {
  Container,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import type { Theme } from 'src/theme';
import Page from 'src/components/Page';

const root = {
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: (theme: Theme) => theme.spacing(3),
  paddingTop: 40,
  paddingBottom: 80
};

const InternalServerErrorView: FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Page
      sx={root}
      title="500: Erreur interne du serveur"
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          sx={{color:"text.primary"}}
        >
          500 : Erreur interne du serveur
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          sx={{color:"text.secondary"}}
        >
          <p>Erreur 500 - Le serveur a rencontré une erreur interne ou une mauvaise configuration et n'a pas pu répondre à votre demande</p>
          <p>Veuillez contacter le <a href="mailto:support@caij.qc.ca">Support technique</a> et les informer de l'heure à laquelle l'erreur s'est produite et tout ce que vous avez pu faire qui a pu causer l'erreur</p>
        </Typography>
      </Container>
    </Page>
  );
};

export default InternalServerErrorView;
