import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {TableBody} from '@mui/material';
import {
  labelConfig,
} from 'src/common';
import type { Resource } from 'src/common/types';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import EmptyList from 'src/components/EmptyList';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import ResourceModel from 'src/model/employee/Resource';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: ResourceModel;
  resources: Resource[];
  isEmptyList: boolean;
  onDeleteResource: (id: number) => Promise<void>;
}

const headCells = [
  {
    id: 'code', width: '15%', numeric: false, disablePadding: false, label: labelConfig.resourceCode
  },
  {
    id: 'name', width: '25%', numeric: false, disablePadding: false, label: labelConfig.resourceName
  },
  {
    id: 'description', width: '50%', numeric: false, disablePadding: false, label: labelConfig.description
  },
  {
    id: '', width: '0%'
  }
];

const Results: FC<ResultsProps> = ({
  model,
  resources,
  isEmptyList,
  onDeleteResource,
}) => {
  const path = model.Path;
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const { order, orderBy, sort, handleRequestSort } = useSort();

  const renderContent = (): JSX.Element => {
    if (isEmptyList) {
      return (
        <EmptyList />
      );
    }
    return (
      <MyCard>
        <CaijTable>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            { sort<Resource>(resources,page).map((row: Resource) => (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  <CaijTableCell>{row.code}</CaijTableCell>
                  <CaijTableCell>{row.name}</CaijTableCell>  
                  <CaijTableCell>{row.description}</CaijTableCell>  
                  <CaijTableCellAction 
                    pageEditUrl={path.getEdit(row.id)}
                    resourceCode={model.ResourceCode}
                    handleToggle={() => ResourceModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                  >
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(row.id) !== -1}
                      onSubmit={() => onDeleteResource(row.id)}
                      setSelectedRow={ setSelectedRow}
                    />
                  </CaijTableCellAction>
                </CaijTableRowClickable>
              ))}
          </TableBody>
        </CaijTable>
        <CaijTablePagination
          showTablePaginationLight 
          len={resources.length}
          page={page}
          setPage={(l) => setPage(l)}
        />
      </MyCard>
    );
  };

  return (
    <>
      { renderContent() }
    </>
  );
};

Results.propTypes = {
  resources: PropTypes.array,
  isEmptyList: PropTypes.bool,
  onDeleteResource: PropTypes.func.isRequired,
};

Results.defaultProps = {
  resources: [],
  isEmptyList: true
};

export default Results;
