import {
  Box,
  Container
} from '@mui/material';
import { FC } from 'react';
import React, { useEffect, useReducer } from 'react';
import {
  AccessModel,
  fetchLawDomainField, 
  LawDomainFieldModel, 
  useLocation, 
  useNavigate, 
  useParams, 
  _lawDomainField,
  root
} from 'src/common';
import Page from 'src/components/Page';
import LawDomainFieldEditForm from './LawDomainFieldEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const LawDomainFieldEditView: FC = () => {
  const model = LawDomainFieldModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_lawDomainField, {isLoading: false, lawDomainField: {} });
  const { id } = useParams();
  
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new LawDomainFieldModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const lawDomainField = await model.getLawDomainFieldById(id);
          if (!model.error) {
            dispatch(fetchLawDomainField(lawDomainField, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchLawDomainField({}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { lawDomainField, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = lawDomainField.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <LawDomainFieldEditForm
            model={model}
            lawDomainField={lawDomainField}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LawDomainFieldEditView;
