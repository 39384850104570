import { Box, Container } from '@mui/material';
import type { FC } from 'react';
import { useCallback, useEffect, useReducer } from 'react';
import { 
  AccessModel,  
  fetchLawPracticeFields,  
  useLocation,
  _lawPracticeField,
  deleteLawPracticeField,
  root, 
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import LawPraticeFieldModel from 'src/model/customer/LawPracticeField';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  lawPraticeFields: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false,
};

const LawDomainListView: FC = () => {
  const model = LawPraticeFieldModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_lawPracticeField, initialState);

  const getPraticeFields = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new LawPraticeFieldModel();
      model.PathName = location.pathname;
      const lawPraticeFields = await model.getLawPracticeFields();
      if (!model.error) {
        dispatch(fetchLawPracticeFields(lawPraticeFields, true, !Array.isArray(lawPraticeFields) || lawPraticeFields.length === 0, true));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getPraticeFields();
    })();
  },[]);

  const handleDelete: (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteLawPracticeField(id));
    }
  };

  const { lawPracticeFields, isLoading, isEmptyList } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model} 
            lawPracticeFields={lawPracticeFields} 
            onDeleteLawPraticeField={handleDelete} 
            isEmptyList={isEmptyList} 
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LawDomainListView;
