import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, Typography, Box } from '@mui/material';
import type { CustomerResource } from 'src/common/types';
import { CustomerModel, CustomerRole } from 'src/common';
import { User as UserIcon } from 'react-feather';
import CaijLabel from 'src/components/label/CaijLabel';

export interface CustomerInfoProps {
  model: CustomerModel;
  customer: CustomerResource;
};

const CustomerInfo: FC<CustomerInfoProps> = ({ model, customer }) => {
  const { subscription } = customer;
  const { MemberExtIdentifier, MemberEmail, MemberPhone, MemberPhoneExt, MemberCellPhone, MemberNoCaij, AddressLine1 } = model;
  return (
    <Box sx={{mb:'2em'}} display='flex'>
      <SvgIcon fontSize='small'>
        <UserIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <Box display='flex' alignItems='center'>
          <Typography sx={{ fontWeight: 'bold' }}>Informations Personnelles</Typography>
        </Box>
        {customer.role === CustomerRole.Admin && <CaijLabel label="Gestionnaire de compte">{subscription?.name}</CaijLabel>}
        {customer.email && <CaijLabel label={MemberEmail.Label} href={customer.email} allowedLink >{customer.email}</CaijLabel>}
        {customer.phone && <CaijLabel label={MemberPhone.Label}>{customer.phone}</CaijLabel>}
        {customer.phoneExt && <CaijLabel label={MemberPhoneExt.Label} >{customer.phoneExt}</CaijLabel>}
        {customer.cellPhone && <CaijLabel label={MemberCellPhone.Label}>{customer.cellPhone}</CaijLabel>}
        {customer.addresses && customer.addresses.length > 0 && <CaijLabel label={AddressLine1.Label}>{customer.addresses[0].line1}</CaijLabel>}
        {customer.extIdentifier && <CaijLabel label={MemberExtIdentifier.Label}>{customer.extIdentifier}</CaijLabel>}
        {customer.noCaij && <CaijLabel label={MemberNoCaij.Label}>{customer.noCaij}</CaijLabel>}
      </Box>
    </Box>
  );
};

CustomerInfo.propTypes = {
  customer: PropTypes.object.isRequired
};

export default CustomerInfo;
