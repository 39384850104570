import React, { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import type {
  EmployeesResource,
  EmployeeResourceForEdit,
  EmployeeAccessDto
} from 'src/common/types';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  labelConfig,
  //fetchResourceGroups,
  //CARD_HEADER_TITLE_GROUPS,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit
} from 'src/common';
//import ResourceGroupModel from 'src/model/employee/ResourceGroup';
//import reducer from 'src/store/reducers/employee/ResourceGroupReducer';
//import useIsMountedRef from 'src/hooks/useIsMountedRef';
//import TransfertList from 'src/components/TransfertList';
import EmployeeModel from 'src/model/employee/Employee';
import printMessage from 'src/views/errors/MessageError';
import Authorize from 'src/components/Authorize';
import CaijCheckbox from 'src/components/inputs/CaijCheckbox';
import MyCard from 'src/components/card/MyCard';
import EmployeeAccess from 'src/components/employee/employee/Forms/EmployeeAccess';

interface GroupResourceFormProps {
  employee: EmployeesResource;
  onHandleRefreshEmployee: (value: EmployeesResource) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

/*
const initialState = {
  resourceGroups: [],
  isLoading: false,
  isEmptyList: true
};
*/

const GroupResourceForm: FC<GroupResourceFormProps> = ({
  employee,
  onHandleRefreshEmployee,
  onSwitchMode
}) => {
  const model = EmployeeModel.getInstance();
  //const [state, dispatch] = useReducer(reducer, initialState);
  const [checked, setChecked] = useState(employee.active);
  const [isAuth, setIsAuth] = useState<boolean>();
  //const isMountedRef = useIsMountedRef();
  //const [selectedGroupInclus, setSelectedGroupInclus] = useState([]);
  //const [selectedGroupDisponible, setSelectedGroupDisponible] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

/*
  useEffect(() => {
    const initialise = async () => {
      const resourceGroupModel = new ResourceGroupModel();
      const availableResourceGroups = await resourceGroupModel.getResourceGroups();
      const { error } = resourceGroupModel;
      if (error) {
        printMessage(error);
        redirect(error.status);
      }
      if (isMountedRef.current && !error) {
        dispatch(fetchResourceGroups(
          availableResourceGroups,
          true,
          availableResourceGroups.length === 0
        ));
        const groupeDisponible: string[] = [];
        let resourceGroupIds: number[] = [];
        const { employeeResourceGroup } = employee;
        if (employeeResourceGroup) {
          resourceGroupIds = employeeResourceGroup.map((empResourceGroup: EmployeeResourceGroup) => empResourceGroup.resourceGroupId);
        }
        const groupInclus: string[] = availableResourceGroups.reduce((currentValues: string[], resourceGroup: ResourceGroup) => {
          const val = `${resourceGroup.id}&${resourceGroup.name}`;
          if (resourceGroupIds.includes(resourceGroup.id)) {
            currentValues.push(val);
          } else {
            groupeDisponible.push(val);
          }
          return currentValues;
        }, []);
       // setSelectedGroupInclus(groupInclus);
       // setSelectedGroupDisponible(groupeDisponible);
      }
    };
    initialise();
  }, [isMountedRef, redirect, employee]);

  const { isEmptyList, isLoading } = state;
  */

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleChange = (event: ChangeEvent<any>) => {
    setChecked(event.target.checked);
  };

  /*
  const renderGroups = (active: boolean): JSX.Element => {
    if (active) {
      return (
        <TransfertList
          title={CARD_HEADER_TITLE_GROUPS}
          listLeft={selectedGroupDisponible}
          listRight={selectedGroupInclus}
          titleLeft="Disponible"
          titleRight="Inclus"
          isEmptyList={isEmptyList}
          isLoading={isLoading}
          onHandleListInclus={(l) => setSelectedGroupInclus(l)}
        />
      );
    }
    return null;
  };
  */

  const renderResources = (active: boolean): JSX.Element => {
    if (active) {
      return <EmployeeAccess employeeAccesses={employee.resources} onHandleSelectPermissions={(l) => setSelectedPermissions(l)} />
    }
    return null;
  };

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          const model = new EmployeeModel();
          const submitData = employee as EmployeeResourceForEdit;
          submitData.active = checked;
          if (checked) {
            const permisssions = await model.getPermission(selectedPermissions);
            const employeeResource = permisssions.map((selectedPermission: EmployeeAccessDto) => {
              const newSelectedPermission = { ...selectedPermission };
              newSelectedPermission.add = newSelectedPermission.delete ? true : false;
              return newSelectedPermission;
            });
            submitData.resources = employeeResource;
          }
          const employeeId = submitData.id;
          if (submitData && employeeId) {
            const response = await model.updateEmployee(submitData);
            if (!model.error) {
              printMessage({
                status: response.status,
                message: response.message
              });
              //await onHandleRefreshEmployee(await model.getRefreshEmployee(employeeId.toString()));
            } 
          }
        }}
      >
        {({
          handleSubmit, setFieldValue, isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <MyCard>
              <CardContent>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Box mb={2}>
                    <CaijCheckbox
                      checked={checked}
                      name="active"
                      value={checked}
                      label={labelConfig.active}
                      onHandleChangeCheckBox={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                </Grid>
                {
                  renderResources(checked)
                }
                <Box mt={2}>
                  <Authorize
                    resourceCode={model.ResourceCode}
                    mode={Mode.edit}
                    onIsAuth={(l) => setIsAuth(l)}
                  >
                    <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                  </Authorize>
                  <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
                </Box>
              </CardContent>
            </MyCard>
          </form>
        )}
      </Formik>
    </>
  );
};

GroupResourceForm.propTypes = {
  employee: PropTypes.object.isRequired,
  onHandleRefreshEmployee: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired
};

export default GroupResourceForm;
