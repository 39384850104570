import React, { useCallback, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import type { CoveoSourceDto } from 'src/common/types';
import { 
  CircularProgress,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { 
  EmptyList,
  EnhancedTableHead,
  SearchEngineModel,
  _coveoSource,
  tableRow,
} from 'src/common';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTable from 'src/components/table/CaijTable';
import MyCard from 'src/components/card/MyCard';
import { getModifiedBy, getTask, getTaskDate } from 'src/components/coveo';
import { fetchCoveoSources } from 'src/store/actions/coveo/source/SourceAction';
import useInterval from 'src/hooks/polling/useInterval';
import useSort from 'src/functions/hooks/sort';

interface CoveoSourceProps {
  title: () => JSX.Element;
  headCell: any[];
}

const CoveoSource: FC<CoveoSourceProps> = ({title, headCell}) => {
  const page = 0, rowsPerPage = 20;
  const [state, dispatch] = useReducer(_coveoSource, { coveoSources:[], isLoading: false, isEmptyList: true});
  const { order, orderBy, sort, handleRequestSort } = useSort();

  const fetchData = useCallback(async () => {
    let model = new SearchEngineModel();
    let coveoSources = await model.getLists();
    if(!model.error){
      coveoSources = coveoSources?.filter(({coveoTask}) => coveoTask);
      dispatch(fetchCoveoSources(coveoSources, true, !Array.isArray(coveoSources) || coveoSources.length === 0))
    }
  },[]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  },[]);

  useInterval(async () => {
    await fetchData();
  },30000);

  return (
    <MyCard sx={{mb: 3}}>
      {title()}
      <CaijTable>
        <EnhancedTableHead 
          headCells={headCell} 
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          notAllowedSort={state.coveoSources?.length === 0}
        />
        <TableBody>
          { !state.isLoading && (
              <TableRow sx={tableRow}>
                <TableCell colSpan={headCell.length} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow> 
            )
          }
          { (state.isLoading && state.isEmptyList) ? (
            <TableRow sx={tableRow}>
              <TableCell colSpan={headCell.length} align='center'>
                <EmptyList noCard /> 
              </TableCell>
            </TableRow>
           ) : sort<CoveoSourceDto>(state.coveoSources,page).map((row: CoveoSourceDto, index: number) => 
                        <TableRow hover tabIndex={-1} key={index} sx={tableRow}>
                          <CaijTableCell>{row.name}</CaijTableCell>
                          <CaijTableCell>
                            { row.coveoTask && (
                                <>
                                  <div>{getTask(row.coveoTask)}</div>
                                  <div>{getModifiedBy(row.coveoTask)}</div>  
                                </>
                              )
                            }
                          </CaijTableCell>
                          <CaijTableCell>
                            { row.coveoTask && <div>{getTaskDate(row.coveoTask)}</div> }
                          </CaijTableCell>
                        </TableRow>
                  )
          }
         </TableBody>
      </CaijTable>
    </MyCard>
  )
};

export default CoveoSource;
