const greyedColor = '#808080';
const largeFontSize = '2em';

const flexCol = {
    display: 'flex',
    flexDirection: 'column',
};

export const greyed = {
    marginTop: '1em',
    fontSize: '0.9em',
    color: greyedColor,
};

export const deleteIcon = {
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
}

export const dialog = {
    backgroundColor: 'background.paper'
};

export const lightGray = {
    color: '#adb0bb'
};

export const box = {
    padding: '1em',
    paddingTop: '1.5em',
    paddingLeft: '1.5em',
    marginBottom: '1em'
};

export const box2 = {
    ...flexCol,
    justifyContent: 'flex-end',
    marginRight: '2em',
    padding: '2em',
};

export const box4 = {
    ...flexCol,
    margin: '1em',
    padding: '1em',
};

export const largeAndGreen = {
    fontSize: largeFontSize,
    color: 'lightGreen',
};

export const large = {
    fontSize: largeFontSize,
};

export const italic = {
    fontSize: '1em',
    fontStyle: 'italic',
};

export const grey = {
    fontSize: '0.9em',
    color: greyedColor,
};

export const columnHeader = {
    fontWeight: 'bold',
};

export const info = {
    color: 'text.secondaire',
    fontSize: '25px',
    paddingLeft: '0.7em',
    paddingBottom: '1em'
};

export const avatar = {
    border: '4px solid',
    marginTop: '-1em',
    position: 'static',
    height: '2em',
    width: '2em',
    fontSize: '4em'
};

export const container = {
    padding: '0em 1em 1em 1em',
    marginTop: '5em',
};

export const container3 = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
};

export const date = {
    marginTop: '-0.4em',
    fontSize: '1em',
};

export const hour = {
    color: 'gray',
    fontSize: '0.95em',
    marginBottom: '-0.4em',
};

export const cardTransactionBox =  {
    display: 'flex',
    paddingTop: '1em',
    justifyContent: 'space-between',
    marginRight: '2em'
};

export const frame = {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'right',
};

export const btn = {
    padding: '0.8em',
    width: '6em',
    color: '#fff',
    borderRadius: '0.2em',
    height: '2.5em',
};

export const btn2 = {
    ...btn,
    padding: '0.5em',
};

export const firstRow = {
    marginTop: '-0.4em',
    fontSize: '1em',
};
  
export const secondRow = {
    color: 'gray',
    fontSize: '0.95em',
    marginBottom: '-0.4em',
};

export const title = {
    fontStyle:"italic", 
    fontSize: '15px',
    textDecoration: 'underline',
    marginBottom: '5px'
};

export const tableCell = {
    verticalAlign: 'top',
    margin:0,
    padding: 0
};

export const row = {
    marginTop: '0.4em',
    display: 'flex',
    width: '100%',
};

export const bold = {
    fontWeight: 'bold',
    marginRight: '0.5em',
};

export const bigAndBold = {
    padding: '0.5em',
    fontWeight: 'bold',
    fontSize: '1.4em',
};

export const btnReturn = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '1em',
    fontSize: '1.2em',
    marginBottom: '1.5em'
};

export const method = {
    minWidth: '10%',
    marginTop: '1em',
};

export const warning = {
    padding: '2em',
    backgroundColor: '#FF0000',
    color: '#ffffff',
    borderRadius: '0.2em',
    marginBottom: '2em',
};
export const marginTop = {marginTop: '1em'};


export const btnMarginRight = {marginRight: '1em'};

export const assignAction = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '2em',
};

export const deleteAction = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: '2em',
    // color: theme.palette.common.red,?
    color: (theme:any) => theme.palette.error.main,
    // backgroundColor: theme.palette.error.main,
    // '&:hover': {
    //   backgroundColor: theme.palette.error.dark,
    // },
};

export const filters = {
    margin: '0em 0.5em 0em 0'
};




