import { TableCell, TableHead, TableRow } from "@mui/material"
import { FC, ReactNode } from "react";
import { CustomerModel, labelConfig } from "src/common"

interface TableHeaderProps {
  model: CustomerModel;
  children?: ReactNode;
}

const TableHeader: FC<TableHeaderProps> = ({model, children}) => (
  <TableHead>
    <TableRow>
      {children}
      <TableCell>{labelConfig.nameFr}</TableCell>
      <TableCell>{labelConfig.subscription}</TableCell>
      <TableCell>{labelConfig.accessGroup}</TableCell>
      <TableCell>{labelConfig.noCard}</TableCell>
      <TableCell>{model?.MemberExtIdentifier?.Label}</TableCell>
      <TableCell>{labelConfig.customerStatus}</TableCell>
      <TableCell>{labelConfig.endorsedBy}</TableCell>
      <TableCell align='right' />
    </TableRow>
  </TableHead>
)

export default TableHeader;