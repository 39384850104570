import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  TribunalResource,
  TribunalResourceForEdit
} from 'src/common/types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  _docCollection,
  TribunalModel,
  Mode,
  btnSubmit
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import TribunalForm from 'src/components/content/caseLaw/tribunal/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: TribunalModel;
  tribunal: TribunalResource;
  onHandleRefreshTribunal: (value: TribunalResource) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: TribunalResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  tribunal,
  onHandleRefreshTribunal,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const inputCodeFrRef  = useRef(null);
  const inputCodeEnRef  = useRef(null);

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: TribunalResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    let submitData = values as TribunalResourceForEdit;
    if (submitData && submitData.id) {
      const model = new TribunalModel();
      submitData.otherCodes = values.otherCodes;
      const response = await model.updateTribunal(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        const newTribunal = await model.getTribunalById(submitData.id.toString());
        await onHandleRefreshTribunal(newTribunal);
      }
    }
  };

  return (
    <Formik
      initialValues={TribunalModel.getInitialValues(tribunal)}
      validationSchema={validateSchema(model,tribunal,onSubmit)}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                 <TribunalForm
                    model={model} 
                    tribunal={values}
                    errors={errors}
                    touched={touched}
                    inputCodeFrRef={inputCodeFrRef}
                    inputCodeEnRef={inputCodeEnRef}
                    handleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                 />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  tribunal: PropTypes.object.isRequired,
  onHandleRefreshTribunal: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
