import { Box, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import {
  AccessModel,
  Authorize,
  btnSubmit,
  CaijButtonReset,
  CaijButtonSubmit,
  EmployeeModel,
  EmployeeRole,
  Formik,
  handleChange,
  handleChangeCheckBox,
  Mode
} from 'src/common';
import {
  EmployeesResource,
  EmployeeResourceForCreate,
  EmployeeResourceForEdit,
  EmployeeAccessDto,
  Error,
  CreateResponse
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import EmployeeForm from 'src/components/employee/employee/Forms';
import EmployeeAccess from 'src/components/employee/employee/Forms/EmployeeAccess';
import validateSchema from '../Schema';

export interface EmployeeEditFormProps {
  model: EmployeeModel;
  employee: EmployeesResource;
  onSubmit?: (values:EmployeesResource) => void;
}

async function redirect(model: EmployeeModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const EmployeeEditForm: FC<EmployeeEditFormProps> = ({
  model,
  employee,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const access = new AccessModel(model.ResourceCode);

  const renderActiveContent = (values:EmployeesResource): JSX.Element => {
    if ((values.active && values.role === EmployeeRole.User) && 
        ((access.Role == EmployeeRole.User && access.Gestion) ||
        (access.Role == EmployeeRole.Admin))) {
      return <EmployeeAccess 
                employeeAccesses={values.resources} 
                onHandleSelectPermissions={l => setSelectedPermissions(l)} 
              />
    }
    return null;
  };

  const handleSubmit = async (values: EmployeesResource) => {
    const model = new EmployeeModel();
    let submitData = values as EmployeeResourceForEdit;
    if (values.active) {
      const employeAccesses = await model.getPermission(selectedPermissions);
      const resources = employeAccesses.map((employeAccess: EmployeeAccessDto) => {
        const empAccess = { ...employeAccess };
        empAccess.add = empAccess.delete ? true : false;
        return empAccess;
      });
      if (resources.length > 0) {
        submitData.resources = resources;
      }else{
        submitData.resources = [];
      }
    }
    if (onSubmit) {
      onSubmit(submitData);
      return;
    }
    const employeeId = submitData.id;
    if (submitData && employeeId) {
      const response = await model.updateEmployee(submitData);
      if (!model.error) {
        await redirect(model,response);
      }
    } else {
      submitData = values as EmployeeResourceForCreate;
      if (values.active) {
        const employeAccesses = await model.getPermission(selectedPermissions);
        const resources = employeAccesses.map((employeAccess: EmployeeAccessDto) => {
          const empAccess = { ...employeAccess };
          empAccess.add = empAccess.delete ? true : false;
          return empAccess;
        });
        if(resources.length > 0){
          submitData.resources = resources
        }else{
          submitData.resources = [];
        }
      }
      delete submitData.id;
      const response = await model.insertEmployee(submitData);
      if (!model.error) {
        await redirect(model,response);
      }
    }
  };

  return (
    <Formik
      initialValues={EmployeeModel.getInitialValues(employee)}
      validationSchema={validateSchema(model, employee)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <EmployeeForm
                  model={model}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleChangeCheckBox={handleChangeCheckBox}
                  setFieldValue={setFieldValue}
                  employee={values}
                />
                {renderActiveContent(values)}
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={employee.id ? Mode.edit : Mode.add}
                  onIsAuth={l => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

EmployeeEditForm.propTypes = {
  employee: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

EmployeeEditForm.defaultProps = {
  onSubmit: null
};

export default EmployeeEditForm;
