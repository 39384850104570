import React, { useEffect, useReducer, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  _docCollections,
  DocCollectionsModel,
  AccessModel,
  root,
  fetchCollections,
  ITEMS_PER_PAGE,
  useNavigate
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import { GetCollectionDocumentsAsyncOptionalParams, Sort } from 'src/common/types';

const initialState = {
  collections: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: true,
  isEmptyList: true,
  isAuthorize: false,
};

const DocCollectionsListView: FC = () => {
  const model = DocCollectionsModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_docCollections, initialState);
  const [deleted, setDeleted] = useState<number>(0);
  const navigate = useNavigate();
  
  const getDocumentCollections = async (model: DocCollectionsModel, params: GetCollectionDocumentsAsyncOptionalParams) : Promise<void> => {
    if(access.canRead()){
      model.PathName = location.pathname;
      const results = await model.getCollectionDocuments(params);
      if (!model.error) {
        dispatch(fetchCollections(results.list, {...results}, false, !Array.isArray(results.list) || results.list.length === 0, true));
      }
    }
  }

  useEffect(() => {
    const initialise = async () => {
      if(access.canRead()){
        const model = new DocCollectionsModel;
        const { pathname, search } = location;
        let params: GetCollectionDocumentsAsyncOptionalParams;
        params = { pageNumber: 1, pageSize: ITEMS_PER_PAGE, sortOptions: 'asc', sortedBy : '' };
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            const { Filters: { page, sort, query, sortedBy }} = model; 
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1,
              sortOptions: model.getQueryParam(sort) as Sort || 'asc',
              searchTerm: model.getQueryParam(query),
              sortedBy: model.getQueryParam(sortedBy)
            };
          }
        }
        await getDocumentCollections(model, params);
      }
    };
    (async () => await initialise())();
  }, [deleted, location]);

  const handelDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        setDeleted(deleted + 1);
    }
  };

  const { collections, paged, isEmptyList, isLoading } = state;

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
      <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            collections={collections}
            deleteCollection={handelDelete}
            isEmptyList={isEmptyList}
            navigate={navigate}
            paged={paged}
            location={location}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocCollectionsListView;
