import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  fetchPartner,
  _partners,
  AccessModel,
  root,
  PartnersModel
} from 'src/common';
import Page from 'src/components/Page';
import PartnersEditForm from './PartnersEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const PartnersEditView: FC = () => {
  const model = PartnersModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_partners, { isLoading: true, partner: {} });
  const { code } = useParams();
 
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(code)){
        const model = new PartnersModel();
        const pathName = location.pathname;
        if (code) {
          model.PathName = pathName;
          const partner = await model.getPartnerByCode(code);
          if (!model.error) {
            dispatch(fetchPartner(partner,false));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchPartner({},false));
        }
      }
    };
    initialise();
  }, [code, location]);

  const { partner, isLoading } = state;

  if (isLoading) return <LoadingScreen />

  model.Title = partner.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(code)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={code} model={model} headerTitle={code ? model.Title : model.BtnAddText} />
        <Box mt={3}>
            <PartnersEditForm 
              model={model}
              partner={partner} 
            />
        </Box>
      </Container>
    </Page>
  );
};

export default PartnersEditView;
