import React, { ChangeEvent, useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  CustomerResource,
  CustomerResourceForEdit,
  DatabankResource,
  GroupDto,
  SubscriptionGroupResource,
} from 'src/common/types';
import { Box, Grid, SvgIcon, Typography } from '@mui/material';
import {
  Formik,
  labelConfig,
  CustomerModel,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  CaijInput,
  AccessModel
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import { Award as AwardIcon } from 'react-feather';
import useSubscription from 'src/hooks/useSubscription';
import SubscriptionAccessGroupList from 'src/components/access/SubscriptionAccessGroupList';

export interface SubscriptionEditFormProps {
  access: AccessModel;
  customer: CustomerResource;
  accessesLibrary: DatabankResource[];
  onHandleRefreshCustomer: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const SubscriptionEditForm: FC<SubscriptionEditFormProps> = ({
  access,
  customer,
  accessesLibrary,
  onHandleRefreshCustomer,
  onSwitchMode
}) => {
  const { getGroups, groups, groupsLoading, getGroup, group, dbGroup, groupLoading } = useSubscription();
  const [value, setValue] = useState<number | undefined>();
  
  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  useEffect(() => {
    setValue(dbGroup?.id);
  },[dbGroup]);

  useEffect(() => {
    const initialise = async () => {
      await getGroup(customer.subscriptionId,customer.subscriptionGroup.id, accessesLibrary);
      await getGroups(customer.subscriptionId);
    };
    (async () => {
      await initialise();
    })();
  },[customer]);

  const handleSubmit = async (values: CustomerResource): Promise<void> => {
    const customerModel = new CustomerModel();
    values.subscriptionGroup = dbGroup as GroupDto;
    const {subscriptionGroup, lawDomains, endorsedBy} = values;
    let submitData = values as CustomerResourceForEdit;
    if (submitData && submitData.id) {
      customerModel.saveRecords(submitData, subscriptionGroup, endorsedBy, lawDomains);
      const response = await customerModel.updateCustomer(submitData);
      if (!customerModel.error) {
        const { status } = response;
        printMessage({status, message: 'Groupe modifié.'});
        await onHandleRefreshCustomer();
      }  
    }
  };

  return (
    <Formik
      initialValues={ CustomerModel.getInitialValues(customer) }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Box display='flex'>
                <SvgIcon fontSize='small'>
                  <AwardIcon />
                </SvgIcon>
                <Box flexDirection="column" marginLeft='0.5em'>
                  <Typography sx={{ fontWeight: 'bold' }}>Abonnement</Typography>
                  <Box mt={2}>
                    { customer.subscription && (<Typography>{customer.subscription.name}</Typography>) }
                    <Box mt={1} sx={{display: groupsLoading ? 'block' : 'none'}}>
                      <CaijInput
                          required
                          label={labelConfig.groups}
                          id='subscriptionGroupId'
                          name='subscriptionGroupId'
                          onHandleChange={async (e:ChangeEvent<HTMLInputElement>) => {
                            const groupId = e.target.value;
                            if(groupId){
                              await getGroup(customer.subscriptionId, +groupId, accessesLibrary); 
                            }
                            setValue(+groupId);
                          }}
                          select
                          disabled={!access.Gestion}
                          value={value}
                          variant='outlined'
                          InputLabelProps={{ shrink: true, required: true }}
                          inputAttr={{ 'aria-label': 'subscriptionGroupId', 'data-testid': 'subscriptionGroupId' }}
                        >
                          { groups && groups.map((group: SubscriptionGroupResource) => (
                              <option value={group.id} key={group.id}>
                                {group.name}
                              </option>
                            ))
                          }
                      </CaijInput>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {
                groupLoading && group && (
                  <SubscriptionAccessGroupList
                    subscription={{
                      id: customer.subscriptionId,
                      databanks: group?.databanks,
                      documentCollections: group?.documentCollections,
                      products: group?.products.map(({ id, nameFr, parametersRequired, parameter }) => ({id, nameFr, parametersRequired, parameter}))
                    }}
                  />
                )
              }
            </Grid>
          </Grid>
          <Box mt={2}>
            <CaijButtonSubmit disabled={isSubmitting || !access.Gestion} sx={btnSubmit} />
            <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
          </Box>
        </form>
      )}
    </Formik>
  );
};

SubscriptionEditForm.propTypes = {
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired
};

export default SubscriptionEditForm;
