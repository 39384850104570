import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import en from 'date-fns/locale/fr-CA';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface ICaijLocalizationProvider {
  children: JSX.Element,
  useAdapterDayjs?: boolean;
}

export function CaijLocalizationProvider(props: ICaijLocalizationProvider) {
  if(props.useAdapterDayjs)
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {props.children}
      </LocalizationProvider>
    );
  else
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
        {props.children}
      </LocalizationProvider>
    );
}