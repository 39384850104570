import React, { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  SvgIcon,
  TableBody,
  TextField,
  InputAdornment,
  Link
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  EnhancedTableHead,
  link,
  EmployeeModel,
  fetchEmployees,
  _employee,
  getEmptyListSearch,
  getOpacity
} from 'src/common';
import type { EmployeesResource } from 'src/common/types';
import { Search as SearchIcon } from 'react-feather';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: EmployeeModel;
  employees: EmployeesResource[];
  onDeleteEmployee: (id: number) => Promise<void>;
  isEmptyList: boolean;
  dispatch: React.Dispatch<any>;
  getEmployees: () => Promise<void>;
}

const Results: FC<ResultsProps> = ({
  model,
  employees,
  onDeleteEmployee,
  isEmptyList,
  dispatch,
  getEmployees
}) => {
  const path = model.Path;
  const [search, setSearch] = useState('');
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState<number>(0);
  const headCell = model.getHeadCells(labelConfig.status);
  const { order, orderBy, sort, handleRequestSort } = useSort();

  const handleChange = async (event: ChangeEvent<any>) => {
    const { value } = event.target;
    if(value){
      const newEmployees = employees.filter(({firstname, lastname, email}) => {
        if (firstname.toLowerCase().indexOf(value.toLowerCase()) >= 0
              || lastname.toLowerCase().indexOf(value.toLowerCase()) >= 0
              || email.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
          return true;
        }
        return false;
      });
      dispatch(fetchEmployees(newEmployees, true, false));
    }else{ 
      await getEmployees();
    }
    setSearch(value);
  };

  const renderTableCell = (row: EmployeesResource) => {
    const opacity = getOpacity(row.active);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.firstname}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.lastname}</CaijTableCell>
        <CaijTableCell opacity={opacity}>
          <Link
            underline="none"
            href={`mailto:${row.email}`}
            sx={{...link,color:'text.secondary'}}
          >
            {row.email}
          </Link>
        </CaijTableCell>
        <CaijTableCellActive active={row.active} />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          showActiveEmployee={row.active}
          handleToggle={() => EmployeeModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.id) !== -1}
            onSubmit={async () => {
              await onDeleteEmployee(row.id)
              setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
        </CaijTableCellAction>
      </>
    )
  }

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
        <TextField
          name='search'
          value={search}
          variant='outlined'
          placeholder="Recherche d'utilisateur"
          sx={{ mr: 1, width: 360, mb: 1 }}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SvgIcon fontSize='small' color='action'>
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCell}
          notAllowedSort={employees.length === 0}
        />
        <TableBody>
          { employees.length === 0 ? getEmptyListSearch(headCell.length) : 
            sort<EmployeesResource>(employees, page).map((row: EmployeesResource) => (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  {renderTableCell(row)}
                </CaijTableRowClickable>
              )
            )
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight  
        len={employees.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  employees: PropTypes.array,
  onDeleteEmployee: PropTypes.func,
  isEmptyList: PropTypes.bool
};

Results.defaultProps = {
  employees: []
};

export default Results;