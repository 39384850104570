import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { LibraryTypeResource, LibraryTypeResourceForEdit } from 'src/common/types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  LibraryTypeModel,
  Mode,
  btnSubmit
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import LibraryTypeForm from 'src/components/library/libraryType/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: LibraryTypeModel;
  libraryType: LibraryTypeResource;
  onHandleRefreshLibraryType: (value: LibraryTypeResource) => void;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: LibraryTypeResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  libraryType,
  onHandleRefreshLibraryType,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const { NameFr, NameEn } = model;

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: LibraryTypeResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LibraryTypeModel();
    const submitData = values as LibraryTypeResourceForEdit;
    const libraryTypeId = submitData.id;
    if (submitData && libraryTypeId) {
      const response = await model.updateLibraryType(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message,
        });
        onHandleRefreshLibraryType(await model.getRefreshLibraryType(libraryTypeId.toString()));
      }
    }
  };

  return (
    <Formik
      initialValues={LibraryTypeModel.getInitialValues(libraryType)}
      validationSchema={validateSchema(model)}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                <LibraryTypeForm 
                   model={model}
                   libraryType={values}
                   errors={errors}
                   touched={touched}
                   handleBlur={handleBlur}
                   onHandleChange={handleChange}
                   setFieldValue={setFieldValue}
                />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  libraryType: PropTypes.object.isRequired,
  onHandleRefreshLibraryType: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
