import React, { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { SubscriptionResource, WfProfileDto } from 'src/common/types';
import {
  Box,
  Typography
} from '@mui/material';
import {$enum} from "ts-enum-util";
import {
  labelConfig,
  CaijCheckbox,
  CaijInput,
  FormikErrors,
  FormikTouched,
  CaijSelect,
  SbGroup,
  CaijDatePicker,
  _doorAccessProfile,
  CaijImage,
  SubscriptionModel
} from 'src/common';
import UploadImage from 'src/components/image/UploadImage';
import { useEnabledSymProfiles } from 'src/functions/hooks/symphony';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import CaijDialogs from 'src/components/dialog';

interface SubscriptionFormProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  errors: FormikErrors<SubscriptionResource>;
  touched: FormikTouched<SubscriptionResource>;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeCheckBox: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleStartDateChange?: (startDate: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleEndDateChange?: (endDate: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeImageUrl?: (imgFieldName: string, imageUrl: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const SubscriptionForm: FC<SubscriptionFormProps> = ({
  model,
  subscription,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  onHandleStartDateChange,
  onHandleEndDateChange,
  onHandleChangeImageUrl,
  setFieldValue,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [subscriptionName, setSubscriptionName] = useState<string>(subscription.name);
  const enabledSymProfile = useEnabledSymProfiles();
  model.Dialog.Header = "Désactiver l'abonnement";
  model.Dialog.BtnText = BtnOk.DISABLED;
  model.Dialog.Name = "l'abonnement";

  const { startDate, endDate, active, group, wfProfile, logoUrl, restrictedAccountCreation } = subscription;
  const { Group, Name, StartDate, EndDate, Active, WfProfile, LogoUrl, RestrictedAccountCreation } = model;
  
  const renderEnabledSymProfiles = () : JSX.Element => {
    const { isLoading, isEmptyList, profiles } = enabledSymProfile;
    return (
      <CaijInput
        label={WfProfile.Label}
        id={WfProfile.Name}
        name={WfProfile.Name}
        select
        value={wfProfile || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        error={Boolean(touched.wfProfile && errors.wfProfile)}
        helperText={(touched.wfProfile && errors.wfProfile) || ' '}
        inputAttr={{ 'aria-label': 'wfProfile', 'data-testid': 'wfProfile' }}
        sx={{ mt: 1, mb: 2, width: '100%' }}
        onHandleChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e, setFieldValue)}
        onHandleBlur={handleBlur}
      >
        <option value=''>Sélectionner un profil symphony</option>
        {isLoading && !isEmptyList && profiles.map((profile: WfProfileDto) => (
          <option value={profile.name} key={profile.name}>
            {profile.name}
          </option>
        ))}
      </CaijInput>
    )
  }

  return (
    <>
      <CaijSelect 
        name={Group.Name}
        id={Group.Name}
        label={Group.Label}
        required
        value={group}
        sx={{m: '1em 0'}}
        error={Boolean(touched.group && errors.group)}
        helperText={touched.group && errors.group}
        inputProps={{ 'aria-label': 'group', 'data-testid': Group.Name }}
        onHandleBlur={handleBlur}
        onHandleChangeSelect={onHandleChange}
        setFieldValue={setFieldValue}
      >
        { $enum(SbGroup).map((value, key) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))
        }
      </CaijSelect>
      <CaijInput
        name={Name.Name}
        required
        value={subscriptionName}
        label={Name.Label}
        error={Boolean(touched.name && errors.name)}
        helperText={touched.name && errors.name}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setSubscriptionName(e.target.value)}
        onHandleBlur={(e: ChangeEvent<HTMLInputElement>) => {
          subscription.name = subscriptionName;
          handleBlur(e);
        }}
        inputAttr={{maxLength: Name.MaxLength, 'data-testid' : Name.Name}}
        InputLabelProps={{ shrink: true, required: true }}
      />
      <Box mt={2} display='flex' alignItems='flex-start' justifyContent='space-between'>
        <Box display="flex" width="50%" justifyContent='left'>
          <CaijDatePicker 
            name={StartDate.Name}
            required
            value={String(startDate)}
            label={StartDate.Label}
            inputProps={{"data-testid": StartDate.Name}}
            helperText={touched.startDate && errors.startDate}
            onHandleChange={(l) => onHandleStartDateChange(l, setFieldValue)}
           />
        </Box>
        <Box width="50%" display="flex" justifyContent='right'>
          <CaijDatePicker 
            name={EndDate.Name}
            value={String(endDate)}
            label={EndDate.Label}
            inputProps={{"data-testid": EndDate.Name, 'aria-label': EndDate.Name}}
            helperText={touched.endDate && errors.endDate}
            onHandleChange={(l) => onHandleEndDateChange(l, setFieldValue)}
          />
        </Box>
      </Box>
      <Box>
        <CaijCheckbox
          name={RestrictedAccountCreation.Name}
          id={RestrictedAccountCreation.Name}
          checked={restrictedAccountCreation}
          value={restrictedAccountCreation}
          label={RestrictedAccountCreation.Label}
          onHandleChangeCheckBox={onHandleChangeCheckBox}
          inputProps={{'data-testid': RestrictedAccountCreation.Name}}
          setFieldValue={setFieldValue}
          sx={{mb:2}}
        />
      </Box>
      <Box>
        <CaijCheckbox
          name={Active.Name}
          id={Active.Name}
          label={Active.Label}
          checked={active}
          value={active}
          inputProps={{ 'aria-label': Active.Name, 'data-testid' : Active.Name }}
          onHandleChangeCheckBox={(e:ChangeEvent<HTMLInputElement>) => {
            e.target.checked ? setOpenDialog(false) : setOpenDialog(true);
            onHandleChangeCheckBox(e, setFieldValue);
          }}
        />
        <Box sx={{mb:3}}>
        {
          !active && (
            <Typography sx={{borderStyle: 'solid', borderWidth: '1px', borderColor: 'text.error', padding:'3px 0 3px 5px'}} variant="body2">
              L'ajout de membre ne sera pas possible et les membres déjà présent ne pourront plus se connecter à l'Espace CAIJ
            </Typography>
          )
        }
        </Box>
        <CaijDialogs
          dialog={model.Dialog}
          isOpen={openDialog}
          onSubmit={() => (async () => {
            setFieldValue('active', false);
          })()}
          close={() => { 
            !active ? setFieldValue('active', true) : '';
            setOpenDialog(false);
          }}
        />
        { renderEnabledSymProfiles() }
        { logoUrl && (
            <Box mb={2}>
              <CaijImage id="logoUrl" src={logoUrl} alt={logoUrl} data-testid='logoUrl' />
            </Box> 
          )
        }
        <Box mb={4}>
          <UploadImage
            imgLabel={LogoUrl.Label}
            resourceCode={model.ResourceCode} 
            style={{ height: '3em' }}
            maxLength={2000}
            btnText={logoUrl ? labelConfig.logoEditBtnFr : labelConfig.logoBtnFr}
            imgName={LogoUrl.Name}
            onHandleChangeImageUrl={onHandleChangeImageUrl}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Box>
    </>
  );
};

SubscriptionForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func.isRequired,
  onHandleStartDateChange: PropTypes.func,
  onHandleEndDateChange: PropTypes.func,
  onHandleChangeImageUrl: PropTypes.func
};

export default SubscriptionForm;
