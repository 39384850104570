import type { TranslationResource } from 'src/common/types';
import * as actionTypes from './ActionTypes';
import type Pagination from 'src/types/pagination';
import { TranslationModel } from 'src/common';
import { Paged } from 'src/types/pagination';

export const fetchTranslations = (
  translations: TranslationResource[], 
  paged?: Paged, 
  isLoading?: boolean,
  isEmptyList?: boolean, 
  isAuthorize?: boolean,
  model?: TranslationModel
): {
  type: 'FETCH_TRANSLATIONS',
  payload: {
    translations: TranslationResource[],
    paged: Paged,
    isEmptyList?: boolean,
    isLoading?: boolean,
    isAuthorize?: boolean,
    model?: TranslationModel
  }
} => ({
  type: actionTypes.FETCH_TRANSLATIONS,
  payload: {
    translations,
    paged,
    isLoading,
    isEmptyList,
    isAuthorize,
    model
  }
});

export const fetchTranslation = (translation: TranslationResource): {
  type: 'FETCH_TRANSLATION',
  payload: {
    translation: TranslationResource
  }
} => ({
  type: actionTypes.FETCH_TRANSLATION,
  payload: {
    translation
  }
});

