import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { TribunalResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { tableRow, TribunalModel } from 'src/common';
import { tableCell } from 'src/styles/tribunal';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface TribunalEnInfoProps {
  model: TribunalModel;
  tribunal: TribunalResource;
}

const TribunalEnInfo: FC<TribunalEnInfoProps> = ({
  model,
  tribunal
}) => {
  const { CodeEn, LabelEn  } = model;
  return (
    <MyCard>
    <CardHeaderDetail  title="Court" />
    <Divider />
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={tableCell}>
            { CodeEn.Label }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { tribunal.codeEn}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={tableRow}>
          <TableCell sx={tableCell}>
            { LabelEn.Label }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { tribunal.labelEn }
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </MyCard>
  );
};

TribunalEnInfo.propTypes = {
  tribunal: PropTypes.object.isRequired
};

export default TribunalEnInfo;
