import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid, Button } from '@mui/material';
import type { 
  MessagePlaceholderResource, 
  MessageResource, 
  MessageResourceForCreate
} from 'src/common/types';
import {
  Formik,
  handleChangeDateChange,
  handleChangeCheckBox,
  CaijButtonSubmit,
  Authorize,
  MessageModel,
  Mode,
  btnSubmit,
  FormikProps,
  MessagePlaceholderModel,
  FormikHelpers,
  NavigateFunction
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import MessageForm from 'src/components/message/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface MessageCreateFormProps {
  holder: MessagePlaceholderResource;
  message?: MessageResource;
  navigate?: NavigateFunction;
  switchMode?: (value?: boolean) => void;
  onSubmit?: (values: MessageResource) => void
}

const MessageCreateForm: FC<MessageCreateFormProps> = ({ holder, navigate, message, switchMode, onSubmit }) => {
  const model = MessageModel.getInstance();
  const [isAuth, setIsAuth] = useState<boolean>();
  const formRef = useRef<FormikProps<MessageResource>>(null);
  
  const handleStartDateChange = (startDate: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    handleChangeDateChange('startDate', startDate, setFieldValue);
  };

  const handleEndDateChange = (endDate: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    handleChangeDateChange('endDate', endDate, setFieldValue);
  };

  const exitHandler = () => {
    switchMode(false);
  };

  const handleSubmit = async (values: MessageResource, formikHelpers: FormikHelpers<typeof values>) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    formikHelpers.validateForm(values);
    const model = new MessageModel();
    let submitData = values as MessageResourceForCreate;
    const response = await model.insertMessage(submitData);
    if (!model.error) {
      printMessage({
        status: response.status,
        message: response.message
      });
      await model.redirect((new MessagePlaceholderModel()).Path.getDetail(holder.id));
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={MessageModel.getCreateInitialValues(message, holder)}
        validationSchema={validateSchema(model, formRef.current?.values.startDate)}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <MyCard>
              <CardContent>
                <Grid container spacing={2}>
                  <MessageForm
                    model={model}
                    message={values}
                    errors={errors}
                    touched={touched}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    onHandleStartDateChange={handleStartDateChange}
                    onHandleEndDateChange={handleEndDateChange}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </CardContent>
            </MyCard>
            <Box mt={2}>
              <Authorize resourceCode={model.ResourceCode} mode={Mode.add} onIsAuth={l => setIsAuth(l)}>
                <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
              </Authorize>
              <Button variant='outlined' disabled={isSubmitting} onClick={() => switchMode ? exitHandler() : navigate(`/app/management/placeholders/${holder.id}`, {replace: true})}>
                Annuler
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

MessageCreateForm.propTypes = {
  holder: PropTypes.any.isRequired,
  message: PropTypes.object,
  switchMode: PropTypes.func,
  onSubmit: PropTypes.func
};

MessageCreateForm.defaultProps = {
  onSubmit: null
};

export default MessageCreateForm;
