import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  _customer,
  AccessModel,
  PendingApprovalModel,
  CustomerModel,
  CustomerStatus,
  fetchPendingApproval,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from 'src/components/customer/customer/List/Others/Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  pendingApprovals: [],
  isLoading: false,
  isEmptyList: true
};

const PendingApprovalListView: FC = () => {
  const model = PendingApprovalModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_customer, initialState);
  
  const getPendingApproval = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const { search, pathname } = location;
      model.PathName = pathname + search;
      const results = await model.getCustomers({
        pageNumber: 1,
        pageSize: 500,
        status: CustomerModel.getStatusByVal(CustomerStatus.ActiveUponApproval)
      });
      if (!model.error) {
        dispatch(fetchPendingApproval(results.list, !Array.isArray(results.list) || results.list.length === 0, true));
      }
    }
  },[location]);

  useEffect(() => {
    (async () => {
      await getPendingApproval();
    })();
  },[]);

  const { pendingApprovals, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            customers={pendingApprovals}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default PendingApprovalListView;
