import { FC } from 'react';
import PropTypes from 'prop-types';
import { DatabankModel, LibraryModel } from 'src/common';
import type { DatabankResource, LibraryResource, SetFieldValue } from 'src/common/types';
import { Grid } from '@mui/material';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';

interface CaijDescriptionProps {
  model: LibraryModel | DatabankModel;
  data: LibraryResource | DatabankResource, 
  allowedBackgroundColor?: boolean;
  setFieldValue: SetFieldValue;
}

const CaijDescription: FC<CaijDescriptionProps> = ({ 
  model,
  data,
  allowedBackgroundColor,
  setFieldValue 
}) => {
  const { DescriptionFr, DescriptionEn } = model;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={DescriptionFr.Label}
          dataTestId={DescriptionFr.Name}
          value={data.descriptionFr}
          name={DescriptionFr.Name}
          setFieldValue={setFieldValue}
          allowedBackgroundColor={allowedBackgroundColor}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={DescriptionEn.Label}
          dataTestId={DescriptionEn.Name}
          value={data.descriptionEn}
          name={DescriptionEn.Name}
          setFieldValue={setFieldValue}
          allowedBackgroundColor={allowedBackgroundColor}
        />
      </Grid>
    </>
  );
}

CaijDescription.propTypes = {
  data: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  allowedBackgroundColor: PropTypes.bool
};

CaijDescription.defaultProps = {
  allowedBackgroundColor: true
}

export default CaijDescription;