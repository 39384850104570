import type { Resource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchResources = (
  resources: Resource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean
): {
  type: 'FETCH_RESOURCES';
  payload: {
    resources: Resource[],
    isLoading?: boolean,
    isEmptyList?: boolean,
    isAuthorize?: boolean
  };
} => ({
  type: actionTypes.FETCH_RESOURCES,
  payload: {
    resources,
    isLoading,
    isEmptyList,
    isAuthorize
  },
});

export const fetchResource = (resource: Resource,  isLoading?: boolean) : { 
  type: 'FETCH_RESOURCE',
  payload: { 
    resource: Resource,
    isLoading?: boolean
  } 
} => ({
  type: actionTypes.FETCH_RESOURCE,
  payload: {
    resource,
    isLoading
  },
});

export const fetchDetailsResource = (
  resource: Resource,
): {
  type: 'FETCH_DETAILS_RESOURCE';
  payload: {
    resource: Resource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_RESOURCE,
  payload: {
    resource,
  },
});
