import React, { useCallback, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import type { CoveoLogs } from 'src/common/types';
import { 
  CircularProgress,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { 
  EmptyList,
  EnhancedTableHead,  
  SearchEngineModel,  
  _coveoLogs,  
  formatDateTime, 
  tableRow
} from 'src/common';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTable from 'src/components/table/CaijTable';
import MyCard from 'src/components/card/MyCard';
import { fetchCoveoLogs } from 'src/store/actions/coveo/logs/LogsAction';
import useSort from 'src/functions/hooks/sort';

interface CoveoLogsHomeProps {
  maxCount: number;
  title?: () => JSX.Element;
  headCell: any[];
}

const CoveoLogsHome: FC<CoveoLogsHomeProps> = ({maxCount, title, headCell}) => {
  const [state, dispatch] = useReducer(_coveoLogs, {coveoLogs: [], coveoSources:[], isLoading: false, isEmptyList: true});
  const { order, orderBy, sort, handleRequestSort } = useSort();

  const fetchData = useCallback(async () => {
    let model = new SearchEngineModel();
    let coveoSources = await model.getLists();
    let results = await model.getCoveoLogsLists({pageNumber: 1, pageSize: maxCount});
    if(!model.error){
      dispatch(fetchCoveoLogs(results.list, coveoSources, true, !Array.isArray(results.list) || results.list.length === 0))
    }
  },[]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  },[]);

  return (
    <MyCard>
      {title && title()}
      <CaijTable>
        <EnhancedTableHead 
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          notAllowedSort={state.coveoLogs?.length === 0}
          headCells={headCell} />
        <TableBody>
        { !state.isLoading && (
            <TableRow sx={tableRow}>
              <TableCell colSpan={headCell.length} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow> 
          )
        }
        { (state.isLoading && state.isEmptyList) ? (
            <TableRow sx={tableRow}>
              <TableCell colSpan={headCell.length} align='center'>
                <EmptyList noCard /> 
              </TableCell>
            </TableRow>
          ) : sort<CoveoLogs>(state.coveoLogs,0).map((row: CoveoLogs, index: number) => (
              <TableRow hover tabIndex={-1} key={index} sx={tableRow}>
                <CaijTableCell>{row.id}</CaijTableCell>
                <CaijTableCell>{formatDateTime(row.created)}</CaijTableCell>
                <CaijTableCell>{state.coveoSources?.find(({id}) => id === row.coveoSourceId)?.name}</CaijTableCell>
                <CaijTableCell>{row.message}</CaijTableCell>
              </TableRow>
            )
          )
        }
        </TableBody>
      </CaijTable>
    </MyCard>
  )
};

export default CoveoLogsHome;
