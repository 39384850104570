import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentCollectionsResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { DocCollectionsModel, DocCollectionsSortOption, DOCUMENT_COLLECTION_TITLE, tableRow } from 'src/common';
import { tableCell } from 'src/styles/docCollection';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow } from 'src/components/table/CaijTableRow';

export interface DocCollectionsNavSortProps {
  model: DocCollectionsModel;
  collection: DocumentCollectionsResource;
}

const DocCollectionsNavSort: FC<DocCollectionsNavSortProps> = ({model, collection}) => (
  <MyCard>
    <CardHeaderDetail title={DOCUMENT_COLLECTION_TITLE} />
    <Divider />
    <Table>
      <TableBody>
        { model.DocumentCollectionsSorts.map((s, index) => {
            const prop = s.Name.split('.')[1];
            return (
              <CaijTableRow key={index} sxTableCell={tableCell} label={s.Label} sxTableRow={index === model.DocumentCollectionsSorts.length - 1 ? tableRow : null}>
                {DocCollectionsSortOption[collection.documentCollectionsSort?.[prop]]}
              </CaijTableRow>
            )
          })
        }
      </TableBody>
    </Table>
  </MyCard>
);

DocCollectionsNavSort.propTypes = {
  collection: PropTypes.object.isRequired
};

export default DocCollectionsNavSort;
