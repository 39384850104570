import { FC, useMemo } from "react";
import { ListType } from "src/common";
import { Grid } from "@mui/material";
import { LibraryServiceLibraryDto } from "src/common/types";
import Libraries from 'src/components/requiredParameter';

interface LibraryListProps {
  lists: LibraryServiceLibraryDto[];
  allowedQtyRequired: boolean;
  onHandleItemsSelected: (value: number[]) => void;
  onHandleRequireParmeters: (value: Map<number, string>) => void;
  setIsListValid: (value: boolean) => void;
}
const LibraryList: FC<LibraryListProps> = ({
  lists,
  allowedQtyRequired,
  onHandleItemsSelected,
  onHandleRequireParmeters,
  setIsListValid
}) => {
  return (
    <Grid item md={6} xs={12}>
      { useMemo(() => 
          <Libraries
            setIsListValid={(l) => setIsListValid(l)}
            isParametersRequired={allowedQtyRequired || false}
            listType={ListType.LibraryServiceLibrary}
            lists={lists}
            onHandleItemsSelected={l => onHandleItemsSelected(l)}
            onHandleRequireParmeters={l => onHandleRequireParmeters(l)}
          />
          ,[lists, allowedQtyRequired])
      }
    </Grid>
  )
};
export default LibraryList;