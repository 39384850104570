import { TableRow } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "src/common";

interface Params {
  path?: string;
  children?: ReactNode;
  sx?: any;
  target?: '_blank' | '_self' | '_parent' | '_top'
  callBack?: () => void;
};

const root = {
  '&:hover':{
    cursor: "pointer"
  }
}

export const CaijTableRowClickable = ({path, callBack,  target = '_self', children, ...rest}: Params) => (
  <TableRow sx={{...rest,...root}} hover tabIndex={-1} onClick={() => path ? window.open(path, target) : callBack()}>
    {children}
  </TableRow>
);

