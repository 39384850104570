import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  SvgIcon,
  TableBody
} from '@mui/material';
import {
  labelConfig,
  DatabankModel,
  getProxy,
  getOpacity,
} from 'src/common';
import { Activity as MonitoringIcon } from 'react-feather';
import { KnownDatabankStatusOption, type DatabankResource } from 'src/common/types';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import EmptyList from 'src/components/EmptyList';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: DatabankModel;
  databanks?: DatabankResource[];
  isEmptyList?: boolean;
  onDeleteDataBank: (id: number) => Promise<void>;
}

const Results: FC<ResultsProps> = ({ model, databanks, isEmptyList, onDeleteDataBank }) => {
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const path = model.Path;
  const monitoring = <SvgIcon fontSize='small'><MonitoringIcon /></SvgIcon>;
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();

  useEffect(() => setOrderBy('nameFr'),[]);

  const renderTableCell = (row: DatabankResource) => (
    <>
      <CaijTableCell opacity={getOpacity(row.enabled)}>
        <Box display="flex">
          { row.enabled && row.probe && <Box mr="10px">{monitoring}</Box> }
          <Box>
            {`${row.nameFr} ${getProxy(row)}`}
          </Box>
        </Box>
      </CaijTableCell>
      <CaijTableCell 
        allowedLabel 
        active={row.databankStatus == KnownDatabankStatusOption.Active} 
        txtActive="Disponible" 
        txtInActive="Indisponible" 
      />
      <CaijTableCellActive active={row.enabled} />
      <CaijTableCellAction 
        pageEditUrl={path.getEdit(row.id)}
        resourceCode={model.ResourceCode}
        handleToggle={() => DatabankModel.handleToggle(row.id, selectedRow, setSelectedRow)}
      >
        <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.id) !== -1}
            onSubmit={async () => {
              await onDeleteDataBank(row.id)
              setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
      </CaijTableCellAction>
    </>
  )

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={model.getHeadCells(labelConfig.status)}
        />
        <TableBody>
          { sort(databanks, page).map((row: DatabankResource) => (
              <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                {renderTableCell(row)}
              </CaijTableRowClickable>
            ))
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight 
        len={databanks.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  databanks: PropTypes.array,
  isEmptyList: PropTypes.bool,
  onDeleteDataBank: PropTypes.func.isRequired,
};

Results.defaultProps = {
  databanks: [],
};

export default Results;

{
/* <Card className={classes.textContainer}>
    <CardContent>
      <ThemeProvider theme={theme}>
        <Box display="flex-column" p={1}>
          <Box p={1}>
            <Typography variant="h6">
              Bases de données exclusives
            </Typography>
          </Box>
          <Box p={1}>
            <Typography variant="body1">
              Le CAIJ offre un accès exclusif à une grande variété de bases de données juridique québecoises, canadiennes et certaines
              internationales, en ligne et sur cédérom.
            </Typography>
          </Box>
          <Box p={1}>
            <Typography variant="body1">
              Cet accès est réservé aux membres du CAIJ et est disponible à partir des postes de travail situés dans les bibliothèque du CAIJ. À
              Montréal, l'accès est aussi offert au Salon des avocates du Palais de Justice de Montréal.
            </Typography>
          </Box>
          <Box p={1}>
            <Typography variant="body1">
              Télécharger le Tableau des opérateurs [PDF]
            </Typography>
          </Box>
          <Box p={1} textAlign="right">
            <Button
              variant="contained"
              type="submit"
              color="primary"
            >
              Modifier
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    </CardContent>
  </Card>
 */
}
