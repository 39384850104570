import { createContext, useReducer, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { SubscriptionModel, Forbidden, Unauthorized, ProductModel, NotFound, InternalServerError, LIBRARY_ACCESS, DatabankModel, DatabankAccessType, DatabankAppType } from 'src/common';
import { DatabankDto, DatabankResource, Error, SubscriptionGroupResource, SubscriptionResource } from 'src/common/types';
import printMessage from 'src/views/errors/MessageError';

export interface ProductSubscription {
  subscriptionId: number,
  groups: number[]
};

interface SubscriptionState {
  subscriptions: SubscriptionResource[];
  groups: SubscriptionGroupResource[];
  group: SubscriptionGroupResource;
  dbGroup: SubscriptionGroupResource;
  isLoading?: boolean;
  groupLoading?: boolean;
  groupsLoading?: boolean;
  isAuthorize?: boolean;
  isEmptyList?: boolean;
  error?: Error;
  withoutSubscription?: boolean;
  customerSubscription?:boolean;
  productSubscription?: ProductSubscription;
  subscriptionGroupId?: number;
}

export interface SubscriptionContextValue extends SubscriptionState {
  getGroups: (subscripionId:number) => Promise<void>;
  getGroup: (subscripionId:number, groupId: number, accessDatabanks: DatabankResource[]) => Promise<void>;
  setSubscriptionGroupId: (subscriptionGroupId:string) => void;
  getProductSubscription: (subscriptionGroupId:number) => Promise<void>;
}

interface SubscriptionProviderProps {
  children: ReactNode;
  withoutSubscription?: boolean;
  customerSubscription?: boolean;
}

type FetchSubscriptionsAction = {
  type: 'FETCH_SUBSCRIPTIONS';
  payload: {
    subscriptions: SubscriptionResource[];
    isAuthorize?: boolean;
    isLoading?: boolean;
    isEmptyList?: boolean;
    error?: Error;
  };
};

type FetchGroupsAction = {
  type: 'FETCH_GROUPS';
  payload: {
    groups: SubscriptionGroupResource[];
    groupsLoading: boolean;
  };
};

type FetchGroupAction = {
  type: 'FETCH_GROUP';
  payload: {
    group: SubscriptionGroupResource;
    dbGroup: SubscriptionGroupResource;
    groupLoading?: boolean;
  };
};

type FetchWithoutSubscriptionAction = {
  type: 'WITHOUT_SUBSCRIPTION';
  payload: {
    withoutSubscription: boolean;
  };
};

type FetchProductSubscriptionAction = {
  type: 'PRODUCT_SUBSCRIPTION';
  payload: {
    productSubscription: ProductSubscription;
  };
};

type FetchSubscriptionGroupIdAction = {
  type: 'SUBSCRIPTION_GROUP_ID';
  payload: {
    subscriptionGroupId: number;
  };
};

type FetchCustomerSubscriptionAction = {
  type: 'CUSTOMER_SUBSCRIPTION';
  payload: {
    customerSubscription: boolean;
  };
};

type Action = FetchSubscriptionsAction | 
              FetchGroupsAction | 
              FetchGroupAction |
              FetchWithoutSubscriptionAction |
              FetchProductSubscriptionAction |
              FetchSubscriptionGroupIdAction |
              FetchCustomerSubscriptionAction;

const initialSubscriptionState: SubscriptionState = {
  subscriptions: [],
  groups: [],
  dbGroup: null,
  group: null,
  isLoading: false,
  groupLoading: false,
  groupsLoading: false,
  isAuthorize: false,
  isEmptyList: true,
  error: null,
  withoutSubscription: false,
  customerSubscription: false,
  productSubscription: {
    subscriptionId: 0,
    groups: []
  },
  subscriptionGroupId: 0
};

const reducer = (state: SubscriptionState, action: Action): SubscriptionState => {
  switch (action.type) {
    case 'FETCH_SUBSCRIPTIONS': {
      const { subscriptions, isAuthorize, isLoading, isEmptyList, error } = action.payload;
      return {
        ...state,
        subscriptions,
        isAuthorize,
        isLoading,
        isEmptyList,
        error
      };
    }
    case 'FETCH_GROUPS': {
      const { groups, groupsLoading } = action.payload;
      return {
        ...state,
        groups,
        groupsLoading
      };
    }
    case 'FETCH_GROUP': {
      const { group, dbGroup, groupLoading } = action.payload;
      return {
        ...state,
        group,
        dbGroup,
        groupLoading
      };
    }
    case 'SUBSCRIPTION_GROUP_ID' : {
      const { subscriptionGroupId } = action.payload;
      return {
        ...state,
        subscriptionGroupId
      };
    }
    case 'WITHOUT_SUBSCRIPTION': {
      const { withoutSubscription } = action.payload;
      return {
        ...state,
        withoutSubscription
      };
    }
    case 'PRODUCT_SUBSCRIPTION':
      const { productSubscription } = action.payload;
      return {
        ...state,
        productSubscription
      };
    case 'CUSTOMER_SUBSCRIPTION':
      const { customerSubscription } = action.payload;
      return {
        ...state,
        customerSubscription
    };
    default: {
      return { ...state };
    }
  }
};

const SubscriptionContext = createContext<SubscriptionContextValue>({
  ...initialSubscriptionState,
  getGroups: (subscriptionId: number) : any => {},
  getGroup: (subscriptionId: number, groupId: number, accessDatabanks: DatabankResource[]) : any => {},
  setSubscriptionGroupId: (subscriptionGroupId: string) : any => {},
  getProductSubscription: (subscriptionGroupId: number) : any => {}
});

export const SubscriptionProvider: FC<SubscriptionProviderProps> = ({ children, withoutSubscription, customerSubscription }) => {
  const [state, dispatch] = useReducer(reducer, initialSubscriptionState);
  const getGroups = async (subscriptionId: number) : Promise<void> => {
    const model = new SubscriptionModel();
    model.skipHandleError = true;
    const groups = await model.getGroupList(subscriptionId);
    const { error } = model;
    if (!error && Array.isArray(groups)) {
      dispatch({
        type: 'FETCH_GROUPS',
        payload: {
          groups,
          groupsLoading: true
        }
      });
    }
  };

  const getGroup = async (subscriptionId: number, groupId: number, accessesLibrary: DatabankResource[]) : Promise<void> => {
    const model = new SubscriptionModel();
    model.skipHandleError = true;
    const group = await model.getGroup(subscriptionId, groupId);
    const { error } = model;
    if (!error) {
      const dbGroup = JSON.parse(JSON.stringify(group)) as SubscriptionGroupResource;
      if(group.products.some(({code}) => code === LIBRARY_ACCESS)){
        DatabankModel.getAvailableDatabankList(group,accessesLibrary);
      }
      dispatch({
        type: 'FETCH_GROUP',
        payload: {
          group,
          dbGroup,
          groupLoading: true
        }
      });
    }
  };

  const setSubscriptionGroupId = (subscriptionGroupId: string) : void => {
    dispatch({
      type: 'SUBSCRIPTION_GROUP_ID',
      payload: {
        subscriptionGroupId: +subscriptionGroupId
      }
    });
  };

  const getProductSubscription = async (subscriptionId: number) => {
    const model = new ProductModel();
    const productSubscriptions = await model.getProductSubscriptions();
    const { error } = model;
    if (error) {
      if(error.status === NotFound || error.status === InternalServerError){
        printMessage(error);
      }
      dispatch({
        type: 'PRODUCT_SUBSCRIPTION',
        payload: {
          productSubscription: { 
            subscriptionId: subscriptionId,
            groups:[]
          }
        }
      });
    }else{
      const groups = productSubscriptions[subscriptionId] || [];
      dispatch({
        type: 'PRODUCT_SUBSCRIPTION',
        payload: {
          productSubscription: { 
            subscriptionId: subscriptionId,
            groups
          }
        }
      });
    }
  };

  useEffect(() => {
    const initialise = async () => {
      const model = new SubscriptionModel();
      model.skipHandleError = true;
      const subscriptions = await model.getSubscriptions();
      const { error } = model;
      if(error) {
          if(error.status === Forbidden || error.status === Unauthorized) {
            dispatch({
              type: 'FETCH_SUBSCRIPTIONS',
              payload: {
                subscriptions,
                isAuthorize: false,
                isLoading: true,
                isEmptyList: true,
                error
              }
            });
          }else {
            dispatch({
              type: 'FETCH_SUBSCRIPTIONS',
              payload: {
                subscriptions,
                isLoading: true,
                isAuthorize: true,
                isEmptyList: !Array.isArray(subscriptions) || subscriptions.length === 0,
                error
              }
            });
          }
        }else{
          dispatch({
            type: 'FETCH_SUBSCRIPTIONS',
            payload: {
              subscriptions,
              isLoading: true,
              isAuthorize: true,
              isEmptyList: !Array.isArray(subscriptions) || subscriptions.length === 0,
            }
          });
        }
    };
    initialise();
    if(withoutSubscription){
      dispatch({
        type: 'WITHOUT_SUBSCRIPTION',
        payload: {
          withoutSubscription
        }
      });
      dispatch({
        type: 'CUSTOMER_SUBSCRIPTION',
        payload: {
          customerSubscription
        }
      });
    }
    return () => { dispatch(null); }
  }, [dispatch]);

  return (
    <SubscriptionContext.Provider
      value={{
        ...state,
        getGroups,
        getGroup,
        setSubscriptionGroupId,
        getProductSubscription
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

SubscriptionProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default SubscriptionContext;
