import { Box, TableCell } from "@mui/material"
import { FC, ReactNode } from "react";
import { 
  CustomerModel, 
  CustomerStatus, 
  getCustomerStatusLabelColor,
  getOpacity,
  Label, 
  labelConfig, 
} from "src/common"
import { CustomerResource } from "src/common/types";
import { CaijTableCell } from "src/components/table/CaijTableCell";
import useSettings from "src/hooks/useSettings";

interface TableCellsProps {
  customer: CustomerResource;
  children?: ReactNode;
}

const TableCells : FC<TableCellsProps> = ({customer, children}) => {
  const { settings } = useSettings();
  const opacity = getOpacity(customer.status !== CustomerModel.getStatusByVal(CustomerStatus.Inactive)); 
  return (
    <>
      <CaijTableCell opacity={opacity}>{customer.lastname}</CaijTableCell>
      <CaijTableCell opacity={opacity}>{customer.firstname}</CaijTableCell>
      <CaijTableCell opacity={opacity}>{customer.email}</CaijTableCell>
      <CaijTableCell opacity={opacity}>{customer.noCaij}</CaijTableCell>
      <CaijTableCell opacity={opacity}>
        { customer.subscription && (
            <Box display='flex' flexDirection='column' alignItems='left'>
              <Box mb={2}>
                <Label color={customer.subscription.active ? 'success' : 'error'}>
                  {customer.subscription.active ? labelConfig.active : labelConfig.inactive}
                </Label>
              </Box>
              <Box>{ customer.subscription.name }</Box>
            </Box>
          )
        }
      </CaijTableCell>
      <CaijTableCell opacity={opacity}>{customer?.subscriptionGroup?.name}</CaijTableCell>
      <CaijTableCell opacity={opacity}>{customer.extIdentifier}</CaijTableCell>
      <CaijTableCell opacity={opacity}>{customer.card}</CaijTableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Label color={getCustomerStatusLabelColor(customer.status)}>{CustomerModel.getStatusByKey(customer.status)}</Label>
          </Box>
          <Box>
            {customer.blocked && <Label color={getCustomerStatusLabelColor(CustomerStatus.Restreint,settings)}>{labelConfig.blocked}</Label>}
          </Box>
        </Box>
      </TableCell>
      {children}
    </>
  )
};

export default TableCells;