import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { Theme } from 'src/theme';

export interface WarningProps {
  title: string;
  message: string;
};

const Warning: FC<WarningProps> = ({title, message}) => {
  return (
    <Box display='flex' flexDirection='column' sx={{backgroundColor: (theme: Theme) => theme.palette.error.main, padding:'15px', borderRadius:'3px'}}>
      <Typography variant="h6" color='#ffffff' sx={{ml:2}}>
        {title}
      </Typography>
      <Typography variant="body2" color='#ffffff' sx={{ml:2}}>
        {message}
      </Typography>
    </Box>
  );
};

Warning.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default Warning;