import { FC, useState } from "react";
import { Box, Link, SvgIcon, Typography } from '@mui/material';
import { 
  AccessModel, 
  CustomerModel, 
  MSG_SEND_EMAIL_MEMBER, 
  PendingChangeType, 
  link 
} from "src/common";
import { CustomerResource } from "src/common/types";
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import {
  Send as SendIcon,
	CheckSquare as CheckIcon,
	Trash as DeletedIcon
} from 'react-feather';
import printMessage from "src/views/errors/MessageError";
import { greyed } from "src/styles/customer";

interface CustomerPendingEmailProps {
  customer: CustomerResource;
  onHandleRefreshCustomer: () => Promise<void>;
  model: CustomerModel;
}

interface ILoading {
  resend: boolean;
  validate: boolean;
	delete: boolean;
}

async function resendCustomerRequest(id: number, requestId: string, setLoading: any) : Promise<void> {
  setLoading((loading: ILoading) => {
		return {
			...loading,
			resend: true
		}
	})
  const model = new CustomerModel();
  const response = await model.resendCustomerRequest(id, requestId);
	const { error } = model;
  if(!error){
		const { status, message } = response;
    printMessage({status, message});
  }
	setLoading((loading: ILoading) => {
		return {
			...loading,
			resend: false
		}
	});
};

async function validateCustomerRequest(requestId: string, setLoading: any) : Promise<boolean>{
	setLoading((loading: ILoading) => {
		return {
			...loading,
			validate: true
		}
	});
  const model = new CustomerModel();
  const response = await model.validateCustomerRequest(requestId);
	const { error } = model;
  if(!error){
		const { status, message } = response;
    model.printEmailMessage(status,MSG_SEND_EMAIL_MEMBER + 'pour confirmer la modification du courriel');
    printMessage({status, message});
		return true;
  }
  setLoading((loading: ILoading) => {
		return {
			...loading,
			validate: false
		}
	});
  return false;
}

async function deleteCustomerRequest(id: number, requestId: string, setLoading: any) : Promise<boolean> {
	setLoading((loading: ILoading) => {
		return {
			...loading,
			delete: true
		}
	});
  const model = new CustomerModel();
  const response = await model.deleteCustomerRequest(id,requestId);
  const { error } = model;
	if(!error){
		const { status, message } = response;
		printMessage({ status, message });
		return true;
	}
  setLoading((loading: ILoading) => {
		return {
			...loading,
			delete: false
		}
	});
  return false;
}

const CustomerPendingEmail: FC<CustomerPendingEmailProps> = ({
  customer, 
  onHandleRefreshCustomer,
  model
}) => {
  const [loading, setLoading] = useState<ILoading>({resend: false, validate: false, delete: false});
  if(customer.customerPendingChanges){
    const customerPendingEmail = customer.customerPendingChanges.find(({changeType}) => changeType === CustomerModel.getPendingChangeTypeByVal(PendingChangeType.Email));
    if(customerPendingEmail){
      const access = new AccessModel(model.ResourceCode);
      return (
        <>
          <Typography sx={{...greyed, marginTop: '1em' }}>Adresse courriel en attente</Typography>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Box mr={2} mb={1}>
              <Link
                underline='none'
                variant='body2'
                sx={{ ...link, color: 'text.primary' }}
                href={`mailto:${customerPendingEmail.pendingValue}`}
              >
                <Typography>{customerPendingEmail.pendingValue}</Typography>
              </Link>
            </Box>
            <Box>
              <LoadingButton
                size="small"
                loading={loading.resend}
                disabled={access.UserUser && !access.Consultation}
                loadingPosition="start"
                startIcon={<SvgIcon fontSize='small'><SendIcon /></SvgIcon>}
                variant="text"
                onClick={() => resendCustomerRequest(customer.id, customerPendingEmail.id, setLoading)}	
                sx={{mr:1}}
              >
                Renvoyer le lien de validation
              </LoadingButton>
              <LoadingButton
                size="small"
                loading={loading.validate}
                loadingPosition="start"
                startIcon={<SvgIcon fontSize='small'><CheckIcon /></SvgIcon>}
                variant="text"
                onClick={async () => {
                  if (await validateCustomerRequest(customerPendingEmail.id, setLoading)) {
                    await onHandleRefreshCustomer();
                  }
                }}	
                sx={{mr:1}}
              >
                Approuver l'adresse
              </LoadingButton>
              <LoadingButton
                size="small"
                loading={loading.delete}
                disabled={access.UserUser && !access.Consultation}
                loadingPosition="start"
                startIcon={<SvgIcon fontSize='small'><DeletedIcon /></SvgIcon>}
                variant="text"
                onClick={async () => {
                  if (await deleteCustomerRequest(customer.id, customerPendingEmail.id, setLoading)) {
                    await onHandleRefreshCustomer();
                  }
                }}	
              >
                Supprimer l'adresse
              </LoadingButton>
            </Box>
          </Box>
        </>
      )
    }
  }
}

CustomerPendingEmail.propTypes = {
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired,
  model: PropTypes.instanceOf(CustomerModel).isRequired
}

export default CustomerPendingEmail;
