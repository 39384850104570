import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import type { Theme } from 'src/theme';
import Page from 'src/components/Page';
import { PageModel } from 'src/model/App';
import { PathModel } from 'src/common';

const root = {
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: (theme: Theme) => theme.spacing(3),
  paddingTop: 25,
  paddingBottom: 80
};

const NotFoundView: FC = () => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const model = new PageModel();

  return (
    <Page
      sx={root}
      title={"404 : Page non trouvée" + model.ADMIN_CAIJ}
    >
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          sx={{color:"text.primary"}}
        >
          404 : La page que vous cherchez n'existe pas ou plus
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <img
            alt="Under development"
            style={{ maxWidth: '100%',
            width: 560,
            maxHeight: 300,
            height: 'auto'}}
            src="/static/images/undraw_page_not_found_su7k.svg"
          />
        </Box>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          <Button
            color="secondary"
            component={RouterLink}
            to={PathModel.Root}
            variant="outlined"
          >
            {model.BackToHome}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default NotFoundView;
