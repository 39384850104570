import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  MSG_UNEXPECTED_TOKEN_JSON,
  UnExpectTokenJson,
  Created,
} from 'src/common';
import type { 
  MessagePlaceholderResource, 
  MessagePlaceholderResourceForEdit, 
  MessagePlaceholderResourceForCreate,
  Error,
  CreateResponse
} from 'src/common/types';
import axios from 'src/utils/axios';
import AppModel from './App';

type TypeResourceEdit = MessagePlaceholderResourceForCreate | MessagePlaceholderResourceForEdit;

export default class MessagePlaceholderModel extends AppModel 
{
  private static _instance: MessagePlaceholderModel;

  constructor(){
    super('/message/placeholder');
    this._headerTitle = "Liste d'emplacements";
    this._resourceCode = 'MESSAGE';
    this.Path.PathName = '/placeholders';
  }
  
  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Messages',
      href: this.Path.Home,
      visible: true
    }
  }

  static getInstance(): MessagePlaceholderModel {
    if (!MessagePlaceholderModel._instance) {
      MessagePlaceholderModel._instance = new MessagePlaceholderModel();
    }
    return MessagePlaceholderModel._instance;
  }

  async getMessagePlaceholders(): Promise<MessagePlaceholderResource[]> {
    let messagePlaceholders: MessagePlaceholderResource[];
    await axios.get<MessagePlaceholderResource[]>(`${this.route}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === 200) {
            messagePlaceholders = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (error) {
          if (status === NoContent) {
            this.error = { status, message: error.message };
          } else {
            this.error = { status: UnExpectTokenJson, message: MSG_UNEXPECTED_TOKEN_JSON };
          }
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return messagePlaceholders;
  }
  async getMessagePlaceholderById(id: number): Promise<MessagePlaceholderResource> {
    let messagePlaceholder: MessagePlaceholderResource;
    await axios.get<MessagePlaceholderResource>(`${this.route}/${id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            messagePlaceholder = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return messagePlaceholder;
  }

  async insertMessagePlaceholder(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<MessagePlaceholderResourceForCreate, CreateResponse>(`${this.route}`, submitData).then(
      async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message =  'Message placeholder créé.';
        } 
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async updateMessagePlaceholder(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<MessagePlaceholderResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message =  'Message placeholder modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }
}
