import React, { ChangeEvent, useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {Box,TableBody} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  ListType,
  getEmptyListSearch,
  ContentPublicationsModel,
  CaijInput,
  getOpacity,
  NavigateFunction,
  FIRST_PAGE,
  EnhancedTableHead
} from 'src/common';
import type { 
  ContentPublicationsResource,
  SearchFilter
} from 'src/common/types';
import CaijTable from 'src/components/table/CaijTable';
import MyCard from 'src/components/card/MyCard';
import Search from 'src/components/search';
import { filters } from 'src/styles/publication';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell, CaijTableCellActive, CaijTableCellConfirm } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { Paged } from 'src/types/pagination';
import parse from 'html-react-parser';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';

export interface ResultsProps {
  contentPublications: ContentPublicationsResource[];
  onDeleteContentPublication: (code: string) => Promise<void>;
  getCollection: (code: string) => JSX.Element;
  getCollectionList: () => JSX.Element[];
  isEmptyList: boolean;
  model: ContentPublicationsModel;
  paged: Paged;
  navigate: NavigateFunction;
  location: {search: string}
}

const Results: FC<ResultsProps> = ({
  contentPublications,
  onDeleteContentPublication,
  getCollection,
  getCollectionList,
  isEmptyList,
  model,
  paged,
  navigate,
  location
}) => {
  const path = model.Path;
  const currentPage = paged.currentPage - 1;
  const [selectedRow, setSelectedRow] = useState([]);
  const headCells = model.getHeadCells();
  const [filter, setFilter] = useState<SearchFilter>({
    collection: '',
    query: new URLSearchParams(location?.search).get("query"),
    doSearch: false,
    page: currentPage
  });
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location?.search);
    const {Filters: { page, query, collection }} = model;
    let queryVal = '', pageVal = FIRST_PAGE, collectionVal = '';
    for (const [key, value] of urlParams.entries()) {
      switch(key){
        case page:
          pageVal = +value;
          break;
        case query:
          queryVal = value;
          break;
        case collection:
          collectionVal = value;
          break;
      }
    }
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        collection: collectionVal,
        doSearch: queryVal || collectionVal ? true : false,
        page: pageVal 
      }
    });
  },[location?.search]);

  const handleCollectionChange = async (event: ChangeEvent<HTMLInputElement>) => {
    model.resetPageValue(filter);
    model.getConfigParameters(filter,4,event.target.value);
    navigate(model.getUrlEncode.apply(model,model.getParams()));
  };

  const renderTableCell = (row: ContentPublicationsResource) => {
    const opacity = getOpacity(row.visible);
    return (
      <>
        <CaijTableCell opacity={opacity}>{parse(row.code)}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{parse(row.title)}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.authors}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{getCollection(row.collection)}</CaijTableCell>
        <CaijTableCellActive active={row.searchable} cellAlign='center' />
        <CaijTableCellActive active={row.visible} cellAlign='center' />
        <CaijTableCellConfirm confirm={row.isFrequentlyConsulted} cellAlign='center' />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(encodeURIComponent(row.code))}
          resourceCode={model.ResourceCode}
          handleToggle={() => ContentPublicationsModel.handleToggle(row.code, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.code) !== -1}
            onSubmit={async () => {
                await onDeleteContentPublication(row.code);
                setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
        </CaijTableCellAction>
      </>
    )
  }

  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      <Box minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
        <Box sx={{margin:'0 0 20px 15px'}}>
          <Search 
            listType={ListType.Publication}
            placeholder='Recherche la/les publication(s)' 
            filter={filter}
            width="400px"
            path={path.Home}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between' sx={{margin:'20px 15px'}}>
          <CaijResetFilterButton path={path.Home} navigate={navigate} />
          <CaijInput
            label={labelConfig.collection}
            id='collection'
            name='collection'
            onHandleChange={handleCollectionChange}
            select
            value={filter.collection || ''}
            sx={filters}
            InputLabelProps={{ shrink: true }}
          >
            <option value=''>Tous</option>
            { getCollectionList() }
          </CaijInput>
        </Box>
      </Box>
      <CaijTable>
        <EnhancedTableHead notAllowedSort headCells={headCells} />
        <TableBody>
          { contentPublications.length === 0 ? getEmptyListSearch(headCells.length) :
            contentPublications.map((row: ContentPublicationsResource, index: number) => (
                  <CaijTableRowClickable key={index} path={path.getDetail(encodeURIComponent(row.code),'/wc')}>
                    {renderTableCell(row)}
                  </CaijTableRowClickable>
                )
            )
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={paged} 
        model={model} 
        filter={filter} 
        page={currentPage}
        navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
      />
    </MyCard>
  );
};

Results.propTypes = {
  contentPublications: PropTypes.array.isRequired,
  onDeleteContentPublication: PropTypes.func.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  getCollection: PropTypes.func.isRequired,
  getCollectionList: PropTypes.func.isRequired,
  model: PropTypes.instanceOf(ContentPublicationsModel)
};

export default Results;
