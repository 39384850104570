import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ProductResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { labelConfig, Label, tableRow, ProductModel } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/product';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ProductFrInfoProps {
  model: ProductModel;
  product: ProductResource;
}

const ProductFrInfo: FC<ProductFrInfoProps> = ({
  model,
  product
}) => {
  const { nameFr, descriptionFr, code, active } = product;
  const { NameFr, DescriptionFr, Code, Active } = model;
  return (
    <MyCard>
      <CardHeaderDetail title='Produits et services' />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>{NameFr.Label}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {nameFr}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>{DescriptionFr.Label}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary', whiteSpace: 'pre-wrap'}}>
                {descriptionFr}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>{Code.Label}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {code}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>{Active.Label}</TableCell>
            <TableCell>
              <Label color={active ? 'success' : 'error'}>{active ? labelConfig.active : labelConfig.inactive}</Label>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ProductFrInfo.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductFrInfo;
