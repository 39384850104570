import PropTypes from 'prop-types';
import { FC, ReactNode, useReducer, useEffect } from 'react';
import { createContext } from 'react';
import { Forbidden, LawPracticeFieldModel, Unauthorized } from 'src/common';
import { LawPracticeFieldDto, LawPracticeFieldResource } from 'src/common/types';

export interface LawPraticeFieldContextValue {
  verifyLawPraticeFieldCode: (value:string, currentCode: string) => boolean;
  setSelectedLawPraticeFields: (lawPraticeFields: LawPracticeFieldDto[]) => void;
  selectedLawPracticeFields: LawPracticeFieldDto[];
  lawPracticeFields: LawPracticeFieldResource[];
  isAuthorize: boolean;
  isLoading: boolean;
  isEmptyList: boolean;
}

interface LawPracticeFieldProviderProps {
  children: ReactNode;
}

type fetchSelectedLawPracticeFieldsAction = {
  type: 'FETCH_SELECTED_LAW_PRATICE_FIELDS';
  payload: {
    selectedLawPracticeFields: LawPracticeFieldDto[];
  };
};

type fetchLawPracticeFieldsAction = {
  type: 'FETCH_LAW_PRATICE_FIELDS';
  payload: {
    lawPracticeFields: LawPracticeFieldResource[];
    isAuthorize: boolean;
    isLoading: boolean;
    isEmptyList: boolean;
  };
};

type Action = fetchSelectedLawPracticeFieldsAction | fetchLawPracticeFieldsAction;

interface LawPracticeFieldState {
  selectedLawPracticeFields: LawPracticeFieldDto[]; 
  lawPracticeFields: LawPracticeFieldResource[];
  isAuthorize: boolean;
  isLoading: boolean;
  isEmptyList: boolean;
}

const initialLawPracticeFieldState = {
  selectedLawPracticeFields: [],
  lawPracticeFields: [],
  isLoading: false,
  isAuthorize: false,
  isEmptyList: true
};

const reducer = (state: LawPracticeFieldState, action: Action): LawPracticeFieldState => {
  switch (action.type) {
    case 'FETCH_SELECTED_LAW_PRATICE_FIELDS': {
      const { selectedLawPracticeFields } = action.payload;
      return {
        ...state,
        selectedLawPracticeFields
      };
    }
    case 'FETCH_LAW_PRATICE_FIELDS': {
      const { lawPracticeFields, isLoading, isAuthorize, isEmptyList} = action.payload;
      return {
        ...state,
        lawPracticeFields,
        isLoading,
        isAuthorize,
        isEmptyList
      };
    }
    default: {
      return { ...state };
    }
  }
};

const LawPraticeFieldContext = createContext<LawPraticeFieldContextValue>({
  ...initialLawPracticeFieldState,
  verifyLawPraticeFieldCode : (value:string, currentCode: string) : any => {},
  setSelectedLawPraticeFields: (lawPraticeFields:LawPracticeFieldDto[]) => {}
});

export const LawPraticeFieldProvider: FC<LawPracticeFieldProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialLawPracticeFieldState);
  
  const setSelectedLawPraticeFields = (lawPraticeFields: LawPracticeFieldDto[]) => {
     if(lawPraticeFields){
      dispatch({
        type: 'FETCH_SELECTED_LAW_PRATICE_FIELDS',
        payload: {
          selectedLawPracticeFields: lawPraticeFields
        }
      });
     }
  };

  useEffect(() => {
    const initialise = async () => {
      const model = new LawPracticeFieldModel();
      model.skipHandleError = true;
      const lawPracticeFields = await model.getLawPracticeFields();
      const { error } = model;
      if (error) {
        if(error.status === Forbidden || error.status === Unauthorized){
          dispatch({
            type: 'FETCH_LAW_PRATICE_FIELDS',
            payload: {
              lawPracticeFields:[],
              isLoading: true,
              isEmptyList: true,
              isAuthorize: false
            }
          });
        }else{
          dispatch({
            type: 'FETCH_LAW_PRATICE_FIELDS',
            payload: {
              lawPracticeFields:[],
              isLoading: true,
              isEmptyList: true,
              isAuthorize: true,
            }
          });
        }
      }else{
        dispatch({
          type: 'FETCH_LAW_PRATICE_FIELDS',
          payload: {
            lawPracticeFields,
            isLoading: true,
            isEmptyList: !Array.isArray(lawPracticeFields) || lawPracticeFields.length === 0,
            isAuthorize: true,
          }
        });
      }
    };
    initialise();
    return () => { dispatch({type: 'FETCH_LAW_PRATICE_FIELDS', payload: initialLawPracticeFieldState}) }
  },[]);
  
  const verifyLawPraticeFieldCode = (value: string, currentCode: string) : boolean => {
    const { lawPracticeFields } = state;
    const index = lawPracticeFields && lawPracticeFields.findIndex((lawPracticeField: LawPracticeFieldResource) => lawPracticeField.code.toLowerCase().trim() === value.toLowerCase().trim() && lawPracticeField.code !== currentCode);
    if (index >= 0) {
      return false;
    }
    return true;
  }
  
  return (
    <LawPraticeFieldContext.Provider
      value={{
        ...state,
        verifyLawPraticeFieldCode,
        setSelectedLawPraticeFields
      }}
    >
      {children}
    </LawPraticeFieldContext.Provider>
  );
};

LawPraticeFieldProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default LawPraticeFieldContext;
