import type { LibraryTypeResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchLibraryTypes = (
  libraryTypes: LibraryTypeResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
): {
  type: 'FETCH_LIBRARY_TYPES';
  payload: {
    libraryTypes: LibraryTypeResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
} => ({
  type: actionTypes.FETCH_LIBRARY_TYPES,
  payload: {
    libraryTypes,
    isLoading,
    isEmptyList,
    isAuthorize,
  },
});

export const fetchLibraryType = (libraryType: LibraryTypeResource, isLoading?: boolean): {
  type: 'FETCH_LIBRARY_TYPE';
  payload: { 
    libraryType: LibraryTypeResource, 
    isLoading?: boolean 
  };
} => ({
  type: actionTypes.FETCH_LIBRARY_TYPE,
  payload: {
    libraryType,
    isLoading
  },
});

export const fetchDetailsLibraryType = (
  libraryType: LibraryTypeResource,
): {
  type: 'FETCH_DETAILS_LIBRARY_TYPE';
  payload: { libraryType: LibraryTypeResource };
} => ({
  type: actionTypes.FETCH_DETAILS_LIBRARY_TYPE,
  payload: {
    libraryType,
  },
});

export const deleteLibraryType = (
  id: number,
): {
  type: 'DELETE_LIBRARY_TYPE';
  payload: { id: number };
} => ({
  type: actionTypes.DELETE_LIBRARY_TYPE,
  payload: {
    id,
  },
});
