import type { ContentMostViewedResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchContentMostVieweds = (contentMostVieweds: ContentMostViewedResource[], isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_CONTENT_MOST_VIEWEDS',
  payload: {
    contentMostVieweds: ContentMostViewedResource[],
    isLoading?: boolean,
    isEmptyList?: boolean
  }
} => ({
  type: actionTypes.FETCH_CONTENT_MOST_VIEWEDS,
  payload: {
    contentMostVieweds,
    isLoading,
    isEmptyList
  }
});

export const fetchContentMostViewed = (contentMostViewed: ContentMostViewedResource, isLoading?: boolean): { 
  type: 'FETCH_CONTENT_MOST_VIEWED', 
  payload: { 
    contentMostViewed: ContentMostViewedResource,
    isLoading?: boolean;
  } } => ({
  type: actionTypes.FETCH_CONTENT_MOST_VIEWED,
  payload: {
    contentMostViewed,
    isLoading
  }
});

export const fetchDetailsContentMostViewed = (
  contentMostViewed: ContentMostViewedResource,
): {
  type: 'FETCH_DETAILS_CONTENT_MOST_VIEWED'
  payload: {
    contentMostViewed: ContentMostViewedResource
  }
} => ({
  type: actionTypes.FETCH_DETAILS_CONTENT_MOST_VIEWED,
  payload: {
    contentMostViewed
  }
});

export const deleteContentMostViewed = (
  id: number,
): {
  type: 'DELETE_CONTENT_MOST_VIEWED'
  payload: {
    id: number
  }
} => ({
  type: actionTypes.DELETE_CONTENT_MOST_VIEWED,
  payload: {
    id
  }
});
