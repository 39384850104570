import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { LibraryTypeResource } from 'src/common/types';
import {
  Grid
} from '@mui/material';
import {
  CaijInput,
  FormikErrors,
  FormikTouched,
  LibraryTypeModel
} from 'src/common';

interface LibraryTypeFormProps {
  model: LibraryTypeModel;
  libraryType: LibraryTypeResource;
  errors: FormikErrors<LibraryTypeResource>;
  touched: FormikTouched<LibraryTypeResource>;
  handleBlur: (e: any) => void;
  onHandleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const LibraryTypeForm: FC<LibraryTypeFormProps> = ({
  model,
  libraryType,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const {NameFr, NameEn } = model;
  return (
    <>
     <Grid
        item
        md={6}
        xs={12}
      >
        <CaijInput
          name={NameFr.Name}
          id={NameFr.Name}
          value={libraryType.nameFr}
          error={Boolean(touched.nameFr && errors.nameFr)}
          helperText={touched.nameFr && errors.nameFr}
          label={NameFr.Label}
          InputLabelProps={{ shrink: true }}
          inputAttr={{maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <CaijInput
          name={NameEn.Name}
          value={libraryType.nameEn}
          error={Boolean(touched.nameEn && errors.nameEn)}
          helperText={touched.nameEn && errors.nameEn}
          label={NameEn.Label}
          InputLabelProps={{ shrink: true }}
          inputAttr={{maxLength: NameEn.MaxLength,  'data-testid': NameEn.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
};

LibraryTypeForm.propTypes = {
  libraryType: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default LibraryTypeForm;
