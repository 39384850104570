import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/common/types';
import { Box, Container } from '@mui/material';
import {
  useParams,
  useLocation,
  fetchMessagePlaceholder,
  _messagePlaceholder,
  MessagePlaceholderModel,
  MessageModel,
  isNaN,
  AccessModel,
  root,
  useNavigate
} from 'src/common';
import Page from 'src/components/Page';
import DetailsRow from './DetailsRow';
import MessagesList from './MessagesList';
import printMessage from 'src/views/errors/MessageError';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  placeholder: {},
};

const MessagesDetailsView: FC = () => {
  const model = MessageModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_messagePlaceholder, initialState);
  const { id } = useParams();
  const [createMsg, setCreateMsg] = useState<boolean>(false);
  const navigate = useNavigate();
  
  const switchCreateMsg = (isCreating: boolean) => {
    setCreateMsg(isCreating);
  };

  const getMessagePlaceholder = useCallback(async () : Promise<void> => {
    const model = new MessagePlaceholderModel();
    if (!id || isNaN(+id)) {
      await model.redirect(model.Path.Home);
    }else if(access.canRead()){
      model.PathName = location.pathname;
      const placeholder = await model.getMessagePlaceholderById(Number(id));
      if (!model.error) {
        const messageModel = new MessageModel();
        messageModel.setActiveCheckMessage(placeholder.messages);
        let activeCheckMessages = messageModel.getActiveCheckMessage();
        placeholder.messages = [];
        activeCheckMessages.forEach((value, key) => {
          placeholder.messages = [...placeholder.messages, ...value];
        })
        dispatch(fetchMessagePlaceholder(placeholder));
      }
    }
  }, [id, location]);
  
  useEffect(() => {
    (async () => {
      await getMessagePlaceholder();
    })();
  },[]);

  const deleteMessage: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      const model = new MessageModel();
      const response = await model.removeMessage(id);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await getMessagePlaceholder();
      }
    }
  };

  const { placeholder } = state;
  
  model.Title = placeholder.name;

  return (
    <Page sx={root} title={model.getEditMessagePageTitle(createMsg)}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle="Liste des messages"/>
        <Box mt={3}>
          <DetailsRow
            model={model}
            colorRender={(l) => MessageModel.colorRender(l)}
            messageView={false}
            isCreating={createMsg}
            createMsg={switchCreateMsg}
            placeholder={placeholder}
            navigate={navigate}
          />
        </Box>
        <Box mt={3}>
          <MessagesList
            model={model}
            colorRender={(l) => MessageModel.colorRender(l)}
            placeholder={placeholder}
            onDeleteMessage={(id: number) => deleteMessage(id)}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default MessagesDetailsView;
