import React, { FC, useCallback, useState, useEffect, ChangeEvent } from 'react';
import { 
  Box, 
  Grid, 
  Card, 
  CardHeader, 
  Tabs, 
  Tab, 
  Divider, 
  Table, 
  TableBody, 
  TableRow, 
  Typography,
  DialogActions, 
  DialogContent, 
  DialogTitle,  
  Dialog,
  SvgIcon,
  Button
} from '@mui/material';
import { 
  Charge, 
  ChargeHistory, 
  CustomerLibraryAccountInfoResource 
} from 'src/common/types';
import { 
  AccessModel,
  CaijButton,
  CaijInput,
  CustomerModel, 
  dialog, 
  dialogTitle, 
  formatDate, 
  labelConfig,
  useNavigate, 
  useParams 
} from 'src/common';
import { info } from '../../../../styles/customer';
import LoadingScreenCard from 'src/components/loading/LoadingScreenCard';
import { CaijTableHeader } from 'src/components/table/CaijTableHeader';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import { 
  Copy as CopyIcon, 
  CheckCircle as ConfirmIcon, 
  XSquare as CancelIcon, 
  CheckSquare as CheckIcon
} from 'react-feather';
import LoadingButton from '@mui/lab/LoadingButton';
import CaijButtonCopy from 'src/components/buttons/CaijButtonCopy';

interface Params {
  author: string;
  title: string;
  checkoutDate?: Date; 
  checkinDate?: Date;
  dueDate?: Date;
  callNumber: string;
  allowedHistory?: boolean
};

interface SortFields {
  dueDate: string; 
  checkoutDate: string;
  callNumber: string;
  title: string;
  author: string;
}; 

const header = [
  { width: "10%", name: "Date d'emprunt"},
  { width: "10%", name: "Date de retour"},
  { width: "15%", name: "Cote"},
  { width: "50%", name: "Titre"},
  { width: "15%", name: "Auteur"},
];

const tabs = [
  { value: 'holds', label: 'Prêts en cours' },
  { value: 'history', label: 'Historique de prêts' },
];

const renderMessage = () => (
  <Card>
    <CardHeader title={<Typography variant="body2" sx={{color:"text.error"}}>Aucun prêt associé à ce compte</Typography>} />
  </Card>
);

const LibraryTableRow = ({author,title,checkoutDate,checkinDate,dueDate,callNumber,allowedHistory}:Params) => (
  <TableRow>
    <CaijTableCell>{formatDate(checkoutDate)}</CaijTableCell>
    <CaijTableCell>{formatDate(allowedHistory ? checkinDate : dueDate)}</CaijTableCell>
    <CaijTableCell>{callNumber}</CaijTableCell>
    <CaijTableCell>{title}</CaijTableCell>
    <CaijTableCell>{author}</CaijTableCell>
  </TableRow>
)

const Holds = (props:{charges: Charge[]}) => {
  const { charges } = props;
  const [value, setValue] = useState<string>('dueDate');
  const sortFields: SortFields = {
    dueDate: header[1].name,
    checkoutDate: header[0].name, 
    callNumber: header[2].name, 
    title: header[3].name, 
    author: header[4].name
  };

  const handleSort = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    if(sortFields[value] === header[0].name || sortFields[value] === header[1].name){
      charges.sort((a:Charge,b:Charge) => formatDate(a[value]) > formatDate(b[value]) ? -1 : 1);
    }else{
      charges.sort((a:Charge,b:Charge) => a[value].localeCompare(b[value]));
    }
    setValue(value);
  };

  if(!charges || (charges && charges.length === 0))
    return renderMessage()
  return (
    <>
      <Box p={2} minHeight={56} display='flex' alignItems='right' justifyContent='right'>
        <CaijInput
          label='Trier par'
          name='sort'
          onHandleChange={handleSort}
          select
          value={value}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
        >
          { Object.entries(sortFields).map(([key,value], index:number) => (
              <option key={index} value={key}>
                {value}
              </option>
            ))
          }
        </CaijInput>
      </Box>
      <Table>
        <CaijTableHeader data={header} />
        <TableBody>
          { charges.map((charge:Charge,index:number) => <LibraryTableRow
              key={index}
              author={charge.author}
              title={charge.title}
              checkoutDate={charge.checkoutDate}
              dueDate={charge.dueDate}
              callNumber={charge.callNumber}
            />
          )}
        </TableBody>
      </Table>
    </>
  )
};

const Histories = (props:{histories: ChargeHistory[]}) => {
  const {histories} = props;
  if(!histories || (histories && histories.length === 0))
    return renderMessage();
  return (
    <Table>
      <CaijTableHeader data={header} />
      <TableBody>
        {histories.map((history:ChargeHistory,index:number) => (
          <LibraryTableRow 
            key={index}
            author={history.author}
            title={history.title}
            checkoutDate={history.checkoutDate}
            checkinDate={history.checkinDate}
            callNumber={history.callNumber}
            allowedHistory
          />
        ))}
      </TableBody>
    </Table>
  )  
};

const PINDialog = (props: {id: number, setOpen: (value: boolean) => void}) => {
  const [pin, setPin] = useState<string | null>('');
  const [success, setSuccess] = useState<string | null>('');
  const [copySuccess, setCopySuccess] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { id, setOpen } = props;

  const copyToClipBoard = async (pin: string) => {
    if(pin){
      try {
        await navigator.clipboard.writeText(pin);
        setCopySuccess('Copié');
      } catch (err) {
        setCopySuccess('Copié avec sans succès');
      }
    }
  };

  return (
    <>
      <DialogTitle sx={dialogTitle}>RÉINITIALISER LE PIN SYMPHONY</DialogTitle>
        <DialogContent dividers sx={{...dialog, width:'auto',padding:'15px 15px 0 15px'}}>
          <Box mb={2}>
            { !success ? (
                <Typography variant='body2' sx={{color: 'text.secondary'}}>
                  Souhaitez-vous réinitialiser le Pin? <b>Cette action est irréversible.</b>
                </Typography>
              ) : (
                <Typography variant='body2' sx={{color: 'text.secondary'}}>
                  Le Pin a été réinitialisé par celui-ci. Veuillez le communiquer au client.
                </Typography>
              )
            }
          </Box>
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Box mr={success ? 2 : 0} flexGrow="1">
              <CaijInput 
                name='pin' 
                label="PIN"
                size='small' 
                placeholder='Inscrivez le nouveau PIN ou laissez vide pour générer'
                InputLabelProps={{shrink: true}}
                disabled={success !== ''}
                inputAttr={{maxLength: 20}}
                error={error}
                helperText={error ? 'Le Pin doit contenir au moins 6 caractères' : ' '}
                value={pin}
                sx={{width: 410}}
                onHandleChange={(e) => {
                  if(error){
                    setError(false);
                  }
                  setPin(e.target.value);
                }}
              />
            </Box>
            { success && (
                <Box mb={2}>
                  <CaijButtonCopy value={pin} />
                </Box>
              )
            }
          </Box>   
      </DialogContent>
      <DialogActions sx={dialog}>
        { !success ? (
            <>
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                variant="outlined"
                startIcon={<SvgIcon fontSize='small'><CheckIcon /></SvgIcon>}
                onClick={async () => {
                  if(pin && pin.length < 6){
                    setError(true);
                    return;
                  }
                  setLoading(true);
                  const model = new CustomerModel();
                  const response = await model.getResetPIN(id, pin);
                  if(!model.error){
                    setSuccess(response);
                    setPin(response);
                  }
                  setLoading(false);
                }}	
              >
                OK
              </LoadingButton>
              <Button 
                type='reset' 
                onClick={() => setOpen(false)} 
                color='secondary' 
                variant='outlined'
                startIcon={(
                  <SvgIcon fontSize="small">
                    <CancelIcon />
                  </SvgIcon>
                )}
              >
                Annuler
              </Button>
            </>
          ) : (
            <Button  
              variant="outlined"
              type="reset"
              onClick={() => setOpen(false)}
              startIcon={(
                <SvgIcon fontSize="small">
                  <ConfirmIcon />
                </SvgIcon>
            )}>OK</Button>
          )
        }
      </DialogActions>
    </>
  );
};

const CustomerLibrariesInfo: FC = () => {
  const navigate = useNavigate();
  const model = new CustomerModel();
  const access = new AccessModel(model.ResourceCode);
  const [currentTab, setCurrentTab] = useState<string>('holds');
  const [libraryTransactions, setLibraryTransactions] = useState<CustomerLibraryAccountInfoResource | undefined>(undefined);
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);

  const getTransactions = useCallback(async () : Promise<void> => {
    if (!id) navigate(model.Path.Home);
    const libraryTransactions = await model.getApiCustomerLibraryTransactionsById(id);
    if(!model.error){
      setLibraryTransactions(libraryTransactions);
    }
  },[id]);

  useEffect(() => {
    (async () => {
      await getTransactions();
    })();
  },[]);

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
  };

  const holdsRender = () => {
    switch (currentTab) {
      case 'holds':
        return <Holds charges={libraryTransactions?.charges?.sort((a:Charge,b:Charge) => formatDate(a.dueDate) > formatDate(b.dueDate) ? -1 : 1)} />
      case 'history':
        return <Histories histories={libraryTransactions?.history} />;
      default:
        return '';
    }
  };

  if(!libraryTransactions){
    return <LoadingScreenCard />
  }

  const {fees, history, charges} = libraryTransactions;

  return (
    <>
      <Grid spacing={3} container>
        { access.Gestion && (
            <Grid md={12} item>
              <Box display="flex" alignItems='right' justifyContent='right'>
                <CaijButton 
                  variant="outlined"
                  onHandleClick={() => setOpen(true)}
                >
                  Réinitialiser le PIN
                </CaijButton>
              </Box>
            </Grid>
          )
        }
        <Grid xs={4} item>
          <Card>
            <CardHeader title={labelConfig.fees} />
            <Box sx={info}>{ fees > 0 ? fees.toFixed(2) : fees }$</Box>
          </Card>
        </Grid>
        <Grid xs={4} item>
          <Card>
            <CardHeader title={labelConfig.holds} />
            <Box sx={info}>{charges?.length || 0}</Box>
          </Card>
        </Grid>
        <Grid xs={4} item>
          <Card>
            <CardHeader title={labelConfig.totalHolds} />
            <Box sx={info}>{history?.length || 0}</Box>
          </Card>
        </Grid>
        <Grid xs={12} item>
          <Card>
            <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='fullWidth' textColor='secondary'>
              {tabs.map(tab => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
            <Divider />
            <Box mt={3}>{holdsRender()}</Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={open} maxWidth="lg">
        <PINDialog id={+id} setOpen={setOpen}/>
      </Dialog>
    </>
  );
};

export default CustomerLibrariesInfo;
