import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import Page from 'src/components/Page';
import TranslationEditForm from './TranslationEditForm';
import { AccessModel, TranslationModel, root } from 'src/common';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const TranslationEditView: FC = () => {
  const model = TranslationModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    if(access.canAdd()){
      setLoading(true);
    }
  },[setLoading])

  if(!loading) return <LoadingScreen />;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(null)}
    >
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.BtnAddText} />
        <Box mt={3}>
          <TranslationEditForm model={model} />
        </Box>
      </Container>
    </Page>
  );
};

export default TranslationEditView;
