import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  LibraryTypeResource,
  Error,
  CreateResponse,
  LibraryTypeResourceForCreate,
  LibraryTypeResourceForEdit
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';
import { BtnOk } from 'src/components/dialog/CaijDialog';

type TypeResourceEdit = LibraryTypeResourceForCreate | LibraryTypeResourceForEdit;

export default class LibraryTypeModel extends AppModel 
{
  private static _instance: LibraryTypeModel;
  readonly NameFr = new FormModel('nameFr', 'Nom', 255);
  readonly NameEn = new FormModel('nameEn', 'Name', 255);
  
  constructor(){
    super('/library/type');
    this.initialize();
  }
  
  private initialize(){
    this._resourceCode = 'LIBRARY';
    this._headerTitle =  'Liste des types de bibliothèques';
    this._btnAddText = 'Ajouter un type de bibliothèque';
    this.Path.PathName = '/libraries/types';

    //Dialog
    this.Dialog.Header = 'Supprimer le type de la bibliothèque';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le type de la bibliothèque';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Types',
      href: this.Path.Home,
      visible: true
    }
  }

  static getInstance(): LibraryTypeModel {
    if (!LibraryTypeModel._instance) {
      LibraryTypeModel._instance = new LibraryTypeModel();
    }
    return LibraryTypeModel._instance;
  }

  static getInitialValues(values: LibraryTypeResource) : LibraryTypeResource {
    return {
      id: values.id,
      nameEn: values.nameEn || '',
      nameFr: values.nameFr || ''
    }
  }

  getHeadCells(){
    return [
      {
        id: 'nameFr', width: '47%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: 'nameEn', width: '47%', numeric: false, disablePadding: false, label: this.NameEn.Label
      },
      {
        id: '', width: '5%'
      }
    ];
  };

  async getLibraryTypes(): Promise<LibraryTypeResource[]> {
    let libraryTypes: LibraryTypeResource[];
    await axios.get<LibraryTypeResource[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraryTypes = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return libraryTypes;
  }

  async getLibraryTypeById(id: string): Promise<LibraryTypeResource> {
    let libraryType: LibraryTypeResource;
    await axios.get<LibraryTypeResource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraryType = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return libraryType;
  }

  async updateLibraryType(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<LibraryTypeResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Type de bibliothèque modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertLibraryType(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<LibraryTypeResourceForCreate, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Type de bibliothèque créé.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Type de bibliothèque supprimé.'
					});
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  async getRefreshLibraryType(id: string): Promise<LibraryTypeResource> {
    let newLibraryType : LibraryTypeResource;
    const libraryType = await this.getLibraryTypeById(id);
    if(!this.error){
      newLibraryType = libraryType;
    }
    return newLibraryType;
  }
}
