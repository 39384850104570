import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, SvgIcon } from '@mui/material';
import type { CustomerResource } from 'src/common/types';
import { CustomerModel, CustomerStatus, formatDate, formatDateTime, labelConfig } from 'src/common';
import Label from 'src/components/Label';
import { Aperture as StatusIcon } from 'react-feather';
import CustomerStatusDetail from 'src/components/customer/customer/Details/CustomerStatusDetail';
import CaijLabel from 'src/components/label/CaijLabel';

export interface AccountInfoProps {
  customer: CustomerResource;
}

const getLastLogin = (lastLogin: Date) => <CaijLabel label="Dernière authentication">{lastLogin ? formatDateTime(lastLogin) : 'Aucune'}</CaijLabel>
     
const getActivated = (activated: Date, lastLogin: Date ) => {
  let date: string = 'Aucune'; 
  if(activated){
    date = formatDate(activated);
  }else if(!activated && lastLogin){
    date = 'Donnée non disponible';
  }
  return <CaijLabel label="Date d'activation">{date}</CaijLabel>
}

const getEmailVerified = (emailVerified: Date) => <CaijLabel label="Date de vérification du courriel">{emailVerified ? formatDate(emailVerified) : 'Aucune'}</CaijLabel>
  
function render(customer: CustomerResource) : JSX.Element {
   const { status, lastLogin, activated, emailVerified, expiration, deactivated, deactivatedBy, passwordUpdateRequired } = customer;
   switch(status){
      case CustomerModel.getStatusByVal(CustomerStatus.Active):
        return (
          <>
            { getLastLogin(lastLogin) }
            { getActivated(activated, lastLogin) } 
            { getEmailVerified(emailVerified) }
            <CaijLabel label="Expiration du compte">{expiration ? formatDate(expiration) : 'Aucune'}</CaijLabel>
            <CaijLabel label="Mot de passe requis">
              <Label color={passwordUpdateRequired ? 'success' : 'error'}>{passwordUpdateRequired ? labelConfig.yes : labelConfig.no}</Label>
            </CaijLabel>
          </>
        )
      case CustomerModel.getStatusByVal(CustomerStatus.ActiveUponApproval):
        return (
          <>
            { getLastLogin(lastLogin) }
            { getActivated(activated, lastLogin) }
            { getEmailVerified(emailVerified) }
          </>
        )
      case CustomerModel.getStatusByVal(CustomerStatus.ActiveOnLogin):
        return (
          <>
            { getLastLogin(lastLogin) }
            { getActivated(activated, lastLogin) }
            { getEmailVerified(emailVerified)}
            <CaijLabel label="Expiration du compte">{expiration ? formatDate(expiration) : 'Aucune'}</CaijLabel>
          </>
        )
      case CustomerModel.getStatusByVal(CustomerStatus.Inactive) :
        return (
          <>
            <CaijLabel label="Date de désactivation">{deactivated ? formatDate(deactivated) : 'Aucune'}</CaijLabel>
            <CaijLabel label="Désactivé par">{deactivatedBy || 'Aucune'}</CaijLabel>
            { getEmailVerified(emailVerified) }
          </>
        )
      default: 
        return null; 
   } 
};

const AccountInfo: FC<AccountInfoProps> = ({ customer }) => (
  <Box display='flex'>
    <SvgIcon fontSize='small'>
      <StatusIcon />
    </SvgIcon>
    <Box display='flex' marginLeft='0.5em' flexDirection='column'>
      <Box display='flex' alignItems='center'>
        <Typography sx={{ fontWeight: 'bold' }}>Statut du compte</Typography>
      </Box>
      <CustomerStatusDetail customer={customer} />
      <CaijLabel label="Date de création">
        {customer.created ? formatDate(customer.created) : 'Aucune'}
      </CaijLabel>
      { render(customer) }
    </Box>
  </Box>
);

AccountInfo.propTypes = {
  customer: PropTypes.object.isRequired
};

export default AccountInfo;
