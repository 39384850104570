import { Box, CardContent, Container, Theme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';
import { FC, useCallback, useEffect, useReducer } from "react";
import Page from 'src/components/Page';
import { AccessModel, ReperageModel, _reperage, fetchReperage, loadingReperage, useNavigate, useParams } from "src/common";
import MyCard from "src/components/card/MyCard";
import ReperageSendForm from "./ReperageSendForm";
import LoadingScreen from "src/components/loading/LoadingScreen";
import GenericHeader from "src/components/header/GenericHeader";

const useStyles = makeStyles()((theme) => {
    return {
        root: {
            // backgroundColor: theme.palette.background.dark,
            minHeight: '100%',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        }
    }
});

const initialState = {
    reperage: {}
};

const ReperageSendView: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const model = ReperageModel.getInstance();
    const access = new AccessModel(model.ResourceCode);
    const { classes } = useStyles();
    const [state, dispatch] = useReducer(_reperage, initialState);

    const getReperageById = useCallback(async (): Promise<void> => {
        if (!id || isNaN(+id)) {
            navigate(model.Path.Home);
        } else if (access.canRead()) {
            dispatch(loadingReperage());
            model.PathName = location.pathname;
            const reperage = await model.getReperageById(id);
            if (!model.error) {
                dispatch(fetchReperage(reperage));
            }
        }
    }, [id, location]);

    useEffect(() => {
        (async () => {
            await getReperageById();
        })();
    }, [getReperageById]);


    const { reperage, isLoading } = state;

    if (!isLoading) return <LoadingScreen />

    return <Page className={classes.root} title={model.PageTitle}>
        <Container maxWidth={false}>
            <GenericHeader id={id} model={model} headerTitle={"Transmission de repérage au client"} />
            <Box mt={3}>
                <MyCard>
                    <CardContent>
                        <ReperageSendForm model={model} reperage={reperage} />
                    </CardContent>
                </MyCard>
            </Box>
        </Container>
    </Page>
}

export default ReperageSendView;