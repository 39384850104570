export const NotConnect = 'Something went wrong';
export const Success = 200;
export const Created = 201;
export const NoContent = 204;
export const BadRequest = 400;
export const Unauthorized = 401;
export const Forbidden = 403;
export const NotFound = 404;
export const Conflit = 409;
export const InternalServerError = 500;
export const ServiceUnavailable = 503;
export const UnExpectTokenJson = 0;
export const Other = 1;

