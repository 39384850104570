import React, { useRef, useState } from 'react';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import {
  Formik,
  Mode,
  CaijButtonReset,
  CaijButtonSubmit,
  btnSubmit,
  LibraryOpenHoursModel
} from 'src/common';
import type {
  LibraryResource,
  LibraryResourceForEdit,
  WorkingHour
} from 'src/common/types';
import Authorize from 'src/components/Authorize';
import LibraryOpenHoursForm from 'src/components/library/library/Forms/LibraryOpenHoursForm';
import LibraryModel from 'src/model/library/Library';
import printMessage from 'src/views/errors/MessageError';
import MyCard from 'src/components/card/MyCard';

interface OpenHoursFormProps {
  model: LibraryModel;
  library: LibraryResource;
  onHandleRefreshLibrary: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const OpenHoursForm: FC<OpenHoursFormProps> = ({
  model,
  library,
  onHandleRefreshLibrary,
  onSwitchMode
}) => {
  const libraryOpenHoursModel = LibraryOpenHoursModel.getInstance();
  const formRef = useRef(null);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedOpenHours, setSelectedOpenHours] = useState<WorkingHour[]>([]);

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        openHours: library.openHours || '',
        submit: null
      }}
      onSubmit={async () => {
        const databankIds = library.databanks
        const newImgUrlFr = library.imgUrlFr;
        const newImgUrlEn = library.imgUrlEn;
        const submitData = library as LibraryResourceForEdit;
        submitData.databanks = databankIds;
        submitData.imgUrlFr = newImgUrlFr;
        submitData.imgUrlEn = newImgUrlEn;
        const libraryId = submitData.id;
        if (submitData && libraryId) {
          const model = new LibraryModel();
          model.setSelectedOpenHours(selectedOpenHours);
          submitData.openHours = model.SelectedOpenHours;
          const response = await model.updateLibrary(submitData);
          if (!model.error) {
            printMessage({
              status: response.status,
              message: response.message
            });
            await onHandleRefreshLibrary();
          }
        }
      }}
    >
      {({
        handleSubmit,
        values,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              md={6}
              lg={6}
              xs={12}
            >
              <MyCard>
                <CardContent>
                  <LibraryOpenHoursForm
                    model={libraryOpenHoursModel}
                    openHours={values.openHours}
                    onHandleOpenHoursChange={(l) => setSelectedOpenHours(l)}
                  />
                  <Box mt={2}>
                    <Authorize
                      resourceCode={model.ResourceCode}
                      mode={Mode.edit}
                      onIsAuth={(l) => setIsAuth(l)}
                    >
                      <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                    </Authorize>
                    <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
                  </Box>
                </CardContent>
              </MyCard>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

OpenHoursForm.propTypes = {
  library: PropTypes.object.isRequired,
  onHandleRefreshLibrary: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired
};

export default OpenHoursForm;
