import type { DatabankResource, SubscriptionResource, LibraryResource } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchDatabanksAction = {
    type: 'FETCH_DATABANKS';
    payload: {
      databanks: DatabankResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchDatabankAction = {
    type: 'FETCH_DATABANK';
    payload: {
      databank: DatabankResource;
      isLoading?: boolean;
    }
};

type FetchDetailsDatabankAction = {
  type: 'FETCH_DETAILS_DATABANK';
  payload: {
    databank: DatabankResource;
    subscriptions?: SubscriptionResource[];
    libraries?: LibraryResource[];
  };
};

type DeleteDatabankAction = {
  type: 'DELETE_DATABANK';
  payload: {
    id: number;
  };
};

type Action = FetchDatabanksAction |
              FetchDatabankAction |
              FetchDetailsDatabankAction |
              DeleteDatabankAction;

export interface DatabankReturnState {
  databanks?: DatabankResource[];
  databank?: DatabankResource;
  subscriptions?: SubscriptionResource[];
  libraries?: LibraryResource[];
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface DatabankState {
  databanks: DatabankResource[];
  databank: DatabankResource;
  subscriptions: SubscriptionResource[];
  libraries: LibraryResource[];
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: DatabankState, action: Action): DatabankReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_DATABANKS: {
      const { databanks, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        databanks,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_DATABANK: {
      const { databank, isLoading } = action.payload;
      return {
        ...state,
        databank,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_DATABANK: {
      const { databank, subscriptions, libraries } = action.payload;
      return {
        ...state,
        databank,
        subscriptions,
        libraries
      };
    }
    case actionTypes.DELETE_DATABANK: {
      return {
        ...state,
        databanks: [...state.databanks].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer

