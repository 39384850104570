import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Results from './Results';
import { EditAccessGroup } from 'src/types/accessGroup';

export interface AccessGroupListViewProps {
  subscriptionId: number;
  onEditAccessGroup: (value: EditAccessGroup) => void;
  onEditSubscription: () => void;
}

const AccessGroupListView: FC<AccessGroupListViewProps> = ({
  subscriptionId,
  onEditAccessGroup,
  onEditSubscription
}) => (
  <Box mt={3}>
    <Results
      subscriptionId={subscriptionId}
      onEditAccessGroup={onEditAccessGroup}
      onEditSubscription={onEditSubscription}
    />
  </Box>
);

AccessGroupListView.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  onEditAccessGroup: PropTypes.func.isRequired,
  onEditSubscription: PropTypes.func.isRequired
}

export default AccessGroupListView;
