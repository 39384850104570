import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  SvgIcon,
  IconButton,
  Dialog,
  Tooltip
} from '@mui/material';
import { CustomerNoteDto, ICustomer } from 'src/common/types';
import { 
  AccessModel,
  CaijButton,
  CaijTooltip,
  CustomerModel,
  EmptyList,
  formatDateTime
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import MyCard from 'src/components/card/MyCard';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import CaijDialogs from 'src/components/dialog';
import CaijTable from 'src/components/table/CaijTable';
import { greyed } from 'src/styles/customer';
import NoteEditForm from './NoteEditForm';
import { 
  PushPinSharp as PinIcon, 
  PinDropSharp as UnPinIcon,
  Edit as EditIcon
} from '@mui/icons-material';
import CaijTypography from 'src/components/typography';

export interface NotesInfoProps {
  model: CustomerModel;
  custId: number;
  access: AccessModel;
  customerNotes:  CustomerNoteDto[];
  setCust: Dispatch<SetStateAction<ICustomer>>;
}

export function getCustomerNoteDate(date: string) : string {
  if(date){
   const [myDate, myHour] = date.split(' ');
   const newDate = myDate.split('-');
   if(newDate.length >= 3){
    return `${newDate[2]}/${newDate[1]}/${newDate[0]} ${myHour}`;
   }
  }
}

const pin = {
  marginRight: '15px'
}

const NotesInfo: FC<NotesInfoProps> = ({ 
  model,
  custId,
  customerNotes,
  setCust,
  access
}) => {
  const [open, setOpen] = useState(false);
  const [selDeleteRow, setSelDeleteRow] = useState([]);
  const [selEditRow, setSelEditRow] = useState([]);
  model.Dialog.Header = 'Supprimer la note';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'la note';
  const disabled = access && !access.Consultation;

  const getNotes = useCallback(async () => {
    const customerNotes = await model.getNotes(custId);
      const { error } = model;
      if (error) {
        printMessage({ status: error.status, message: error.message });
      } else {
        setCust((prevState: ICustomer) => {
          return {
            ...prevState,
            customerNotes
          }
        });
      }
  },[]);

  const handleSubmit = async (customerNote: CustomerNoteDto, pin: boolean) => {
    customerNote.pin = pin;
    const response = await model.updateCustomerNote(custId, customerNote);
    const { status, message } = response;
    if(!model.error){
      printMessage({status, message});
      await getNotes();
    }
  }

  const deleteNote = async (noteId: number): Promise<void> => {
    const response = await model.deleteCustomerNote(custId, noteId);
    const { status, message } = response;
    if (!model.error) {
      printMessage({ status, message });
      await getNotes();
    }
  }

  const renderNotes = (notes: CustomerNoteDto[]) : JSX.Element[] => {
    return notes.map((row:CustomerNoteDto) => (
      <TableRow key={row.id}>
        <TableCell sx={{borderBottom: 0}}>
          <MyCard>
            <Box display="flex" flexDirection="column" p={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" sx={{marginBottom: '0.3em', whiteSpace: 'pre-wrap'}}>{row.note}</Typography>
                <Box width="50" display="flex" alignItems="center">
                    { row.pin ? (
                        <CaijTooltip title="Désépingler" disabled={disabled}>
                          <IconButton disabled={disabled} onClick={() => handleSubmit(row, false)} sx={pin} >
                              <SvgIcon fontSize='small'>
                                <UnPinIcon />
                              </SvgIcon>
                          </IconButton>
                        </CaijTooltip>
                      ) : (
                        <CaijTooltip title="Épingler" disabled={disabled}>
                          <IconButton disabled={disabled} onClick={() => handleSubmit(row, true)} sx={pin} >
                            <SvgIcon fontSize='small'>
                              <PinIcon />
                            </SvgIcon>
                          </IconButton>
                        </CaijTooltip>
                      ) 
                    }
                    <CaijTooltip title="Éditer" disabled={disabled}>
                      <IconButton
                        sx={pin}
                        disabled={disabled}
                        onClick={() => CustomerModel.handleToggle(row.id, selEditRow, setSelEditRow)}
                      >
                        <SvgIcon fontSize='small'>
                          <EditIcon />
                        </SvgIcon>
                      </IconButton>
                    </CaijTooltip>
                    <CaijTooltip title="Supprimer" disabled={disabled}>
                      <IconButton disabled={disabled} onClick={() => CustomerModel.handleToggle(row.id, selDeleteRow, setSelDeleteRow)}>
                        <SvgIcon fontSize='small'>
                          <DeleteIcon />
                        </SvgIcon>
                      </IconButton>
                    </CaijTooltip>
                    <Dialog maxWidth='md' fullWidth open={selEditRow.indexOf(row.id) !== -1}>
                      <NoteEditForm
                        access={access}
                        model={model} 
                        custId={custId}
                        note={row} 
                        setOpen={(l) => {
                          setOpen(l)
                          setSelEditRow([]);
                        }} 
                        reloadNotes={getNotes} 
                      />
                    </Dialog>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <CaijTypography sx={{...greyed, mr:'2px'}}>
                  Créée par <span style={{fontStyle: 'italic'}}>{row.employeeName}</span> - {getCustomerNoteDate(formatDateTime(row.created))}
                </CaijTypography>
                <CaijTypography sx={greyed}>
                { row.updated && `| Modifié le - ${getCustomerNoteDate(formatDateTime(row.updated))}`}
                </CaijTypography>
              </Box>
            </Box>
          </MyCard>
          <Box>
            <CaijDialogs
              allowedReadAccessForDeletion
              dialog={model.Dialog}
              isOpen={selDeleteRow.indexOf(row.id) !== -1}
              onSubmit={async () : Promise<void> => {
                await deleteNote(row.id);
                setSelDeleteRow([]);
              }}
              setSelectedRow={setSelDeleteRow}
            />
          </Box>
        </TableCell>
      </TableRow>
    ))
  }

  return (
    <Grid spacing={3} container>
      <Grid md={12} xs={12} sm={12} lg={12} item>
        <Box display="flex" flexDirection="column">
          <Box display='flex' justifyContent='end' >
            <CaijButton
              type='add'
              color='secondary'
              variant='contained'
              disabled={disabled}
              onHandleClick={() => setOpen(true)}
            >
              Créer une note
            </CaijButton>
          </Box>
          { !customerNotes || customerNotes.length === 0 ? (
              <EmptyList />
            ) : (
              <CaijTable>
                <TableBody>
                  {renderNotes(customerNotes)}
                </TableBody>
              </CaijTable>
            )
          }
          <Dialog maxWidth='md' fullWidth open={open}>
            <NoteEditForm
              note={{pin: false, note: ''}}
              access={access}
              model={model} 
              custId={custId} 
              setOpen={setOpen} 
              reloadNotes={async () => await getNotes()} 
            />
          </Dialog>
        </Box>
      </Grid>
    </Grid>
  );
};

NotesInfo.propTypes = {
  custId: PropTypes.number.isRequired
};

export default NotesInfo;

