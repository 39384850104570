import { FC, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import Page from 'src/components/Page';
import Header from './Header';
import Version from 'src/components/Version';
import { useStats } from 'src/functions/hooks/stats';
import Results from './Results';
import { root, useLocation } from 'src/common';
import AppModel from 'src/model/App';
import LoadingScreen from 'src/components/loading/LoadingScreen';

const AccueilView: FC = () => {
  const home = new AppModel();
  const location = useLocation();
  const { 
    sActiveCustomer,
    sCardRequestCustomer,
    sDelayAccountCustomer,
    sActiveCustomersBySubscription,
    sActiveCustomersByGroup
  } = useStats();

  useEffect(() => {
    home.PathName = location.pathname;
  },[]);

  if(!sActiveCustomersBySubscription.isLoading){
    return <LoadingScreen />
  }
  
  return (
    <Page sx={root} title={home.PageTitle}>
      <Container maxWidth={false}>
        <Header />
        <Box mt={3}>
          <Results
            activeCustomer={sActiveCustomer}
            cardRequestCustomer={sCardRequestCustomer}
            delayAccountCustomer={sDelayAccountCustomer}
            activeCustomersBySubscription={sActiveCustomersBySubscription}
            activeCustomersByGroup={sActiveCustomersByGroup}
          />
        </Box> 
      </Container>
      <Box mt={1}>
        <Container>
          <Version />
        </Container>
      </Box>
    </Page>
  );
}

export default AccueilView;
