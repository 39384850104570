
import { FC } from "react";
import { Params, useMatches } from "react-router-dom";
import { link, LoaderRoute, Link as RouterLink } from 'src/common';
import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNextOutlined';
import { IMessage } from "../header/GenericHeader";

interface IMatches {
    id: string;
    pathname: string;
    params: Params<string>;
    data: unknown;
    handle?: {
        crumb?: (param?: string) => React.ReactNode;
    };
}

interface Breadcrumbprops {
    title?: string;
    message?: IMessage
}

const getMatches = (matches: IMatches, message: IMessage) => {
    const data = matches.data; 
    switch(data){
        case LoaderRoute.wc:
            const path = matches.pathname;
            const idx = path.lastIndexOf('/');
            matches.pathname = `${path.slice(0,idx)}/${data}${path.slice(idx)}`;
            break;
        case LoaderRoute.hidden:
            matches.pathname = '';
            break;
        case LoaderRoute.placeholder:
            message ? matches.pathname = `${LoaderRoute.placeholder}${message.id}` : '';
            break;
    }
    return matches; 
}

const Breadcrumb: FC<Breadcrumbprops> = ({ title, message }) => {
    const matches: IMatches[] = useMatches();
    let crumbs = matches.map((match) => getMatches(match, message))
                        .filter((match) => Boolean(match.handle));
    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {crumbs.map((crumb, index) => {
                if (index == crumbs.length -1)
                    return <Typography key={index} variant="body1" sx={{ color: "text.primary" }}>
                        {crumb.handle.crumb(title)}
                    </Typography>
                return crumb.pathname && <Link sx={link} variant="body1" underline="none" color="inherit" key={index} to={crumb.pathname} component={RouterLink}>
                    {crumb.handle.crumb((message && crumb.data === LoaderRoute.placeholder) ? message.label : title)}
                </Link>
            })}
        </Breadcrumbs>
    );
};

export default Breadcrumb;
