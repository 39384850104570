import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type {  
  PartnerResource,  
  DocumentResource, 
  TribunalResource
} from 'src/common/types';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { 
  labelConfig, 
  CaijInput, 
  FormikErrors, 
  FormikTouched,
  CaijSwitch, 
  handleChangeSwitch, 
  jurisdictionConfig, 
  CaijDatePicker, 
  DocLanguage, 
  CaijTextarea,
  DocumentModel
} from 'src/common';
import { useDocument } from 'src/functions/hooks/document';
import {$enum} from "ts-enum-util";

interface DocumentFormProps {
  model: DocumentModel;
  document: DocumentResource;
  errors: FormikErrors<DocumentResource>;
  touched: FormikTouched<DocumentResource>;
  onHandleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const DocumentForm: FC<DocumentFormProps> = ({
  model,
  document,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const [selTribunal, setSelTribunal] = useState<TribunalResource | null>(null);
  const [selPartner, setSelPartner] = useState<PartnerResource | null>(null);
  const { sCollection, sDocumentType, sLaw, sTribunal, sPartners} = useDocument();
  const { title, collection, visible, identifier, juridiction, searchable, filepath, documentType, published, rssDate, otherLangDocId, lawId, lang, metadatas } = document;
  const { Titre, Collection, Identifier, Juridiction, Filepath, OtherLangDocId, Tribunal, DocumentType, Visible, Searchable, Lang, LawId, Partner, Published, RssDate, Metadatas } = model;    

  useEffect(() => {
    if(document.tribunal)
      setSelTribunal(sTribunal.tribunaux.find(({codeFr}) => codeFr.toLowerCase() == document.tribunal.toLowerCase()));
    if(document.publisher)
      setSelPartner(sPartners.partners.find(({nameFr}) => nameFr == document.publisher));
  },[document,sTribunal,sPartners]);
        
  const handleChangeTribunal = (event:ChangeEvent<{}>, tribunal: TribunalResource) => {
    if(tribunal){
      setSelTribunal(tribunal);
      setFieldValue(Tribunal.Name, tribunal.codeFr.toLocaleLowerCase());
    }else{
      setSelTribunal(null);
      setFieldValue(Tribunal.Name,'');
    }
  }

  const handleChangePartner = (event:ChangeEvent<{}>, partner: PartnerResource) => {
    if(partner){
      setSelPartner(partner);
      setFieldValue(Partner.Name, partner.nameFr);
    }else{
      setSelPartner(null);
      setFieldValue(Partner.Name,'');
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <CaijSwitch
        name={Visible.Name}
        label={Visible.Label}
        checked={visible}
        value={visible}
        sx={{mb:2}}
        inputProps={{'data-testid': Visible.Name }}
        onHandleChangeSwitch={handleChangeSwitch}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        required
        name={Titre.Name}
        id={Titre.Name}
        value={title}
        helperText={touched.title && errors.title}
        error={Boolean(touched.title && errors.title)}
        label={Titre.Label}
        InputLabelProps={{ shrink: true, required: true, }}
        inputAttr={{maxLength: Titre.MaxLength, 'data-testid': Titre.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        required
        name={Identifier.Name}
        id={Identifier.Name}
        value={identifier}
        helperText={touched.identifier && errors.identifier}
        error={Boolean(touched.identifier && errors.identifier)}
        label={Identifier.Label}
        InputLabelProps={{ shrink: true, required: true }}
        disabled
        inputAttr={{maxLength: Identifier.MaxLength, 'data-testid': Identifier.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        required
        label={DocumentType.Label}
        id={DocumentType.Name}
        name={DocumentType.Name}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setFieldValue(DocumentType.Name,e.target.value)}
        select
        value={documentType || ''}
        variant='outlined'
        helperText={touched.documentType && errors.documentType}
        error={Boolean(touched.documentType && errors.documentType)}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{'data-testid': DocumentType.Name }}
        sx={{ marginTop: 2 }}
      >
        <option value=''>Sélectionner un type de document</option>
        {  sDocumentType.allChecked && sDocumentType.documentTypes.map(({code, nameFr}) => <option value={code} key={code}>{nameFr}</option>) }
      </CaijInput>
      <CaijSwitch
        name={Searchable.Name}
        label={Searchable.Label}
        checked={searchable}
        value={searchable}
        sx={{mb:2}}
        inputProps={{ 'data-testid': Searchable.Name }}
        onHandleChangeSwitch={handleChangeSwitch}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        name={Filepath.Name}
        id={Filepath.Name}
        value={filepath}
        label={Filepath.Label}
        helperText={touched.filepath && errors.filepath}
        error={Boolean(touched.filepath && errors.filepath)}
        InputLabelProps={{ shrink: true }}
        disabled
        inputAttr={{maxLength: Filepath.MaxLength, 'data-testid': Filepath.Name}}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        required
        label={Lang.Label}
        id={Lang.Name}
        name={Lang.Name}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setFieldValue(Lang.Name,e.target.value)}
        select
        value={lang || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{ 'data-testid': Lang.Name }}
        helperText={touched.lang && errors.lang}
        error={Boolean(touched.lang && errors.lang)}
      >
        { $enum(DocLanguage).map((value, key) => <option value={key} key={key}>{value}</option>) }
      </CaijInput>
      <CaijInput
        name={OtherLangDocId.Name}
        id={OtherLangDocId.Name}
        disabled
        value={otherLangDocId}
        helperText={touched.otherLangDocId && errors.otherLangDocId}
        error={Boolean(touched.otherLangDocId && errors.otherLangDocId)}
        label={OtherLangDocId.Label}
        InputLabelProps={{ shrink: true }}
        inputAttr={{ maxLength: OtherLangDocId.MaxLength, 'data-testid': OtherLangDocId.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        required
        label={Collection.Label}
        id={Collection.Name}
        name={Collection.Name}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setFieldValue(Collection.Name,e.target.value)}
        select
        value={collection || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{ 'data-testid': Collection.Name }}
        helperText={touched.collection && errors.collection}
        error={Boolean(touched.collection && errors.collection)}
      >
        <option value='' disabled>Sélectionner une {Collection.Label.toLowerCase()}</option>
        {
          (sCollection.isLoading && sCollection.isAuthorize && sCollection.docCollections) &&
          sCollection.docCollections.map(({code, nameFr}) => <option value={code} key={code}>{nameFr}</option>)
        }
      </CaijInput>
      <CaijInput
        label={LawId.Label}
        name={LawId.Name}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setFieldValue(LawId.Name,e.target.value)}
        select
        value={lawId || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true }}
      >
          <option value="">Sélectionner une {LawId.Label.toLowerCase()}</option>
          {
            (sLaw.isLoading && sLaw.isAuthorize && sLaw.laws) &&
            sLaw.laws.map(({id, titleFr}) => <option value={id} key={id}>{titleFr}</option>)
          }
      </CaijInput>
      <Autocomplete
        fullWidth
        value={selPartner || null}
        options={sPartners.partners.filter(({nameFr}) => nameFr !== document.publisher)}
        getOptionLabel={({nameFr}) => nameFr}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            variant="outlined"
            label={Partner.Label}
            placeholder="Rechercher dans la liste des partenaires"
            InputLabelProps={{shrink: true}}
            helperText={touched.publisher && errors.publisher}
            error={Boolean(touched.publisher && errors.publisher)}
        />
        )}
        onChange={(event, value, reason) => handleChangePartner(event,value)}
        renderTags={(tagValue, getTagProps) => 
            tagValue.map(({code, nameFr}, index) => {
              return <Chip key={code} label={nameFr} color="default" />
            })
        }
        sx={{mt:2, mb:5}}
      />
      <Autocomplete
        value={selTribunal || null}
        options={sTribunal.tribunaux.filter(({codeFr}) => codeFr.toLowerCase() !== selTribunal?.codeFr?.toLowerCase())}
        getOptionLabel={({labelFr}) => labelFr}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={Tribunal.Label}
            placeholder="Rechercher dans la liste des tribunaux"
            InputLabelProps={{shrink: true}}
            helperText={touched.tribunal && errors.tribunal}
            error={Boolean(touched.tribunal && errors.tribunal)}
        />
        )}
        onChange={(event, value, reason) => handleChangeTribunal(event,value)}
        renderTags={(tagValue, getTagProps) => 
            tagValue.map(({codeFr, labelFr}, index) => {
              return <Chip key={codeFr} label={labelFr} color="default" />
            })
        }
        sx={{mb: 3}}
      />
      <CaijInput
        label={Juridiction.Label}
        name={Juridiction.Name}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setFieldValue(Juridiction.Name,e.target.value)}
        select
        value={juridiction || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        helperText={touched.juridiction && errors.juridiction}
        error={Boolean(touched.juridiction && errors.juridiction)}
        sx={{mb: 2}}
      >
          <option value="">Sélectionner une {Juridiction.Label.toLowerCase()}</option>
          {
            jurisdictionConfig && Object.keys(jurisdictionConfig).map((key) => (
              <option value={key} key={key}>
                {jurisdictionConfig[key]}
              </option>
            ))
          }
      </CaijInput>
      <CaijDatePicker 
        name={Published.Name}
        required
        value={String(published)}
        label={Published.Label}
        inputProps={{"data-testid": Published.Name}}
        helperText={touched.published && errors.published}
        onHandleChange={(l) => setFieldValue(Published.Name, l)}
        sx={{mb: 2, width: '320px'}}
      />
      <CaijDatePicker 
        name={RssDate.Name}
        value={String(rssDate)}
        label={RssDate.Label}
        inputProps={{"data-testid": RssDate.Name}}
        onHandleChange={(l) => setFieldValue(RssDate.Name, l)}
        sx={{width: '320px'}}
      />
      <CaijTextarea
        name={Metadatas.Name}
        rows="6"
        value={metadatas}
        label={labelConfig.metadatas}
        InputLabelProps={{ shrink: true }}
        inputProps={{ 'data-testid': Metadatas.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

DocumentForm.propTypes = {
  document: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default DocumentForm;
