import { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { 
    CaijInputAddress, 
    FormikErrors, 
    FormikTouched, 
    CaijInput, 
    CaijCard, 
    CaijCheckbox,
    CustomerModel,
    Label,
} from 'src/common';
import type {
    CustomerAddressDto,
    CustomerResource,  
} from 'src/common/types';
import { 
    Box, 
    SvgIcon,
    Button
} from '@mui/material';
import { Trash as DeleteIcon } from 'react-feather';

interface CustomerAddressFormProps {
  model: CustomerModel;
  count: number;
  customer: CustomerResource;
  errors: FormikErrors<CustomerResource>;
  touched: FormikTouched<CustomerResource>;
  onHandleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CustomerAddressForm: FC<CustomerAddressFormProps> = ({ 
  model,
  count,
  customer,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  setFieldValue
}) => {
  const { addresses } = customer;
  const addresseCount = count;
  const err = (errors.addresses as CustomerAddressDto[]);
  const touch = (touched.addresses as FormikTouched<CustomerAddressDto>[]);
  const { AddressName, AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry } = model;
  const deleteAddress = (count: number) => {
    const newAddresses = [...customer.addresses];
    newAddresses.splice(count,1);
    setFieldValue('addresses', newAddresses);
  }

  return (
    <Box mb={2}>
      <CaijCard title={`Adresse ${addresseCount + 1}`}>
        <Box>
          {addresses[count].imported && <Label color="warning5" >Importée</Label>}
        </Box>
        <CaijCheckbox
          label="Principale"
          name={`addresses.${count}.preferred`}
          checked={addresses[count].preferred}
          value={addresses[count].preferred}
          disableRipple
          inputProps={{ "aria-labelledby": `preferred-${count}`}}
          onHandleChangeCheckBox={(e:ChangeEvent<HTMLInputElement>) => {
            let newAddress = [...customer.addresses];
            if(e.target.value === 'false'){
              setFieldValue('addresses',newAddress.map((addresse: CustomerAddressDto, index: number) => {
                addresse.preferred = (index === count) ? true : false;
                return addresse;
              }));
            }else{
              setFieldValue('addresses',newAddress.map((addresse: CustomerAddressDto, index: number) => {
                addresse.preferred = false;
                return addresse;
              }));
            }
          }}
          sx={{mb: 2}}
          />
        <CaijInput
          name={`addresses.${count}.name`}
          value={addresses[count]?.name || ''}
          label={AddressName.Label}
          error={Boolean((touch && (touch[count]?.name || false)) && (err && (err[count]?.name || false)))}
          helperText={((touch && (touch[count]?.name || false)) && (err && (err[count]?.name || false))) || ' '}
          inputAttr={{ maxLength: AddressName.MaxLength, 'data-testid': AddressName.Name, autoComplete: 'none'  }}
          InputLabelProps={{ shrink: true }}
          onHandleChange={onHandleChange}
          onHandleBlur={onHandleBlur}
          setFieldValue={setFieldValue}
        />
       <CaijInputAddress
          name={`addresses.${count}.line1`}
          required
          label={AddressLine1.Label}
          value={addresses[count]?.line1 || ''}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: AddressLine1.MaxLength, 'data-testid': AddressLine1.Name}}
          error={Boolean((touch && (touch[count]?.line1 || false)) && (err && (err[count]?.line1 || false)))}
          helperText={((touch && (touch[count]?.line1 || false)) && (err && (err[count]?.line1 || false))) || ' '}
          onHandleChange={onHandleChange}
          onHandleBlur={onHandleBlur}
          setFieldValue={setFieldValue}
        />
        <CaijInputAddress
          name={`addresses.${count}.line2`}
          label={AddressLine2.Label}
          value={addresses[count]?.line2 || ''}
          InputLabelProps={{ shrink: true }}
          inputProps={{maxLength: AddressLine2.MaxLength, 'data-testid': AddressLine2.Name}}
          error={Boolean((touch && (touch[count]?.line2 || false)) && (err && (err[count]?.line2 || false)))}
          helperText={((touch && (touch[count]?.line2 || false)) && (err && (err[count]?.line2 || false))) || ' '}
          onHandleChange={onHandleChange}
          onHandleBlur={onHandleBlur}
          setFieldValue={setFieldValue}
        />
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box mr={1}>
            <CaijInput
              name={`addresses.${count}.city`}
              required
              value={addresses[count]?.city || ''}
              label={AddressCity.Label}
              error={Boolean((touch && (touch[count]?.city || false)) && (err && (err[count]?.city || false)))}
              helperText={((touch && (touch[count]?.city || false)) && (err && (err[count]?.city || false))) || ' '}
              InputLabelProps={{ shrink: true }}
              inputAttr={{maxLength: AddressCity.MaxLength, 'data-testid': AddressCity.Name, autoComplete: 'none' }}
              onHandleChange={onHandleChange}
              onHandleBlur={onHandleBlur}
              setFieldValue={setFieldValue}
            />
          </Box>
          <Box mr={1}>
            <CaijInput
              name={`addresses.${count}.state`}
              required
              label={AddressState.Label}
              value={addresses[count]?.state || ''}
              error={Boolean((touch && (touch[count]?.state || false)) && (err && (err[count]?.state || false)))}
              helperText={((touch && (touch[count]?.state || false)) && (err && (err[count]?.state || false))) || ' '}
              InputLabelProps={{ shrink: true }}
              inputAttr={{maxLength: AddressState.MaxLength, 'data-testid': AddressState.Name, autoComplete: 'none' }}
              onHandleChange={onHandleChange}
              onHandleBlur={onHandleBlur}
              setFieldValue={setFieldValue}
            />
          </Box>
          <Box>
            <CaijInput
              name={`addresses.${count}.zip`}
              required
              label={AddressZip.Label}
              value={addresses[count]?.zip || ''}
              error={Boolean((touch && (touch[count]?.zip || false)) && (err && (err[count]?.zip || false)))}
              helperText={((touch && (touch[count]?.zip || false)) && (err && (err[count]?.zip || false))) || ' '}
              InputLabelProps={{ shrink: true }}
              inputAttr={{ maxLength: AddressZip.MaxLength, 'data-testid': AddressZip.Name, autoComplete: 'none' }}
              onHandleChange={onHandleChange}
              onHandleBlur={onHandleBlur}
              setFieldValue={setFieldValue}
            />
          </Box>
        </Box>
        <CaijInput
          name={`addresses.${count}.country`}
          label={AddressCountry.Label}
          value={addresses[count]?.country || ''}
          error={Boolean((touch && (touch[count]?.country || false)) && (err && (err[count]?.country || false)))}
          helperText={((touch && (touch[count]?.country || false)) && (err && (err[count]?.country || false))) || ' '}
          InputLabelProps={{ shrink: true }}
          inputAttr={{maxLength: AddressCountry.MaxLength, 'data-testid': AddressCountry.Name, autoComplete: 'none' }}
          onHandleChange={onHandleChange}
          onHandleBlur={onHandleBlur}
          setFieldValue={setFieldValue}
        />
        <Button 
          startIcon={
            <SvgIcon fontSize='small'>
              <DeleteIcon />
            </SvgIcon>
          }
          onClick={() => deleteAddress(count)}
        >
          Supprimer l'adresse
        </Button>
      </CaijCard>
    </Box>
  );
};

CustomerAddressForm.propTypes = {
  count: PropTypes.number.isRequired,
  customer: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default CustomerAddressForm;
