import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type {
  TabsEmailTemplate,
  EmailTemplateDto
} from 'src/common/types';
import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import {
  useParams,
  useLocation,
  useNavigate,
  setHash,
  setEditHash,
  EmailTemplateModel,
  _emailTemplate,
  fetchEmailTemplate,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Details from './Details';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import DetailsEditForm from './Forms/DetailsEditForm';
import GenericHeader from 'src/components/header/GenericHeader';

const initialTabs: TabsEmailTemplate[] = [
  { value: 'details', label: 'détails' }
];

const EmailTemplateDetailsView: FC = () => {
  const model = EmailTemplateModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [tabs, setTabs] = useState<TabsEmailTemplate[]>(initialTabs);
  const [editMode, setEditMode] = useState({
    details: false
  });
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const { id } = useParams();
  const [state, dispatch] = useReducer(_emailTemplate,{ emailTemplate: null });
  const navigate = useNavigate();

  const getEmailTemplateById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const model = new EmailTemplateModel();
      const emailTemplate = await model.getEmailTemplateById(id);
      if (!model.error) {
        dispatch(fetchEmailTemplate(emailTemplate));
      }
    }
  },
  [id]);
 
  const setEditModeEmailTemplate = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit,
    });
    setIsEditing(isEdit);
  };

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsEmailTemplate) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }

  useEffect(() => {
    if(access.canRead()){
      const { hash, pathname } = location;
      model.PathName = pathname + hash;
    }
  },[location]);

  useEffect(() => {
    (async () => {
      handleBrowserRefresh();
      await getEmailTemplateById();
    })();
  }, []);
  
  const { emailTemplate } = state;

  if (!emailTemplate) {
    return <LoadingScreen />;
  }

  model.Title = `${emailTemplate.name} - ${emailTemplate.code}`;

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeEmailTemplate(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    event.persist();
    setHash(value, navigate);
    setCurrentTab(value);
    setEditModeEmailTemplate(false);
  };

  const handleRefreshEmailTemplate = async (emailTemplate: EmailTemplateDto, value: string): Promise<void> => {
    setHash(value,navigate);
    dispatch(fetchEmailTemplate(emailTemplate));
    setEditModeEmailTemplate(false);
  };

  const editEmailTemplate = () => {
    setEditHash('edit', navigate);
    setEditModeEmailTemplate(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case tabs[0].value:
        if (editMode.details) {
          return (
            <DetailsEditForm
              model={model}
              emailTemplate={emailTemplate}
              onHandleRefreshEmailTemplate={(l) => handleRefreshEmailTemplate(l, tabs[0].value)}
              onSwitchMode={(l) => switchMode(l, tabs[0].value)}
            />
          );
        }
        return (
          <Details model={model} emailTemplate={emailTemplate} />
        );
      default:
        return '';
    }
  };

  return (
    <Page sx={root} title={model.DetailPageTitle}>
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={emailTemplate.name} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editEmailTemplate()}
          titleBadge={{label: emailTemplate.code}}
        />
        <Box mt={2}>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='standard' textColor='secondary'>
            {tabs.map(tab =>  (
                <Tab key={tab.value} 
                    label={tab.label} 
                    value={tab.value}
                    onChange={() => {}}
                />
              )
            )}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{renderContent()}</Box>
      </Container>
    </Page>
  );
};

export default EmailTemplateDetailsView;
