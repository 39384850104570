import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR
} from 'src/common';
import type {
  ContentMostViewedResource,
  Error
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

export default class ContentMostViewedModel extends AppModel
{
  private static _instance: ContentMostViewedModel;
  readonly Titre = new FormModel('title','Titre',500);
  readonly PublicationCode = new FormModel('publicationCode','Code',2000);
  readonly Secured = new FormModel('secured', 'Sécurisé');
  readonly Rank = new FormModel('rank','Rang',11);

  constructor(){
    super('/content/mostviewed');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'CONTENT_MOST_VIEWED';
    this._headerTitle = 'Liste des références pratiques - Doctrine';
    this.Path.PathName = '/contents/most-viewed';

    //Dialog
    this.Dialog.Header = 'Supprimer la référence pratiques - Doctrine';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'la références pratiques - Doctrine';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Références pratiques - Doctrine',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: this.Titre.Name, width: '35%', numeric: false, disablePadding: false, label: this.Titre.Label
      },
      {
        id: this.PublicationCode.Name, width: '50%', numeric: false, disablePadding: false, label: this.PublicationCode.Label
      },
      {
        id: '', width: '5%', numeric: false, disablePadding: false, label: this.Secured.Label
      },
      {
        id: this.Rank.Name, width: '5%', numeric: false, disablePadding: false, label: this.Rank.Label
      },
      {
        id: '', width: '5%'
      }
    ];
  }

  static getInstance(): ContentMostViewedModel {
    if (!ContentMostViewedModel._instance) {
      ContentMostViewedModel._instance = new ContentMostViewedModel();
    }
    return ContentMostViewedModel._instance;
  }

  async getContentMostVieweds(): Promise<ContentMostViewedResource[]> {
    let contentMostVieweds: ContentMostViewedResource[];
    await axios.get<ContentMostViewedResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentMostVieweds = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return contentMostVieweds;
  }

  async updateContentMostViewed(id: number, secured: boolean): Promise<Error> {
    let result: Error;
    await axios.put<boolean,Error>(`${this.route}/${encodeURIComponent(id)}`,secured).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        result = response;
        result.message = 'Références pratiques - Doctrine modifié.';
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async updateContentMostViewedRanking(submitData: string): Promise<Error> {
    let result: Error;
    await axios.put<string,Error>(`${this.route}/ranking`, JSON.stringify(submitData)).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        result = response;
        result.message = 'Références pratiques - Doctrine modifié.';
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${encodeURIComponent(id)}`).then((response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Références pratiques - Doctrine supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }
}
