import { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  LibraryModel, 
} from 'src/common';
import type { LibraryResource } from 'src/common/types';
import { Grid } from '@mui/material';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';

interface QuoteProps {
  model: LibraryModel;
  library: LibraryResource, 
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const Quote: FC<QuoteProps> = ({ 
  model,
  library,
  setFieldValue 
}) => {
  const { QuoteFr, QuoteEn } = model;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={QuoteFr.Label}
          dataTestId={QuoteFr.Name}
          value={library.quoteFr}
          name={QuoteFr.Name}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={QuoteEn.Label}
          dataTestId={QuoteEn.Name}
          value={library.quoteEn}
          name={QuoteEn.Name}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
}

Quote.propTypes = {
  library: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default Quote;