import { LibraryTypeModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: LibraryTypeModel) => {
  const { NameFr, NameEn } = model;
  return Yup.object().shape({
    nameEn: NameEn.nullable(true),
    nameFr: NameFr.nullable(true)
  });
}
export default validateSchema;