import type { TribunalCodesResource, TribunalResource } from 'src/common/types';
import * as actionTypes from '../../ActionTypes';
import { TribunalModel } from 'src/common';
import { Paged } from 'src/types/pagination';

export const fetchTribunaux = (tribunaux: TribunalResource[], paged?: Paged, isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean, model?: TribunalModel): {
  type: 'FETCH_TRIBUNAUX',
  payload: {
    tribunaux: TribunalResource[],
    paged: Paged,
    isEmptyList?: boolean,
    isLoading?: boolean,
    isAuthorize?: boolean,
    model?: TribunalModel
  }
} => ({
  type: actionTypes.FETCH_TRIBUNAUX,
  payload: {
    tribunaux,
    paged,
    isLoading,
    isEmptyList,
    isAuthorize,
    model
  }
});

export const fetchTribunals = (tribunaux: TribunalResource[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
  type: 'FETCH_TRIBUNALS',
  payload: {
    tribunaux: TribunalResource[],
    isEmptyList?: boolean,
    isLoading?: boolean,
    isAuthorize?: boolean,
  }
} => ({
  type: actionTypes.FETCH_TRIBUNALS,
  payload: {
    tribunaux,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchTribunal = (tribunal: TribunalResource,  isLoading?: boolean): {
  type: 'FETCH_TRIBUNAL',
  payload: {
    tribunal: TribunalResource,
    isLoading?: boolean,
  }
} => ({
  type: actionTypes.FETCH_TRIBUNAL,
  payload: {
    tribunal,
    isLoading
  }
});

export const deleteTribunal = (id: number) : {
  type: 'DELETE_TRIBUNAL',
  payload: {
    id: number
  }
} => ({
  type: actionTypes.DELETE_TRIBUNAL,
  payload: {
    id
  }
});

export const fetchTribunalCodesNotAssigned = (tribunalCodesNotAssigned: TribunalCodesResource[], isLoading?: boolean, isEmptyList?: boolean) : {
  type: 'FETCH_TRIBUNAL_CODES_NOT_ASSIGNED',
  payload: {
    tribunalCodesNotAssigned: TribunalCodesResource[],
    isLoading?: boolean,
    isEmptyList?: boolean
  }
} => ({
  type: actionTypes.FETCH_TRIBUNAL_CODES_NOT_ASSIGNED,
  payload: {
    tribunalCodesNotAssigned,
    isLoading,
    isEmptyList
  }
});

/*
export const fetchTribunalCodes = (tribunalCodes: TribunalCodes[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
  type: 'FETCH_TRIBUNAL_CODES',
  payload: {
    tribunalCodes: TribunalCodes[],
    isLoading?: boolean,
    isEmptyList?: boolean,
    isAuthorize?: boolean
  }
} => ({
  type: actionTypes.FETCH_TRIBUNAL_CODES,
  payload: {
    tribunalCodes,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});
*/

