import { ChangeEvent, FC, useState } from 'react';
import PropTypes from 'prop-types';
import { 
  FormikErrors, 
  FormikTouched, 
  LibraryModel,  
  CaijButton,
  LoadScript,
  GoogleMap,
  googleApiKey,
  Marker
} from 'src/common';
import type { GoogleMaps, LibraryResource } from 'src/common/types';
import { Box, Grid } from '@mui/material';
import ContactAddressForm from './ContactAddressForm';

interface ContactAddressProps {
  model: LibraryModel;
  library: LibraryResource, 
  errors: FormikErrors<LibraryResource>;
  touched: FormikTouched<LibraryResource>;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ContactAddress: FC<ContactAddressProps> = ({ 
  model,
  library,
  errors,
  touched,
  handleBlur,
  setFieldValue 
}) => {
  const [latitude, setLatitude] = useState<number>(library.latitude);
  const [longtitude, setLongtitude] = useState<number>(library.longitude);
   
  const handleGeolocation = async () => {
    const coord : GoogleMaps = await model.handleGeolocation(library);
    coord?.latitude ? setLatitude(+coord.latitude) : setLatitude(0);
    coord?.longitude ? setLongtitude(+coord.longitude) : setLongtitude(0);
  }

  return (
    <>
      <Grid item md={6} xs={12}>
        <ContactAddressForm 
          model={model}
          contactAddress={library.addressFr}
          errors={errors} 
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue} 
        />
        <CaijButton 
          sx={{margin: '1em 0'}} 
          onHandleClick={async () => await handleGeolocation()}
        >
          Obtenir Location
        </CaijButton>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box sx={{pt:2}}>
          <LoadScript googleMapsApiKey={googleApiKey.key}>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '530px',
              }}
              center={{
                lat: latitude,
                lng: longtitude,
              }}
              zoom={15}
            >
              <Marker
                data-testid='Marker'
                title='Marker'
                position={{
                  lat: latitude,
                  lng: longtitude,
                }}
              />
            </GoogleMap>
          </LoadScript>
        </Box>
      </Grid>
    </>
  );
}

ContactAddress.propTypes = {
  library: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default ContactAddress;