import { formatString } from 'src/common';
import { EndorsedCustomerResource } from 'src/common/types';
import { BtnOk } from 'src/components/dialog/CaijDialog';

export default class DialogModel
{
  private _header: string;
  private _btnText: string; 
  private _name: string;
  private _resourceCode: string;
  private _endorser: EndorsedCustomerResource;
  
  constructor(){}

  set Header(value: string){
    this._header = value;
  }

  get Header(){
    return this._header;
  }

  set Name(value: string){
    this._name = value;
  }

  get Name(){
    return this._name;
  }
  
  set BtnText(value: string){
    this._btnText = value;
  }

  get BtnText(){
    return this._btnText;
  }

  set ResourceCode(value: string){
    this._resourceCode = value;
  }

  get ResourceCode() {
    return this._resourceCode;
  }

  set Endorser(value: EndorsedCustomerResource){
    this._endorser = value;
  }

  get Endorser() {
    return this._endorser;
  }
  
  get Body(){
    switch(this.BtnText){
      case BtnOk.DELETED:
        return formatString("Souhaitez-vous supprimer &1 ?", ['&1'], [this.Name]);
      case BtnOk.RESETPASSWORD:
        return formatString("Souhaitez-vous réinitialiser &1 du membre ?", ['&1'], [this.Name]);
      case BtnOk.DISABLED:
        return formatString("Souhaitez-vous désactiver &1 ?", ['&1'], [this.Name]);
      case BtnOk.SENDEMAIL:
        return formatString("Souhaitez-vous envoyer &1 de compte ?", ['&1'], [this.Name]);
      case BtnOk.APPROVED:
        return formatString("Souhaitez-vous approuver &1 ?", ['&1'], [this.Name]);
      case BtnOk.PUBLIER:
        return formatString("Afin de publier &1, vous devez mettre à jour la date de fin de publication ci-dessous", ['&1'], [this.Name]);
      case BtnOk.RESTRICT_STATUS:
        return "Souhaitez-vous restreindre le compte d'un membre ?";
      case BtnOk.REACTIVATE_STATUS:
        return "Souhaitez-vous lever la restriction du compte d'un membre ?";
      case BtnOk.ACTIVATE_STATUS:
      case BtnOk.DEACTIVATE_STATUS:
      case BtnOk.DELETED_STATUS:
        return "Souhaitez-vous changer le statut d'un membre ?";
      case BtnOk.PROXY:
        return "Êtes-vous certain que la base de données a été configurée dans le proxy?";
    } 
  }

  submit(value: boolean, setSelectedRow: (value: any[]) => void, close: () => void, deleteRow: () => Promise<void | boolean>){
    if(value){
      deleteRow();
    }else{
      setSelectedRow ? setSelectedRow([]) : '';
      close ? close() : '';
    }
  }

  submitPublisherMessage(value: string, setSelectedRow: (value: any[]) => void, close: () => void, onSubmitPublisherMessage: (value: string) => void){
    if(value){
      onSubmitPublisherMessage(value);
    }else{
      setSelectedRow ? setSelectedRow([]) : '';
      close ? close() : '';
    }
  }

  submitCustomerEndorsed(value: number, close: () => void, onSubmitCustomerEndorsed: (value: number) => void){
    if(value){
      onSubmitCustomerEndorsed(value);
    }else{
      close ? close() : '';
    }
  }
}
