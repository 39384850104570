import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { LawHomePageDto, LawResource } from 'src/common/types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import {
  labelConfig,
  CaijCheckbox,
  CaijInput,
  FormikErrors,
  FormikTouched,
  jurisdictionConfig,
  LawType,
  Authorize,
  Mode,
  Language,
  Label,
  CaseLaw,
  CaijTextarea,
  LawModel,
} from 'src/common';
import { $enum } from "ts-enum-util";
import { Edit as EditIcon } from 'react-feather';
import { ILawHomePage } from 'src/types/law';
import { publishVersion } from 'src/styles/law';
import { Root } from 'src/components/styled';
import IndexingKeyword from './indexingKeyword';
import { PageModel } from 'src/model/App';
import { getPublishVersion } from 'src/utils/law';
import LawHomePage from '../lawHomePage';
import CaijMultipleSelectCheckmarks from 'src/components/inputs/CaijMultipleSelectCheckmarks';
import useLawDomainField from 'src/hooks/useLawDomainField';

interface LawFormProps {
  model: LawModel;
  law: LawResource;
  lawHomePage?: ILawHomePage;
  errors: FormikErrors<LawResource>;
  touched: FormikTouched<LawResource>;
  handleBlur: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onHandleLawHomePage: (value: ILawHomePage) => void;
}

type Compare = [key: string, value: string];

function getLastContent(law: LawResource, lang: Language) {
  return law.lawHomePages.find((lawHomePage: LawHomePageDto) => lawHomePage.lang === lang);
};

const LawForm: FC<LawFormProps> = ({
  model,
  law,
  errors,
  touched,
  onHandleLawHomePage,
  handleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  const [open, setOpen] = useState<boolean>();
  const [language, setLanguage] = useState<Language>();
  const [lawHomePage, setLawHomePage] = useState<ILawHomePage>(null);
  const { isAuthorize, isLoading, isEmptyList, lawDomainFields } = useLawDomainField();
  const { Code, Jurisdiction, Type, TitleFr, TitleEn, Abreviation, LawReference, LawReferenceEn, FilterCaseLaw, LawDomain } = model;
  const {
    id,
    code,
    jurisdiction,
    type,
    titleFr,
    titleEn,
    published,
    abreviation,
    filterCaseLaw,
    queryCaseLaw,
    queryDoctrine,
    lawReference,
    lawReferenceEn,
    lawIndexKeywords,
    lawDomain,
    annotated
  } = law;

  useEffect(() => {
    if (id) {
      setLawHomePage({
        fr: getLastContent(law, Language.FR),
        en: getLastContent(law, Language.EN)
      });
    }
  }, [id]);

  useEffect(() => {
    onHandleLawHomePage(lawHomePage);
  }, [lawHomePage]);

  const closeDialog = () => setOpen(false)

  const handleClickOpen = (language: Language) => {
    setOpen(true);
    setLanguage(language);
  };

  const setLawIndexKeyword = (lawIndexKeywords: string[]): void => {
    law.lawIndexKeywords = lawIndexKeywords;
  }

  const setPublish = (value: LawHomePageDto, language: Language) => {
    setLawHomePage({ ...lawHomePage, [language.toLowerCase()]: value });
    closeDialog();
  }

  return (
    <>
      { id && (
          <Grid item md={12} xs={12}>
            <CaijCheckbox
              name='published'
              id='published'
              checked={published}
              label={labelConfig.published}
              onHandleChangeCheckBox={() => setFieldValue('published', !published)}
              inputProps={{ 'aria-label': 'published', 'data-testid': 'published' }}
            />
          </Grid>
        )
      }
      <Grid item md={12} xs={12}>
        <CaijInput
          name={Code.Name}
          id={Code.Name}
          required
          value={code}
          error={Boolean(touched.code && errors.code)}
          helperText={touched.code && errors.code}
          label={Code.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: Code.MaxLength, 'data-testid': 'code' }}
          onHandleBlur={handleBlur}
          onHandleChange={(e: ChangeEvent<HTMLInputElement>) => {
            const code = e.target.value;
            const codes = Object.assign([], code);
            setFieldValue('code', codes.join('').toUpperCase());
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={TitleFr.Name}
          id={TitleFr.Name}
          required
          value={titleFr}
          error={Boolean(touched.titleFr && errors.titleFr)}
          helperText={touched.titleFr && errors.titleFr}
          label={TitleFr.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: TitleFr.MaxLength, 'data-testid': 'titleFr' }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={TitleEn.Name}
          id={TitleEn.Name}
          required
          value={titleEn}
          error={Boolean(touched.titleEn && errors.titleEn)}
          helperText={touched.titleEn && errors.titleEn}
          label={TitleEn.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: TitleEn.MaxLength, 'data-testid': 'titleEn' }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijCheckbox
          name='annotated'
          id='annotated'
          checked={annotated}
          label={labelConfig.annotated}
          onHandleChangeCheckBox={() => setFieldValue('annotated', !annotated)}
          inputProps={{ 'aria-label': 'annotated', 'data-testid': 'annotated' }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          required
          label={Jurisdiction.Label}
          name={Jurisdiction.Name}
          onHandleChange={(l) => onHandleChange(l, setFieldValue)}
          select
          value={jurisdiction}
          variant='outlined'
          error={Boolean(touched.jurisdiction && errors.jurisdiction)}
          helperText={touched.jurisdiction && errors.jurisdiction}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ 'data-testid': Jurisdiction.Name }}
        >
          <option value="">-- Veuillez choisir une juridiction --</option>
          {
            jurisdictionConfig && Object.entries(jurisdictionConfig)
              .sort((a: Compare, b: Compare) => a[1].localeCompare(b[1]))
              .map((el) => (
                <option value={el[0]} key={el[0]}>
                  {el[1]}
                </option>
              ))
          }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          required
          label={Type.Label}
          name={Type.Name}
          onHandleChange={(l) => onHandleChange(l, setFieldValue)}
          select
          value={type}
          variant='outlined'
          error={Boolean(touched.type && errors.type)}
          helperText={touched.type && errors.type}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ 'data-testid': Type.Name }}
        >
          <option value="">{`${Type.Label} est obligatoire`}</option>
          {
            $enum(LawType).map((value: string, key: string) => (
              <option value={value} key={key}>
                {value}
              </option>
            ))
          }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijMultipleSelectCheckmarks
          required
          label={labelConfig.lawDomain}
          data={!isLoading ? lawDomainFields?.map(({id, nameFr}) => ({id : id.toString(), name: nameFr})) : []}
          onHandleChange={(event: SelectChangeEvent<any>) => setFieldValue(LawDomain.Name, typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
          values={lawDomain?.map(id => id.toString())}
          sx={{margin: '10px 0 20px 0', width: '50%'}}
        >
          { (!isLoading && isAuthorize && !isEmptyList && lawDomainFields) && lawDomainFields.map(({id, nameFr}) => (
            <MenuItem key={id} value={id.toString()}>
              <Checkbox checked={lawDomain?.findIndex(lawDomainId => lawDomainId.toString() === id.toString()) > -1} />
              <ListItemText primary={nameFr} />
            </MenuItem>
            ))
          }
        </CaijMultipleSelectCheckmarks>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          name={Abreviation.Name}
          id={Abreviation.Name}
          value={abreviation}
          error={Boolean(touched.abreviation && errors.abreviation)}
          helperText={touched.abreviation && errors.abreviation}
          label={Abreviation.Label}
          InputLabelProps={{ shrink: true }}
          inputAttr={{ maxLength: Abreviation.MaxLength, 'data-testid': 'abreviation' }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={LawReference.Name}
          id={LawReference.Name}
          required
          value={lawReference}
          error={Boolean(touched.lawReference && errors.lawReference)}
          helperText={touched.lawReference && errors.lawReference}
          label={LawReference.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: LawReference.MaxLength, 'data-testid': LawReference.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={LawReferenceEn.Name}
          id={LawReferenceEn.Name}
          required
          value={lawReferenceEn}
          error={Boolean(touched.lawReferenceEn && errors.lawReferenceEn)}
          helperText={touched.lawReferenceEn && errors.lawReferenceEn}
          label={LawReferenceEn.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: LawReferenceEn.MaxLength, 'data-testid': LawReferenceEn.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          label={FilterCaseLaw.Label}
          name={FilterCaseLaw.Name}
          onHandleChange={(l) => onHandleChange(l, setFieldValue)}
          select
          value={filterCaseLaw || ''}
          variant='outlined'
          error={Boolean(touched.filterCaseLaw && errors.filterCaseLaw)}
          helperText={touched.filterCaseLaw && errors.filterCaseLaw}
          InputLabelProps={{ shrink: true }}
          inputAttr={{'data-testid': FilterCaseLaw.Name}}
        >
          <option value=''>Sélectionner une jurisprudence</option>
          {
            $enum(CaseLaw).map((value: string, key: string) => (
              <option value={value} key={key}>
                {value}
              </option>
            ))
          }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijTextarea
          name='queryCaseLaw'
          rows='4'
          label={labelConfig.queryCaseLaw}
          value={queryCaseLaw}
          inputProps={{ 'aria-label': 'queryCaseLaw', 'data-testid': 'queryCaseLaw' }}
          InputLabelProps={{ shrink: true }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijTextarea
          name='queryDoctrine'
          rows='4'
          label={labelConfig.queryDoctrine}
          value={queryDoctrine}
          inputProps={{ 'aria-label': 'queryDoctrine', 'data-testid': 'queryDoctrine' }}
          InputLabelProps={{ shrink: true }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <IndexingKeyword
          model={model}
          lawIndexKeywords={lawIndexKeywords}
          lawId={id}
          setLawIndexKeyword={(l) => setLawIndexKeyword(l)}
        />
      </Grid>
      { id && lawHomePage && (
          <Grid item md={12} xs={12}>
            <Box flexDirection="column">
              <Typography sx={{ marginBottom: '1em', fontSize: '16px', fontWeight: 'bold' }}>{`Page d'${PageModel.HOME.toLowerCase()}`}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Authorize
                resourceCode={model.ResourceCode}
                mode={Mode.edit}
                onIsAuth={l => setIsEditAuth(l)}
              >
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Button
                    disabled={!isEditAuth}
                    startIcon={<EditIcon />}
                    onClick={() => handleClickOpen(Language.FR)}
                  >
                    Version française
                  </Button>
                  <Box display="flex" alignItems="center">
                    <Typography sx={{ ...publishVersion, ml: 4 }} variant="body2">
                      {getPublishVersion(law.lawHomePages.find(({ lang }) => lang === Language.FR))}
                    </Typography>
                    <div>
                      {lawHomePage.fr?.page && <Label color='success'>Publier</Label>}
                    </div>
                  </Box>
                </Box>
              </Authorize>
            </Box>
            <Box>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={Mode.edit}
                onIsAuth={l => setIsEditAuth(l)}
              >
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Button
                    disabled={!isEditAuth}
                    startIcon={<EditIcon />}
                    onClick={() => handleClickOpen(Language.EN)}
                  >
                    Version Anglaise
                  </Button>
                  <Box display="flex" alignItems="center">
                    <Typography sx={{ ...publishVersion, ml: 4 }} variant="body2">
                      {getPublishVersion(law.lawHomePages?.find(({ lang }) => lang === Language.EN))}
                    </Typography>
                    <div>
                      {lawHomePage.en?.page && <Label color='success'>Publish</Label>}
                    </div>
                  </Box>
                </Box>
              </Authorize>
            </Box>
            <LawHomePage
              open={open}
              law={law}
              lawHomePage={language === Language.EN ? lawHomePage.en : lawHomePage.fr}
              language={language}
              handleClose={closeDialog}
              publish={setPublish}
            />
          </Grid>
        )
      }
    </>
  );
};

LawForm.propTypes = {
  law: PropTypes.object.isRequired,
  lawHomePage: PropTypes.any,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleLawHomePage: PropTypes.func.isRequired
};

export default LawForm;
