// Config object to be passed to Msal on creation.
// For a full list of msal.js configuration parameters,
// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html

// eslint-disable-next-line
enum CacheStorage {
    LocalStorage = 'localStorage',
    SessionStorage = 'sessionStorage'
  }

export const msalConfig = {
  auth: {
    clientId: '610f3317-8089-4c28-8fe8-ff5c870c9853', // "35422f54-52e5-4a42-9835-da4bf2db4ce3",
    authority: 'https://login.microsoftonline.com/70ac3e2f-18e7-497b-8f4f-be76e8f9bca7',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: CacheStorage.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['https://admin.caij.qc.ca/access_as_user']
};

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: ['https://admin.caij.qc.ca/access_as_user']
};
