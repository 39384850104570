import type { CoveoSourceDto, DocumentCollectionResource, SubscriptionResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchDocCollectionsAction = {
    type: 'FETCH_DOCUMENT_COLLECTIONS';
    payload: {
      docCollections: DocumentCollectionResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchDocCollectionAction = {
  type: 'FETCH_DOCUMENT_COLLECTION';
  payload: {
    docCollection: DocumentCollectionResource;
    coveoSources: CoveoSourceDto[];
    isLoading?: boolean;
  }
};

type DeleteDocCollectionAction = {
  type: 'DELETE_DOCUMENT_COLLECTION';
  payload: {
    id: number;
  }
};

type Action = FetchDocCollectionsAction |
              FetchDocCollectionAction |
              DeleteDocCollectionAction;

export interface DocCollectionReturnState {
  docCollections?: DocumentCollectionResource[];
  docCollection?: DocumentCollectionResource;
  subscriptions?: SubscriptionResource[];
  coveoSources?: CoveoSourceDto[];
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface DocCollectionState {
  docCollections: DocumentCollectionResource[];
  docCollection: DocumentCollectionResource;
  subscriptions: SubscriptionResource[];
  coveoSources: CoveoSourceDto[];
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: DocCollectionState, action: Action): DocCollectionReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_COLLECTIONS: {
      const { docCollections, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        docCollections,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_DOCUMENT_COLLECTION: {
      const { docCollection, coveoSources, isLoading } = action.payload;
      return {
        ...state,
        docCollection,
        coveoSources,
        isLoading
      };
    }
    case actionTypes.DELETE_DOCUMENT_COLLECTION: {
      return {
        ...state,
        docCollections: [...state.docCollections].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
