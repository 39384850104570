import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Card, 
  CardHeader, 
  Grid,
  TableBody, 
  CardContent,
  CardActions,
  Link,
  TableRow} from '@mui/material';
import type { StatsDto } from 'src/common/types';
import type { StatsResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { 
  CustomerModel, 
  EmptyList, 
  EnhancedTableHead, 
  tableRow
} from 'src/common';
import NumberFormat from 'react-number-format'
import CardRequestPendingModel from './../../model/customer/CardRequestPending';
import StatsRow from './StatsRow';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import SearchEngineSource from '../../components/coveo/SearchEngineSource';
import useSort from 'src/functions/hooks/sort';

export interface ChartsProps {
  activeCustomer: StatsResource;
  cardRequestCustomer: StatsResource;
  delayAccountCustomer: StatsResource;
  activeCustomersBySubscription: StatsResource;
  activeCustomersByGroup: StatsDto[];
}

const info = {
  color: 'text.secondary',
  fontSize: '25px'
};

const cardHeaderTitle = {
  '& .MuiCardHeader-title': { 
    fontSize: '20px',
  }
};

function StatsCard(props: {title: string, count: number, link: string, href: string}) : JSX.Element{
  const { title, count, link, href } = props;
  return (
    <Card>
      <CardHeader title={title} sx={cardHeaderTitle}/>
      <CardContent>
      <Box sx={info}>
        <NumberFormat value={count} displayType={'text'} thousandSeparator="." decimalSeparator=";" />
      </Box>
      </CardContent>
      <CardActions>
        <Link href={href} variant="body2" underline='none'>
          {link}
        </Link>
      </CardActions>
    </Card>
  )
};

const Charts: FC<ChartsProps> = ({ 
  activeCustomer,
  cardRequestCustomer,
  delayAccountCustomer,
  activeCustomersBySubscription,
  activeCustomersByGroup
}) => {
  const customerModel = CustomerModel.getInstance();
  const [page, setPage] = useState<number>(0);
  const headerCells = customerModel.getStatsHeadCells()
  const { order, orderBy, sort, handleRequestSort } = useSort();

  const renderRows = () => {
    const { isLoading, isEmptyList, data } = activeCustomersBySubscription;
    if(isLoading && isEmptyList){
      return (
        <TableRow sx={tableRow}>
          <CaijTableCell colSpan={headerCells.length}>
            <EmptyList noCard />
          </CaijTableCell>
        </TableRow>
      )
    }else if(isLoading && data){
      return sort<StatsDto>(data, page).map((row: StatsDto, index: number) => (
          <StatsRow 
            stats={row} 
            key={index} 
            model={customerModel} 
            activeCustomersByGroups={activeCustomersByGroup} 
          />
        ))
    }
  }

  return (
    <>
        <Grid container spacing={3} mb={5}>
          <Grid item md={4} xs={12}>
            {
              activeCustomer.isLoading && (
                <StatsCard 
                  title={activeCustomer.title}
                  count={activeCustomer.counter}
                  link="Voir les comptes"
                  href={`${customerModel.Path.Home}?status=Active&sort=asc`}
                />
              )
            }
          </Grid>
          <Grid item md={4} xs={12}>
            {
              delayAccountCustomer.isLoading && (
                <StatsCard 
                  title={delayAccountCustomer.title}
                  count={delayAccountCustomer.counter}
                  link="Voir les comptes"
                  href={`${customerModel.Path.Home}?sort=asc&delayAccountCustomer=true`}
                />
              )
            }
          </Grid>
          <Grid item md={4} xs={12}>
            {
              cardRequestCustomer.isLoading && (
                <StatsCard 
                  title={cardRequestCustomer.title}
                  count={cardRequestCustomer.counter}
                  link="Voir les demandes"
                  href={CardRequestPendingModel.getInstance().Path.Home}
                />
              )
            }
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <SearchEngineSource />
          </Grid>
        </Grid>
        <MyCard>
          <CaijTable>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headerCells}
            />
            <TableBody>
              {renderRows()}
            </TableBody>
          </CaijTable>
        </MyCard>
    </>
  );
};

Charts.propTypes = {
  activeCustomersBySubscription: PropTypes.object,
  activeCustomer: PropTypes.object,
  cardRequestCustomer: PropTypes.object,
  delayAccountCustomer: PropTypes.object
};

export default Charts;
