import React from 'react';
import type { FC, ReactNode } from 'react';
import { FormHelperText } from '@mui/material';

interface CaijErrorProps {
  sx?: any;
  children: ReactNode;
}

const CaijError: FC<CaijErrorProps> = ({ children, sx }) => (
  <FormHelperText sx={{color: 'text.error', marginTop: '5px', ...sx}}>
    {children ?? " "}
  </FormHelperText>
);

export default CaijError;