import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody, TableRow,
} from '@mui/material';
import {
  EmptyList,
  CustomerModel,
  tableRow
} from 'src/common';
import type { CustomerResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import TableHeader from './TableHeader';
import TableCells from './TableCells';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';

export interface ResultsProps {
  customers: CustomerResource[];
  isEmptyList: boolean;
}

const Results: FC<ResultsProps> = ({
  customers,
  isEmptyList,
}) => {
  const model = CustomerModel.getInstance();
  const path = model.Path;
  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <CaijTable>
        <TableHeader model={model}/>
        <TableBody>
          { customers.map((row: CustomerResource) => (
              <TableRow hover tabIndex={-1} key={row.id} sx={tableRow}>
                <TableCells customer={row} path={path}>
                <CaijTableCellAction 
                  showRemoveIcon={false}
                  pageEditUrl={path.getEditCustomerProfile(row.id)}
                  resourceCode={model.ResourceCode}
                />
                </TableCells>
              </TableRow>
              )
            )
          }
        </TableBody>
      </CaijTable>
    </MyCard>
  );
};

Results.propTypes = {
  customers: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool
};

export default Results;
