import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, TableBody } from '@mui/material';
import {
  labelConfig,
  getOpacity,
  DocCollectionsModel,
  ListType,
  getEmptyListSearch,
  NavigateFunction
} from 'src/common';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import type { DocumentCollectionsResource, SearchFilter, Sort } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';
import Search from 'src/components/search';
import { Paged } from 'src/types/pagination';

export interface ResultsProps {
  model: DocCollectionsModel;
  collections: DocumentCollectionsResource[];
  isEmptyList: boolean;
  deleteCollection: (id: number) => Promise<void>;
  navigate: NavigateFunction;
  paged: Paged;
  location: {search: string};
}

const Results: FC<ResultsProps> = ({
  model,
  collections,
  deleteCollection,
  isEmptyList,
  ...rest
}) => {
  const { navigate, paged, location } = rest;
  const searchParams = new URLSearchParams(location?.search);
  const path = model.Path;
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const { order, orderBy, setOrderBy, handleRequestSort } = useSort();
  const [filter, setFilter] = useState<SearchFilter>({query: searchParams.get("query"), doSearch: false, page: paged?.currentPage - 1, sort: null, sortedBy: ''});
  const headCells = model.getHeadCells(labelConfig.status);

  useEffect(() => setOrderBy('title'),[]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryVal = params.get('query');
    const  pageVal = params.get('page');
    const sortVal = params.get('sort');
    const sortedByVal = params.get('sortedBy');
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        page: +pageVal,
        doSearch: queryVal ? true : false,
        sort: sortVal as Sort,
        sortedBy: sortedByVal
      }
    });
    setPage(+pageVal);
  },[location?.search]);
  
  const renderTableCell = (row: DocumentCollectionsResource) => {
    const opacity = getOpacity(row.active);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.collectionCode}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.title}</CaijTableCell>
        <CaijTableCell opacity={opacity} whiteSpace='pre-wrap'>{row.descriptionFr}</CaijTableCell>
        <CaijTableCell opacity={opacity} whiteSpace='pre-wrap'>{row.descriptionEn}</CaijTableCell>
        <CaijTableCellActive active={row.active} />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          handleToggle={() => DocCollectionsModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.id) !== -1}
            onSubmit={async () => {
              await deleteCollection(row.id);
              setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
        </CaijTableCellAction>
      </>
    )
  }

  return (
    <MyCard>
      <Box minHeight={56} sx={{margin:'0 0 20px 15px'}}>
        <Search 
          listType={ListType.Collections}
          placeholder='Recherche la/les collection(s)' 
          filter={filter}
          width="400px"
          path={path.Home}
        />
      </Box>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={(e,p) => handleRequestSort<DocCollectionsModel>(e,p,model,filter,navigate)}
          headCells={headCells}
        />
        <TableBody>
          { isEmptyList ? getEmptyListSearch(headCells.length) :
            collections.map((row: DocumentCollectionsResource) => (
              <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                {renderTableCell(row)}
              </CaijTableRowClickable>
              ) 
            )
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={paged} 
        model={model} 
        filter={filter} 
        page={page}
        navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
      />
    </MyCard>
  );
};

Results.propTypes = {
  collections: PropTypes.array.isRequired,
  deleteCollection: PropTypes.func.isRequired
};

export default Results;
