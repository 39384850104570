import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { SubscriptionResource } from 'src/common/types';
import { Box } from '@mui/material';
import {
  labelConfig,
  CaijCheckbox,
  CaijCard
} from 'src/common';

interface MemberAccessFormProps {
  subscription: SubscriptionResource;
  onHandleChangeCheckBox: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const MemberAccessForm: FC<MemberAccessFormProps> = ({
  subscription,
  onHandleChangeCheckBox,
  setFieldValue,
}) => {
  const { hasContent } = subscription;
  return (
    <Box>
      <CaijCard title="Contenu privé">
        <CaijCheckbox
          name='hasContent'
          label={labelConfig.hasContent}
          checked={hasContent}
          value={hasContent}
          inputProps={{ 'aria-labelledby': "hasContent", 'data-testid' : 'hasContent' }}
          onHandleChangeCheckBox={onHandleChangeCheckBox}
          setFieldValue={setFieldValue}
        />
      </CaijCard>
    </Box>
  );
};

MemberAccessForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func.isRequired
};

export default MemberAccessForm;
