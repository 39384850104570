import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  _coveoSource,
  SearchEngineModel,
  _coveoTaskHistory,
  useNavigate,
  AccessModel,
  ITEMS_PER_PAGE,
  root
} from 'src/common';
import type { Sort, GetSearchEngineTaskHistoryListOptionalParams } from 'src/common/types';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { fetchCoveoTaskHistories } from 'src/store/actions/coveo/taskHistory/TaskHistoryAction';
import Results from './Results';
import { useEmployees } from 'src/functions/hooks/employee';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  coveoTaskHistories: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: false,
  isEmptyList: true
};

const CoveoTaskHistoryListView: FC = () => {
  const navigate = useNavigate();
  const model = new SearchEngineModel();
  const access = new AccessModel(model.ResourceCode);
  model.HeaderTitle = "Historique des tâches";
  const location = useLocation();
  const [state, dispatch] = useReducer(_coveoTaskHistory, initialState);
  const { employees } = useEmployees();

  const getCoveoTaskHistories = useCallback(async (model: SearchEngineModel, params: GetSearchEngineTaskHistoryListOptionalParams) : Promise<void> => {
    const { search, pathname } = location;
    model.PathName = pathname + search;
    const results = await model.getCoveoTaskHistories(params);
    if (!model.error) {
      dispatch(fetchCoveoTaskHistories(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0));
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname } = location;
        let params: GetSearchEngineTaskHistoryListOptionalParams = { pageNumber: 1, pageSize: ITEMS_PER_PAGE };
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate, true)){
            const { Filters: { sort, page, coveoSourceId, name, email, collection, startDate, endDate, task, status, coveoIdentifier }} = model;
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1,
              sortOptions: model.getQueryParam(sort) as Sort || 'desc',
              coveoSourceId: +model.getQueryParam(coveoSourceId),
              sourceName: model.getQueryParam(name),
              sourceCollection: model.getQueryParam(collection),
              coveoIdentifier: model.getQueryParam(coveoIdentifier),
              status: model.getQueryParam(status),
              task: model.getQueryParam(task),
              startDate: model.getQueryParam(startDate),
              endDate: model.getQueryParam(endDate),
              email: model.getQueryParam(email)
            };
            await getCoveoTaskHistories.apply(null,[model,params]);
          }
        }else
          await getCoveoTaskHistories.apply(null,[model,params]);
      }
    })();
  },[location]);
 
  const { coveoTaskHistories, paged, isLoading, isEmptyList } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            employees={employees}
            coveoTaskHistories={coveoTaskHistories}
            isEmptyList={isEmptyList}
            paged={paged}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CoveoTaskHistoryListView;
