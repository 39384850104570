import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { Forbidden, LibraryServiceModel } from 'src/common';
import { Resource, Error, LibraryServiceResource } from 'src/common/types';
import ResourceModel from 'src/model/employee/Resource';
import printMessage from 'src/views/errors/MessageError';

export interface LibraryServiceContextValue {
  libraryServices: LibraryServiceResource[];
  isAuthorize?: boolean;
  isLoading?: boolean;
  isEmptyList?: boolean;
  error?: Error;
}

interface LibraryServiceProviderProps {
  children: ReactNode;
}

type fetchLibraryServicesAction = {
  type: 'FETCH_LIBRARY_SERVICES';
  payload: {
    libraryServices: LibraryServiceResource[];
    isAuthorize?: boolean;
    isLoading?: boolean;
    isEmptyList?: boolean;
    error?: Error;
  };
};

type Action = fetchLibraryServicesAction;

interface LibraryServiceState {
  libraryServices: LibraryServiceResource[];
  isLoading: boolean;
  isAuthorize: boolean;
  isEmptyList: boolean;
  error: Error;
}

const initialLibraryServiceState = {
  libraryServices: [],
  isLoading: false,
  isAuthorize: false,
  isEmptyList: true,
  error: null
};

const reducer = (state: LibraryServiceState, action: Action): LibraryServiceState => {
  switch (action.type) {
    case 'FETCH_LIBRARY_SERVICES': {
      const { libraryServices, isAuthorize, isLoading, isEmptyList, error } = action.payload;
      return {
        ...state,
        libraryServices,
        isAuthorize,
        isLoading,
        isEmptyList,
        error
      };
    }
    default: {
      return { ...state };
    }
  }
};

const LibraryServiceContext = createContext<LibraryServiceContextValue>({
  ...initialLibraryServiceState
});

export const LibraryServiceProvider: FC<LibraryServiceProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialLibraryServiceState);
  useEffect(() => {
    const initialise = async () => {
      const model = new LibraryServiceModel();
      model.skipHandleError = true;
      const libraryServices = await model.getLibraryServices();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden) {
          dispatch({
            type: 'FETCH_LIBRARY_SERVICES',
            payload: {
              libraryServices: [],
              isAuthorize: false,
              isLoading: true,
              isEmptyList: true,
              error
            }
          });
        }else{
          dispatch({
            type: 'FETCH_LIBRARY_SERVICES',
            payload: {
              libraryServices: [],
              isLoading: true,
              isEmptyList: true,
              error
            }
          });
        }
        printMessage(error);
      }else{
        dispatch({
          type: 'FETCH_LIBRARY_SERVICES',
          payload: {
            libraryServices,
            isLoading: true,
            isAuthorize: true,
            isEmptyList: !Array.isArray(libraryServices) || libraryServices.length === 0
          }
        });
      }
    };
    initialise();
  }, [dispatch]);

  return (
    <LibraryServiceContext.Provider
      value={{
        ...state
      }}
    >
      {children}
    </LibraryServiceContext.Provider>
  );
};

LibraryServiceProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default LibraryServiceContext;
