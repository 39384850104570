import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  Mode,
  btnSubmit,
  handleChangeCheckBox,
  DocCollectionsModel,
} from 'src/common';
import type {
  DocumentCollectionsResource,
  DocumentCollectionsResourceForCreate,
  DocumentCollectionsResourceForEdit
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';
import DocCollectionsForm from 'src/components/content/docCollections/Forms';

export interface DocCollectionsEditFormProps {
  model: DocCollectionsModel;
  collection: DocumentCollectionsResource;
  onSubmit?: (values:DocumentCollectionsResource) => void;
}

const DocCollectionsEditForm: FC<DocCollectionsEditFormProps> = ({
  model,
  collection,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  
  const handleSubmit = async (values: DocumentCollectionsResource) => {
    if (onSubmit){
		  onSubmit(values);
      return;
    }
    let submitData = values as DocumentCollectionsResourceForEdit;
    const model = new DocCollectionsModel();
    if (submitData && submitData.id) {
      const response = await model.updateCollectionDocument(submitData);
      if (!model.error) {
        const { status, message } = response;
        await model.redirect(model.Path.Home,{status,message});
      }
    } else {
      submitData = values as DocumentCollectionsResourceForCreate;
      delete submitData.id;
      const response = await model.insertCollectionDocument(submitData);
      if (!model.error) {
        const { status, message } = response;
        await model.redirect(model.Path.getDetail(response.data.id),{status,message});
      }
    }
  }

  return (
    <Formik
      initialValues={DocCollectionsModel.getInitialValues(collection)}
      validateOnChange={false}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <DocCollectionsForm
                  model={model}
                  collection={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                />
              </Grid> 
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={collection.id ? Mode.edit : Mode.add}
                  onIsAuth={l => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DocCollectionsEditForm.propTypes = {
  collection: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

DocCollectionsEditForm.defaultProps = {
  onSubmit: null
};

export default DocCollectionsEditForm;
