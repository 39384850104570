import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentTypeResource, DocumentTypeResourceForEdit } from 'src/common/types';
import { Box, CardContent } from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  DocumentTypeModel,
  _documentType,
  Mode,
  button
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import DocumentTypeForm from 'src/components/content/documentType/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: DocumentTypeModel;
  documentType: DocumentTypeResource;
  onHandleRefreshDocumentType: (value: DocumentTypeResource) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values:DocumentTypeResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  documentType,
  onHandleRefreshDocumentType,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: DocumentTypeResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const submitData = values as DocumentTypeResourceForEdit;
    if (submitData && submitData.code) {
      const model = new DocumentTypeModel();
      const response = await model.updateDocumentType(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message,
        });
        const newDocumentType = await model.getDocumentTypeById(submitData.code);
        await onHandleRefreshDocumentType(newDocumentType);
      }
    }
  };
  
  return (
    <Formik
      initialValues={DocumentTypeModel.getInitialValues(documentType)}
      validationSchema={validateSchema(model, documentType)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <DocumentTypeForm 
                 model={model}
                 documentType={values}
                 errors={errors}
                 touched={touched}
                 onHandleBlur={handleBlur}
                 onHandleChange={handleChange}
                 setFieldValue={setFieldValue}
              />
              <Box mt={2}>
                <Authorize 
                   resourceCode={model.ResourceCode} 
                   mode={Mode.edit}
                   onIsAuth={(l) => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={button} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  documentType: PropTypes.object.isRequired,
  onHandleRefreshDocumentType: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
