import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Box,
  ListItem,
  Typography
} from '@mui/material';
import type { DatabankResource } from 'src/common/types';
import Libraries from 'src/components/databank/Lists/Libraries';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijCard, MSG_EMPTY_LIST } from 'src/common';

interface LibraryAccessInfoProps {
  databank: DatabankResource;
}

const LibraryAccessInfo: FC<LibraryAccessInfoProps> = ({
  databank
}) => { 
  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12} lg={12}>
        <MyCard>
          <CardHeaderDetail title="Accès en bibliothèque" />
          <Divider />
          <Box m={3}>
          {
            databank.libraries.length === 0 ? (
                <CaijCard title="Bibliothèques d'accès">
                  <ListItem sx={{ justifyContent: 'center'}}>
                    <Typography variant="body2" sx={{color: 'text.error'}}>{MSG_EMPTY_LIST}</Typography>
                  </ListItem>
                </CaijCard>
              ) : (<Libraries databank={databank} viewOnly={true} />)
          }
          </Box>
        </MyCard>
      </Grid>
    </Grid>
  );
};

LibraryAccessInfo.propTypes = {
  databank: PropTypes.object.isRequired
};

export default LibraryAccessInfo;
