import React, { useState } from 'react';
import type { FC, Dispatch } from 'react';
import PropTypes from 'prop-types';
import {$enum} from "ts-enum-util";
import type { 
  AccountExpiryType, 
  AccountRenewalExpiryOptions, 
  HandleChange, 
  SetFieldValue, 
  SubscriptionGroupResource, 
  SubscriptionResource 
} from 'src/common/types';
import { Box, Typography } from '@mui/material';
import {
  CaijCheckbox,
  CaijInputRank,
  AccExpRenewMode,
  AccountExpiryMode,
  CaijDayPicker,
  CaijMonthPicker,
  SubscriptionModel,
  SbGroup,
  CaijInput,
  CaijInputLink
} from 'src/common';
import { FormModel } from 'src/model/Form';
import useEmailTemplates from 'src/functions/hooks/emailTemplate';

export interface AccountFormProps {
  model: SubscriptionModel;
  subscription?: SubscriptionResource;
  subscriptionGroup?: SubscriptionGroupResource;
  onHandleChange: HandleChange;
  onHandleChangeCheckBox?: HandleChange;
  setFieldValue: SetFieldValue;
  setIsExpireOnValid: Dispatch<React.SetStateAction<boolean>>;
}

const expireOnDate = SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.ExpireOnDate);
const expireAfterDays = SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.ExpireAfterDays);
const neverExpire = SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.NeverExpire);

interface IAccountForm {
  accountExpiry?: AccountRenewalExpiryOptions;
  accountExpiryMode?: string;
  accountRenewMode?: string;
  emailTemplateId?: number;
  formUrl?: string;
  expireInDaysForm?: FormModel;
  accountExpiryModeForm?: FormModel;
  accountRenewModeForm?: FormModel;
  dayForm?: FormModel;
  monthForm?: FormModel;
  emailTemplateIdForm?: FormModel;
  formUrlForm?: FormModel;
}

const getData = (model: SubscriptionModel, subscription: SubscriptionResource, subscriptionGroup: SubscriptionGroupResource) => {
  let data: IAccountForm = {};
  if(subscriptionGroup){
    const accountExpiry = 'accountExpiry';
    const expireOn = `${accountExpiry}.expireOn`;
    data = {
      accountExpiry: subscriptionGroup?.accountExpiry,
      accountExpiryMode: subscriptionGroup?.accountExpiryMode,
      accountRenewMode: subscriptionGroup?.accountRenewMode,
      emailTemplateId: subscriptionGroup?.emailTemplateId,
      formUrl: subscriptionGroup?.formUrl,
      dayForm: model.getDay(`${expireOn}.day`),
      monthForm: model.getMonth(`${expireOn}.month`),
      expireInDaysForm: model.getAccountDurationDays(`${accountExpiry}.expireInDays`),
      accountExpiryModeForm: model.getAccountExpiryMode('accountExpiryMode'),
      accountRenewModeForm: model.getAccountRenewMode('accountRenewMode'),
      emailTemplateIdForm: model.getEmailTemplateId('emailTemplateId'),
      formUrlForm: model.getFormUrl('formUrl')
    }
  }else{  
    const accountExpiry = 'subscriptionGroup.accountExpiry';
    const expireOn = `${accountExpiry}.expireOn`;
    data = {
      accountExpiry: subscription?.subscriptionGroup?.accountExpiry,
      accountExpiryMode: subscription?.subscriptionGroup?.accountExpiryMode,
      accountRenewMode: subscription?.subscriptionGroup?.accountRenewMode,
      emailTemplateId: subscription?.subscriptionGroup?.emailTemplateId,
      formUrl: subscription?.subscriptionGroup?.formUrl,
      dayForm: model.getDay(`${expireOn}.day`),
      monthForm: model.getMonth(`${expireOn}.month`),
      expireInDaysForm: model.getAccountDurationDays(`${accountExpiry}.expireInDays`),
      accountExpiryModeForm: model.getAccountExpiryMode('subscriptionGroup.accountExpiryMode'),
      accountRenewModeForm: model.getAccountRenewMode('subscriptionGroup.accountRenewMode'),
      emailTemplateIdForm: model.getEmailTemplateId('subscriptionGroup.emailTemplateId'),
      formUrlForm: model.getFormUrl('subscriptionGroup.formUrl')
    }
  }
  return data;
}

const AccountForm: FC<AccountFormProps> = ({
  model,
  subscription,
  subscriptionGroup,
  onHandleChange,
  onHandleChangeCheckBox,
  setFieldValue,
  setIsExpireOnValid
}) => {
  const { SendLcapNotif } = model;
  const data: IAccountForm = getData(model, subscription, subscriptionGroup);
  const [helperText, setHelperText] = useState<string | undefined>('');
  const { isAuthorize, isLoading, emailTemplates, isEmptyList } = useEmailTemplates();

  const renderContent = (accountExpiryMode: AccountExpiryType) : JSX.Element => {
    if(accountExpiryMode === expireOnDate){
      return  (
        <Box display="flex" flexDirection="column" mb={2}>
          <Box mb={4}>
            <Typography variant="body1">
              Tous les comptes de l'abonnement expireront à la date indiqué de l'année en cours, ou de l'année prochaine si celle-ci est passée
            </Typography>
          </Box>
          <Box mb={3} display='flex' alignItems='flex-start'>
            <Box width="50%" display="flex" justifyContent="left">
              <CaijDayPicker 
                name='day'
                required
                label={data.dayForm.Label}
                value={+data.accountExpiry?.expireOn?.day}
                onHandleChange={(l) => setFieldValue(data.dayForm.Name, l)}
              />
            </Box>
            <Box width="50%" display="flex" justifyContent="right">
              <CaijMonthPicker 
                name='month'
                required
                label={data.monthForm.Label}
                value={+data.accountExpiry?.expireOn?.month}
                onHandleChange={(l) => setFieldValue(data.monthForm.Name, l)}
              />
            </Box>
          </Box>
        </Box>
      )
    } else if(accountExpiryMode === expireAfterDays){
      return (
        <Box>
          <CaijInputRank
            required
            name={data.expireInDaysForm.Name}
            label={data.expireInDaysForm.Label}
            value={String(data.accountExpiry?.expireInDays)}
            helperText={helperText}
            error={Boolean(helperText)}
            onHandleChange={(l) => {
              const value = l.target.value;
              if(value.length > data.expireInDaysForm.MaxLength){
                setHelperText(`Le champs ${data.expireInDaysForm.Label} doit contenir au maximum ${data.expireInDaysForm.MaxLength} caractères`);
                setIsExpireOnValid(false);
              }else{
                setHelperText('');
                setIsExpireOnValid(true);
              }
              setFieldValue(data.expireInDaysForm.Name, value);
            }}
            sx={{mb:3}}
            inputProps={{maxLength: data.expireInDaysForm.MaxLength, 'data-testid' : data.expireInDaysForm.Name}}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      )
    }
  }

  return (
    <>
      <CaijInput 
        required
        select
        name={data.accountExpiryModeForm.Name}
        id={data.accountExpiryModeForm.Name}
        label={data.accountExpiryModeForm.Label}
        value={data.accountExpiryMode}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{'data-testid': data.accountExpiryModeForm.Name}}
        onHandleChange={(e) => {
          if(e.target.value === neverExpire){
            setFieldValue(data.accountRenewModeForm.Name, 0);
          }
          onHandleChange(e, setFieldValue);
        }}
        setFieldValue={setFieldValue}
      >
        <option  value="">Sélectionner une expiration des comptes</option>
        {
          $enum(AccountExpiryMode).map((value, key) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))
        }
      </CaijInput>
      { renderContent(data?.accountExpiryMode) }
      {
        data.accountExpiryMode && data.accountExpiryMode !== neverExpire && (
          <Box mt={1}>
            <CaijInput
              select
              required
              name={data.accountRenewModeForm.Name}
              id={data.accountRenewModeForm.Name}
              label={data.accountRenewModeForm.Label}
              value={data?.accountRenewMode}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{'data-testid': data.accountRenewModeForm.Name}}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
              >
                <option  value="">Sélectionner un renouvellement d'un compte</option>
                {
                  $enum(AccExpRenewMode).map((value, key) => (
                    <option value={key} key={key}>{value}</option>
                  ))
                }
            </CaijInput>
          </Box>
        )
      }
      { data.accountExpiryMode && data.accountExpiryMode !== neverExpire && data?.accountRenewMode !== SubscriptionModel.getAccountRenewModeByVal(AccExpRenewMode.SelfRenewal) && (
          <Box mt={1}>
            <CaijInput
              select
              name={data.emailTemplateIdForm.Name}
              id={data.emailTemplateIdForm.Name}
              label={data.emailTemplateIdForm.Label}
              value={data?.emailTemplateId || ''}
              sx={{margin: '0.5em 0 0 0'}}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
              >
                <option value=''>Sélectionner un gabarit de courriel</option>
                { isLoading && isAuthorize && !isEmptyList && (
                    emailTemplates.map(({id, name}) => (
                      <option value={id} key={id}>{name}</option>
                    ))
                  )
                }
            </CaijInput>
          </Box>
        )
      }
      { data.accountExpiryMode && data.accountExpiryMode !== neverExpire && data?.accountRenewMode == SubscriptionModel.getAccountRenewModeByVal(AccExpRenewMode.OnApproval) && (
          <CaijInputLink
            name={data.formUrlForm.Name}
            id={data.formUrlForm.Name}
            value={data.formUrl}
            label={data.formUrlForm.Label}
            InputLabelProps={{ shrink: true}}
            inputProps={{ maxLength: data.formUrlForm.MaxLength, 'data-testid': data.formUrlForm.Name}}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        )
      }
      {
        subscription?.group === SubscriptionModel.getSbGroupByVal(SbGroup.B2B) && (
          <CaijCheckbox
            name={SendLcapNotif.Name}
            id={SendLcapNotif.Name}
            label={SendLcapNotif.Label}
            checked={subscription?.sendLcapNotif}
            value={subscription?.sendLcapNotif}
            inputProps={{'data-testid': SendLcapNotif.Name }}
            onHandleChangeCheckBox={onHandleChangeCheckBox}
            setFieldValue={setFieldValue}
          />
        )
      }
    </>
  );
};

AccountForm.propTypes = {
  subscription: PropTypes.object,
  subscriptionGroup: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func,
  setIsExpireOnValid: PropTypes.func
};

export default AccountForm;
