import type { LibraryServiceResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchLibraryServices = (
  libraryServices: LibraryServiceResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
): {
  type: 'FETCH_LIBRARY_SERVICES';
  payload: { libraryServices: LibraryServiceResource[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean };
} => ({
  type: actionTypes.FETCH_LIBRARY_SERVICES,
  payload: {
    libraryServices,
    isLoading,
    isEmptyList,
    isAuthorize,
  },
});

export const fetchLibraryService = (libraryService: LibraryServiceResource, isLoading?: boolean): {
  type: 'FETCH_LIBRARY_SERVICE';
  payload: { 
    libraryService: LibraryServiceResource,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_LIBRARY_SERVICE,
  payload: {
    libraryService,
    isLoading
  },
});

export const fetchDetailsLibraryService = (
  libraryService: LibraryServiceResource,
): {
  type: 'FETCH_DETAILS_LIBRARY_SERVICE';
  payload: {
    libraryService: LibraryServiceResource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_LIBRARY_SERVICE,
  payload: {
    libraryService,
  },
});

export const deleteLibraryService = (
  id: number,
): {
  type: 'DELETE_LIBRARY_SERVICE';
  payload: {
    id: number;
  };
} => ({
  type: actionTypes.DELETE_LIBRARY_SERVICE,
  payload: {
    id,
  },
});
