import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead
} from '@mui/material';
import {
  EmptyList,
  SelOpt,
  ListType,
  getEmptyListSearch,
  TranslationModel,
  useNavigate,
  useLocation,
  NavigateFunction,
} from 'src/common';
import { Paged } from 'src/types/pagination';
import { 
  SearchFilter,
  TranslationResource, 
  TranslationResourceForEdit 
} from 'src/common/types';
import printMessage from 'src/views/errors/MessageError';
import TranslationForm from 'src/components/translation/Forms';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';

interface Submit{
  id : number;
  value: string; 
  selOpt: SelOpt;
};

export interface ResultsProps {
  translations: TranslationResource[];
  paged: Paged;
  isEmptyList: boolean;
  model?: TranslationModel;
  onSubmit?: (value: Submit) => void;
  onDeleteTraduction: (id: number) => Promise<void>;
  navigate: NavigateFunction;
  location: {search: string}
}

const Results: FC<ResultsProps> = ({
  translations,
  paged,
  isEmptyList,
  model,
  onSubmit,
  onDeleteTraduction,
  navigate
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const [disableButton, setDisableButton] = useState<number[]>([]);
  const [fr,setFr] = useState<Map<number, string>>(new Map());
  const [en,setEn] = useState<Map<number, string>>(new Map());
  const currentPage = paged.currentPage - 1;
  const query = new URLSearchParams(location?.search).get("query");
  const [filter, setFilter] = useState<SearchFilter>({
    query,
    doSearch: false,
    page: currentPage
  });
  const { Code, Fr, En } = model;

  useEffect(() => {
    const { Filters: { query, page } } = model;
    const queryVal = model.getQueryParam(query);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        doSearch: queryVal ? true : false,
        page: +model.getQueryParam(page)
      }
    });
    disableButton.length = 0;
  },[model]);

  const save = async (id : number, value: string, selOpt: SelOpt) => {
    if(onSubmit){
      onSubmit({id,value,selOpt});
      return;
    }
    const model = new TranslationModel();
    const translation = await model.getTranslationById(id);
    if(!model.error){
      const submitData = translation as TranslationResourceForEdit;
      if(selOpt === SelOpt.fr){
        submitData.fr = value;
        setFr((prev: Map<number, string>) => new Map(prev.set(id, value)));
      }else if(selOpt === SelOpt.en){
        submitData.en = value;
        setEn((prev: Map<number, string>) => new Map(prev.set(id, value)));
      }
      const response = await model.updateTranslation(submitData);
      if(!model.error) {
          printMessage(response);
        }
    }
  };

  const handleBlur = (id: number, value: string, selOpt: SelOpt) => {
    const currentIndex = disableButton.indexOf(id);
    if(value) {
      if(currentIndex === -1) {
        setDisableButton([...disableButton, id]);
      }
      if(selOpt === SelOpt.fr){
        setFr((prev: Map<number, string>) => new Map(prev.set(id, value)));
        if(!en.has(id)){
          const index = translations.findIndex((translation: TranslationResource) => translation.id === id);
          setEn((prev: Map<number, string>) => new Map(prev.set(id, translations[index].en)));
        }
      }else if(selOpt === SelOpt.en){
        setEn((prev: Map<number, string>) => new Map(prev.set(id, value)));
        if(!fr.has(id)){
          const index = translations.findIndex((translation: TranslationResource) => translation.id === id);
          setFr((prev: Map<number, string>) => new Map(prev.set(id, translations[index].fr)));
        }
      }
    }
  };

  const renderContent = (): JSX.Element => {
    if (isEmptyList && !filter.doSearch) {
      return <EmptyList />;
    }
    return (
      <MyCard>
        <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between' flexGrow={1}>
          <Search 
            listType={ListType.Traduction}
            placeholder='Recherche la/les traduction(s)'
            filter={filter}
            width="400px"
            path={path.Home}
          />
        </Box>
        <CaijTable>
          <TableHead>
            <TableRow>
              <TableCell width='5%'>{Code.Label}</TableCell>
              <TableCell>{Fr.Label}</TableCell>
              <TableCell>{En.Label}</TableCell>
              <TableCell width='5%'/>
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              translations.length === 0 ? getEmptyListSearch(4) :
              translations.map((row: TranslationResource) => {
                return (
                  <TableRow hover key={row.id}>
                    <TableCell>
                      <Typography variant='body2' sx={{color:'text.secondary'}}>
                        {row.code}
                      </Typography>
                    </TableCell>
                    <TableCell >
                      <TranslationForm
                        id={row.id}
                        rowVal={row.fr}
                        label={Fr.Label}
                        text={SelOpt.fr}
                        maxLength={model.Fr.MaxLength}
                        isSubmitting={disableButton.length === 0}
                        onSave={(id, value) => save(id, value, SelOpt.fr)}
                        onHandleBlur={(l, v) => handleBlur(l, v, SelOpt.fr)}
                      />
                    </TableCell>
                    <TableCell>
                      <TranslationForm
                        id={row.id}
                        rowVal={row.en}
                        label={En.Label}
                        text={SelOpt.en}
                        maxLength={model.En.MaxLength}
                        isSubmitting={disableButton.length === 0}
                        onSave={(id, value) => save(id, value, SelOpt.en)}
                        onHandleBlur={(l, v) => handleBlur(l, v, SelOpt.en)}
                      />
                    </TableCell>
                    <CaijTableCellAction 
                      resourceCode={model.ResourceCode}
                      handleToggle={() => TranslationModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                    >
                      <CaijDialogs
                        dialog={model.Dialog}
                        isOpen={selectedRow.indexOf(row.id) !== -1}
                        onSubmit={async () => {
                          await onDeleteTraduction(row.id);
                          setSelectedRow([]);
                        }}
                        setSelectedRow={setSelectedRow}
                      />
                    </CaijTableCellAction>
                  </TableRow>
                );
              })
            }
          </TableBody>
        </CaijTable>
        <CaijTablePagination 
          paged={paged} 
          model={model} 
          filter={filter} 
          page={currentPage}
          navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
        />
      </MyCard>
    );
  };

  return (<>{ renderContent() }</>);
};

Results.propTypes = {
  translations: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onDeleteTraduction: PropTypes.func.isRequired,
  model: PropTypes.instanceOf(TranslationModel)
};

Results.defaultProps = {
  onSubmit: null
};

export default Results;
