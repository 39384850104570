import React, { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress
} from '@mui/material';

const root = {
  position: 'absolute',
  alignItems: 'center',
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: 'grey' ,
  opacity: 0.75,
  zIndex: 20
};

const LoadingOverlay: FC = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box sx={root}>
      <div>
        <LinearProgress />
      </div>
    </Box>
  );
};

export default LoadingOverlay;
