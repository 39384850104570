import React, { useState, FC, ChangeEvent, useEffect, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
  Divider,
  Table,
  TableBody,
  TextField,
  TableRow,
  TableCell,
  SvgIcon
} from '@mui/material';
import { ArrowRight as RightIcon } from 'react-feather';
import Label from 'src/components/Label';
import { 
  CustomerCardTransactionsResource, 
  CustomerResource, 
  Print, 
  Sort, 
  Transaction 
} from 'src/common/types';
import { 
  CardRequestPendingModel,
  CustomerModel,
  EmptyList, 
  formatDate, 
  formatDateHours, 
  ITEMS_PER_PAGE, 
  PaginationModel, 
  getAmount
} from 'src/common';
import CaijCardInfo from './Details/CaijCardInfo';
import TransactionDetails from './Details/TransactionDetails';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MyCard from 'src/components/card/MyCard';
import { btn, cardTransactionBox, date, frame, hour } from '../../../../styles/customer';
import LoadingScreenCard from 'src/components/loading/LoadingScreenCard';
import EditButton from 'src/components/customer/customer/Details/EditButton';
import { Align, CaijTableHeader } from 'src/components/table/CaijTableHeader';
import dayjs from 'dayjs';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';

const tabs = [
  { value: 'transactions', label: 'Transactions' },
  { value: 'impressions', label: 'Impressions' },
];
  
const dateSortOptions: { value: 'desc' | 'asc'; label: string }[] = [
  {
    value: 'desc',
    label: 'La plus récente',
  },
  {
    value: 'asc',
    label: 'La plus ancienne',
  },
];

const statusSortOptions: { value: 'Imprimé' | 'NotPrint'; label: string }[] = [
  {
    value: 'Imprimé',
    label: 'Imprimé',
  },
  {
    value: 'NotPrint',
    label: 'Pas imprimé',
  },
];

const headerTransaction = [
  { width: "10%", name: "Date"},
  { width: "40%", name: "Description"},
  { width: "20%", name: "Opération"},
  { width: "10%", name: "Montant", align: "right" as Align},
  { width: "15%", name: "Effectuée par"},
  { width: "5%", name: "Actions", align: "center" as Align}
];

const headerPrint = [
  { width: "10%", name: "Date"},
  { width: "60%", name: "Description"},
  { width: "20%", name: "Statut"},
  { width: "10%", name: "Montant", align: "right" as Align}
];

interface CustCardTransactions {
  data: CustomerCardTransactionsResource;
  isLoading: boolean
}

interface TransactionsInfoProps {
  onTransactionMode: () => void;
  customer?: CustomerResource;
  customerCardTransaction?: CustCardTransactions;
  modifyCaijCard: (action: string) => void;
  onHandleRefreshCustomer: () => Promise<void>;
  onHandleSortChangeTransaction: (operation: string, sort: Sort) => void;
  onHandleSortChangePrint:  (status: string, sort: Sort) => void;
  cardRequestPendingModel: CardRequestPendingModel;
  model: CustomerModel;
}

type IButtonTransaction = {
  model: CustomerModel;
  customer: CustomerResource;
  cardRequestPendingModel: CardRequestPendingModel;
  onTransactionMode: () => void;
  children: ReactNode;
};

function ButtonTransaction({model, customer, cardRequestPendingModel, onTransactionMode, children}: IButtonTransaction){
  return (
    <>
      <Box sx={{marginLeft: '15px'}}>
        <EditButton 
          model={model} 
          customer={customer} 
          tab='card'
          cardRequestPendingModel={cardRequestPendingModel} 
          onTransactionMode={onTransactionMode}
        />
      </Box>
      {children}
    </>
  );
};

const Amount = (props: {value: number}) => <TableCell align="right">{getAmount(props.value, true)}$</TableCell>;

const TransactionsInfo: FC<TransactionsInfoProps> = ({ 
  customer, 
  customerCardTransaction,
  modifyCaijCard, 
  onTransactionMode, 
  onHandleRefreshCustomer,
  onHandleSortChangeTransaction,
  onHandleSortChangePrint,
  cardRequestPendingModel,
  model
}) => {
  const [currentTab, setCurrentTab] = useState<string>('transactions');
  const [sort, setSort] = useState<Sort>('desc');
  const [operation, setOperation] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [transactionDetailsMode, setTransactionDetailsMode] = useState<boolean>(false);
  const [transactionToView, setTransactionToView] = useState(null);
  const [printToView, setPrintToView] = useState(null);
  const [page, setPage] = useState<number>(0);
  const pagination = new PaginationModel();
  const [state, setState] = useState(new Set());

  useEffect(() => {
    setTransactionDetailsMode(false);
    if(customerCardTransaction.isLoading){
    customerCardTransaction.data.transactions.map((transaction: Transaction) => {
      const { operation } = transaction;
      if(operation){
        setState((prev) => new Set(prev).add(transaction.operation));
      }
    })
  }
  }, [setState, customerCardTransaction, setTransactionDetailsMode]);

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
    setStatus('');
    setSort('desc');
    setPage(0);
  };

  const colorRender = (status: string) => {
    if (status === 'Imprimé') {
      return 'success';
    }
    if (status === '') {
      return 'warning';
    }
    if (status === 'Cancelé') {
      return 'error';
    }
    return 'error';
  };

  const handleSwitchToTransactionDetails = (transaction?: Transaction | null, print?: Print | null): void => {
    if (transaction) {
      setTransactionToView(transaction);
      setPrintToView(null);
      setTransactionDetailsMode(true);
    }
    if (print) {
      setTransactionToView(null);
      setPrintToView(print);
      setTransactionDetailsMode(true);
    }
    if (!transaction && !print) {
      setTransactionDetailsMode(false);
    }
  };

  const handleDateSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    const sort = event.target.value as Sort;
    if(currentTab === 'transactions'){
      onHandleSortChangeTransaction(operation, sort);
    }else if(currentTab === 'impressions'){
      onHandleSortChangePrint(status, sort);
    }
    setSort(sort);
  };

  const handleOperationSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    const operation = event.target.value;
    onHandleSortChangeTransaction(operation, sort);
    setOperation(operation);
  };

  const handleStatusSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    const status = event.target.value;
    onHandleSortChangePrint(status, sort);
    setStatus(status);
  };
  
  const cardTransactionFilter = () => {
    return (
      <Box sx={cardTransactionBox}>
        { currentTab === 'transactions' ? (
            <Box ml={2}>
              <EditButton 
                model={model} 
                customer={customer} 
                tab='card'
                cardRequestPendingModel={cardRequestPendingModel} 
                onTransactionMode={onTransactionMode}
              />
            </Box>
          ) : (<Box />)
        }
        <Box display="flex">
          <Box mr={2}>
            <TextField
              className='dateFilter'
              label='Date'
              name='sort date'
              onChange={handleDateSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant='outlined'
              InputLabelProps={{ shrink: true }}
            >
              {dateSortOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box>
            <TextField
              className={currentTab === 'transactions' ? 'operationFilter' : 'statusFilter'}
              label={currentTab === 'transactions' ? 'Opération' : 'Statut'}
              name={currentTab === 'transactions' ? 'sort operation' : 'status operation'}
              onChange={currentTab === 'transactions' ? handleOperationSortChange : handleStatusSortChange}
              select
              SelectProps={{ native: true }}
              value={currentTab === 'transactions' ? operation : status}
              variant='outlined'
              InputLabelProps={{ shrink: true }}
            >
              <option value=''>Tous</option>
              {currentTab === 'transactions'
                ? Array.from(state).map((value: string) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))
                : statusSortOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
            </TextField>
          </Box>
        </Box>
      </Box>
    );
  }

  const cardTransactionsRender = (transactions: Transaction[]): JSX.Element[] | JSX.Element => {
    if(Array.isArray(transactions) && transactions.length > 0){
      return  pagination.stableSort(transactions, () => {})
                        .slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE)
                        .map((transaction: Transaction) => {
                          const { created } = transaction;
                          return (
                            <TableRow key={transaction.id}>
                              <TableCell>
                                <Typography sx={date}>{formatDate(created)}</Typography>
                                <Typography sx={hour}>{formatDateHours(dayjs(created).toDate())}</Typography>
                              </TableCell>
                              <TableCell>{transaction.label}</TableCell>
                              <TableCell>{transaction.operation}</TableCell>
                              <Amount value={transaction.amount} />
                              <TableCell>{transaction.createdBy}</TableCell>
                              <TableCell>
                                <Button
                                  sx={btn}
                                  disableElevation
                                  color='secondary'
                                  variant='contained'
                                  onClick={() => handleSwitchToTransactionDetails(transaction, null)}
                                  startIcon={
                                    <SvgIcon fontSize='small'>
                                      <RightIcon style={{marginRight: '-0.5em'}} />
                                    </SvgIcon>
                                  }
                                >
                                  Voir
                                </Button>
                              </TableCell>
                            </TableRow>
                        )});
      }
  };

  const printsRender = (prints: Print[]): JSX.Element[] | JSX.Element => {
    if(Array.isArray(prints) && prints.length > 0){
      return pagination.stableSort(prints, () => {})
                       .slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE)
                       .map((print: Print) => {
                         const { created } = print;
                         return (
                          <TableRow key={print.id}>
                            <TableCell>
                              <Typography sx={date}>{formatDate(created)}</Typography>
                              <Typography sx={hour}>{formatDateHours(dayjs(created).toDate())}</Typography>
                            </TableCell>
                            <TableCell>{print.label}</TableCell>
                            <TableCell>
                              <Label color={colorRender(print.status)}>{print.status ? print.status : 'Aucun statut'}</Label>
                            </TableCell>
                            <Amount value={print.cost} />
                          </TableRow>
                        )});
      }
  };

  const renderContent = (): JSX.Element[] | JSX.Element => {
    const { data : { transactions, prints }, isLoading } = customerCardTransaction;
    switch (currentTab) {
      case 'transactions':
        if(!isLoading){
          return (
            <ButtonTransaction  
              model={model}
              customer={customer}
              cardRequestPendingModel={cardRequestPendingModel}
              onTransactionMode={onTransactionMode}
            >
              <LoadingScreenCard />
            </ButtonTransaction>
          )
        }else if(isLoading && Array.isArray(transactions) && transactions.length === 0){
          return (
            <ButtonTransaction  
              model={model}
              customer={customer}
              cardRequestPendingModel={cardRequestPendingModel}
              onTransactionMode={onTransactionMode}
            >
              <EmptyList />
            </ButtonTransaction>
          )
        }else{
          return (
            <>
              {cardTransactionFilter()}
              <PerfectScrollbar>
                <Box sx={frame}>
                  <Table>
                    <CaijTableHeader data={headerTransaction} />
                    <TableBody>
                      {cardTransactionsRender(transactions)}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <CaijTablePagination
                showTablePaginationLight 
                len={transactions.length}
                page={page}
                setPage={(l) => setPage(l)}
              />
            </>
          );
        }
      case 'impressions':
        if(!isLoading){
          return <LoadingScreenCard />
        }else if(isLoading && Array.isArray(prints) && prints.length === 0){
          return <EmptyList />
        }else{
          return (
            <>
              {cardTransactionFilter()}
              <PerfectScrollbar>
                <Box sx={frame}>
                  <Box sx={cardTransactionBox}></Box>
                  <Table>
                    <CaijTableHeader data={headerPrint} />
                    <TableBody>
                      {printsRender(prints)}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
              <CaijTablePagination
                showTablePaginationLight 
                len={prints.length}
                page={page}
                setPage={(l) => setPage(l)}
              />
            </>
          );
        }
    }
  };

  const transactionDetailsRender = (): JSX.Element[] | JSX.Element => {
    if (!transactionDetailsMode)
      return (
        <MyCard>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='fullWidth' textColor='secondary'>
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
          <Box mt={3}>{renderContent()}</Box>
        </MyCard>
      );
    if (transactionDetailsMode)
      return <TransactionDetails switchMode={handleSwitchToTransactionDetails} transaction={transactionToView} print={printToView} />;
  };

  return (
    <Grid spacing={3} container>
      <Grid xs={3} item>
        <CaijCardInfo
          modifyCaijCard={modifyCaijCard}
          onHandleRefreshCustomer={onHandleRefreshCustomer}
          customer={customer}
          onTransactionMode={onTransactionMode}
        />
      </Grid>
      <Grid xs={9} item>
        {transactionDetailsRender()}
      </Grid>
    </Grid>
  );
};

TransactionsInfo.propTypes = {
  onTransactionMode: PropTypes.func,
  customer: PropTypes.object,
  customerCardTransaction: PropTypes.exact({
    data: PropTypes.exact({prints: PropTypes.array, transactions: PropTypes.array}), 
    isLoading: PropTypes.bool
  }),
  modifyCaijCard: PropTypes.func,
  onHandleRefreshCustomer: PropTypes.func,
  onHandleSortChangeTransaction: PropTypes.func.isRequired,
  onHandleSortChangePrint: PropTypes.func.isRequired
};

TransactionsInfo.defaultProps = {
  customer: {},
};

export default TransactionsInfo;

