import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  MSG_UNEXPECTED_TOKEN_JSON,
  UnExpectTokenJson,
  Created,
  formatDateTime,
} from 'src/common';
import type { 
  Error, 
  MessagePlaceholderResource, 
  MessagePlaceholderResourceForEdit, 
  MessageResource, 
  MessageResourceForCreate, 
  MessageResourceForEdit 
} from 'src/common/types';
import { CreateResponse } from 'src/types/createResponse';
import AppModel from './App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from './Form';

type TypeResourceEdit = MessageResourceForCreate | MessageResourceForEdit;

export type Color = "primary" | "secondary" | "error" | "warning" | "success" | "warning2";

class ActiveCheckMessages extends Map<number, MessageResource[]>
{
  setActiveCheckMessages(key: number, message: MessageResource){
    if(this.keyExist(key)){
      this.set(key, [...this.get(key), message]);
    }else{
      this.set(key,[message]);
    }
  }
  keyExist(key:number){
    if(this && this.has(key)){
      return true;
    }
    return false;
  }
  sortKeys(){
    return Array.from(this.keys()).sort();
  }
  getSortedActiveCheckMessages(){
    const keySorted = this.sortKeys();
    keySorted.forEach((key)=>{
      const value = this.get(key);
      this.delete(key);
      this.set(key,value);
    })
    return this;
  }
}

export default class MessageModel extends AppModel 
{
  private static _instance: MessageModel;
  private static _startDate : string;
  private static _endDate : string;
  private static _inProgress = 'En cours';
  private static _upComing = 'À venir';
  private static _archives = 'Archives';
  private _activeCheckMessages: ActiveCheckMessages = new ActiveCheckMessages();
  
  readonly Name = new FormModel('name','Nom',150);
  readonly Rank = new FormModel('rank','Rang',9);
  readonly TitleFr = new FormModel('titleFr','Titre (Français)',300);
  readonly TitleEn = new FormModel('titleEn','Titre (English)',300);
  readonly MessageFr = new FormModel('messageFr','Message (Français)');
  readonly MessageEn = new FormModel('messageEn','Message (English)');
  readonly Code = new FormModel('code','Code',45);

  constructor(){
    super('/message');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'MESSAGE';
    this._headerTitle = 'Liste des messages';
    this._btnAddText = 'Créer un nouveau message';
    this._breadCrumbsEditText = 'Éditer un message';
    this.Path.PathName = '/placeholders/messages';

    //Dialog
    this.Dialog.Header = 'Supprimer le message';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le message';
  }

  static getInstance(): MessageModel {
    if (!MessageModel._instance) {
      MessageModel._instance = new MessageModel();
    }
    return MessageModel._instance;
  }

  static addDays(date: Date, days: number) : Date {
    return new Date(date.setDate(date.getDate() + days));
  }

  static getCreateInitialValues(values: MessageResource, holder: MessagePlaceholderResource) : MessageResource {
    return {
      name: values?.name,
      visible: false,
      rank: values?.rank ?? 0,
      startDate: values?.startDate ?? formatDateTime(new Date()),
      endDate: values?.endDate ?? formatDateTime(this.addDays(new Date(), 1)),
      closable: true,
      placeholderId: holder.id,
      titleFr: values?.titleFr,
      titleEn: values?.titleEn,
      messageFr: values?.messageFr,
      messageEn: values?.messageEn,
      placeholder: holder,
    }
  }

  static set StartDate(startDate : string) {
    this._startDate = formatDateTime(new Date(startDate));
  }

  static get StartDate() : string {
    return this._startDate;
  }

  static set EndDate(endDate : string) {
    this._endDate = formatDateTime(new Date(endDate));
  }

  static get EndDate() : string {
    return this._endDate;
  }

  getActiveCheckMessage() : ActiveCheckMessages {
    return this._activeCheckMessages.getSortedActiveCheckMessages();
  }

  getHeadCells(status: string){
    return [
      {
        id: 'startDate', width: '10%', numeric: false, disablePadding: false, label: 'Date de début'
      },
      {
        id: 'endDate', width: '10%', numeric: false, disablePadding: false, label: 'Date de fin'
      },
      {
        id: 'name', width: '50%', numeric: false, disablePadding: false, label: this.Name.Label
      },
      {
        id: '', width: '10%', numeric: false, disablePadding: false, label: status
      },
      {
        id: 'rank', width: '5%', numeric: false, disablePadding: false, label: this.Rank.Label
      },
      {
        width: '5%', align:'right' as 'right'
      }
    ];
  }

  async getMessages(): Promise<MessageResource[]> {
    let messages: MessageResource[];
    await axios.get<MessageResource[]>(`${this.route}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            messages = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (error) {
          if (status === NoContent) {
            this.error = { status, message: error.message };
          } else {
            this.error = { status: UnExpectTokenJson, message: MSG_UNEXPECTED_TOKEN_JSON };
          }
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return messages;
  }

  async getMessageById(id: number): Promise<MessageResource> {
    let message: MessageResource;
    await axios.get<MessageResource>(`${this.route}/${id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            message = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(error);
      },
    );
    return message;
  }

  async insertMessage(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<MessageResourceForCreate, CreateResponse>(this.route, submitData).then(
      async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Message créé.';
        } 
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async updateMessage(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<MessagePlaceholderResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Message modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }
  async removeMessage(id: number): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Message supprimé.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  static activeCheck(message: MessageResource)  {
    this.StartDate = message.startDate;
    this.EndDate = message.endDate;
    if (message.visible && this.StartDate <= this.TodayTime && this.EndDate >= this.TodayTime) {
      return this._inProgress;
    }else if (message.visible && this.StartDate > this.TodayTime && this.EndDate > this.TodayTime) {
      return this._upComing;
    }else{
      return this._archives;
    }
  };

  static colorRender(message: MessageResource) : Color {
    if (this.activeCheck(message) === this._inProgress) {
      return 'success';
    }
    if (this.activeCheck(message) === this._upComing) {
      return 'warning';
    }
    if (this.activeCheck(message) === this._archives) {
      return 'error';
    }
  };

  setActiveCheckMessage(messages: MessageResource[]){
    for(var message of messages){
      MessageModel.StartDate = message.startDate;
      MessageModel.EndDate = message.endDate;
      if(message.visible && MessageModel.StartDate <= MessageModel.TodayTime && MessageModel.EndDate >= MessageModel.TodayTime){
        this._activeCheckMessages.setActiveCheckMessages(1, message);
      }else if(message.visible && MessageModel.StartDate > MessageModel.TodayTime && MessageModel.EndDate > MessageModel.TodayTime){
        this._activeCheckMessages.setActiveCheckMessages(2, message);
      }else{
        this._activeCheckMessages.setActiveCheckMessages(3, message);
      }
    }
  }
}
