import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  SvgIcon,
  Typography
} from '@mui/material';
import type { 
  DatabankResource,
  DocumentCollectionResource,
  ProductResource,
  SubscriptionResource, 
  SubscriptionResourceForEdit 
} from 'src/common/types';
import {
  Formik,
  CaijButtonReset,
  CaijButtonSubmit,
  SubscriptionModel,
  Authorize,
  Mode,
  Label,
  DatabankModel,
  DocCollectionModel,
  ProductModel,
  dialog,
  dialogTitle,
  btnSubmit
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import SubscriptionAccess from 'src/components/access/SubscriptionAccess';
import { XSquare as CancelIcon, CheckCircle as OkIcon } from 'react-feather';
import { useAccesses } from 'src/functions/hooks/access';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';
import useDoorAccessProfile from 'src/hooks/useDoorAccessProfile';
import useUxpertise from 'src/hooks/useUxpertise';

interface AccessFormProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  onHandleRefreshSubscription: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

interface Access {
  ids: number[], 
  selected: boolean 
};

type Options = true | false;
type Data = ProductResource[] | DatabankResource[] | DocumentCollectionResource[];

const getSelectedParameters = (selectedParameters: Map<number,string>, id: number) => {
  if(selectedParameters?.has(id)){
    if(selectedParameters?.get(id) !== 'null')
      return ` (${selectedParameters?.get(id)})`;
  }
  return '';
}

const getEmptyAccess = (option: Options) : JSX.Element => (
  <Box display='flex' alignItems="flex-start" justifyContent="flex-start">
    <Box mr={option ? 6 : 5}><Label color={option ? "success" : "error"}>{option ? 'AJOUT' : 'RETRAIT'}</Label></Box>
    <Box>Aucun</Box>
  </Box>
);

const getAccessLists = (data: Data, option: Options, selectedParameters: Map<number,string> = null) : JSX.Element => (
  <Box display='flex' alignItems="flex-start" justifyContent="flex-start">
    <Box mr={option ? 6 : 5}><Label color={option ? "success" : "error"}>{option ? 'AJOUT': 'RETRAIT'}</Label></Box>
    <Box sx={{flexWrap: 'wrap'}}>
      {data.map(({id,nameFr}) => `${nameFr}${getSelectedParameters(selectedParameters, id)}`).join(', ')}
    </Box>
  </Box>
);

const verifyProductAccess = (source: ProductResource[], dest: Access, products: ProductResource[], selectedParameters: Map<number,string>, option: Options) :JSX.Element => {
  const model = new ProductModel();
  let productsServices : ProductResource[] = [];
  if(option){
    productsServices = model.verifyAddedAccess(source, dest, products);
  }else{
    productsServices = model.verifyDeletedAccess(source, dest, products);
  }
  if(!productsServices){
    return getEmptyAccess(option);
  }
  return getAccessLists(productsServices,option, selectedParameters);
}

const verifyDatabankAccess = (source: DatabankResource[], dest: Access, databanks: DatabankResource[], option: Options) : JSX.Element => {
  const model = new DatabankModel();
  let dbs: DatabankResource[] = [];
  if(option){
    dbs = model.verifyAddedAccess(source, dest, databanks);
  }else{
    dbs = model.verifyDeletedAccess(source, dest, databanks);
  }
  if(!dbs){
    return getEmptyAccess(option);
  }
  return getAccessLists(dbs,option);
}

const verifyDocCollectionAccess = (source: DocumentCollectionResource[], dest: Access, docCollections: DocumentCollectionResource[], option: Options) : JSX.Element => {
  const model = new DocCollectionModel();
  let collections: DocumentCollectionResource[] = [];
  if(option){
    collections = model.verifyAddedAccess(source, dest, docCollections);
  }else{
    collections = model.verifyDeletedAccess(source, dest, docCollections);
  }
  if(!collections){
    return getEmptyAccess(option);
  }
  return getAccessLists(collections,option);
}

const AccessForm: FC<AccessFormProps> = ({
  model,
  subscription,
  onHandleRefreshSubscription,
  onSwitchMode
}) => {
  const [open, setOpen] = useState(false);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selProducts, setSelProducts] = useState<Access>({ids: [],selected: false});
  const [selDatabanks, setSelDatabanks] = useState<Access>({ids: [],selected: false});
  const [selDocCollections, setSelDocCollections] = useState<Access>({ids: [],selected: false});
  const [selectedParameters, setSelectedParameters] = useState<Map<number, string>>();
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(false);
  const { sdatabank, scollection, sproduct } = useAccesses();
  const { handleChange, value } = useDoorAccessProfile();
  const { value: uxpertiseValue, handleChange: handleChangeUxpertise } = useUxpertise();
  
  useEffect(() => {
    handleChange(String(subscription.doorCardProfile));
    handleChangeUxpertise(String(subscription.uxpertiseGroup));
  }, [])
  
  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleClose = (isClosed: boolean) => {
    setOpen(isClosed);
  };

  const dialogRender = (values: SubscriptionResource, selProducts: Access,selDatabanks:Access, selDocCollections:Access) => {
    return (
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          setOpen(false);
          setDisabledSubmitBtn(true);
          const model = new SubscriptionModel();
          let submitData = subscription as SubscriptionResourceForEdit;
          submitData.doorCardProfile = +value;
          submitData.uxpertiseGroup = +uxpertiseValue;
          if (submitData && submitData.id) {
            submitData.note = values.note;
            model.SelectedProductParameters = selectedParameters;
            model.getSubmitListData(
              submitData,
              subscription,
              selProducts, 
              selDatabanks, 
              selDocCollections
            );
            const response = await model.updateSubscription(submitData);
            if (!model.error) {
              printMessage({
                status: response.status,
                message: response.message
              });
              await onHandleRefreshSubscription();
            }else{
              setDisabledSubmitBtn(false);
            }
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle sx={dialogTitle}>{subscription.name.toUpperCase()}</DialogTitle>
            <DialogContent dividers sx={{...dialog, padding: '1em', width: 'auto'}}>
              <Typography variant="body2" sx={{ margin: '0 0 2em 0'}}>Êtes-vous certain de vouloir apporter ces modifications d'accès?</Typography>
              <Typography variant="h6" sx={{ margin: '0 0 1em 0'}}>Produit et service à distance</Typography>
                <Box mb={2}>
                  { verifyProductAccess(subscription.products, selProducts, sproduct.products, selectedParameters,true)}
                </Box>
                <Box mb={3}>
                 { verifyProductAccess(subscription.products, selProducts, sproduct.products, selectedParameters, false)}
                </Box>
              <Typography variant="h6" sx={{ margin: '0 0 1em 0'}}>Base de donnée à distance</Typography>
                <Box mb={2}>
                  { verifyDatabankAccess(subscription.databanks, selDatabanks, sdatabank.databanks, true)}
                </Box>
                <Box mb={3}>
                 { verifyDatabankAccess(subscription.databanks, selDatabanks, sdatabank.databanks, false)}
                </Box>
              <Typography variant="h6" sx={{ margin: '0 0 1em 0'}}>Contenu à distance</Typography>
                <Box mb={2}>
                  { verifyDocCollectionAccess(subscription.documentCollections, selDocCollections, scollection.docCollections, true) }
                </Box>
                <Box mb={3}>
                  { verifyDocCollectionAccess(subscription.documentCollections, selDocCollections, scollection.docCollections, false) }
                </Box>
              <Box mt={3}>
                <Authorize 
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={l => setIsAuth(l)}
                >
                  <Button 
                    type="submit" 
                    variant="outlined" 
                    disabled={!isAuth || isSubmitting} 
                    sx={{marginRight: 1}}
                    startIcon={(
                    <SvgIcon fontSize="small">
                      <OkIcon />
                    </SvgIcon>
                    )}
                  >OK</Button>
                </Authorize>
                <Button  
                  variant="outlined"
                  type="reset"
                  onClick={() => handleClose(false)}
                  disabled={isSubmitting}
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <CancelIcon />
                    </SvgIcon>
                  )}>Annuler</Button>
              </Box>
            </DialogContent>
          </form>
        )}
      </Formik>
    );
  };
  
  return (
    <Formik
      initialValues={SubscriptionModel.getInitialValues(subscription)}
      onSubmit={() => {
        setOpen(true);
      }}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Root>
                  <SubscriptionAccess 
                    subscription={subscription}
                    setSelectedProducts={setSelProducts}
                    selectedProducts={selProducts}
                    setSelectedDatabanks={setSelDatabanks}
                    selectedDatabanks={selDatabanks}
                    setSelectedDocCollections={setSelDocCollections}
                    selectedDocCollections={selDocCollections}
                    setSelectedParameters={setSelectedParameters}
                  />
                </Root>
              </Box>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={Mode.edit}
                onIsAuth={(l) => setIsAuth(l)}
              >
                <CaijButtonSubmit disabled={!isAuth || disabledSubmitBtn} sx={btnSubmit} />
              </Authorize>
              <CaijButtonReset disabled={disabledSubmitBtn} onSwitchMode={switchMode} />
            </CardContent>
          </MyCard>
          <Box>
            <Dialog maxWidth='md' fullWidth open={open} >
              {dialogRender(values, selProducts, selDatabanks, selDocCollections)}
            </Dialog>
          </Box>
        </form>
      )}
    </Formik>
  );
};

AccessForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  onHandleRefreshSubscription: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired
};

export default AccessForm;
