import { Dayjs } from 'dayjs';
import type { ChangeEvent } from 'react';

const handleChange = (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void): void => {
  const { value, name } = event.target;
  setFieldValue(name, value);
};

const handleChangeCheckBox = (
  event: ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  const { name, checked } = event.target;
  setFieldValue(name, checked);
};

const handleChangeSwitch = (
  event: ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  const { name, checked } = event.target;
  setFieldValue(name, checked);
};

const handleChangeSwitchEnum = (
  event: ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  const { name, value } = event.target;
  setFieldValue(name, value);
};

const handleChangeDateChange = (name: string,date: string | Dayjs | Date,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  setFieldValue(name, date);
};

const handleChangeExpiredDayChange = (name: string,date: Dayjs,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  setFieldValue(name, +date.format('DD'));
};

const handleChangeExpiredMonthChange = (name: string,date: Dayjs,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  setFieldValue(name, +date.format('MM'));
};

const handleChangeImageUrl = (
  name: string,
  imageUrl: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
): void => {
  setFieldValue(name, imageUrl);
};

export { 
  handleChange, 
  handleChangeCheckBox,
  handleChangeSwitch, 
  handleChangeSwitchEnum, 
  handleChangeDateChange,
  handleChangeExpiredDayChange,
  handleChangeExpiredMonthChange,
  handleChangeImageUrl 
};
