import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  EmployeesResource,
  Error,
  CreateResponse,
  EmployeeResourceForCreate,
  EmployeeResourceForEdit,
  EmployeeAccessDto,
  Resource
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import ResourceModel from './Resource';
import { Permissions } from 'src/constants';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceEdit = EmployeeResourceForCreate | EmployeeResourceForEdit;

export default class EmployeeModel extends AppModel 
{
  private static _instance: EmployeeModel;
  readonly Firstname = new FormModel('firstname','Prénom',100);
  readonly Lastname = new FormModel('lastname','Nom de famille',100);
  readonly Card = new FormModel('card','Carte',20);
  readonly Titre = new FormModel('title','Titre',100);
  readonly Email = new FormModel('email','Courriel',320);

  constructor(){
    super('/employee');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'EMPLOYEE';
    this._headerTitle = 'Liste des utilisateurs';
    this._btnAddText = 'Ajouter un utilisateur';
    this.Path.PathName = '/employee';

    //Dialog
    this.Dialog.Header = "Supprimer l'utilisateur";
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = "l'utilisateur";
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Utilisateurs',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(status: string){
    return [
      {
        id: 'firstname', width: '23%', numeric: false, disablePadding: false, label: this.Firstname.Label
      },
      {
        id: 'lastname', width: '23%', numeric: false, disablePadding: false, label: this.Lastname.Label
      },
      {
        id: 'email', width: '23%', numeric: false, disablePadding: false, label: this.Email.Label
      },
      {
        id: '', width: '10%', numeric: false, disablePadding: false, label: status
      },
      {
        id: '', width: '0%'
      }
    ];
  }

  static getInstance(): EmployeeModel {
    if (!EmployeeModel._instance) {
      EmployeeModel._instance = new EmployeeModel();
    }
    return EmployeeModel._instance;
  }

  static getInitialValues(values: EmployeesResource) : EmployeesResource {
    return {
      id: values.id,
      role: values.role,
      identifier: values.identifier || '',
      firstname: values.firstname || '',
      lastname: values.lastname || '',
      email: values.email || '',
      active: values.active,
      title: values.title || '',
      card: values.card || '',
      created: values.created || new Date(),
      createdBy: values.createdBy || '',
      modified: values.modified || new Date(),
      modifiedBy: values.modifiedBy || '',
      resources: values.resources || [],
    }
  }

  async getEmployees(): Promise<EmployeesResource[]> {
    let employees: EmployeesResource[];
    await axios.get<EmployeesResource[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            employees = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return employees;
  }

  async getEmployeeById(id: string): Promise<EmployeesResource> {
    let employee: EmployeesResource;
    await axios.get<EmployeesResource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            employee = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return employee;
  }

  async updateEmployee(submitData: EmployeeResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<EmployeeResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Utilisateur modifié.'
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertEmployee(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<EmployeeResourceForCreate, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Utilisateur créé.'
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Utilisateur supprimé.'
					});         
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  async getPermission(selectedPermissions: string[]): Promise<EmployeeAccessDto[]> {
    const employeeAccesses: EmployeeAccessDto[] = [];
    const data = new Map<string, string[]>();
    if (selectedPermissions && selectedPermissions.length > 0) {
      const model = new ResourceModel();
      const response = await model.getResources();
      selectedPermissions.forEach((selectedPermission: string) => {
        const [id, action] = selectedPermission.split('&');
        if (!data.has(id)) {
          data.set(id, [action]);
        } else {
          const temp = data.get(id);
          temp.push(action);
          data.set(id, temp);
        }
      });
      data.forEach((values, key) => {
        const obj = {
          resource: response.find((resource: Resource) => resource.id === +key)
        };
        values.forEach((value: string) => {
          if (value === Permissions.Add) obj[Permissions.Add] = true;
          if (value === Permissions.Edit) obj[Permissions.Edit] = true;
          if (value === Permissions.Read) obj[Permissions.Read] = true;
          if (value === Permissions.Delete) obj[Permissions.Delete] = true;
        });
        employeeAccesses.push(obj);
      });
    }
    return employeeAccesses;
  }
  
  getResourcesChecked(resources: EmployeeAccessDto[]): string[] {
    return resources.reduce((permissions: string[], resource: EmployeeAccessDto) => {
      const { add, read, edit, delete: del } = resource;
      const resourceId = resource.resource.id;
      if (read) permissions.push(`${resourceId}&${Permissions.Read}`);
      if (add) permissions.push(`${resourceId}&${Permissions.Add}`);
      if (edit) permissions.push(`${resourceId}&${Permissions.Edit}`);
      if (del) permissions.push(`${resourceId}&${Permissions.Delete}`);
      return permissions;
    }, []);
  }

}
