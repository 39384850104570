import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles, SvgIcon } from '@mui/material';
import type { Theme, CustomerResource } from 'src/common/types';
import { formatDate, labelConfig } from 'src/common';
import { Mail as PreferenceIcon } from 'react-feather';
import { greyed } from 'src/styles/customer';

export interface PreferenceInfoProps {
  customer: CustomerResource;
}

const PreferenceInfo: FC<PreferenceInfoProps> = ({ customer }) => {
  const { lcapLastEdit } = customer;
  return (
    <Box display='flex'>
      <SvgIcon fontSize='small'>
        <PreferenceIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <Box display='flex' alignItems='center'>
          <Typography sx={{ fontWeight: 'bold' }}>Paramètres des messages électroniques commerciaux</Typography>
        </Box>
        <Typography sx={greyed}>{labelConfig.lcapCommercials}</Typography>
        <Typography>{customer.lcapCommercials ? 'Oui' : 'Non'}</Typography>{' '}
        <Typography sx={greyed}>{labelConfig.lcapNewsletter}</Typography>{' '}
        <Typography>{customer.lcapNewsletter ? 'Oui' : 'Non'}</Typography>
        <Typography sx={greyed}>{labelConfig.lcapImprovement}</Typography>
        <Typography>{customer.lcapImprovement ? 'Oui' : 'Non'}</Typography>
        <Typography sx={{...greyed, fontStyle: 'italic'}}>{lcapLastEdit ? `Dernière mise à jour le ${formatDate(lcapLastEdit)}`: 'Aucune date mise à jour'}</Typography>
      </Box>
    </Box>
  );
};

PreferenceInfo.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default PreferenceInfo;
