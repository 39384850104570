import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { TribunalTypeResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { labelConfig, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/tribunalType';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface TribunalTypeEnInfoProps {
  tribunalType: TribunalTypeResource;
}

const TribunalTypeEnInfo: FC<TribunalTypeEnInfoProps> = ({
  tribunalType
}) => {
  return (
    <MyCard>
      <CardHeaderDetail title="Tribunal type" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              { labelConfig.nameEn }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { tribunalType.labelEn }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

TribunalTypeEnInfo.propTypes = {
  tribunalType: PropTypes.object.isRequired
};

export default TribunalTypeEnInfo;
