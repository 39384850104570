import {
  Box, Container,
  Divider,
  Tab, Tabs
} from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  DatabankModel,
  fetchDatabanks,
  fetchDetailsLibrary,
  fetchLibraryServices,
  Forbidden,
  LibraryModel,
  LibraryServiceModel,
  ListType,
  Unauthorized,
  useLocation,
  useNavigate,
  useParams,
  _databank,
  _library,
  _libraryService,
  LibraryOpenHoursModel,
  root
} from 'src/common';
import type {  
  TabsLibrary,  
} from 'src/common/types';
import Page from 'src/components/Page';
import { LibraryServiceProvider } from 'src/contexts/LibraryServiceContext';
import Addresses from './Addresses';
import DatabanksInfo from 'src/components/listInfo';
import Details from './Details';
import AddressesForm from './Forms/AddressesForm';
import DetailsEditForm from './Forms/DetailsEditForm';
import LibraryDatabankForm from './Forms/LibraryDatabankForm';
import LibraryLibraryServiceForm from './Forms/LibraryLibraryServiceForm';
import OpenHoursForm from './Forms/OpenHoursForm';
import LibraryServicesInfo from 'src/components/requiredParameter/listInfo';
import OpenHoursInfo from './OpenHoursInfo';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

const initialOthersState = {
  isEmptyList: true,
  isLoading: false,
  isAuthorize: false,
};

const tabs:TabsLibrary[] = [
  { value: 'details', label: 'Détails' },
  { value: 'address', label: 'Adresses' },
  { value: 'openHours', label: 'Heures d\'ouverture' },
  { value: 'databank', label: 'Bases de données' },
  { value: 'librarylibraryService', label: 'Services' }
];

const LibraryDetailsView: FC = () => {
  const model = LibraryModel.getInstance();
  const libraryOpenHoursModel = LibraryOpenHoursModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_library,{library: {}});
  const [sDatabank, dispatchDatabank] = useReducer(_databank,{...initialOthersState, databanks: []});
  const [sLibraryService, dispatchLibraryService] = useReducer(_libraryService,{...initialOthersState, libraryServices: []});
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    address: false,
    openHours: false,
    databank: false,
    librarylibraryService: false
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  model.Dialog.Header = 'Supprimer la bibliothèque';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'la bibliothèque';
  
//#region useCallback functions
  const getDatabanks = useCallback(async () : Promise<void> => {
    const model = new DatabankModel();
    const databanks = await model.getDatabanks();
    const { error } = model;
    if (error) {
      if (error.status === Forbidden || error.status === Unauthorized) {
        dispatchDatabank(fetchDatabanks(databanks, true, true, false));
      }
    } else {
      dispatchDatabank(fetchDatabanks(databanks, true, !Array.isArray(databanks) || databanks.length === 0, true));
    }
  },[]);

  const getLibraryServices = useCallback(async () : Promise<void> => {
    const model = new LibraryServiceModel();
    model.skipHandleError = true;
    const libraryServies = await model.getLibraryServices();
    const { error } = model;
    if (error) {
      if (error.status === Forbidden) {
        dispatchLibraryService(fetchLibraryServices(libraryServies, true, true, false));
      }
    } else {
      dispatchLibraryService(fetchLibraryServices(libraryServies, true, !Array.isArray(libraryServies) || libraryServies.length === 0, true));
    }
  },[]);

  const getLibraryById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const libraryModel = new LibraryModel();
      libraryModel.PathName = location.pathname;
      const library = await libraryModel.getLibraryById(id);
      if (!libraryModel.error) {
        dispatch(fetchDetailsLibrary(library));
      }
    }
  }, [id, location]);
//#endregion

  useEffect(() => {
    (async () => {
      await Promise.all([getDatabanks(),getLibraryServices(),getLibraryById()]);
    })();
  }, []);

  const { library } = state;

  if (Object.keys(library).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = library.nameFr;

  const setEditModeLibrary = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeLibrary(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setEditModeLibrary(false);
    setCurrentTab(value);
  };

  const handleRefreshLibrary = async () : Promise<void> => {
    await Promise.all([getDatabanks(),getLibraryServices(),getLibraryById()]);
    setEditModeLibrary(false);
  };

  const editLibrary = () => {
    setEditModeLibrary(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <DetailsEditForm
              model={model}
              libraryOpenHoursModel={libraryOpenHoursModel}
              library={library}
              onHandleRefreshLibrary={handleRefreshLibrary}
              onSwitchMode={(l) => switchMode(l)}
            />
          );
        }
        return (
          <Details model={model} library={library} />
        );
      case 'address':
        if (editMode.address) {
          return (
            <AddressesForm
              model={model}
              library={library}
              onHandleRefreshLibrary={handleRefreshLibrary}
              onSwitchMode={(l) => switchMode(l)}
            />
          );
        }
        return (
          <Addresses model={model} library={library} />
        );
      case 'openHours':
        if (editMode.openHours) {
          return (
            <OpenHoursForm
              model={model}
              library={library}
              onHandleRefreshLibrary={handleRefreshLibrary}
              onSwitchMode={(l) => switchMode(l)}
            />
          );
        }
        return (
          <OpenHoursInfo 
            model={libraryOpenHoursModel}
            library={library} 
          />
        );
      case 'databank':
        if (editMode.databank) {
          return (
            <LibraryDatabankForm
              model={model}
              library={library}
              onHandleRefreshLibrary={handleRefreshLibrary}
              onSwitchMode={(l) => switchMode(l)}
            />
          );
        }
        return (
          <DatabanksInfo
            listType={ListType.DatabankLibrary}
            isAuthorize={sDatabank.isAuthorize}
            data={sDatabank.databanks}
            lists={library.databanks}
          />
        );
      case 'librarylibraryService':
        if (editMode.librarylibraryService) {
          return (
            <LibraryServiceProvider>
              <LibraryLibraryServiceForm
                model={model}
                library={library}
                onHandleRefreshLibrary={handleRefreshLibrary}
                onSwitchMode={(l) => switchMode(l)}
              />
            </LibraryServiceProvider>
          );
        }
        return (
            <LibraryServicesInfo
              isAuthorize={sLibraryService.isAuthorize}
              listType={ListType.LibraryService}
              data={sLibraryService.libraryServices}
              lists={library.libraryServices}
            />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editLibrary()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryDetailsView;
