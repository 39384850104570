import type { FC } from 'react';
import PropTypes from 'prop-types';
import Results from './Results';
import { CustomerResource } from 'src/common/types';
import { CustomerModel } from 'src/common';

export interface CustomerEndorsedListViewProps {
  model: CustomerModel;
  limitEndorsed?: number;
  totalEndorsed?: number;
  customer: CustomerResource;
  onEditCustomerEndorsed: (isEdit: boolean, customerEndorsedId: number) => void;
}

const CustomerEndorsedListView: FC<CustomerEndorsedListViewProps> = ({
  model,
  customer,  
  limitEndorsed,
  totalEndorsed,
  onEditCustomerEndorsed,
}) => {
  const { customersEndorsed } = customer;
  return (
    <Results
      model={model}
      customersEndorsed={customersEndorsed}
      onEditCustomerEndorsed={(x,y) => onEditCustomerEndorsed(x,y)}
      limitEndorsed={limitEndorsed}
      totalEndorsed={totalEndorsed}
    />
  );
};

CustomerEndorsedListView.propTypes = {
  limitEndorsed: PropTypes.number,
  totalEndorsed: PropTypes.number,
  customer: PropTypes.object.isRequired,
  onEditCustomerEndorsed: PropTypes.func.isRequired
};

export default CustomerEndorsedListView;
