import TribunalModel from './Tribunal';

export default class TribunalCodesNotAssignedModel extends TribunalModel 
{
  private static _tribunalCodesNotAssignedModel: TribunalCodesNotAssignedModel;

  constructor(){
    super();
    this._headerTitle =  'Liste des codes tribunaux non assigné à un tribunal';
    this.Path.PathName = '/courts/codes-to-link';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'À traiter',
      href: this.Path.Home,
      visible: true
    }
  }

  static getInstance(): TribunalCodesNotAssignedModel {
    if (!TribunalCodesNotAssignedModel._tribunalCodesNotAssignedModel) {
      TribunalCodesNotAssignedModel._tribunalCodesNotAssignedModel = new TribunalCodesNotAssignedModel();
    }
    return TribunalCodesNotAssignedModel._tribunalCodesNotAssignedModel;
  }
}
  