import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import LibraryModel from 'src/model/library/Library';
import { tableRow} from 'src/common';
import { tableCell2 } from 'src/styles/library';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ContactInfoProps {
  model: LibraryModel;
  library: LibraryResource;
}

const ContactInfo: FC<ContactInfoProps> = ({
  model,
  library
}) => {
  const contactUs = LibraryModel.convertContactStringToJson(library.contactUs);
  const { ContactTelephone, ContactFax, ContactTollfree} = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Contact" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell2}>
              { ContactTelephone.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { contactUs?.telephone }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell2}>
              { ContactFax.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {contactUs?.fax}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell2}>
              { ContactTollfree.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {contactUs?.tollfree}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ContactInfo.propTypes = {
  library: PropTypes.object.isRequired
};

export default ContactInfo;
