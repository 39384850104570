import { FC } from "react";
import { Typography } from '@mui/material';
import { CustomerModel, CustomerStatus, getCustomerStatusLabelColor, Label, labelConfig } from "src/common";
import { CustomerResource } from "src/common/types";
import { greyed } from "src/styles/customer";
import useSettings from "src/hooks/useSettings";

interface CustomerStatusDetailProps {
  customer: CustomerResource;
  showTitle?: boolean;
}

const CustomerStatusDetail: FC<CustomerStatusDetailProps> = ({customer, showTitle}) => {
  const { settings } = useSettings();
  return (
    <>
      {showTitle && <Typography sx={greyed}>{labelConfig.customerStatus}</Typography>}
      <div style={{margin: '0.5em 0 1em 0', display: 'flex'}}>
        <Label color={getCustomerStatusLabelColor(customer.status, settings)}>{CustomerModel.getStatusByKey(customer.status)}</Label>
        {customer.blocked && <Label color={getCustomerStatusLabelColor(CustomerStatus.Restreint, settings)}>{labelConfig.blocked}</Label>}
      </div>
    </>
  )
}

export default CustomerStatusDetail;
