import React, { useState, MutableRefObject } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type {
  TribunalResource,
  TribunalTypeResource
} from 'src/common/types';
import {
  Grid,
  Box,
  Typography,
  ListItemIcon,
  IconButton,
  SvgIcon,
  FormGroup,
  TextField,
} from '@mui/material';
import {
  labelConfig,
  CaijInput,
  jurisdictionConfig,
  formatError,
  errorConfig,
  FormikErrors,
  FormikTouched,
  TribunalModel,
  MSG_FORBIDEN_ERROR,
  CaijTooltip
} from 'src/common';
import {
  PlusSquare as PlusSquareIcon,
  XCircle as XCircleIcon
} from 'react-feather';
import useTribunal from 'src/hooks/useTribunal';
import { X as DeleteIcon } from 'react-feather';
import { StyledListItem } from 'src/components/styled';

interface TribunalFormProps {
  model: TribunalModel;
  tribunal: TribunalResource;
  errors: FormikErrors<TribunalResource>;
  touched: FormikTouched<TribunalResource>;
  inputCodeFrRef?: MutableRefObject<any>;
  inputCodeEnRef?: MutableRefObject<any>;
  handleBlur: (e: any) => void;
  onHandleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const TribunalForm: FC<TribunalFormProps> = ({
  model,
  tribunal,
  errors,
  touched,
  inputCodeFrRef,
  inputCodeEnRef,
  handleBlur,
  onHandleChange,
  setFieldValue
}) => {
  const { tribunalTypes, isAuthorize, isEmptyList, isLoading } = useTribunal();
  const [otherCodesInput, setOtherCodesInput] = useState<string | undefined>('');
  const [otherCodes, setOtherCodes] = useState<string[]>(tribunal.otherCodes);
  const [error, setError] = useState<boolean>(false);
  const [helperText, setHelpertext] = useState<string>();
  const { CodeFr, CodeEn, LabelFr, LabelEn, Coverage, OtherCodesInput } = model;
  const { codeFr, codeEn, labelFr, labelEn, tribunalType, coverage, juridiction } = tribunal;

  const removeError = () => {
    setError(false);
    setHelpertext('');
  };

  const handleOtherCodes = (otherCode: string, isRemoveClick?: boolean): void => {
    const newOtherCodes = [...otherCodes];
    const currentIndex = newOtherCodes.indexOf(otherCode);
    removeError();
    if (currentIndex === -1) {
      newOtherCodes.push(otherCode);
    } else {
      if(isRemoveClick) {
        if(otherCode === codeFr) {
          touched.codeFr = false;
        }else if(otherCode === codeEn){
          touched.codeEn = false;
        }
        newOtherCodes.splice(currentIndex, 1);
      }else{
        setError(true);
        setHelpertext(formatError(errorConfig.exist, ['&1'], [labelConfig.code]));
      }
    }
    const len = tribunal.otherCodes.length;
    tribunal.otherCodes.splice(0, len);
    tribunal.otherCodes.push(...newOtherCodes);
    setOtherCodes(newOtherCodes);
  };

  const empty = () => {
    setOtherCodesInput('');
    removeError();
  }

  const save = async (otherCodesInput: string) => {
    const codeFrVal = inputCodeFrRef.current.value as string;
    const codeEnVal = inputCodeEnRef.current.value as string;
    if(!otherCodesInput) {
      setError(true);
      setHelpertext(formatError(errorConfig.required, ['&1'], [labelConfig.code]));
    }else if(otherCodesInput && (otherCodesInput.toLowerCase() === codeFrVal.toLowerCase() || otherCodesInput.toLowerCase() === codeEnVal.toLowerCase())){
      setError(true);
      setHelpertext(formatError(errorConfig.exist, ['&1'], [labelConfig.code]));
    }else if(otherCodesInput && !await (new TribunalModel).verifyTribunalCode(otherCodesInput, tribunal.id)){
      setError(true);
      setHelpertext(formatError(errorConfig.exist, ['&1'], [labelConfig.code]));
    }else{
      handleOtherCodes(otherCodesInput);
      setOtherCodesInput('');
    }
  }
  return (
    <>
      <Grid item md={6} xs={12}>
        <Box mb={1}>
          <CaijInput 
            name={CodeFr.Name}
            label={CodeFr.Label} 
            value={codeFr}
            required
            inputRef={inputCodeFrRef}
            helperText={touched.codeFr && errors.codeFr}
            error={Boolean(touched.codeFr && errors.codeFr)}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{ maxLength: CodeFr.MaxLength, 'data-testid': CodeFr.Name }}
            onHandleBlur={handleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput 
          name={CodeEn.Name}
          label={CodeEn.Label}
          value={codeEn}
          required
          inputRef={inputCodeEnRef}
          helperText={touched.codeEn && errors.codeEn}
          error={Boolean(touched.codeEn && errors.codeEn)}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: CodeEn.MaxLength, 'data-testid': CodeEn.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
      />
      </Grid>
      <Grid item md={6} xs={12}>
        <Box mb={1}>
          <CaijInput 
            name={LabelFr.Name}
            label={LabelFr.Label}
            value={labelFr}
            margin="none"
            required
            helperText={touched.labelFr && errors.labelFr}
            error={Boolean(touched.labelFr && errors.labelFr)}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{ maxLength: LabelFr.MaxLength, 'data-testid' : LabelFr.Name }}
            onHandleBlur={handleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
          <CaijInput 
            name={LabelEn.Name}
            label={LabelEn.Label}
            value={labelEn}
            required
            margin="none"
            helperText={touched.labelEn && errors.labelEn}
            error={Boolean(touched.labelEn && errors.labelEn)}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{ maxLength: LabelEn.MaxLength, 'data-testid': LabelEn.Name}}
            onHandleBlur={handleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
      </Grid>
      <Grid item md={12} xs={12}>
        <TextField
          label={labelConfig.juridiction}
          name='juridiction'
          required
          onChange={(e) => onHandleChange(e, setFieldValue)}
          SelectProps={{ native: true }}
          select
          value={juridiction}
          variant='outlined'
          sx={{mb:3}}
          inputProps={{ 'aria-label': 'juridiction', 'data-testid': 'juridiction'}}
          InputLabelProps={{ shrink: true }}
          onBlur={(e) => handleBlur(e)}
        >
          <option value="" disabled>Sélectionner une juridiction(obligatoire)</option>
          {
            jurisdictionConfig && Object.keys(jurisdictionConfig).map((key) => (
              <option value={key} key={key}>
                {jurisdictionConfig[key]}
              </option>
            ))
          }
        </TextField>
      </Grid>
      <Grid item md={12} xs={12}>
        <Box mb={1}>
          <TextField
            label={labelConfig.type}
            name='tribunalType'
            required
            onChange={(e) => onHandleChange(e, setFieldValue)}
            SelectProps={{ native: true }}
            select
            value={tribunalType}
            variant='outlined'
            sx={{mb:2.5}}
            inputProps={{ 'aria-label': 'tribunalType' }}
            InputLabelProps={{ shrink: true }}
            onBlur={(e) => handleBlur(e)}
          >
            <option value='' disabled>Sélectionner un type(obligatoire)</option>
            {
              (isAuthorize && !isEmptyList) && tribunalTypes.map((tribunalType: TribunalTypeResource) => (
                <option value={tribunalType.id} key={tribunalType.id}>
                  {tribunalType.labelFr}
                </option>
              ))
            }
          </TextField>
          <Box mt={1}>{(isLoading && !isAuthorize) && <span style={{ color: '#F44336' }}>{MSG_FORBIDEN_ERROR}</span>}</Box>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
          <CaijInput
            name={Coverage.Name}
            value={coverage}
            label={Coverage.Label}
            margin="none"
            inputAttr={{ maxLength: Coverage.MaxLength, 'data-testid': 'coverage' }}
            onHandleChange={onHandleChange}
            onHandleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
          <FormGroup>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <TextField 
                    name={OtherCodesInput.Name}
                    label={OtherCodesInput.Label}
                    value={otherCodesInput}
                    sx={{ width: '100%', margin: '1em 0.5em 0 0'}}
                    helperText={helperText || ' '}
                    error={Boolean(error)}
                    variant='outlined'
                    inputProps={{ maxLength: OtherCodesInput.MaxLength, autoComplete: 'none' }}
                    InputLabelProps={{ shrink: true}}
                    onChange={(e) => {
                      if(error){
                        removeError();
                      }
                      setOtherCodesInput(e.target.value)
                    }}
                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if(e.key === 'Enter'){
                        save(otherCodesInput);
                        e.preventDefault();
                      }
                    }}
                    InputProps={{
                    endAdornment: (
                        <>
                          { otherCodesInput && (
                            <CaijTooltip title="Effacer">
                              <a onClick={empty} style={{padding: '0.4em 0 0 0', cursor:'pointer', fontSize:'14px'}}>
                                <SvgIcon fontSize='small'>
                                  <DeleteIcon />
                                </SvgIcon>
                              </a>
                            </CaijTooltip>
                          )}
                        </>
                      )
                    }}
                  />
                    <CaijTooltip title="Ajouter" marginTop="0px">
                      <IconButton sx={{ marginBottom: '5px', marginLeft: '5px', marginRight: '5px', height: '40px', width:'40px'}} component="button" onClick={
                        async () => {
                          save(otherCodesInput);
                        }
                      }>
                        <SvgIcon fontSize="small">
                        <PlusSquareIcon />
                        </SvgIcon>
                      </IconButton>
                    </CaijTooltip>
              </Box>
              <Box>
                {
                   otherCodes.map((otherCode: string, index: number) => 
                      <StyledListItem key={index}>
                        <Box display="flex" alignItems="center" width="100%">
                          <Box width="95%">
                            <Typography variant="body2" sx={{color: 'text.secondary'}}>{otherCode}</Typography>
                          </Box>
                          <Box width="5%">
                            <ListItemIcon>
                              <CaijTooltip title="Supprimer">
                                <IconButton onClick={() => handleOtherCodes(otherCode,true)}>
                                  <SvgIcon fontSize="small">
                                    <XCircleIcon />
                                  </SvgIcon>
                                </IconButton>
                              </CaijTooltip>
                            </ListItemIcon>
                          </Box>
                        </Box>
                      </StyledListItem>
                  )
                }
              </Box>
          </FormGroup>
      </Grid>
    </>
  );
};

TribunalForm.propTypes = {
  tribunal: PropTypes.object.isRequired,
  inputCodeFrRef: PropTypes.any,
  inputCodeEnRef: PropTypes.any,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default TribunalForm;


