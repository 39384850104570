import { WorkingHour } from "src/common/types";

export default class LibraryOpenHoursModel
{
  private static _instance: LibraryOpenHoursModel;
  private _openHours: Map<number, WorkingHour>;
  private _days:Readonly<string[]> = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  
  constructor(){
    this._openHours = new Map<number, WorkingHour>();
  }

  set OpenHours(openHours: Map<number, WorkingHour>){
    this._openHours = openHours;
  }

  get OpenHours() : Map<number, WorkingHour>{
    return this._openHours;
  }

  get Days() : Readonly<string[]> {
    return this._days;
  }

  get Size() : number {
    if(this.OpenHours){
      return this.OpenHours.size;
    }
  }

  static getInstance(): LibraryOpenHoursModel {
    if (!LibraryOpenHoursModel._instance) {
      LibraryOpenHoursModel._instance = new LibraryOpenHoursModel();
    }
    return LibraryOpenHoursModel._instance;
  }

  public setOpenHours(key: number, hours: WorkingHour) {
    if(this.OpenHours.has(key)){
      this.OpenHours.set(key, hours);
    }
  }

  public getStartHour(key: number) : string {
    if(this.OpenHours.has(key)){
      return this.OpenHours.get(key).hours[0]?.start;
    }
  }

  public getEndHour(key: number) : string {
    if(this.OpenHours.has(key)){
      return this.OpenHours.get(key).hours[0]?.end;
    }
  }

  parseHours = (hour: string) => {
    hour = (/^\d\d:\d$/.test(hour)) ? `${hour}0` : hour;
    hour = (/^\d:\d\d$/.test(hour)) ? `0${hour}` : hour;
    hour = (/^\d:\d$/.test(hour)) ? `0${hour}0` : hour;
    hour = (/^NaN:NaN$/.test(hour)) ? null : hour;
    return hour;
  }

  public initialiseOpenHour(openHourFromBD: any, setOpenHour :(day: number, start: string, end: string) => void) {
    if(openHourFromBD){
      openHourFromBD.forEach((v:WorkingHour, k: number) => {
        const start = this.parseHours(v.hours[0]?.start);
        const end = this.parseHours(v.hours[0]?.end);
        this.OpenHours.set(k, {day:k, hours:[{start, end}] });
        setOpenHour(+k, start, end);
      });
    }else{
      this.Days.forEach((v:string, k: number) => {
        this.OpenHours.set(k,{day:k, hours:[{start:null, end:null}]})
        setOpenHour(k,null, null);
      });
    }
  }

  convertStringToJson(value: string): WorkingHour {
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }
}
