import { useEffect, useReducer } from "react";
import { 
    DocCollectionModel, 
    fetchDocCollections, 
    Forbidden, 
    Unauthorized, 
    _partners, 
    _documentType, 
    _docCollection,
} from "src/common";
import { DocumentCollectionResource } from 'src/common/types';
import { FETCH_DOCUMENT_COLLECTIONS } from "src/store/actions/ActionTypes";
import usePartners from "./partners";
import useDocumentTypes from "./documentType";

interface Data {
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

export interface Collection extends Data {
  docCollections: DocumentCollectionResource[];
}

const initialStateBase = {
  isEmptyList: true,
  isLoading: false,
  isAuthorize: false,
};

export function useCollection() : Collection {
  const initialState: Collection = {
    ...initialStateBase,
    docCollections: []
  };
  const [state, dispatch] = useReducer(_docCollection, initialState);
  
  useEffect(() => {
    const initialise = async () => {
      const model = new DocCollectionModel();
      model.skipHandleError = true;
      const collections = await model.getDocCollections();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchDocCollections(collections, true, false, false));
        }else{
          dispatch(fetchDocCollections(collections, true, true, true));
        }
      } else {
        dispatch(fetchDocCollections(collections, true, !Array.isArray(collections) || collections.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({ type: FETCH_DOCUMENT_COLLECTIONS, payload: initialState}); }
  },[]);
  const { docCollections, isEmptyList, isLoading, isAuthorize } = state;
  return { docCollections, isEmptyList, isLoading, isAuthorize }
};

export function usePublication() {
  return {
    sCollection: useCollection(),
    sPartners: usePartners(),
    sDocumentType: useDocumentTypes()
  }
}