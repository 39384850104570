import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import { LibraryModel, tableRow } from 'src/common';
import { tableCell2 } from 'src/styles/library';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow } from 'src/components/table/CaijTableRow';

export interface AddressFrInfoProps {
  model: LibraryModel;
  library: LibraryResource;
}

const AddressFrInfo: FC<AddressFrInfoProps> = ({model,library}) => {
  const { addressFr } = library;
  const { AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Adresse" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow label={AddressLine1.Label} sxTableCell={tableCell2}>
            {addressFr?.line1}
          </CaijTableRow>  
          <CaijTableRow label={AddressLine2.Label} sxTableCell={tableCell2}>
            {addressFr?.line2}
          </CaijTableRow>  
          <CaijTableRow label={AddressCity.Label} sxTableCell={tableCell2}>
            {addressFr?.city}
          </CaijTableRow>
          <CaijTableRow label={AddressState.Label} sxTableCell={tableCell2}>
            {addressFr?.state}
          </CaijTableRow>
          <CaijTableRow label={AddressZip.Label} sxTableCell={tableCell2}>
            {addressFr?.zip}
          </CaijTableRow>
          <CaijTableRow label={AddressCountry.Label} sxTableCell={tableCell2} sxTableRow={tableRow}>
            {addressFr?.country}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

AddressFrInfo.propTypes = {
  library: PropTypes.object.isRequired
};

export default AddressFrInfo;
