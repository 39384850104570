import React, { FC } from 'react';
import type { MessageResource } from 'src/common/types';
import {
  Box,
  Card,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  SvgIcon,
} from '@mui/material';
import { formatDateTime, labelConfig, MessagePlaceholderModel, NavigateFunction, tableRow, useNavigate } from 'src/common';
import { ArrowLeftCircle as LeftIcon } from 'react-feather';
import { bigAndBold, bold, box, darker, date, flex, messageTxt, row , btnReturn} from 'src/styles/message';
import parse from 'html-react-parser';

const icon = {
  marginRight: '-0.5em',
  height: '1.5em',
  width: '1.5em'
}

export interface MessageDetailsProps {
  messagePlaceholderModel: MessagePlaceholderModel;
  message: MessageResource;
  navigate: NavigateFunction;
}

const MessageDetails: FC<MessageDetailsProps> = ({ navigate, message, messagePlaceholderModel }) => {
  return (
      <Card>
        { !message.startDate ? (
            <Box sx={box}>
              <Typography variant="body2" sx={{color:"text.error"}}>Pas de données à afficher</Typography>
            </Box>
          ):(
            <Box sx={box}>
              <Box sx={row} mb={2}>
                  <Button
                    sx={btnReturn}
                    onClick={() => navigate(messagePlaceholderModel.Path.getDetail(message.placeholderId))}
                    startIcon={
                      <SvgIcon fontSize='inherit' sx={icon}>
                        <LeftIcon />
                      </SvgIcon>
                    }
                  >
                    <Typography sx={bigAndBold}>{message?.placeholder?.name}</Typography>
                  </Button>
              </Box>
              <Box display='flex'>
                <Box sx={row}>
                  <Card sx={darker}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={bold}>{labelConfig.nameFr}</Typography>
                          </TableCell>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={{color:'text.secondary'}}>
                              {message.name}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={bold}>{labelConfig.rank}</Typography>
                          </TableCell>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={{color:'text.secondary'}}>{message.rank}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={bold}>{labelConfig.startDate}</Typography>
                          </TableCell>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={{color:'text.secondary'}}>{formatDateTime(new Date(message.startDate))}</Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={tableRow}>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={bold}>{labelConfig.endDate}</Typography>
                          </TableCell>
                          <TableCell align='left'>
                            <Typography variant="body2" sx={{color:'text.secondary'}}>{formatDateTime(new Date(message.endDate))}</Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Box>
                <Box sx={date}>
                  <Box display='flex' sx={flex}>
                    <Typography variant="body2" sx={{...bold, marginRight: '0.5em'}}>
                      {labelConfig.titleFr} :
                    </Typography>
                    <Typography variant="body2" align='left' sx={{color:'text.secondary'}}>{message.titleFr}</Typography>
                  </Box>
                  <Box sx={messageTxt}>
                  {
                    message.messageFr && (
                      <div>{parse(message.messageFr)}</div>
                    )
                  }
                  </Box>
                  <Box display='flex' sx={flex}>
                    <Typography variant="body2" sx={{...bold, marginRight: '0.5em'}}>
                      {labelConfig.titleEn} :
                    </Typography>
                    <Typography variant="body2" sx={{color:'text.secondary'}}>{message.titleEn}</Typography>
                  </Box>
                  <Box sx={messageTxt}>
                  {
                    message.messageEn && (
                      <div>{parse(message.messageEn)}</div>
                    )
                  }
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }
      </Card>
    );
};

export default MessageDetails;
