import { Box, IconButton, SvgIcon } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Authorize, CaijInput, CaijTextarea, Mode, ReperageModel } from "src/common";
import { Save as SaveIcon, X as CancelIcon } from 'react-feather';
import CaijLabel from "src/components/label/CaijLabel";


interface ReperageNameFormProps {
    id: number;
    rowVal: string;
    label: string;
    isSubmitting?: boolean;
    maxLength: number;
    onSave: (value: string) => void;
    onCancel?: () => void;
}

const ReperageNameForm: FC<ReperageNameFormProps> = ({
    id,
    rowVal,
    label,
    isSubmitting,
    onSave,
    onCancel
}) => {
    const model = ReperageModel.getInstance();
    const filename = rowVal.substring(0,rowVal.lastIndexOf('.'));
    const fileExtension = rowVal.substring(rowVal.lastIndexOf('.'));
    const [isEditAuth, setIsEditAuth] = useState<boolean>();
    const [helperText, setHelpertext] = useState<string>('');
    const [error, setError] = useState<boolean>();
    const [value, setValue] = useState<string>(filename);
    const disableButton = isSubmitting || rowVal === value || !value;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!value) {
            setError(true);
            setHelpertext(`${label} est obligatoire`);
            setValue(value);
            return;
        }
        setError(false);
        setHelpertext('');
        setValue(value);
    };

    return (
        <>
            <Box pb={1} display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%' }}>
                <Box sx={{ width: '90%' }} display="flex" alignItems="center">
                    <Authorize
                        resourceCode={model.ResourceCode}
                        mode={Mode.edit}
                        onIsAuth={(l) => setIsEditAuth(l)}
                    >
                        <CaijInput
                            label={label}
                            name={`${label}-${id}`}
                            id={`${label}-${id}`}
                            required
                            disabled={!isEditAuth}
                            value={value}
                            error={error}
                            InputLabelProps={{ required: true }}
                            helperText={helperText}
                            onHandleChange={(e) => handleChange(e)}
                            inputProps={{endAdornment: fileExtension}}
                        />
                    </Authorize>
                </Box>
                {onCancel &&
                    <Box sx={{ marginTop: '10px', ml: '10px' }}>
                        <IconButton title='ANNULER' name={`cancel-edit-${label}-${id}`} disabled={!isEditAuth} onClick={(e) => onCancel()}>
                            <SvgIcon fontSize='small'>
                                <CancelIcon />
                            </SvgIcon>
                        </IconButton>
                    </Box>
                }
                <Authorize
                    resourceCode={model.ResourceCode}
                    mode={Mode.edit}
                    onIsAuth={(l) => setIsEditAuth(l)}
                >
                    <Box sx={{ marginTop: '10px', ml: onCancel ? '' : '10px', display: disableButton ? 'none' : 'block' }}>
                        <IconButton title='SAUVEGARDE' name={`${label}-${id}`} disabled={!isEditAuth} onClick={(e) => onSave(value+fileExtension)}>
                            <SvgIcon fontSize='small'>
                                <SaveIcon />
                            </SvgIcon>
                        </IconButton>
                    </Box>
                </Authorize>
            </Box>
        </>
    )
}

export default ReperageNameForm;