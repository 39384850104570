import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  DatabankAppType,
  DatabankModel, 
  fetchDetailsDatabank,
  useLocation, 
  useNavigate, 
  useParams,
  _databank,
  _library, _subscription, root
} from 'src/common';
import type { TabsDatabank } from 'src/common/types';
import Page from 'src/components/Page';
import Details from './Details';
import LibraryAccessInfo from './LibraryAccessInfo';
import RemoteAccessInfo from './RemoteAccessInfo';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { useSubscriptions } from 'src/components/listInfo/hook';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  databank: {}
};

const tabs: TabsDatabank[] = [
  { value: 'details', label: 'Détails' },
  { value: 'libraryAccess', label: 'Accès' },
  { value: 'remoteAccess', label: 'Accès' }
];

const DatabankDetailsView: FC = () => {
  const model = DatabankModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_databank, initialState);
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  const { subscriptions, isAuthorize } = useSubscriptions();
  model.Dialog.Header = 'Supprimer la base de donnée';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'la base de donnée';
  
  const getDatabankById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if (access.canRead()) {
      model.PathName = location.pathname;
      const databank = await model.getDatabankById(id);
      if (!model.error) {
        dispatch(fetchDetailsDatabank(databank));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getDatabankById();
    })();
  },[]);

  const { databank } = state;

  if (Object.keys(databank).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = databank.nameFr;

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
  };

  const getAccessTab = (tabValue : string) : boolean => {
    let isValid = false;
    const { appType } = databank;
    if(tabValue === "libraryAccess" && (appType !== DatabankAppType.Unknown && appType === DatabankAppType.Desktop)){
      isValid = true;
    }else if(tabValue === "remoteAccess" && (appType !== DatabankAppType.Unknown && appType === DatabankAppType.Online)){
      isValid = true;
    }else if(tabValue === "details"){
      isValid = true;
    }
    return isValid;
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        return <Details model={model} databank={databank} />;
      case 'libraryAccess':
        return (
          <LibraryAccessInfo databank={databank} />
        );
      case 'remoteAccess':
        return <RemoteAccessInfo
                  databank={databank} 
                  subscriptions={{
                    data: subscriptions,
                    isAuthorize: isAuthorize
                  }}
               />; 
      default:
        return '';
    }
  };

  return (
    <Page sx={root} title={model.DetailPageTitle}>
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          actions={['edit','delete']}
        />
        <Box mt={2}>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='scrollable' textColor='secondary'>
            {tabs.map(tab => 
              getAccessTab(tab.value) && (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{renderContent()}</Box>
      </Container>
    </Page>
  );
};

export default DatabankDetailsView;
