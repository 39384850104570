import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import { LibraryModel, tableRow } from 'src/common';
import CaijImage from 'src/components/image/CaijImage';
import { tableCell } from 'src/styles/library';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import parse from 'html-react-parser';

export interface LibraryEnInfoProps {
  model: LibraryModel;
  library: LibraryResource;
}

const LibraryEnInfo: FC<LibraryEnInfoProps> = ({
  model,
  library
}) => {
  const { descriptionEn, imgUrlEn, quoteEn, tempClosingMsgEn, staffEn } = library;
  const { ImageUrlEn, TempClosingMsgEn, StaffEn, QuoteEn, DescriptionEn } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Library" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              { DescriptionEn.Label }
            </TableCell>
            <TableCell>
              {
                descriptionEn && (
                  <div>{parse(descriptionEn)}</div>
                )
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { ImageUrlEn.Label }
            </TableCell>
            <TableCell>
              { imgUrlEn && (<CaijImage id="imgUrlEn" src={imgUrlEn} alt={imgUrlEn} />) }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { QuoteEn.Label }
            </TableCell>
            <TableCell>
              {
                quoteEn && (
                  <div>{parse(quoteEn)}</div>
                )
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { TempClosingMsgEn.Label }
            </TableCell>
            <TableCell>
              {
                tempClosingMsgEn && (
                  <div>{parse(tempClosingMsgEn)}</div>
                )
              }
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              { StaffEn.Label}
            </TableCell>
            <TableCell>
              {
                staffEn && (
                  <div>{parse(staffEn)}</div>
                )
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LibraryEnInfo.propTypes = {
  library: PropTypes.object.isRequired
};

export default LibraryEnInfo;
