import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  useParams,
  fetchLibrary,
  _library,
  LibraryModel,
  AccessModel,
  useNavigate,
  LibraryOpenHoursModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import LibraryEditForm from './LibraryEditForm';
import { LibraryServiceProvider } from 'src/contexts/LibraryServiceContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const LibraryEditView: FC = () => {
  const model = LibraryModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const libraryOpenHoursModel = LibraryOpenHoursModel.getInstance();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_library, { isLoading: false, library: {} });
  const { id } = useParams();
  
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new LibraryModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const library = await model.getLibraryById(id);
          if (!model.error) {
            dispatch(fetchLibrary(library, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchLibrary({ libraryServices: [] }, true));
        }
      }
    };
    (async () => {
      await initialise();
    })();
  }, [id, location]);

  const { library, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = library.nameFr;

  const {
    databanks,
    libraryServices,
    addressFr,
    addressEn,
    ...rest
  } = library;

  return (
    <Page sx={root} title={model.getEditPageTitle(id)}>
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <LibraryServiceProvider>
            <LibraryEditForm model={model} library={library} libraryOpenHoursModel={libraryOpenHoursModel} />
          </LibraryServiceProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryEditView;
