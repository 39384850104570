import CustomerModel from './Customer';

export default class PendingApprovalModel extends CustomerModel 
{
  private static _pendingApproval: PendingApprovalModel;

  constructor(){
    super();
    this._headerTitle =  "Liste en attente d'approbation";
    this.Path.PathName = '/members/pending-approval';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: "En attente d'approbation",
      href: this.Path.Home,
      visible: true
    }
  }

  static getInstance(): PendingApprovalModel {
    if (!PendingApprovalModel._pendingApproval) {
      PendingApprovalModel._pendingApproval = new PendingApprovalModel();
    }
    return PendingApprovalModel._pendingApproval;
  }
}
  