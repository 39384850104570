import { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import type { 
  DatabankResource,
  DatabankItemResource,
  DocumentCollectionResource,
  DocumentCollectionItemResource,
  SubscriptionResource,
  LibraryResource,
  DatabankAccessResource,
  DocumentTypeResource
} from 'src/common/types';
import { 
  ListType, 
  ListInfos,
  Result,
  TypeList,
  _docCollection,
  _databank,
  MSG_NOT_EXLCUS,
  MSG_NOT_INCLUS,
  MSG_FORBIDEN_ERROR,
  Size,
  SubscriptionModel,
  DocCollectionModel,
  DatabankModel,
  PathModel,
  DatabankAppType,
  DocumentTypeModel
} from 'src/common';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useLists } from './hook';
import { IListInfo } from 'src/types/listInfo';

type TypeListArray = DatabankItemResource[] | DocumentCollectionItemResource[] | number[] | string[];
type TypeDataArray = LibraryResource[] | DatabankResource[] | DocumentCollectionResource[] | SubscriptionResource[]

interface ListInfoProps {
  listType : ListType;
  data: TypeDataArray;
  lists: TypeListArray;
  isAuthorize: boolean;
}

const listItemStyle = {
  '&:hover': {
    cursor: 'hand',
  }
}

const getProxy = (appType: string, databankAccesses: DatabankAccessResource[]) : string => {
  if(appType === DatabankAppType.Online || appType === DatabankAppType.Unknown){
    const result = databankAccesses?.find(a => a.proxy?.name)
    if(result) return `( ${result.proxy.name} )`;
  }
  return '';
};

const ListInfo: FC<ListInfoProps> = ({
  listType,
  data,
  lists,
  isAuthorize
}) => {
  const isMountedRef = useIsMountedRef();
  const [availableList, setAvailableList] = useState<IListInfo[]>([]);
  const [inclusList, setInclusList] = useState<IListInfo[]>([]);
  const { sCollection } = useLists(listType);
  const list = ListInfos();

  useEffect(() => {
    const initialise = () => {
      if(isMountedRef.current){
        let Ids: number[] = [];
        switch(listType){
          case ListType.CustomerDatabank:
          case ListType.Databank:
          case ListType.DatabankLibrary:
            const databanks = lists as DatabankItemResource[];
            const dbs = data as DatabankResource[];
            if(databanks){
              Ids = databanks.map(({id}) => id);
              if(Ids[0] === undefined){
                Ids = lists as number[];
              }
              if(dbs){
                setAvailableList(dbs.filter(({id}) => !Ids.includes(id)));
                setInclusList(databanks);
              }
            }else if(dbs){
              setAvailableList(dbs);
            }
            break;
          case ListType.CustomerCollection:
          case ListType.Collection:
            const collections = lists as DocumentCollectionItemResource[];
            const docCollections = data as DocumentCollectionResource[];
            if(collections && docCollections){
              Ids = collections.map(({id}) => id);
              setAvailableList(docCollections.filter(({id}) => !Ids.includes(id)));
              setInclusList(collections);
            }else if(docCollections){
              setAvailableList(docCollections);
            }
            break;
          case ListType.Subscription:
            Ids = lists as number[];
            const subscriptions = data as SubscriptionResource[];
            if(Ids && subscriptions){
              setAvailableList(subscriptions.filter(({id}) => !Ids.includes(id)));
              setInclusList(subscriptions.filter(({id}) => Ids.includes(id)));
            }else if(subscriptions){
              setAvailableList(subscriptions);
            }
            break;
          case ListType.DocumentType:
            const codes = lists as string[];
            const documentTypes = data as DocumentTypeResource[];
            if(Ids && documentTypes){
              setAvailableList(documentTypes.filter(({code}) => !codes.includes(code)));
              setInclusList(documentTypes.filter(({code}) => codes.includes(code)));
            }else if(documentTypes){
              setAvailableList(documentTypes);
            }
            break;
        }
      }
    };
    initialise();
  }, [isMountedRef, listType, data, lists]);

  const getExclusList = (listType: ListType): JSX.Element | JSX.Element[] => {
    if(availableList){
        const avList: TypeList = { sx: listItemStyle };
        let path: PathModel;
        switch(listType){
          case ListType.CustomerDatabank://Access or Access group(Databank) or DatabankLibrary
          case ListType.Databank:
          case ListType.DatabankLibrary:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsDatabank: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsDatabank.success){
                return listType === ListType.CustomerDatabank ? null : rsDatabank.message;
            }
            path = DatabankModel.getInstance().Path;
            return availableList && availableList.sort((a,b) => a.nameFr.localeCompare(b.nameFr))
                                                 .map(({ id, nameFr, appType, databankAccesses }) => {
              return listType === ListType.DatabankLibrary ? 
                list.getList({
                  ...avList,
                  id,
                  path:path.getDetail(id),
                  name: nameFr
                }) : 
                list.getList({
                  ...avList,
                  id,
                  path:path.getDetail(id),
                  name: `${nameFr} ${getProxy(appType, databankAccesses)}`
                });
            });
          case ListType.CustomerCollection://Access or Access group (Collection)
          case ListType.Collection:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR)
            }
            const rsCollection: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsCollection.success){
              return listType === ListType.CustomerCollection ? null : rsCollection.message;
            }
            path = DocCollectionModel.getInstance().Path;
            return availableList && availableList.sort((a,b) => a.nameFr.localeCompare(b.nameFr))
                                                 .map(({id, nameFr}) => list.getList({
              ...avList,
              id,
              path: path.getDetail(id),
              name: nameFr
            }));
          case ListType.Subscription:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsSubscription: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsSubscription.success){
              return rsSubscription.message;
            }
            path = SubscriptionModel.getInstance().Path;
            return availableList && availableList.map(({id, name}) => list.getList({
              ...avList,
              id,
              path: path.getDetail(id),
              name
            }));
          case ListType.DocumentType:
            if(!isAuthorize){
              return list.getMessageError(MSG_FORBIDEN_ERROR);
            }
            const rsDocumentType: Result = list.isListValid(availableList, MSG_NOT_EXLCUS);
            if(!rsDocumentType.success){
              return rsDocumentType.message;
            }
            path = DocumentTypeModel.getInstance().Path;
            return availableList && availableList.map(({code, nameFr}) => list.getList({
              ...avList,
              id: code,
              path: path.getDetail(code, '/wc'),
              name: nameFr
            }));
        } 
    }
  };

  const getInclusList = (listType: ListType): JSX.Element | JSX.Element[] => {
    if(inclusList){
      const incList: TypeList = { sx: listItemStyle };
      let path: PathModel;
      switch(listType){
        case ListType.CustomerDatabank://Access or Access group (Databank)
        case ListType.Databank:
        case ListType.DatabankLibrary:
          if(!isAuthorize){
            return list.getMessageError(MSG_FORBIDEN_ERROR);
          }
          const rsbds: Result = list.isListValid(inclusList, MSG_NOT_INCLUS);
          if(!rsbds.success){
            return listType === ListType.CustomerDatabank ? null : rsbds.message;
          }
          path = DatabankModel.getInstance().Path;
          return inclusList && inclusList.sort((a,b) => a.nameFr.localeCompare(b.nameFr))
                                         .map(({id, nameFr, enabled, databankAccesses, appType}, index: number) => {
            return listType === ListType.DatabankLibrary ? 
              list.getList({
                ...incList, 
                id, 
                path: path.getDetail(id), 
                name: nameFr,
                active: enabled
              }) :
              list.getList({
                ...incList, 
                id, 
                path: path.getDetail(id), 
                name: `${nameFr} ${getProxy(appType, databankAccesses)}`,  
                active: enabled,
                appType: appType
              });
          });
        case ListType.CustomerCollection://Access or Access group (Collection)
        case ListType.Collection:
          if(!isAuthorize){
            return list.getMessageError(MSG_FORBIDEN_ERROR);
          }
          //Mixing PRIVATE & PUBLIC
          const collections: IListInfo[] = [...inclusList,...sCollection.docCollections];
          const rsCollection: Result = list.isListValid(collections, MSG_NOT_INCLUS);
          if(!rsCollection.success){
            return listType === ListType.CustomerCollection ? null : rsCollection.message;
          }
          path = DocCollectionModel.getInstance().Path;
          return collections && collections.sort((a,b) => a.nameFr.localeCompare(b.nameFr))
                                           .map(({id, nameFr, access, active}) => list.getList({
            ...incList, 
            id, 
            path: path.getDetail(id), 
            name: nameFr,
            listType, 
            accessType: access,
            active
          }));
        case ListType.Subscription:
          if(!isAuthorize){
            return list.getMessageError(MSG_FORBIDEN_ERROR);
          }
          const rsSubscription: Result = list.isListValid(inclusList, MSG_NOT_INCLUS);
          if(!rsSubscription.success){
            return rsSubscription.message;
          }
          path = SubscriptionModel.getInstance().Path;
          return inclusList && inclusList.map(({id, name, active}) => list.getList({
            ...incList, 
            id, 
            path: path.getDetail(id), 
            name, 
            active
          }));
        case ListType.DocumentType:
          if(!isAuthorize){
            return list.getMessageError(MSG_FORBIDEN_ERROR);
          }
          const rsDocumentType: Result = list.isListValid(inclusList, MSG_NOT_INCLUS);
          if(!rsDocumentType.success){
            return rsDocumentType.message;
          }
          path = DocumentTypeModel.getInstance().Path;
          return inclusList && inclusList.map(({code, nameFr}) => list.getList({
            ...incList, 
            id : code, 
            path: path.getDetail(code, '/wc'), 
            name: nameFr
          }));
      }
    }
  }
   
  const renderList = <T extends IListInfo[]>(availableList: T, inclusList: T) => {
    const listExclus = getExclusList(listType);
    const listInclus = getInclusList(listType);
    let size: Size = {md: 12,xs: 12,lg: 12}
    if(availableList.length === 0 && inclusList.length > 0){
      return list.renderInclusList(listInclus,size);
    }else if(availableList.length > 0 && inclusList.length === 0) {
      return list.renderExclusList(listExclus,size);
    }else if(availableList.length > 0 && inclusList.length > 0){
      return list.renderBothList(listExclus,listInclus)
    }
  }

  const renderContent = (listType: ListType) => {
    if(listType === ListType.CustomerDatabank){
      return renderList(availableList, inclusList);
    }if(listType === ListType.CustomerCollection){
      if(!sCollection.docCollections)
        return renderList(availableList, [...inclusList]);
      else
        return renderList(availableList, [...inclusList,...sCollection.docCollections]);
    }else{
      return renderList(availableList,inclusList);
    }
  }; 

  return (
    <Grid container spacing={3}>
      { renderContent(listType) }
    </Grid>
  );
};

ListInfo.propTypes = {
  listType: PropTypes.oneOf([
    ListType.Databank, 
    ListType.Collection,
    ListType.Subscription,
    ListType.DatabankLibrary,
    ListType.CustomerDatabank,
    ListType.CustomerCollection,
    ListType.DocumentType
  ]),
  data: PropTypes.array,
  lists: PropTypes.array,
  isAuthorize: PropTypes.bool.isRequired
};

export default ListInfo;
