import AppModel from './App';
import axios from 'src/utils/axios';

export default class UploadImageModel extends AppModel 
{
  constructor(){
    super('/file');
  }

  async uploadImage(file: FormData) : Promise<string> {
    let image: string;
    await axios({
      method: "post",
      url: this.route,
      data: file,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => image = response.data as string)
    .catch(error => this.printError(error));
    return image;
  }
}
