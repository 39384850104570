import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@mui/material';

interface CaijTableProps {
  children?: ReactNode;
}

const CaijTable: FC<CaijTableProps> = ({ children }) => {
  return (
    <>
      <Table sx={{width:'100%',backgroundColor:'background.paper'}}>
        {children}
      </Table>
    </>
  );
};

CaijTable.propTypes = {
  children: PropTypes.node
};

export default CaijTable;
