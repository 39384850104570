import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import { CheckSquare as SaveIcon } from 'react-feather';

interface CaijButtonSubmitProps {
  disabled?: boolean;
  title?: string;
  sx?: any;
  onHandleClick?: (event?: ChangeEvent<any>) => void;
  color?: "primary" | "secondary" | 'success' | 'error' | 'info' | 'warning' | 'inherit';
  value?: string;
}

const CaijButtonSubmit: FC<CaijButtonSubmitProps> = ({ onHandleClick, value, title, disabled, color, sx }) => (
  <>
    <Button
      name="submit"
      variant="contained"
      type="submit"
      disabled={disabled}
      color={color}
      sx={sx}
      onClick={onHandleClick}
      title={title}
      startIcon={(
        <SvgIcon fontSize="small">
          <SaveIcon />
        </SvgIcon>
        )}
    >
      {value}
    </Button>  
  </>
);

CaijButtonSubmit.propTypes = {
  disabled: PropTypes.bool,
  onHandleClick: PropTypes.func,
  sx: PropTypes.any,
  title: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'success' , 'error' , 'info' , 'warning', 'inherit']),
};

CaijButtonSubmit.defaultProps = {
  value: 'Sauvegarder',
  disabled: false,
  color: 'primary',
  onHandleClick: () => {}
};

export default CaijButtonSubmit;
