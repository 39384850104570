import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  AccessModel,
  _apiKey,
  ApiKeyModel,
  fetchApiKeys,
  deleteApiKey,
  root,
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  apiKeys: [],
  isLoading: false,
  isEmptyList: true,
};

const ApiKeyListView: FC = () => {
  const model = ApiKeyModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_apiKey, initialState);

  const getApiKeys = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new ApiKeyModel();
      model.PathName = location.pathname;
      const apiKeys = await model.getApiKeys();
      if (!model.error) {
        dispatch(fetchApiKeys(apiKeys, true,!Array.isArray(apiKeys) || apiKeys.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getApiKeys();
    })();
  },[]);

  const handleDelete: (id: string) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteApiKey(id));
    }
  };

  const { apiKeys, isEmptyList, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            apiKeys={apiKeys}
            onDeleteApiKey={handleDelete}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ApiKeyListView;
