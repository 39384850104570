import React, { MutableRefObject } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { InputAttr, InputProps, Margin, Size, Variant } from 'src/types/input';

interface CaijInputDefaultValueProps {
  type?: 'text' | 'number';
  id?: string;
  defaultValue?: string | number;
  label?: string;
  name: string;
  rows?: number;
  placeholder?: string;
  error?: any;
  multiline?: boolean;
  disabled?: boolean;
  size?: Size;
  variant?: Variant;
  margin?: Margin;
  helperText?: any;
  InputLabelProps?: { shrink?: boolean, required?: boolean };
  inputProps?: InputProps;
  inputAttr?: InputAttr;
  required?: boolean;
  sx?: any;
  inputRef?: MutableRefObject<any>;
  onHandleBlur?: (e: any) => void;
  onHandleChange?: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijInputDefaultValue: FC<CaijInputDefaultValueProps> = ({
  defaultValue,
  variant,
  inputRef,
  inputProps,
  inputAttr,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
  ...rest
} : CaijInputDefaultValueProps) => (
  <TextField
    {...rest}
    autoComplete="off"
    variant={variant}
    fullWidth
    inputRef={inputRef}
    onBlur={onHandleBlur}
    onChange={(event: ChangeEvent<HTMLInputElement>) => onHandleChange(event, setFieldValue)}
    defaultValue={defaultValue || ''}
    InputProps={inputProps}
    inputProps={inputAttr}
  />
);

CaijInputDefaultValue.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number']),
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.any,
  inputRef: PropTypes.any,
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  margin: PropTypes.oneOf(['normal', 'dense', 'none']),
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  inputAttr: PropTypes.object,
  onHandleBlur: PropTypes.func,
  onHandleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
};

CaijInputDefaultValue.defaultProps = {
  type: 'text',
  id: '',
  multiline: false,
  rows: 1,
  required: false,
  disabled: false,
  InputLabelProps: { shrink: true, required: false },
  inputProps: { readOnly: false },
  variant: 'outlined',
  margin: 'normal',
  size: 'medium',
  placeholder: '',
  inputRef:null,
  sx:{ marginBottom: 0},
  onHandleBlur: () => {},
  onHandleChange: () => {},
  setFieldValue: () => {},
};

export default CaijInputDefaultValue;
