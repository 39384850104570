import React, { useState, useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  TableBody,
  TableRow,
  Typography
} from '@mui/material';
import {
  Link as RouterLink,
  labelConfig,
  Authorize,
  EmptyList,
  Label,
  Mode,
  CaijButton,
  formatDate,
  ListType,
  getEmptyListSearch,
  CustomerModel,
  useNavigate,
  FIRST_PAGE,
  link,
  useLocation,
  NO_AUTHORIZE_ACCESS_MSG,
  SubscriptionModel,
  Forbidden,
  _customer,
  ITEMS_PER_PAGE,
  Unauthorized,
  fetchCustomers,
  CustomerRole
} from 'src/common';
import type { 
  CustomerResource, 
  GetCustomersAsyncOptionalParams, 
  SearchFilter 
} from 'src/common/types';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { getCustomerStatusLabelColor } from 'src/common';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import { CaijTableHeader } from 'src/components/table/CaijTableHeader';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';

const error = {
  padding: '30px 0 30px 0',
  textAlign: 'center'
}

const header = [
  { width: "25%", name: labelConfig.nameFr},
  { width: "25%", name: labelConfig.accessGroup},
  { width: "15%", name: labelConfig.noCard},
  { width: "25%", name: labelConfig.customerStatus},
  { width: "10%", name: labelConfig.createdDate},
];

export interface CustomerListViewProps {
  model: SubscriptionModel;
  subscriptionId: number;
  isActive: boolean;
  onEditSubscription: () => void;
}

const initialState = {
  customers: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isEmptyList: true,
  model: null
};

const params: GetCustomersAsyncOptionalParams  = {
  pageNumber: 1,
  pageSize: ITEMS_PER_PAGE,
  role: CustomerRole.User,
};

const CustomerListView: FC<CustomerListViewProps> = ({
  model,
  subscriptionId,
  isActive,
  onEditSubscription
}) => {
  const [state, dispatch] = useReducer(_customer, initialState);
  const [isCreateAuth, setIsCreateAuth] = useState<boolean>();
  const customerModel = CustomerModel.getInstance();
  const navigate = useNavigate();
  const location = useLocation();
  const [filter, setFilter] = useState<SearchFilter>({
    query:'',
    doSearch: false,
    page: state.paged?.currentPage - 1
  });

  const getCustomers = useCallback(async (model: CustomerModel, params: GetCustomersAsyncOptionalParams) : Promise<void> => {
    model.skipHandleError = true;
    const results = await model.getCustomers(params);
    const { error } = model;
    if(error){
      if (error.status === Forbidden || error.status === Unauthorized) {
        dispatch(fetchCustomers([], null, true, true, false, model));
      }else{
        dispatch(fetchCustomers([], null, true, true, true, model));
      }
    }else{
      dispatch(fetchCustomers(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0, true, model));
    }
  },[]);
  
  useEffect(() => {
    (async () => { 
      const { search } = location;
      const { Filters: { query, page } } = customerModel;
      const urlParams = new URLSearchParams(search);
      const pageNumber = +urlParams.get(page);
      if(urlParams.get(query) && search){
        if(await customerModel.setQueryParams(search, customerModel, navigate)){
          await getCustomers(customerModel,{
            ...params,
            pageNumber: +customerModel.getQueryParam(page) + 1,
            searchTerm: customerModel.getQueryParam(query),
            subscriptionId
          });
        }
        setFilter((prevState: SearchFilter) => {
          return {
            ...prevState,
            page: +pageNumber || FIRST_PAGE,
            query: urlParams.get(query),
            doSearch: true
          }
        });
      }else{
        setFilter((prevState: SearchFilter) => {
          return {
            ...prevState,
            page: +pageNumber || FIRST_PAGE
          }
        });
        await getCustomers(customerModel,{...params, pageNumber: +pageNumber + 1, searchTerm:'',subscriptionId});
      }
    })();
  },[location.search]);

  if(!state.isLoading) return;
  
  if(!state.isAuthorize){
    return (
      <MyCard>
        <Typography variant="subtitle1" sx={{...error,color: 'text.error'}}>
          {NO_AUTHORIZE_ACCESS_MSG}
        </Typography>
      </MyCard>
    )
  }
  return (
    <MyCard>
      <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
      {
        (!state.isEmptyList || filter.doSearch) ? (
            <Search 
              listType={ListType.MemberSubscription}
              filter={filter}
              placeholder='Recherche le/les membre(s)' 
              width="400px"
              path={model.Path.getDetail(subscriptionId + location.hash)}
            />
          ) : <Box />
        }
        <Box display='flex' alignItems='right' justifyContent='space-between'>
          <Authorize resourceCode={customerModel.ResourceCode} mode={Mode.add} onIsAuth={(l) => setIsCreateAuth(l)}> 
            <CaijButton
              type='add'
              color='secondary'
              variant='contained'
              disabled={!isCreateAuth || !isActive}
              onHandleClick={() => onEditSubscription()}
            >
              Ajouter un membre
            </CaijButton>
          </Authorize>
        </Box>
      </Box>
      { state.isEmptyList && !filter.doSearch ? <EmptyList /> : (
          <>
            <CaijTable>
              <CaijTableHeader data={header} />
              <TableBody>
                { state.isEmptyList ? getEmptyListSearch(header.length) :
                  state.customers.map((customer: CustomerResource) => {
                  return (
                    <TableRow hover key={customer.id}>
                      <CaijTableCell>
                        <div>
                          <Link 
                            underline="none" 
                            sx={{...link,color:'text.secondary'}} 
                            color="inherit" 
                            component={RouterLink} 
                            to={customerModel.Path.getDetail(customer.id)} 
                            variant='body2'
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {`${customer.firstname} ${customer.lastname}`}
                          </Link>
                        </div>
                        <div>
                          <Link 
                            underline="none" 
                            sx={{...link, color:'text.secondary'}} 
                            href={`mailto:${customer.email}`}
                          >
                            {customer.email}
                          </Link>
                        </div>
                        <div>
                          {customer.noCaij}
                        </div>
                      </CaijTableCell>
                      <CaijTableCell>
                      { customer.subscription && (
                          <Box display='flex' flexDirection='column' alignItems='left'>
                            <Box mb={2}>
                              <Label color={customer.subscription.active ? 'success' : 'error'}>
                                {customer.subscription.active ? labelConfig.active : labelConfig.inactive}
                              </Label>
                            </Box>
                            <Box>{customer?.subscriptionGroup?.name}</Box>
                          </Box>
                        )
                      }
                      </CaijTableCell>
                      <CaijTableCell>{customer.card}</CaijTableCell>
                      <CaijTableCell>
                        <Label color={getCustomerStatusLabelColor(customer.status)}>{CustomerModel.getStatusByKey(customer.status)}</Label>
                      </CaijTableCell>
                      <CaijTableCell>{formatDate(customer.created)}</CaijTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </CaijTable>
            <CaijTablePagination 
              paged={state.paged}
              model={model}
              page={filter.page}
              filter={filter}
              navigate={(model) => navigate(model.getUrlEncodeMembreSubscription.apply(model,model.getParams()))}
            />
          </>
          )
        }
    </MyCard>
  );
};

CustomerListView.propTypes = {
  subscriptionId: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
  onEditSubscription: PropTypes.func.isRequired,
};

export default CustomerListView;
