import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  AccessPermissions,
  CaijButtonReset,
  CaijButtonSubmit,
  DocCollectionModel,
  _docCollection,
  Authorize,
  ANIMATION_TIMING,
  Mode,
  ListType,
  btnSubmit,
  handleChangeCheckBox,
  SubscriptionModel,
  AccessModel,
} from 'src/common';
import type {
  DocumentCollectionResource,
  DocumentCollectionResourceForCreate,
  DocumentCollectionResourceForEdit
} from 'src/common/types';
import DocCollectionForm from 'src/components/content/docCollection/Forms';
import { CSSTransition } from 'react-transition-group';
import AccessTypeForm from 'src/components/access/AccessTypeForm';
import SubscriptionList  from 'src/components/listInfo/checkList';
import MyCard from 'src/components/card/MyCard';
import Memo from 'src/components/memo';
import validateSchema from '../Schema';

export interface DocCollectionEditFormProps {
  model: DocCollectionModel;
  collection: DocumentCollectionResource;
  onSubmit?: (values:DocumentCollectionResource) => void;
}

export interface Subscription {
  subscriptions: number[],
  isLoading: boolean;
};

const DocCollectionEditForm: FC<DocCollectionEditFormProps> = ({
  model,
  collection,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const subscriptionModel = SubscriptionModel.getInstance();
  const subscriptionAccess = new AccessModel(subscriptionModel.ResourceCode);
  const [selectedSubscriptionDocCollections, setSelectedSubscriptionDocCollections] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  
  const handleSubmit = async (values: DocumentCollectionResource) => {
    if (onSubmit){
		  onSubmit(values);
      return;
    }
    
    let submitData = values as DocumentCollectionResourceForEdit;
    const model = new DocCollectionModel();
    if (submitData && submitData.id) {
      model.setSubmitListData(submitData, selectedSubscriptionDocCollections);
      const response = await model.updateDocCollection(submitData);
      if (!model.error) {
        const { status, message } = response;
        await model.redirect(model.Path.Home,{status,message});
      }
    } else {
      submitData = values as DocumentCollectionResourceForCreate;
      model.setSubmitListData(submitData, selectedSubscriptionDocCollections);
      delete submitData.id;
      const response = await model.insertDocCollection(submitData);
      if (!model.error) {
        const { status, message } = response;
        await model.redirect(model.Path.getDetail(response.data.id),{status,message});
      }
    }
  }

  return (
    <Formik
      initialValues={DocCollectionModel.getInitialValues(collection)}
      validateOnChange={false}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <DocCollectionForm
                  model={model}
                  collection={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                />
                <Grid item md={6} xs={12}>
                  <Box>
                    <AccessTypeForm
                      data={values}
                      errors={errors}
                      touched={touched}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    <Box mt={2}>
                      <Authorize
                        resourceCode={model.ResourceCode}
                        mode={collection.id ? Mode.edit : Mode.add}
                        onIsAuth={l => setIsAuth(l)}
                      >
                        <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                      </Authorize>
                      <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
                    </Box>
                  </Box>
                </Grid>
                <CSSTransition
                  in={values.access === AccessPermissions.Private && subscriptionAccess.Gestion}
                  timeout={ANIMATION_TIMING}
                  classNames='fade'
                  unmountOnExit
                  mountOnEnter
                >
                  <Grid item md={6} xs={12}>
                    <Memo>
                      <SubscriptionList 
                        listType={ListType.CollectionSubscription}
                        lists={values?.subscriptions}
                        group={values?.group}
                        onHandleSelectedGroupItems={(l) => setFieldValue('group', l)}
                        onHandleSelectedItems={l =>
                          setSelectedSubscriptionDocCollections({
                            ...selectedSubscriptionDocCollections,
                            ids: l as number[],
                            selected: true,
                          })
                        }
                      />
                    </Memo>
                  </Grid>
                </CSSTransition>
              </Grid>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DocCollectionEditForm.propTypes = {
  collection: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

DocCollectionEditForm.defaultProps = {
  onSubmit: null
};

export default DocCollectionEditForm;
