import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/common/types';
import { Box, Container, Grid, Typography } from '@mui/material';
import {
  useParams,
  useLocation,
  fetchMessage,
  _message,
  MessageModel,
  AccessModel,
  MessagePlaceholderModel,
  root,
} from 'src/common';
import Page from 'src/components/Page';
import MessageEditForm from '../Forms/MessageEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { container } from '../../../../styles/message';
import MyCard from 'src/components/card/MyCard';
import GenericHeader from 'src/components/header/GenericHeader';


const big = {
  fontSize: '1.7em',
};
const grayed = {
  color: 'gray',
};
const padding = {
  padding: '1em',
};

const initialState = {
  isLoading: false,
  message: {},
};

const MessagesEditView: FC = () => {
  const model = MessageModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_message, initialState);
  const { id } = useParams();
  const [createMsg, setCreateMsg] = useState<boolean>(false);

  const switchCreateMsg = (isCreating: boolean) => {
    setCreateMsg(isCreating);
  };

  const getMessageById = useCallback(async () : Promise<void> => {
    const model = new MessageModel();
    if (!id || isNaN(+id)) {
      await model.redirect(model.Path.Home);
    }else if(access.canEdit(id)){
      model.PathName = location.pathname;
      const message = await model.getMessageById(Number(id));
      if (!model.error) {
        dispatch(fetchMessage(message, true));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getMessageById();
    })();
  },[]);

  const { message, isLoading } = state;

  if(!isLoading) return <LoadingScreen />;
  
  model.Title = message.name;

  if (message && message.placeholder)
    return (
      <Page sx={root} title={model.getEditPageTitle(message.id)}>
        <Container maxWidth={false}>
          <GenericHeader 
            model={model} 
            headerTitle={model.Title} 
            message={{ id: message.placeholder.id, label: message.placeholder.name }} 
          />
          <Box mt={3}>
            <Grid spacing={2} container>
              <Grid xs={9} item>
                <MyCard>
                  <Box sx={{...container}}>
                    <Box width='100%' mr={2}>
                      <Box sx={padding}>
                        <Box>
                          <Typography sx={grayed}>Nom de l'emplacement de ce message</Typography>
                        </Box>
                        <Box>
                          <Typography sx={big}>{message.placeholder.name}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </MyCard>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <MessageEditForm message={message} switchMode={switchCreateMsg} holder={message.placeholder} />
          </Box>
        </Container>
      </Page>
    );
};

export default MessagesEditView;
