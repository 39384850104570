import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {Box, CardContent} from '@mui/material';
import {
  Formik,
  handleChange,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  DocumentModel
} from 'src/common';
import type {
  PartnerResource,
  DocumentResource,
  DocumentResourceForEdit,
  Error
} from 'src/common/types';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import DocumentForm from 'src/components/content/document/Forms';
import { Root } from 'src/components/styled';
import validateSchema from '../Schema';

export interface DocumentEditFormProps {
  model: DocumentModel;
  document: DocumentResource;
  onSubmit?: (values: PartnerResource) => void;
}

async function redirect(model: DocumentModel, response: Error) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const DocumentEditForm: FC<DocumentEditFormProps> = ({
  model,
  document,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const handleSubmit = async (values:DocumentResource) => {
    if (onSubmit) {
		  onSubmit(values);
      return;
    }
    const model = new DocumentModel();
    let submitData = values as DocumentResourceForEdit;
    if (submitData && submitData.documentId) {
      const response = await model.updateDocument(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  }

  return (
    <Formik
      initialValues={{...document}}
      validateOnChange={false}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Root>
                <DocumentForm 
                  model={model}
                  document={values} 
                  errors={errors}
                  touched={touched}
                  onHandleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Root>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DocumentEditForm.propTypes = {
  document: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

DocumentEditForm.defaultProps = {
  onSubmit: null
}

export default DocumentEditForm;
