import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Link,
  Grid,
  Box,
  ListItem
} from '@mui/material';
import { 
  DatabankResource, 
  SubscriptionResource, 
  SubscriptionItemResource, 
  ProxyDto,
  KnownDatabankAccessType,
  DatabankAccessResource
} from 'src/common/types';
import {
  labelConfig,
  ListType,
  tableRow,
  link,
  getUrlTitle,
  DatabankAppType,
  MSG_EMPTY_LIST,
  CaijCard,
  DatabankLinkTypeList
} from 'src/common';
import Subscriptions from 'src/components/listInfo';
import { tableCell2 } from 'src/styles/databank';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import Libraries from 'src/components/databank/Lists/Libraries';

interface RemoteAccessInfoProps {
  databank: DatabankResource;
  subscriptions: {
    data: SubscriptionResource[];
    isAuthorize: boolean;
  }
}

function getAccessType(databankAccesses:DatabankAccessResource[]):string{
  if(databankAccesses.length > 1){
    return 'À distance et pour tous en bibliothèque'
  }
  else if(databankAccesses.length === 1){
    if(databankAccesses[0].accessType === KnownDatabankAccessType.Library){
      return 'En bibliothèque seulement'
    }
    else if(databankAccesses[0].accessType === KnownDatabankAccessType.Remote){
      return 'À distance'
    }
  }
}

function getLibraries(databank: DatabankResource){
    if(databank.libraries.length === 0){
      return (
        <CaijCard title="Bibliothèques d'accès">
          <ListItem sx={{ justifyContent: 'center'}}>
            <Typography variant="body2" sx={{color: 'text.error'}}>{MSG_EMPTY_LIST}</Typography>
          </ListItem>
        </CaijCard>
      )
    }
    return (
      <Libraries databank={databank} viewOnly={true} />
    ) 
}

const RemoteAccessInfo: FC<RemoteAccessInfoProps> = ({
  databank,
  subscriptions
}) => {

  const { databankAccesses } = databank;
  const remoteAccess = databankAccesses.find(a => a.accessType === KnownDatabankAccessType.Remote)
  const libraryAccess = databankAccesses.find(a => a.accessType === KnownDatabankAccessType.Library)

  return (
    <Grid container spacing={3}>
        <Grid item md={12} xs={12} lg={12}>
          <MyCard>
            <MyCard>
              <CardHeaderDetail title="Base de données en ligne" />
              <Divider />
              <Table>
                <TableBody>
                  <TableRow>
                     <TableCell sx={tableCell2}>{labelConfig.databankAccessType}</TableCell>
                     <TableCell>
                      <Typography variant='body2' sx={{color:'text.secondary'}}>
                        {getAccessType(databank.databankAccesses)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                     <TableCell sx={tableCell2}>{labelConfig.databankLinkType}</TableCell>
                     <TableCell>
                      <Typography variant='body2' sx={{color:'text.secondary'}}>
                        {remoteAccess ? DatabankLinkTypeList[remoteAccess.linkType] : DatabankLinkTypeList[libraryAccess?.linkType]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={tableCell2}>{getUrlTitle(remoteAccess?.linkType)}</TableCell>
                    <TableCell>
                      <Link 
                        underline="none"
                        href={remoteAccess?.proxy?.url ? `${remoteAccess.proxy.url}${remoteAccess?.link}`:`${remoteAccess?.link}`} 
                        sx={{ ...link, color:'text.secondary' }} 
                        variant='body2'
                        rel='noopener noreferrer' 
                        target='_blank'
                      >
                        {remoteAccess?.link}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={tableCell2}>{labelConfig.urlLibrary}</TableCell>
                    <TableCell>
                      <Link 
                        underline="none"
                        href={libraryAccess?.link} 
                        sx={{ ...link, color:'text.secondary' }} 
                        variant='body2'
                        rel='noopener noreferrer' 
                        target='_blank'
                      >
                        {libraryAccess?.link}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={tableRow}>
                     <TableCell sx={tableCell2}>{labelConfig.ezproxyCollName}</TableCell>
                     <TableCell>
                      <Typography variant='body2' sx={{color:'text.secondary'}}>
                        {remoteAccess?.ezproxyCollName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MyCard>
            <Box m={2}>
              { 
                databank.appType === DatabankAppType.Online && remoteAccess && (
                  <Subscriptions 
                    listType={ListType.Subscription}
                    data={subscriptions.data}
                    lists={databank.subscriptions.map((subscription: SubscriptionItemResource) => subscription.id)}
                    isAuthorize={subscriptions.isAuthorize}
                  />
                ) 
              }
              {databank.appType === DatabankAppType.Online && !remoteAccess && libraryAccess && getLibraries(databank)}
            </Box>
          </MyCard>
        </Grid>
    </Grid>
  );
};

RemoteAccessInfo.propTypes = {
  databank: PropTypes.object.isRequired,
  subscriptions: PropTypes.exact({
    data: PropTypes.array, 
    isAuthorize: PropTypes.bool,
  }).isRequired
};

export default RemoteAccessInfo;
