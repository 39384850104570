import React, { useEffect } from 'react';
import type { FC } from 'react';
import {Box,Container} from '@mui/material';
import {AccessModel,SearchEngineModel,root} from 'src/common';
import Page from 'src/components/Page';
import CoveoLogsHome from 'src/components/coveo/CoveoLogs';
import GenericHeader from 'src/components/header/GenericHeader';

const CoveoLogsListView: FC = () => {
  const model = new SearchEngineModel();
  const access = new AccessModel(model.SearchIndexResourceCode);
  model.HeaderTitle = "Liste des logs";
  
  useEffect(() => {
    if(access.canRead()){
      model.PathName = location.pathname;
    }
  },[]);
 
  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
          <GenericHeader model={model} headerTitle={model.HeaderTitle} />
          <Box mt={3}>
            <CoveoLogsHome 
              maxCount={20}
              headCell={model.CoveoLogsHomeHeadCells}
            />
          </Box>
      </Container>
    </Page>
  );
};

export default CoveoLogsListView;
