import { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  TextField, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import {
  FormikErrors,
  FormikTouched,
  CaijCard,
  getUrlTitle,
  DatabankModel,
  CaijInput,
  CaijCheckbox,
  dialogTitle,
  DatabankLinkTypeList,
  CaijInputLink
} from 'src/common';
import { DatabankAccessResource, DatabankResource, KnownDatabankAccessType, KnownDatabankLinkType } from 'src/common/types';
import CaijDialogs from 'src/components/dialog';
import { BtnOk } from 'src/components/dialog/CaijDialog';

export interface LibraryAccessLinkFormProps {
  databank: DatabankResource;
  errors?: FormikErrors<DatabankResource>;
  touched?: FormikTouched<DatabankResource>;
  handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disableBtnSubmit: (value:boolean) => void;
}

const LibraryAccessLinkForm: FC<LibraryAccessLinkFormProps> = ({
  databank,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  disableBtnSubmit
}) => {
  const chatUrl = 'https://lc.chat/now/8855344';
  const model = DatabankModel.getInstance();
  const [openDialogProxy, setOpenDialogProxy] = useState(false);
  const [openDialogAccesses, setOpenDialogAccesses] = useState(false);
  const remoteAccess = databank?.databankAccesses.find(a => a.accessType === KnownDatabankAccessType.Remote)
  const libraryAccess = databank?.databankAccesses.find(a => a.accessType === KnownDatabankAccessType.Library)
  const libraryAccessDefaultUrl = libraryAccess && remoteAccess?.linkType === KnownDatabankLinkType.Direct;

  const handleChangeDatabankAccessType = (e) => {
    if(!e.target.checked){
      const result = databank.databankAccesses.filter(a =>a.accessType !== e.target.value)
      setFieldValue('databankAccesses', result)
    }else{
      const newDatabankAccess:DatabankAccessResource =  {accessType:e.target.value} 
      const result:DatabankAccessResource[] = [...databank.databankAccesses, newDatabankAccess]
      setFieldValue('databankAccesses', result)
      if(result.length > 1){
        setOpenDialogAccesses(true)
      }
    }
  }

  const handleChangeAccess = (e) => {
    const { name, value } = e.target
    const splittedName = name.split('.')
    const accessType = splittedName[0];
    const objProp = splittedName[1];
    const {databankAccesses} = databank;
    const accessIndex = databankAccesses.findIndex(a => accessType === a.accessType)
    if(accessIndex > -1){
      databankAccesses[accessIndex][objProp] = value;
      //Gérer le lien pour le clavardage
      if(accessType == KnownDatabankAccessType.Remote && objProp === 'linkType'){
        if(value === KnownDatabankLinkType.Clavardage){
          databankAccesses[accessIndex].link = chatUrl;
        }else{
          databankAccesses[accessIndex].link = '';
        }
      }
      //Gérer le lien direct par défaut si la BD est À distance et En bibliothèque
      if(databankAccesses.length > 1){
        const remoteAccessIndex = databankAccesses.findIndex(a => a.accessType === KnownDatabankAccessType.Library);
        if(objProp === 'linkType' && value === KnownDatabankLinkType.Direct){
          databankAccesses[remoteAccessIndex].link = databankAccesses[accessIndex].link
        }else if(databankAccesses[accessIndex].linkType === KnownDatabankLinkType.Direct && objProp === 'link'){
          databankAccesses[remoteAccessIndex].link = value; 
        }
      }
      //Réinitialiser le champ ezproxy
      if(objProp === 'linkType' && value !== KnownDatabankLinkType.Caij)
      {
        databankAccesses[accessIndex].ezproxyCollName = '';
      }
      setFieldValue('databankAccesses', databankAccesses)
    }
    //Gérer l'affichage de l'avis pour les proxy
    if(objProp === 'linkType' && (value === KnownDatabankLinkType.Caij || value === KnownDatabankLinkType.Bibliomagis)){
      model.Dialog.Header = 'CONFIGURATION DE PROXY!';
      model.Dialog.BtnText = BtnOk.PROXY;
      setOpenDialogProxy(true)
    }
  }

  return (
      <CaijCard title={'Configuration des accès'} sx={{mt:2}} allowedBackgroundColor={false}>
       <Box display='flex' alignItems='center'>
          <CaijCheckbox
            name={`${KnownDatabankAccessType.Remote}.accessType`}
            label='À distance'
            value={KnownDatabankAccessType.Remote}
            checked={remoteAccess ? true:false}
            onHandleChangeCheckBox={handleChangeDatabankAccessType}
            sx={{flexShrink:0, minWidth:150}}
            inputProps={{'data-testid': `${KnownDatabankAccessType.Remote}.accessType`}}
          />
          <TextField
            label='Type de lien'
            disabled={remoteAccess ? false:true}
            name={`${KnownDatabankAccessType.Remote}.linkType`}
            select
            SelectProps={{ value: remoteAccess?.linkType ? remoteAccess?.linkType:KnownDatabankLinkType.None, native: true }}
            variant='outlined'
            onChange={handleChangeAccess}
            sx={{minWidth:200}}
            inputProps={{'data-testid': `${KnownDatabankAccessType.Remote}.linkType`}}
          >
            {Object.entries(DatabankLinkTypeList).map((result) => <option key={result[0]} value={result[0]}>{result[1]}</option>)}
          </TextField>
          {remoteAccess?.linkType == KnownDatabankLinkType.Caij && <CaijInput
            name={`${KnownDatabankAccessType.Remote}.ezproxyCollName`}
            label='Ezproxy'
            value={remoteAccess?.ezproxyCollName}
            onHandleBlur={handleBlur}
            onHandleChange={handleChangeAccess}
            sx={{maxWidth:200, marginLeft:'1em', marginTop: '30px'}}
            inputAttr={{'data-testid': `${KnownDatabankAccessType.Remote}.ezproxyCollName`}}
            />}
          <CaijInputLink
            name={`${KnownDatabankAccessType.Remote}.link`}
            label={getUrlTitle(remoteAccess?.linkType)}
            disabled={remoteAccess && remoteAccess.linkType != KnownDatabankLinkType.Clavardage ? false:true}
            value={remoteAccess?.link}
            onHandleBlur={handleBlur}
            onHandleChange={handleChangeAccess}
            sx={{marginTop: '30px', marginLeft:'1em'}}
            inputProps={{'data-testid': `${KnownDatabankAccessType.Remote}.link`}}
            />
        </Box>
        <Box display='flex' alignItems='center'>
          <CaijCheckbox
            name={`${KnownDatabankAccessType.Library}.accessType`}
            label='En bibliothèque'
            value={KnownDatabankAccessType.Library}
            checked={libraryAccess ? true:false}
            onHandleChangeCheckBox={handleChangeDatabankAccessType}
            sx={{flexShrink:0, minWidth:150}}
            inputProps={{'data-testid': `${KnownDatabankAccessType.Library}.accessType`}}
            />
          <CaijInputLink
            name={`${KnownDatabankAccessType.Library}.link`}
            label='URL'
            disabled={libraryAccess && !libraryAccessDefaultUrl ? false:true}
            value={libraryAccess?.link}
            onHandleBlur={handleBlur}
            onHandleChange={handleChangeAccess}
            sx={{marginTop: '30px'}}
            inputProps={{'data-testid': `${KnownDatabankAccessType.Library}.link`}}
          />
        </Box>
        <CaijDialogs
          dialog={model.Dialog}
          isOpen={openDialogProxy}
          onSubmit={() => (async () => {
            setOpenDialogProxy(false);
            disableBtnSubmit(false);
          })()}
          close={() => {
            setOpenDialogProxy(false);
            disableBtnSubmit(true);
          }}
        />
        <Dialog 
          open={openDialogAccesses}
          onClose={()=>setOpenDialogAccesses(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle sx={dialogTitle} id="alert-dialog-title">
                {"BASE DE DONNÉE ACCESSIBLE POUR TOUS EN BIBLIOTHÈQUE"}
          </DialogTitle>
          <DialogContent sx={{backgroundColor: 'background.paper', paddingBottom: 0}} dividers>
            <div style={{fontSize:"0.875rem"}}>
              <p>En sélectionnant "À distance" <span style={{fontWeight:600}}>ET</span> "En bibliothèque", c'est que vous désirez que cette base de données soit accessible:</p>
              <ul style={{paddingLeft:'2em', paddingTop:10}}>
                <li>À distance pour les membres qui possèdent un abonnement autorisé;</li>
                <li>En bibliothèque pour tous les membres qui possèdent une carte CAIJ.</li>
              </ul>
              <p style={{paddingTop:10, paddingBottom:15}}>Si ce n'est pas le comportement souhaité, veuillez choisir seulement une des deux options.</p>
            </div>
          </DialogContent>
          <DialogActions sx={{backgroundColor: 'background.paper', paddingTop: 2,flexDirection:'row', justifyContent:"center"}}>
            <Button
              type='reset'
              onClick={()=>setOpenDialogAccesses(false)}
              variant='outlined'
              sx={{marginBottom: '10px'}}
            >
              J'ai compris
            </Button>
          </DialogActions>
        </Dialog>
      </CaijCard>
  );
};

LibraryAccessLinkForm.propTypes = {
  databank: PropTypes.object.isRequired,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disableBtnSubmit: PropTypes.func
};

export default LibraryAccessLinkForm;
