import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import type { SubscriptionResource } from 'src/common/types';
import { Box,Container } from '@mui/material';
import {
  useLocation,
  useParams,
  fetchSubscription,
  _subscription,
  SbGroup,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import {$enum} from "ts-enum-util";
import Page from 'src/components/Page';
import SubscriptionModel from 'src/model/Subscription';
import SubscriptionEditForm from './SubscriptionEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { DoorAccessProfileProvider } from 'src/contexts/DoorAccessProfileContext';
import { UxpertiseProvider } from 'src/contexts/UxpertiseContext';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  isLoading: false,
  subscription: {}
};

const SubscriptionEditView: FC = () => {
  const model = SubscriptionModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_subscription, initialState);
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new SubscriptionModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const subscription = await model.getSubscriptionById(id);
          if (!model.error) {
            if(!subscription.contact.address){
              dispatch(fetchSubscription({ ...subscription, contact: { address: { 
                line1:'',
                line2: '', 
                city: '', 
                zip: '', 
                state: '', 
                country: '' 
              }}},true));
            }else{
              dispatch(fetchSubscription(subscription, true));
            } 
          }
        }else{
          model.PathName = pathName;
          const data: SubscriptionResource = {
            contact: { 
              name: '', 
              email: '', 
              phone: '',
              address: {
                line1: '', 
                line2: '', 
                city: '', 
                zip: '', 
                state: '', 
                country: '' 
              }
            },
            group: $enum(SbGroup).getKeyOrDefault(SbGroup.B2B),
            subscriptionGroup: {
              accountRenewMode: '',
              accountExpiryMode: '',
              accountExpiry: {
                expireOn: {
                  day: 0,
                  month: 0
                }
              }
            },
            products: [],
            databanks: [],
            documentCollections: []
          };
          dispatch(fetchSubscription(data, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { subscription, isLoading } = state;

  if (!isLoading){
    return <LoadingScreen />
  }  

  model.Title = subscription.name;

  return (
    <Page sx={root} title={model.getEditPageTitle(id)}>
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={2}>
          <DoorAccessProfileProvider>
            <UxpertiseProvider>
              <SubscriptionEditForm
                model={model}
                subscription={subscription}
              />
            </UxpertiseProvider>
          </DoorAccessProfileProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default SubscriptionEditView;
