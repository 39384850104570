import { Box, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useState } from 'react';
import {
  Authorize,
  btnSubmit,
  CaijButtonReset,
  CaijButtonSubmit,
  Formik,
  handleChange,
  LawPracticeFieldModel,
  Mode
} from 'src/common';
import type { 
  LawPracticeFieldResource, 
  LawPracticeFieldForCreate, 
  LawPracticeFieldForEdit ,
  Error,
  CreateResponse
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import LawPraticeFieldForm from 'src/components/lawPraticeField/Forms';
import validateSchema from '../Schema';

export interface LawPracticeFieldEditFormProps {
  model: LawPracticeFieldModel;
  lawPracticeField: LawPracticeFieldResource;
  onSubmit?: (values: LawPracticeFieldResource) => void;
}

async function redirect(model: LawPracticeFieldModel, response: Error | CreateResponse) : Promise<void> {
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message,
  });
};

const LawPraticeFieldEditForm: FC<LawPracticeFieldEditFormProps> = ({
  model,
  lawPracticeField,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const handleSubmit = async (values: LawPracticeFieldResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LawPracticeFieldModel();
    let submitData = values as LawPracticeFieldForEdit;
    if (submitData && submitData.id) {
      // const customerIds = lawDomain.customerLawDomains.map((customerLawDomain: CustomerLawDomains) => customerLawDomain.customerId);
      // submitData.customerLawDomains = customerIds;
      const response = await model.updateLawPracticeField(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as LawPracticeFieldForCreate;
      delete submitData.id;
      const response = await model.insertLawPracticeField(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <Formik
      initialValues={LawPracticeFieldModel.getInitialValues(lawPracticeField)}
      validationSchema={validateSchema(model, lawPracticeField)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <LawPraticeFieldForm 
                model={model}
                lawPraticeField={values}
                errors={errors}
                touched={touched}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={values.id ? Mode.edit : Mode.add}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

LawPraticeFieldEditForm.propTypes = {
  lawPracticeField: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

LawPraticeFieldEditForm.defaultProps = {
  onSubmit: null
};

export default LawPraticeFieldEditForm;
