import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  fetchEmployee,
  _employee,
  EmployeeModel,
  EmployeeRole,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import type { EmployeesResource } from 'src/common/types';
import Page from 'src/components/Page';
import EmployeeEditForm from './EmployeeEditForm';
import { EmployeeProvider } from 'src/contexts/EmployeeContext';
import { ResourceProvider } from 'src/contexts/ResourceContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

interface stateProps {
  employee: EmployeesResource;
  isLoading: boolean;
}

const initialState: stateProps = {
  isLoading: false,
  employee: {
    role: EmployeeRole.User
  }
}

const EmployeeEditView: FC = () => {
  const model = EmployeeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_employee, initialState);
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if (access.canEdit(id)){
        const model = new EmployeeModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const employee = await model.getEmployeeById(id);
          if (!model.error) {
            dispatch(fetchEmployee({...initialState.employee, ...employee}, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchEmployee(initialState.employee, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { employee, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = `${employee.firstname} ${employee.lastname}`;

  return (
    <Page sx={root} title={model.getEditPageTitle(id)}>
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <EmployeeProvider>
            <ResourceProvider>
              <EmployeeEditForm model={model} employee={employee} />
            </ResourceProvider>
          </EmployeeProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default EmployeeEditView;
