import { FC, useState } from "react";
import { Authorize, CaijButton, Mode, useNavigate } from "src/common";
import AppModel from "src/model/App";

interface HeaderButtonProps {
    model?: AppModel;
    label?: string;
    mode?: Mode;
}

const HeaderAddButton: FC<HeaderButtonProps> = ({ model, label, mode}) => {
    const navigate = useNavigate();
    let [isAuth, setIsAuth] = useState<boolean>(false);
    return (
        <Authorize
            resourceCode={model.ResourceCode}
            mode={mode}
            onIsAuth={l => setIsAuth(l)}>
            <CaijButton
                type='add'
                color='secondary'
                variant='contained'
                disabled={!isAuth}
                onHandleClick={() => navigate(model.Path.Create)}
            >
                { label? label : model.BtnAddText }
            </CaijButton>
        </Authorize>
    )
}

export default HeaderAddButton;