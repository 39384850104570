import React, {
  useState,
  useRef
} from 'react';
import type { FC } from 'react';
import {
  Badge,
  Box,
  Button,
  IconButton,
  MenuItem,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { SETTINGS, THEMES } from 'src/constants';
import { type Theme } from 'src/theme';
import { makeStyles } from 'tss-react/mui';
import { CaijTooltip } from 'src/common';

const useStyles = makeStyles()((theme) => {
  return {
    badge: {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginTop: 10,
      marginRight: 5
    },
    popover: {
      width: 320,
      padding: theme.spacing(2),
    }
  }
});

const popover = {
  '& .MuiPopover-paper': { 
    background: (theme: Theme) => theme.palette.background.paper
  }
};

const Settings: FC = () => {
  const { classes } = useStyles();
  const ref = useRef<any>(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <CaijTooltip title={SETTINGS}>
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </CaijTooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        sx={popover}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h6"
          sx={{color:"text.primary"}}
        >
          {SETTINGS}
        </Typography>
        { /*
            <Box
              mt={1}
              px={1}
            >
              <FormControlLabel
                control={(
                  <Switch
                    checked={values.responsiveFontSizes}
                    edge="start"
                    name="direction"
                    onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
                  />
                )}
                label="Responsive font sizes"
              />
            </Box> 
          */
        }
        <Box mt={3}>
          <TextField
            fullWidth
            label="Thème"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <MenuItem
                key={theme}
                value={theme}
              >
                {theme === THEMES.ONE_DARK ? 'SOMBRE' : 'CLAIR'}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Sauvegarde {SETTINGS}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
