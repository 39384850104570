import React from 'react';
import type { FC, ReactNode, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { CheckSquare as SaveIcon } from 'react-feather';

interface CaijLoadingButtonProps {
  type: "button" | "reset" | "submit";
  disabled?: boolean;
  children?: ReactNode;
  loading: boolean;
  loadingPosition: "start" | "end" | "center";
  onHandleClick?: (event?: ChangeEvent<any>) => void;
  sx?:any;
  color?: "primary" | "secondary" | 'success' | 'error' | 'info' | 'warning'| 'inherit';
  variant?: "outlined" | "contained";
}

const CaijLoadingButtonSave: FC<CaijLoadingButtonProps> = ({
  children,
  type,
  onHandleClick,
  ...rest
}) => {
  return (
    <LoadingButton
      type={type}
      {...rest} 
      startIcon={<SaveIcon />}
      onClick={(e) => onHandleClick(e)}
    >
      {children}
    </LoadingButton>
  );
}

CaijLoadingButtonSave.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onHandleClick: PropTypes.func,
  sx:PropTypes.any,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  color: PropTypes.oneOf(['primary', 'secondary', 'success' , 'error' , 'info' , 'warning', 'inherit']),
  variant: PropTypes.oneOf(["outlined", "contained"])
};

CaijLoadingButtonSave.defaultProps = {
  disabled: false,
  color: 'primary',
  variant: 'contained',
  type: 'button',
  onHandleClick: () => {},
};

export default CaijLoadingButtonSave;
