import type { FC } from 'react';
import { Box, ListItemText, Typography } from '@mui/material';
import Label from './Label';
import { DatabankAppType, labelConfig } from 'src/common';

interface CaijListItemTextProps {
  name: string;
  active?: boolean;
  color: 'primary' | 'secondary';
  allowedAccessType?: boolean;
  accessType?: string;
  appType?: string;
}

const InactiveLabel = (props: {active: boolean}) => !props.active && (
  <Box sx={{mr:1}}>
    <Label color={'error'}>
      { labelConfig.inactive }
    </Label>
  </Box>
);

const AppType = (props: {appType: string}) => {
  let text: string;
  if(props.appType == DatabankAppType.Online)
    text = 'À distance';
  else if(props.appType == DatabankAppType.Desktop)
    text = 'En bibliothèque';
  else
    text = 'À distance / En bibliothèque';
  return (
    <Label color={'success'}>
      { text }
    </Label>
  )
}

const CaijListItemText: FC<CaijListItemTextProps> = ({
  name,
  active,
  color,
  allowedAccessType,
  accessType,
  appType
}) => {
  if(allowedAccessType){
    return (
      <ListItemText 
        primary={
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Typography variant="body2" color={`text.${color}`} sx={{mr:1}}>
              {name} - <span style={{fontStyle:'italic'}}>{accessType}</span>
            </Typography>
            <InactiveLabel active={active} />
          </Box>
        }
      />
    )
  }else {
    return (
      <ListItemText 
        primary={
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Typography color={`text.${color}`} variant="body2" sx={{mr:1}}>{name}</Typography>
            <InactiveLabel active={active} />
            {appType && <AppType appType={appType} />}
          </Box>
        }
      />
    );
  }
}

CaijListItemText.defaultProps = {
  active: true,
  allowedAccessType: false
}

export default CaijListItemText;
