import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useNavigate,
  _doorAccessProfile,
  DoorAccessProfileModel,
  fetchDoorCardProfiles,
  AccessModel,
  root
} from 'src/common';
import { 
  DoorCardProfileDto, 
  CreateResponse, 
  Error
} from 'src/common/types';
import Page from 'src/components/Page';
import Results from './Results';
import printMessage from 'src/views/errors/MessageError';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  doorCardProfiles: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false
};

const getMessage = (model: DoorAccessProfileModel, response: Error | CreateResponse) : boolean => {
  if (!model.error) {
    printMessage({
      status: response.status,
      message: response.message
    });
    return true
  }
  return false;
}

const DoorAccessProfileListView: FC = () => {
  const model = DoorAccessProfileModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_doorAccessProfile, initialState);
  
  const getDoorAccessProfileList = useCallback(async () : Promise<void> => {
    model.PathName = location.pathname;
    const doorCardProfiles = await model.getDoorCardProfiles();
    doorCardProfiles.sort((a:DoorCardProfileDto,b:DoorCardProfileDto) => a?.name?.localeCompare(b?.name));
    if (!model.error) {
      dispatch(fetchDoorCardProfiles(doorCardProfiles, true,!Array.isArray(doorCardProfiles) || doorCardProfiles.length === 0, true));
    }else model.resetError();
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname} = location;
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            let doorCardProfiles = await model.getDoorCardProfiles();
            doorCardProfiles.sort((a:DoorCardProfileDto,b:DoorCardProfileDto) => a?.name?.localeCompare(b?.name));
            if (!model.error) {
              const { Filters: { query } } = model;
              const searchTerm = model.getQueryParam(query).toLowerCase();
              if(searchTerm){
                doorCardProfiles = doorCardProfiles.filter(({name}) => name && name.toLowerCase().search(searchTerm) >= 0);
                dispatch(fetchDoorCardProfiles(doorCardProfiles, true,!Array.isArray(doorCardProfiles) || doorCardProfiles.length === 0, true));
              }else{
                dispatch(fetchDoorCardProfiles(doorCardProfiles, true,!Array.isArray(doorCardProfiles) || doorCardProfiles.length === 0, true));
              }
            }else model.resetError();
          }
        }else{
          await getDoorAccessProfileList();
        }
      }
    })();
  }, [location.search]);
  
  const deActivate = async (id: number, name: string) : Promise<void> => {
    const response = await model.deleteDoorAccessProfile(id, name);
    if(!model.error){
      getMessage(model, response as Error);
      const doorCardProfiles = state.doorCardProfiles.filter((doorCardProfile: DoorCardProfileDto) => {
        if(doorCardProfile.id === id) doorCardProfile.activated = false;
        return doorCardProfile; 
      });
      dispatch(fetchDoorCardProfiles(doorCardProfiles, true,!Array.isArray(doorCardProfiles) || doorCardProfiles.length === 0, true));
    }else model.resetError();
  };

  const activate = async (doorCardProfile: DoorCardProfileDto) : Promise<void> => {
    const response = await model.insertDoorAccessProfile(doorCardProfile);
    if(!model.error){
      getMessage(model, response as CreateResponse);
      const doorCardProfiles = state.doorCardProfiles.filter((item: DoorCardProfileDto) => {
        if(item.id === doorCardProfile.id) item.activated = true;
        return item; 
      });
      dispatch(fetchDoorCardProfiles(doorCardProfiles, true,!Array.isArray(doorCardProfiles) || doorCardProfiles.length === 0, true));
    }else model.resetError();
  };

  const { doorCardProfiles, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            doorCardProfiles={doorCardProfiles}
            isEmptyList={isEmptyList}
            onDeactivate={deActivate}
            onActivate={activate}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DoorAccessProfileListView;
