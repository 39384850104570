import type { CreateResponse, DocumentOptionResource, DocumentResourceForCreate, DocumentResourceForEdit, Error, GetDocumentListAsyncOptionalParams } from "src/common/types";
import AppModel from "../App";
import axios from "src/utils/axios";
import { Created, MSG_NO_CONTENT_ERROR, NoContent, Success } from "src/common";
import { FormModel } from "../Form";
import printMessage from "src/views/errors/MessageError";
import { PagedList } from "src/types/pagination";

export default class SpecialFilesModel extends AppModel {
    private static _instance: SpecialFilesModel;
    //readonly Name = new FormModel('Name', 'Nom', 100);
    readonly Published = new FormModel('published', 'Date de publication', 100);
    readonly Archived = new FormModel('Archived', 'Archivé', 100)
    readonly Status = new FormModel('Status', 'Statut', 100);
    readonly Filepath = new FormModel('filepath', 'Chemin du fichier', 1024);
    readonly Titre = new FormModel('title', 'Titre', 500);
    readonly Identifier = new FormModel('identifier', 'Identifiant', 100);
    readonly Cover = new FormModel('cover', 'Image', 2000);
    readonly Description = new FormModel('description', 'Description', 3000);
    readonly Content = new FormModel('content', 'Contenu', Infinity);
    constructor() {
        super('/content/document')
        this.initialize();
    }

    private initialize() {
        this._resourceCode = "SPECIAL_FILES";
        this._headerTitle = "Liste des dossiers spéciaux";
        this._btnAddText = "Ajouter un dossier spécial";
        this.Path.PathName = "/contents/special-files";
    }

    get Section() {
        return {
            resourceCode: this.ResourceCode,
            title: "Dossiers spéciaux",
            href: this.Path.Home,
            visible: true
        }
    }

    get DocumentId() {
        return "Document ID";
    }

    getHeadCells() {
        return [
            {
                id: this.Titre.Name, width: '50%', numeric: false, disablePadding: false, label: this.Titre.Label
            },
            {
                id: this.Published.Name, width: '15%', numeric: false, disablePadding: false, label: this.Published.Label
            },
            {
                id: '', width: '15%', numeric: false, disablePadding: false, label: this.Archived.Label
            },
            {
                id: '', width: '15%', numeric: false, disablePadding: false, label: this.Status.Label
            },
            {
                id: '', width: '5%'
            }
        ]
    }

    static getInstance(): SpecialFilesModel {
        if (!SpecialFilesModel._instance) {
            SpecialFilesModel._instance = new SpecialFilesModel();
        }
        return SpecialFilesModel._instance
    }

    async getSpecialFiles(params: GetDocumentListAsyncOptionalParams): Promise<PagedList<DocumentOptionResource>> {
        let pagedList: PagedList<DocumentOptionResource>;
        await axios.get<PagedList<DocumentOptionResource>>(`${this.route}/documents`, { params }).then(
            async (response) => {
                const { status, data } = response;
				if (status === Success) {
					pagedList = data;
				}
            },
            async (error) => {
                this.error = error;
                await this.handleError(this.error);
            },
        );
        return pagedList;
    }

    async createSpecialFile(submitData: DocumentResourceForCreate): Promise<CreateResponse> {
        let result: CreateResponse;
        await axios.post<DocumentResourceForCreate, CreateResponse>(this.route, submitData).then(async (response) => {
            const { status } = response;
            if (status === Created) {
                result = response;
                result.message = 'Dossier spécial créé.';
            }
        }, async (error) => {
            this.error = error;
            await this.handleError(this.error);
        })
        return result;
    }

    async updateDocument(submitData: DocumentResourceForEdit): Promise<Error> {
        let result: Error;
        await axios
            .put<DocumentResourceForEdit, Error>(`${this.route}/info?doc=${encodeURIComponent(submitData.documentId)}`, submitData)
            .then(
                async (response) => {
                    const { status } = response;
                    try {
                        if (status === Success) {
                            result = response;
                            result.message = 'Document modifié.';
                        } else if (status === NoContent) {
                            throw new Error(MSG_NO_CONTENT_ERROR);
                        }
                    } catch (ex) {
                        this.error = { status, message: ex.message };
                        await this.handleError(this.error);
                    }
                },
                async (error) => {
                    this.error = error;
                    await this.handleError(this.error);
                },
            );
        return result;
    }

    async deleteDocument(id: string, allowedRedirect: boolean = false): Promise<boolean> {
        let success: boolean = false;
        await axios.delete<Error>(`${this.route}/${id}`).then(
            async (response) => {
                const { status } = response;
                if (status === Success) {
                    printMessage({
                        status: status,
                        message: 'Dossier spécial supprimé.'
                    });
                    if (allowedRedirect)
                        this.redirect(this.Path.Home);
                    success = true;
                }
            },
            async (error) => {
                this.error = error;
                await this.handleError(this.error);
            },
        );
        return success;
    }
}