import type { FC } from 'react';
import CaijTypography from 'src/components/typography';

const SplitName: FC<{lastName: string, firstName: string}> = props => (
  <>
    <CaijTypography variant='body2' color="text.secondary">Nom de famille : {props.lastName}</CaijTypography>
    <CaijTypography variant='body2' color="text.secondary">Prénom : {props.firstName}</CaijTypography>
  </>
);

export default SplitName;