import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  CaijDialog,
  DialogModel
} from 'src/common';
import { BtnOk } from './CaijDialog';

interface CaijDialogsProps {
  dialog?: DialogModel;
  disabled?: boolean;
  isOpen: boolean;
  startDate?: string;
  endDate?: string;
  allowedReadAccessForDeletion?: boolean;
  onSubmit?: () => Promise<void | boolean>;
  onSubmitPublisherMessage?: (value: string) => void;
  onSubmitCustomerEndorsed?: (value: number) => void;
  setSelectedRow? : React.Dispatch<React.SetStateAction<any[]>>;
  close?: () => void;
}

const CaijDialogs: FC<CaijDialogsProps> = ({
  dialog, 
  startDate, 
  endDate, 
  setSelectedRow, 
  close, 
  onSubmit, 
  onSubmitPublisherMessage, 
  onSubmitCustomerEndorsed,
  ...rest
}) => {
  if(dialog.BtnText === BtnOk.PUBLIER)
    return (
      <CaijDialog
        {...rest}
        header={dialog?.Header}
        btnText={dialog?.BtnText}
        body={dialog?.Body}
        name={dialog?.Name}
        startDate={startDate}
        endDate={endDate}
        resourceCode={dialog?.ResourceCode}
        onSubmitPublisherMessage={(value: string) => dialog.submitPublisherMessage(value, setSelectedRow, close, onSubmitPublisherMessage)}
      />
    );
  else
    return (
      <CaijDialog
        {...rest}
        header={dialog?.Header}
        btnText={dialog?.BtnText}
        body={dialog?.Body}
        endorser={dialog?.Endorser}
        name={dialog?.Name}
        resourceCode={dialog?.ResourceCode}
        onSubmit={(value: boolean) => dialog.submit(value, setSelectedRow, close, onSubmit)}
        onSubmitCustomerEndorsed={(value: number) => dialog.submitCustomerEndorsed(value, close, onSubmitCustomerEndorsed)}
      />
  );
};
CaijDialogs.propTypes = {
  dialog: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onSubmit: PropTypes.func,
  onSubmitPublisherMessage: PropTypes.func,
  onSubmitCustomerEndorsed: PropTypes.func,
  setSelectedRow: PropTypes.func,
  close: PropTypes.func
};

CaijDialogs.defaultProps = {
  setSelectedRow: null
}

export default CaijDialogs;
