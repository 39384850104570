import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useNavigate,
  EmailTemplateModel,
  _emailTemplate,
  fetchEmailTemplates,
  AccessModel,
  deleteEmailTemplate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  emailTemplates: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false
};

const EmailTemplateListView: FC = () => {
  const access = new AccessModel(EmailTemplateModel.getInstance().ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_emailTemplate, initialState);
  const navigate = useNavigate();

  const getEmailTemplates = useCallback(async (model: EmailTemplateModel) : Promise<void> => {
    model.PathName = location.pathname;
    const emailTemplates = await model.getEmailTemplates();
    if (!model.error) {
      dispatch(fetchEmailTemplates(emailTemplates, true, !Array.isArray(emailTemplates) || emailTemplates.length === 0, true, model));
    }
  },[location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        let { search, pathname} = location;
        const model = new EmailTemplateModel();
        if(search){
          model.PathName = pathname + search;
          model.Navigate = navigate;
          if(await model.setQueryParams(search, model, navigate)){
            let emailTemplates = await model.getEmailTemplates();
            if (!model.error) {
              const { Filters: { query } } = model;
              const searchTerm = model.getQueryParam(query).toLowerCase();
              emailTemplates = emailTemplates.filter(({code, name}) => code?.toLowerCase().search(searchTerm) >= 0 || name?.toLowerCase().search(searchTerm) >= 0);
              dispatch(fetchEmailTemplates(emailTemplates, true, !Array.isArray(emailTemplates) || emailTemplates.length === 0, true, model));
            }
          }
        }else{
          await getEmailTemplates(model);
        }
      }
    })();
  }, [location.search]);

  const handleDelete: (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteEmailTemplate(id));
    }
  };

  const { emailTemplates, isEmptyList, isLoading, model } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            emailTemplates={emailTemplates}
            isEmptyList={isEmptyList}
            onDeleteEmailTemplate={handleDelete}
            model={model}
            location={location}
            navigate={navigate}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default EmailTemplateListView;
