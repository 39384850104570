import { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
import {
  useLocation,
  AccessModel,
  _document,
  fetchDocuments,
  useNavigate,
  ITEMS_PER_PAGE,
  MSG_FORBIDEN_ERROR,
  root,
} from 'src/common';
import type { GetDocumentListAsyncOptionalParams} from 'src/common/types';
import DocumentModel from 'src/model/content/Document';
import Page from 'src/components/Page';
import Results from './Results';
import useCollection from 'src/functions/hooks/collection';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  documents: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false,
  model: new DocumentModel()
};

const DocumentListView: FC = () => {
  const collection  = useCollection();
  const access = new AccessModel(DocumentModel.getInstance().ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_document, initialState);
  const navigate = useNavigate();

  const getDocuments = useCallback(async (model:DocumentModel, documentParams: GetDocumentListAsyncOptionalParams) : Promise<void> => {
    if(access.canRead()){
      model.PathName = location.pathname;
      const results = await model.getDocuments(documentParams);
      if (!model.error) {
        dispatch(fetchDocuments(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0, true, model));
      }
    }
  }, [location]);

  const getCollection = useCallback((value : string) : JSX.Element => {
    const { isLoading, isAuthorize, docCollections } = collection;
    if(isLoading && !isAuthorize){
      return (
        <Typography variant='body2' sx={{color:'text.error'}}>
          {MSG_FORBIDEN_ERROR}
        </Typography>
      ) 
    }
    return (
      <Typography variant='body2' sx={{color:'text.secondary'}}>
        {docCollections ? docCollections.find(({code}) => code === value.toLowerCase())?.nameFr : ''}
      </Typography>
    )
  },[collection]);

  const getCollectionList = useCallback(() : JSX.Element[] => {
    const { isLoading, isAuthorize, docCollections } = collection;
    if(isLoading && isAuthorize && docCollections){
      return docCollections.map(({code, nameFr}) => (
        <option key={nameFr} value={code}>
          {nameFr}
        </option>
      )) 
     }   
  },[collection]);

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canRead()){
        const { pathname, search } = location;
        let params: GetDocumentListAsyncOptionalParams = {pageNumber: 1, pageSize: ITEMS_PER_PAGE};
        const model = new DocumentModel;
        const urlParams = new URLSearchParams(search);
        if(urlParams.get('docId') || urlParams.get('docTitle') || urlParams.get('collection')){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            const { Filters: { page, docId, docTitle, collection } } = model;
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1, 
              documentId: model.getQueryParam(docId), 
              title: model.getQueryParam(docTitle), 
              collection: model.getQueryParam(collection)
            }
            await getDocuments.apply(null,[model,params]);
          }
        }else if(urlParams.get('page')){
          navigate(model.Path.Home, { replace: true });
        }else{
          dispatch(fetchDocuments(null,null,true,true,true,model));
        }
      }
    };
    (async () => {
      await initialise();
    })();
  }, [location.search]);

  const { documents, isEmptyList, isLoading, paged, model } = state;

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            documents={documents}
            getCollection={getCollection}
            getCollectionList={getCollectionList}
            isAuthorize={collection.isAuthorize}
            isEmptyList={isEmptyList}
            isLoading={isLoading}
            paged={paged}
            model={model}
            navigate={navigate}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocumentListView;
