import * as actionTypes from '../actions/ActionTypes';
import { ReperageResource } from 'src/common/types';
import { Paged } from 'src/types/pagination';

type LoadingReperageAction = {
  type: 'LOADING_REPERAGE';
}

type FetchReperagesAction = {
    type: 'FETCH_REPERAGES';
    payload: {
      reperages: ReperageResource[];
      paged: Paged,
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
}

type FetchReperageAction = {
  type: 'FETCH_REPERAGE';
  payload: {
    reperage: ReperageResource;
    isLoading?: boolean;
  }
};

type Action = FetchReperagesAction |
              FetchReperageAction | 
              LoadingReperageAction;

export interface ReperageReturnState {
  reperages?: ReperageResource[];
  reperage?: ReperageResource;
  paged?: Paged;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface ReperageState {
  reperages: ReperageResource[];
  reperage: ReperageResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  paged: Paged;
}

const reducer = (state: ReperageState, action: Action): ReperageReturnState => {
  switch (action.type) {
    case actionTypes.LOADING_REPERAGE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case actionTypes.FETCH_REPERAGES: {
      const { reperages, paged, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        reperages,
        paged,
        isLoading: true,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_REPERAGE: {
      const { reperage, isLoading } = action.payload;
      return {
        ...state,
        reperage,
        isLoading: true
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

