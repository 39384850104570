import { createContext, useReducer, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Forbidden, Unauthorized, DoorAccessProfileModel } from 'src/common';
import { DoorCardProfileDto } from 'src/common/types';

interface DoorAccessProfileState {
  productId?: number;
  productCode?: string;
  value: string;
  doorCardProfiles: DoorCardProfileDto[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean
};

export interface DoorAccessProfileContextValue extends DoorAccessProfileState{
  handleChange: (value: string) => void;
}

interface DoorAccessProfileProviderProps {
  children: ReactNode;
}

type FetchDoorAccessProfileAction = {
  type: 'FETCH_DOOR_ACCESS_PROFILE';
  payload: {
    productId?: number;
    productCode?: string;
    value?: string;
    doorCardProfiles: DoorCardProfileDto[];
    isAuthorize?: boolean;
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

type HandleChangeDoorAccessProfileAction = {
  type: 'HANDLE_CHANGE_DOOR_ACCESS_PROFILE';
  payload: {
    value: string;
  };
};

type Action = FetchDoorAccessProfileAction 
              | HandleChangeDoorAccessProfileAction;

const initialState : DoorAccessProfileState = {
  productId: null,
  productCode: null,
  value: '',
  doorCardProfiles: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false
}; 

const reducer = (state: DoorAccessProfileState, action: Action): DoorAccessProfileState => {
  switch (action.type) {
    case 'FETCH_DOOR_ACCESS_PROFILE':
      const { doorCardProfiles, isAuthorize, isLoading, isEmptyList } = action.payload;
      return {
        ...state,
        doorCardProfiles,
        isAuthorize,
        isLoading,
        isEmptyList
      };
    case 'HANDLE_CHANGE_DOOR_ACCESS_PROFILE':
        const { value } = action.payload;
        return {
          ...state,
          value
        };
    default:
      return { ...state };
  }
};

const DoorAccessProfileContext = createContext<DoorAccessProfileContextValue>({
  ...initialState,
  handleChange: (value: string) : void => {}
});

export const DoorAccessProfileProvider: FC<DoorAccessProfileProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  const handleChange = (value: string) : void => {
      dispatch({
        type: 'HANDLE_CHANGE_DOOR_ACCESS_PROFILE',
        payload: {
          value
        }
      });
  };

  useEffect(() => {
    const initialise = async () => {
      const model = new DoorAccessProfileModel();
      model.skipHandleError = true;
      let doorCardProfiles = await model.getDoorCardProfiles();
      const { error } = model;
      if(error){
        if (error.status === Forbidden || error.status === Unauthorized) {
         dispatch({
          type: 'FETCH_DOOR_ACCESS_PROFILE',
          payload: {
            doorCardProfiles: [],
            isAuthorize:false,
            isLoading: true,
            isEmptyList: true
          }
        });
        }
      }else {
        doorCardProfiles = doorCardProfiles.sort((a:DoorCardProfileDto,b:DoorCardProfileDto) => a?.name?.localeCompare(b?.name))
                                           .filter(({activated}) => activated);
        dispatch({
          type: 'FETCH_DOOR_ACCESS_PROFILE',
          payload: {
            doorCardProfiles,
            isAuthorize:true,
            isLoading: true,
            isEmptyList: !Array.isArray(doorCardProfiles) || doorCardProfiles.length === 0
          }
        });
      }
    };
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: 'FETCH_DOOR_ACCESS_PROFILE', payload: initialState }) }
  }, []);

  return (
    <DoorAccessProfileContext.Provider
      value={{
        ...state,
        handleChange
      }}
    >
      {children}
    </DoorAccessProfileContext.Provider>
  );

};

DoorAccessProfileProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default DoorAccessProfileContext;
