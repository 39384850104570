import PropTypes from 'prop-types';
import { FC, ReactNode, useReducer, useEffect } from 'react';
import { createContext } from 'react';
import { Forbidden, LawDomainFieldModel, Unauthorized } from 'src/common';
import { LawDomainFieldResource } from 'src/common/types';

export interface LawDomainFieldContextValue {
  setSelectedLawDomainFields: (lawDomainFields: LawDomainFieldResource[]) => void;
  selectedLawDomainFields: LawDomainFieldResource[];
  lawDomainFields: LawDomainFieldResource[];
  isAuthorize: boolean;
  isLoading: boolean;
  isEmptyList: boolean;
}

interface LawDomainFieldProviderProps {
  children: ReactNode;
}

type fetchSelectedLawDomainFieldsAction = {
  type: 'FETCH_SELECTED_LAW_DOMAIN_FIELD';
  payload: {
    selectedLawDomainFields: LawDomainFieldResource[];
  };
};

type fetchLawDomainFieldsAction = {
  type: 'FETCH_LAW_DOMAIN_FIELDS';
  payload: {
    lawDomainFields: LawDomainFieldResource[];
    isAuthorize: boolean;
    isLoading: boolean;
    isEmptyList: boolean;
  };
};

type Action = fetchSelectedLawDomainFieldsAction | fetchLawDomainFieldsAction;

interface LawDomainFieldState {
  selectedLawDomainFields: LawDomainFieldResource[]; 
  lawDomainFields: LawDomainFieldResource[];
  isAuthorize: boolean;
  isLoading: boolean;
  isEmptyList: boolean;
}

const initialLawDomainFieldState = {
  selectedLawDomainFields: [],
  lawDomainFields: [],
  parents: [],
  isAuthorize: false,
  isLoading: true,
  isEmptyList: true
};

const reducer = (state: LawDomainFieldState, action: Action): LawDomainFieldState => {
  switch (action.type) {
    case 'FETCH_SELECTED_LAW_DOMAIN_FIELD': {
      const { selectedLawDomainFields } = action.payload;
      return {
        ...state,
        selectedLawDomainFields
      };
    }
    case 'FETCH_LAW_DOMAIN_FIELDS': {
      const { lawDomainFields, isAuthorize, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        lawDomainFields,
        isAuthorize,
        isLoading,
        isEmptyList
      };
    }
    default: {
      return { ...state };
    }
  }
};

const LawDomainFieldContext = createContext<LawDomainFieldContextValue>({
  ...initialLawDomainFieldState,
  setSelectedLawDomainFields: (lawDomainFields:LawDomainFieldResource[]) => {}
});

export const LawDomainFieldProvider: FC<LawDomainFieldProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialLawDomainFieldState);
  
  const setSelectedLawDomainFields = (lawDomainFields: LawDomainFieldResource[]) => {
     if(lawDomainFields){
      dispatch({
        type: 'FETCH_SELECTED_LAW_DOMAIN_FIELD',
        payload: {
          selectedLawDomainFields: lawDomainFields
        }
      });
     }
  };

  useEffect(() => {
    const initialise = async () => {
      const model = new LawDomainFieldModel();
      model.skipHandleError = true;
      const results = await model.getLawDomainFields({pageNumber: 1, sortOptions: 'asc', pageSize: 999 });
      const { error } = model;
      if (error) {
        if(error.status === Forbidden || error.status === Unauthorized){
          dispatch({
            type: 'FETCH_LAW_DOMAIN_FIELDS',
            payload: {
              lawDomainFields:[],
              isLoading: false,
              isEmptyList: true,
              isAuthorize: false,
            }
          });
        }else{
          dispatch({
            type: 'FETCH_LAW_DOMAIN_FIELDS',
            payload: {
              lawDomainFields:[],
              isLoading: false,
              isEmptyList: true,
              isAuthorize: true,
            }
          });
        }
      }
      if (!error) {
        dispatch({
          type: 'FETCH_LAW_DOMAIN_FIELDS',
          payload: {
            lawDomainFields: results.list,
            isLoading: false,
            isEmptyList: !Array.isArray(results.list) || results.list.length === 0,
            isAuthorize: true,
          }
        });
      }
    };
    (async () => await initialise())();
    return () => { dispatch({type: 'FETCH_LAW_DOMAIN_FIELDS', payload: initialLawDomainFieldState}) }
  }, []);
  
  return (
    <LawDomainFieldContext.Provider
      value={{
        ...state,
        setSelectedLawDomainFields
      }}
    >
      {children}
    </LawDomainFieldContext.Provider>
  );
};

LawDomainFieldProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default LawDomainFieldContext;
