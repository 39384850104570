import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { SubscriptionResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { 
  labelConfig, 
  formatDate, 
  tableRow, 
  SubscriptionModel, 
  ENDORSEMENT
} from 'src/common';
import { SbGroup } from 'src/enum';
import { tableCell } from 'src/styles/subscription';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { 
  CaijTableRow, 
  CaijTableRowActive, 
  CaijTableRowConfirm, 
  CaijTableRowImage 
} from 'src/components/table/CaijTableRow';

export interface SubscriptionInfoProps {
  model: SubscriptionModel,
  subscription: SubscriptionResource;
}

const SubscriptionInfo: FC<SubscriptionInfoProps> = ({
  model,
  subscription
}) => {
  const { 
    name, group, startDate, hasContent, active, endDate, 
    doorCardProfile, logoUrl, wfProfile, restrictedAccountCreation 
  } = subscription;
  const { Group, Name, WfProfile, StartDate, EndDate, RestrictedAccountCreation } = model;
  const parameter = subscription.products.find(({code}) => code === ENDORSEMENT)?.parameter;
  return (
    <MyCard>
      <CardHeaderDetail title='Abonnement' />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow label={Group.Label} sxTableCell={tableCell}>
            {SbGroup[group]}
          </CaijTableRow>  
          <CaijTableRow label={Name.Label} sxTableCell={tableCell}>
            {name}
          </CaijTableRow> 
          <CaijTableRow label={StartDate.Label} sxTableCell={tableCell}>
            {startDate && formatDate(startDate as Date)}
          </CaijTableRow>   
          <CaijTableRow label={EndDate.Label} sxTableCell={tableCell}>
            {endDate && formatDate(endDate as Date)}
          </CaijTableRow>
          <CaijTableRowConfirm 
            label={RestrictedAccountCreation.Label} 
            sxTableCell={tableCell}
            confirm={restrictedAccountCreation}
          />
          <CaijTableRowActive 
            label={labelConfig.status} 
            sxTableCell={tableCell} 
            active={active}
          />
          <CaijTableRow label={WfProfile.Label} sxTableCell={tableCell}>
            {wfProfile}
          </CaijTableRow>
          <CaijTableRowImage 
            label={labelConfig.logo} 
            sxTableCell={tableCell}
            logoUrl={logoUrl}
          />
          <CaijTableRowConfirm 
            label={labelConfig.hasContent} 
            sxTableCell={tableCell}
            confirm={hasContent}
          />  
          <CaijTableRow label={labelConfig.doorCardProfile} sxTableCell={tableCell}>
            {doorCardProfile}
          </CaijTableRow>
          <CaijTableRow 
            label={labelConfig.memberGuaranteeCount} 
            sxTableCell={tableCell} 
            sxTableRow={tableRow}
          >
            {parameter || 'Aucune caution'}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

SubscriptionInfo.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default SubscriptionInfo;
