import React, { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  CaijInput,
  CaijInputPhone,
  CaijInputEmail,
  CaijCard,
  FormikTouched,
  FormikErrors,
  CaijInputAddress,
  SubscriptionModel
} from 'src/common';
import type { HandleBlur, SetFieldValue, SubscriptionResource } from 'src/common/types';
import { Box } from '@mui/material';

interface ContactFormProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  errors: FormikErrors<SubscriptionResource>;
  touched: FormikTouched<SubscriptionResource>;
  handleBlur: HandleBlur;
  setFieldValue: SetFieldValue;
}

const ContactForm: FC<ContactFormProps> = ({
  model,
  subscription,
  errors,
  touched,
  handleBlur,
  setFieldValue
}) => {
  const [name, setName] = useState<string>(subscription?.contact?.name);
  const [phone, setPhone] = useState<string>(subscription?.contact?.phone);
  const [email, setEmail] = useState<string>(subscription?.contact?.email);
  const [line1, setLine1] = useState<string>(subscription?.contact?.address?.line1);
  const [line2, setLine2] = useState<string>(subscription?.contact?.address?.line2);
  const [city, setCity] = useState<string>(subscription?.contact?.address?.city);
  const [country, setCountry] = useState<string>(subscription?.contact?.address?.country);
  const [state, setState] = useState<string>(subscription?.contact?.address?.state); 
  const [zip, setZip] = useState<string>(subscription?.contact?.address?.zip);
  const { ContactName, ContactEmail, ContactPhone, AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry } = model;
  return (
    <CaijCard title="Personne contact">
        <CaijInput
            id={ContactName.Name}
            value={name}
            label={ContactName.Label}
            error={Boolean((touched.contact?.name) && (errors.contact?.name))}
            helperText={(touched.contact?.name) && (errors.contact?.name)}
            inputAttr={{
                maxLength: ContactName.MaxLength, 
                'data-testid': ContactName.Name,
                autoComplete: 'none' 
            }}
            InputLabelProps={{ shrink: true }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                setFieldValue(ContactName.Name, name);
                handleBlur(e);
            }}
        />
        <CaijInputEmail
            id={ContactEmail.Name}
            value={email}
            label={ContactEmail.Label}
            error={Boolean((touched.contact?.email) && (errors.contact?.email))}
            helperText={(touched.contact?.email) && (errors.contact?.email)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: ContactEmail.MaxLength, 'data-testid': ContactEmail.Name }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                setFieldValue(ContactEmail.Name, email);
                handleBlur(e);
            }}
        />
        <CaijInputPhone
            id={ContactPhone.Name}
            value={phone}
            label={ContactPhone.Label}
            error={Boolean((touched.contact?.phone) && (errors.contact?.phone))}
            helperText={(touched.contact?.phone) && (errors.contact?.phone)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: ContactPhone.MaxLength, 'data-testid': ContactPhone.Name }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                setFieldValue(ContactPhone.Name, phone);
                handleBlur(e);
            }}
        />
        <CaijInputAddress
            id={AddressLine1.Name}
            value={line1} 
            label={AddressLine1.Label}
            InputLabelProps={{ shrink: true }} 
            error={Boolean((touched.contact?.address?.line1) && ( errors.contact?.address?.line1))}
            helperText={(touched.contact?.address?.line1) && (errors.contact?.address?.line1)}
            inputProps={{maxLength: AddressLine1.MaxLength, 'data-testid': AddressLine1.Name}}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine1(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                setFieldValue(AddressLine1.Name, line1);
                handleBlur(e);
            }}
        />
        <CaijInputAddress
            id={AddressLine2.Name}
            value={line2}
            label={AddressLine2.Label}
            InputLabelProps={{ shrink: true }} 
            error={Boolean((touched.contact?.address?.line2) && (errors.contact?.address?.line2))}
            helperText={(touched.contact?.address?.line2) && (errors.contact?.address?.line2)}
            inputProps={{ maxLength: AddressLine2.MaxLength, 'data-testid': AddressLine2.Name }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine2(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                setFieldValue(AddressLine2.Name, line2);
                handleBlur(e);
            }}
        />
        <CaijInput
            id={AddressCity.Name}
            value={city}
            label={AddressCity.Label}
            error={Boolean((touched.contact?.address?.city) && (errors.contact?.address?.city))}
            helperText={(touched.contact?.address?.city) && (errors.contact?.address?.city)}
            inputAttr={{
                maxLength: AddressCity.MaxLength, 
                'data-testid': AddressCity.Name,
                autoComplete: 'none' 
            }}
            InputLabelProps={{ shrink: true }}
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
            onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                setFieldValue(AddressCity.Name, city);
                handleBlur(e);
            }}
        />
        <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box>
                <CaijInput
                    id={AddressCountry.Name}
                    label={AddressCountry.Label}
                    value={country}
                    error={Boolean((touched.contact?.address?.country) && (errors.contact?.address?.country))}
                    helperText={(touched.contact?.address?.country) && (errors.contact?.address?.country)}
                    inputAttr={{
                        maxLength: AddressCountry.MaxLength, 
                        'data-testid': AddressCountry.Name,
                        autoComplete: 'none' 
                    }}
                    InputLabelProps={{ shrink: true }}
                    onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
                    onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(AddressCountry.Name, country);
                        handleBlur(e);
                    }}
                />
            </Box>
            <Box>
                <CaijInput
                    id={AddressState.Name}
                    label={AddressState.Label}
                    value={state}
                    error={Boolean((touched.contact?.address?.state) && (errors.contact?.address?.state))}
                    helperText={(touched.contact?.address?.state) && (errors.contact?.address?.state)}
                    inputAttr={{ 
                        maxLength: AddressState.MaxLength,  
                        'data-testid': AddressState.Name,
                        autoComplete: 'none' 
                    }}
                    InputLabelProps={{ shrink: true }}
                    onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
                    onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(AddressState.Name, state);
                        handleBlur(e);
                    }}
                />
            </Box>
            <Box>
                <CaijInput
                    id={AddressZip.Name}
                    label={AddressZip.Label}
                    value={zip}
                    error={Boolean((touched.contact?.address?.zip) && (errors.contact?.address?.zip))}
                    helperText={(touched.contact?.address?.zip) && (errors.contact?.address?.zip)}
                    inputAttr={{ 
                        maxLength: AddressZip.MaxLength, 
                        'data-testid' : AddressZip.Name,
                        autoComplete: 'none' 
                    }}
                    InputLabelProps={{ shrink: true }}
                    onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setZip(e.target.value)}
                    onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(AddressZip.Name, zip);
                        handleBlur(e);
                    }}
                />
            </Box>
        </Box>
    </CaijCard>
  );
};

ContactForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default ContactForm;
