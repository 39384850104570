import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { InputProps2, Margin, Size, Variant } from 'src/types/input';

interface CaijTextareaProps {
  value?: string | number;
  label?: string;
  rows?: number | string;
  size?: Size;
  variant?: Variant;
  margin?: Margin;
  placeholder?:string;
  name?: string;
  id?: string;
  error?: any;
  sx?: object;
  disabled?: boolean;
  helperText?: any;
  InputLabelProps?: any;
  inputProps?: InputProps2;
  required?: boolean;
  onHandleBlur?: (e: any) => void;
  onHandleChange?: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijTextarea: FC<CaijTextareaProps> = ({
  value,
  variant,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
  ...rest
} : CaijTextareaProps) => (
  <TextField
    {...rest}
    type="text"
    multiline
    variant={variant}
    fullWidth
    onBlur={onHandleBlur}
    onChange={(event: ChangeEvent<HTMLInputElement>) => onHandleChange(event, setFieldValue)}
    value={value || ''}
  />
);

CaijTextarea.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(['outlined', 'filled']),
  margin: PropTypes.oneOf(['normal', 'dense', 'none']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputLabelProps: PropTypes.any,
  inputProps: PropTypes.object,
  onHandleBlur: PropTypes.func,
  onHandleChange: PropTypes.func,
  setFieldValue: PropTypes.func
};

CaijTextarea.defaultProps = {
  id: '',
  label: '',
  required: false,
  disabled: false,
  InputLabelProps: { shrink: true, required: false },
  variant: "outlined",
  margin: 'normal',
  size: "medium",
  inputProps: { 'aria-label': '', autoFocus: false },
  helperText: ' ',
  sx:{ marginBottom: 0}
}

export default CaijTextarea;
