import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { LibraryTypeResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { labelConfig, LibraryTypeModel, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/libraryType';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface LibraryTypeFrInfoProps {
  model: LibraryTypeModel;
  libraryType: LibraryTypeResource;
}

const LibraryTypeFrInfo: FC<LibraryTypeFrInfoProps> = ({
  model,
  libraryType
}) => {
  const { NameFr } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Type de bibliothèque" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              { NameFr.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { libraryType.nameFr }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LibraryTypeFrInfo.propTypes = {
  libraryType: PropTypes.object.isRequired
};

export default LibraryTypeFrInfo;
