import type { FC } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Alert
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Navigate } from 'src/common';

interface AzureAdLoginProps {
  className?: string;
}

const AzureAdLogin: FC<AzureAdLoginProps> = ({ className, ...rest }) => {
  const { login, isServiceAvailable, isInternalServerError, isForbidden } = useAuth();
  const isMountedRef = useIsMountedRef();

  if(isInternalServerError){
    return <Navigate  to="/500" />;
  }else if(isForbidden){
    return <Navigate  to="/403" />;
  }else if(!isServiceAvailable){
    return <Navigate  to="/503" />;
  }

  return (
    <Formik
      initialValues={{
        submit: null
      }}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await login();
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...rest}
        >

          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Me connecter
            </Button>
          </Box>
          <Box mt={2} marginTop="40px">
            <Alert
              severity="info"
            >
              <div>
                Il s'agit de vos informations pour vous connecter sur le
                {' '}
                <b>domaine CAIJ</b>
                {' '}
                et du même mot de passe que votre session <b>Windows</b>
              </div>
            </Alert>
          </Box>
        </form>
      )}
    </Formik>
  );
};

AzureAdLogin.propTypes = {
  className: PropTypes.string,
};

export default AzureAdLogin;
