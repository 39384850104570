import CustomerModel from './Customer';

export default class JugesAClasserModel extends CustomerModel 
{
  private static _jugesAClasser: JugesAClasserModel;

  constructor(){
    super();
    this._headerTitle =  'Liste des juges à classer';
    this.Path.PathName = '/members/new-judges';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Juges à classer',
      href: this.Path.Home,
      visible: true
    }
  }

  static getInstance(): JugesAClasserModel {
    if (!JugesAClasserModel._jugesAClasser) {
      JugesAClasserModel._jugesAClasser = new JugesAClasserModel();
    }
    return JugesAClasserModel._jugesAClasser;
  }
}
  