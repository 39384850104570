import { ErrorType, INVALID_DATE, labelConfig, MessageModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: MessageModel, startDate: string) => {
  const { Name, Rank, TitleFr, TitleEn, MessageFr, MessageEn } = model;
  return Yup.object().shape({
    name: Name.required(true),
    rank: Rank.required(true),
    titleFr: TitleFr.required(true),
    titleEn: TitleEn.required(true),
    messageFr: MessageFr.required(),
    messageEn: MessageEn.required(),
    startDate: Yup.string()
      .transform((curr, orig) => orig === null ? '' : curr)
      .required(MessageModel.formatError(ErrorType.required, labelConfig.startDate))
      .test(labelConfig.startDate, MessageModel.formatError(ErrorType.invalid, labelConfig.startDate), (startDate:string) => {
        return startDate === INVALID_DATE ? false : true;
      }),
    endDate: Yup.string()
      .transform((curr, orig) => orig === null ? '' : curr)
      .required(MessageModel.formatError(ErrorType.required, labelConfig.endDate))
      .test(labelConfig.endDate, MessageModel.formatError(ErrorType.invalid, labelConfig.endDate), (endDate:string) => {
        return endDate === INVALID_DATE ? false : true;
      })
      .test(labelConfig.endDate, MessageModel.formatError(ErrorType.endDateMustBeLater, labelConfig.endDate), (endDate:string) => {
        if((startDate && startDate !== INVALID_DATE) && (endDate && endDate !== INVALID_DATE)){
          return MessageModel.endDateMessageMustBeGreaterThanStartDateMessage(startDate, endDate);
        }
      })
  });
}
export default validateSchema;