import axios from "src/utils/axios";
import { MSG_NO_CONTENT_ERROR, MSG_UNEXPECTED_TOKEN_JSON, NoContent, Success, UnExpectTokenJson } from "src/common";
import type {
    DocumentLockResource,
    DocumentVersionResource,
    Error,
    PublishDocumentResource,
} from 'src/common/types';
import AppModel from "./App";

export class EditableDocumentModel {
    private _route: string;
    private _pageModel: AppModel;

    constructor(pageModel: AppModel) {
        this._route = "/content/document/editor";
        this._pageModel = pageModel
    }
    
    protected get route(): string {
        if (this._route) {
            return this._route;
        }
    }

    async getContent(id: string, version?: string): Promise<string> {
        let content: string;
        const params = {
            doc: id,
            version
        }
        await axios.get<string>(`${this.route}`, { params }).then(
            async response => {
                const { status, data } = response;
                try {
                    if (status === Success) {
                        content = data;
                    } else if (status === NoContent) {
                        throw new Error(MSG_NO_CONTENT_ERROR);
                    }
                } catch (ex) {
                    this._pageModel.error = { status, message: ex.message };
                    if (!this._pageModel.skipHandleError) {
                        await this._pageModel.handleError(this._pageModel.error);
                    }
                }
            },
            async error => {
                this._pageModel.error = error;
                if (!this._pageModel.skipHandleError) {
                    await this._pageModel.handleError(this._pageModel.error);
                }
            },
        );
        return content;
    }

    async getDocumentVersionHistory(id: string): Promise<DocumentVersionResource[]> {
        let history: DocumentVersionResource[];
        await axios.get<DocumentVersionResource[]>(`${this.route}/history?doc=${id}`).then(async (response) => {
            const { status, data } = response;
            try {
                if (status === Success) {
                    history = data;
                }
            } catch (error) {
                this._pageModel.error = { status: UnExpectTokenJson, message: MSG_UNEXPECTED_TOKEN_JSON };
                if (!this._pageModel.skipHandleError) {
                    await this._pageModel.handleError(this._pageModel.error);
                }
            }
        }, async (error) => {
            this._pageModel.error = error;
            await this._pageModel.handleError(this._pageModel.error);
        });
        return history;
    }

    async saveNewDocumentVersion(id: string, docContent: string): Promise<Error> {
        let result: Error;
        await axios.post<string, Error>(`${this.route}?doc=${id}`, docContent).then(async (response) => {
            const { status } = response;
            try {
                if (status === Success) {
                    result = response;
                    result.message = 'Contenu html sauvegardé.';
                } else if (status === NoContent) {
                    throw new Error(MSG_NO_CONTENT_ERROR);
                }
            } catch (ex) {
                this._pageModel.error = { status, message: ex.message };
                await this._pageModel.handleError(this._pageModel.error);
            }
        }, async (error) => {
            this._pageModel.error = error;
            await this._pageModel.handleError(this._pageModel.error);
        });
        return result;
    }

    async publishDocumentContent(id: string, submitData: PublishDocumentResource): Promise<Error> {
        let result: Error;
        await axios.put<PublishDocumentResource, Error>(`${this.route}?doc=${id}`, submitData).then(async (response) => {
            const { status } = response;
            try {
                if (status === Success) {
                    result = response;
                    result.message = 'Contenu html publié';
                } else if (status === NoContent) {
                    throw new Error(MSG_NO_CONTENT_ERROR);
                }
            } catch (ex) {
                this._pageModel.error = { status, message: ex.message };
                await this._pageModel.handleError(this._pageModel.error);
            }
        }, async (error) => {
            this._pageModel.error = error;
            await this._pageModel.handleError(this._pageModel.error);
        });
        return result;
    }

    async getDocumentLockStatus(id: string): Promise<DocumentLockResource> {
        let lock: DocumentLockResource;
        await axios.get<DocumentLockResource>(`${this.route}/lock?doc=${id}`).then(async (response) => {
            const { status, data } = response;
            try {
                if (status === Success) {
                    lock = data;
                }
            } catch (error) {
                this._pageModel.error = { status: UnExpectTokenJson, message: MSG_UNEXPECTED_TOKEN_JSON };
                if (!this._pageModel.skipHandleError) {
                    await this._pageModel.handleError(this._pageModel.error);
                }
            }
        }, async (error) => {
            this._pageModel.error = error;
            await this._pageModel.handleError(this._pageModel.error);
        });
        return lock;
    }

    async lockDocumentContentEditing(id: string): Promise<Error> {
        let result: Error;
        await axios.post<void, Error>(`${this.route}/lock?doc=${id}`).then(async (response) => {
            const { status } = response;
            try {
                if (status === Success) {
                    result = response;
                    result.message = "L'édition de ce document est verrouillée lorsque vous faites vos modifications";
                } else if (status === NoContent) {
                    throw new Error(MSG_NO_CONTENT_ERROR);
                }
            } catch (ex) {
                this._pageModel.error = { status, message: ex.message };
                await this._pageModel.handleError(this._pageModel.error);
            }
        }, async (error) => {
            this._pageModel.error = error;
            await this._pageModel.handleError(this._pageModel.error);
        });
        return result;
    }

    async removeDocumentLock(id: string): Promise<Error> {
        let result: Error;
        await axios.delete<void, Error>(`${this.route}/lock?doc=${id}`).then(async (response) => {
            const { status } = response;
            try {
                if (status === Success) {
                    result = response;
                    result.message = "L'édition de ce document a été déverrouillée";
                } else if (status === NoContent) {
                    result = response;
                    result.message = "Ce document est déjà déverrouillé"
                }
            } catch (ex) {
                this._pageModel.error = { status, message: ex.message };
                await this._pageModel.handleError(this._pageModel.error);
            }
        }, async (error) => {
            this._pageModel.error = error;
            await this._pageModel.handleError(this._pageModel.error);
        });
        return result;
    }
}