import { ErrorType, labelConfig, LibraryModel } from 'src/common';
import * as Yup from 'yup';

export const validateEditSchema = (model: LibraryModel) => {
  const { 
    NameFr, TempClosingMsgFr, TempClosingMsgEn, CloakroomInfo, ImageUrlFr, ImageUrlEn,
    AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry,
    StaffFr, StaffEn, ByodPrinterUrl
  } = model;
  return Yup.object().shape({
    nameFr: NameFr.required(true),
    byodPrinterUrl: ByodPrinterUrl.nullable(true),
    cloakroomInfo: CloakroomInfo.nullable(true), 
    libraryTypeId: Yup.number().required(LibraryModel.formatError(ErrorType.required, labelConfig.libraryType)),
    imgUrlFr: Yup.string()
          .test(ImageUrlFr.Label, LibraryModel.formatError(ErrorType.max, ImageUrlFr.Label, ImageUrlFr.MaxLength), (imageUrlFr: string) => {
            return LibraryModel.verifyImageUrlLength(imageUrlFr, ImageUrlFr.MaxLength);
          }),
    imgUrlEn: Yup.string()
          .test(ImageUrlEn.Label, LibraryModel.formatError(ErrorType.max, ImageUrlEn.Label, ImageUrlEn.MaxLength), (imageUrlEn: string) => {
            return LibraryModel.verifyImageUrlLength(imageUrlEn, ImageUrlEn.MaxLength);
          }),
    addressFr: Yup.object({
      line1: AddressLine1.nullable(true),
      line2: AddressLine2.nullable(true),
      city: AddressCity.nullable(true),
      zip: AddressZip.nullable(true),
      state: AddressState.nullable(true),
      country: AddressCountry.nullable(true)
    }),
    contactUs: Yup.string().nullable()
      .test(null, LibraryModel.formatError(ErrorType.invalid,null), (contactUs: string) => {
        if(contactUs){
          const contact = LibraryModel.convertContactStringToJson(contactUs);
          if(!model.verifyContactTelephone(contact)){
            return false;
          }else if(!model.verifyContactFax(contact)){
            return false;
          }else if(!model.verifyContactTollfree(contact)){
            return false;
          }
        }
        return true;
      }),
  });
}
export const validateDetailSchema = (model: LibraryModel) => {
  const { NameFr, TempClosingMsgFr, TempClosingMsgEn, CloakroomInfo, ImageUrlFr, ImageUrlEn, StaffFr, StaffEn } = model;
  return Yup.object().shape({
    nameFr: NameFr.required(true),
    cloakroomInfo: CloakroomInfo.nullable(true), 
    libraryTypeId: Yup.number().required(LibraryModel.formatError(ErrorType.required, labelConfig.libraryType)),
    imgUrlFr: Yup.string()
          .test(ImageUrlFr.Label, LibraryModel.formatError(ErrorType.max, ImageUrlFr.Label, ImageUrlFr.MaxLength), (imageUrlFr: string) => {
            return LibraryModel.verifyImageUrlLength(imageUrlFr, ImageUrlFr.MaxLength);
          }),
    imgUrlEn: Yup.string()
          .test(ImageUrlEn.Label, LibraryModel.formatError(ErrorType.max, ImageUrlEn.Label, ImageUrlEn.MaxLength), (imageUrlEn: string) => {
            return LibraryModel.verifyImageUrlLength(imageUrlEn, ImageUrlEn.MaxLength);
          }),
    contactUs: Yup.string().nullable()
      .test(null, LibraryModel.formatError(ErrorType.invalid,null), (contactUs: string) => {
        if(contactUs){
          const contact = LibraryModel.convertContactStringToJson(contactUs);
          if(!model.verifyContactTelephone(contact)){
            return false;
          }else if(!model.verifyContactFax(contact)){
            return false;
          }else if(!model.verifyContactTollfree(contact)){
            return false;
          }
        }
        return true;
      }),
  });
}
export const validateAddressSchema = (model: LibraryModel) => {
  const { AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry } = model;
  return Yup.object().shape({
    addressFr: Yup.object({
      line1: AddressLine1.nullable(true),
      line2: AddressLine2.nullable(true),
      city: AddressCity.nullable(true),
      zip: AddressZip.nullable(true),
      state: AddressState.nullable(true),
      country: AddressCountry.nullable(true)
    })
  });
}
