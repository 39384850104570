import { DoorCardProfileDto } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchDoorCardProfilesAction = {
  type: 'FETCH_DOOR_CARD_PROFILES';
  payload: {
    doorCardProfiles: DoorCardProfileDto[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

export type DoorAccessProfileAction = FetchDoorCardProfilesAction;

export interface DoorAccessProfileReturnState {
  doorCardProfiles?: DoorCardProfileDto[];
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface DoorAccessProfileState {
  doorCardProfiles: DoorCardProfileDto[];
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

export default (state: DoorAccessProfileState, action: DoorAccessProfileAction): DoorAccessProfileReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_DOOR_CARD_PROFILES: {
      const { doorCardProfiles, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        doorCardProfiles,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    default: {
      return state;
    }
  }
}

