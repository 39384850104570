import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { LibraryCardTransaction, CardTransactionType } from 'src/common/types';
import { TextField } from '@mui/material';
import { method } from 'src/styles/customer';

interface Methods {
    Payment: string[][],
    Refund: string[][],
    Refill: string[][]
}
  
const methods: Methods = {
    Payment : [
      ["carte", "Carte CAIJ - Nouvelle carte"],
      ["peb", "Prêt de document – Autre bibliothèque"],
      ["amende", "Prêt de document – Amende"],
      ["perdu", "Prêt de document – Livre perdu ou abîmé"],
      ["messagerie", "Messagerie"],
      ["impression", "Photocopie ou impression"],
      ["fax", "Télécopie"],
      ["numerisation", "Numérisation"],
      ["recherche", "Recherche documentaire juridique"],
      ["reperage", "Repérage de document"],
      ["commande", "Commande d’article"],
      ["papier", "Papier à jugement"],
      ["cleusb", "Clé USB"],
      ["soquijplum", "Plumitif SOQUIJ"],
      ["transfert", "Transfert de fonds sur une autre carte CAIJ"],
      ["autre","Autre"]
    ],
    Refund : [
      ["carte", "Carte CAIJ - Nouvelle carte"],
      ["peb", "Prêt de document – Autre bibliothèque"],
      ["amende", "Prêt de document – Amende"],
      ["perdu", "Prêt de document – Livre perdu ou abîmé"],
      ["messagerie", "Messagerie"],
      ["impression", "Photocopie ou impression"],
      ["fax","Télécopie"],
      ["numerisation", "Numérisation"],
      ["recherche", "Recherche documentaire juridique"],
      ["reperage", "Repérage de document"],
      ["commande", "Commande d’article"],
      ["papier", "Papier à jugement"],
      ["cleusb", "Clé USB"],
      ["soquijplum", "Plumitif SOQUIJ"],
      ["autre", "Autre"]
    ],
    Refill : [
      ["argent", "Argent comptant"],
      ["debit", "Carte de débit"],
      ["credit", "Carte de crédit"],
      ["transfert", "Transfert de fonds d'une autre carte CAIJ"],
      ["cadeauAssermentation", "Nouvelle assermentation"],
      ["promotion", "Promotion"]
    ]
};

export interface MethodFormProps {
  label: string;
  libraryCardTransaction: LibraryCardTransaction;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const optionsRender = (operation: CardTransactionType): JSX.Element => {
  return methods[operation].map((item: string[]) => (
    <option key={item[0]} value={item[1]}>
      {item[1]}
    </option>
  ));
};

const MethodForm: FC<MethodFormProps> = ({
  label,
  libraryCardTransaction,
  onHandleChange,
  setFieldValue
}) => {
  return (
    <>
      <TextField
          sx={{...method}}
          label={label}
          name='reason'
          onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e, setFieldValue)}
          select
          SelectProps={{ native: true }}
          value={libraryCardTransaction.reason}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
        >
          <option value=''>------Veuillez choisir------</option>
          { optionsRender(libraryCardTransaction.transactionType) }
        </TextField>
    </>
  );
};

MethodForm.propTypes = {
  libraryCardTransaction:PropTypes.object.isRequired,
  onHandleChange:PropTypes.func.isRequired,
  setFieldValue:PropTypes.func.isRequired
};

export default MethodForm;
