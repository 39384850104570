import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  fetchDetailsLawPracticeField,
  LawPracticeFieldModel,
  useLocation,
  useNavigate,
  useParams,
  _lawPracticeField,
  root
} from 'src/common';
import Page from 'src/components/Page';
import { LawPraticeFieldProvider } from 'src/contexts/LawPraticeFieldContext';
import Details from './Details';
import DetailsEditForm from './Forms/DetailsEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import LawPraticeFieldModel from 'src/model/customer/LawPracticeField';
import GenericHeader from 'src/components/header/GenericHeader';

const LawPraticeFieldDetailsView: FC = () => {
  const model = LawPraticeFieldModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_lawPracticeField, { lawPracticeField: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    subscription: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();

  const tabs = [
    { value: 'details', label: 'Détails' },
  ];

  const getPraticeFieldById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const model = new LawPracticeFieldModel();
      model.PathName = location.pathname;
      const lawPraticeField = await model.getLawPracticeFieldById(id);
      if (!model.error) {
        dispatch(fetchDetailsLawPracticeField(lawPraticeField));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getPraticeFieldById();
    })();
  }, []);

  const setEditModeLawPraticeField = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeLawPraticeField(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setEditModeLawPraticeField(false);
    setCurrentTab(value);
    setIsEditing(false);
  };

  const handleRefreshLawPraticeField = async () => {
    await getPraticeFieldById();
    setEditModeLawPraticeField(false);
  };

  const editLawPraticeField = () => {
    setEditModeLawPraticeField(true);
  };

  const { lawPracticeField } = state;

  if (Object.keys(lawPracticeField).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${lawPracticeField.nameFr} - ${lawPracticeField.code}`;

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <LawPraticeFieldProvider>
              <DetailsEditForm
                model={model}
                lawPracticeField={lawPracticeField}
                onHandleRefreshLawPraticeField={handleRefreshLawPraticeField}
                onSwitchMode={(l) => switchMode(l)}
              />
            </LawPraticeFieldProvider>
          );
        }
        return (
          <Details model={model} lawPracticeField={lawPracticeField} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={lawPracticeField.nameFr} 
          titleBadge={{label: lawPracticeField.code}}
          editMode={editMode.details} 
          actions={['editDetail','delete']}
          onEditDetail={() => editLawPraticeField()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default LawPraticeFieldDetailsView;
