import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid, Button } from '@mui/material';
import type { 
  MessagePlaceholderResource, 
  MessageResource, 
  MessageResourceForEdit 
} from 'src/common/types';
import {
  Formik,
  handleChangeDateChange,
  handleChangeCheckBox,
  CaijButtonSubmit,
  Authorize,
  MessageModel,
  Mode,
  formatDateTime,
  btnSubmit,
  FormikProps,
  MessagePlaceholderModel,
  FormikHelpers
} from 'src/common';
import MessageForm from 'src/components/message/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface MessageEditFormProps {
  holder: MessagePlaceholderResource;
  message: MessageResource;
  switchMode: (value?: boolean) => void;
  onSubmit?: (values: MessageResource) => Promise<void>;
}

const MessageEditForm: FC<MessageEditFormProps> = ({ holder, message, switchMode, onSubmit }) => {
  const model = MessageModel.getInstance();
  const [isAuth, setIsAuth] = useState<boolean>();
  const formRef = useRef<FormikProps<MessageResource>>(null);

  const handleStartDateChange = (startDate: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    handleChangeDateChange('startDate', startDate, setFieldValue);
  };

  const handleEndDateChange = (endDate: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    handleChangeDateChange('endDate', endDate, setFieldValue);
  };

  const exitHandler = () => {
    if (message && message.id) {
      model.redirect((new MessagePlaceholderModel()).Path.getDetail(message.placeholderId));
    }
  };

  const handleSubmit = async (values: MessageResource, formikHelpers: FormikHelpers<typeof values>) : Promise<void> =>  {
    if (onSubmit){
      onSubmit(values);
      return;
    }
    formikHelpers.validateForm(values);
    const model = new MessageModel();
    let submitData = values as MessageResourceForEdit;
    const messageId = submitData.id;
    if (submitData && messageId) {
      const response = await model.updateMessage(submitData);
      if (!model.error) {
        await model.redirect(model.Path.getDetail(messageId),{
          status: response.status,
          message: response.message
        });
      }
    }
  };

  const { id, startDate, endDate } = message;
  
  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          id,
          name: message.name || '',
          visible: message.visible,
          rank: message.rank || 0,
          startDate: formatDateTime(new Date(startDate)),
          endDate: formatDateTime(new Date(endDate)),
          closable: message.closable,
          placeholderId: holder.id,
          titleFr: message.titleFr || '',
          titleEn: message.titleEn || '',
          messageFr: message.messageFr || '',
          messageEn: message.messageEn || '',
          placeholder: message.placeholder || holder,
        }}
        validationSchema={validateSchema(model, formRef.current?.values.startDate)}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleSubmit, handleChange, isSubmitting, touched, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <MyCard>
              <CardContent>
                <Grid container spacing={2}>
                  <MessageForm 
                    model={model}
                    message={values}
                    errors={errors}
                    touched={touched}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    onHandleStartDateChange={handleStartDateChange}
                    onHandleEndDateChange={handleEndDateChange}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </CardContent>
            </MyCard>
            <Box mt={2}>
              <Authorize resourceCode={model.ResourceCode} mode={holder.id ? Mode.edit : Mode.add} onIsAuth={l => setIsAuth(l)}>
                <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
              </Authorize>
              <Button variant='outlined' disabled={isSubmitting} onClick={() => exitHandler()}>
                Annuler
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

MessageEditForm.propTypes = {
  holder: PropTypes.any.isRequired,
  message: PropTypes.object,
  switchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

MessageEditForm.defaultProps = {
  onSubmit: null
}

export default MessageEditForm;
