import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentTypeResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { DocumentTypeModel, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/documentType';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow } from 'src/components/table/CaijTableRow';

export interface DocumentTypeEnInfoProps {
  model: DocumentTypeModel;
  documentType: DocumentTypeResource;
}

const DocumentTypeEnInfo: FC<DocumentTypeEnInfoProps> = ({
  model,
  documentType
}) => {
  const { NameEn } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Document type" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableCell={tableCell} label={NameEn.Label} sxTableRow={tableRow}>
            { documentType.nameEn }
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

DocumentTypeEnInfo.propTypes = {
  documentType: PropTypes.object.isRequired
};

export default DocumentTypeEnInfo;
