import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import CaijButton from '../buttons/CaijButton';
import { Box } from '@mui/material';

export interface CaijPdfProps {
  title: string;
  base64String: string;
  setShown: (value: boolean) => void;
};

const container = {
  backgroundColor: '#fff',
  flexDirection: 'column',
  overflow: 'auto', 
  left: 0,
  position: 'fixed',
  top: 0,  
  height: '100%',
  width: '100%',
  zIndex: 9999,
};

const header = {
  alignItems: 'center',
  backgroundColor: '#000',
  color: '#fff',
  display: 'flex',
  padding: '.5rem',
};

const body = {
  flexGrow: 1,
  overflow: 'hidden',
  height: '100%'
};

const getBase64toBlob = (base64String: string) => {
  const bytes = window.atob(base64String);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: 'application/pdf' });
};

const CaijPdf : FC<CaijPdfProps> = ({title, base64String, setShown}) => {
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if(base64String) setUrl(URL.createObjectURL(getBase64toBlob(base64String)));
  },[base64String])
  
  const modalBody = (title: string, url: string) => (
    <Box sx={container}>
      <Box sx={header}>
        <div style={{ marginRight: 'auto' }}>{title}</div>
          <div style={{display: 'flex', justifyContent: 'space-around'}}>
            <CaijButton
              variant="contained"
              color="error"
              onHandleClick={() => setShown(false)}
            >
              Fermer
            </CaijButton>
          </div>
      </Box>
      <Box sx={body}>
        { url ? (
            <embed
              src={`${url}#view=Fit`}
              style={{width: '100%', height: '100%'}}
            />
          ) : <div style={{textAlign: 'center'}}>Téléchargment en cours...</div>
        }
      </Box>
    </Box>
  );
  return (ReactDOM.createPortal(modalBody(title, url), document.body));
};

export default CaijPdf;

