import { 
  Box, 
  Divider, 
  IconButton,
  Menu, 
  TableCell, 
  Typography
} from "@mui/material"
import { 
  FC, 
  useEffect, 
  useState 
} from "react";
import {  
  CaijTooltip,
  CustomerModel,
  ITEM_HEIGHT,
  ListType, 
  PathModel,
  useLocation
} from "src/common"
import { 
  Search as SearchIcon,
  FormatListBulleted as ListIcon,
  Close as CloseIcon,
  FilterAlt as FilterIcon
} from '@mui/icons-material';
import { popoverClasses } from "@mui/material/Popover";
import Search from "src/components/search";
import { TypeFilter } from "src/types/filter";
import { SubscriptionResource, SearchFilter } from "src/common/types";
import FilterList from "./FilterList";
import { AccessGroup } from "src/types/subscription";

interface FilterTableCellsProps {
  model: CustomerModel;
  colName: string;
  property: string;
  sx?: any;
  type: TypeFilter;
  filter: SearchFilter;
  path: PathModel;
  subscriptions: SubscriptionResource[];
  groups: AccessGroup;
}

const popover = {
  [`& .${popoverClasses.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    overFlowY: 'scroll',
  }
};

const getMaxWidth = (model: CustomerModel, colName: string) => (colName !== model.ColName.status && colName !== model.ColName.subscription && colName !== model.ColName.group) ? '250px' : '';

const  getFiltered = (urlParams: URLSearchParams, model: CustomerModel, colName: string) => Object.entries(model.ColName).find(([key,value]) => value === colName && urlParams.get(key)) ? <FilterIcon fontSize="small" /> : '';

const FilterTableCells: FC<FilterTableCellsProps> = ({
  model,
  colName, 
  property,
  sx, 
  type, 
  filter, 
  path, 
  subscriptions,
  groups
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const { search } = location;
  const urlParams = new URLSearchParams(search);

  const getFocus = () => {
    for(const key in model.ColName){
      var el = document.getElementById(model.ColName[key]);
      if(el) el.focus();
    }
  }

  useEffect(() => {
    window.addEventListener('click',getFocus);
    return () => window.removeEventListener('click', getFocus);
  },[]);

  const handleOpen = (event: { currentTarget: any; }) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <TableCell sx={{...sx, minWidth: '125px'}}>
      <Box marginTop='1em' sx={{display: type === 'none' ? type : 'flex', height: '37px', alignItems: 'center'}}>
        <span>{colName}</span>
        <Box sx={{ml:'10px'}} display='flex' alignItems='center'>
        {getFiltered(urlParams, model, colName)}
        <CaijTooltip title={`Chercher par ${colName}`}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOpen}
          >
            {type === 'search' ? <SearchIcon fontSize="small" /> : <ListIcon fontSize="small" />}
          </IconButton>
        </CaijTooltip>
        </Box>
        <Menu
          onClose={handleClose}
          keepMounted
          sx={popover}
          anchorEl={anchorEl}
          open={open}
          MenuListProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              maxWidth: getMaxWidth(model, colName)
            },
          }}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography variant='body2' sx={{ml: 2}}>{colName}</Typography>
            <CaijTooltip title="Fermer">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClose}
                sx={{marginRight: '10px'}}
              >
                <CloseIcon fontSize="small"/>
              </IconButton>
            </CaijTooltip>
          </Box>
          <Divider sx={{marginBottom: type === 'search' ? '7px' : '', marginTop : '7px'}} />
          { colName !== model.ColName.status && colName !== model.ColName.subscription && colName !== model.ColName.group ? (
              <Search 
                listType={ListType.Customer}
                filter={filter}
                path={path.Home}
                width="100%"
                size="small"
                label=''
                colName={colName}
                searchValue={filter[property]}
                sx={{padding: '5px 10px'}}
                onlyFilterColumn
              />
            ) : (
              <FilterList 
                groups={groups} 
                subscriptions={subscriptions} 
                colName={colName} 
                model={model} 
                filter={filter}
              />
            )
          }
        </Menu>
      </Box>
    </TableCell>
  );
}

export default FilterTableCells;