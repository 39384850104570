import { DocumentLockResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchHtmlContentAction = {
    type: 'FETCH_HTML_CONTENT';
    payload: {
        content: string;
        isLoading: boolean;
    }
}

type FetchHtmlContentLockAction = {
    type: "FETCH_HTML_CONTENT_LOCK";
    payload: {
        documentLock: DocumentLockResource;
    }
}

type Action = FetchHtmlContentAction | FetchHtmlContentLockAction;

export interface editableDocumenteReturnState {
    content?: string;
    documentLock?: DocumentLockResource;
    isLoading?: boolean;
}

interface editableDocumenteState {
    content: string;
    documentLock?: DocumentLockResource;
    isLoading: boolean;
}

const reducer = (state: editableDocumenteState, action: Action): editableDocumenteReturnState => {
    switch (action.type) {
        case actionTypes.FETCH_HTML_CONTENT:
            const { content, isLoading } = action.payload;
            return {
                ...state,
                content,
                isLoading
            }
        case actionTypes.FETCH_HTML_CONTENT_LOCK:
            const { documentLock } = action.payload;
            return {
                ...state,
                documentLock
            }
    }
}

export default reducer;