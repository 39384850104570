import React, { MutableRefObject } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, SvgIcon } from '@mui/material';
import { Link as LinkIcon } from 'react-feather';
import { InputProps2, Margin, Variant, Size } from 'src/types/input';

interface CaijInputLinkProps {
  value?: string;
  id?: string;
  label?: string;
  name?: string;
  title?: string;
  error?: any;
  sx?: object;
  ref?: MutableRefObject<any>;
  size?: Size
  variant?: Variant;
  margin?: Margin;
  disabled?: boolean;
  helperText?: any;
  InputLabelProps?: any;
  inputProps?: InputProps2;
  required?: boolean;
  onHandleBlur?: (e: any) => void;
  onHandleChange?: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijInputLink: FC<CaijInputLinkProps> = ({
  value,
  variant,
  ref,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
  ...rest
} : CaijInputLinkProps) => (
  <>
    <TextField
      {...rest}
      type="url"
      autoComplete="off"
      fullWidth
      ref={ref}
      onBlur={onHandleBlur}
      onChange={(event) => onHandleChange(event, setFieldValue)}
      value={value || ''}
      variant={variant}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              fontSize="small"
              color="action"
            >
              <LinkIcon />
            </SvgIcon>
          </InputAdornment>
        )
      }}
    />
  </>
);

CaijInputLink.propTypes = {
  value: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['outlined', 'filled']),
  margin: PropTypes.oneOf(['normal', 'dense', 'none']),
  sx: PropTypes.object,
  ref: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  InputLabelProps: PropTypes.any,
  inputProps: PropTypes.object,
  onHandleBlur: PropTypes.func,
  onHandleChange: PropTypes.func,
  setFieldValue: PropTypes.func
};

CaijInputLink.defaultProps = {
  value: '',
  label: '',
  id:'',
  required: false,
  disabled: false,
  variant: "outlined",
  margin: "normal",
  size: "medium",
  helperText: ' ',
  sx:{ marginBottom: 0},
  onHandleBlur: () => {},
  onHandleChange: () => {},
  setFieldValue: () => {}
}

export default CaijInputLink;
