import { LawDomainFieldModel } from 'src/common';
import type { LawDomainFieldResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';
import { Paged } from 'src/types/pagination';

type FetchLawDomainFieldsAction = {
  type: 'FETCH_LAW_DOMAIN_FIELDS';
  payload: {
    lawDomainFields: LawDomainFieldResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
    paged?: Paged;
    model?: LawDomainFieldModel;
  };
};

type FetchLawDomainFieldAction = {
  type: 'FETCH_LAW_DOMAIN_FIELD';
  payload: {
    lawDomainField: LawDomainFieldResource;
    isLoading?: boolean;
  };
};

type FetchDetailsLawDomainFieldAction = {
  type: 'FETCH_DETAILS_LAW_DOMAIN_FIELD';
  payload: {
    lawDomainField: LawDomainFieldResource;
  };
};

type Action = FetchLawDomainFieldsAction |
              FetchLawDomainFieldAction |
              FetchDetailsLawDomainFieldAction;

export interface lawDomainFieldReturnState {
  lawDomainFields?: LawDomainFieldResource[];
  lawDomainField?: LawDomainFieldResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  paged?: Paged;
  model?:LawDomainFieldModel;
}

interface lawDomainFieldState {
  lawDomainFields: LawDomainFieldResource[];
  lawDomainField: LawDomainFieldResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  paged: Paged;
  model:LawDomainFieldModel;
}

const reducer = (state: lawDomainFieldState, action: Action): lawDomainFieldReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_LAW_DOMAIN_FIELDS: {
      const { lawDomainFields, isEmptyList, isLoading, isAuthorize, paged, model } = action.payload;
      return {
        ...state,
        lawDomainFields,
        isLoading,
        isEmptyList,
        isAuthorize,
        paged,
        model
      };
    }
    case actionTypes.FETCH_LAW_DOMAIN_FIELD: {
      const { lawDomainField, isLoading } = action.payload;
      return {
        ...state,
        lawDomainField,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_LAW_DOMAIN_FIELD: {
      const { lawDomainField } = action.payload;
      return {
        ...state,
        lawDomainField
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
