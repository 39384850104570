import { CustomerModel } from 'src/common';
import type {
  SubscriptionResource,
  AddressDto,
  CustomerResource,
  ProductDto,
  CustomerCardTransactionsResource,
  LawPracticeFieldResource,
  CustomerAddressDto,
  EndorsedCustomerResource,
  InvoiceInfo,
  CustomerHistoryResource,
  CustomerLibraryAccountInfoResource,
  Print,
  Transaction,
  StatsDto,
  DocumentOrderedDto,
  ReperageResource,
  EmailStatusDto,
} from 'src/common/types';
import type Pagination from 'src/types/pagination';
import * as actionTypes from '../../actions/ActionTypes';
import { Paged } from 'src/types/pagination';

type FetchCutomersAction = {
  type: 'FETCH_CUSTOMERS';
  payload: {
    customers: CustomerResource[];
    pagination?: Pagination;
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
    model?: CustomerModel;
    paged?: Paged;
  };
};

type FetchCutomersByEmailAction = {
  type: 'FETCH_CUSTOMERS_BY_EMAIL';
  payload: {
    customers: CustomerResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

type FetchEmailStatusAction = {
  type: 'FETCH_EMAIL_STATUS';
  payload: {
    emailStatus: EmailStatusDto[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

type FetchCustomerAction = {
  type: 'FETCH_CUSTOMER';
  payload: {
    customer: CustomerResource;
    subscriptions?: SubscriptionResource[];
    addresses?: AddressDto[];
    lawPraticeFields?: LawPracticeFieldResource[];
  };
};

type FetchCustomerCardTransactionsAction = {
  type: 'FETCH_CUSTOMER_CARD_TRANSACTIONS';
  payload: {
    cardTransactions: CustomerCardTransactionsResource;
  };
};

type FetchCustomerLibraryTransactionsAction = {
  type: 'FETCH_CUSTOMER_LIBRARY_TRANSACTIONS';
  payload: {
    libraryTransactions: CustomerLibraryAccountInfoResource;
  };
};

type FetchCustomerAddressesAction = {
  type: 'FETCH_CUSTOMER_ADDRESSES';
  payload: {
    customerAddresses: CustomerAddressDto[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
};

type FetchCustomersEndorsedAction = {
  type: 'FETCH_CUSTOMERS_ENDORSED';
  payload: {
    customersEndorsed: EndorsedCustomerResource[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
};

type FetchCustomerEndorsedAction = {
  type: 'FETCH_CUSTOMER_ENDORSED';
  payload: {
    customerEndorsed: EndorsedCustomerResource;
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
};

type FetchResetPasswordAction = {
  type: 'FETCH_RESET_PASSWORD';
  payload: {
    password: string;
  };
};

type FetchCustomerCardTransactionsByIdAction = {
  type: 'FETCH_CUSTOMER_CARD_TRANSACTION_BY_ID';
  payload: {
    customerCardTransaction: CustomerCardTransactionsResource;
    isLoading?: boolean;
  };
};

type FetchCustomerInvoicesByIdAction = {
  type: 'FETCH_CUSTOMER_INVOICES_BY_ID';
  payload: {
    customerOrders: DocumentOrderedDto[];
    customerInvoices: InvoiceInfo[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

type FetchJugesAClasserAction = {
  type: 'FETCH_JUGES_A_CLASSER';
  payload: {
    jugesAClasser: CustomerResource[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
};

type FetchPendingApprovalAction = {
  type: 'FETCH_PENDING_APPROVAL';
  payload: {
    pendingApprovals: CustomerResource[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
};

// #region Statistic
type FetchCustomerStatsAction = {
  type: 'FETCH_CUSTOMER_STATS';
  payload: {
    counter: number,
    title: string,
    isLoading?: boolean;
  }
}

type FetchActiveCustomerBySubscriptionAction = {
  type: 'FETCH_ACTIVE_CUSTOMER_BY_SUBSCRIPTION';
  payload: {
    data: StatsDto[],
    isLoading?: boolean;
    isEmptyList?: boolean;
  }
}

type FetchCustomerReperagesAction = {
  type: 'FETCH_CUSTOMER_REPERAGES';
  payload: {
    customerReperages: ReperageResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  }
}
// #endregion

export type CustomerAction =
  | FetchCutomersAction
  | FetchCustomerAction
  | FetchCutomersByEmailAction
  | FetchCustomersEndorsedAction
  | FetchCustomerEndorsedAction
  | FetchCustomerCardTransactionsAction
  | FetchCustomerLibraryTransactionsAction
  | FetchCustomerAddressesAction
  | FetchResetPasswordAction
  | FetchCustomerCardTransactionsByIdAction
  | FetchCustomerInvoicesByIdAction
  | FetchCustomerStatsAction
  | FetchActiveCustomerBySubscriptionAction
  | FetchJugesAClasserAction
  | FetchPendingApprovalAction
  | FetchCustomerReperagesAction
  | FetchEmailStatusAction;

export interface CustomerReturnState {
  customers?: CustomerResource[];
  customer?: CustomerResource;
  data?: StatsDto[];
  counter?: number;
  title?: string;
  pagination?: Pagination;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  subscriptions?: SubscriptionResource[];
  accesses?: ProductDto[];
  lawPraticeFields?: LawPracticeFieldResource[];
  addresses?: AddressDto[];
  cardTransactions?: CustomerCardTransactionsResource;
  libraryTransactions?: CustomerLibraryAccountInfoResource;
  customerAddresses?: CustomerAddressDto[];
  customersEndorsed?: EndorsedCustomerResource[];
  customerEndorsed?: EndorsedCustomerResource;
  password?: string;
  customerCardTransaction?: CustomerCardTransactionsResource;
  customerInvoices?: InvoiceInfo[];
  customerOrders?: DocumentOrderedDto[];
  customerReperages?: ReperageResource[];
  model?: CustomerModel;
  jugesAClasser?: CustomerResource[];
  pendingApprovals?: CustomerResource[];
  paged?: Paged;
  emailStatus?: EmailStatusDto[];
}

interface CustomerState {
  customers: CustomerResource[];
  customer: CustomerResource;
  data: StatsDto[];
  counter: number;
  title: string;
  pagination: Pagination;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  subscriptions: SubscriptionResource[];
  accesses: ProductDto[];
  lawPraticeFields: LawPracticeFieldResource[];
  addresses: AddressDto[];
  cardTransactions: CustomerCardTransactionsResource;
  libraryTransactions: CustomerLibraryAccountInfoResource;
  customerAddresses: CustomerAddressDto[];
  customersEndorsed: EndorsedCustomerResource[];
  customerEndorsed: EndorsedCustomerResource;
  password: string;
  customerCardTransaction: {
    prints: Print[];
    transactions: Transaction[];
  };
  customerInvoices: InvoiceInfo[];
  customerOrders?: DocumentOrderedDto[];
  customerReperages?: ReperageResource[];
  customerHistory: CustomerHistoryResource[];
  model: CustomerModel;
  jugesAClasser: CustomerResource[];
  pendingApprovals: CustomerResource[];
  page: Paged;
  emailStatus: EmailStatusDto[];
}

const reducer = (state: CustomerState, action: CustomerAction): CustomerReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMERS: {
      const { customers, paged, pagination, isEmptyList, isLoading, isAuthorize, model } = action.payload;
      return {
        ...state,
        customers,
        pagination,
        isEmptyList,
        isLoading,
        isAuthorize,
        model,
        paged
      };
    }
    case actionTypes.FETCH_CUSTOMERS_BY_EMAIL: {
      const { customers, isLoading, isEmptyList } = action.payload;
      return {
        ...state,
        customers,
        isLoading,
        isEmptyList,
      };
    }
    case actionTypes.FETCH_EMAIL_STATUS: {
      const { emailStatus, isLoading, isEmptyList } = action.payload;
      return {
        ...state,
        emailStatus,
        isLoading,
        isEmptyList,
      };
    }
    case actionTypes.FETCH_CUSTOMER: {
      const { customer, subscriptions, addresses, lawPraticeFields } = action.payload;
      return {
        ...state,
        customer,
        subscriptions,
        addresses,
        lawPraticeFields,
      };
    }
    case actionTypes.FETCH_CUSTOMER_CARD_TRANSACTIONS: {
      const { cardTransactions } = action.payload;
      return {
        ...state,
        cardTransactions,
      };
    }
    case actionTypes.FETCH_CUSTOMER_LIBRARY_TRANSACTIONS: {
      const { libraryTransactions } = action.payload;
      return {
        ...state,
        libraryTransactions,
      };
    }
    case actionTypes.FETCH_CUSTOMER_ADDRESSES: {
      const { customerAddresses, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        customerAddresses,
        isEmptyList,
        isLoading,
      };
    }
    case actionTypes.FETCH_CUSTOMERS_ENDORSED: {
      const { customersEndorsed, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        customersEndorsed,
        isEmptyList,
        isLoading,
      };
    }
    case actionTypes.FETCH_CUSTOMER_ENDORSED: {
      const { customerEndorsed, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        customerEndorsed,
        isEmptyList,
        isLoading,
      };
    }
    case actionTypes.FETCH_RESET_PASSWORD: {
      const { password } = action.payload;
      return {
        ...state,
        password
      };
    }
    case actionTypes.FETCH_CUSTOMER_CARD_TRANSACTION_BY_ID: {
      const { customerCardTransaction, isLoading } = action.payload;
      return {
        ...state,
        customerCardTransaction,
        isLoading
      };
    }
    case actionTypes.FETCH_CUSTOMER_INVOICES_BY_ID: {
      const { customerInvoices, customerOrders, isLoading, isEmptyList } = action.payload;
      return {
        ...state,
        customerOrders,
        customerInvoices,
        isLoading,
        isEmptyList
      };
    }
    case actionTypes.FETCH_CUSTOMER_STATS: {
      const { counter, title, isLoading } = action.payload;
      return {
        ...state,
        counter,
        title,
        isLoading
      };
    }
    case actionTypes.FETCH_ACTIVE_CUSTOMER_BY_SUBSCRIPTION: {
      const { data, isLoading, isEmptyList } = action.payload;
      return {
        ...state,
        data,
        isLoading,
        isEmptyList
      };
    }
    case actionTypes.FETCH_JUGES_A_CLASSER: {
      const { jugesAClasser, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        jugesAClasser,
        isEmptyList,
        isLoading
      };
    }
    case actionTypes.FETCH_PENDING_APPROVAL: {
      const { pendingApprovals, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        pendingApprovals,
        isEmptyList,
        isLoading
      };
    }
    case actionTypes.FETCH_CUSTOMER_REPERAGES: {
      const { customerReperages, isLoading, isEmptyList } = action.payload;
      return {
        ...state,
        customerReperages,
        isLoading,
        isEmptyList
      }
    }
    default: {
      return state;
    }
  }
};

export { reducer };

