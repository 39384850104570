import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentCollectionResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { DocCollectionModel, labelConfig, tableRow } from 'src/common';
import { tableCell } from 'src/styles/docCollection';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow, CaijTableRowActive } from 'src/components/table/CaijTableRow';

export interface DocCollectionFrInfoProps {
  model: DocCollectionModel;
  docCollection: DocumentCollectionResource;
}

const DocCollectionFrInfo: FC<DocCollectionFrInfoProps> = ({
  model,
  docCollection
}) => {
  const { Code, NameFr, DescriptionFr } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Classement du contenu" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableCell={tableCell} label={NameFr.Label}>
            {docCollection.nameFr}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={Code.Label}>
            {docCollection.code}
          </CaijTableRow>
          <CaijTableRowActive 
            label={labelConfig.status} 
            sxTableCell={tableCell}
            active={docCollection.active}
          />
          <CaijTableRow 
            sxTableCell={tableCell} 
            sxTableRow={tableRow} 
            label={DescriptionFr.Label} 
            whiteSpace='pre-wrap'
          >
            {docCollection.descriptionFr}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

DocCollectionFrInfo.propTypes = {
  docCollection: PropTypes.object.isRequired
};

export default DocCollectionFrInfo;
