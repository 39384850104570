import { 
  Box, 
  Button, 
  Divider, 
  IconButton,
  Menu, 
  TableCell, 
  Typography
} from "@mui/material"
import { 
  FC, 
  useEffect, 
  useState 
} from "react";
import {  
  ITEM_HEIGHT,
  ListType, 
  CaijDatePicker,
  useNavigate,
  formatDate,
  NavigateFunction,
  Juridictions,
  CaijTooltip
} from "src/common"
import { 
  Search as SearchIcon,
  Close as CloseIcon,
  DateRange as DateRangeIcon,
  ListAlt as DropdownIcon
} from '@mui/icons-material';
import { popoverClasses } from "@mui/material/Popover";
import Search from "src/components/search";
import {  
  IFilter,  
  IHeader, 
  IListFilter, 
  TypeFilter 
} from "src/types/filter";
import AppModel from "src/model/App";
import { FilterAlt as FilterIcon } from '@mui/icons-material';
import CaijDropdown from "../inputs/CaijDropdown";

interface CaijTableHeaderFilterProps {
  listType: ListType;
  colName: string;
  headers: IHeader[];
  type: TypeFilter;
  filter: IFilter;
  lists?: IListFilter;
  width: string | number;
  property: string;
}

const popover = {
  [`& .${popoverClasses.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    overFlowY: 'scroll',
  }
};

const DateRange = (props: {model: AppModel, filter: IFilter, navigate: NavigateFunction}) => {
  const [startDate, setStartDate] = useState<string>(props.filter.startDate);
  const [endDate, setEndDate] = useState<string>(props.filter.endDate);
  return (
    <Box flexDirection='column' sx={{padding: '15px 15px 0 15px'}}>
      <CaijDatePicker 
        name="startDate"
        value={String(startDate)}
        label="Date début"
        inputProps={{"data-testid": 'startDate'}}
        onHandleChange={(l) => setStartDate(l.toString())}
      />
      <CaijDatePicker 
        name="endDate"
        value={String(endDate)}
        label="Date fin"
        inputProps={{"data-testid": 'endDate'}}
        onHandleChange={(l) => setEndDate(l.toString())}
      />
      <Box display="flex" justifyContent="right">
        <Button 
          variant="outlined" 
          color="success"
          size="small"
          onClick={() => {
            if(startDate)
              props.model.getConfigParameters(props.filter,26,formatDate(new Date(startDate)));
            if(endDate)
              props.model.getConfigParameters(props.filter,27,formatDate(new Date(endDate)));
            if(startDate || endDate)
              props.navigate(props.model.getUrlEncode.apply(props.model,props.model.getParams()));
          }}
        >Ok</Button>
      </Box>
    </Box>
  )
}

const CaijTableHeaderFilter: FC<CaijTableHeaderFilterProps> = ({
  listType,
  headers,
  colName, 
  type, 
  filter, 
  width,
  lists,
  property
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const model = AppModel.getInstance();

  const getFocus = () => {
    headers.forEach(({colName}) => {
      var el = document.getElementById(colName);
      if(el) el.focus();
    })
  }

  useEffect(() => {
    window.addEventListener('click',getFocus);
    return () => window.removeEventListener('click', getFocus);
  },[]);

  const handleOpen = (event: { currentTarget: any; }) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const renderInputs = () => {
    if(type === 'search')
      return <Box sx={{padding: '10px 0 5px 0'}}>
                <Search 
                  listType={listType}
                  filter={filter}
                  width="100%"
                  size="small"
                  label=''
                  colName={colName}
                  searchValue={filter[property]}
                
                  onlyFilterColumn
                />
              </Box>
    else if(type === 'dateRange')
      return <DateRange model={model} filter={filter} navigate={navigate} />
    else if(type === 'dropdown' && colName === 'Responsable')
      return  <CaijDropdown defaultValue={filter.email} label='Employé' name='employee' navigate={(value: string) => {
                model.getConfigParameters(filter,18, value);
                navigate(model.getUrlEncode.apply(model,model.getParams()));
              }}>{
                () => lists && lists.map(({name, value}, index: number) => (
                  <option key={index} value={value}>
                    {name}
                  </option>
                ))
              }</CaijDropdown>
    else if(type === 'dropdown' && colName === 'Juridiction')
      return  <CaijDropdown defaultValue={filter.jurisdiction} label='Juridiction' name='Juridiction' navigate={(value: string) => {
                model.getConfigParameters(filter,10, value);
                navigate(model.getUrlEncode.apply(model,model.getParams()));
              }}>{
                  () => Object.keys(Juridictions).map((keyVal: string, index: number) => (
                    <option key={index} value={keyVal}>
                      {Juridictions[keyVal]}
                    </option>
                  ))
                }
              </CaijDropdown>
  }

  const renderIcon = () => {
    switch(type){
      case 'search':
        return <SearchIcon fontSize="small" /> 
      case 'dateRange':
        return <DateRangeIcon  fontSize="small" /> 
      case 'dropdown':
        return <DropdownIcon  fontSize="small" /> 
    }
  }

  return (
    <TableCell width={width}>
        <Box marginTop='1em' sx={{display: 'flex', height: '37px', alignItems: 'center'}}>
          <span>{colName}</span>
          { property?.split('|').some((value) => location.search.includes(value)) ? <FilterIcon fontSize="small" sx={{marginLeft: '10px'}} /> : ''}
          { type !== 'none' && (
              <>
                <Box sx={{ml:'10px'}} display='flex' alignItems='center'>
                  <CaijTooltip title={`Chercher par ${colName}`}>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleOpen}
                    > {renderIcon()}</IconButton>
                  </CaijTooltip>
                </Box>
                <Menu
                  onClose={handleClose}
                  keepMounted
                  sx={popover}
                  anchorEl={anchorEl}
                  open={open}
                  MenuListProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      maxWidth: '250px'
                    },
                  }}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                >
                  <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant='body2' sx={{ml: 2}}>{colName}</Typography>
                      <CaijTooltip title="Fermer">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClose}
                          sx={{marginRight: '10px'}}
                        >
                          <CloseIcon fontSize="small"/>
                        </IconButton>
                      </CaijTooltip>
                  </Box>
                  <Divider sx={{marginTop : '7px'}} />
                  {renderInputs()}
                </Menu>
            </>
          )
        }
        </Box>
    </TableCell>
  );
}

export default CaijTableHeaderFilter;