import { SxProps, TableCell, TableRow, Typography } from "@mui/material";
import { ReactNode } from "react";
import { CaijImage, labelConfig } from "src/common";
import Label from "../Label";

interface Params {
  label: string;
  activeTxt?: string;
  inactiveTxt?: string;
  children?: ReactNode;
  sxTableCell?: SxProps;
  sxTableRow?: SxProps;
  active?: boolean;
  confirm?: boolean;
  warning?: boolean;
  logoUrl?: string;
  whiteSpace?: any
};

const CaijTableRowParent = ({label, sxTableRow, sxTableCell, children}: Params) => (
  <TableRow sx={sxTableRow || null}>
    <TableCell sx={sxTableCell || null}>{label}</TableCell>
    <TableCell>
      {children}
    </TableCell>
  </TableRow>
);

export const CaijTableRow = ({label, sxTableRow, sxTableCell, whiteSpace, children}: Params) => (
  <CaijTableRowParent label={label} sxTableRow={sxTableRow} sxTableCell={sxTableCell}>
    <Typography variant='body2' sx={{color:'text.secondary', whiteSpace}}>
      {children}
    </Typography>
  </CaijTableRowParent>
);

export const CaijTableRowActive = ({label, sxTableRow, sxTableCell, active, activeTxt, inactiveTxt}: Params) => (
  <CaijTableRowParent label={label} sxTableRow={sxTableRow} sxTableCell={sxTableCell}>
    <Label color={active ? 'success' : 'error'}>
      {active ? activeTxt || labelConfig.active : inactiveTxt || labelConfig.inactive}
    </Label>
  </CaijTableRowParent>
);

export const CaijTableRowConfirm = ({label, sxTableRow, sxTableCell, confirm}: Params) => (
  <CaijTableRowParent label={label} sxTableRow={sxTableRow} sxTableCell={sxTableCell}>
    <Label color={confirm ? 'success' : 'error'}>
      {confirm ? labelConfig.yes : labelConfig.no}
    </Label>
  </CaijTableRowParent>
);

export const CaijTableRowWarning = ({label, sxTableRow, sxTableCell, warning}: Params) => (
  <CaijTableRowParent label={label} sxTableRow={sxTableRow} sxTableCell={sxTableCell}>
    <Label color={warning ? 'success' : 'error'}>
      {warning ? labelConfig.warning : labelConfig.noWarning}
    </Label>
  </CaijTableRowParent>
);

export const CaijTableRowImage = ({label, sxTableRow, sxTableCell, logoUrl}: Params) => (
  <CaijTableRowParent label={label} sxTableRow={sxTableRow} sxTableCell={sxTableCell}>
    {logoUrl && <CaijImage src={logoUrl} alt={logoUrl} />}
  </CaijTableRowParent>
);