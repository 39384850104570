import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  SvgIcon,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import type { CustomerResource } from 'src/common/types';
import { AccessModel, CardRequestPendingModel } from 'src/common';
import { XSquare as CancelIcon } from 'react-feather';
import printMessage from 'src/views/errors/MessageError';
import CustomerModel from 'src/model/customer/Customer';
import { ArrowLeftCircle } from 'react-feather';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { btnMarginRight, btnReturn, warning } from 'src/styles/customer';
import CaijDialogs from 'src/components/dialog';
import { Root } from 'src/components/styled';

interface CaijCardDeleteFormProps {
  cardRequestPendingModel: CardRequestPendingModel;
  customer?: CustomerResource;
  onHandleRefreshCustomer: () => Promise<void>;
  modifyCaijCard: (action: string) => void;
  onSubmit?: (values: CustomerResource) => void;
}

const icon = {
  height: '2em',
  width: '2em',
}

const CaijCardDeleteForm: FC<CaijCardDeleteFormProps> = ({ 
  cardRequestPendingModel,
  customer, 
  modifyCaijCard, 
  onHandleRefreshCustomer,
  onSubmit
}) => {
  const [removeOpen, setRemoveOpen] = useState<boolean>(false);
  const access = new AccessModel(cardRequestPendingModel.ResourceCode);
 
  const handleRemoveClickOpen = () => {
    setRemoveOpen(true);
  };
  const handleClose = () => {
    setRemoveOpen(false);
  };

  const handleSubmit = async (customer: CustomerResource): Promise<void> => {
    if(onSubmit){
      onSubmit(customer);
      return;
    }
    let model = new CustomerModel();
    if (customer && customer.id) {
      const response = await model.unassignCard(customer.id,"");
      if (!model.error) {
        if (response.status == 200) {
          customer.card = null;
        }
        printMessage({
          status: response.status,
          message: response.message,
        });
        await onHandleRefreshCustomer();
      }
	  }
    handleClose();
  }; 
  
  return (
    <Box>
      <Button
        onClick={() => modifyCaijCard('return')}
        sx={btnReturn}
        startIcon={
          <SvgIcon sx={icon} fontSize='small'>
            <ArrowLeftCircle />
          </SvgIcon>
        }
      >
        Supprimer la carte
      </Button>
      <Root>
        <Box sx={warning}>
          <Typography>ATTENTION: Êtes-vous certain de vouloir retirer ce numéro de carte de ce compte?</Typography>
          <Typography>La carte ne sera plus active et ne sera plus associée au membre.</Typography>
          <Typography>Cette action n'a aucun impact sur le solde présent dans le porte-monnaie numérique. Pour effectuer un remboursement complet du solde, veuillez créer une nouvelle transaction et sélectionner <i>Retrait</i> dans les opérations.</Typography>
        </Box>
      </Root>
      <Button 
        sx={btnMarginRight}
        onClick={handleRemoveClickOpen} 
        color='secondary' 
        variant='contained'
        disabled={!access.Gestion}
        startIcon={(
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        )}
      >
        Supprimer la carte
      </Button>
      <Button 
        type='reset' 
        onClick={() => modifyCaijCard('return')} 
        color='secondary' 
        variant='outlined'
        startIcon={(
          <SvgIcon fontSize="small">
            <CancelIcon />
          </SvgIcon>
        )}
      >
        Annuler
      </Button>
      <CaijDialogs
        dialog={cardRequestPendingModel.ConfigDialog}
        isOpen={removeOpen}
        onSubmit={() => handleSubmit(customer)}
        close={() => handleClose()}
      />
    </Box>
  );
};

CaijCardDeleteForm.propTypes = {
  customer: PropTypes.object,
  onHandleRefreshCustomer: PropTypes.func,
  modifyCaijCard: PropTypes.func,
  onSubmit:  PropTypes.func
};

CaijCardDeleteForm.defaultProps = {
  customer: {},
  onSubmit: null
};

export default CaijCardDeleteForm;
