import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';
import { X as DeleteIcon, CheckSquare as EnterIcon } from 'react-feather';
import { CaijTooltip } from 'src/common';

interface CaijSearchActionProps {
  handleClear: () => void;
  handleEnter?:  () => void;
  showEnterIcon?: boolean;
  showDocument?: boolean;
}

const root = {
  display: 'flex',
  alignItems: 'center'
}

const anchor = {
  padding: '0.4em 5px 0 5px', 
  cursor: 'pointer', 
  fontSize: '14px'
}

const CaijSearchAction: FC<CaijSearchActionProps> = ({ showEnterIcon, showDocument = false, handleClear, handleEnter } : CaijSearchActionProps) => {
  
  const clearContent = (
    <CaijTooltip title="Effacer">
      <a onClick={() => handleClear()} style={{...anchor}}>
        <SvgIcon fontSize='small'>
          <DeleteIcon />
        </SvgIcon>
      </a>
    </CaijTooltip>
  );
  
  if(showDocument){
    return clearContent;
  }

  return (
    <div style={{...root}}>
      <CaijTooltip title="Chercher">
        <a onClick={() => handleEnter()} style={{...anchor, display: showEnterIcon ? 'block' : 'none', marginRight: '5px'}}>  
          <SvgIcon fontSize='small' >
            <EnterIcon />
          </SvgIcon>
        </a>
      </CaijTooltip>
      {clearContent}
    </div>
  )
};

CaijSearchAction.propTypes = {
  handleClear: PropTypes.func,
  handleEnter: PropTypes.func
};

export default CaijSearchAction;
