import { FC, useState } from "react";
import { Authorize, CaijButton, Mode, useNavigate } from "src/common";
import AppModel from "src/model/App";

interface HeaderButtonProps {
    id: string|number;
    model?: AppModel;
    onClick?: any;
    editMode?: boolean;
}

const HeaderEditButton: FC<HeaderButtonProps> = ({ id, editMode, model, onClick}) => {
    const navigate = useNavigate();
    let [isCreateAuth, setIsCreateAuth] = useState<boolean>(false)
    return (
        <Authorize
            resourceCode={model.ResourceCode}
            mode={Mode.edit}
            onIsAuth={l => setIsCreateAuth(l)}>
            <CaijButton
                type='edit'
                color='secondary'
                variant='contained'
                disabled={!isCreateAuth || editMode}
                onHandleClick={() => onClick ? onClick() : navigate(model.Path.getEdit(id))}
                sx={{width: '100px', mr:2}}
            >
                Éditer
            </CaijButton>
        </Authorize>
    )
}

export default HeaderEditButton;