import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  CustomerAddressDto,
  CustomerResource,
  CustomerResourceForEdit,
} from 'src/common/types';
import { 
  Box, 
  Button, 
  Dialog, 
  Grid, 
  SvgIcon, 
  TextField, 
  Typography 
} from '@mui/material';
import {
  Link as RouterLink, 
  Formik,
  handleChange,
  labelConfig,
  CaijInput,
  handleChangeDateChange,
  Authorize,
  CustomerModel,
  CaijButtonSubmit,
  CaijButtonReset,
  CaijInputPhone,
  Mode,
  LanguageProfile,
  CaijDatePicker,
  btnSubmit,
  Conflit,
  DELAY_LONG_POLLING,
  AccountExpiryMode,
  MSG_SEND_EMAIL_MEMBER,
  SubscriptionModel,
  CaijCheckbox,
  handleChangeCheckBox,
  AccessModel
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import CustomerForm from 'src/components/customer/customer/Forms/CustomerForm';
import CustomerLawPraticeFieldInfo from '../CustomerLawPraticeFieldInfo';
import CustomerAddressForm from '../Forms/CustomerAddressForm';
import {$enum} from "ts-enum-util";
import { PlusSquare as AddIcon } from 'react-feather';
import useLawPraticeField from 'src/hooks/useLawPraticeField';
import useInterval from 'src/hooks/polling/useInterval';
import RefreshProfilePageDialog from 'src/components/customer/customer/Forms/RefreshProfilePageDialog';
import { validateEditProfileSchema } from '../../Schema';

export interface CustomerProfileEditFormProps {
  model: CustomerModel;
  customer: CustomerResource;
  onHandleRefreshCustomer: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: CustomerResource) => void;
}

const CustomerProfileEditForm: FC<CustomerProfileEditFormProps> = ({
  model,
  customer,
  onHandleRefreshCustomer,
  onSwitchMode,
  onSubmit
}) => {
  const access = new AccessModel(model.ResourceCode);
  const [isAuth, setIsAuth] = useState<boolean>();
  const { selectedLawPracticeFields } = useLawPraticeField();
  const [conflit, setConflit] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState(true);
  const { 
    MemberPhone, MemberPhoneExt, MemberCellPhone, MemberOrganization, 
    MemberJob, MemberNoCaij, MemberExtIdentifier, MemberAdditionalInfo,
    MemberAccessBetaCaij, MemberExpiration, MemberBirthday, MemberLanguage 
   } = model;
  const neverExpire = SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.NeverExpire);

  const getCustomerById = useCallback(async () => {
    const { id, modified } = customer;
    if(await model.getCustomerById(id,modified)){
      setIsRunning(false);
      setConflit(true);
    }
  },[customer]);

  useInterval(async () => {
    await getCustomerById();
  },isRunning ? DELAY_LONG_POLLING : null);

  useEffect(() => {
    (async () => {
      await getCustomerById();
    })()
  },[]);

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };
  
  const handleSubmit = async (values: CustomerResource): Promise<void> => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    if(isRunning) {
      setIsRunning(false);
      setConflit(false);
    }
    const { subscriptionGroup, addresses, endorsedBy } = values;
    let submitData = values as CustomerResourceForEdit;
    const customerId = submitData.id;
    if (submitData && customerId) {
      const model = new CustomerModel();
      model.saveRecords(submitData, subscriptionGroup, endorsedBy, selectedLawPracticeFields);
      submitData.addresses = addresses.filter((addresse: CustomerAddressDto) => (
        addresse.name ||
        addresse.line1 || 
        addresse.line2 || 
        addresse.city || 
        addresse.state ||
        addresse.country ||
        addresse.zip
      ));
      const response = await model.updateCustomer(submitData);
      const { error } = model;
      if(error){
        if(error.status === Conflit){
          setConflit(true);
        }
      }else{
        const { status, message } = response;
        const { email } = submitData;
        const emailChanged = customer.email !== email;
        if (emailChanged){
          model.printEmailMessage(status, MSG_SEND_EMAIL_MEMBER + 'pour l\'avertir que son courriel a été modifié');
        }
        printMessage({status, message});
        await onHandleRefreshCustomer();
      }
    }
  };
  
  return (
    <>
      <Formik
        initialValues={CustomerModel.getInitialValues(customer)}
        validationSchema={validateEditProfileSchema(model)}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} xs={12}>
                <CustomerForm
                  model={model}
                  disabledSubscription={true}
                  initialSubscriptionGroup={values.subscriptionGroup}
                  customer={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <Box display='flex'>
                  <Box sx={{ width: '60%', marginRight: '1em' }}>
                    <CaijInputPhone
                      name={MemberPhone.Name}
                      id={MemberPhone.Name}
                      label={MemberPhone.Label}
                      value={values.phone}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      inputProps={{'data-testid': MemberPhone.Name, maxLength: MemberPhone.MaxLength }}
                      InputLabelProps={{ shrink: true }}
                      onHandleBlur={handleBlur}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                  <Box sx={{ width: '30%' }}>
                    <CaijInput
                      name={MemberPhoneExt.Name}
                      id={MemberPhoneExt.Name}
                      label={MemberPhoneExt.Label}
                      value={values.phoneExt}
                      error={Boolean(touched.phoneExt && errors.phoneExt)}
                      helperText={touched.phoneExt && errors.phoneExt}
                      InputLabelProps={{ shrink: true }}
                      inputAttr={{'data-testid': MemberPhoneExt.Name,  maxLength: MemberPhoneExt.MaxLength}}
                      onHandleBlur={handleBlur}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                </Box>
                <Box display='flex' flexDirection='column' sx={{ width: '60%' }}>
                  <CaijInputPhone
                    name={MemberCellPhone.Name}
                    id={MemberCellPhone.Name}
                    label={MemberCellPhone.Label}
                    value={values.cellPhone}
                    error={Boolean(touched.cellPhone && errors.cellPhone)}
                    helperText={touched.cellPhone && errors.cellPhone}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{'data-testid': MemberCellPhone.Name, maxLength: MemberCellPhone.MaxLength }}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Box sx={{ width: '60%', margin: '0.8em 0' }}>
                    <CaijDatePicker
                      name={MemberBirthday.Name}
                      label={MemberBirthday.Label}
                      inputProps={{'data-testid': MemberBirthday.Name}}
                      value={String(values.birthdate)}
                      onHandleChange={(l) => setFieldValue(MemberBirthday.Name, l)}
                    />
                  </Box>
                </Box>
                <CaijInput
                  name={MemberOrganization.Name}
                  id={MemberOrganization.Name}
                  label={MemberOrganization.Label}
                  value={values.organization}
                  error={Boolean(touched.organization && errors.organization)}
                  helperText={touched.organization && errors.organization}
                  InputLabelProps={{ shrink: true }}
                  inputAttr={{'data-testid': MemberOrganization.Name,  maxLength: MemberOrganization.MaxLength }}
                  onHandleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <CaijInput
                  name={MemberJob.Name}
                  id={MemberJob.Name}
                  label={MemberJob.Label}
                  value={values.job}
                  error={Boolean(touched.job && errors.job)}
                  helperText={touched.job && errors.job}
                  InputLabelProps={{ shrink: true }}
                  inputAttr={{'data-testid': MemberJob.Name, maxLength: MemberJob.MaxLength }}
                  onHandleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                  sx={{mb:1}}
                />
                {
                  values.addresses && values.addresses.map((addresse: CustomerAddressDto , index: number) => (
                    <Box key={index}>
                      <CustomerAddressForm
                        model={model}
                        count={index}
                        customer={values}
                        errors={errors}
                        touched={touched}
                        onHandleBlur={handleBlur}
                        onHandleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                  ))
                }
                <Grid item lg={12} md={12} xs={12}>
                  <Button
                      to={'#'}
                      component={RouterLink}
                      onClick={(e:React.MouseEvent<HTMLAnchorElement>) => {
                        values.addresses = [...values.addresses, {
                          preferred: false,
                          name: '',
                          line1: '',
                          line2: '',
                          city: '',
                          zip: '',
                          state: '',
                          country: ''}];
                        setFieldValue('addresses', values.addresses);
                      }}
                  >
                    <Box width={25} sx={{marginBottom: '-0.2em' }}>
                      <SvgIcon fontSize='small'>
                        <AddIcon />
                      </SvgIcon>
                    </Box>
                    <Box>
                      <Typography sx={{fontSize: '14px'}}>Ajouter une nouvelle adresse</Typography>
                    </Box>
                  </Button>
                </Grid>
              </Grid>
              <Grid item lg={4} md={4} xs={12} mt={2}>
                  { /*
                     s’il y a une expiration, on l’affiche peu importe le mode d’expiration du compte. 
                     Il y a un cas spécial avec le Barreau qu’on met une date d’expiration afin de donner
                     une période de grâce avant de désactiver le compte (Lorsque le Barreau demande de faire 
                     des formations au CAIJ afin d'être membre du Barreau, le membre doit avoir son compte actif 
                     pour pouvoir faire ses formations)
                    */
                    ((customer.subscriptionGroup?.accountExpiryMode !== neverExpire) || customer.expiration !== null || customer.endorsed) && (
                      <Box sx={{mb:2}}>
                        <CaijDatePicker 
                          name={MemberExpiration.Name}
                          required
                          value={String(values.expiration)}
                          label={MemberExpiration.Label}
                          inputProps={{"data-testid": MemberExpiration.Name}}
                          helperText={touched.expiration && errors.expiration}
                          onHandleChange={(d) => setFieldValue(MemberExpiration.Name, d)}
                      />
                     </Box>
                    )
                  }
                  <TextField
                    required
                    label={MemberLanguage.Label}
                    id={MemberLanguage.Name}
                    name={MemberLanguage.Name}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(MemberLanguage.Name,e.target.value);
                    }}
                    select
                    SelectProps={{ native: true }}
                    value={values.language}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    inputProps={{'data-testid': MemberLanguage.Name }}
                    sx={{ marginBottom: 3, marginLeft: 0, width: '100%' }}
                  >
                    {
                      $enum(LanguageProfile).map((value, key) => <option value={key} key={key}>{value}</option>)
                    }
                  </TextField>
                  <CaijInput
                    name={MemberNoCaij.Name}
                    id={MemberNoCaij.Name}
                    required
                    label={MemberNoCaij.Label}
                    value={values.noCaij}
                    disabled
                    InputLabelProps={{ shrink: true, required: true }}
                    inputAttr={{'data-testid': MemberNoCaij.Name, maxLength: MemberNoCaij.MaxLength }}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <CaijInput
                    name={MemberExtIdentifier.Name}
                    id={MemberExtIdentifier.Name}
                    label={MemberExtIdentifier.Label}
                    value={values.extIdentifier}
                    disabled={!access.Gestion}
                    InputLabelProps={{ shrink: true }}
                    inputAttr={{'data-testid': MemberExtIdentifier.Name, maxLength: MemberExtIdentifier.MaxLength}}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <CaijInput
                    name='statusInfo'
                    id='statusInfo'
                    label={labelConfig.statusInfo}
                    value={values.statusInfo}
                    disabled
                  />
                  <CaijInput
                    name='groupCategory'
                    id='groupCategory'
                    label={labelConfig.groupCategory}
                    value={values.groupCategory}
                    disabled
                  />
                  <CaijInput
                    name={MemberAdditionalInfo.Name}
                    id={MemberAdditionalInfo.Name}
                    label={MemberAdditionalInfo.Label}
                    value={values.additionalInfo}
                    error={Boolean(touched.additionalInfo && errors.additionalInfo)}
                    helperText={touched.additionalInfo && errors.additionalInfo}
                    InputLabelProps={{ shrink: true }}
                    inputAttr={{'data-testid': MemberAdditionalInfo.Name, maxLength: MemberAdditionalInfo.MaxLength }}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <CaijCheckbox
                    name={MemberAccessBetaCaij.Name}
                    id={MemberAccessBetaCaij.Name}
                    checked={values.accessBetaCaij}
                    value={values.accessBetaCaij}
                    label={MemberAccessBetaCaij.Label}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    inputProps={{'data-testid': MemberAccessBetaCaij.Name}}
                    setFieldValue={setFieldValue}
                    sx={{mb:3}}
                  />
                <CustomerLawPraticeFieldInfo model={model} customer={customer} editMode />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Authorize resourceCode={model.ResourceCode} mode={customer.id ? Mode.edit : Mode.add} onIsAuth={l => setIsAuth(l)}>
                <CaijButtonSubmit title="CustomerProfileSubmitBtn" disabled={!isAuth || isSubmitting} sx={btnSubmit} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
            </Box>
          </form>
        )}
      </Formik>
      <Dialog maxWidth='sm' fullWidth open={conflit} >
        <RefreshProfilePageDialog 
          setConflit={setConflit} 
          onHandleRefreshCustomer={() => window.location.reload()}
        />
      </Dialog>
    </>
  );
};

CustomerProfileEditForm.propTypes = {
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

CustomerProfileEditForm.defaultProps = {
  onSubmit: null
};

export default CustomerProfileEditForm;
