import type { DocumentCollectionsResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';
import { Paged } from 'src/types/pagination';

type FetchCollectionsAction = {
    type: 'FETCH_COLLECTIONS';
    payload: {
      collections: DocumentCollectionsResource[];
      paged?: Paged;
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchCollectionAction = {
  type: 'FETCH_COLLECTION';
  payload: {
    collection: DocumentCollectionsResource;
    isLoading?: boolean;
  }
};

type Action = FetchCollectionsAction |
              FetchCollectionAction;

export interface DocCollectionsReturnState {
  collections?: DocumentCollectionsResource[];
  collection?: DocumentCollectionsResource;
  paged?: Paged;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface DocCollectionsState {
  collections: DocumentCollectionsResource[];
  collection: DocumentCollectionsResource;
  paged: Paged;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: DocCollectionsState, action: Action): DocCollectionsReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_COLLECTIONS: {
      const { collections, paged, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        collections,
        paged,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_COLLECTION: {
      const { collection, isLoading } = action.payload;
      return {
        ...state,
        collection,
        isLoading
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
