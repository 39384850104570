import { Table, TableBody, TableRow, TableCell, Typography, TableHead, Divider, Chip } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { Authorize, CaijSelect, ReperageModel, tableRow } from "src/common"
import { ReperageResource } from "src/common/types"
import CardHeaderDetail from "src/components/card/CardHeaderDetail"
import MyCard from "src/components/card/MyCard"
import { labelConfig } from "src/config";
import { Mode, ReperageFolderStatus, ReperageFolderStatusColor } from "src/enum"
import StatusForm from "../Forms/StatusForm"
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

export interface ReperageInfoProps {
    reperage: ReperageResource;
    onChangeReperageStatus: (status: string, reason?: string) => void;
    onChangeReperageName: (name: string) => void;
    model: ReperageModel;
    isLoading: boolean;
}

interface ReasonOptionType {
    inputValue?: string;
    title: string;
};

const ReperageInfo: FC<ReperageInfoProps> = ({ reperage, onChangeReperageStatus, onChangeReperageName, model, isLoading }) => {
    const [isEditAuth, setIsEditAuth] = useState<boolean>(false);

    return (
        <MyCard>
            <CardHeaderDetail title="Repérage" />
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>
                                {reperage.name}
                        </TableCell>
                    </TableRow>
                    <Authorize
                        resourceCode={model.ResourceCode}
                        mode={Mode.edit}
                        onIsAuth={setIsEditAuth}
                    >
                        {!isEditAuth || reperage.archived ?
                            <TableRow>
                                <TableCell sx={null}>
                                    {labelConfig.status}
                                </TableCell>
                                <TableCell>
                                    <Chip sx={{ fontSize: "1rem", padding: "0 1rem" }} color={ReperageFolderStatusColor[reperage.status]} label={ReperageFolderStatus[reperage.status]} />
                                </TableCell>
                            </TableRow>
                            :
                            <StatusForm
                                onChangeReperageStatus={onChangeReperageStatus}
                                isLoading={isLoading}
                                reperage={reperage}
                            />
                        }
                    </Authorize>
                    <TableRow>
                        <TableCell sx={null}>
                            {labelConfig.type}
                        </TableCell>
                        <TableCell>
                            <Typography
                                variant="body2"
                                sx={{ color: "text.secondary" }}
                            >
                                {reperage.types.join(', ')}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={null}>
                            {model.Infos.Label}
                        </TableCell>
                        <TableCell>
                            {parse(reperage.infos)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </MyCard>
    )
};

ReperageInfo.propTypes = {
    reperage: PropTypes.object.isRequired,
    onChangeReperageName: PropTypes.func.isRequired,
    onChangeReperageStatus: PropTypes.func.isRequired,
    model: PropTypes.instanceOf(ReperageModel).isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default ReperageInfo;