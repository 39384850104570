import React, { FC } from 'react';
import type { CustomerResource } from 'src/common/types';
import { formatDate, getCardBalance, labelConfig } from 'src/common';
import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  SvgIcon,
  Box,
  Divider,
} from '@mui/material';
import { CreditCard as CardIcon } from 'react-feather';
import {  
  box, 
  container3,
  grey, 
  greyed, 
  italic, 
  large, 
  largeAndGreen, 
  lightGray 
} from 'src/styles/customer';
import AccessCardRequest from 'src/components/access/AccessCardRequest';

interface CaijCardInfoProps {
  customer: CustomerResource;
  summaryView?: boolean;
  modifyCaijCard: (action: string) => void;
  onTransactionMode: () => void;
  onHandleRefreshCustomer: (value: CustomerResource) => Promise<void>;
}

const CaijCardInfo: FC<CaijCardInfoProps> = ({ 
  customer, 
  summaryView, 
  onTransactionMode, 
  modifyCaijCard, 
  onHandleRefreshCustomer }) => {
  // const balanceCalculator = useCallback((card, prints) => {
  //   let balance = 0;
  //   if (impressionTransactions && cardTransactions) {
  //     let impressionsBalance = 0;
  //     let cardTransBalance = 0;
  //     impressionTransactions.forEach((item) => {
  //       impressionsBalance += item.cost;
  //       console.log(item.cost);
  //     });
  //     cardTransactions.forEach((item) => {
  //       cardTransBalance += item.amount;
  //       console.log(item.amount);
  //     });
  //     balance = impressionsBalance + cardTransBalance;
  //   }
  //   setCardBalance(balance);
  // }, []);

    if (summaryView) {
      return (
        <Box display='flex'>
          <SvgIcon fontSize='small'>
            <CardIcon />
          </SvgIcon>
          <Box marginLeft='0.5em' display='flex' flexDirection='column'>
            <Box display='flex' alignItems='center'>
              <Typography sx={{ fontWeight: 'bold' }}>carte CAIJ</Typography>
            </Box>
            <Typography sx={greyed}>{labelConfig.card}</Typography>
            <Typography>{customer.card ? customer.card : 'Aucune'}</Typography>
            <Typography sx={greyed}>{labelConfig.balance}</Typography>
            <Typography>{getCardBalance(customer.cardBalance)} $</Typography>
          </Box>
        </Box>
      );
    }
    return (
      <div>
        { customer.cardBalance !== null && (
          <>
            <Card sx={box}>
              <Typography sx={lightGray}>Solde du compte</Typography>
              <Typography sx={largeAndGreen}>{getCardBalance(customer.cardBalance)} $</Typography>
            </Card>
            <Divider sx={{margin:'0 1em 1em 0',width:'100%'}}/>
            </>
          )
        }
        <Card sx={box}>
          <Typography sx={grey}>Numéro de la carte CAIJ</Typography>
          <Typography sx={customer.card ? large : italic}>
            {customer.card ? customer.card : 'Aucune'}
          </Typography>
        </Card>
        <Box sx={container3}>
          <AccessCardRequest
            customer={customer}
            modifyCaijCard={modifyCaijCard}
          />
          {/* <CaijCardAssignForm onHandleRefreshCustomer={onHandleRefreshCustomer} customer={customer} /> */}
          {/* <CaijCardDeleteForm onHandleRefreshCustomer={onHandleRefreshCustomer} customer={customer} /> */}
        </Box>
      </div>
    );
};

CaijCardInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  onTransactionMode: PropTypes.func,
  onHandleRefreshCustomer: PropTypes.func,
  modifyCaijCard: PropTypes.func,
  summaryView: PropTypes.bool,
};

CaijCardInfo.defaultProps = {
  customer: {},
  summaryView: false,
};

export default React.memo(CaijCardInfo);
