import { Box, Card } from "@mui/material"
import { FC } from "react";
import {  
  CustomerModel,
  getCustomerStatusLabelColor,
  Label,
  PathModel 
} from "src/common"
import { CustomerResource } from "src/common/types";
import CaijAvatar from "../../Details/CaijAvatar";
import { avatar } from "src/styles/customer";
import SplitName from "../../splitName";
import CaijTypography from "src/components/typography";

interface LightProps {
  path: PathModel;
  customer: CustomerResource;
  logo: string;
}

const getSubscriptionInfo = (customer: CustomerResource) => {
  let result: String[] = [];
  if(customer.subscription)
    result.push(customer.subscription.name);
  if(customer.subscriptionGroup)
    result.push(` - ${customer.subscriptionGroup.name}`);
  if(customer.extIdentifier)
    result.push(` - ${customer.extIdentifier}`);
  return result;
}

const Light: FC<LightProps> = ({customer, logo}) => (
  <Card sx={{ display: 'flex', alignItems: 'center', margin: '15px 15px', padding: '15px', background: theme => theme.palette.background.paper }}>
    <CaijAvatar customer={customer} logo={logo} sx={{...avatar, margin: '10px 10px 0 0', height: '80px', width: '80px', fontSize: '1.5em'}} />
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
      <Box display="flex" alignItems="center">
        <CaijTypography variant='h6' color="text.primary" sx={{fontWeight: 'bold', marginRight: '10px'}}>{customer.fullname}</CaijTypography>
        <CaijTypography variant='body2' color="text.secondary">{customer.noCaij}</CaijTypography>
      </Box>
      <SplitName firstName={customer.firstname} lastName={customer.lastname} />
      <CaijTypography variant='body2' color="text.secondary">Courriel : {customer.email}</CaijTypography>
      <CaijTypography variant='body2' color="text.primary">
        <span style={{fontWeight: 'bold'}}>Abonnement : </span> 
        {getSubscriptionInfo(customer).map(s => s)}
      </CaijTypography>
      { customer.card && (
          <CaijTypography variant='body2' color="text.primary">
            <span style={{fontWeight: 'bold'}}>Carte CAIJ : </span>
            {customer.card}
          </CaijTypography>
        )
      }
    </Box>
    <Label color={getCustomerStatusLabelColor(customer.status)}>{CustomerModel.getStatusByKey(customer.status)}</Label>
  </Card>
);

export default Light;