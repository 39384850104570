import React, { useState, FC, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TextField,
  Collapse, 
  IconButton, 
  SvgIcon,
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Typography, 
  ClassNameMap,
} from '@mui/material';
import { 
  EmployeeModel, 
  EmptyList, 
  ErrorType,
  fetchCustomerHistoryList, 
  FORMAT_DATE_PICKER, 
  Formik, 
  FormikProps, 
  labelConfig, 
  _history,
  tableRow,
  CaijDatePicker,
  formatDateTimeZoneTZ2,
  ITEMS_PER_PAGE,
} from 'src/common';
import { 
  CustomerHistoryResource, 
  EmployeesResource 
} from 'src/common/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import * as Yup from 'yup';
import { CheckCircle as ConfirmIcon } from 'react-feather';
import HistoryModel from 'src/model/customer/History';
import CustomerHistory from './CustomerHistory';
import CaijTable from 'src/components/table/CaijTable';
import dayjs from 'dayjs';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';

const item = {
  display: 'flex',
  alignItems: 'center'
};

const typo = {
  color: 'text.secondary', 
  display: 'flex', 
  alignItems: 'center'
};

interface Params {
  change:string;
  prevChange: string; 
  employees: EmployeesResource[];
  note: string; 
  resourceName?: string;
  stateBefore?: string;
};

const note = 'NOTES';

const renderItem = (item: Params) : JSX.Element => {
  const history = new HistoryModel();
  const prevHistory = new HistoryModel();
  const customerHistory = new CustomerHistory();
  const data: any = JSON.parse(item.change);
  if(item.resourceName !== note){//Customer
    history.Change = new Map<string, any>(Object.entries(data));
    if(item.prevChange){
      const prevData: any = JSON.parse(item.prevChange);
      prevHistory.Change = new Map<string, any>(Object.entries(prevData));
      customerHistory.PrevChange = prevHistory.Change;
    }
    const { note } = item;
    return (
      <>
        { customerHistory.getCustomerInfo(history, prevHistory, note) }
        { customerHistory.getSubscriptionInfo(history, prevHistory) }
        { customerHistory.getGroupInfo(history, prevHistory) }
        { customerHistory.getLawDomainInfo(history, prevHistory) }
        { customerHistory.getAddressInfo(history, prevHistory) }
        { customerHistory.getCustomerEndorsedInfo(history, prevHistory) }
      </>
    )
  }else{//Notes
    if(item.prevChange) 
      prevHistory.Change = JSON.parse(item.prevChange);
    history.Change = data;
    const { employees, stateBefore } = item;
    return (
      <>
        { customerHistory.getCustomerNoteInfo(history.Change, prevHistory.Change , employees, stateBefore)}
      </>
    );
  }
};

function Row(props: { row: CustomerHistoryResource, custId: number, employees: EmployeesResource[] }) {
  const { row, custId, employees } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<Params>(null);
  useEffect(() => {
    (async () => {
      if(open){
        let params: Params;
        const model = new HistoryModel();
        let customerHistories:CustomerHistoryResource[];
        if(row.resourceName !== note){
          customerHistories  = await model.getCustomerHistory(row.date, custId.toString());
        }else{
          customerHistories  = await model.getCustomerNoteHistory(row.date, custId.toString());
        }
        if(!model.error){
          params = {
            change: customerHistories[0].change, 
            prevChange: customerHistories[1]?.change,
            note: customerHistories[0].note,
            resourceName: customerHistories[0].resourceName,
            stateBefore: customerHistories[0]?.stateBefore,
            employees        
          };
        }
        setParams(params);
        setLoading(true);
      }
    })();
  },[open, custId,setLoading,setParams])

  return (
    <>
      <TableRow sx={{'& > *': { borderBottom: 'none' }}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2" sx={typo}>
              {row.by}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="body2" sx={typo}>
            {formatDateTimeZoneTZ2(row.date, 11, 22)}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow sx={tableRow}>
        <TableCell sx={{ pb: 0, pt: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            { loading ? renderItem(params) : null }
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

interface Request {
  email:string; 
  startDate:string;
  endDate:string;
}

interface ModificationsHistoryInfoProps {
  custId: number;
};

const ModificationsHistoryInfo: FC<ModificationsHistoryInfoProps> = ({ custId }) => {
  const [page, setPage] = useState<number>(0);
  const [employees, setEmployees] = useState<EmployeesResource[]>([]);
  const [submitting, setSubmiting] = useState<boolean>(false);
  const formRef = useRef<FormikProps<Request>>(null);
  const [state, dispatch] = useReducer(_history,{ 
    customerHistory:[],
    paged: {
      totalCount: 0,
      pageSize: ITEMS_PER_PAGE,
      currentPage: 1,
      totalPages: 0,
    },
    isLoading: false,
    isEmptyList:true,
    isAuthorize: false
  });
  
  const getSubmitData = (values: Request) : Request => {
    if(values){
      return {
        email: values.email,
        startDate: values.startDate,
        endDate: values.endDate,
      };
    }
    return;
  }

  useEffect(() => {
    const initialise = async () => {
        const model = new EmployeeModel();
        model.skipHandleError = true;
        const employees = await model.getEmployees();
        if(!model.error){
          setEmployees(employees);
        }
    };
    initialise();
  },[]);

  useEffect(() => {
    (async () => {  
      await doQuery.apply(null,[0, ITEMS_PER_PAGE]);
    })();
  },[]);
  
  const doQuery = async (page: number, values:Request) : Promise<void> => {
    const model = new HistoryModel;
    let startDate = null
    let endDate = null;
    let email = null
    if(values !== undefined){
      startDate = values.startDate ? dayjs(values.startDate, FORMAT_DATE_PICKER).toDate() : null;
      endDate = values.endDate ? dayjs(values.endDate, FORMAT_DATE_PICKER).toDate() : null;
      email = values.email;
    }
    const results = await model.getCustomerHistoryList({
      sortOptions: 'desc',
      pageNumber: page + 1,
      pageSize: ITEMS_PER_PAGE,
      customerId: custId.toString(),
      modifedBySearchTerm: email,
      startDateSearchTerm: startDate,
      endDateSearchTerm: endDate
    });
    const { error } = model;
    if(!error){
      dispatch(fetchCustomerHistoryList(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0, true));
    }
  }

  const handleChangePage = async (page: number) => {
    if(submitting){
      await doQuery.apply(null,[page, getSubmitData(formRef.current.values)]);
    }else{
      await doQuery.apply(null,[page]);
    }
  }

  const render = () : JSX.Element | JSX.Element[] => {
    const { isLoading, isEmptyList, customerHistory } = state;
    if(isLoading && isEmptyList){
      return <EmptyList />
    }else if(isLoading && !isEmptyList) {
      return (
        <>
          <CaijTable>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Employé</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && customerHistory.map((row : CustomerHistoryResource, index: number) => {
                  return <Row key={index} row={row} custId={custId} employees={employees} />
                })}
              </TableBody>
          </CaijTable>
          <CaijTablePagination
            showTablePaginationLight 
            len={state.paged.totalCount}
            page={page}
            setPage={async (l) => {
              await handleChangePage(l);
              setPage(l);
            }}
          />
       </>
      )
    }
  }

  return (
  <>
     <Formik
        innerRef={formRef}
        initialValues={{email: '', startDate: null, endDate: null}}
        validationSchema={Yup.object().shape({
          endDate: Yup.string().nullable()
          .transform((curr, orig) => orig === null ? null : curr)
          .test(labelConfig.endDate, HistoryModel.formatError(ErrorType.endDateMustBeLater, labelConfig.endDate), (endDate: string) => {
            const startDate = formRef.current.values.startDate;
            if(endDate && startDate){
              return HistoryModel.endDateMustBeGreaterThanOrEqualStartDate(dayjs(startDate, FORMAT_DATE_PICKER).toDate(), dayjs(endDate,FORMAT_DATE_PICKER).toDate());
            }
            return true;
          }),
        })}
          onSubmit={async (values) => {
            await doQuery(0, getSubmitData(values));
            setSubmiting(!submitting);
          }
        }
      >
         {props => (
         <form onSubmit={props.handleSubmit}>
            <Box mt={2} mb={2} display='flex' alignItems='center' justifyContent='flex-start'>
              <Box mr={3} mb={3}>
              <TextField
                  label='Employé'
                  name='email'
                  onChange={props.handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={props.values.email}
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=''>Tous</option>
                  {employees && employees.map((employee:EmployeesResource) => (
                    <option key={employee.id} value={employee.email}>
                      {employee.fullname}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Box mr={3}>
                <CaijDatePicker
                  name='startDate'
                  label={labelConfig.startDate}
                  value={String(props.values.startDate)}
                  onHandleChange={(l) => props.setFieldValue('startDate',l)}
                />
              </Box>
              <Box mr={3}>
                <CaijDatePicker
                  name='endDate'
                  label={labelConfig.endDate}
                  value={String(props.values.endDate)}
                  helperText={props.touched.endDate && props.errors.endDate}
                  onHandleChange={(l) => props.setFieldValue('endDate',l)}
                />
              </Box>
              <Box sx={{marginBottom: '5px'}}>   
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={
                    <SvgIcon>
                      <ConfirmIcon />
                    </SvgIcon>
                  }
                >
                  OK
                </Button>
              </Box>
            </Box>
         </form>
       )}
      </Formik>
      {render()}
  </>
  );
};

ModificationsHistoryInfo.propTypes = {
  custId: PropTypes.number.isRequired
};

export default ModificationsHistoryInfo;
