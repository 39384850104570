import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, ListItem, SvgIcon } from '@mui/material';
import type { DocumentResource } from 'src/common/types';
import GeneralInfo from './GeneralInfo';
import DateInfo from './DateInfo';
import VisibilityInfo from 'src/components/visibility/VisibilityInfo';
import SpecialFilesModel from 'src/model/content/SpecialFiles';
import { CaijImage, labelConfig } from 'src/common';
import CaijTypography from 'src/components/typography';
import {Image as ImageIcon} from 'react-feather';

interface DetailsProps {
  model: SpecialFilesModel;
  document: DocumentResource;
}

const noStyle  = {
  width: '100%',
  justifyContent: 'flex-start',
  fontWeight: 'normal',
  justifyItems: 'flex-start',
  display: 'flex'
}

const Details: FC<DetailsProps> = ({ model, document }) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={4} xl={4} xs={12}>
        <ListItem sx={noStyle}>
          <GeneralInfo document={document} model={model} />
        </ListItem>
      </Grid>
      <Grid item md={4} xl={4} xs={12}>
        <ListItem sx={noStyle}>
          <VisibilityInfo content={document} />
        </ListItem>
        <ListItem>
          <Box sx={{ mb: '2em' }} display='flex'>
            <SvgIcon fontSize='small'>
              <ImageIcon />
            </SvgIcon>
            <Box display='flex' marginLeft='0.5em' flexDirection='column'>
              <Box display='flex' alignItems='center'>
                <CaijTypography sx={{ fontWeight: 'bold' }}>{model.Cover.Label}</CaijTypography>
              </Box>
              {document.cover && <CaijImage src={document.cover} />}
            </Box>
          </Box>
        </ListItem>
      </Grid>
      <Grid item md={4} xl={4} xs={12}>
        <ListItem sx={noStyle}>
          <DateInfo document={document} />
        </ListItem>
      </Grid>
    </Grid>
  );
}

Details.propTypes = {
  document: PropTypes.object.isRequired
};

export default Details;
