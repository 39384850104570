import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import type { LibraryServiceResource } from 'src/common/types';
import { labelConfig, Label, tableRow, LibraryServiceModel } from 'src/common';
import { tableCell } from 'src/styles/libraryService';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface LibraryServiceFrInfoProps {
  model: LibraryServiceModel;
  libraryService: LibraryServiceResource;
}

const LibraryServiceFrInfo: FC<LibraryServiceFrInfoProps> = ({
  model,
  libraryService
}) => {
  const { nameFr, detailsFr } = libraryService;
  const { NameFr, DetailsFr } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Service offert par la bibliothèque" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              {NameFr.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {nameFr}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              {DetailsFr.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {detailsFr}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              {labelConfig.requireQty}
            </TableCell>
            <TableCell>
              <Label color={libraryService.requireParameters ? 'success' : 'error'}>
                { libraryService.requireParameters ? labelConfig.yes : labelConfig.no}
              </Label>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LibraryServiceFrInfo.propTypes = {
  libraryService: PropTypes.object.isRequired
};

export default LibraryServiceFrInfo;
