import type { CoveoLogs, CoveoSourceDto } from 'src/common/types';
import * as actionTypes from '../../ActionTypes';

export const fetchCoveoLogs = (coveoLogs: CoveoLogs[], coveoSources: CoveoSourceDto[], isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_COVEO_LOGS',
  payload: {
    coveoLogs: CoveoLogs[],
    coveoSources: CoveoSourceDto[],
    isLoading?: boolean,
    isEmptyList?: boolean
  }
} => ({
  type: actionTypes.FETCH_COVEO_LOGS,
  payload: {
    coveoLogs,
    coveoSources,
    isLoading,
    isEmptyList
  }
});
