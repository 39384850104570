import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { TribunalTypeResource } from 'src/common/types';
import TribunalTypeFrInfo from './TribunalTypeFrInfo';
import TribunalTypeEnInfo from './TribunalTypeEnInfo';

interface DetailsProps {
  tribunalType: TribunalTypeResource;
}

const Details: FC<DetailsProps> = ({
  tribunalType
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <TribunalTypeFrInfo tribunalType={tribunalType} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <TribunalTypeEnInfo tribunalType={tribunalType} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  tribunalType: PropTypes.object.isRequired
};

export default Details;
