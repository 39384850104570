import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  CardHeader,
  Button,
  SvgIcon,
  TableBody,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Divider
} from '@mui/material';
import { 
  DocumentOrderedDto,
  InvoiceInfo 
} from 'src/common/types';
import { 
  ITEMS_PER_PAGE, 
  labelConfig, 
  PaginationModel, 
  EmptyList, 
  FileType,
  _customer,
  CustomerModel,
  fetchCustomerInvoicesById
} from 'src/common';
import { 
  formatDateTime, 
  getAmount
} from 'src/utils/helper';
import { btn2, firstRow, info, secondRow } from '../../../../styles/customer';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { CaijTableHeader } from 'src/components/table/CaijTableHeader';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import LoadingScreenCard from 'src/components/loading/LoadingScreenCard';
import {PictureAsPdf} from '@mui/icons-material';
import CaijPdf from 'src/components/pdf';
import LoadingButton from '@mui/lab/LoadingButton';
import {Eye as SeeIcon} from 'react-feather';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';

const tabs = [
  { value: 'factures', label: 'Factures' },
  { value: 'commandes', label: 'Commandes' },
];

const headerInvoice = [
  { width: "10%", name: "Numéro de facture"},
  { width: "20%", name: "Description"},
  { width: "25%", name: "Transaction ID"},
  { width: "10%", name: "Méthode de paiement"},
  { width: "10%", name: "Montant"},
  { width: "10%", name: "Statut"},
  { width: "10%", name: "Date"},
  { width: "10%", name: ""}
];

const headerOrder = [
  { name: ""},
  { width: "40%", name: "Nom"},
  { width: "20%", name: "Juridiction"},
  { width: "10%", name: "Coût"},
  { width: "10%", name: "Créé"},
  { width: "10%", name: "Reçu"},
  { width: "10%", name: ""}
];

interface InvoicesInfoProps {
  id: number;
}

const NumberItem = (props: {title: String, length: number}) => (
  <MyCard>
    <CardHeader title={props.title} />
    <Box sx={{...info, fontFamily: theme => theme.typography.fontFamily}}>{props.length}</Box>
  </MyCard>
)

const GrandTotal = (props: {children: ReactNode}) => (
  <MyCard>
    <CardHeader title={labelConfig.amountOwed} />
    <Box sx={{...info, fontFamily: theme => theme.typography.fontFamily}}>
      {`${props.children}$`}
    </Box>
  </MyCard>
)

const getOrders = async (custId: number, model: CustomerModel) => await model.getApiCustomerOrdersById(custId);
const getInvoices = async (custId: number, model: CustomerModel) => await model.getApiCustomerInvoicesById(custId);

const InvoicesInfo: FC<InvoicesInfoProps> = ({id}) => {
  const pagination = new PaginationModel();
  const [currentTab, setCurrentTab] = useState<string>('factures');
  const [page, setPage] = useState<number>(0);
  const [shown, setShown] = useState(false);
  const [pdf, setPdf] = useState<{title: string, base64String: string}>({
    title: '',
    base64String: ''
  });

  const [state, dispatch] = useReducer(_customer,{ 
    customerInvoices: [], 
    customerOrders: [],
    isLoading: false, 
    isEmptyList: true
  });
  const [selectedRow, setSelectedRow] = useState([]);

  const getCustomerInvoicesById = useCallback(async () : Promise<void> => {
    const model = new CustomerModel();
    const [customerInvoices,customerOrders] = await Promise.all([
      getInvoices(id, model),
      getOrders(id, model),
    ]);
    dispatch(fetchCustomerInvoicesById(customerInvoices,customerOrders, true, !Array.isArray(customerInvoices) || customerInvoices.length === 0));
  },[id]);

  useEffect(() => {
    (async () => {
      await getCustomerInvoicesById();
    })();
  },[]);

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
    setPage(0);
  };

  const {isLoading, isEmptyList, customerInvoices, customerOrders} = state;

  const printInvoices = () => {
    if(!isLoading){
      return <LoadingScreenCard/>
    }else if(isLoading && isEmptyList){
      return <EmptyList />
    }
    return (
      <Grid spacing={3} container>
        <Grid xs={4} item>
          <NumberItem title="Factures" length={customerInvoices.length} />
        </Grid>
        <Grid xs={4} item>
          <GrandTotal>
            {getAmount(customerInvoices.reduce((currentValue: number, invoice: InvoiceInfo) => currentValue + invoice.amount,0))}
          </GrandTotal> 
        </Grid>
        <Grid xs={12} item>
          <MyCard>
            <CaijTable>
              <CaijTableHeader data={headerInvoice} />
              <TableBody>
                {pagination.stableSort(customerInvoices, () => {})
                  .slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE)
                  .map((row : InvoiceInfo, index: number) => (
                    <TableRow key={index}>
                      <CaijTableCell allowedBaseColor>{row.invoiceId}</CaijTableCell>
                      <CaijTableCell allowedBaseColor>{row.description}</CaijTableCell>
                      <CaijTableCell allowedBaseColor>
                        <Typography component="div" sx={firstRow}>{row.id}</Typography>
                        { row.refId && <Typography sx={secondRow}>{`Ref: #${row.refId}`}</Typography> }
                      </CaijTableCell>
                      <CaijTableCell allowedBaseColor>
                        { row.cardType && (
                          <>
                            <Typography sx={firstRow}>{row.cardType}</Typography>
                            <Typography sx={secondRow}>{`**** ${row.cardLastDigits}`}</Typography>
                          </>
                          )
                        }
                      </CaijTableCell>
                      <CaijTableCell allowedBaseColor>{`${getAmount(row.amount, true)}$`}</CaijTableCell>
                      <CaijTableCell allowedBaseColor>{row.refunded ? 'Remboursé' : 'Payé'}</CaijTableCell>
                      <CaijTableCell allowedBaseColor>
                        <Typography sx={firstRow}>{formatDateTime(row.date).slice(0, 10)}</Typography>
                        <Typography sx={secondRow}>{formatDateTime(row.date).slice(11, 16)}</Typography>
                      </CaijTableCell>
                      <CaijTableCell>
                        { row.hasInvoiceFile && (
                          <LoadingButton
                            size="small"
                            variant="contained"
                            color="primary"
                            loading={selectedRow.indexOf(row.id) !== -1}
                            loadingPosition="start"
                            startIcon={<SvgIcon fontSize='small'><SeeIcon /></SvgIcon>}
                            onClick={async () => {
                              CustomerModel.handleToggle(row.id, selectedRow, setSelectedRow);
                              const model = new CustomerModel();
                              const response = await model.getApiCustomerInvoice(id,row.id);
                              if(!model.error){
                                setPdf({
                                  ...pdf, 
                                  title: row.description, 
                                  base64String: response.pdfFile
                                });
                                setShown(true);
                              }
                              setSelectedRow([]);
                            }}	
                           >
                            Voir
                           </LoadingButton>
                          )
                        }
                      </CaijTableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </CaijTable>
            <CaijTablePagination
              showTablePaginationLight 
              len={customerInvoices.length}
              page={page}
              setPage={(l) => setPage(l)}
            />
          </MyCard>
        </Grid>
      </Grid>
    );
  }

  const printOrders = () => {
    if(!customerOrders || customerOrders.length === 0){
      return <EmptyList />
    }
    return (
		<Grid spacing={3} container>
			<Grid xs={4} item>
				<NumberItem title='Commandes' length={customerOrders.length} />
			</Grid>
			<Grid xs={4} item>
				<GrandTotal>
					{getAmount(customerOrders.reduce((currentValue: number, order: DocumentOrderedDto) => currentValue + order.cost, 0))}
				</GrandTotal>
			</Grid>
			<Grid xs={12} item>
				<MyCard>
					<CaijTable>
						<CaijTableHeader data={headerOrder} />
						<TableBody>
							{pagination
								.stableSort(customerOrders, () => {})
								.slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE)
								.map((row: DocumentOrderedDto, index: number) => (
									<TableRow key={index}>
										<CaijTableCell allowedBaseColor>
											{row.fileType === FileType.PDF ? <PictureAsPdf /> : ''}
										</CaijTableCell>
										<CaijTableCell allowedBaseColor>{row.companyName}</CaijTableCell>
										<CaijTableCell allowedBaseColor>{row.jurisdiction}</CaijTableCell>
										<CaijTableCell allowedBaseColor>{`${getAmount(row.cost, true)}$`}</CaijTableCell>
										<CaijTableCell allowedBaseColor>
											<Typography sx={firstRow}>{row.dateCreated}</Typography>
										</CaijTableCell>
										<CaijTableCell allowedBaseColor>
											<Typography sx={firstRow}>{row.dateReceived}</Typography>
										</CaijTableCell>
										<CaijTableCell>
											{row.dateReceived && row.documentFile && (
												<Button
													sx={btn2}
													size='small'
													color='secondary'
													variant='contained'
													startIcon={
														<SvgIcon fontSize='small'>
															<SeeIcon />
														</SvgIcon>
													}
													onClick={async () => {
														const model = new CustomerModel();
														const response = await model.getApiCustomerOrderById(id, row.documentFile);
														if (!model.error) {
															setPdf({
																...pdf,
																title: row.companyName,
																base64String: response,
															});
															setShown(true);
														}
													}}
												>
													Voir
												</Button>
											)}
										</CaijTableCell>
									</TableRow>
								))}
						</TableBody>
					</CaijTable>
          <CaijTablePagination
            showTablePaginationLight  
            len={customerOrders.length}
            page={page}
            setPage={(l) => setPage(l)}
          />
				</MyCard>
			</Grid>
		</Grid>
	);
  }

  const renderContent = () => {
    switch (currentTab) {
      case 'factures':
        return printInvoices();
      case 'commandes':
        return printOrders();
    }
  }
  
  return (
    <Grid spacing={3} container>
      <Grid xs={12} item>
        <MyCard>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='fullWidth' textColor='secondary'>
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Divider />
          <Box mt={3}>{renderContent()}</Box>
          {shown && ( 
              <CaijPdf 
                title={pdf.title} 
                base64String={pdf.base64String} 
                setShown={() => setShown(false)} 
              /> 
            )
          }
        </MyCard>
      </Grid>
    </Grid>
  )
};

InvoicesInfo.propTypes = {
  id: PropTypes.number.isRequired
};

export default InvoicesInfo;