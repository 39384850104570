import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR
} from 'src/common';
import type {
  Error,
  ApiKeyDto,
  CreateResponseApiKey,
  ApiKeySettings
} from 'src/common/types';
import AppModel from './App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from './Form';
import printMessage from 'src/views/errors/MessageError';

export default class ApiKeyModel extends AppModel
{
  private static _instance: ApiKeyModel;
  
  constructor(){
    super('/apikey');
    this._dialog.Header = "Supprimer la clé d'Api";
    this._dialog.BtnText = BtnOk.DELETED;
    this._dialog.Name = "la clé d'Api";
    this._resourceCode = 'API_KEY';
    this._headerTitle = "Liste des clés d'API";
    this._btnAddText = "Ajouter une clé d'API";
    this.Path.PathName = '/api-key';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Clés d\'Api',
      href: this.Path.Home,
      visible: true
    }
  }

  get ApiKey(){
    return new FormModel('apiKey','Clé d\'Api',320);
  }

  get Active(){
    return new FormModel('active', 'Active');
  }

  get Name() {
    return new FormModel('name','Nom',320);
  }

  get Description(){
    return new FormModel('description','Description',1000);
  }

  static getInstance(): ApiKeyModel {
    if (!ApiKeyModel._instance) {
      ApiKeyModel._instance = new ApiKeyModel();
    }
    return ApiKeyModel._instance;
  }

  async getApiKeys(): Promise<ApiKeyDto[]> {
    let apiKeys: ApiKeyDto[];
    await axios.get<ApiKeyDto[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          apiKeys = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return apiKeys;
  }

  async insertApiKey(submitData: ApiKeySettings): Promise<CreateResponseApiKey> {
    let result: CreateResponseApiKey;
    await axios.post<string, CreateResponseApiKey>(this.route, submitData).then((response) => {
      const { status } = response;
      if (status === Success) {
        result = response;
        result.message = "Clé d'Api créée."
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async updateApiKey(id: string, submitData: ApiKeySettings): Promise<Error> {
    let result: Error;
    await axios.put<ApiKeySettings, Error>(`${this.route}/${id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = "Clé d'Api modifié.";
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false) : Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
            status: status, 
            message: "Clé d'Api supprimé."
          });
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }
}
