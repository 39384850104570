import { useEffect, useReducer, useState } from "react";
import { 
  CustomerModel,
  fetchActiveCustomerBySubscription,
  fetchCustomerStats, 
  _customer,
  Success,
} from "src/common";
import { StatsDto } from "src/common/types";

export function useStats() {
  const initVal = {counter: 0, isLoading: false, title: ''};
  const [sActiveCustomer, dispatchActiveCustomer] = useReducer(_customer, initVal);
  const [sCardRequestCustomer, dispatchCardRequestCustomer] = useReducer(_customer, initVal);
  const [sDelayAccountCustomer, dispatchDelayAccountCustomer] = useReducer(_customer, initVal);
  const [sActiveCustomersByGroup, setActiveCustomersByGroup] = useState<StatsDto[]>([]);
  const [sActiveCustomersBySubscription, dispatchActiveCustomersBySubscription] = useReducer(_customer, { data:[], isLoading: false, isEmptyList: true});
  
  useEffect(() => {
      let isCurrent = true;
      const initialise = async () => {
        const model = new CustomerModel();
        const results = isCurrent && await Promise.all([
          model.getActiveCustomer(), 
          model.getCardRequestPendingCustomer(),
          model.getDelayAccountCustomer(),
          model.getActiveCustomersByGroup(),
          model.getActiveCustomersBySubscription()
        ]);
        if(results[0].status === Success){//Active Customer
          dispatchActiveCustomer(fetchCustomerStats(+results[0].data,results[0].message,true));
        }
        if(results[1].status === Success){//CardRequest Customer
          dispatchCardRequestCustomer(fetchCustomerStats(+results[1].data,results[1].message,true));
        }
        if(results[2].status === Success){//Delay Account Customer
          dispatchDelayAccountCustomer(fetchCustomerStats(+results[2].data,results[2].message,true))
        }
        //Active Customers By Group
        setActiveCustomersByGroup(results[3]);
        //Active Customers By Subscription
        dispatchActiveCustomersBySubscription(fetchActiveCustomerBySubscription(results[4], true, !Array.isArray(results[4]) || results[4].length === 0));
      }
      (async () => {
        await initialise();
      })();
      return () => { isCurrent = false; }
  },[]);
  
  return {
    sActiveCustomersBySubscription,
    sActiveCustomer,
    sCardRequestCustomer,
    sDelayAccountCustomer,
    sActiveCustomersByGroup
  }
}