import { EmailTemplateModel, ErrorType } from 'src/common';
import useEmailTemplates from 'src/functions/hooks/emailTemplate';
import * as Yup from 'yup';

const validateSchema = (model: EmailTemplateModel, id: number) => {
  const { Code, Name, SubjectFr, SubjectEn } = model;
  const { emailTemplates } = useEmailTemplates();
  return Yup.object().shape({
    code: Code.required(true)
      .test(Code.Label, EmailTemplateModel.formatError(ErrorType.invalid, Code.Label),(value: string) => {
        return value ? EmailTemplateModel.verifyWord(value) : true;
      })
      .test(Code.Label, EmailTemplateModel.formatError(ErrorType.exist, Code.Label),(value: string) => {
        return value && !id ? !emailTemplates.some(({code}) => code === value) : true;
      }),
    name: Name.required(true)
      .test(Name.Label, EmailTemplateModel.formatError(ErrorType.exist, Name.Label),(value: string) => {
        return value ? !emailTemplates.some((emailTemplate) => emailTemplate.name === value && emailTemplate.id != id) : true;
      }),
    subjectFr: SubjectFr.required(true),
    subjectEn: SubjectEn.required(true)
  });
}

export default validateSchema;