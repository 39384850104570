import React, { useState, useEffect, ChangeEvent } from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import PropTypes from 'prop-types';
import {
  EditorState,
  convertToRaw,
  ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CaijSwitch from '../inputs/CaijSwitch';
import CaijTextarea from '../inputs/CaijTextarea';
import { makeStyles } from 'tss-react/mui';
import { createTheme } from '@mui/material/styles';

//#region Types
export interface DraftEditorProps extends EditorProps {
  value?: string;
  className?: string;
  onEditorChange?: (value: string) => void;
  dataTestId?: string
  maxHeight?: number;
  minHeight?: number;
  readOnly?: boolean;
  allowedShowToolbar?: boolean;
  reload?: number;
  rows?: number;
}

interface IDraftEditor {
  editor: EditorState;
  value: string;
  showEditorCode: boolean;
};

interface Params {
  value?: string, 
  setState: any
};
//#endregion

//#region Styles
const useStyles = makeStyles()(() => {
  const theme = createTheme();
  return {
    root: {
      fontFamily: theme?.typography?.fontFamily,
      '& .rdw-option-wrapper': {
        background: 'transparent',
        border: 'none',
        minWidth: 26,
        padding: 6,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette.action.hover
        }
      },
      '& .rdw-option-active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.action.selected
      },
      '& .rdw-dropdown-wrapper': {
        boxShadow: 'none',
        background: 'transparent',
      },
      '& .rdw-dropdown-optionwrapper': {
        overflowY: 'auto',
        boxShadow: theme.shadows[10],
        padding: theme.spacing(1)
      }
    },
    toolbar: {
      marginBottom: 0,
      borderLeft: 'none',
      borderTop: 'none',
      borderRight: 'none',
      borderBottom: `1px solid ${theme.palette.divider}`,
      background: '#fff',
    },
    editor: {
      backgroundColor: '#fff',
      padding: theme.spacing(1),
      color: '#000',
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: '1px thin #696969',
    },
    hide: {
      display: 'none'
    }
  }
});
//#endregion

//#region Functions
function unescape(escaped: string){
  return escaped ? escaped
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quote;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&nbsp;/g, " ")
    .replace(/style="text-align:start;"/g, "")
    .replace(/style="text-align:end;"/g, "")
    .replace(/\<p\>\<\/p\>/g, '')
    .replace(/\<p\>\<p\>/g, '<p>')
    .replace(/\<\/p\>\<\/p\>/g, '</p>')
    .replace(/\<p\>\<p/g, '<p') : '';
};

function setHtmlToDraft({ value, setState } : Params){
  const blocksFromHTML = htmlToDraft(unescape(value).trim());
  const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
  setState((prevState:any) => {
  return { 
    ...prevState, 
    editor: EditorState.createWithContent(contentState),
    showEditorCode: false,
    value
  }});
}
//#endregion

const DraftEditor: FC<DraftEditorProps> = ({
  value,
  className,
  onEditorChange,
  dataTestId,
  maxHeight,
  minHeight,
  readOnly,
  allowedShowToolbar,
  reload,
  rows
}) => {
  const { classes } = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [state, setState] = useState<IDraftEditor>({ 
    editor: EditorState.createEmpty(),
    value: '',
    showEditorCode: false
  });

  useEffect(() => {
    setHtmlToDraft({value, setState});
  },[reload]);

  useEffect(() => {
    if(onEditorChange) onEditorChange(state.value);
  },[state?.value]);

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if(checked){
      setState({...state, showEditorCode: true})
    }else{
      const params: Params = {value: '', setState};
      params.value = state.value;
      setHtmlToDraft(params);
    }
    setChecked(checked);
  };

  const handleChange = (editor: EditorState) => {
    setState({ 
      ...state, 
      editor, 
      value: unescape(draftToHtml(convertToRaw(editor.getCurrentContent()))) 
    });
  };

  return (
    <>
      <div>
        <Editor
          webDriverTestID={dataTestId}
          editorState={state.editor}
          wrapperClassName={clsx(classes.root, className)}
          toolbarClassName={clsx(classes.toolbar, state.showEditorCode || !allowedShowToolbar ? classes.hide : '')}
          editorClassName={clsx(classes.editor, state.showEditorCode ? classes.hide : '')}
          editorStyle={{maxHeight: maxHeight || 250, minHeight: minHeight || 250}}
          onEditorStateChange={handleChange}
          readOnly={readOnly}
        />
        { state.showEditorCode && <CaijTextarea
                value={state.value}
                margin="none"
                rows={rows || 12}
                helperText=""
                onHandleChange={(e: ChangeEvent<HTMLInputElement>) => setState({...state, value: unescape(e.target.value) })}
              /> 
        }
      </div>
      {!readOnly && (
          <CaijSwitch
            label="HTML"
            checked={checked}
            sx={{mt:2}}
            onHandleChangeSwitch={(e: ChangeEvent<HTMLInputElement>) => handleSwitch(e)}
          />
        )
      }
    </>
  );
};

DraftEditor.propTypes = {
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.string,
  allowedShowToolbar: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onEditorChange: PropTypes.func,
};

DraftEditor.defaultProps = {
  readOnly: false,
  allowedShowToolbar: true,
};

export default DraftEditor;
