import { useRef, useState } from 'react';
import type { FC } from 'react';
import { useSnackbar } from 'notistack';
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography, ListItemIcon } from '@mui/material';
import { CaijTooltip, useNavigate } from 'src/common';
import useAuth from 'src/hooks/useAuth';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    popover: {
      width: 200,
    }
  }
});

const Account: FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const label = "Me déconnecter";

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <CaijTooltip title={label}>
        <Box
          display='flex'
          alignItems='center'
          component={ButtonBase}
          onClick={handleOpen}
          // @ts-ignore
          ref={ref}
        >
          <Avatar alt='User' sx={{height: 32, width: 32, marginRight: theme => theme.spacing(1), color: 'background.paper'}} src={user?.avatar} />
          <Hidden smDown>
            <Typography variant='body2'>
              {user?.name}
            </Typography>
          </Hidden>
        </Box>
      </CaijTooltip>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>{label}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
