import React from 'react';
import type { FC } from 'react';
import {TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {EmptyList,Juridictions,tableRow} from 'src/common';
import type { TribunalCodesResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { CaijTableCell } from 'src/components/table/CaijTableCell';

export interface ResultsProps {
  tribunalCodesNotAssigned: TribunalCodesResource[];
  isEmptyList: boolean;
}

const getHeadCells = () => {
  return [
    {
      width: '50%', label: 'Juridiction '
    },
    {
      width: '50%', label: 'Code'
    }
  ];
};

const Results: FC<ResultsProps> = ({tribunalCodesNotAssigned,isEmptyList}) => isEmptyList ? <EmptyList /> : (
  <MyCard>
    <CaijTable>
      <TableHead>
        <TableRow>
            {getHeadCells().map(({width, label}, index: number) => <TableCell key={index} width={width}>{label}</TableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        { tribunalCodesNotAssigned.map((row: TribunalCodesResource, index: number) => (
            <TableRow hover tabIndex={-1} key={index} sx={tableRow}>
              <CaijTableCell>{Juridictions[row.juridiction]}</CaijTableCell> 
              <CaijTableCell>{row.tribunal}</CaijTableCell> 
            </TableRow>
            )
          )
        }
      </TableBody>
    </CaijTable>
  </MyCard>
);

export default Results;
