import { WfLibraryDto, WfProfileDto } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchLibrariesSymphonyAction = {
  type: 'FETCH_LIBRARIES_SYMPHONY';
  payload: {
    libraries: WfLibraryDto[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

type FetchProfilesSymphonyAction = {
  type: 'FETCH_PROFILES_SYMPHONY';
  payload: {
    profiles: WfProfileDto[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

export type SymphonyAction = FetchLibrariesSymphonyAction |
                             FetchProfilesSymphonyAction;

export interface SymphonyReturnState {
  libraries?: WfLibraryDto[];
  profiles?: WfProfileDto[];
  isEmptyList?: boolean;
  isLoading?: boolean;
}

interface SymphonyState {
  libraries: WfLibraryDto[];
  profiles: WfProfileDto[];
  isEmptyList: boolean;
  isLoading: boolean;
}

export default (state: SymphonyState, action: SymphonyAction): SymphonyReturnState => {
    switch (action.type) {
      case actionTypes.FETCH_LIBRARIES_SYMPHONY: {
        const { libraries, isEmptyList, isLoading } = action.payload;
        return {
          ...state,
          libraries,
          isLoading,
          isEmptyList
        };
      }
      case actionTypes.FETCH_PROFILES_SYMPHONY: {
        const { profiles, isEmptyList, isLoading } = action.payload;
        return {
          ...state,
          profiles,
          isLoading,
          isEmptyList
        };
      }
      default: {
        return state;
      }
    }
}

