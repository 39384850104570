import { Divider, CardContent, Box } from "@mui/material"
import { FC } from "react";
import CardHeaderDetail from "src/components/card/CardHeaderDetail"
import MyCard from "src/components/card/MyCard"
import PropTypes from 'prop-types';

export interface MessageInfoProps {
    note: string;
}

const MessageInfo: FC<MessageInfoProps> = ({ note }) => {

    return (
        <MyCard>
            <CardHeaderDetail title="Message pour client" />
            <Divider />
            <CardContent>
                <Box mt={1} mb={2}>
                    <p>Bonjour,</p>
                    <p>Le(s) document(s) dont vous avez besoin est(sont) joint(s) au présent dossier.</p>
                    <p>{note}</p>
                    <p>Nous vous remercions d’avoir utilisé le service de soutien à la recherche du CAIJ, et nous demeurons à votre disposition pour toutes autres demandes.</p>
                    <p>Meilleures salutations.</p>
                </Box>
            </CardContent>
        </MyCard>
    )
};

MessageInfo.propTypes = {
    note: PropTypes.string.isRequired
}

export default MessageInfo;