import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody
} from '@mui/material';
import {
  EmptyList,
  ListType,
  getEmptyListSearch,
  EmailTemplateModel,
  EnhancedTableHead,
  NavigateFunction
} from 'src/common';
import type { 
  EmailTemplateDto,
  SearchFilter
} from 'src/common/types';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  emailTemplates: EmailTemplateDto[];
  isEmptyList: boolean;
  onDeleteEmailTemplate: (id: number) => Promise<void>;
  model: EmailTemplateModel;
  navigate: NavigateFunction;
  location: {search: string};
}

const Results: FC<ResultsProps> = ({
  emailTemplates,
  isEmptyList,
  onDeleteEmailTemplate,
  model,
  navigate,
  location
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState<number>(0);
  const query = new URLSearchParams(location?.search).get("query");
  const [filter, setFilter] = useState<SearchFilter>({
    query,
    doSearch: false,
    page
  });
  const header = model.getHeadCells();
  const { order, orderBy, sort, handleRequestSort } = useSort();

  useEffect(() => {
    const { Filters: { query, page } } = model;
    const queryVal = model.getQueryParam(query);
    const pageVal = +model.getQueryParam(page);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        doSearch: queryVal ? true : false,
        page: pageVal
      }
    });
    setPage(pageVal);
  },[model]);

  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
        <Search 
          listType={ListType.EmailTemplate}
          placeholder='Recherche le/les gabarit(s) courriel' 
          filter={filter}
          width="400px"
          path={path.Home}
        />
      </Box>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={header}
          notAllowedSort={isEmptyList}
        />
        <TableBody>
          { emailTemplates.length === 0 ? getEmptyListSearch(header.length) :
            sort<EmailTemplateDto>(emailTemplates, page).map((row: EmailTemplateDto, index: number) => (
              <CaijTableRowClickable key={index} path={path.getDetail(row.id)}>
                <CaijTableCell>{row.code}</CaijTableCell>
                <CaijTableCell>{row.name}</CaijTableCell>
                <CaijTableCellAction 
                  pageEditUrl={path.getEdit(row.id)}
                  resourceCode={model.ResourceCode}
                  handleToggle={() => EmailTemplateModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                >
                  <CaijDialogs
                    dialog={model.Dialog}
                    isOpen={selectedRow.indexOf(row.id) !== -1}
                    onSubmit={async () => {
                      await onDeleteEmailTemplate(row.id);
                      setSelectedRow([]);
                    }}
                    setSelectedRow={setSelectedRow}
                  />
                </CaijTableCellAction>
              </CaijTableRowClickable>
            ))
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={{totalCount: emailTemplates.length}} 
        model={model} 
        filter={filter} 
        page={page}
        navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
      />
    </MyCard>
  );
};

Results.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool,
  onDeleteEmailTemplate: PropTypes.func
};

export default Results;
