import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import LibraryFrInfo from './LibraryFrInfo';
import LibraryEnInfo from './LibraryEnInfo';
import ContactInfo from './ContactInfo';
import OthersInfo from './OthersInfo';
import { LibraryModel } from 'src/common';

interface DetailsProps {
  model: LibraryModel;
  library?: LibraryResource;
}

const Details: FC<DetailsProps> = ({
  model,
  library
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <LibraryFrInfo model={model} library={library} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <LibraryEnInfo model={model} library={library} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <ContactInfo model={model} library={library} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <OthersInfo model={model} library={library} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  library: PropTypes.any
};

export default Details;
