import { useEffect, useReducer, useState } from 'react';
import type { FC } from 'react';
import {
    Box,
    Container
} from '@mui/material';
import { CustomerResource } from 'src/common/types';
import Page from 'src/components/Page';
import ReperageEditForm from './ReperageEditForm';
import { 
    AccessModel, 
    CustomerModel, 
    ReperageModel, 
    _reperage, 
    fetchReperage, 
    root, 
    useLocation, 
    useNavigate, 
    useParams 
} from 'src/common';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const ReperageEditView: FC = () => {
    const model = ReperageModel.getInstance();
    const access = new AccessModel(model.ResourceCode);
    const navigate = useNavigate();
    const { id, customerId } = useParams();
    //const { subscriptions } = useSubscriptions();
    const location = useLocation();
    const [state, dispatch] = useReducer(_reperage, { isLoading: false, reperage: {} });
    const [customer, setCustomer] = useState<CustomerResource>(null);
   // const [employees, setEmployees] = useState<CustomerResource[]>(null);
    const detailPageUrl = location.state?.key;
    /*
    useEffect(() => {
        const getEmployees = async (model: CustomerModel) => {
            const results = await model.getCustomers({
                pageNumber: 1,
                pageSize: 50,
                // À revoir: ce condition détermine ce qui constitue un "employé assignable" dans le contexte des repérages.
                subscription: String(subscriptions.find(sub => sub.name.toLocaleLowerCase() === "caij - employé")?.id)
            });
            setEmployees(results?.list?.filter(emp => emp.wfLibrary !== null));
        }

        if (subscriptions?.length > 0) {
            getEmployees(new CustomerModel);
        }
    }, [subscriptions])
    */
    useEffect(() => {
        const initialise = async () => {
            const model = new ReperageModel();
            const pathName = location.pathname;
            if (access.canRead()) {
                const customerModel = new CustomerModel();
                if (customerId && isNaN(+customerId)) {
                    navigate(model.Path.Home);
                } else if (customerId) {
                    model.PathName = pathName;
                    const customer = await customerModel.getCustomerById(+customerId);
                    if (!customerModel.error) {
                        setCustomer(customer);
                    }
                }
            }
            if (id && isNaN(+id)) {
                navigate(model.Path.Home);
            } else if (id) {
                if(access.canEdit(id)){
                    model.PathName = pathName;
                    const reperage = await model.getReperageById(id);
                    if (!model.error) {
                        dispatch(fetchReperage(reperage, true));
                    }
                }
            } else if(access.canRead()){
                model.PathName = pathName;
                dispatch(fetchReperage({ contentItems: [] }, true));
            }
        };
        (async () => {
            await initialise();
        })();
    }, [location, id, customerId]);

    const { reperage, isLoading } = state;

    if (!isLoading) return <LoadingScreen />

    model.Title = reperage.name;
    
    return (
        <Page
            sx={root}
            title={model.getEditPageTitle(null)}
        >
            <Container maxWidth={false}>
                <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
                <Box mt={3}>
                    <ReperageEditForm
                        model={model}
                        customer={customer}
                        reperage={reperage}
                        detailPageUrl={detailPageUrl}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ReperageEditView;
