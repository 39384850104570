import {
  Box,
  CardContent
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import {
  Authorize,
  btnSubmit,
  CaijButtonReset,
  CaijButtonSubmit,
  Formik,
  handleChange,
  LawDomainFieldModel,
  Mode
} from 'src/common';
import type { 
  LawDomainFieldResource, 
  LawDomainFieldResourceForEdit
 } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import LawDomainFieldForm from 'src/components/content/lawDomainField/Forms';
import printMessage from 'src/views/errors/MessageError';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: LawDomainFieldModel;
  lawDomainField: LawDomainFieldResource;
  onHandleRefreshLawDomainField: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: LawDomainFieldResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  lawDomainField,
  onHandleRefreshLawDomainField,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isParent, setIsParent] = useState<boolean>(lawDomainField.parentId ? false : true);

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: LawDomainFieldResource): Promise<void> => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LawDomainFieldModel();
    const submitData = values as LawDomainFieldResourceForEdit;
    isParent ? submitData.parentId = null : '';
    if (submitData && submitData.id) {
      const response = await model.updateLawDomainField(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await onHandleRefreshLawDomainField();
      }
    }
  };

  return (
    <Formik
      initialValues={LawDomainFieldModel.getInitialValues(lawDomainField)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <LawDomainFieldForm 
                isParent={isParent}
                setIsParent={(l) => setIsParent(l)}
                model={model}
                lawDomainField={values}
                errors={errors}
                touched={touched}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  lawDomainField: PropTypes.object.isRequired,
  onHandleRefreshLawDomainField: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
