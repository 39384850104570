import { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  _tribunal,
  TribunalModel,
  fetchTribunaux,
  ITEMS_PER_PAGE,
  useNavigate,
  AccessModel,
  deleteTribunal,
  root
} from 'src/common';
import type { GetTribunalsListOptionalParams} from 'src/common/types';
import Page from 'src/components/Page';
import Results from './Results';
import { TribunalProvider } from 'src/contexts/TribunalContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  tribunaux: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false,
  model: null
};

const TribunalListView: FC = () => {
  const access = new AccessModel(TribunalModel.getInstance().ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_tribunal, initialState);
  const navigate = useNavigate();

  const getTribunals = useCallback(async (model: TribunalModel, params: GetTribunalsListOptionalParams) : Promise<void> => {
    const { search, pathname } = location;
    model.PathName = search ? pathname + search : search;
    const results = await model.getTribunalsPaginated(params);
    if (!model.error) {
      dispatch(fetchTribunaux(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0, true, model));
    }
  }, [location]);

  const doQuery = useCallback(async (model: TribunalModel) : Promise<void> => {
    if(access.canRead()){
      const { search, pathname} = location;
      let params: GetTribunalsListOptionalParams = { pageNumber: 1, pageSize: ITEMS_PER_PAGE };
      if(search){
        model.PathName = pathname + search;
        if(await model.setQueryParams(search, model, navigate)){
          const { Filters: { page, jurisdiction, tribunalType, query } } = model;
          params = {
            ...params,
            searchTerm: model.getQueryParam(query),
            jurisdiction: model.getQueryParam(jurisdiction),
            tribunalType: model.getQueryParam(tribunalType),
            pageNumber: +model.getQueryParam(page) + 1,
            sortOptions: 'asc'
          }
          await getTribunals(model,params);
        }
      }else{
        await getTribunals(model, params);
      }
    }
  },[location]);

  useEffect(() => {
    (async () => await doQuery(new TribunalModel))();
  },[location.search]);

  const handleDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteTribunal(id));
    }
  };

  const { tribunaux, isEmptyList, isLoading, paged, model } = state;
 
  if (!isLoading) {
    return <LoadingScreen />;
  }
  
  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <TribunalProvider>
            <Results
              tribunals={tribunaux}
              paged={paged}
              onDeleteTribunal={handleDelete}
              isEmptyList={isEmptyList}
              model={model}
              navigate={navigate}
              location={location}
            />
          </TribunalProvider>
        </Box> 
      </Container>
    </Page>
  );
};

export default TribunalListView;


