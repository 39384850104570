import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { DocumentTypeResource } from 'src/common/types';
import { Grid } from '@mui/material';
import { CaijTextarea, CaijInput, FormikErrors, FormikTouched, DocumentTypeModel } from 'src/common';

interface DocumentTypeFormProps {
  model: DocumentTypeModel;
  documentType: DocumentTypeResource;
  errors: FormikErrors<DocumentTypeResource>;
  touched: FormikTouched<DocumentTypeResource>;
  onHandleBlur: (e: any) => void;
  onHandleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const DocumentTypeForm: FC<DocumentTypeFormProps> = ({
  model,
  documentType,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const { nameFr, nameEn, code, description } = documentType;
  const { Code, NameFr, NameEn, Description } = model

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <CaijInput
            required
            name={Code.Name}
            id={Code.Name}
            value={code}
            label={Code.Label}
            helperText={touched.code && errors.code}
            error={Boolean(touched.code && errors.code)}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{maxLength: Code.MaxLength, 'data-testid': Code.Name}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CaijInput
            required
            name={NameFr.Name}
            id={NameFr.Name}
            margin='none'
            value={nameFr}
            helperText={touched.nameFr && errors.nameFr}
            error={Boolean(touched.nameFr && errors.nameFr)}
            label={NameFr.Label}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <CaijInput
            required
            name={NameEn.Name}
            id={NameEn.Name}
            margin='none'
            value={nameEn}
            label={NameEn.Label}
            helperText={touched.nameEn && errors.nameEn}
            error={Boolean(touched.nameEn && errors.nameEn)}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{maxLength: NameEn.MaxLength, 'data-testid' : NameEn.Name}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <CaijTextarea
            name={Description.Name}
            id={Description.Name}
            rows="4"
            margin="none"
            label={Description.Label}
            helperText={touched.description && errors.description}
            error={Boolean(touched.description && errors.description)}
            value={description}
            InputLabelProps={{ shrink: true }}
            inputProps={{'data-testid' : Description.Name}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
    </>
  );
};

DocumentTypeForm.propTypes = {
  documentType: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default DocumentTypeForm;
