import { LawDomainFieldModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: LawDomainFieldModel) => {
  const { NameFr, NameEn } = model;
  return Yup.object().shape({
    nameFr: NameFr.required(true),
    nameEn: NameEn.required(true)
  });
}

export default validateSchema;