import React, { useCallback, useState, useEffect, useReducer, useRef } from 'react';
import type { FC, ChangeEvent } from 'react';
import type {
  Theme,
  TabsCustomer,
  Transaction,
  Sort,
  CustomerCardTransactionsResource,
  Print,
  EndorsedCustomerResource,
  SubscriptionProductDto,
  DatabankResource,
  ICustomer
} from 'src/common/types';
import { Box, Container, Divider, Tab, Tabs, Grid } from '@mui/material';
import {
  useParams,
  useLocation,
  CustomerModel,
  _customer,
  fetchCustomerCardTransactionsById,
  PaginationModel,
  useNavigate,
  setHash,
  setEditHash,
  AccessModel,
  DatabankModel,
  ENDORSEMENT,
  SubscriptionModel,
  CardRequestPendingModel,
  LIBRARY_ACCESS,
  CustomerStatus,
  root,
} from 'src/common';
import Page from 'src/components/Page';
import Header from './Header';
import Details from './Details';
import CustomerLibrariesInfo from './CustomerLibrariesInfo';
import TransactionsInfo from './TransactionsInfo';
import TransactionForm from './Forms/TransactionForm';
import InvoicesInfo from './InvoicesInfo';
import CaijCardAssignForm from './Forms/CaijCardAssignForm';
import CaijCardDeleteForm from './Forms/CaijCardDeleteForm';
import CaijCardReplaceForm from './Forms/CaijCardReplaceForm';
import CustomerEndorsedListView from 'src/views/customer/customer/CustomerDetailsView/CustomerEndorsed/CustomerEndorsedViewedListView';
import CustomerEndorsedEditView from 'src/views/customer/customer/CustomerDetailsView/CustomerEndorsed/CustomerEndorsedEditView';
import CustomerProfile from '../CustomerDetailsView/CustomerProfile';
import CustomerProfileEditForm from './Forms/CustomerProfileEditForm';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import SubscriptionInfo from './Details/SubscriptionInfo';
import AccessGroupViewedDetailsView from 'src/views/subscription/SubscriptionDetailsView/AccessGroup/AccessGroupViewedDetailsView';
import SubscriptionEditForm from './Forms/SubscriptionEditForm';
import { LawPraticeFieldProvider } from 'src/contexts/LawPraticeFieldContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import printMessage from 'src/views/errors/MessageError';
import NotesInfo from './Note/NotesInfo';
import Access from 'src/model/Access';
import EditButton from 'src/components/customer/customer/Details/EditButton';
import Main from 'src/components/customer/customer/Details/Main';
import CustomerReperagesList from './CustomerReperagesList';

const container = {
  backgroundColor: (theme: Theme) => theme.palette.background.paper,
}

const box = {
  color: (theme: Theme) => theme.palette.text.primary,
  backgroundColor: (theme: Theme) => theme.palette.background.paper,
  padding: '1em',
};

const initialTabs: TabsCustomer[] = [
  { value: 'overview', label: 'Aperçu' },
  { value: 'profile', label: 'Profil' },
  { value: 'subscription', label: 'Abonnement' },
  { value: 'library', label: 'Bibliothèques' },
  { value: 'card', label: 'Carte CAIJ' },
  { value: 'invoices', label: 'Factures' },
  { value: "reperages", label: "Repérages" },
  { value: 'endorsed', label: 'Cautionnement' },
  { value: 'notes', label: 'Notes' }
];

//#region Functions
async function getLibraryFees(custId: string) {
  const model = new CustomerModel();
  const response = await model.getApiCustomerLibraryTransactionsById(custId);
  const { error } = model;
  if (error) {
    printMessage({ ...error });
  } else {
    return response?.fees;
  }
}

function getTotalEndorsed(customersEndorsed: EndorsedCustomerResource[]) {
  return customersEndorsed ? customersEndorsed.filter(({ status }) => status !== CustomerModel.getStatusByVal(CustomerStatus.Inactive)).length : 0;
}

const getLabel = (label: string, ...rest: any) => {
  if (label === initialTabs[8].label) {
    label += ` (${rest[0]?.length || 0})`;
  }
  return label;
}
//#endregion Functions

const CustomerDetailsView: FC = () => {
  const model = CustomerModel.getInstance();
  const cardRequestPendingModel = CardRequestPendingModel.getInstance();
  const location = useLocation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [tabs, setTabs] = useState<TabsCustomer[]>(initialTabs);
  const [editMode, setEditMode] = useState({
    overview: false,
    profile: false,
    subscription: false,
    library: false,
    card: false,
    invoices: false,
    endorsed: false
  });
  const [transactionMode, setTransactionMode] = useState(false);
  const [caijCardAssignMode, setCaijCardAssignMode] = useState(false);
  const [caijCardReplaceMode, setCaijCardReplaceMode] = useState(false);
  const [caijCardDeleteMode, setCaijCardDeleteMode] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('overview');
  const { id } = useParams();
  const [cust, setCust] = useState<ICustomer>({
    customer: null,
    endorsedBy: null,
    canEndorsed: false,
    limitEndorsed: 0,
    totalEndorsed: 0,
    customerNotes: [],
    accessesLibrary: [],
    logo: "",
    libraryFees: 0
  });
  const [customerEndorsedId, setCustomerEndorsedId] = useState<number>();
  const [state, dispatch] = useReducer(_customer, {
    customerCardTransaction: {
      prints: [],
      transactions: []
    },
    isLoading: false
  });
  const ref = useRef<CustomerCardTransactionsResource>(null);
  const navigate = useNavigate();
  const accessCustomer = new AccessModel(model.ResourceCode);

  const getCustomerCardTransactionsById = useCallback(async (): Promise<void> => {
    const model = new CustomerModel();
    const customerCardTransaction = await model.getApiCustomerCardTransactionsById(id);
    if (!model.error) {
      ref.current = customerCardTransaction;
      dispatch(fetchCustomerCardTransactionsById(customerCardTransaction, true));
    }
  }, [id]);

  const getCustomer = useCallback(async (): Promise<void> => {
    const model = new CustomerModel();
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    } else if (accessCustomer.canRead()) {
      const customer = await model.getCustomerById(+id);
      const { subscriptionGroup } = customer;
      if (!model.error) {
        const { endorsedBy, customerNotes } = customer;
        if (endorsedBy) {//check if customer endorsed by another member
          setCust((prevState: ICustomer) => {
            return {
              ...prevState,
              endorsedBy
            }
          });
        } else {
          const product = subscriptionGroup.products.find(({ product }) => product.code === ENDORSEMENT);
          if (product) { //check if customer can endorsed other member
            const limitEndorsed = product?.parameter || 0;
            setCust((prevState: ICustomer) => {
              return {
                ...prevState,
                canEndorsed: true,
                limitEndorsed,
                totalEndorsed: getTotalEndorsed(customer?.customersEndorsed)
              }
            });
          }
        }
        //Access Databanks
        let accessesLibrary: DatabankResource[] = [];
        if (subscriptionGroup.products.some((product: SubscriptionProductDto) => product.product.code === LIBRARY_ACCESS)) {
          accessesLibrary = await DatabankModel.getAccessLibraryDatabanks(DatabankModel.getInstance());
          DatabankModel.getAvailableDatabankList(customer.subscriptionGroup, accessesLibrary);
        }
        const libraryFees = await getLibraryFees(id);
        setCust((prevState: ICustomer) => {
          return {
            ...prevState,
            customer,
            accessesLibrary,
            libraryFees,
            customerNotes
          }
        });
        if (customer.logoUrl) {
          const newLogo = await model.getCustomerPicture(customer.id);
          if (newLogo) {
            const extension = customer.logoUrl?.split('.').pop();
            setCust((prevState: ICustomer) => {
              return {
                ...prevState,
                logo: `data:image/${extension};base64,${newLogo}`
              }
            });
          }
        }
      }
    }
  }, [id]);

  const setEditModeCustomer = (isEdit: boolean, value?: string) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit,
    });
    setIsEditing(isEdit);
  };

  const handleBrowserRefresh = (): void => {
    const hash = location.hash;
    if (hash) {
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = initialTabs.findIndex((tab: TabsCustomer) => tab.value === tabVal);
      if (index !== -1) {
        setCurrentTab(tabVal);
        if (tabVal === 'card') {
          if (removedHashSymbol.indexOf('edit') !== -1) {
            setIsEditing(true);
            setTransactionMode(true);
          } else if (removedHashSymbol.indexOf('assign') !== -1) {
            setIsEditing(true);
            setCaijCardAssignMode(true);
          } else if (removedHashSymbol.indexOf('replace') !== -1) {
            setIsEditing(true);
            setCaijCardReplaceMode(true);
          } else if (removedHashSymbol.indexOf('delete') !== -1) {
            setIsEditing(true);
            setCaijCardDeleteMode(true);
          }
        } else if (tabVal === 'endorsed') {
          setEditMode({ ...editMode, [tabVal]: removedHashSymbol.indexOf('edit') !== -1 ? true : false });
        } else {
          if (removedHashSymbol.indexOf('edit') !== -1) {
            setIsEditing(true);
            setEditMode({ ...editMode, [tabVal]: true });
          }
        }
      } else {
        setHash(tabs[0].value, navigate);
      }
    }
  }

  useEffect(() => {
    const { hash, pathname } = location;
    model.PathName = pathname + hash;
  }, [location]);

  useEffect(() => {
    const initialise = async (): Promise<void> => {
      await Promise.all([
        getCustomerCardTransactionsById(),
        getCustomer()
      ]);
      handleBrowserRefresh();
    };
    (async () => {
      await initialise();
    })();
  }, [id]);

  if (!cust.customer) {
    return <LoadingScreen />;
  }

  const {
    customer,
    limitEndorsed,
    customerNotes,
    canEndorsed,
    endorsedBy,
    accessesLibrary,
    totalEndorsed,
    logo,
    libraryFees
  } = cust;

  model.Title = `${customer.firstname} ${customer.lastname}`;

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeCustomer(isEdit);
    setTransactionMode(isEdit);
  };

  const handleTabsChange = async (event: ChangeEvent<any>, value: string): Promise<void> => {
    event.persist();
    if (value === 'card') {
      await getCustomerCardTransactionsById();
    }
    setHash(value, navigate);
    setCurrentTab(value);
    setTransactionMode(false);
    setCaijCardAssignMode(false);
    setCaijCardDeleteMode(false);
    setCaijCardReplaceMode(false);
    setEditModeCustomer(false, value);
  };

  const handleRefreshCustomer = async (value?: string): Promise<void> => {
    setHash(value, navigate);
    if (value === 'card') {
      await getCustomerCardTransactionsById();
      await getCustomer();
    } else {
      await getCustomer();
    }
    setEditModeCustomer(false, value);
    setTransactionMode(false);
    setCaijCardReplaceMode(false);
    setCaijCardDeleteMode(false);
    setCaijCardAssignMode(false);
  };

  const editCustomer = () => {
    setEditHash('edit', navigate);
    setEditModeCustomer(true);
    setTransactionMode(false);
  };

  const newTransaction = () => {
    setEditHash('edit', navigate);
    setTransactionMode(true);
    setEditModeCustomer(true, 'card');
    setCurrentTab('card');
    setIsEditing(true);
    setCaijCardReplaceMode(false);
    setCaijCardDeleteMode(false);
    setCaijCardAssignMode(false);
  };

  const modifyCaijCard = (action: string) => {
    setEditModeCustomer(true, 'card');
    setCurrentTab('card');
    setIsEditing(true);
    if (action === 'assign') {
      setEditHash('assign', navigate);
      setCaijCardAssignMode(true);
    }
    if (action === 'replace') {
      setEditHash('replace', navigate);
      setCaijCardReplaceMode(true);
    }
    if (action === 'delete') {
      setEditHash('delete', navigate);
      setCaijCardDeleteMode(true);
    }
    if (action === 'return') {
      setEditModeCustomer(false);
      setCaijCardDeleteMode(false);
      setCaijCardAssignMode(false);
      setCaijCardReplaceMode(false);
      setIsEditing(false);
    }
  };

  const editCustomerEndorsed = (isEdit: boolean, customerEndorsedId?: number) => {
    setEditHash('edit', navigate);
    if (customerEndorsedId) {
      setCustomerEndorsedId(customerEndorsedId);
    }
    setEditModeCustomer(isEdit);
  }

  const disabledTabCustomer = (tabValue: string): boolean => {
    let disableTabCustomer = false;
    if (tabValue === "endorsed") {
      disableTabCustomer = !canEndorsed;
    } else if (tabValue === "card") {
      disableTabCustomer = !Access.accessCarteCaij(customer);
    } else if (tabValue === "library") {
      disableTabCustomer = !Access.accessLibrary(customer);
    }
    return disableTabCustomer;
  };

  const handleSortChangeTransaction = async (operation: string, sort: Sort) => {
    const pagination = new PaginationModel();
    const newCustomerCardTransaction = { ...ref.current };
    if (operation) {
      let newTransactions = newCustomerCardTransaction.transactions.filter((transaction: Transaction) => transaction.operation === operation);
      newTransactions = pagination.stableSort(newTransactions, pagination.getComparator(sort, 'created'));
      newCustomerCardTransaction.transactions = newTransactions;
    } else {
      let newTransactions = pagination.stableSort(newCustomerCardTransaction.transactions, pagination.getComparator(sort, 'created'));
      newCustomerCardTransaction.transactions = newTransactions;
    }
    dispatch(fetchCustomerCardTransactionsById(newCustomerCardTransaction, true));
  };

  const handleSortChangePrint = async (status: string, sort: Sort) => {
    const pagination = new PaginationModel();
    const newCustomerCardTransaction = { ...ref.current };
    if (status) {
      let newPrints = []
      if (status === 'NotPrint') {
        newPrints = newCustomerCardTransaction.prints.filter((print: Print) => !print.status);
      } else {
        newPrints = newCustomerCardTransaction.prints.filter((print: Print) => print.status === status);
      }
      newPrints = pagination.stableSort(newPrints, pagination.getComparator(sort, 'created'));
      newCustomerCardTransaction.prints = newPrints;
    } else {
      let newPrints = pagination.stableSort(newCustomerCardTransaction.prints, pagination.getComparator(sort, 'created'));
      newCustomerCardTransaction.prints = newPrints;
    }
    dispatch(fetchCustomerCardTransactionsById(newCustomerCardTransaction, true));
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'overview':
        return (
          <Details
            model={model}
            endorsedBy={endorsedBy}
            onTransactionMode={newTransaction}
            customer={customer}
            libraryFees={libraryFees}
            notes={customerNotes}
            modifyCaijCard={modifyCaijCard}
            switchTabNote={() => setCurrentTab('notes')}
            onHandleRefreshCustomer={() => handleRefreshCustomer('overview')}
          />
        );
      case 'profile':
        if (editMode.profile) {
          return (
            <SubscriptionProvider>
              <LawPraticeFieldProvider>
                <CustomerProfileEditForm
                  model={model}
                  customer={customer}
                  onSwitchMode={l => switchMode(l, 'profile')}
                  onHandleRefreshCustomer={() => handleRefreshCustomer('profile')}
                />
              </LawPraticeFieldProvider>
            </SubscriptionProvider>
          );
        }
        return (
          <CustomerProfile
            model={model}
            customer={customer}
            logo={logo}
            isEditing={isEditing}
            onEditCustomer={editCustomer}
            onHandleRefreshCustomer={() => handleRefreshCustomer('profile')}
          />
        );
      case 'subscription':
        if (editMode.subscription) {
          return (
            <SubscriptionProvider>
              <SubscriptionEditForm
                access={accessCustomer}
                customer={customer}
                accessesLibrary={accessesLibrary}
                onHandleRefreshCustomer={() => handleRefreshCustomer('subscription')}
                onSwitchMode={l => switchMode(l, 'subscription')}
              />
            </SubscriptionProvider>
          )
        }
        return (
          <Main>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <EditButton
                  model={model}
                  isEditing={isEditing}
                  onEditCustomer={editCustomer}
                  tab='subscription'
                />
                <SubscriptionInfo customer={customer} allowedNote />
              </Grid>
              {
                customer.subscriptionGroup && (
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <AccessGroupViewedDetailsView
                      model={SubscriptionModel.getInstance()}
                      subscription={{
                        id: customer.subscriptionId,
                        databanks: customer.subscriptionGroup.databanks.map(({ id, nameFr, enabled, appType }) => ({ id, nameFr, enabled, appType })),
                        documentCollections: customer.subscriptionGroup.documentCollections.map(({ id, code, nameFr, nameEn, access, active }) => ({ id, code, nameFr, nameEn, access, active })),
                        products: customer.subscriptionGroup.products.map(({ parameter, product: { id, nameFr, nameEn, code, access, parametersRequired, active } }) => ({ id, nameFr, nameEn, code, access, parametersRequired, parameter, active }))
                      }}
                    />
                  </Grid>
                )
              }
            </Grid>
          </Main>
        )
      case 'endorsed':
        if (editMode.endorsed) {
          return (
            <CustomerEndorsedEditView
              model={model}
              customerEndorsedId={customerEndorsedId}
              customer={customer}
              onHandleRefreshCustomer={() => handleRefreshCustomer('endorsed')}
              onSwitchMode={(l) => switchMode(l, 'endorsed')}
              limitEndorsed={limitEndorsed}
              totalEndorsed={totalEndorsed}
            />
          );
        }
        return (
          <CustomerEndorsedListView
            model={model}
            customer={customer}
            onEditCustomerEndorsed={(x, y) => editCustomerEndorsed(x, y)}
            limitEndorsed={limitEndorsed}
            totalEndorsed={totalEndorsed}
          />
        );
      case 'library':
        return <CustomerLibrariesInfo />;
      case 'card':
        if (transactionMode) {
          return <TransactionForm
            model={cardRequestPendingModel}
            customer={customer}
            onHandleRefreshCustomer={() => handleRefreshCustomer('card')}
            onSwitchMode={l => switchMode(l, 'card')}
          />;
        }
        if (caijCardAssignMode) {
          return <CaijCardAssignForm
            model={model}
            cardRequestPendingModel={cardRequestPendingModel}
            modifyCaijCard={modifyCaijCard}
            customer={customer}
            onHandleRefreshCustomer={() => handleRefreshCustomer('card')} />;
        }
        if (caijCardDeleteMode) {
          return <CaijCardDeleteForm
            cardRequestPendingModel={cardRequestPendingModel}
            modifyCaijCard={modifyCaijCard}
            customer={customer}
            onHandleRefreshCustomer={() => handleRefreshCustomer('card')}
          />;
        }
        if (caijCardReplaceMode) {
          return (
            <CaijCardReplaceForm
              model={model}
              cardRequestPendingModel={cardRequestPendingModel}
              modifyCaijCard={modifyCaijCard}
              customer={customer}
              onHandleRefreshCustomer={() => handleRefreshCustomer('card')}
            />
          );
        }
        return (
          <TransactionsInfo
            model={model}
            cardRequestPendingModel={cardRequestPendingModel}
            modifyCaijCard={modifyCaijCard}
            onHandleRefreshCustomer={() => handleRefreshCustomer('card')}
            customer={customer}
            customerCardTransaction={{
              data: state.customerCardTransaction,
              isLoading: state.isLoading,
            }}
            onTransactionMode={newTransaction}
            onHandleSortChangeTransaction={(o, s) => handleSortChangeTransaction(o, s)}
            onHandleSortChangePrint={(l, s) => handleSortChangePrint(l, s)}
          />
        );
      case 'invoices':
        return (
          <InvoicesInfo id={customer.id} />
        );
      case 'notes':
        return (
          <NotesInfo
            access={accessCustomer}
            model={model}
            customerNotes={customerNotes}
            custId={customer.id}
            setCust={setCust}
          />
        )
      case 'reperages':
        return <CustomerReperagesList customer={customer} />
      default:
        return '';
    }
  };

  return (
    <Page sx={root} title={model.DetailPageTitle}>
      <Container maxWidth={false}>
        <Header
          model={model}
          logo={logo}
          customer={customer}
          libraryFees={libraryFees}
          onHandleRefreshCustomer={() => handleRefreshCustomer('profile')}
        />
        <Box sx={container} style={{ paddingTop: customer.blocked ? '25px' : '0' }}>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='scrollable' textColor='secondary'>
            {tabs.map(tab =>
              !disabledTabCustomer(tab.value) && (
                <Tab
                  key={tab.value}
                  label={getLabel(tab.label, customerNotes)}
                  value={tab.value}
                />
              )
            )}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={box}>{renderContent()}</Box>
      </Container>
    </Page>
  );
};

export default CustomerDetailsView;
