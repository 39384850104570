import React, { useRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent } from '@mui/material';
import type {
  CustomerResource,
  CustomerResourceForCreate,
  CustomerResourceForEdit
} from 'src/common/types';
import {
  Formik,
  CaijButtonSubmit,
  CaijButtonReset,
  CustomerModel,
  btnSubmit,
  handleChangeDateChange,
  MSG_SEND_EMAIL_MEMBER,
  AccessModel
} from 'src/common';
import CustomerForm from 'src/components/customer/customer/Forms/CustomerForm';
import printMessage from 'src/views/errors/MessageError';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';
import { validateCreateSchema } from '../Schema';

export interface CustomerEditFormProps {
  model: CustomerModel;
  customer: CustomerResource;
  onSubmit?: (values: CustomerResource) => void;
  onSwitchMode?: (isEdit: boolean) => void;
  onHandleRefreshCustomer?: (isEdit: boolean) => Promise<void>
}

const CustomerEditForm: FC<CustomerEditFormProps> = ({
  model,
  customer,
  onSubmit,
  onSwitchMode,
  onHandleRefreshCustomer
}) => {
  const access = new AccessModel(model.ResourceCode);
  const formRef = useRef(null);

  const handleSubmit = async (values: CustomerResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new CustomerModel();
    let submitData = values as CustomerResourceForEdit;
    const { subscriptionGroup, lawDomains, endorsedBy } = values;
    submitData = values as CustomerResourceForCreate;
    model.saveRecords(submitData, subscriptionGroup, endorsedBy, lawDomains);
    const response = await model.insertCustomer(submitData);
    if (!model.error) {
      let { status, message } = response;
      const sendEmailMessage = MSG_SEND_EMAIL_MEMBER + 'pour faire l\'activation de son compte';
      if(onHandleRefreshCustomer){
        model.printEmailMessage(status,sendEmailMessage);
        printMessage({status,message});
        await onHandleRefreshCustomer(false);
      }else{
        model.redirectEmailMessage(status,sendEmailMessage);
        printMessage({status, message});
      }
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={CustomerModel.getInitialValues(customer)}
        validationSchema={validateCreateSchema(model)}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, setFieldValue, values, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <MyCard>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Root>
                    <CustomerForm
                      model={model}
                      customer={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      showExpiredDate
                      allowedRestrictedAccountCreation
                      handleExpiredDateChange={(d, s) => handleChangeDateChange('expiration', d, s)}
                    />
                  </Root>
                </Box> 
                <CaijButtonSubmit disabled={access.Disabled || isSubmitting } sx={btnSubmit} />
                {
                  onSwitchMode ? (
                    <CaijButtonReset disabled={isSubmitting} onSwitchMode={(l) => onSwitchMode(l)} />
                  ) : (
                    <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
                  )
                }
              </CardContent>
            </MyCard>
          </form>
        )}
      </Formik>
    </>
  );
};

CustomerEditForm.propTypes = {
  customer: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onSwitchMode: PropTypes.func,
  onHandleRefreshCustomer: PropTypes.func
};

CustomerEditForm.defaultProps = {
  onSubmit: null,
  onSwitchMode: null,
  onHandleRefreshCustomer: null
};

export default CustomerEditForm;
