import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {CardContent, Grid} from '@mui/material';
import {
  Formik,
  handleChange,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  btnReset
} from 'src/common';
import type { 
  LibraryTypeResource, 
  LibraryTypeResourceForCreate, 
  LibraryTypeResourceForEdit,
  Error,
  CreateResponse
} from 'src/common/types';
import LibraryTypeModel from 'src/model/library/LibraryType';
import Authorize from 'src/components/Authorize';
import LibraryTypeForm from 'src/components/library/libraryType/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';

export interface LibraryTypeEditFormProps {
  model: LibraryTypeModel;
  libraryType: LibraryTypeResource;
  onSubmit?: (values: LibraryTypeResource) => void;
}

async function redirect(model: LibraryTypeModel, response: Error | CreateResponse) : Promise<void> {
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message,
  });
};

const LibraryTypeEditForm: FC<LibraryTypeEditFormProps> = ({
  model,
  libraryType,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const handleSubmit = async (values: LibraryTypeResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LibraryTypeModel();
    let submitData = values as LibraryTypeResourceForEdit;
    if (submitData && submitData.id) {
      const response = await model.updateLibraryType(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as LibraryTypeResourceForCreate;
      delete submitData.id;
      const response = await model.insertLibraryType(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <Formik
      initialValues={LibraryTypeModel.getInitialValues(libraryType)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                <LibraryTypeForm 
                   model={model}
                   libraryType={values}
                   errors={errors}
                   touched={touched}
                   handleBlur={handleBlur}
                   onHandleChange={handleChange}
                   setFieldValue={setFieldValue}
                />
              </Grid>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={libraryType.id ? Mode.edit : Mode.add}
                onIsAuth={(l) => setIsAuth(l)}
              >
                <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={{...btnSubmit, mt: 1 }} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} sx={btnReset} />
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

LibraryTypeEditForm.propTypes = {
  libraryType: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

LibraryTypeEditForm.defaultProps = {
  onSubmit: null
};

export default LibraryTypeEditForm;
