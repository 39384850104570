import { LawDomainFieldModel } from 'src/common';
import type { LawDomainFieldResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';
import { Paged } from 'src/types/pagination';

export const fetchLawDomainFields = (
  lawDomainFields: LawDomainFieldResource[],
  paged: Paged,
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
  model?: LawDomainFieldModel
): {
  type: 'FETCH_LAW_DOMAIN_FIELDS';
  payload: {
    lawDomainFields: LawDomainFieldResource[];
    paged: Paged;
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
    parentIds?: number[];
    model?: LawDomainFieldModel;
  };
} => ({
  type: actionTypes.FETCH_LAW_DOMAIN_FIELDS,
  payload: {
    lawDomainFields,
    paged,
    isLoading,
    isEmptyList,
    isAuthorize,
    model
  },
});

export const fetchLawDomainField = (lawDomainField: LawDomainFieldResource,isLoading?: boolean) : {
  type: 'FETCH_LAW_DOMAIN_FIELD';
  payload: { 
    lawDomainField: LawDomainFieldResource,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_LAW_DOMAIN_FIELD,
  payload: {
    lawDomainField,
    isLoading
  },
});

export const fetchDetailsLawDomainField = (
  lawDomainField: LawDomainFieldResource,
): {
  type: 'FETCH_DETAILS_LAW_DOMAIN_FIELD';
  payload: {
    lawDomainField: LawDomainFieldResource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_LAW_DOMAIN_FIELD,
  payload: {
    lawDomainField,
  },
});

