import { DocumentOptionResource, DocumentResource } from "src/common/types";
import * as actionTypes from '../../actions/ActionTypes';
import { Paged } from "src/types/pagination";


type FetchSpecialFilesAction = {
    type: 'FETCH_SPECIAL_FILES';
    payload: {
        specialFiles: DocumentOptionResource[];
        isLoading?: boolean;
        isEmptyList: boolean;
        paged: Paged;
    }
}
type DeleteSpecialFileAction = {
    type: "DELETE_SPECIAL_FILE",
    payload: {
        id: string;
    };
};

interface SpecialFilesReturnState {
    specialFiles?: DocumentOptionResource[];
    specialFile?: DocumentResource;
    isLoading?: boolean;
    isEmptyList?: boolean;
    paged?: Paged;
}

interface SpecialFilesState {
    specialFiles: DocumentOptionResource[];
    specialFile: DocumentResource;
    isLoading: boolean;
    isEmptyList: boolean;
    paged: Paged;
}

type Action = FetchSpecialFilesAction | DeleteSpecialFileAction;

const reducer = (state: SpecialFilesState, action: Action): SpecialFilesReturnState => {
    switch (action.type) {
        case actionTypes.FETCH_SPECIAL_FILES: {
            const { specialFiles, paged, isLoading, isEmptyList } = action.payload;
            return {
                ...state,
                specialFiles,
                paged,
                isLoading,
                isEmptyList
            }
        }
        case actionTypes.DELETE_SPECIAL_FILE: {
            return {
                ...state,
                specialFiles: [...state.specialFiles].filter(({documentId}) => documentId !== action.payload.id)
            }
        }
    }
}

export default reducer;