import {
  Box,
  TableBody,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ChangeEvent, FC, useEffect } from 'react';
import React, { useState } from 'react';
import {
  EmptyList, 
  formatDate, 
  getEmptyListSearch, 
  labelConfig,
  SbGroup,
  SubscriptionModel,
  getOpacity,
  NavigateFunction,
} from 'src/common';
import type { SearchFilter, SubscriptionResource } from 'src/common/types';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import useAuth from 'src/hooks/useAuth';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  subscriptions: SubscriptionResource[];
  isEmptyList: boolean;
  onDeleteSubscription: (id: number) => Promise<void>;
  model?:SubscriptionModel;
  navigate: NavigateFunction;
}

const Results: FC<ResultsProps> = ({
  subscriptions,
  isEmptyList,
  onDeleteSubscription,
  model,
  navigate
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState<number>(0);
  let { user } = useAuth();
  const [filter, setFilter] = useState<SearchFilter>({
    status:'', 
    doSearch: false,
    page
  });
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();
  const headCells = model.getHeadCells(labelConfig.status);

  useEffect(() => setOrderBy('name'),[]);

  useEffect(() => {
    const { Filters: { status, page } } = model;
    const statusVal = model.getQueryParam(status);
    const pageVal = +model.getQueryParam(page);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        page: pageVal,
        status: statusVal,
        doSearch: statusVal ? true : false,
      }
    });
    setPage(pageVal);
  },[model]);

  const handleStatusChange = (e: ChangeEvent<any>) => {
    model.resetPageValue(filter);
    model.getConfigParameters(filter,3,+e.target.value);
    navigate(model.getUrlEncode.apply(model,model.getParams()));
  };

  const renderTableCell = (row: SubscriptionResource) => {
    const opacity = getOpacity(row.active);
    return (
      <>
        <CaijTableCell opacity={opacity}>{SbGroup[row.group]}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.name}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.startDate && formatDate(new Date(row.startDate))}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.endDate && formatDate(new Date(row.endDate))}</CaijTableCell>
        <CaijTableCellActive active={row.active} />
        <CaijTableCellAction 
          showSubscription
          user={user}
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          handleToggle={() => SubscriptionModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
              dialog={model.Dialog}
              isOpen={selectedRow.indexOf(row.id) !== -1}
              onSubmit={async () => {
                await onDeleteSubscription(row.id)
                setSelectedRow([]);
              }}
              setSelectedRow={setSelectedRow}
            />
        </CaijTableCellAction>
      </>
    )
  }

  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      <Box p={2} minHeight={56} display='flex' alignItems='right' justifyContent='right'>
        <TextField
          label='Statut'
          name='status'
          onChange={handleStatusChange}
          select
          SelectProps={{ native: true }}
          value={filter.status}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
        >
          <option key='0' value=''>
            Tous
          </option>
          <option key='1' value='1'>
            {labelConfig.active}
          </option>
          <option key='2' value='2'>
            {labelConfig.inactive}
          </option>
        </TextField>
      </Box>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headCells}
        />
        <TableBody>
          { subscriptions.length === 0 ? getEmptyListSearch(headCells.length) :
            sort<SubscriptionResource>(subscriptions, page).map((row: SubscriptionResource) => (
              <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                {renderTableCell(row)}
              </CaijTableRowClickable>
            ))
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={{totalCount: subscriptions.length}} 
        model={model} 
        filter={filter} 
        page={page}
        navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
      />
    </MyCard>
  );
};

Results.propTypes = {
  subscriptions: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  onDeleteSubscription: PropTypes.func.isRequired,
  model: PropTypes.instanceOf(SubscriptionModel)
};

export default Results;
