import React, { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { LibraryResource, WorkingHour } from 'src/common/types';
import { Box, TextField } from '@mui/material';
import { 
  labelConfig, 
  CaijCheckbox, 
  CaijInput, 
  FormikErrors, 
  FormikTouched,  
  _libraryType, 
  renderLibraryTypeMenuItem, 
  MSG_FORBIDEN_ERROR, 
  LibraryModel,
  LibraryOpenHoursModel,
  CaijInputLink
} from 'src/common';
import LibraryContactForm from './LibraryContactForm';
import CaijDialogs from 'src/components/dialog';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { useLibraryTypes } from 'src/functions/hooks/libraryType';
import LibraryOpenHoursForm from './LibraryOpenHoursForm';

interface LibraryFormProps {
  model: LibraryModel;
  libraryOpenHoursModel: LibraryOpenHoursModel;
  library: LibraryResource;
  errors: FormikErrors<LibraryResource>;
  touched: FormikTouched<LibraryResource>;
  handleOpenHoursChange?: (openHour: WorkingHour[]) => void;
  handleBlur: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeCheckBox: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const LibraryForm: FC<LibraryFormProps> = ({
  model,
  libraryOpenHoursModel,
  library,
  errors,
  touched,
  handleOpenHoursChange,
  handleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  setFieldValue,
}) => {
  const [nameFr, setNameFr] = useState(library.nameFr);
  const [cloakroomInfo, setCloakroomInfo] = useState(library.cloakroomInfo);
  const { libraryTypeId, cloakroom, enabled, openHours, byodPrinterUrl } = library;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { libraryTypes, isLoading, isAuthorize } = useLibraryTypes();
  model.Dialog.BtnText = BtnOk.DISABLED;
  model.Dialog.Header = 'Désactiver la bibilothèque';
  model.Dialog.Name = 'la bibliothèque';
  const { NameFr, CloakroomInfo, Enabled, ByodPrinterUrl } = model;

  return (
    <>
        <CaijCheckbox
          name={Enabled.Name}
          id={Enabled.Name}
          checked={enabled}
          value={enabled}
          label={Enabled.Label}
          onHandleChangeCheckBox={(event:ChangeEvent<HTMLInputElement>) => {
            event.target.checked ? setOpenDialog(false) : setOpenDialog(true);
            onHandleChangeCheckBox(event,setFieldValue);
          }}
          setFieldValue={setFieldValue}
          sx={{mb:1}}
          inputProps={{'data-testid': Enabled.Name}}
        />
        <CaijDialogs
          dialog={model.Dialog}
          isOpen={openDialog}
          onSubmit={() => (async () => {
            setFieldValue(Enabled.Name, false);
          })()}
          close={() => { 
            !library.enabled ? setFieldValue(Enabled.Name, true) : '';
            setOpenDialog(false);
          }}
        />
        <CaijInput
          name={NameFr.Name}
          id={NameFr.Name}
          required
          value={nameFr}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            library.nameFr = nameFr;
            handleBlur(e);
          }}
          error={Boolean(touched.nameFr && errors.nameFr)}
          helperText={touched.nameFr && errors.nameFr}
          label={NameFr.Label}
          InputLabelProps={{ shrink: true }}
          inputAttr={{maxLength: NameFr.MaxLength, autoComplete: 'none', 'data-testid': NameFr.Name}}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setNameFr(e.target.value)}
          setFieldValue={setFieldValue}
        />
        <TextField
          label={labelConfig.libraryType}
          name='libraryTypeId'
          onChange={(e:ChangeEvent<HTMLTextAreaElement>) => onHandleChange(e,setFieldValue)}
          onBlur={(e) => handleBlur(e)}
          select
          SelectProps={{ native: true }}
          value={libraryTypeId}
          variant='outlined'
          InputLabelProps={{ shrink: true, required: true }}
          error={Boolean(touched.libraryTypeId && errors.libraryTypeId)}
          helperText={(touched.libraryTypeId && errors.libraryTypeId) || ' '}
          sx={{ mt: 2}}
        >
          <option value=''>Sélectionner un type(obligatoire)</option>
          {renderLibraryTypeMenuItem(isAuthorize, libraryTypes)}
        </TextField>
        <Box>{isLoading && !isAuthorize && <span style={{ color: 'red' }}>{MSG_FORBIDEN_ERROR}</span>}</Box>
        <Box mb={2}>
            <CaijCheckbox
                name='cloakroom'
                checked={cloakroom}
                value={cloakroom}
                label={labelConfig.cloakroom}
                sx={{mb:1}}
                inputProps={{ 'data-testid': 'cloakroom' }}
                onHandleChangeCheckBox={onHandleChangeCheckBox}
                setFieldValue={setFieldValue}
            />
            {cloakroom && (
                <CaijInput
                    name={CloakroomInfo.Name}
                    id={CloakroomInfo.Name}
                    value={cloakroomInfo}
                    error={Boolean(touched.cloakroomInfo && errors.cloakroomInfo)}
                    helperText={touched.cloakroomInfo && errors.cloakroomInfo}
                    label={CloakroomInfo.Label}
                    InputLabelProps={{ shrink: true }}
                    inputAttr={{maxLength: CloakroomInfo.MaxLength, 'data-testid': CloakroomInfo.Name}}
                    onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
                      library.cloakroomInfo = cloakroomInfo;
                      handleBlur(e);
                    }}
                    onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCloakroomInfo(e.target.value)}
                    setFieldValue={setFieldValue}
                />
            )}
            <LibraryContactForm
              model={model}
              library={library}
              handleBlur={handleBlur}
            />
            <CaijInputLink
              name={ByodPrinterUrl.Name}
              id={ByodPrinterUrl.Name}
              value={byodPrinterUrl}
              label={ByodPrinterUrl.Label}
              helperText={touched.byodPrinterUrl && errors.byodPrinterUrl}
              error={Boolean(touched.byodPrinterUrl && errors.byodPrinterUrl)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: ByodPrinterUrl.MaxLength, 'data-testid': ByodPrinterUrl.Name }}
              onHandleBlur={handleBlur}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
              sx={{mb: 2}}
            />
            { handleOpenHoursChange && (
                <Box>
                  <LibraryOpenHoursForm 
                    openHours={openHours} 
                    model={libraryOpenHoursModel}
                    onHandleOpenHoursChange={handleOpenHoursChange} 
                  />
                </Box>
              )
            }
        </Box>
    </>
  );
};

LibraryForm.propTypes = {
  library: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func.isRequired,
  handleOpenHoursChange: PropTypes.func
};

export default LibraryForm;
