import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  fetchDetailsLawDomainField,
  LawDomainFieldModel,
  setEditHash,
  setHash,
  useLocation,
  useNavigate,
  useParams,
  _lawDomainField,
  root,
  Mode
} from 'src/common';
import Page from 'src/components/Page';
import Details from './Details';
import DetailsEditForm from './Forms/DetailsEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { LawDomainFieldProvider } from 'src/contexts/LawDomainFieldContext';

interface TabsLawDomainField {
  value: string, label: string
}

const tabs: TabsLawDomainField[] = [
  { value: 'details', label: 'Détails' },
];

const LawDomainFieldDetailsView: FC = () => {
  const model = LawDomainFieldModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_lawDomainField, { lawDomainField: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  model.Dialog.Header = 'Supprimer le domaine de droit';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'le domaine de droit';
  
  const getLawDomainFieldById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      await model.redirect(model.Path.Home);
    }else if(access.canRead()){
      const model = new LawDomainFieldModel();
      model.PathName = location.pathname;
      const lawDomainField = await model.getLawDomainFieldById(id);
      if (!model.error) {
        dispatch(fetchDetailsLawDomainField(lawDomainField));
      }
    }
  }, [id, location]);

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsLawDomainField) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }
  
  useEffect(() => {
    (async () => {
      handleBrowserRefresh()
      await getLawDomainFieldById();
    })();
  },[]);

  useEffect(() => {
    const { hash, pathname } = location;
    (new LawDomainFieldModel).PathName = pathname + hash;
  },[location]);

  const setEditModeLawDomainField = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeLawDomainField(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    event.preventDefault();
    setHash(value, navigate);
    setEditModeLawDomainField(false);
    setCurrentTab(value);
  };

  const handleRefreshLawDomainField = async (value: string) => {
    setHash(value, navigate);
    setEditModeLawDomainField(false);
    await getLawDomainFieldById();
  };

  const editLawDomainField = () => {
    setEditHash('edit', navigate);
    setEditModeLawDomainField(true);
  };

  const { lawDomainField } = state;

  if (Object.keys(lawDomainField).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = lawDomainField.nameFr;

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
              <DetailsEditForm
                model={model}
                lawDomainField={lawDomainField}
                onHandleRefreshLawDomainField={() => handleRefreshLawDomainField('details')}
                onSwitchMode={(l) => switchMode(l, 'details')}
              />
          );
        }
        return (
          <LawDomainFieldProvider>
            <Details lawDomainField={lawDomainField} model={model}/>
          </LawDomainFieldProvider>
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          editMode={editMode.details}
          actions={['editDetail', 'delete']}
          onEditDetail={() => editLawDomainField()}
          deleteMode={Mode.admin}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default LawDomainFieldDetailsView;
