import React, { useState, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Collapse,
  IconButton
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  CustomerModel,
  CustomerStatus,
  ListType,
  CaijInput,
  CaijSwitch,
  getEmptyListSearch,
  ITEM_HEIGHT,
  ITEM_PADDING_TOP,
  Link as RouterLink,
  link,
  ExpertListFilter,
  FIRST_PAGE,
  NavigateFunction,
  CaijTooltip
} from 'src/common';
import type { 
  CustomerResource, 
  SubscriptionResource, 
  SortOption, 
  Sort,
  SearchFilter
} from 'src/common/types';
import { Paged } from 'src/types/pagination';
import { $enum } from 'ts-enum-util';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import { filters } from 'src/styles/customer';
import Light from 'src/components/customer/customer/List/Light';
import CaijTable from 'src/components/table/CaijTable';
import FilterTableCells from 'src/components/customer/customer/List/Expert/FilterTableCells';
import Expert from 'src/components/customer/customer/List/Expert';
import MenuIcon from '@mui/icons-material/Dehaze';
import { AccessGroup } from 'src/types/subscription';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';

export interface ResultsProps {
  customerModel: CustomerModel;
  customers: CustomerResource[];
  subscriptions?: SubscriptionResource[];
  groups?: AccessGroup;
  isEmptyList: boolean;
  paged: Paged;
  model: CustomerModel;
  resetGroups?: (ids: string) => AccessGroup;
  navigate: NavigateFunction;
  location: {search: string}
}

const sortOptions: SortOption[] = [
  {
    value: 'asc',
    label: 'Nom [A-Z]',
  },
  {
    value: 'desc',
    label: 'Nom [Z-A]',
  },
];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getSubscription(subscriptions: SubscriptionResource[], value: string[]){
  return value.reduce((accValue: string[], currentValue: string) => {
    accValue.push(subscriptions.find(({id}) => id === +currentValue)?.name);
    return accValue;
  },[]);
};

function getStatus(value: string[]){
  return value.reduce((accValue: string[], currentValue: string) => {
    accValue.push(currentValue);
    return accValue;
  },[]);
};

const Results: FC<ResultsProps> = ({
  customerModel,
  customers,
  subscriptions,
  groups,
  paged,
  isEmptyList,
  model,
  resetGroups,
  navigate,
  location
}) => {
  const path = customerModel.Path;
  const currentPage = paged.currentPage - 1;
  const searchParams = new URLSearchParams(location?.search);
  const query = searchParams.get("query");
  const toggle = searchParams.get("toggle");
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<string[]>([]);
  const expertVersion = toggle === customerModel.Toggle.yes ? true : false;
  const [filter, setFilter] = useState<SearchFilter>({
    query,
    sort: sortOptions[0].value,
    delayAccountCustomer: false,
    toggle: customerModel.Toggle.no,
    page: currentPage,
  });
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const {Filters: { query, status, sort, delayAccountCustomer, subscription, firstName, lastName, extIdentifier, card, email, noCaij, toggle, group, page }} = model;
    let queryVal = '', pageVal = FIRST_PAGE, sortVal = '',statusVal = '', delayAccountCustomerVal = '', subscriptionVal = '', firstNameVal = '', lastNameVal = '', extIdentifierVal = '', cardVal = '', emailVal = '', noCaijVal = '', toggleVal = '', groupVal = '';
    for (const [key, value] of urlParams.entries()) {
      switch(key){
        case page:
          pageVal = +value;
          break;
        case sort:
          sortVal = value;
          break;
        case query:
          queryVal = value;
          break;
        case status:
          statusVal = value;
          break;
        case delayAccountCustomer:
          delayAccountCustomerVal = value;
          break;
        case subscription:
          subscriptionVal = value;
          break;
        case firstName:
          firstNameVal = value;
          break;
        case lastName:
          lastNameVal = value;
          break;
        case extIdentifier:
          extIdentifierVal = value;
          break;
        case card:
          cardVal = value;
          break;
        case email:
          emailVal = value;
          break;
        case noCaij:
          noCaijVal = value;
          break;
        case toggle:
          toggleVal = value;
          break;
        case group:
          groupVal = value;
          break;
      }
    }
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        page: pageVal,
        toggle: toggleVal,
        status: statusVal,
        subscription: subscriptionVal,
        group: groupVal,
        firstName: firstNameVal,
        lastName: lastNameVal,
        extIdentifier: extIdentifierVal, 
        card: cardVal, 
        email: emailVal, 
        noCaij: noCaijVal,
        sort: sortVal as Sort || 'asc',
        delayAccountCustomer: Boolean(delayAccountCustomerVal)
      }
    });
    if(toggleVal === customerModel.Toggle.yes){
      let groups: AccessGroup;
      if(resetGroups)
        groups = resetGroups(subscriptionVal);
      customerModel.reSetSelectedFilterItem(statusVal, subscriptionVal, groupVal, groups);
    }else{
      subscriptionVal ? setSelectedSubscriptions(getSubscription(subscriptions,subscriptionVal.split(','))) : setSelectedSubscriptions([]);
      statusVal ? setSelectedStatus(getStatus(statusVal.split(','))) : setSelectedStatus([]);
    }
  },[location?.search]);

  const handleClick = () => setOpen(!open);

  const handleSubscriptionChange = (event: SelectChangeEvent<typeof selectedSubscriptions>) => {
    const { value } = event.target;
    setSelectedSubscriptions(typeof value === 'string' ? value.split(',') : value);
    customerModel.clearSelectedSubscription();
    for(const val of value){
	    customerModel.setSelectedFilterItem(ExpertListFilter.Subscription, String(subscriptions.find(({name}) => name === val)?.id), false);
    }
    customerModel.getConfigParameters(filter,13,customerModel.getSelectedFilterItemParamsValue(ExpertListFilter.Subscription));
    navigate(customerModel.getUrlEncode.apply(customerModel,customerModel.getParams()));
  };

  const handleStatusChange = (event: SelectChangeEvent<typeof selectedStatus>) => {
    const {value} = event.target;
    setSelectedStatus(typeof value === 'string' ? value.split(',') : value);
    customerModel.clearSelectedAccountStatus();
    for(const val of value){
	    customerModel.setSelectedFilterItem(ExpertListFilter.Status,val, false);
    }
    customerModel.getConfigParameters(filter,3,customerModel.getSelectedFilterItemParamsValue(ExpertListFilter.Status));
    navigate(customerModel.getUrlEncode.apply(customerModel,customerModel.getParams()));
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const sort = event.target.value as Sort;
    customerModel.getConfigParameters(filter,8,sort);
    navigate(customerModel.getUrlEncode.apply(customerModel,customerModel.getParams()));
  };

  const renderSearchInput = () => (
    <Search 
      listType={ListType.Customer}
      placeholder='Recherche le/les membre(s)' 
      filter={filter}
      path={path.Home}
      width="400px"
    />
  );

  const renderResetFilterButton = (allowedExpertMode: boolean, alignSelf: string = '', marginTop: number = 0) => (
    <CaijResetFilterButton 
      sx={{...filters,alignSelf,marginTop}} 
      path={path.Home} 
      navigate={navigate} 
      extraParam={allowedExpertMode ? "?toggle=yes" : ''}
      callBack={() => customerModel.clearSelectedFilterItem()} 
    /> 
  );

  const renderSubscriptionDropdown = () => {
    if(subscriptions)
      return (
        <FormControl sx={{margin:'0 15px 20px 0',width: 400}}>
          <InputLabel id="subscriptions">Abonnement</InputLabel>
          <Select
            id="subscriptions"
            multiple
            value={selectedSubscriptions}
            onChange={handleSubscriptionChange}
            input={<OutlinedInput label="Abonnement" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {subscriptions && subscriptions.map(({id,name}) => (
              <MenuItem key={id} value={name}>
                <Checkbox checked={selectedSubscriptions.indexOf(name) > -1} />
                <ListItemText primary={
                  <Typography variant="subtitle1" sx={{whiteSpace: 'pre-wrap'}}>{name}</Typography>
                }/>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
  }

  const renderStatutDropdown = () => (
    <FormControl sx={{ margin: '0 10px 20px 0', width: 320 }}>
      <InputLabel id="status">{labelConfig.customerStatus}</InputLabel>
      <Select
        id="status"
        multiple
        value={selectedStatus}
        onChange={handleStatusChange}
        input={<OutlinedInput label={labelConfig.customerStatus} />}
        renderValue={(selected) => selected.map((value: string) => CustomerModel.getStatusByKey(value)).join(', ')}
        MenuProps={MenuProps}
      >
        {$enum(CustomerStatus).getValues().sort().map((value) => {
          if(value !== CustomerStatus.Restreint){
            const key = $enum(CustomerStatus).getKeyOrDefault(value);
            return (<MenuItem key={key} value={key}>
              <Checkbox checked={selectedStatus.indexOf(key) > -1} />
              <ListItemText primary={
                <Typography variant="subtitle1" sx={{whiteSpace: 'pre-wrap'}}>{value}</Typography>
              }/>
            </MenuItem>
            )
          }
          })
        }
      </Select>
    </FormControl>
  );

  const renderSortDropdown = () => (
    <CaijInput
      label='Trier par'
      name='Sort'
      onHandleChange={handleSortChange}
      select
      value={filter.sort}
      sx={filters}
      InputLabelProps={{ shrink: true }}
    >
      {sortOptions.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </CaijInput>
  );
  
  const renderLightList = () => (
    <>
      <Box sx={{overflowX:'auto',display:{xs:'none',sm:'none',md:'flex',lg:'flex'},marginTop:'7px',alignItems:'center',justifyContent:'space-between'}} >
        <Box sx={{margin:'0 0 20px 15px'}}>
          {renderSearchInput()}
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between' sx={{margin:'20px 15px'}}>
          <Box sx={{height: "56", marginBottom: '20px'}}>
            {renderResetFilterButton(false)}
          </Box>
          {renderSubscriptionDropdown()}
          {renderStatutDropdown()}
          <Box sx={{height: "56", marginBottom: '20px', marginTop: '20px'}}>
            {renderSortDropdown()}
          </Box>
        </Box>
      </Box>
      <Box sx={{display:{xs:'block',sm:'block',md:'none',lg:'none'}, marginLeft:'10px', marginTop:'7px'}}>
        <CaijTooltip title="Menu" marginTop="18px">
          <IconButton onClick={handleClick} sx={{marginLeft: '10px'}}>
            <MenuIcon />
          </IconButton>
        </CaijTooltip>
        <Collapse timeout="auto" in={open} unmountOnExit sx={{margin:'0 0 0 15px'}}>
          <Box sx={{margin:'10px 0 20px 10px', minWidth:250}}>
            {renderResetFilterButton(false)}
          </Box>
          <Box sx={{margin:'15px 10px'}}>
            {renderSearchInput()}
          </Box>
          <Box sx={{margin:'20px 0 0 10px', width:400}}>
            {renderSubscriptionDropdown()}
          </Box>
          <Box sx={{ margin:'0 0 5px 10px'}}>
            {renderStatutDropdown()}
          </Box>
          <Box sx={{margin:'0 0 10px 10px', width:150}}>
            {renderSortDropdown()}
          </Box>
        </Collapse>
      </Box>
      { isEmptyList ? (<EmptyList value="Aucune donnée trouvée" />) : (
        customers.map((row: CustomerResource, index: number) => (
          <Link 
            key={index}
            underline="none" 
            color='inherit' 
            component={RouterLink} 
            to={path.getDetail(row.id)} 
            sx={{...link}} 
            rel="noopener noreferrer"
          >
            <Light 
              customer={row} 
              path={path} 
              key={index} 
              logo={model?.getLogos(row.id)} 
            />
          </Link>
        )))
      }
    </>
  );

  const renderExpertList = () => { 
    const headCells = customerModel.getHeadCells();
    return (
      <Box display='flex' flexDirection="column">
        {renderResetFilterButton(true,"end",2)}
        <CaijTable>
          <TableHead>
            <TableRow>
              { headCells.map(({colName, width, type, property}, index: number) => (
                  <FilterTableCells
                    model={customerModel}
                    path={path}
                    colName={colName} 
                    sx={{width}} 
                    type={type} 
                    filter={filter} 
                    property={property}
                    key={index}
                    subscriptions={subscriptions} 
                    groups={groups}
                  />
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            { isEmptyList ? getEmptyListSearch(headCells.length) : 
              customers.map((row: CustomerResource, index: number) => (
                <Expert customer={row} path={path} key={index} />
              ))
            }
          </TableBody>
        </CaijTable>
      </Box>
    )
  }
  
  return (
    <MyCard>
      <>
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '10px', paddingRight: '10px' }}>
          <CaijSwitch
            name='expertVersion'
            label="Version expert"
            value={expertVersion}
            checked={expertVersion}
            onHandleChangeSwitch={(event) => {
              if(event.target.checked){//Expert version
                customerModel.getConfigParameters(filter,19,customerModel.Toggle.yes);
                navigate(customerModel.getUrlEncode.apply(customerModel,customerModel.getParams()));
              }else{
                filter.query = '';
                customerModel.getConfigParameters(filter,19,customerModel.Toggle.no);
                navigate(customerModel.getUrlEncode.apply(customerModel,customerModel.getParams()));
              }
            }}
          />
        </Box>
        {expertVersion ? renderExpertList() : renderLightList() }
        <CaijTablePagination 
          paged={paged}
          model={model}
          page={currentPage}
          filter={filter}
          navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
        />
      </>
    </MyCard>
  )
};

Results.propTypes = {
  customers: PropTypes.array.isRequired,
  subscriptions: PropTypes.array,
  isEmptyList: PropTypes.bool
};

export default Results;
