import React from 'react';
import {Box,SvgIcon} from '@mui/material';
import { CaijImage, labelConfig } from 'src/common';
import { Image as ImageIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';

export interface GeneralInfoProps {
  cover: string;
}

export default class CoverInfo extends React.Component<GeneralInfoProps>{
  render(){
    const {cover} = this.props;
    return (
      <Box sx={{mb:'2em'}} display='flex'>
        <SvgIcon fontSize='small'>
          <ImageIcon />
        </SvgIcon>
        <Box display='flex' marginLeft='0.5em' flexDirection='column'>
          <Box display='flex' alignItems='center' mb={2}>
            <CaijTypography sx={{ fontWeight: 'bold' }}>{labelConfig.cover}</CaijTypography>
          </Box>
          <CaijImage id="logoUrlFr" src={cover} alt={cover} />
        </Box>
      </Box>
    );
  }
};