import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import {
  AccessModel,
  fetchDetailsLibraryService,
  LibraryServiceModel,
  ListType,
  setEditHash,
  setHash,
  useLocation,
  useNavigate,
  useParams,
  _library,
  _libraryService,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Details from './Details';
import DetailsEditForm from './Forms/DetailsEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { useLibraries } from 'src/components/listInfo/hook';
import LibraryForm from './Forms/LibraryForm';
import LibrariesInfo from 'src/components/requiredParameter/listInfo';
import GenericHeader from 'src/components/header/GenericHeader';

interface TabsLibraryService {
  value: string, label: string
}

const initialState = {
  libraryService: {}
};

const tabs: TabsLibraryService[] = [
  { value: 'details', label: 'Détails' },
  { value: 'library', label: 'Bibliothèques' }
];

const LibraryServiceDetailsView: FC = () => {
  const model = LibraryServiceModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_libraryService, initialState);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    library: false
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  const { libraries, isAuthorize } = useLibraries();

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsLibraryService) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }

  const getLibraryServiceById = useCallback(async () : Promise<void> => {
    const model = new LibraryServiceModel();
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      model.PathName = location.pathname;
      const libraryService = await model.getLibraryServiceById(id);
      if (!model.error) {
        dispatch(fetchDetailsLibraryService(libraryService));
      }
    }
   
  }, [id, location]);

  useEffect(() => {
    (async () => {
      handleBrowserRefresh();
      await getLibraryServiceById();
    })();
  }, []);

  const { libraryService } = state;

  if (Object.keys(libraryService).length === 0) {
    return <LoadingScreen />;
  }
  
  model.Title = libraryService.nameFr;

  const setEditModeLibraryService = (isEdit: boolean, value?: string) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeLibraryService(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setHash(value, navigate);
    setEditModeLibraryService(false, value);
    setCurrentTab(value);
  };

  const handleRefreshLibraryService = async (value?: string) : Promise<void> => {
    setHash(value, navigate);
    await getLibraryServiceById();
    setEditModeLibraryService(false, value);
  };

  const editLibraryService = () => {
    setEditHash('edit', navigate);
    setEditModeLibraryService(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <DetailsEditForm
              model={model}
              libraryService={libraryService}
              onHandleRefreshLibaryService={() => handleRefreshLibraryService(tabs[0].value)}
              onSwitchMode={(l) => switchMode(l, tabs[0].value)}
            />
          );
        }
        return (
          <Details model={model} libraryService={libraryService} />
        );
      case 'library':
        if (editMode.library) {
          return (
            <LibraryForm
              model={model}
              libraryService={libraryService}
              onHandleRefreshLibaryService={() => handleRefreshLibraryService(tabs[1].value)}
              onSwitchMode={(l) => switchMode(l, tabs[1].value)}
            />
          );
        }
        return (
          <LibrariesInfo 
            isAuthorize={isAuthorize}
            listType={ListType.LibraryServiceLibrary}
            data={libraries}
            lists={libraryService.libraryServiceLibraries}
          />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editLibraryService()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryServiceDetailsView;
