import type { ContentPublicationsResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';
import { Paged } from 'src/types/pagination';

export const fetchContentPublications = (contentPublications: ContentPublicationsResource[], paged?: Paged, isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
   type: 'FETCH_CONTENT_PUBLICATIONS',
   payload: {
     contentPublications: ContentPublicationsResource[],
     paged?: Paged
     isLoading?: boolean,
     isEmptyList?: boolean,
     isAuthorize?: boolean
   }
} => ({
  type: actionTypes.FETCH_CONTENT_PUBLICATIONS,
  payload: {
    contentPublications,
    paged,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchContentPublication = (contentPublication: ContentPublicationsResource,isLoading?: boolean): { 
  type: 'FETCH_CONTENT_PUBLICATION', 
  payload: { 
    contentPublication: ContentPublicationsResource,
    isLoading?: boolean
  } 
} => ({
  type: actionTypes.FETCH_CONTENT_PUBLICATION,
  payload: {
    contentPublication,
    isLoading
  }
});

export const fetchDetailsContentPublication = (contentPublication: ContentPublicationsResource): {
  type: 'FETCH_DETAILS_CONTENT_PUBLICATIONS'
  payload: {
    contentPublication: ContentPublicationsResource,
  }
} => ({
  type: actionTypes.FETCH_DETAILS_CONTENT_PUBLICATIONS,
  payload: {
    contentPublication
  }
});
