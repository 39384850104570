import React, { Dispatch, SetStateAction, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { 
  Box, 
  Button, 
  CardContent, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  SvgIcon, 
  Typography 
} from '@mui/material';
import {
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  ApiKeyModel,
  useFormik,
  CaijTextarea,
  CaijSwitch,
  handleChangeSwitch,
  CaijInput,
  dialogTitle,
  dialog,
  NavigateFunction,
  useNavigate
} from 'src/common';
import type {
  Error,
  ApiKeyDto,
  ApiKeySettings
} from 'src/common/types';
import { 
  CheckCircle as ConfirmIcon,
  Copy as CopyIcon
} from 'react-feather';
import Authorize from 'src/components/Authorize';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';

export interface ApiKeyEditFormProps {
  model: ApiKeyModel;
  apiKey: ApiKeyDto;
  onSubmit?: (values: ApiKeyDto) => void
}

async function redirect(model: ApiKeyModel, response: Error) : Promise<void>{
  await model.redirect(model.Path.Home, { 
    status: response.status, 
    message: response.message
  });
};

interface ICopy {
  loading: boolean;
  copying?: boolean;
  success?: boolean;
  apiKey: string;
};

const getCopyApiKeyMessageError = (apiKey: string, success: boolean) : JSX.Element => {
  const error = {color: 'text.error', margin: '0.5em'};
  if(!apiKey){
    return (
      <Typography variant="body2" sx={error}>Clé d'Api invalid</Typography>
    );
  }else if(!success){
    return (
      <Typography variant="body2" sx={error}>Copié avec sans succès</Typography>
    ); 
  }
};

function getCopyApiKeyMessage(copy: ICopy) : JSX.Element {
  const { success, apiKey, copying } = copy;
  if(copying && success){
    return (
      <span style={{color: '#31c110',margin: '0.5em'}}>Copié</span>
    );
  }else if(copying && !success){
    return getCopyApiKeyMessageError(apiKey,success);
  }
}

const copyApiKeyToClipBoard = async (apiKey: string, setCopy: Dispatch<SetStateAction<ICopy>>) => {
  if(apiKey){
    try {
      await navigator.clipboard.writeText(apiKey);
      setCopy((prevState: ICopy) => {
        return {
          ...prevState,
          success: true,
          copying: true
        }
      });
    } catch (err) {
      setCopy((prevState: ICopy) => {
        return {
          ...prevState,
          success: false,
          copying: true
        }
      });
    }
  }
};

const dialogRender = (copy:ICopy, setCopy: Dispatch<SetStateAction<ICopy>>, navigate: NavigateFunction, model: ApiKeyModel) : JSX.Element => {
  return (
    <>
      <DialogTitle sx={dialogTitle}>Votre nouvelle clé d'api!</DialogTitle>
        <DialogContent dividers sx={{...dialog, width: 'auto' }}>
          <Box mb={2}>
            <Typography variant='body2' sx={{color: 'text.secondary'}}>Veuillez prendre en note la clé d'api, car celle-ci ne sera plus affichée.</Typography>
          </Box>
          <Box display='flex' alignItems='center' justifyContent="space-between">
            <Box mr={2} flexGrow="1">
              <CaijInput 
                name='apiKey' 
                label="Clé d'Api à copier"
                size='small' 
                placeholder="Clé d'Api à copier"
                InputLabelProps={{ shrink: true}}
                inputProps={{readOnly: true}}
                value={copy.apiKey}
              />
            </Box>
            <Box mb={1} display="flex" alignItems="center">
              <Button  
                  variant="outlined"
                  type="button"
                  onClick={async () => await copyApiKeyToClipBoard(copy.apiKey, setCopy)}
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <CopyIcon />
                    </SvgIcon>
                )}>Copier</Button>
                <Box>{ getCopyApiKeyMessage(copy) }</Box>
            </Box>
          </Box>
      </DialogContent>
      <DialogActions sx={dialog}>
          <Button  
            variant="outlined"
            type="reset"
            onClick={() => {
              setCopy((prevState: ICopy) => {
                return {
                  ...prevState,
                  loading: false
                }
              });
              navigate(model.Path.Home);
              }
            }
            startIcon={(
              <SvgIcon fontSize="small">
                <ConfirmIcon />
              </SvgIcon>
            )}>OK</Button>
      </DialogActions>
    </>
  );
};

const ApiKeyEditForm: FC<ApiKeyEditFormProps> = ({
  model,
  apiKey,
  onSubmit
}) => {
  const { Name, ApiKey, Active, Description, Path } = model;
  const navigate = useNavigate();
  const [copy, setCopy] = useState<ICopy>({
    loading: false,
    success: false,
    copying: false,
    apiKey: null,
  });
  
  const validationSchema = () => {
    return Yup.object({
      name: Name.required(true),
      description: Description.required(true)
    });
  };

  const { errors, handleBlur, setFieldValue, handleSubmit, handleChange, values, touched, isSubmitting } = useFormik({
    initialValues: {...apiKey, description: apiKey.description || '', name: apiKey.name || ''},
    validationSchema: validationSchema(),
    validateOnChange: false,
    validateOnBlur: apiKey.id ? true : false,
    onSubmit: async values => {
      if(onSubmit){
        onSubmit(values);
        return;
      }
      const model = new ApiKeyModel();
      const { id } = values;
      let submitData = values as ApiKeySettings;
      if(id){
        const response = await model.updateApiKey(id, submitData);
        if(!model.error){
          redirect(model, response);
        }
      }else{
        const response = await model.insertApiKey(submitData);
        if(!model.error){
          const { data : { apiKey }} = response;
          setCopy((prevState: ICopy) => {
            return {
              ...prevState,
              apiKey,
              loading: true
            }
          })
        }
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <MyCard>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Root>
              {
                values.id && (
                  <>
                  <CaijInput
                    name={ApiKey.Name}
                    id={ApiKey.Name}
                    value={values.apiKey}
                    label={ApiKey.Label}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{readOnly: true}}
                  />
                  <CaijSwitch
                    name={Active.Name}
                    label={Active.Label}
                    checked={values.active}
                    value={values.active}
                    sx={{mb:2}}
                    inputProps={{'aria-label': Active.Label, 'data-testid': Active.Name }}
                    onHandleChangeSwitch={handleChangeSwitch}
                    setFieldValue={setFieldValue}
                  />
                </>
                )
              }
              <CaijInput
                required
                name={Name.Name}
                id={Name.Name}
                value={values.name}
                label={Name.Label}
                InputLabelProps={{ shrink: true }}
                inputAttr={{ maxLength: Name.MaxLength, 'aria-label': Name.Label, 'data-testid': Name.Name }}
                inputProps={{autoFocus: true}}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <CaijTextarea
                required
                name={Description.Name}
                id={Description.Name}
                rows="4"
                value={values.description}
                label={Description.Label}
                InputLabelProps={{ shrink: true }}
                inputProps={{maxLength: Description.MaxLength, 'aria-label': Description.Label, 'data-testid': Description.Name }}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </Root>
            <Box display="flex" alignItems="center">
              <Authorize
                resourceCode={model.ResourceCode}
                mode={apiKey.id ? Mode.edit : Mode.add}
              >
                <CaijButtonSubmit disabled={isSubmitting} sx={btnSubmit} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} pathName={Path.Home} sx={btnSubmit} />
              <Dialog maxWidth='sm' fullWidth open={(copy.loading && copy.apiKey !== null)}>
                {dialogRender(copy, setCopy, navigate, model)}
              </Dialog>
            </Box>
          </Box>
        </CardContent>
      </MyCard>
    </form>
  );
};

ApiKeyEditForm.propTypes = {
  apiKey: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

ApiKeyEditForm.defaultProps = {
  onSubmit : null
};

export default ApiKeyEditForm;
