import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Box } from '@mui/material';
import { CaijInput } from 'src/common';

interface CaijDropdownProps {
  defaultValue: string;
  name: string;
  children: () => JSX.Element[];
  navigate?: (value: string) => void;
  [x: string]: any;
}

const CaijDropdown: FC<CaijDropdownProps> = ({name, defaultValue, navigate, children, ...rest} : CaijDropdownProps) => {
  const [value, setValue] = useState<string>(defaultValue);

  useEffect(() => { setValue(defaultValue);},[defaultValue]);
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
    if(navigate){
      navigate(value);
    }
  }

  return (
    <Box sx={{padding: '15px 15px 10px 15px'}}>
      <CaijInput
        {...rest}
        name={name}
        onHandleChange={handleChange} 
        select
        size="small"
        value={value}
        variant='outlined'
        InputLabelProps={{ shrink: true }}
      >
        <option value=''>---Sélectionner---</option>
        {children()}
      </CaijInput>
    </Box>
  );
};

export default CaijDropdown
