import { Theme } from 'src/common/types'

export const tableRow = {
  "&:last-child th, &:last-child td": {
    borderBottom: 0,
  },
};

export const dialog = {
  backgroundColor: 'background.paper',
  padding: '15px'
};

export const dialogTitle = {
  ...dialog,
  fontSize: 14
};

export const listIcon = { minWidth: '35px'};

export const link = {
  ':hover': {
      textDecoration:'none'
  }
};

export const btnSubmit = {
    mr: 1.5
}

export const btnReset = {
    mt : 1
}

export const button = {
  ...btnSubmit
}

export const input = {
  mb: 0
}

export const root = {
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  minHeight: '100%',
  paddingTop: (theme: Theme) => theme.spacing(3),
  paddingBottom: (theme: Theme) => theme.spacing(3)
};