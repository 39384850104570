import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { CaijTooltip } from 'src/common';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const attrs = {
  placement:"top",
  marginBottom:'15px'
};

export function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const disablePrevFirstPage = page === 0;
  const disableNexLastPage = page >= Math.ceil(count / rowsPerPage) - 1;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <CaijTooltip title="Première page" {...attrs} disabled={disablePrevFirstPage}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={disablePrevFirstPage}
          aria-label="Première page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </CaijTooltip>
      <CaijTooltip title="Page précédente" {...attrs} disabled={disablePrevFirstPage}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={disablePrevFirstPage}
          aria-label="Page précédente"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
      </CaijTooltip>
      <CaijTooltip title="Page suivante" {...attrs} disabled={disableNexLastPage}>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={disableNexLastPage}
          aria-label="Page suivante"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </CaijTooltip>
      <CaijTooltip title="Dernière page" {...attrs} disabled={disableNexLastPage}>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={disableNexLastPage}
          aria-label="Dernière page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </CaijTooltip>
    </Box>
  );
}