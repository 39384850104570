import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import { 
  useParams, 
  useLocation, 
  fetchMessage,
  _message, 
  MessageModel, 
  isNaN,
  AccessModel,
  MessagePlaceholderModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import DetailsRow from '../DetailsRow';
import MessageDetails from './MessageDetails';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  message: {},
};

const MessagesDetailsView: FC = () => {
  const messagePlaceholderModel = MessagePlaceholderModel.getInstance();
  const model = MessageModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_message, initialState);
  const { id } = useParams();
  const [createMsg, setCreateMsg] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const switchCreateMsg = () => {
    setCreateMsg(true);
    if (isCreating) setCreateMsg(false);
  };

  const getMessageById = useCallback(async () : Promise<void> => {
    const model = new MessageModel();
    if (!id || isNaN(+id)) {
      await model.redirect(model.Path.Home);
    }else if(access.canRead()){
      model.PathName = location.pathname;
      const message = await model.getMessageById(Number(id));
      if (!model.error) {
        dispatch(fetchMessage(message));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getMessageById();
    })();
  },[]);

  const { message } = state;
  
  if (Object.keys(message).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = message.name;

  return (
    <Page sx={root} title={model.DetailPageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.Title} message={{id: message.placeholder.id, label: message.placeholder.name }} />
        {message.placeholder && (
            <>
              <Box mt={3}>
                <DetailsRow
                  model={model}
                  messageView={true}
                  message={message}
                  colorRender={(l) => MessageModel.colorRender(l)}
                  isCreating={createMsg}
                  createMsg={switchCreateMsg}
                  placeholder={message.placeholder}
                />
              </Box>
              <Box mt={3}>
                <MessageDetails navigate={navigate} messagePlaceholderModel={messagePlaceholderModel} message={message} />
              </Box>
            </>
          )}
      </Container>
    </Page>
  );
};

export default MessagesDetailsView;
