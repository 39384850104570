import {Success} from 'src/common';
import type {CustomerPendingChangeResource} from 'src/common/types';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import axios from 'src/utils/axios';
import CustomerModel from './Customer';

export default class CardRequestPendingModel extends CustomerModel
{
  private static _cardRequestPendingInstance: CardRequestPendingModel;
 
  constructor(){
    super();
    this._resourceCode = 'CARTE_CAIJ';
    this._headerTitle = 'Liste des demandes de carte CAIJ';
    this.Path.PathName = '/members/card-request';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Demande de carte en attente',
      href: this.Path.Home,
      visible: true
    }
  }

  get Section2(){
    return {
      resourceCode: this._resourceCode, 
      title: 'carteCaij',
      visible: false
    }
  }

  get ConfigDialog(){
    const dialog = this._dialog;
    dialog.ResourceCode = this._resourceCode;
    dialog.Header = 'Supprimer la demande de carte CAIJ';
    dialog.BtnText = BtnOk.DELETED;
    dialog.Name = 'la demande de carte CAIJ';
    return dialog
  }

  static getInstance(): CardRequestPendingModel {
    if (!CardRequestPendingModel._cardRequestPendingInstance) {
      CardRequestPendingModel._cardRequestPendingInstance = new CardRequestPendingModel();
    }
    return CardRequestPendingModel._cardRequestPendingInstance;
  }

  async getCardRequestPending(): Promise<CustomerPendingChangeResource[]> {
    let customerPendingChanges: CustomerPendingChangeResource[];
    await axios.get<CustomerPendingChangeResource[]>(`${this.route}/card/requests`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            customerPendingChanges = data;
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return customerPendingChanges;
  }
}
  