import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs
} from '@mui/material';
import {
  AccessModel,
  AccessPermissions,
  CaijCard, 
  DocCollectionModel,
  fetchDocCollection,
  fetchSubscriptions,
  Forbidden, 
  ListType,
  setEditHash,
  setHash,
  SubscriptionModel,
  SUBSCRIPTION_TITLE,
  Unauthorized,
  useLocation,
  useNavigate,
  useParams,
  _docCollection,
  _subscription,
  root
} from 'src/common';
import Page from 'src/components/Page';
import { DocumentTypeProvider } from 'src/contexts/DocumentTypeContext';
import Details from './Details';
import DetailsEditForm from './Forms/DetailsEditForm';
import SubscriptionForm from './Forms/SubscriptionForm';
import SubscriptionsInfo from 'src/components/listInfo';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import AccessInfo from './Details/accessInfo';
import MyCard from 'src/components/card/MyCard';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import SearchEngineModel from 'src/model/coveo/SearchEngine';
import SearchEngineSource from 'src/components/coveo';
import GenericHeader from 'src/components/header/GenericHeader';

interface TabsDocCollection {
  value: string, label: string
}

const tabs: TabsDocCollection[] = [
  { value: 'details', label: 'Détails' },
  { value: 'subscription', label: 'Permissions' },
  { value: 'coveo', label: 'Coveo' }
];

const initialSubscriptionState = {
  subscriptions: [],
  isEmptyList: true,
  isLoading: false,
  isAuthorize: false,
};

const DocCollectionDetailsView: FC = () => {
  const model = DocCollectionModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_docCollection, { docCollection: {}, coveoSources:[], subscriptions: [] });
  const [stateSubscription, dispatchSubscription] = useReducer(_subscription, initialSubscriptionState);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    subscription: false,
  });
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const { id } = useParams();
  
  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsDocCollection) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }

  const getSubscriptions = useCallback(async () : Promise<void> => {
    const model = new SubscriptionModel();
    model.skipHandleError = true;
    const subscriptions = await model.getSubscriptions();
    const { error } = model;
    if (error) {
      if (error.status === Forbidden || error.status === Unauthorized) {
        dispatchSubscription(fetchSubscriptions(subscriptions, true, false, false));
      }else{
        dispatchSubscription(fetchSubscriptions(subscriptions, true, true, true));
      }
    }
    if(!error){
      dispatchSubscription(fetchSubscriptions(subscriptions, true, !Array.isArray(subscriptions) || subscriptions.length === 0, true));
    }
  },[]);

  const getDocCollectionById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)){
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const docCollection = await model.getDocCollectionById(id);
      if (!model.error){
        const coveoSources = await (new SearchEngineModel()).getLists(docCollection.code);
        dispatch(fetchDocCollection(docCollection, coveoSources));
      }
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      handleBrowserRefresh();
      await Promise.all([getSubscriptions(), getDocCollectionById()])
    })();
  }, []);

  useEffect(() => {
    if(access.canRead()){
      const { hash, pathname } = location;
      model.PathName = pathname + hash;
    }
  },[location]);

  const { docCollection, coveoSources } = state;

  if (Object.keys(docCollection).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${docCollection.nameFr} - ${docCollection.code}`;

  const { subscriptions, isAuthorize } = stateSubscription;

  const setEditModeDocCollection = (isEdit: boolean, value?: string) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeDocCollection(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    event.preventDefault();
    setHash(value, navigate);
    setCurrentTab(value);
    setEditModeDocCollection(false, value);
  };

  const handleRefreshDocCollection = async (value: string) : Promise<void> => {
    setHash(value, navigate);
    await Promise.all([getSubscriptions(), getDocCollectionById()]);
    setEditModeDocCollection(false, value);
  };

  const editDocCollection = () => {
    setEditHash('edit', navigate);
    setEditModeDocCollection(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case tabs[0].value:
        if (editMode.details) {
          return (
            <DocumentTypeProvider>
              <DetailsEditForm
                model={model}
                docCollection={docCollection}
                onHandleRefreshDocCollection={() => handleRefreshDocCollection(tabs[0].value)}
                onSwitchMode={(l) => switchMode(l, tabs[0].value)}
              />
            </DocumentTypeProvider>
          );
        }
        return (
          <DocumentTypeProvider>
            <Details model={model} docCollection={docCollection} />
          </DocumentTypeProvider>
        );
      case tabs[1].value:
        if (editMode.subscription) {
          return (
            <SubscriptionProvider>
              <SubscriptionForm
                model={model}
                docCollection={docCollection}
                onHandleRefreshDocCollection={() => handleRefreshDocCollection(tabs[1].value)}
                onSwitchMode={(l) => switchMode(l, tabs[1].value)}
              />
            </SubscriptionProvider>
          );
        }
        return (
            <Grid container spacing={3}>
               <Grid item md={12} xs={12} lg={12}>
                  <MyCard>
                    <AccessInfo collection={docCollection}/>
                  {
                    docCollection.access === AccessPermissions.Private && (
                      <Grid
                        item
                        md={12}
                        xs={12}
                        lg={12}
                      >
                        <Box m={2}>
                          <CaijCard title={SUBSCRIPTION_TITLE}>
                            <SubscriptionsInfo
                              listType={ListType.Subscription}
                              data={subscriptions}
                              lists={docCollection.subscriptions}
                              isAuthorize={isAuthorize}
                            />
                          </CaijCard>
                        </Box>
                      </Grid>
                    )
                  }
                </MyCard>
              </Grid>
            </Grid>
          );
      case tabs[2].value:
        return (
          <Grid container spacing={3}>
             <Grid item md={12} xs={12} lg={12}> 
                <SearchEngineSource 
                  coveoSources={coveoSources} 
                  docCollection={docCollection}
                  onHandleRefresh={() => handleRefreshDocCollection(tabs[2].value)}
                />
            </Grid>
          </Grid>
        )
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={docCollection.nameFr} 
          titleBadge={{label: docCollection.code}} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editDocCollection()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default DocCollectionDetailsView;
