import React, { useContext } from "react";
import { ComponentType } from "react";
import {IMenuContext, MenuContext} from 'src/contexts/MenuContext';

interface IAppState extends IMenuContext{}

export interface PropsWithContext{
  context?: IAppState;
}

export const AppContext = React.createContext<IAppState | undefined>(undefined);

export function withAppContext<OutProps extends PropsWithContext, InProps = Omit<OutProps, "context">>(
  Component: ComponentType<InProps>
){
  return function BoundComponent(props: InProps){
    const ctxMenu = useContext(MenuContext);
    return (
      <AppContext.Consumer>
        {(context: IAppState) => <Component context={{...context,...ctxMenu}} {...props} />}
      </AppContext.Consumer>
    )
  }
}