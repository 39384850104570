import { createRef, useCallback, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import printMessage from 'src/views/errors/MessageError';
import UploadImageModel from 'src/model/UploadImage';
import AddPhotoIcon from '@mui/icons-material/AddPhotoAlternate';
import { ErrorType, AccessModel } from 'src/common';
import { Typography, Button, Box } from '@mui/material';
import { BadRequest, Success } from '../../views/errors/ErrorTypes';
import AppModel from 'src/model/App';
import Dropzone from 'react-dropzone';

interface UploadImageProps {
  imgName: string;
  imgLabel: string;
  maxLength: number;
  accept?: string[];
  maxSize?: number
  resourceCode: string;
  style?: {};
  btnText: string;
  onHandleChangeImageUrl: (imgFieldName: string,imageUrl: string,setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const container = {
  padding: '10px 0',
  borderStyle: 'dotted',
  borderWidth: 'solid',
  textAlign: 'center',
  borderColor: 'rgba(60, 60, 60, 0.5)'
};

enum Code {
  MaxLength,
  Extension
};

const UploadImage: FC<UploadImageProps> = ({
  imgName,
  imgLabel,
  maxLength,
  maxSize,
  accept,
  btnText, 
  style, 
  onHandleChangeImageUrl, 
  setFieldValue, 
  resourceCode,
  ...rest
}) => {
  const dropzoneRef = createRef<any>();
  const access = new AccessModel(resourceCode);
  const model = new UploadImageModel();
  const [error,setError] = useState<{code: Code, message: string}>(null);
  const extensions = {'.jpeg':'image/jpeg','.gif':'image/gif','.png' : 'image/png','.svg': 'image/svg+xml'};

  const printMessageError = () : JSX.Element => {
    if(error){
      return (
        <Typography sx={{ color: 'text.error', fontSize: '12px', marginTop: 1}}>
          { error.code === Code.MaxLength ? AppModel.formatError(ErrorType.max, imgLabel, +error.message) : AppModel.formatError(ErrorType.extension, error.message) }
        </Typography>
      )
    }
    return null;
  };

  function validator(file: File) {
    let error = {code: null,message: ""};
    if (file.name.length > maxLength) {
      error = {
        code: Code.MaxLength,
        message: maxLength.toString()
      }
      setError(error);
      return error;
    }else if(!Object.values(extensions).includes(file.type)){
      error = {
        code: Code.Extension,
        message: 'Fichier non autorisé'
      }
      setError(error);
      return error;
    }
    return null
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => printMessage({ status: BadRequest, message: 'la lecture du fichier a été abandonnée' })
      reader.onerror = () => printMessage({ status: BadRequest, message: 'la lecture du fichier a échoué' })
      reader.onload = async () => {
        const mess = `${imgLabel.charAt(0).toUpperCase() + imgLabel.slice(1)} ${file.name}`;
        const fileType = file.type;
        const fileName = `${uuidv4()}.${fileType.split('/').pop()}`;
        const formData = new FormData();
        formData.append('file', file, fileName);
        let image = await model.uploadImage(formData);
        if (!image) {
          printMessage({message: `${mess} n'a pas pu être sauvegardée` });
        } else {
          onHandleChangeImageUrl(imgName, image, setFieldValue);
          printMessage({ status: Success, message: `${mess} a été sauvegardé avec succès` });
          setError(null);
        }
      }
      reader.readAsArrayBuffer(file);
    })
  }, [])
  
  return (
    <Dropzone 
      {...rest}
      accept={accept || Object.values(extensions)} 
      ref={dropzoneRef} 
      maxFiles={1}
      validator={validator}
      onDrop={onDrop}
      noClick
    >
        {({getRootProps, getInputProps}) => {
        return (
          <Box sx={container}>
            <Box {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Glisser-déposer le fichier ici</p>
              <p style={{marginBottom: '10px'}}>{`Fichiers autorisés : ${Object.keys(extensions).join(',')}`}</p>
              <Button 
                disabled={(!access.UserAdmin && (!access.Gestion && !access.Edition))} 
                style={{...style, alignSelf: 'center'}} 
                variant='contained' 
                color='primary' 
                startIcon={<AddPhotoIcon />} 
                onClick={() => {
                  if (dropzoneRef.current) {
                    dropzoneRef.current.open()
                  }
                }}
              >
                { btnText }
              </Button>
            </Box>
            {printMessageError()}
          </Box>
        );
      }}
    </Dropzone>
  )
};

UploadImage.propTypes = {
  imgName: PropTypes.string.isRequired,
  resourceCode: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  style: PropTypes.object,
  onHandleChangeImageUrl: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default UploadImage;

