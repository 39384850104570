import React, { useEffect, useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import {
  Box,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  getEmptyListSearch,
  formatDateTime,
  CaijInput,
  useNavigate,
  SearchEngineModel,
  FIRST_PAGE,
  ListType
} from 'src/common';
import type { 
  CoveoTaskHistoryDto,  
  SortOption, 
  Sort, 
  EmployeesResource, 
  IListFilter, 
  SearchFilter
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableHeaderFilter from 'src/components/table/CaijTableHeaderFilter';
import { Paged } from 'src/types/pagination';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { getTask } from 'src/components/coveo';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';

const sortOptions: SortOption[] = [
  {
    value: 'desc',
    label: 'Le plus récent',
  },
  {
    value: 'asc',
    label: 'Le plus ancien',
  }
];

export interface ResultsProps {
  model: SearchEngineModel;
  coveoTaskHistories: CoveoTaskHistoryDto[];
  employees: EmployeesResource[];
  isEmptyList: boolean;
  paged: Paged;
}

const Results: FC<ResultsProps> = ({
  model,
  coveoTaskHistories,
  employees,
  isEmptyList,
  paged
}) => {
  const navigate = useNavigate();
  let currentPage = paged.currentPage - 1;
  const [filter, setFilter] = useState<SearchFilter>({
    page: currentPage,
    sort: sortOptions[0].value,
    coveoSourceId: null
  });
  const headCellFilter = model.getCoveoTaskHistoryHeadCells();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const { Filters: { page, collection, coveoIdentifier, startDate, endDate, email, name, status, task } } = model;
    let nameVal = '', pageVal = FIRST_PAGE, collectionVal = '', startDateVal = '', endDateVal = '', emailVal = '', coveoIdentifierVal = '', taskVal = '', statusVal = '';
    for (const [key, value] of urlParams.entries()) {
      switch(key){
        case page:
          pageVal = +value;
          break;
        case name:
          nameVal = value;
          break;
        case collection:
          collectionVal = value;
          break;
        case coveoIdentifier:
          coveoIdentifierVal = value;
          break;
        case status:
          statusVal = value;
          break;
        case task:
          taskVal = value;
          break; 
        case startDate:
          startDateVal = value;
          break;
        case endDate:
          endDateVal = value;
          break;
        case email:
          emailVal = value;
          break;
      }
    }
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        page: +pageVal,
        name: nameVal,
        collection: collectionVal,
        coveoIdentifier: coveoIdentifierVal,
        status: statusVal,
        task: taskVal,
        startDate: startDateVal,
        enddate: endDateVal,
        email: emailVal
      }
    });
  },[location.search]);
  
  const handleSortChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sort = event.target.value as Sort;
    model.getConfigParameters(filter,8,sort);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        sort: sort as Sort || 'asc',
      }
    });
    navigate(model.getUrlEncode.apply(model,model.getParams()));
  };
  
  const getFilterList = () => {
    let list: IListFilter = [];
    employees.forEach(({fullname, email}) => {
      list.push({ name: fullname, value: email })
    });
    return list;
  }

  const renderContent = (): JSX.Element => {
    return (
      <MyCard>
        <Box sx={{display: 'flex', marginTop:'7px',alignItems:'center',justifyContent:'right'}} >
          <CaijResetFilterButton path={model.CoveoTaskHistoryHome} navigate={navigate} sx={{marginRight: '10px'}}/>
          <CaijInput
            label='Trier par'
            name='Sort'
            onHandleChange={handleSortChange}
            select
            value={filter.sort}
            sx={{margin: '1.2em 1em 0 0'}}
            InputLabelProps={{ shrink: true }}
          >
            {sortOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </CaijInput>
        </Box>
        <CaijTable>
          <TableHead>
            <TableRow>
             { headCellFilter.map(({colName, width, type, property}, index: number, headers) => (
                  <CaijTableHeaderFilter
                    listType={ListType.CoveoTaskHistory}  
                    headers={headers}
                    colName={colName} 
                    width={width} 
                    type={type} 
                    filter={filter} 
                    key={index}
                    property={property}
                    lists={getFilterList()}
                  />
                ))
            }
            </TableRow>
          </TableHead>
          <TableBody>
            { isEmptyList ? getEmptyListSearch(headCellFilter.length) :
              coveoTaskHistories.map((row: CoveoTaskHistoryDto) => (
                          <TableRow hover tabIndex={-1} key={row.id}>
                            <CaijTableCell>{row.coveoSource.name}</CaijTableCell>
                            <CaijTableCell>{row.coveoSource.collection}</CaijTableCell>
                            <CaijTableCell>{row.coveoSource.coveoIdentifier}</CaijTableCell>
                            <CaijTableCell>{formatDateTime(row.started)}</CaijTableCell>
                            <CaijTableCell>{row.status}</CaijTableCell>
                            <CaijTableCell>{row.task && getTask(row)}</CaijTableCell>
                            <CaijTableCell>{employees.find(({email}) => email === row.modifiedBy)?.fullname}</CaijTableCell>
                          </TableRow>
                        ))
            }
          </TableBody>
        </CaijTable>
        <CaijTablePagination 
          paged={paged} 
          model={model} 
          filter={filter} 
          page={currentPage}
          navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
        />
      </MyCard>
    );
  };

  return (
    <>
      { renderContent() }
    </>
  );
};

export default Results;
