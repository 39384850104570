import { useEffect, useReducer } from "react";
import { _partners, fetchPartners, Forbidden, PartnersModel, Unauthorized } from "src/common";
import { FETCH_PARTNERS } from "src/store/actions/ActionTypes";

export default function usePartners() {
  const initialState = {
    isEmptyList: true,
    isLoading: true,
    partners: [],
  };
  const [state, dispatch] = useReducer(_partners, initialState);
  useEffect(() => {
    const initialise = async () => {
      const model = new PartnersModel();
      model.skipHandleError = true;
      const response = model.getPartners();
      const partners = await response;
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchPartners(partners, false, true, false));
        }else{
          dispatch(fetchPartners(partners, false, true, true));
        }
      } else {
        dispatch(fetchPartners(partners, false, !Array.isArray(partners) || partners.length === 0, true));
      }
    }
    (async () => await initialise())();
    return () => { dispatch({type: FETCH_PARTNERS, payload: initialState});  }
  },[]);
  const { partners, isEmptyList, isLoading, isAuthorize } = state;
  return { 
    partners,
    allChecked: !isEmptyList && !isLoading && isAuthorize
  };
};