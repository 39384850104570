import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TableBody,
} from '@mui/material';
import {
  EmptyList,
  ListType,
  LawDomainFieldModel,
  PaginationModel,
  NavigateFunction,
  EnhancedTableHead,
  getEmptyListSearch
} from 'src/common';
import type { LawDomainFieldResource, SearchFilter, Sort } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import Search from 'src/components/search';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijDialogs from 'src/components/dialog';
import { Paged } from 'src/types/pagination';
import CaijTable from 'src/components/table/CaijTable';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import { Root } from 'src/components/styled';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  parentId?: number;
  paged: Paged;
  lawDomainFields: LawDomainFieldResource[];
  allowedChildren?: boolean;
  isEmptyList: boolean;
  model: LawDomainFieldModel;
  navigate: NavigateFunction;
  location: {search: string}
  onDeleteLawDomainField: (value: number) => Promise<void>;
}

const Results: FC<ResultsProps> = ({
  parentId,
  lawDomainFields,
  isEmptyList,
  allowedChildren = false,
  model,
  paged,
  navigate,
  location,
  onDeleteLawDomainField
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState<number>(0);
  const query = new URLSearchParams(location?.search).get("query");
  const [filter, setFilter] = useState<SearchFilter>({query, doSearch: false, page, sort: null, sortedBy: ''});
  const headCell = model.getHeadCells();
  const { order, orderBy, handleRequestSort, setOrderBy } = useSort();
  
  useEffect(() => setOrderBy('nameFr'),[]);
  
  useEffect(() => {
    const { Filters: { query, page, sort, sortedBy } } = model;
    const queryVal = model.getQueryParam(query);
    const pageVal = +model.getQueryParam(page);
    const sortVal = model.getQueryParam(sort);
    const sortedByVal =  model.getQueryParam(sortedBy);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        doSearch: queryVal ? true : false,
        page: pageVal,
        sort: sortVal as Sort,
        sortedBy: sortedByVal
      }
    });
    setPage(pageVal);
  },[model]);
  
  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      <Box p={2} minHeight={56}>
        <Search 
          listType={ListType.LawDomainField}
          placeholder='Recherche la/les domaine(s) de droit' 
          filter={filter}
          width="400px"
          path={!allowedChildren ? path.Home : `${model.LawDomainChildHome}/${parentId}`}
        />
        { allowedChildren && (
          <Box sx={{minWidth: '217px', height: "56"}}>
            <Button onClick={() => { navigate(model.Path.Home);
              }}
            >
              VOIR LISTE DES PARENTS
            </Button>
          </Box>
          )
        }
      </Box>
      <Root>
        <CaijTable>
          <EnhancedTableHead 
            headCells={headCell} 
            order={order}
            orderBy={orderBy}
            onRequestSort={(e,p) => handleRequestSort<LawDomainFieldModel>(e,p,model,filter,navigate)}
          />
          <TableBody>
            { lawDomainFields.length === 0 ? getEmptyListSearch(headCell.length) :
              lawDomainFields.map((row: LawDomainFieldResource) => (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  <CaijTableCell>{row.nameFr}</CaijTableCell>
                  <CaijTableCell>{row.nameEn}</CaijTableCell>
                  <CaijTableCellAction 
                    lawDomainFieldChildUrl={!allowedChildren ? `${model.LawDomainChildHome}/${row.id}` : ''}
                    pageEditUrl={path.getEdit(row.id)}
                    resourceCode={model.ResourceCode}
                    handleToggle={() => LawDomainFieldModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                  >
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(row.id) !== -1}
                      onSubmit={async () => {
                        await onDeleteLawDomainField(row.id)
                        setSelectedRow([]);
                      }}
                      setSelectedRow={setSelectedRow}
                    />
                  </CaijTableCellAction>
                </CaijTableRowClickable>
              ))
            }
          </TableBody>
        </CaijTable>
        <CaijTablePagination 
          paged={paged} 
          model={model} 
          filter={filter} 
          page={page}
          navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
        />
      </Root>
    </MyCard>
  );
};

Results.propTypes = {
  lawDomainFields: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  model: PropTypes.instanceOf(LawDomainFieldModel)
};

export default Results;
