
import { TribunalTypeModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: TribunalTypeModel) => {
  const { LabelFr, LabelEn } = model;
  return Yup.object().shape({
    labelFr: LabelFr.required(true),
    labelEn: LabelEn.required(true)
  });
};
export default validateSchema;