import { CustomerModel } from 'src/common';
import type {
  SubscriptionResource,
  CustomerResource,
  CustomerAddressDto,
  EndorsedCustomerResource,
  InvoiceInfo,
  CustomerCardTransactionsResource,
  StatsDto,
  DocumentOrderedDto,
  ReperageResource,
  EmailStatusDto
} from 'src/common/types';
import { Paged } from 'src/types/pagination';
import * as actionTypes from '../ActionTypes';

export const fetchCustomers = (
  customers: CustomerResource[],
  paged?: Paged,
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
  model?: CustomerModel
): {
  type: 'FETCH_CUSTOMERS';
  payload: {
    customers: CustomerResource[];
    paged?: Paged;
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
    model?: CustomerModel;
  };
} => ({
  type: actionTypes.FETCH_CUSTOMERS,
  payload: {
    customers,
    paged,
    isEmptyList,
    isLoading,
    isAuthorize,
    model
  },
});

export const fetchCustomersByEmail = (
  customers: CustomerResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
): {
  type: 'FETCH_CUSTOMERS_BY_EMAIL';
  payload: { customers: CustomerResource[]; isLoading?: boolean; isEmptyList?: boolean };
} => ({
  type: actionTypes.FETCH_CUSTOMERS_BY_EMAIL,
  payload: {
    customers,
    isLoading,
    isEmptyList,
  },
});

export const fetchCustomer = (
  customer: CustomerResource,
  subscriptions?: SubscriptionResource[],
): {
  type: 'FETCH_CUSTOMER';
  payload: {
    customer: CustomerResource;
    subscriptions?: SubscriptionResource[];
  };
} => ({
  type: actionTypes.FETCH_CUSTOMER,
  payload: {
    customer,
    subscriptions,
  },
});

export const fetchCustomerAddresses = (
  customerAddresses: CustomerAddressDto[],
  isLoading?: boolean,
  isEmptyList?: boolean,
): {
  type: 'FETCH_CUSTOMER_ADDRESSES';
  payload: {
    customerAddresses: CustomerAddressDto[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
} => ({
  type: actionTypes.FETCH_CUSTOMER_ADDRESSES,
  payload: {
    customerAddresses,
    isEmptyList,
    isLoading,
  },
});

export const fetchCustomersEndorsed = (
  customersEndorsed: EndorsedCustomerResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
): {
  type: 'FETCH_CUSTOMERS_ENDORSED';
  payload: { customersEndorsed: EndorsedCustomerResource[]; isLoading?: boolean; isEmptyList?: boolean };
} => ({
  type: actionTypes.FETCH_CUSTOMERS_ENDORSED,
  payload: {
    customersEndorsed,
    isLoading,
    isEmptyList,
  },
});

export const fetchCustomerEndorsed = (
  customerEndorsed: EndorsedCustomerResource,
  isLoading?: boolean,
  isEmptyList?: boolean,
): {
  type: 'FETCH_CUSTOMER_ENDORSED';
  payload: { customerEndorsed: EndorsedCustomerResource; isLoading?: boolean; isEmptyList?: boolean };
} => ({
  type: actionTypes.FETCH_CUSTOMER_ENDORSED,
  payload: {
    customerEndorsed,
    isLoading,
    isEmptyList,
  },
});

export const fetchResetPassword = (password: string): { type: 'FETCH_RESET_PASSWORD'; payload: { password: string }; } => ({
  type: actionTypes.FETCH_RESET_PASSWORD,
  payload: {
    password
  },
});

export const fetchCustomerCardTransactionsById = (
  customerCardTransaction: CustomerCardTransactionsResource,
  isLoading?: boolean
): {
  type: 'FETCH_CUSTOMER_CARD_TRANSACTION_BY_ID';
  payload: { customerCardTransaction: CustomerCardTransactionsResource, isLoading?: boolean };
} => ({
  type: actionTypes.FETCH_CUSTOMER_CARD_TRANSACTION_BY_ID,
  payload: {
    customerCardTransaction,
    isLoading
  },
});

export const fetchCustomerInvoicesById = (
  customerInvoices: InvoiceInfo[],
  customerOrders: DocumentOrderedDto[],
  isLoading?: boolean,
  isEmptyList?: boolean
): {
  type: 'FETCH_CUSTOMER_INVOICES_BY_ID';
  payload: { customerInvoices: InvoiceInfo[], customerOrders: DocumentOrderedDto[], isLoading?: boolean, isEmptyList?: boolean };
} => ({
  type: actionTypes.FETCH_CUSTOMER_INVOICES_BY_ID,
  payload: {
    customerInvoices,
    customerOrders,
    isLoading,
    isEmptyList
  },
});

export const fetchJugesAClasser = (
  jugesAClasser: CustomerResource[],
  isEmptyList?: boolean,
  isLoading?: boolean
): {
  type: 'FETCH_JUGES_A_CLASSER';
  payload: {
    jugesAClasser: CustomerResource[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
} => ({
  type: actionTypes.FETCH_JUGES_A_CLASSER,
  payload: {
    jugesAClasser,
    isEmptyList,
    isLoading
  },
});

export const fetchPendingApproval = (
  pendingApprovals: CustomerResource[],
  isEmptyList?: boolean,
  isLoading?: boolean
): {
  type: 'FETCH_PENDING_APPROVAL';
  payload: {
    pendingApprovals: CustomerResource[];
    isEmptyList?: boolean;
    isLoading?: boolean;
  };
} => ({
  type: actionTypes.FETCH_PENDING_APPROVAL,
  payload: {
    pendingApprovals,
    isEmptyList,
    isLoading
  },
});

// #region Statistic
export const fetchCustomerStats = (counter: number, title: string, isLoading?: boolean): {
  type: 'FETCH_CUSTOMER_STATS',
  payload: {
    counter: number;
    title: string;
    isLoading?: boolean;
  }
} => ({
  type: actionTypes.FETCH_CUSTOMER_STATS,
  payload: {
    counter,
    title,
    isLoading
  }
});

export const fetchActiveCustomerBySubscription = (data: StatsDto[], isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_ACTIVE_CUSTOMER_BY_SUBSCRIPTION',
  payload: {
    data: StatsDto[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  }
} => ({
  type: actionTypes.FETCH_ACTIVE_CUSTOMER_BY_SUBSCRIPTION,
  payload: {
    data,
    isLoading,
    isEmptyList
  }
});

export const fetchCustomerReperages = (customerReperages: ReperageResource[], isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_CUSTOMER_REPERAGES',
  payload: {
    customerReperages: ReperageResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  }
} => ({
  type: actionTypes.FETCH_CUSTOMER_REPERAGES,
  payload: {
    customerReperages,
    isLoading,
    isEmptyList
  }
})
// #endregion

export const fetchEmailStatus = (emailStatus: EmailStatusDto[], isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_EMAIL_STATUS',
  payload: {
    emailStatus: EmailStatusDto[]
    isLoading?: boolean;
    isEmptyList?: boolean;
  }
} => ({
  type: actionTypes.FETCH_EMAIL_STATUS,
  payload: {
    emailStatus,
    isLoading,
    isEmptyList
  }
});


