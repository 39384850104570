import type { TribunalCodesResource, TribunalResource} from 'src/common/types';
import * as actionTypes from '../../../actions/ActionTypes';
import { TribunalModel } from 'src/common';
import { Paged } from 'src/types/pagination';

type FetchTribunauxAction = {
    type: 'FETCH_TRIBUNAUX';
    payload: {
      tribunaux: TribunalResource[];
      paged: Paged;
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
      model?: TribunalModel;
    }
}

type FetchTribunalsAction = {
  type: 'FETCH_TRIBUNALS';
  payload: {
    tribunaux: TribunalResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  }
}

type FetchTribunalAction = {
  type: 'FETCH_TRIBUNAL';
  payload: {
    tribunal: TribunalResource;
    isLoading?: boolean;
  }
};

type DeleteTribunalAction = {
  type: 'DELETE_TRIBUNAL';
  payload: {
    id: number;
  }
};

type FetchTribunalCodesAction = {
  type: 'FETCH_TRIBUNAL_CODES';
  payload: {
    tribunalCodes: { [key: string]: number };
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

type FetchTribunalCodesNotAssignedAction = {
  type: 'FETCH_TRIBUNAL_CODES_NOT_ASSIGNED';
  payload: {
    tribunalCodesNotAssigned: TribunalCodesResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

export type TribunalAction = FetchTribunauxAction |
                             FetchTribunalsAction |
                             FetchTribunalAction |
                             FetchTribunalCodesAction |
                             FetchTribunalCodesNotAssignedAction |
                             DeleteTribunalAction;

interface TribunalReturnState {
  tribunaux?: TribunalResource[];
  tribunalCodesNotAssigned?: TribunalCodesResource[];
  tribunal?: TribunalResource;
  paged?: Paged;
  tribunalCodes?: { [key: string]: number };
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  model?: TribunalModel;
}

interface TribunalState {
  tribunaux: TribunalResource[];
  tribunalCodesNotAssigned: TribunalCodesResource[];
  tribunal: TribunalResource;
  paged: Paged;
  tribunalCodes: { [key: string]: number };
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  model: TribunalModel;
}

const reducer = (state: TribunalState, action: TribunalAction): TribunalReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_TRIBUNAUX: {
      const { tribunaux, paged, isEmptyList, isLoading, isAuthorize, model } = action.payload;
      return {
        ...state,
        tribunaux,
        paged,
        isLoading,
        isEmptyList,
        isAuthorize,
        model
      };
    }
    case actionTypes.FETCH_TRIBUNALS: {
      const { tribunaux, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        tribunaux,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_TRIBUNAL_CODES_NOT_ASSIGNED: {
      const { tribunalCodesNotAssigned, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        tribunalCodesNotAssigned,
        isLoading,
        isEmptyList
      };
    }
    case actionTypes.FETCH_TRIBUNAL: {
      const { tribunal, isLoading } = action.payload;
      return {
        ...state,
        tribunal,
        isLoading
      };
    }
    case actionTypes.DELETE_TRIBUNAL: {
      return {
        ...state,
        tribunaux: [...state.tribunaux].filter(({id}) => id !== action.payload.id)
      };
    }
    case actionTypes.FETCH_TRIBUNAL_CODES: {
      const { tribunalCodes } = action.payload;
      return {
        ...state,
        tribunalCodes
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;



