import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  TribunalTypeResource,
  TribunalTypeResourceForEdit
} from 'src/common/types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  TribunalTypeModel,
  Mode,
  btnSubmit
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import TribunalTypeForm from 'src/components/content/caseLaw/tribunalType/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: TribunalTypeModel;
  tribunalType: TribunalTypeResource;
  onHandleRefreshTribunalType: (value: TribunalTypeResource) => void;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values:TribunalTypeResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  tribunalType,
  onHandleRefreshTribunalType,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: TribunalTypeResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new TribunalTypeModel();
    const submitData = values as TribunalTypeResourceForEdit;
    const tribunalTypeId = submitData.id;
    if (submitData && tribunalTypeId) {
      const response = await model.updateTribunalType(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        onHandleRefreshTribunalType(await model.getRefreshTribunalType(tribunalTypeId.toString()));
      } 
    }
  };

  return (
    <Formik
      initialValues={TribunalTypeModel.getInitialValues(tribunalType)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                <TribunalTypeForm 
                   model={model}
                   tribunalType={values}
                   errors={errors}
                   touched={touched}
                   handleBlur={handleBlur}
                   onHandleChange={handleChange}
                   setFieldValue={setFieldValue}
                />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  tribunalType: PropTypes.object.isRequired,
  onHandleRefreshTribunalType: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
