import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  LawModel,
  _law,
  fetchLaw,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import LawEditForm from './LawEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import { LawDomainFieldProvider } from 'src/contexts/LawDomainFieldContext';

const LawEditView: FC = () => {
  const model = LawModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_law, { isLoading: false, law: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new LawModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const law = await model.getLawById(id);
          if (!model.error) {
            dispatch(fetchLaw(law, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchLaw({lawDomain: []}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { law, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = law.titleFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.Title} />
        <Box mt={3}>
          <LawDomainFieldProvider>
            <LawEditForm model={model} law={law} />
          </LawDomainFieldProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default LawEditView;
