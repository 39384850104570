import type { FC } from 'react';
import { Box, TablePagination } from '@mui/material';
import { ITEMS_PER_PAGE, scrollDashBoardContentToTop } from 'src/common';
import { Paged } from 'src/types/pagination';
import AppModel from 'src/model/App';
import { TablePaginationActions } from './TablePaginationActions';
import { SearchFilter } from 'src/common/types';

export interface CaijTablePaginationProps {
  paged?: Paged;
  filter?: SearchFilter;
  page: number;
  model?: AppModel;
  len?: number
  navigate?: (model: AppModel) => void;
  showTablePaginationLight?: boolean;
  setPage?: (value:number) => void;
}

const CaijTablePagination : FC<CaijTablePaginationProps> = ({ 
  model, 
  navigate, 
  paged, 
  filter, 
  page,
  len,
  showTablePaginationLight,
  setPage,
}) => {

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    model.getConfigParameters(filter,0,newPage);
    scrollDashBoardContentToTop();
    navigate(model);
  };

  const handleChangePageLight = (event: any, newPage: number) => {
    scrollDashBoardContentToTop();
    setPage(newPage);
  };

  return (
    <Box mr={2}>
      { showTablePaginationLight ? (
          <TablePagination
            rowsPerPageOptions={[]}
            component='div'
            count={len}
            rowsPerPage={ITEMS_PER_PAGE}
            page={page}
            onPageChange={handleChangePageLight}
            ActionsComponent={TablePaginationActions}
          />
        ) : (
          <TablePagination
            rowsPerPageOptions={[]}
            component='div'
            count={paged.totalCount}
            rowsPerPage={ITEMS_PER_PAGE}
            page={page}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        )
      }
    </Box>
  );
};

export default CaijTablePagination;