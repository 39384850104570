import type { CustomerHistoryResource } from 'src/common/types';
import { Paged } from 'src/types/pagination';
import * as actionTypes from '../ActionTypes';

export const fetchCustomerHistoryList = (
  customerHistory: CustomerHistoryResource[],
  paged?: Paged,
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
): {
  type: 'FETCH_CUSTOMER_HISTORY_LIST';
  payload: {
    customerHistory: CustomerHistoryResource[];
    paged?: Paged;
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
  };
} => ({
  type: actionTypes.FETCH_CUSTOMER_HISTORY_LIST,
  payload: {
    customerHistory,
    paged,
    isEmptyList,
    isLoading,
    isAuthorize
  },
});
