import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC } from 'react';
import type { Theme } from 'src/common/types';
import { Box, Container, Grid, Typography } from '@mui/material';
import {
  useParams,
  useLocation,
  fetchMessage,
  _message,
  MessageModel,
  AccessModel,
  MessagePlaceholderModel,
  root,
  useNavigate,
  fetchMessagePlaceholder,
  _messagePlaceholder,
} from 'src/common';
import Page from 'src/components/Page';
import MessageEditForm from '../Forms/MessageEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { container } from '../../../../styles/message';
import MyCard from 'src/components/card/MyCard';
import GenericHeader from 'src/components/header/GenericHeader';
import MessageCreateForm from '../Forms/MessageCreateForm';


const big = {
  fontSize: '1.7em',
};
const grayed = {
  color: 'gray',
};
const padding = {
  padding: '1em',
};

const initialState = {
  placeholder: {},
};

const MessagesEditView: FC = () => {
  const model = MessageModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_messagePlaceholder, initialState);
  const { id } = useParams();
  const navigate = useNavigate();
  
  const getMessagePlaceholderById = useCallback(async () : Promise<void> => {
    const model = new MessagePlaceholderModel();
    if(id && isNaN(+id)){
      navigate(model.Path.Home);
    }else if(access.canEdit(id)){
      model.PathName = location.pathname;
      const placeholder = await model.getMessagePlaceholderById(Number(id));
      if (!model.error) {
        dispatch(fetchMessagePlaceholder(placeholder));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getMessagePlaceholderById();
    })();
  },[]);

  const { placeholder } = state;

  if(Object.keys(placeholder).length === 0) return;

  return (
    <Page sx={root} title="Créer un nouveau message">
      <Container maxWidth={false}>
        <GenericHeader 
          model={model} 
          headerTitle={model.Title} 
          message={{ id: placeholder.id, label: placeholder.name }} 
        />
        <Box mt={3}>
          <Grid spacing={2} container>
            <Grid xs={9} item>
              <MyCard>
                <Box sx={{...container}}>
                  <Box width='100%' mr={2}>
                    <Box sx={padding}>
                      <Box>
                        <Typography sx={grayed}>Nom de l'emplacement de ce message</Typography>
                      </Box>
                      <Box>
                        <Typography sx={big}>{placeholder.name}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </MyCard>
            </Grid>
          </Grid>
        </Box>
        <Box mt={3}>
          <MessageCreateForm holder={placeholder} navigate={navigate}/>
        </Box>
      </Container>
    </Page>
  );
};

export default MessagesEditView;
