import { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {Box,CardContent, Typography, alpha} from '@mui/material';
import {
  Formik,
  handleChange,
  labelConfig,
  CaijInput,
  CaijButtonSubmit,
  CaijButtonReset,
  CustomerModel,
  btnSubmit,
  CaijInputEmail,
  CaijDatePicker,
  AccessModel,
  CustomerStatus,
  GroupType,
  INACTIF,
  INACTIF_COLOR,
  MSG_SEND_EMAIL_MEMBER
} from 'src/common';
import type {
  EndorsedCustomerResource,
  CustomerResource,
  CustomerResourceForEdit,
  SubscriptionGroupResource,
} from 'src/common/types';
import printMessage from 'src/views/errors/MessageError';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';
import useSubscription from 'src/hooks/useSubscription';
import CaijInputDefaultValue from 'src/components/inputs/CaijInputDefaultValue';
import { validateCreateEndorsedSchema } from '../../../Schema';

export interface CustomerEndorsedEditFormProps {
  model: CustomerModel;
  customerEndorsed: EndorsedCustomerResource;
  totalEndorsed?: number;
  customer: CustomerResource;
  limitEndorsed?: number;
  onHandleRefreshCustomer?: () => Promise<void>;
  onSwitchMode?: (isEdit: boolean) => void;
  onSubmit?: (values: EndorsedCustomerResource) => void;
}

function getGrpEndorsement(groups: SubscriptionGroupResource[]){
  if(groups)
    return groups?.find(({ groupType }) => groupType === GroupType.Endorsed);
};

const CustomerEndorsedEditForm: FC<CustomerEndorsedEditFormProps> = ({
  model,
  customerEndorsed,
  totalEndorsed,
  customer,
  limitEndorsed,
  onHandleRefreshCustomer,
  onSwitchMode,
  onSubmit
}) => {
  const access = new AccessModel(model.ResourceCode);
  const { isEmptyList, subscriptions, getGroups, groups, getProductSubscription, productSubscription} = useSubscription();
  const { MemberEmail, MemberFirstname, MemberLastname, MemberExpiration } = model;

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      const { subscriptionId } = customer;
      await Promise.all([getGroups(subscriptionId), getProductSubscription(subscriptionId)]);
    };
    (async () => {
      await initialise();
    })();
  },[customer]);

  const handleSubmit = async (values: EndorsedCustomerResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const { id, subscriptionId, wfLibrary } = customer;
    let submitData = values as CustomerResourceForEdit;
    const { id: subscriptionGroupId, wfProfile } = getGrpEndorsement(groups);
    if (id && subscriptionId && subscriptionGroupId && limitEndorsed > 0 && totalEndorsed < limitEndorsed){ 
      submitData.endorsedBy = id;
      submitData.subscriptionId = subscriptionId;
      submitData.status = CustomerModel.getStatusByVal(CustomerStatus.ActiveOnLogin);
      submitData.subscriptionGroupId = subscriptionGroupId;
      submitData.wfLibrary = wfLibrary;
      submitData.wfProfile = wfProfile || '';
      const response = await model.insertCustomer(submitData);
      if (!model.error) {
        const { status, message } = response;
        model.printEmailMessage(status, MSG_SEND_EMAIL_MEMBER + 'pour faire l\'activation de son compte');
        printMessage({status, message});
        await onHandleRefreshCustomer();
      }
    }
  }
  
  return (
    <Formik
      initialValues={{...customerEndorsed}}
      validationSchema={validateCreateEndorsedSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
                <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{mb:'10px'}}>
                  <Root>
                    <Typography variant="h4" sx={{marginBottom:'1em'}}>Ajouter un cautionné</Typography>
                    <CaijInput
                      name='name'
                      disabled
                      value={customer.fullname}
                      label={labelConfig.endorsed}
                      inputAttr={{'data-testid' : 'name'}}
                    />
                    <CaijInput
                      name={MemberFirstname.Name}
                      id={MemberFirstname.Name}
                      required
                      label={MemberFirstname.Label}
                      value={values.firstname}
                      error={Boolean(touched.firstname && errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputAttr={{maxLength: MemberFirstname.MaxLength, 'data-testid':'firstname', autoComplete: 'none'}}
                      onHandleBlur={handleBlur}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    <CaijInput
                      name={MemberLastname.Name}
                      id={MemberLastname.Name}
                      required
                      label={MemberLastname.Label}
                      value={values.lastname}
                      error={Boolean(touched.lastname && errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputAttr={{maxLength: MemberLastname.MaxLength, 'data-testid': 'lastname'}}
                      onHandleBlur={handleBlur}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    <CaijInputEmail
                      name={MemberEmail.Name}
                      id={MemberEmail.Name}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      label={MemberEmail.Label}
                      required
                      InputLabelProps={{ shrink: true, required: true }}
                      inputProps={{ maxLength: MemberEmail.MaxLength, 'data-testid': 'email' }}
                      onHandleBlur={handleBlur}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    <Box sx={{mt:2}}>
                      <CaijDatePicker 
                        name={MemberExpiration.Name}
                        required
                        value={String(values.expiration)}
                        label={MemberExpiration.Label}
                        inputProps={{"data-testid": MemberExpiration.Name}}
                        helperText={touched.expiration && errors.expiration}
                        onHandleChange={(l) => setFieldValue(MemberExpiration.Name,l)}
                      />
                    </Box>
                    <CaijInput
                      label={labelConfig.subscription}
                      id='subscriptionId'
                      name='subscriptionId'
                      required
                      disabled={true}
                      select
                      value={customer.subscriptionId || ''}
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      inputAttr={{ 'aria-label': 'subscriptionId', 'data-testid': 'subscriptionId' }}
                    >
                      <option value=''>Sélectionner un abonnement</option>
                      {!isEmptyList && subscriptions.map(({id, name, active}) => (
                        <option value={id} key={id} style={{color: !active ? INACTIF_COLOR : ''}}>
                          {name} {!active ? ` - ${INACTIF}` : ''}
                        </option>
                      ))}
                    </CaijInput>
                    { /*
                      groups && (
                        <TextField
                          label={labelConfig.groups}
                          id='subscriptionGroupId'
                          name='subscriptionGroupId'
                          disabled={true}
                          select
                          SelectProps={{ native: true }}
                          value={customer?.subscriptionGroup?.id || ''}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ 'aria-label': 'subscriptionGroupId', 'data-testid': 'subscriptionGroupId' }}
                          sx={{ marginTop: 2, mb: 3, width: '100%' }}
                        >
                          <option value="">Sélectionner un groupe</option>
                          {
                            groups.map((group: SubscriptionGroupResource) => (
                                  <option value={group.id} key={group.id}>
                                    {group.name}
                                  </option> ))
                          }
                        </TextField>
                      )*/
                    }
                    {
                      (productSubscription.subscriptionId == customer.subscriptionId && 
                        productSubscription.groups.includes(customer?.subscriptionGroup?.id)) && (
                          <>
                            <CaijInputDefaultValue
                              label={labelConfig.wfLibrary} 
                              disabled={true}
                              name="wfLibrary"
                              type="text"
                              defaultValue={customer?.wfLibrary}
                              sx={{mb:2}}
                            />
                            <CaijInputDefaultValue
                              label={labelConfig.wfProfile} 
                              disabled={true}
                              name="wfProfile"
                              type="text"
                              defaultValue={getGrpEndorsement(groups)?.wfProfile}
                            />
                          </>
                        )
                    }
                  </Root>
                </Box>
                <CaijButtonSubmit disabled={access.Disabled || isSubmitting || limitEndorsed === 0 || totalEndorsed >= limitEndorsed} sx={btnSubmit} />
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={(l) => onSwitchMode(l)} />
              </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

CustomerEndorsedEditForm.propTypes = {
  limitEndorsed: PropTypes.number,
  totalEndorsed: PropTypes.number,
  customerEndorsed: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func,
  onSubmit: PropTypes.func
};

CustomerEndorsedEditForm.defaultProps = {
  onSubmit: null
};

export default CustomerEndorsedEditForm;
