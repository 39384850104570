import React, { useEffect } from 'react';
import {
  useLocation,
  useMatch,
  Link as RouterLink,
  EmployeeRole,
  SYSTEM_PREFERENCES,
  GESTION
} from 'src/common';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Hidden, Drawer, List, ListSubheader } from '@mui/material';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import type { User } from 'src/types/user';
import NavItem from './NavItem';
import { Theme, Item, Section } from 'src/common/types';
import { sections } from 'src/layouts/Section';
import CollapseDrawer from 'src/components/layout/CollapseDrawer';
import CaijCollapseIcon from 'src/components/layout/CollapseIcon';
import { PropsWithContext, withAppContext} from 'src/functions/withAppContext';

const resourceCodesOfSubmenu = new Map<string, string[]>();

export interface NavBarProps extends PropsWithContext{
  onMobileClose: () => void;
  openMobile: boolean;
}

const mobileDrawer = {
  '& .MuiDrawer-paper': { 
    overflow: 'hidden', 
    background: (theme: Theme) => theme.palette.background.paper, 
    width: 256
  }
};

const desktopDrawer = {
  zIndex: 999, 
  '& .MuiDrawer-paper': {
    overflow: 'hidden', 
    position: 'absolute', 
    top:'64px', 
    height:'calc(100% - 64px)', 
    width: 260,
    paddingLeft: '0.8em'
  }
};

const desktopDrawerCollapse = {
  zIndex: 999, 
  '& .MuiDrawer-paper': {
    width: 30
  }
} 

const getResourceCodesOfSubMenu = (mainMenu: Section[], index: number) => {
  if (typeof mainMenu[index] === 'object') {
    const subMenu = mainMenu[index];
    Object.keys(subMenu).forEach(prop => {
      if (prop === 'items') {
        const sections = subMenu[prop] as Item[];
        sections.forEach((section: Item) => {
          const { items, title } = section;
          if (items) {
            const resourceCodes: string[] = [];
            items.forEach((item: Item) => {
              const { resourceCode } = item;
              if (resourceCode) {
                resourceCodes.push(resourceCode);
              }
            });
            if (resourceCodes.length > 0) {
              resourceCodesOfSubmenu.set(title, resourceCodes);
            }
          }
        });
      }
    });
  }
  if (index < mainMenu.length - 1) {
    index += 1;
    return getResourceCodesOfSubMenu(mainMenu, index);
  }
  return null;
};

const verifyResourceCodesOfSubMenu = (title: string, ressourceCode: string) => {
  if (resourceCodesOfSubmenu && resourceCodesOfSubmenu.has(title)) {
    if (resourceCodesOfSubmenu.get(title).includes(ressourceCode)) {
      return true;
    }
  }
  return false;
};

export function isItemDisplayMainMenu(item: Item, user: User, depth: number): boolean {
  const { accesses, role } = user.employee;
  let isItemDisplayedSubMenu = false;
  if (role === EmployeeRole.Admin) {
    return true;
  }
  if (role === EmployeeRole.User) {
    Object.values(accesses).forEach(access => {
      if (verifyResourceCodesOfSubMenu(item.title, access.ressourceCode) && access.read) {
        isItemDisplayedSubMenu = true;
        return false;
      }
      return true;
    });
    if (isItemDisplayedSubMenu) {
      return true;
    }
  }
  return false;
}

export function isItemDisplaySubMenu(item: Item, user: User, depth: number): boolean {
  const { accesses, role } = user.employee;
  if (item && !item.resourceCode) {
    return true;
  }
  return role === EmployeeRole.Admin || (accesses[item.resourceCode] && accesses[item.resourceCode].read);
}

export function renderNavItems({ items, pathname, user, depth = 0 }: { items: Item[]; pathname: string; user: User; depth?: number }) {
  return (
    <List disablePadding>
      {items.reduce(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            user,
            depth,
          }),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, user, depth }: { acc: any[]; pathname: string; item: Item; user: User; depth: number }) {
  const key = item.title + depth;
  if (item.items) {
    const match = useMatch(pathname);
    const index = item.items.findIndex((value) => value.href === match.pathname);
    if (isItemDisplayMainMenu(item, user, depth)) {
      acc.push(
        <NavItem className='mainnav' pathName={pathname} depth={depth} icon={item.icon} info={item.info} key={key} open={index !== -1} title={item.title} visible={item.visible}>
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            user,
          })}
        </NavItem>,
      );
    }
  } else if (isItemDisplaySubMenu(item, user, depth)) {
    acc.push(<NavItem className='subnav' pathName={pathname} depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} visible={item.visible}/>);
  }
  return acc;
}

const getSubHeader = (subheader: string): JSX.Element => {
  const subheaders = [SYSTEM_PREFERENCES,GESTION]
  if (subheaders.includes(subheader)) {
     return (
       <ListSubheader disableGutters disableSticky>
         {subheader}
       </ListSubheader>
     );
   }
};

if (sections.length > 0) {
  getResourceCodesOfSubMenu(sections, 0);
}

const NavBar = (props: NavBarProps) => {
  const {openMobile, onMobileClose, context} = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const getStyle = () => {
    if(context.isDesktopNavOverlapOpen) return mobileDrawer;
    if(context.isDesktopNavOpen) return desktopDrawer;
    return desktopDrawerCollapse;
  }

  const getDisplayStyle = () => {
    if(openMobile)
      return 'block';
    if(!context.isDesktopNavOverlapOpen && !context.isDesktopNavOpen)
      return 'none';
    return 'block';
  }

  const getContent = (allowedShowIcon: boolean) => (
    <>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display='flex' justifyContent='center'>
            <RouterLink to='/'>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        { /*
            <Box p={2}>
              <Box display='flex' justifyContent='center' >
                <RouterLink to='/app/account'>
                  <Avatar  alt='Utilisateur' sx={{cursor: 'pointer',width: 64,height: 64, color: 'background.paper'}} src={user.avatar} />
                </RouterLink>
              </Box>
              <Box mt={2} textAlign='center'>
                <Link component={RouterLink} to='/app/account' variant='subtitle2' color='text.primary' underline='none'>
                  {user.name}
                </Link>
              </Box>
            </Box>
            <Divider />
          */
        }
        <Box p={2}>
          { allowedShowIcon && <CaijCollapseIcon /> }
          { sections.map(section => (
              <List
                key={`1-${section.subheader}`}
                subheader={ getSubHeader(section.subheader) }
                sx={{display: getDisplayStyle()}}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  user,
                })}
              </List>
            ))
          }
        </Box>
      </PerfectScrollbar>
    </>
  );

  return (
    <>
      <Hidden lgUp only="lg">
        <Drawer anchor='left' sx={mobileDrawer} onClose={onMobileClose} open={openMobile} variant='temporary'>
          {getContent(false)}
        </Drawer>
      </Hidden>
      <Hidden mdDown only="md">
        <Drawer anchor='left' sx={getStyle()} open variant='persistent'>
          <CollapseDrawer>
            {getContent(true)}
          </CollapseDrawer>
        </Drawer> 
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default withAppContext(NavBar);
