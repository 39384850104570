import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ProductResource } from 'src/common/types';
import {$enum} from "ts-enum-util";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { labelConfig, Label, AccessType, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell  } from 'src/styles/product';

export interface AccessInfoProps {
  product: ProductResource;
}

const AccessInfo: FC<AccessInfoProps> = ({
  product
}) => {
  const { parametersRequired, access } = product;
  return (
    <MyCard>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{...tableCell, width: 1/8 }}>
                {labelConfig.accessType}
            </TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {$enum(AccessType).getValueOrDefault(access)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              {labelConfig.requireQty}
            </TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
              <Label color={parametersRequired ? 'success' : 'error'}>{parametersRequired ? labelConfig.yes : labelConfig.no}</Label>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

AccessInfo.propTypes = {
  product: PropTypes.object.isRequired
};

export default AccessInfo;
