import type { MessageResource } from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchMessages = (
  messages: MessageResource[],
  isEmptyList: boolean,
): {
  type: 'FETCH_MESSAGES';
  payload: {
    messages: MessageResource[];
    isEmptyList?: boolean;
  };
} => ({
  type: actionTypes.FETCH_MESSAGES,
  payload: {
    messages,
    isEmptyList,
  },
});

export const fetchMessage = (message: MessageResource, isLoading?: boolean): {
  type: 'FETCH_MESSAGE';
  payload: {
    message: MessageResource,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_MESSAGE,
  payload: {
    message,
    isLoading
  },
});

export const fetchMessageDetails = (
  message: MessageResource,
): {
  type: 'FETCH_DETAILS_MESSAGE';
  payload: {
    message: MessageResource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_MESSAGE,
  payload: {
    message,
  },
});
