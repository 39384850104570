import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import {
  Formik,
  FormikProps,
  Mode,
  CaijButtonReset,
  CaijButtonSubmit,
  btnSubmit
} from 'src/common';
import type {
  GoogleMaps,
  LibraryResource,
  LibraryResourceForEdit
} from 'src/common/types';
import Authorize from 'src/components/Authorize';
import LibraryModel from 'src/model/library/Library';
import printMessage from 'src/views/errors/MessageError';
import MyCard from 'src/components/card/MyCard';
import { validateAddressSchema } from '../../Schema';
import ContactAddress from 'src/components/library/library/ContactAddress';

interface AddressesFormProps {
  model: LibraryModel;
  library: LibraryResource;
  onHandleRefreshLibrary: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const AddressesForm: FC<AddressesFormProps> = ({
  model,
  library,
  onHandleRefreshLibrary,
  onSwitchMode
}) => {
  const formRef = useRef<FormikProps<LibraryResource>>(null);
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const { addressFr, addressEn, longitude, latitude } = library;

  return (
    <Formik<LibraryResource>
      innerRef={formRef}
      initialValues={{
        longitude: longitude || 0,
        latitude: latitude || 0,
        addressFr: addressFr || {},
        addressEn: addressEn || {}
      }}
      validationSchema={validateAddressSchema(model)}
      onSubmit={async values => {
        const model = new LibraryModel();
        const submitData = library as LibraryResourceForEdit;
        const libraryId = submitData.id;
        if (submitData && libraryId) {
          const imageUrl = model.getSubmitDataImageUrl(submitData.imgUrlFr, submitData.imgUrlEn);
          submitData.imgUrlFr = imageUrl.Fr;
          submitData.imgUrlEn = imageUrl.En;
          const { addressFr, addressEn } = values;
          submitData.addressFr = addressFr;
          submitData.addressEn = addressEn;
          const coord : GoogleMaps = await model.handleGeolocation(submitData);
          submitData.latitude = +coord?.latitude;
          submitData.longitude = +coord?.longitude;
          const response = await model.updateLibrary(submitData);
          if (!model.error) {
            printMessage({
              status: response.status,
              message: response.message
            });
            await onHandleRefreshLibrary();
          }
        }
      }}
    >
      {({ handleSubmit, handleBlur, setFieldValue, errors, touched, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <ContactAddress
                  model={model}
                  library={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Box mt={2}>
                <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

AddressesForm.propTypes = {
  library: PropTypes.object.isRequired,
  onHandleRefreshLibrary: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired
};

export default AddressesForm;
