import { DocCollectionModel} from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: DocCollectionModel) => {
  const { Code, NameFr, NameEn, DescriptionFr, DescriptionEn } = model;
  return Yup.object().shape({
    code: Code.required(true),
    nameFr: NameFr.required(true),
    nameEn: NameEn.required(true),
    descriptionFr: DescriptionFr.nullable(true),
    descriptionEn: DescriptionEn.nullable(true),
  });
};

export default validateSchema;