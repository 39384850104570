import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FORMAT_DAY_PICKER } from 'src/common';
import { Box } from '@mui/material';
import { CaijLocalizationProvider } from './LocalizationProvider';

interface CaijDayPickerProps {
  name: string;
  value?: number;
  label?: string;
  clearable?: boolean;
  required?: boolean;
  helperText?: ReactNode;
  inputProps?: {'data-testid'?: string, 'aria-label'?:string};
  onHandleChange: (date: number | string) => void;
}

const getDay = (date: Date) : number | string => {
  const m = date.getDate();
  if (m.toString().length === 1) {
      return `0${m}`;
  } else {
      return m;
  }
};

const CaijDayPicker: FC<CaijDayPickerProps> = ({
  value,
  label,
  required,
  inputProps,
  helperText,
  onHandleChange,
  ...rest
}) => {
  const [cleared, setCleared] = useState<boolean>(true);
  const [day, setDay] = useState<(Date | null)>(null);
  
  useEffect(() => {
    const d = new Date();
    if(value){
      d.setDate(value);
      setDay(d);
    }
  },[value]);

  return (
    <Box display="flex" flexDirection="column">
      <CaijLocalizationProvider>
        <DatePicker
          {...rest}
          label={label}
          value={day}
          format={FORMAT_DAY_PICKER}
          views={["day"]}
          openTo="day"
          slotProps={{
            field: { clearable: cleared, onClear: () => setCleared(false) },
            textField: {
              helperText: helperText,
              inputProps: inputProps,
              required: required,
              InputLabelProps: {shrink: true}
            },
          }}
          onChange={(e) => { 
            if(e){
              onHandleChange(getDay(e) as number);
              setCleared(true)
            }
          }}
      />
    </CaijLocalizationProvider>
  </Box>
  );
}

CaijDayPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  clearable: PropTypes.bool,
  required: PropTypes.bool,
  onHandleChange: PropTypes.func.isRequired
};

export default CaijDayPicker;
