import { useEffect, useReducer } from "react";
import { 
  PartnersModel,
  DocumentTypeModel,
  fetchPartners,  
  fetchLaws,   
  Forbidden, 
  LawModel, 
  TribunalModel, 
  Unauthorized, 
  _partners, 
  _docCollection,
  _law,
  _tribunal,
  fetchTribunaux,
  ITEMS_PER_PAGE,
  fetchDocumentTypes,
  _documentType
} from "src/common";
import { 
  PartnerResource, 
  DocumentTypeResource, 
  LawResource, 
  TribunalResource 
} from "src/common/types";
import { 
  FETCH_PARTNERS, 
  FETCH_DOCUMENT_TYPES, 
  FETCH_LAWS, 
  FETCH_TRIBUNAUX 
} from "src/store/actions/ActionTypes";
import useCollection from "./collection";
import { usePublication } from "./publication";
import usePartners from "./partners";
import useDocumentTypes from "./documentType";

interface Data {
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
};

interface Law extends Data {
  laws: LawResource[];
};

interface Tribunal extends Data {
  tribunaux: TribunalResource[];
};

let initialStateBase = {
  isEmptyList: true,
  isLoading: false,
  isAuthorize: false
};

function useLaw() : Law {
  const initialState = {
    ...initialStateBase,
    laws: []
  };
  const [state, dispatch] = useReducer(_law, initialState);
  useEffect(() => {
    const initialise = async () => {
      const model = new LawModel();
      model.skipHandleError = true;
      const laws = await model.getLaws();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchLaws(laws, true, false, false));
        }else{
          dispatch(fetchLaws(laws, true, true, true));
        }
      } else {
        dispatch(fetchLaws(laws, true, !Array.isArray(laws) || laws.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: FETCH_LAWS, payload: initialState}); }
  },[]);
  const { laws, isEmptyList, isLoading, isAuthorize } = state;
  return { laws, isEmptyList, isLoading, isAuthorize };
};

function useTribunal() : Tribunal {
  const initialState = {
    ...initialStateBase,
    tribunaux: [],
    paged: {
      totalCount: 0,
      pageSize: ITEMS_PER_PAGE,
      currentPage: 1,
      totalPages: 0,
    },
  };
  const [state, dispatch] = useReducer(_tribunal, initialState);
  useEffect(() => {
    const initialise = async () => {
      const model = new TribunalModel();
      model.skipHandleError = true;
      const results = await model.getTribunalsPaginated({pageNumber: 1, pageSize: 500});
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchTribunaux(results?.list,{...results}, true, false, false));
        }else{
          dispatch(fetchTribunaux(results?.list,{...results}, true, true, true));
        }
      } else {
        dispatch(fetchTribunaux(results.list,{...results},true, Object.entries(results.list).length === 0, true));
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: FETCH_TRIBUNAUX, payload: initialState}); }
  },[]);
  const { tribunaux, isEmptyList, isLoading, isAuthorize } = state;
  return { tribunaux, isEmptyList, isLoading, isAuthorize };
};

export function useDocument() {
    return {
      sCollection: useCollection(),
      sPublication: usePublication(),
      sDocumentType: useDocumentTypes(),
      sLaw: useLaw(),
      sTribunal: useTribunal(),
      sPartners: usePartners()
    }
}
