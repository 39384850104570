import { Box, CardContent} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import {
  Authorize,
  btnSubmit,
  CaijButtonReset,
  CaijButtonSubmit,
  Formik,
  handleChange,
  LawDomainFieldModel,
  Mode
} from 'src/common';
import type { 
  Error,
  CreateResponse,
  LawDomainFieldResource,
  LawDomainFieldResourceForEdit,
  LawDomainFieldResourceForCreate
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import LawDomainFieldForm from 'src/components/content/lawDomainField/Forms';
import { Root } from 'src/components/styled';
import validateSchema from '../Schema';

export interface LawDomainFieldEditFormProps {
  model: LawDomainFieldModel;
  lawDomainField: LawDomainFieldResource;
  onSubmit?: (values: LawDomainFieldResource) => void;
}

async function redirect(model: LawDomainFieldModel, response: Error | CreateResponse) : Promise<void> {
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message,
  });
};

const LawDomainFieldEditForm: FC<LawDomainFieldEditFormProps> = ({
  model,
  lawDomainField,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isParent, setIsParent] = useState<boolean>(lawDomainField.parentId ? false : true);

  const handleSubmit = async (values: LawDomainFieldResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LawDomainFieldModel();
    let submitData = values as LawDomainFieldResourceForEdit;
    isParent ? submitData.parentId = null : '';
    if (submitData && submitData.id) {
      const response = await model.updateLawDomainField(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as LawDomainFieldResourceForCreate;
      delete submitData.id;
      const response = await model.insertLawDomainField(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <Formik
      initialValues={LawDomainFieldModel.getInitialValues(lawDomainField)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Root>
                <LawDomainFieldForm 
                  isParent={isParent}
                  setIsParent={(l) => setIsParent(l)}
                  model={model}
                  lawDomainField={values}
                  errors={errors}
                  touched={touched}
                  onHandleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Root>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={lawDomainField.id ? Mode.edit : Mode.add}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

LawDomainFieldEditForm.propTypes = {
  lawDomainField: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

LawDomainFieldEditForm.defaultProps = {
  onSubmit: null
};

export default LawDomainFieldEditForm;
