import { Dispatch, SetStateAction } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, RadioGroup, Typography } from '@mui/material';
import {
  labelConfig,
  CaijTextarea,
  FormikErrors,
  FormikTouched,
  getIn,
  CaijRadio,
  AccessModel,
  ListType,
  CaijCard,
  PRIVATE_PERMISSION_MSG,
  CARD_HEADER_TITLE_SUBSCRIPTION2,
  DatabankModel,
  SubscriptionModel,
} from 'src/common';
import {
  DatabankResource, KnownDatabankAccessType, KnownDatabankAppType,
} from 'src/common/types';
import Libraries from 'src/components/databank/Lists/Libraries';
import LibraryAccessLink from './LibraryAccessLink';
import SubscriptionList from 'src/components/listInfo/checkList';
import Memo from 'src/components/memo';

export interface LibraryAccessFormProps {
  model: DatabankModel;
  databank: DatabankResource;
  errors?: FormikErrors<DatabankResource>;
  touched?: FormikTouched<DatabankResource>;
  handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setSelectedLibraries: Dispatch<SetStateAction<number[]>>;
  setSelectedSubscriptions: Dispatch<SetStateAction<number[]>>;
  disableBtnSubmit: (value: boolean) => void;
}

const LibraryAccessForm: FC<LibraryAccessFormProps> = ({
  model,
  databank,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  setSelectedSubscriptions,
  setSelectedLibraries,
  disableBtnSubmit
}) => {
  const subscription = SubscriptionModel.getInstance();
  const accessSubscription = new AccessModel(subscription.ResourceCode);
  const { appType, subscriptions, databankAccesses } = databank;
  const { AccessInfoFr, AccessInfoEn } = model;

  const handleAppType = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if(value === KnownDatabankAppType.Desktop){
      setFieldValue('databankAccesses', [])
    }
    setFieldValue('appType', value);
  }

  const renderLibraryAccess = () : JSX.Element => {
    return (
      <>
      <Grid item md={12} xs={12}>
          <div style={{marginTop: "0.5em", width:"100%"}}>
            <Memo>
              <Libraries 
                databank={databank} 
                onHandleSelectedItems={l => setSelectedLibraries(l)} 
              />
            </Memo>
          </div>
      </Grid>
      </>
    )
  }
 
  const renderWebAccess = () : JSX.Element => {
    const inLibraryOnly = databankAccesses.length === 1 && databankAccesses.some(a => a.accessType === KnownDatabankAccessType.Library);
    const remoteAccess =  databankAccesses.some(a => a.accessType === KnownDatabankAccessType.Remote)
    const subscriptionAccess = accessSubscription.Edition;
    return (
		<Grid item md={12} xs={12}>
			<Box mb={3}>
        <LibraryAccessLink
          databank={databank}
					errors={errors}
					touched={touched}
					handleBlur={handleBlur}
					handleChange={handleChange}
					setFieldValue={setFieldValue}
					disableBtnSubmit={(l: boolean) => disableBtnSubmit(l)}
        />
			</Box>
			<Box display='flex' justifyContent='space-between'>
				<Box width={inLibraryOnly ? '0%' : '100%'}>
					{!subscriptionAccess && (
						<CaijCard sx={{ mr: 2 }} title={CARD_HEADER_TITLE_SUBSCRIPTION2} allowedBackgroundColor={false}>
							<Typography variant='body2' sx={{ color: 'text.error' }}>
								{PRIVATE_PERMISSION_MSG}
							</Typography>
						</CaijCard>
					)}
					{remoteAccess && subscriptionAccess && (
						<Memo>
							<SubscriptionList
								listType={ListType.Subscription}
								lists={subscriptions}
                group={databank.group}
								onHandleSelectedItems={(l) => setSelectedSubscriptions(l as number[])}
                onHandleSelectedGroupItems={(l) => setFieldValue('group', l) }
							/>
						</Memo>
					)}
				</Box>
				{inLibraryOnly && (
					<Box width='100%'>
						<Memo>
							<Libraries databank={databank} onHandleSelectedItems={(l) => setSelectedLibraries(l as number[])} />
						</Memo>
					</Box>
				)}
			</Box>
		</Grid>
	);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <RadioGroup
            aria-label='appType'
            value={appType || ''}
            onChange={handleAppType}
          >
            <CaijRadio 
              name='libraryAccessEnabled'
              label={labelConfig.libraryAccessEnabled} 
              value={KnownDatabankAppType.Desktop}
              inputProps={{'data-testid': 'library_access_enabled'}}
            />
            <CaijRadio 
              name='webAccessEnabled'
              label={labelConfig.webAccessEnabled} 
              value={KnownDatabankAppType.Online || ''}
              inputProps={{'data-testid': 'web_access_enabled'}}
            />
          </RadioGroup>
        </Grid>
        {
          appType !== KnownDatabankAppType.Unknown && (
            <>
               { appType === KnownDatabankAppType.Desktop && renderLibraryAccess() }
               { appType === KnownDatabankAppType.Online && renderWebAccess() }
            </>
          )
        }
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid item md={6} xs={12}>
            <CaijTextarea
              name={AccessInfoFr.Name}
              rows='4'
              label={AccessInfoFr.Label}
              value={databank.accessInfoFr ? databank.accessInfoFr : ""}
              error={Boolean(getIn(touched, 'accessInfoFr') && getIn(errors, 'accessInfoFr'))}
              helperText={getIn(touched, 'accessInfoFr') && getIn(errors, 'accessInfoFr')}
              inputProps={{ maxLength: AccessInfoFr.MaxLength, 'aria-label': 'accessInfoFr', 'data-testid': 'accessInfoFr' }}
              InputLabelProps={{ shrink: true }}
              onHandleBlur={handleBlur}
              onHandleChange={handleChange}
              setFieldValue={setFieldValue}
              sx={{mr: 5}}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CaijTextarea
              name={AccessInfoEn.Name}
              rows='4'
              label={AccessInfoEn.Label}
              helperText={getIn(touched, 'accessInfoEn') && getIn(errors, 'accessInfoEn')}
              error={Boolean(getIn(touched, 'accessInfoEn') && getIn(errors, 'accessInfoEn'))}
              value={databank.accessInfoEn ? databank.accessInfoEn : ""}
              inputProps={{ maxLength: AccessInfoEn.MaxLength, 'aria-label': 'accessInfoEn', 'data-testid': 'accessInfoEn' }}
              InputLabelProps={{ shrink: true }}
              onHandleBlur={handleBlur}
              onHandleChange={handleChange}
              setFieldValue={setFieldValue}
            />
        </Grid>
      </Grid>
    </>
  );
};

LibraryAccessForm.propTypes = {
  databank: PropTypes.object.isRequired,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setSelectedSubscriptions: PropTypes.func,
  disableBtnSubmit: PropTypes.func
};

export default LibraryAccessForm;
