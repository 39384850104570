import { FC } from 'react';
import PropTypes from 'prop-types';
import type { DatabankResource, SetFieldValue } from 'src/common/types';
import { Grid } from '@mui/material';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';
import { FormModel } from 'src/model/Form';

interface DatabankNoteProps {
  note: FormModel;
  databank: DatabankResource; 
  setFieldValue: SetFieldValue;
}

const DatabankNote: FC<DatabankNoteProps> = ({ 
  note,
  databank,
  setFieldValue 
}) => (
  <Grid item md={6} xs={12}>
    <CaijDraftEditor
      title={note.Label}
      dataTestId={note.Name}
      value={databank.note}
      name={note.Name}
      setFieldValue={setFieldValue}
      allowedBackgroundColor={false}
    />
  </Grid>
);

DatabankNote.propTypes = {
  note: PropTypes.instanceOf(FormModel),
  setFieldValue: PropTypes.func.isRequired
};

export default DatabankNote;