import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type { EmployeesResource, Theme, TabsEmployee } from 'src/common/types';
import { AccessModel, EmployeeRole, root, useNavigate } from 'src/common';
import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import { useLocation, useParams, fetchDetailsEmployee,_employee } from 'src/common';
import EmployeeModel from 'src/model/employee/Employee';
//import ResourceGroupModel from 'src/model/employee/ResourceGroup';
import Page from 'src/components/Page';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';
//import GroupInfo from './GroupInfo';
import GroupResourceForm from './Forms/GroupResourceForm';
import { ResourceProvider } from 'src/contexts/ResourceContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import EmployeeAccess from 'src/components/employee/employee/Forms/EmployeeAccess';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  employee: {},
  resourceGroups: [],
  resources: [],
  tabs: []
};

const EmployeeDetailsView: FC = () => {
  const model = EmployeeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_employee, initialState);
  const [editMode, setEditMode] = useState({
    details: false,
    group: false,
    resource: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  
  const getEmployeeById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const model = new EmployeeModel();
      model.PathName = location.pathname;
      const employee = await model.getEmployeeById(id);
      //const resourceGroupModel = new ResourceGroupModel();
      //const resourceGroups = await resourceGroupModel.getResourceGroups();
      //const resourceGroupError = resourceGroupModel.error;
      if (!model.error) {
        const tabs:TabsEmployee[] = [{ value: 'details', label: 'Détails' }];
        if (employee.active && employee.role === EmployeeRole.User) {
          tabs.push({ value: 'resource', label: 'Permissions' });
        }
        dispatch(fetchDetailsEmployee(employee, tabs));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getEmployeeById();
    })();
  },[]);

  const { employee, tabs } = state;

  if (Object.keys(employee).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${employee.firstname} ${employee.lastname}`;

  const setEditModeEmployee = (isEdit: boolean, value?: string) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit,
    });
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeEmployee(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setEditModeEmployee(false, value);
    setCurrentTab(value);
  };

  const handleRefreshEmployee = async (employee: EmployeesResource, value?: string): Promise<void> => {
    //const resourceGroupModel = new ResourceGroupModel();
    //const rgs = await resourceGroupModel.getResourceGroups();
   // let tabs: TabsEmployee[]  = [];
    if (employee.active && employee.role === EmployeeRole.User) {
      const oldTabs = [...tabs];
      if (oldTabs.length === 1) {
        dispatch(fetchDetailsEmployee(employee,[...tabs, { value: 'resource', label: 'Permissions' }]));
      }
    } else {
      const oldTabs = [...tabs];
      if (oldTabs.length >= 2) {
        const newTabs = oldTabs.filter((x: TabsEmployee) => x.value !== 'resource');
        dispatch(fetchDetailsEmployee(employee,newTabs));
      }
    }
    setEditModeEmployee(false, value);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <DetailsEditForm 
              model={model}
              employee={employee} 
              onHandleRefreshEmployee={handleRefreshEmployee} 
              onSwitchMode={l => switchMode(l)} 
            />
          );
        }
        return <Details model={model} employee={employee} />;
     /* case 'group':
        if (editMode.group) {
          return (
            <GroupResourceForm
              employee={employee}
              onHandleRefreshEmployee={rg => handleRefreshEmployee(rg, tabs[1].value)}
              onSwitchMode={l => switchMode(l)}
            />
          );
        }
        return <GroupInfo resourceGroups={resourceGroups} employeeResourceGroups={employeeResourceGroup} />;
      */
      case 'resource':
        if (editMode.resource) {
          return (
            <ResourceProvider>
              <GroupResourceForm
                employee={employee}
                onHandleRefreshEmployee={r => handleRefreshEmployee(r, tabs[1].value)}
                onSwitchMode={l => switchMode(l)}
              />
            </ResourceProvider>
          );
        }
        return (
          <ResourceProvider>
            <EmployeeAccess 
              employeeAccesses={employee.resources} 
              allowedEdit={false}
            />
          </ResourceProvider>
        );
      default:
        return '';
    }
  };

  return (
    <Page sx={root} title={model.DetailPageTitle}>
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          actions={['edit','delete']}
        />
        <Box mt={2}>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='scrollable' textColor='secondary'>
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{renderContent()}</Box>
      </Container>
    </Page>
  );
};

export default EmployeeDetailsView;
