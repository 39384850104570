import { Box, Button, Dialog, DialogContent, IconButton, SvgIcon, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { btnSubmit, dialog, formatDateTime, tableRow } from "src/common";
import { CheckSquare } from "react-feather";
import { EditableDocumentModel } from "src/model/EditableDocument";
import { DocumentVersionResource } from "src/common/types";
import LoadingScreen from "src/components/loading/LoadingScreen";
import CaijTable from "src/components/table/CaijTable";
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import CaijAppBar from "src/components/AppBar";

interface IVersionHistoryDialogProps {
    open: boolean;
    onClose: () => void;
    docId: string;
    selectedVersion: DocumentVersionResource;
    handleSelectVersion: (version: DocumentVersionResource) => void;
    model: EditableDocumentModel;
    loadingVersion: boolean;
}

const VersionHistoryDialog: FC<IVersionHistoryDialogProps> = ({
    open,
    onClose,
    docId,
    selectedVersion,
    handleSelectVersion,
    model,
    loadingVersion
}) => {
    const [versions, setVersions] = useState<DocumentVersionResource[]>(null);
    const [loadingVersionHistory, setLoadingVersionHistory] = useState<boolean>(true);
    const headCells = [{label: 'Version'}, {label: 'Publié'}, {label: 'Auteur'},{label: ''}];

    useEffect(() => {
        const fetchVersionHistory = async () => {
            setLoadingVersionHistory(true);
            const data = await model.getDocumentVersionHistory(docId);
            setVersions(data);
            setLoadingVersionHistory(false);
        };

        if (open) {
            fetchVersionHistory();
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if(reason == 'backdropClick' || reason == 'escapeKeyDown') return;
                onClose();
            }}
            maxWidth="md"
            disableEscapeKeyDown
        >
            <CaijAppBar title="Historique des versions du document" handleClose={onClose} />
            <DialogContent sx={{...dialog}}>
                {loadingVersionHistory || loadingVersion ? <LoadingScreen /> :
                    versions?.length > 0 ? (
                            <CaijTable>
                                <TableHead>
                                    <TableRow>{headCells.map(({label}, index: number) =>  <TableCell key={index}>{label}</TableCell>)}</TableRow>
                                </TableHead>
                                <TableBody>
                                    {versions.map(version => (
                                        <TableRow hover tabIndex={-1} key={version.createdBy + version.created} sx={tableRow}>
                                            <CaijTableCell>{formatDateTime(version.created)}</CaijTableCell>
                                            <CaijTableCell>{formatDateTime(version.published)}</CaijTableCell>
                                            <CaijTableCell>{version.createdBy}</CaijTableCell>
                                            <CaijTableCell>
                                                <Button
                                                    onClick={() => handleSelectVersion(version)}
                                                    variant="contained"
                                                    startIcon={<SvgIcon fontSize='small'><CheckSquare /></SvgIcon>}
                                                    sx={btnSubmit}
                                                    disabled={selectedVersion?.filepath === version.filepath}
                                                >Charger</Button>
                                            </CaijTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CaijTable>
                        ) : (
                            <Box mb={2}>
                                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                    <div>Ce document n'a pas de versions sauvegardées.</div>
                                </Typography>
                            </Box>
                        )
                }
            </DialogContent>
        </Dialog>
    )
}

export default VersionHistoryDialog;