import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import type { LibraryServiceResource } from 'src/common/types';
import { LibraryServiceModel, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/libraryService';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface LibraryServiceEnInfoProps {
  model: LibraryServiceModel;
  libraryService: LibraryServiceResource;
}

const LibraryServiceEnInfo: FC<LibraryServiceEnInfoProps> = ({
  model,
  libraryService
}) => {
  const { nameEn, detailsEn } = libraryService;
  const { NameEn, DetailsEn } = model;

  return (
    <MyCard>
      <CardHeaderDetail title="Service offered by the library" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              {NameEn.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {nameEn}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              {DetailsEn.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {detailsEn}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LibraryServiceEnInfo.propTypes = {
  libraryService: PropTypes.object.isRequired
};

export default LibraryServiceEnInfo;
