import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  Error,
  DocumentTypeResourceForCreate,
  DocumentTypeResourceForEdit,
  DocumentTypeResource,
  CreateResponse
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

export default class DocumentTypeModel extends AppModel 
{
  private static _instance: DocumentTypeModel;
  readonly Code = new FormModel('code', 'Code',50);
  readonly NameFr = new FormModel('nameFr','Nom',255);
  readonly NameEn = new FormModel('nameEn','Name',255);
  readonly Description = new FormModel('description', 'Description');

  constructor(){
    super('/content/document/types');
    this.initialize();
  }
  
  private initialize(){
    this._resourceCode = 'DOCUMENT_TYPE';
    this._headerTitle = 'Liste des types de document';
    this._btnAddText= 'Ajouter un type de document';
    this.Path.PathName = '/contents/types';

    //Dialog
    this.Dialog.Header = 'Supprimer le type de document';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le type de document';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Types de document',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: this.Code.Name, width: '15%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.NameFr.Name, width: '40%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: this.NameEn.Name, width: '40%', numeric: false, disablePadding: false, label: this.NameEn.Label
      },
      {
        id: '', width: '5%'
      }
    ];
  };

  static getInstance() {
    if (!DocumentTypeModel._instance) {
      DocumentTypeModel._instance = new DocumentTypeModel();
    }
    return DocumentTypeModel._instance;
  }

  static getInitialValues(values: DocumentTypeResource) {
    return {
      nameEn: values.nameEn || '',
      nameFr: values.nameFr || '',
      code: values.code || '',
      description: values.description || ''
    }
  }

  async getDocumentTypes(): Promise<DocumentTypeResource[]> {
    let contentTypes: DocumentTypeResource[];
    await axios.get<DocumentTypeResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentTypes = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return contentTypes;
  }

  async getDocumentTypeById(id: string): Promise<DocumentTypeResource> {
    let contentType: DocumentTypeResource;
    await axios.get<DocumentTypeResource>(`${this.route}/${id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          contentType = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return contentType;
  }

  async updateDocumentType(submitData: DocumentTypeResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<DocumentTypeResourceForEdit, Error>(`${this.route}/${submitData.code}`, submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Success) {
          result = response;
          result.message = 'Type de document modifié.';
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertDocumentType(submitData: DocumentTypeResourceForCreate): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<DocumentTypeResourceForCreate, CreateResponse>(this.route, submitData).then((response) => {
      const { status } = response;
      if (status === Created) {
        result = response;
        result.message = 'Type de document créé.';
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(code: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${code}`).then((response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Type de document supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }
}
