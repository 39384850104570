import { ErrorType } from 'src/enum';
import * as Yup from 'yup';
import AppModel from './App';

interface IFormModel {
  Name: string;
  Label: string;
  MaxLength: number;
  nullable(isMax?: boolean, isEmail?: boolean) : Yup.StringSchema;
  required(isMax?: boolean, isEmail?: boolean) : Yup.StringSchema;
  max() : Yup.StringSchema;
  email() : Yup.StringSchema;
};

export class FormModel implements IFormModel{
  private _yup = Yup.string();
  private _name: string;
  private _label: string;
  private _maxLength: number;

  constructor(name?:string, label?:string, maxLenght?: number){
    if(name){
      this._name = name;
    }
    if(label){
      this._label = label;
    }
    if(maxLenght){
      this._maxLength = maxLenght;
    } 
  }

  get Name(){
    return this._name;
  }

  get Label(){
    return this._label;
  }

  get MaxLength(){
    return this._maxLength;
  }

  nullable(isMax?: boolean, isEmail?: boolean){
    this._yup = this._yup.nullable();
    if(isMax){
      this._yup = this.max();
    }
    if(isEmail){
      this._yup = this.email();
    }
    return this._yup;
  }

  required(isMax?: boolean, isEmail?: boolean){
    this._yup = this._yup.required(AppModel.formatError(ErrorType.required,this._label));
    if(isMax){
      this._yup = this.max();
    }
    if(isEmail){
      this._yup = this.email();
    }
    return this._yup;
  }

  max(){
    this._yup = this._yup.max(this._maxLength, AppModel.formatError(ErrorType.max,this._label, this._maxLength));
    return this._yup;
  }

  email(){
    this._yup = this._yup.email(AppModel.formatError(ErrorType.invalid,this._label));
    return this._yup;
  }

  dateRequired(){
    return this._yup.transform((curr, orig) => orig === null ? '' : curr)
                    .required(AppModel.formatError(ErrorType.required,this._label));
  }
}