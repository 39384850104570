import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid } from '@mui/material';
import {
  Formik,
  handleChange,
  TribunalModel,
  Authorize,
  CaijButtonSubmit,
  CaijButtonReset,
  Mode,
  button
} from 'src/common';
import type { 
  TribunalResourceForCreate, 
  TribunalResourceForEdit, 
  TribunalResource,
  CreateResponse,
  Error
} from 'src/common/types';
import TribunalForm from 'src/components/content/caseLaw/tribunal/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';

export interface TribunalEditFormProps {
  model: TribunalModel;
  tribunal: TribunalResource;
  onSubmit?: (values: TribunalResource) => void;
};

async function redirect(model:TribunalModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status, 
    message: response.message
  });
};

const TribunalEditForm: FC<TribunalEditFormProps> = ({ model, tribunal, onSubmit }) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const inputCodeFrRef = useRef(null);
  const inputCodeEnRef = useRef(null);
  const { CodeFr, CodeEn, LabelFr, LabelEn, Coverage, OtherCodesInput } = model;

  const handleSubmit = async (values: TribunalResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new TribunalModel();
    let submitData = values as TribunalResourceForEdit;
    if (submitData && submitData.id) {
      submitData.otherCodes = values.otherCodes;
      const response = await model.updateTribunal(submitData);
      if (!model.error) {
        redirect(model,response);
      }
    } else {
      submitData = values as TribunalResourceForCreate;
      submitData.otherCodes = values.otherCodes;
      delete submitData.id;
      const response = await model.insertTribunal(submitData);
      if (!model.error) {
        redirect(model,response);
      }
    }
  };

  return (
    <Formik
      initialValues={TribunalModel.getInitialValues(tribunal)}
      validationSchema={validateSchema(model,tribunal,onSubmit)}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <TribunalForm
                  model={model}
                  tribunal={values}
                  errors={errors}
                  touched={touched}
                  inputCodeFrRef={inputCodeFrRef}
                  inputCodeEnRef={inputCodeEnRef}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Box mt={2}>
                <Authorize resourceCode={model.ResourceCode} mode={tribunal.id ? Mode.edit : Mode.add} onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={button} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

TribunalEditForm.propTypes = {
  tribunal: PropTypes.object.isRequired,
  onSubmit:PropTypes.func
};

TribunalEditForm.defaultProps = {
  onSubmit: null
};

export default TribunalEditForm;
