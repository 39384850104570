import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {TableBody} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  EnhancedTableHead,
  LibraryServiceModel
} from 'src/common';
import type { LibraryServiceResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: LibraryServiceModel;
  libraryServices: LibraryServiceResource[];
  onDeleteLibraryService: (id: number) => Promise<void>;
  isEmptyList: boolean;
}

const Results: FC<ResultsProps> = ({
  model,
  libraryServices,
  onDeleteLibraryService,
  isEmptyList
}) => {
  const path = model.Path;
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const { order, orderBy, sort, handleRequestSort } = useSort();

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={model.getHeadCells(labelConfig.requireQty)}
        />
        <TableBody>
          {  sort(libraryServices, page).map((row: LibraryServiceResource) => (
              <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                <CaijTableCell>{row.nameFr}</CaijTableCell>
                <CaijTableCell>{row.nameEn}</CaijTableCell>
                <CaijTableCell>{row.detailsFr}</CaijTableCell>    
                <CaijTableCell>{row.detailsEn}</CaijTableCell> 
                <CaijTableCell allowedLabel active={row.requireParameters} txtActive={labelConfig.require} txtInActive={labelConfig.notRequire} />
                <CaijTableCellAction 
                  pageEditUrl={path.getEdit(row.id)}
                  resourceCode={model.ResourceCode}
                  handleToggle={() => LibraryServiceModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                >
                  <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(row.id) !== -1}
                      onSubmit={async () => {
                        await onDeleteLibraryService(row.id);
                        setSelectedRow([]);
                      }}
                      setSelectedRow={setSelectedRow}
                  />
                </CaijTableCellAction>
              </CaijTableRowClickable>
            ))}
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight 
        len={libraryServices.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  libraryServices: PropTypes.array.isRequired,
  onDeleteLibraryService: PropTypes.func.isRequired,
  isEmptyList: PropTypes.bool.isRequired
};

export default Results;
