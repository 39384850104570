import React, { useCallback, useEffect, useReducer, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  _coveoSource,
  AccessModel,
  SearchEngineModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { deleteCoveoSource, fetchCoveoSources } from 'src/store/actions/coveo/source/SourceAction';
import SearchEngineSource from 'src/components/coveo';
import useInterval from 'src/hooks/polling/useInterval';
import GenericHeader from 'src/components/header/GenericHeader';
import { CoveoSourceDto } from 'src/common/types';

const initialState = {
  coveoSources: [],
  isLoading: false,
  isEmptyList: true
};

const CoveoSourceListView: FC = () => {
  const model = new SearchEngineModel();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_coveoSource, initialState);
  const navigate = useNavigate();;
  const [count, setCount] = useState<number>(0);
  const [isRunning, setIsRunning] = useState(true);
  
  const fetchSourceCoveoList = useCallback(async () => {
    if(access.canRead()){
      const { pathname, search } = location;
      let coveoSources = await model.getLists();
      if(search){
        model.PathName = pathname + search;
        if(await model.setQueryParams(search, model, navigate)){
          const { Filters: { name, coveoIdentifier, collection, jurisdiction }} = model; 
          const terms = {
            name: model.getQueryParam(name),
            collection: model.getQueryParam(collection),
            coveoIdentifier: model.getQueryParam(coveoIdentifier),
            juridiction: model.getQueryParam(jurisdiction)
          };
          Object.keys(terms).forEach((key: string, index: number) => {
            if(terms[key])
              coveoSources = key === 'juridiction' ? coveoSources?.filter((coveoSource: CoveoSourceDto) => coveoSource[key] === terms[key]) : coveoSources?.filter((coveoSource: CoveoSourceDto) => coveoSource[key].toLowerCase().search(terms[key].toLowerCase()) >= 0);
          });
          dispatch(fetchCoveoSources(coveoSources, true, !Array.isArray(coveoSources) || coveoSources?.length === 0));
        }
      }else
        dispatch(fetchCoveoSources(coveoSources, true, !Array.isArray(coveoSources) || coveoSources?.length === 0));
    }
  }, [location.search]);

  useEffect(() => {
    (async () => {
      await fetchSourceCoveoList();
    })();
  },[location.search, count]);
 
  useInterval(async () => {
    await fetchSourceCoveoList();
  },isRunning ? 30000 : null);
  
  const handleDelete: (id: number) => Promise<void> = async (id) => {
    if(id && !isNaN(id)) {
      dispatch(deleteCoveoSource(id));
    }
  };

  const { coveoSources, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <SearchEngineSource 
            coveoSources={coveoSources} 
            showCoveoSourcePageList
            setIsRunning={setIsRunning}
            onHandleRefresh={async (l) => {
              if(l)
                await handleDelete(l);
              else
                setCount(count + 1);
            }}
          /> 
        </Box>
      </Container>
    </Page>
  );
};

export default CoveoSourceListView;
