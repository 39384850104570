import type {DocumentOptionResource, DocumentResource} from 'src/common/types';
import DocumentModel from 'src/model/content/Document';
import * as actionTypes from '../../actions/ActionTypes';
import { Paged } from 'src/types/pagination';

type FetchDocumentsAction = {
  type: 'FETCH_DOCUMENTS';
  payload: {
    documents: DocumentOptionResource[];
    paged?: Paged;
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
    model?: DocumentModel;
  };
};

type FetchDocumentAction = {
  type: 'FETCH_DOCUMENT';
  payload: {
    document: DocumentResource;
    isLoading?: boolean;
  }
};

export type DocumentAction = FetchDocumentsAction |
                             FetchDocumentAction;

interface DocumentReturnState {
  documents?: DocumentOptionResource[];
  document?: DocumentResource;
  paged?: Paged;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  model?: DocumentModel;
}

interface DocumentState {
  documents: DocumentOptionResource[];
  document: DocumentResource;
  paged: Paged;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  model:DocumentModel;
}

const reducer = (state: DocumentState, action: DocumentAction): DocumentReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENTS: {
      const { documents, paged, isEmptyList, isLoading,  isAuthorize, model } = action.payload;
      return {
        ...state,
        documents,
        paged,
        isEmptyList,
        isLoading,
        isAuthorize,
        model
      };
    }
    case actionTypes.FETCH_DOCUMENT: {
      const { document, isLoading } = action.payload;
      return {
        ...state,
        document,
        isLoading
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

