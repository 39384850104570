import React, { useEffect } from 'react';
import type { Dispatch, FC } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { SubscriptionModel } from 'src/common';
import type { SubscriptionResource } from 'src/common/types';
import AccessGroupEditForm from './AccessGroupEditForm';
import { useAccessGroup } from 'src/functions/hooks/accessGroup';
import { EditAccessGroup } from 'src/types/accessGroup';
import { Action } from 'src/store/reducers/SubscriptionReducer';

export interface AccessGroupEditViewProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  onEditAccessGroup: (value: EditAccessGroup) => void;
  groupId: number;
  dispatch: Dispatch<Action>;
 }

const AccessGroupEditView: FC<AccessGroupEditViewProps> = ({
  model,
  subscription,
  onEditAccessGroup,
  groupId,
  dispatch
}) => {

  const { group, getGroup, allowedInsert, isLoadingGroup } = useAccessGroup();

  useEffect(() => {
		(async () => {
			await getGroup(groupId, subscription.id);
    })();
	},[])

  if (isLoadingGroup) return null;

  return (
    <Box>
      <AccessGroupEditForm
        allowedInsert={allowedInsert}
        model={model}
        group={group} 
        getGroup={getGroup}
        subscription={subscription} 
        onEditAccessGroup={onEditAccessGroup}
        dispatch={dispatch}
      />
    </Box>
  );
};

AccessGroupEditView.propTypes = {
  onEditAccessGroup: PropTypes.func.isRequired
};

export default AccessGroupEditView;
