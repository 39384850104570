import { EmployeeRole, Forbidden, LIBRARY_ACCESS, Mode, NO_AUTHORIZE_ACCESS_MSG, PathModel } from 'src/common';
import useAuth from 'src/hooks/useAuth';
import { getAuthorize } from 'src/components/Authorize';
import AppModel from './App';
import printMessage from 'src/views/errors/MessageError';
import { CustomerResource, EmployeeUser, SubscriptionProductDto } from 'src/common/types';

export default class AccessModel extends AppModel
{
  private _add:boolean;
  private _edit: boolean;
  private _deleted: boolean;
  private _read: boolean;
  private _home: string;
  private _message: string;
  private _employee: EmployeeUser;
  
  constructor(resourceCode: string){
    super();
    if(resourceCode){
      const authContext = useAuth();
      if(authContext.user){
        this._employee = authContext.user.employee;
        this._add = getAuthorize(this._employee, Mode.add, resourceCode) ?? false;
        this._read = getAuthorize(this._employee, Mode.read, resourceCode) ?? false; 
        this._edit = getAuthorize(this._employee, Mode.edit, resourceCode) ?? false;
        this._deleted = getAuthorize(this._employee, Mode.delete, resourceCode) ?? false;
      }
      this._home = PathModel.Root;
      this._message = NO_AUTHORIZE_ACCESS_MSG;
    }
  }

  get Consultation():boolean{
    return this._read;
  }

  get Add():boolean {
    return this._add;
  }

  get Edition():boolean{
    return this.Consultation && this._edit;
  }

  get Deleted():boolean {
    return this._deleted;
  }

  get Gestion():boolean {
    return this.Add && this.Edition && this.Deleted;
  }

  get Role(){
    if(this._employee){
      return this._employee.role;
    }
  }
  
  get Message(): string {
    return this._message;
  }

  get Home(){
    return this._home;
  }

  get UserAdmin(): boolean{
    return this.Role === EmployeeRole.Admin;
  }

  get UserUser(): boolean{
    return this.Role === EmployeeRole.User;
  }

  get Disabled(): boolean{
    return !(this.UserAdmin || (this.UserUser && this.Edition)) && !(this.UserAdmin || (this.UserUser && this.Gestion))
  }

  canRead(): boolean{
    if(this.UserAdmin) return true;
    let ok: boolean;
    if(this.UserUser && !(ok = this.Consultation)){
      printMessage({
        status: Forbidden,
        message: this.Message
      });
      window.location.href = this.Home;
    }
    return ok;
  }

  canAdd(): boolean{
    if(this.UserAdmin) return true;
    let ok: boolean;
    if(this.UserUser && !(ok = this.Add)){
      printMessage({
        status: Forbidden,
        message: this.Message
      });
      window.location.href = this.Home;
    }
    return ok;
  }

  canEdit(id: string, showMessage: boolean = true): boolean{
    if(this.UserAdmin) return true;
    let ok: boolean;
    if(!id && this.UserUser && !(ok = this.Add)){
      if(showMessage){
          printMessage({
          status: Forbidden,
          message: this.Message
        });
      }
      window.location.href = this.Home;
    }else if(id && this.UserUser && !(ok = this.Edition)){
      if(showMessage){
        printMessage({
          status: Forbidden,
          message: this.Message
        });
      }
      window.location.href = this.Home;
    }
    return ok;
  }

  canDelete(): boolean{
    if(this.UserAdmin) return true;
    let ok: boolean;
    if(this.UserUser && !(ok = this.Deleted)){
      printMessage({
        status: Forbidden,
        message: this.Message
      });
      window.location.href = this.Home;
    }
    return ok;
  }

  canGestion(showMessage:boolean = true): boolean{
    if(this.UserAdmin) return true;
    let ok: boolean;
    if(this.UserUser && !(ok = this.Gestion)){
      if(showMessage){
        printMessage({
          status: Forbidden,
          message: this.Message
        });
      }
      window.location.href = this.Home;
    }
    return ok;
  }

  canEditionGestion(showMessage:boolean = true): boolean{
    if(this.UserAdmin) return true;
    let ok: boolean;
    if(this.UserUser && !(ok = this.Gestion) && !(ok = this.Edition)){
      if(showMessage){
        printMessage({
          status: Forbidden,
          message: this.Message
        });
      }
      window.location.href = this.Home;
    }
    return ok;
  }

  static accessCarteCaij(customer:CustomerResource) {
    let enableCarteCaij = false;
    const {subscriptionGroup, card} = customer
    if(subscriptionGroup){
      const allowedAccessLibrary = AccessModel.accessLibrary(customer);
      if((!allowedAccessLibrary && card) || allowedAccessLibrary){
        enableCarteCaij = true;
      }
    }
    return enableCarteCaij;
  }

  static accessLibrary = (customer:CustomerResource) => customer.subscriptionGroup.products.some((product: SubscriptionProductDto) => product.product.code === LIBRARY_ACCESS);
}
