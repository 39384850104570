import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { DatabankResource } from 'src/common/types';
import DatabankDetailFrInfo from './DatabankDetailFrInfo';
import DatabankDetailEnInfo from './DatabankDetailEnInfo';
import Note from 'src/components/note';
import { DatabankModel } from 'src/common';

interface DetailsProps {
  model: DatabankModel;
  databank: DatabankResource;
}

const Details: FC<DetailsProps> = ({
  model,
  databank
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={12}
      md={12}
      xl={12}
      xs={12}
    >
      <Note allowedHTML note={databank.note} label={model.Note.Label} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <DatabankDetailFrInfo databank={databank} model={model} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <DatabankDetailEnInfo databank={databank} model={model} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  databank: PropTypes.object.isRequired
};

export default Details;
