import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
  TableBody,
  Typography
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  ListType,
  getEmptyListSearch,
  LibraryModel,
  AccessModel,
  dialogTitle,
  dialog,
  Success,
  getOpacity,
  NavigateFunction
} from 'src/common';
import {
  CheckSquare as CheckIcon,
  XSquare as CancelIcon, 
} from 'react-feather';
import type { LibraryResource, SearchFilter } from 'src/common/types';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import Search from 'src/components/search';
import CaijTable from 'src/components/table/CaijTable';
import MyCard from 'src/components/card/MyCard';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import LoadingButton from '@mui/lab/LoadingButton';
import printMessage from 'src/views/errors/MessageError';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  libraries: LibraryResource[];
  onDeleteLibrary: (id: number) => Promise<void>;
  onSychronize: () => Promise<void>;
  isEmptyList: boolean;
  model: LibraryModel;
  navigate: NavigateFunction;
  location: {search: string};
}

//#region Sychronize
const SynchronizeDialog = (props: {setOpen: (value: boolean) => void, onSychronize: () => Promise<void>}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { setOpen, onSychronize } = props;
  return (
    <>
      <DialogTitle sx={dialogTitle}>SYNCHRONISER TOUTES LES BIBLIOTHÈQUES</DialogTitle>
      <DialogContent dividers sx={{...dialog, width:'auto',padding:'15px 15px 0 15px'}}>
        <Box mb={2}>
          <Typography variant='body2' sx={{color: 'text.secondary', mb: '10px'}}>
            La synchronisation des bibliothèques a pour effet de supprimer toutes les bibliothèques du site corpo avant de les réafficher. Cette opération peut prendre jusqu'à 10 minutes.
          </Typography>
          <Typography variant='body2' sx={{color: 'text.secondary'}}>
            Que souhaitez-vous faire?
          </Typography>
        </Box> 
      </DialogContent>
      <DialogActions sx={{...dialog, display: 'flex', justifyContent: 'center'}}>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          startIcon={<SvgIcon fontSize='small'><CheckIcon /></SvgIcon>}
          onClick={async () => {
            setLoading(true);
            onSychronize();
            setLoading(false);
            setOpen(false);
          }}	
        >Synchroniser immédiatement</LoadingButton>
        <Button 
          type='reset' 
          onClick={() => {
            setOpen(false);
            printMessage({status: Success, message: "Aucune synchronisation n'a été effectuée"});
          }} 
          color='secondary' 
          variant='outlined'
          startIcon={(
            <SvgIcon fontSize="small">
              <CancelIcon />
            </SvgIcon>
          )}
        >Annuler</Button>
      </DialogActions>
    </>
  );
};

const Synchronize = (props: {onSychronize: () => Promise<void>}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Box sx={{height: "56", margin: '0 15px 20px 0'}}>
      <Button
        startIcon={
          <SvgIcon>
            <CheckIcon />
          </SvgIcon>
        }
        onClick={() => setOpen(true)}
      >
        Synchroniser toutes les bibliothèques
      </Button>
      <Dialog maxWidth='sm' fullWidth open={open}>
        <SynchronizeDialog setOpen={setOpen} onSychronize={props.onSychronize} />
      </Dialog>
    </Box>
  )
}
//#endregion Synchronize

const Results: FC<ResultsProps> = ({
  libraries,
  onDeleteLibrary,
  onSychronize,
  isEmptyList,
  model,
  navigate,
  location
}) => {
  const path = model.Path;
  const access = new AccessModel(model.ResourceCode);
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState<number>(0);
  const query = new URLSearchParams(location?.search).get("query");
  const headCell = model.getHeadCells(labelConfig.status, labelConfig.type);
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();
  const [filter, setFilter] = useState<SearchFilter>({
    query,
    doSearch: false,
    page
  });
  
  useEffect(() => setOrderBy('nameFr'),[]);

  useEffect(() => {
    const { Filters: { query, page } } = model;
    const queryVal = model.getQueryParam(query);
    const pageVal = +model.getQueryParam(page);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        doSearch: true,
        page: pageVal
      }
    });
    setPage(pageVal);
  },[model]);

  const renderTableCell = (row: LibraryResource) => {
    const opacity = getOpacity(row.enabled);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.nameFr}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.libraryType?.nameFr}</CaijTableCell>
        <CaijTableCellActive active={row.enabled} />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          handleToggle={() => LibraryModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
              dialog={model.Dialog}
              isOpen={selectedRow.indexOf(row.id) !== -1}
              onSubmit={async () => {
                await onDeleteLibrary(row.id)
                setSelectedRow([]);
              }}
              setSelectedRow={setSelectedRow}
            />
        </CaijTableCellAction>
      </>
    )
  }

  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <>
      <MyCard>
        <Box minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
          <Box sx={{margin:'20px 0 20px 15px'}}>
            <Search
              listType={ListType.Library} 
              placeholder='Recherche la/les bibliothèque(s)' 
              width="400px"
              filter={filter}
              path={path.Home}
            />
          </Box>
          { access.UserAdmin && <Synchronize onSychronize={onSychronize} />}
        </Box>
        <CaijTable>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCell}
            notAllowedSort={isEmptyList}
          />
          <TableBody>
            {
              libraries.length === 0 ? getEmptyListSearch(headCell.length) :
              sort<LibraryResource>(libraries, page).map((row: LibraryResource) => (
                  <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                    {renderTableCell(row)}
                  </CaijTableRowClickable>
                ) 
              )
            }
            </TableBody>
        </CaijTable>
        <CaijTablePagination 
          paged={{totalCount: libraries.length}} 
          model={model} 
          filter={filter}  
          page={page}
          navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
        />
      </MyCard>
    </>
  );
};

Results.propTypes = {
  libraries: PropTypes.array.isRequired,
  onDeleteLibrary: PropTypes.func.isRequired,
  onSychronize: PropTypes.func.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  model: PropTypes.instanceOf(LibraryModel)
};

export default Results;
