import { useEffect, useCallback, useReducer, useState } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  _translation,
  fetchTranslations,
  ITEMS_PER_PAGE,
  TranslationModel,
  useNavigate,
  AccessModel,
  root
} from 'src/common';
import type { GetPagedTranslationsAsyncOptionalParams } from 'src/common/types';
import Page from 'src/components/Page';
import Results from './Results';
import printMessage from 'src/views/errors/MessageError';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  translations: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false,
  model: null
};

const TranslationListView: FC = () => {
  const access = new AccessModel(TranslationModel.getInstance().ResourceCode);
  const location = useLocation();
  const [deleted, setDeleted] = useState<number>(0);
  const [state, dispatch] = useReducer(_translation, initialState);
  const navigate = useNavigate();

  const getTraductions = useCallback(async (model: TranslationModel,params:  GetPagedTranslationsAsyncOptionalParams) : Promise<void> =>  {
    const { search, pathname } = location;
    model.PathName = search ? pathname + search : search;
    const results = await model.getTranslations(params);
    if (!model.error) {
      dispatch(fetchTranslations(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0, true, model));
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search } = location;
        const model = new TranslationModel();
        let params: GetPagedTranslationsAsyncOptionalParams = { pageNumber: 1, pageSize: ITEMS_PER_PAGE };
        if(search){
          if(await model.setQueryParams(search, model, navigate)){
            const { Filters: { page, query } } = model;
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1,
              sortOptions: 'asc',
              searchTerm: model.getQueryParam(query)
            }
            await getTraductions.apply(model,[model,params]);
          }
        }else{
          await getTraductions.apply(model,[model,params]);
        }
      }
    })();
  },[location.search, deleted]);

  const deleteTraduction: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      const model = new TranslationModel();
      const response = await model.deleteTranslation(id);
      if (!model.error) {
        printMessage({
          status: response.status, 
          message: response.message
        });
        setDeleted(deleted + 1);
      }
    }
  };

  const { translations, isEmptyList, isLoading, paged, model } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            translations={translations}
            paged={paged}
            isEmptyList={isEmptyList}
            onDeleteTraduction={(id) => deleteTraduction(id)}
            model={model}
            navigate={navigate}
            location={location}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default TranslationListView;
