import { FC, ReactNode } from "react";

interface MainProps {
  children: ReactNode;
}

const Main: FC<MainProps> = ({children}) => {
  return (
    <div style={{ padding: '0 0.5em' }}>{children}</div>
  )
}

export default Main;
