import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Submitting, WfTypeDto } from 'src/common/types';
import SymphonyList from 'src/components/customer/symphony/SymphonyList';
import { Root } from 'src/components/styled';
import { SymLibrary } from 'src/functions/hooks/symphony';
import { SymphonyModel } from 'src/common';

interface LibrarySymphonyProps {
   model: SymphonyModel;
   state: SymLibrary;
   onActivate: (value: WfTypeDto) => void;
   onDeActivate: (value: string) => void;
   submitting: Submitting;
};

const LibrarySymphony : FC<LibrarySymphonyProps> = ({ model, state, onActivate, onDeActivate, submitting }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="left">
            <Root>
                <SymphonyList
                    model={model} 
                    lists={state.libraries} 
                    isLoading={state.isLoading} 
                    isEmptyList={state.isEmptyList} 
                    onActivate={(l: WfTypeDto) => onActivate(l)}
                    onDeActivate={(l:string) => onDeActivate(l)}
                    submitting={submitting}
                />
            </Root>
        </Box> 
    );    
};

export default LibrarySymphony;
