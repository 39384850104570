import type { FC, ChangeEvent, } from 'react';
import PropTypes from 'prop-types';
import type { MessageResource} from 'src/common/types';
import { Box, Grid, Typography} from '@mui/material';
import {
  labelConfig,
  CaijCheckbox,
  CaijInput,
  CaijInputRank,
  FormikErrors,
  FormikTouched,
  MessageModel,
  CaijCard,
  DraftEditor,
  CaijDatePicker
} from 'src/common';
import CaijDateTimePicker from 'src/components/date/CaijDateTimePicker';
import CaijError from 'src/components/CaijError';

interface MessageFormProps {
  model: MessageModel;
  message: MessageResource;
  errors: FormikErrors<MessageResource>;
  touched: FormikTouched<MessageResource>;
  onHandleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: (e: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleChangeCheckBox: (e: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleStartDateChange: (startDate: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleEndDateChange?: (endDate: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const MessageForm: FC<MessageFormProps> = ({
  model,
  message,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  onHandleStartDateChange,
  onHandleEndDateChange,
  setFieldValue,
}) => {
 
  const { name, rank, titleFr, titleEn, messageFr, messageEn, closable, startDate, endDate, visible } = message;
  const { Name, Rank, TitleFr, TitleEn, MessageFr, MessageEn } = model;

  return (
    <>
        <Grid item md={6} xs={12}>
            <CaijInput
                name={Name.Name}
                id={Name.Name}
                required
                label={Name.Label}
                value={name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                InputLabelProps={{ shrink: true, required: true }}
                inputAttr={{ maxLength: Name.MaxLength, 'data-testid': Name.Name }}
                onHandleBlur={onHandleBlur}
                onHandleChange={onHandleChange}
                setFieldValue={setFieldValue}
            />
            <CaijInput
                required
                name={TitleFr.Name}
                id={TitleFr.Name}
                label={TitleFr.Label}
                value={titleFr}
                error={Boolean(touched.titleFr && errors.titleFr)}
                helperText={touched.titleFr && errors.titleFr}
                InputLabelProps={{ shrink: true, required: true }}
                inputAttr={{maxLength: TitleFr.MaxLength, 'data-testid': TitleFr.Name}}
                onHandleChange={onHandleChange}
                onHandleBlur={onHandleBlur}
                setFieldValue={setFieldValue}
            />
            <CaijCard title={MessageFr.Label} sx={{mt:2}} required>
                <DraftEditor
                    dataTestId='messageFr'
                    wrapperClassName="messageFr"
                    value={messageFr}
                    className='demo-wrapper'
                    onEditorChange={l => setFieldValue('messageFr', (l === '\n' || !l) ? '' : l, true)}
                />
                <CaijError>{touched.messageFr && errors.messageFr}</CaijError>
            </CaijCard>
        </Grid>
        <Grid item md={6} xs={12}>
            <CaijInputRank
                required
                name={Rank.Name}
                id={Rank.Name}
                label={Rank.Label}
                value={String(rank)}
                error={Boolean(touched.rank && errors.rank)}
                helperText={touched.rank && errors.rank}
                InputLabelProps={{ shrink: true, required: true }}
                inputProps={{ maxLength: Rank.MaxLength, 'data-testid': Rank.Name }}
                onHandleBlur={onHandleBlur}
                onHandleChange={onHandleChange}
                setFieldValue={setFieldValue}
            />
            <CaijInput
                required
                name={TitleEn.Name}
                id={TitleEn.Name}
                label={TitleEn.Label}
                value={titleEn}
                error={Boolean(touched.titleEn && errors.titleEn)}
                helperText={touched.titleEn && errors.titleEn}
                InputLabelProps={{ shrink: true, required: true }}
                inputAttr={{ maxLength: TitleEn.MaxLength,'data-testid': TitleEn.Name }}
                onHandleChange={onHandleChange}
                onHandleBlur={onHandleBlur}
                setFieldValue={setFieldValue}
            />
            <CaijCard title={MessageEn.Label} sx={{mt:2}} required>
                <DraftEditor
                    dataTestId='messageEn'
                    wrapperClassName="messageEn"
                    value={messageEn}
                    className='demo-wrapper'
                    onEditorChange={l => setFieldValue('messageEn', (l === '\n' || !l) ? '' : l, true)}
                />
                <CaijError>{touched.messageEn && errors.messageEn}</CaijError>
            </CaijCard>
        </Grid>
        <Grid item md={12} xs={12}>
            <CaijCheckbox
                checked={closable}
                label={labelConfig.closable}
                onHandleChangeCheckBox={onHandleChangeCheckBox}
                setFieldValue={setFieldValue}
                inputProps={{ 'aria-label': 'closable', 'data-testid': 'closable' }}
                name='closable'
                id='closable'
                sx={{mb:3}}
            />
            <Box display='flex' alignItems='flex-start' width='100%'>
                <Box mr={2}>
                    <CaijDateTimePicker
                        name='startDate'
                        required
                        label={labelConfig.startDate}
                        value={String(startDate)}
                        inputProps={{'data-testid': 'startDate'}}
                        helperText={touched.startDate && errors.startDate}
                        onHandleChange={(l) => onHandleStartDateChange(l, setFieldValue)}
                    />
                </Box>
                <Box>
                    <CaijDateTimePicker
                        name='endDate'
                        required
                        label={labelConfig.endDate}
                        value={String(endDate)}
                        inputProps={{'data-testid': 'endDate'}}
                        helperText={touched.endDate && errors.endDate}
                        onHandleChange={(l) => onHandleEndDateChange(l, setFieldValue)}
                    />
                </Box>
            </Box>
            <CaijCheckbox
                checked={visible}
                label={labelConfig.publishing}
                onHandleChangeCheckBox={onHandleChangeCheckBox}
                setFieldValue={setFieldValue}
                inputProps={{ 'aria-label': 'visible' }}
                name='visible'
                id='visible'
            />
        </Grid>
    </>
  );
};

MessageForm.propTypes = {
  message: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  onHandleChangeCheckBox: PropTypes.func.isRequired,
  onHandleStartDateChange: PropTypes.func.isRequired,
  onHandleEndDateChange: PropTypes.func
};

export default MessageForm;
