import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Link, SvgIcon, Paper, Typography } from '@mui/material';
import { Link as RouterLink, MessagePlaceholderModel } from 'src/common';
import { Theme, MessagePlaceholderResource } from 'src/common/types';
import { Mail as AllIcon, Eye as ActiveIcon } from 'react-feather';

const card = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '8em',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '1em',
  padding: '1em',
  '&:hover': {
    color: (theme: Theme) => theme.palette?.primary.main,
    textDecoration: 'none',
  }
}

function noDeco() {
  return {
    width: '100%',
    fontSize: '1em',
    textDecoration: 'none',
    '&:hover': {
      color: (theme:any) => theme.palette?.primary.main,
      textDecoration: 'none',
    },
  }
};

export interface PlaceholderListProps {
  model: MessagePlaceholderModel;
  placeholders: MessagePlaceholderResource[];
}

const PlaceholderList: FC<PlaceholderListProps> = ({ model, placeholders }): JSX.Element => {

  const visibleCount = (item: MessagePlaceholderResource) => {
    let count = 0;
    if (item.messages){
      item.messages.forEach(item => {
        if (item.visible === true && new Date(item.endDate) > new Date() && new Date(item.startDate) < new Date()) {
          ++count;
        }
      });
    }
    return count;
  };

  const listRender = () => {
    return placeholders.map(item => {
      return (
        <Grid xs={3} item key={item.id}>
          <Link sx={{...noDeco()}} component={RouterLink} to={model.Path.getDetail(item.id)}>
            <Paper sx={{...card, backgroundColor : theme => theme.palette.background.paper}}>
              <Box>
                <Typography>{item.name}</Typography>
              </Box>
              <Box width='100%' display='flex' height='100%' justifyContent='flex-end' alignItems='flex-end'>
                <Box width='100%' display='flex' height='100%' justifyContent='flex-end' alignItems='flex-end'>
                  <Box display='flex' width='100%' justifyContent='flex-end'>
                    {item.messages && item.messages.length > 0 ? (
                      <Box display='flex' sx={{ marginRight: '0.3em' }}>
                        <SvgIcon sx={{ marginRight: '0.3em' }} fontSize='small'>
                          <ActiveIcon />
                        </SvgIcon>
                        <Typography>{visibleCount(item)}</Typography>
                      </Box>
                    ) : (
                      <Box />
                    )}
                    <SvgIcon sx={{ marginRight: '0.3em' }} fontSize='small'>
                      <AllIcon />
                    </SvgIcon>
                    <Typography>{item.messages?.length}</Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Link>
        </Grid>
      );
      });
  };
  return (
    <Grid item md={12} spacing={2} direction='row' justifyContent='flex-start' container>
      {listRender()}
    </Grid>
  );
};

PlaceholderList.propTypes = {
  placeholders: PropTypes.array.isRequired
};

export default PlaceholderList;
