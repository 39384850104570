import AppModel from "../App";
import { FormModel } from "../Form";

export default class CommunauteJuridiqueModel extends AppModel {
    private static _instance: CommunauteJuridiqueModel;
    readonly Titre = new FormModel('title', 'Titre', 500);
    readonly Url = new FormModel('url', 'Url', 2000);
    readonly Secured = new FormModel('secured', 'Sécurisé');
    readonly Rank = new FormModel('rank', 'Rang', 11);

    constructor() {
        super('/content/document/editor');
        this.initialize();
    }

    private initialize() {
        this._resourceCode = "COMMUNAUTE_JURIDIQUE";
        this._headerTitle = "Éditer le document html pour Communauté Juridique";
        this.Path.PathName = '/contents/legal-community'
    }

    get Section() {
        return {
            resourceCode: this._resourceCode,
            title: "Communauté Juridique",
            href: this.Path.Home,
            visible: true
        }
    }

    static getInstance(): CommunauteJuridiqueModel {
        if (!CommunauteJuridiqueModel._instance) {
            CommunauteJuridiqueModel._instance = new CommunauteJuridiqueModel();
        }
        return CommunauteJuridiqueModel._instance;
    }
}