import { useEffect, useState } from "react";
import { SubscriptionModel } from "src/common";
import { GroupDto, SubscriptionResource } from "src/common/types";
import { AccessGroup } from "src/types/subscription";

const removeCommaAtTheEndString = (groups: AccessGroup) => {
	for(let prop in groups) groups[prop] = groups[prop].slice(0,-1);
}

const initialiseGroup = (groups: GroupDto[], newGroups: AccessGroup) => groups.forEach(({id, name}) => !newGroups[name] ? newGroups[name] = `${id.toString()},` : newGroups[name] += `${id.toString()},`);

export function useCustomerList() {
	const [groups, setGroups] = useState<AccessGroup>({});
  const [subscriptions, setSubscriptions] = useState<SubscriptionResource[]>([]);

	const resetGroups = (Ids: string) => {
		const groups: AccessGroup = {};
		if(Ids){
			const ids = Ids.split(',');
			for(let id of ids){
				const subscription = [...subscriptions].find((subscription: SubscriptionResource) => subscription.id === +id)
				subscription ? initialiseGroup(subscription.groups, groups) : '';
			}
			removeCommaAtTheEndString(groups);
		}
		setGroups(groups);
		return groups;
  }

	useEffect(() => {
    let isCurrent: boolean = true;
		const initialise = async () : Promise<void> => {
			const model = new SubscriptionModel();
			model.skipHandleError = true;
      const sbs = isCurrent && await model.getSubscriptions();
      setSubscriptions(sbs);
		};
		(async () => await initialise())();
		return () => { isCurrent = false;};
	}, []);
	return { subscriptions, groups, resetGroups };
}
