import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Grid } from '@mui/material';
import {
  Formik,
  handleChange,
  CaijButtonReset,
  CaijButtonSubmit,
  TribunalTypeModel,
  Authorize,
  Mode,
  btnSubmit,
  btnReset
} from 'src/common';
import type {
  TribunalTypeResource,
  TribunalTypeResourceForCreate,
  TribunalTypeResourceForEdit,
  Error,
  CreateResponse
} from 'src/common/types';
import TribunalTypeForm from 'src/components/content/caseLaw/tribunalType/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';

export interface TribunalTypeEditFormProps {
  model: TribunalTypeModel;
  tribunalType: TribunalTypeResource;
  onSubmit?: (values:TribunalTypeResource) => void; 
}

async function redirect(model: TribunalTypeModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const TribunalTypeEditForm: FC<TribunalTypeEditFormProps> = ({
  model,
  tribunalType,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const handleSubmit = async (values: TribunalTypeResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new TribunalTypeModel();
    let submitData = values as TribunalTypeResourceForEdit;
    if (submitData && submitData.id) {
      const response = await model.updateTribunalType(submitData);
      if (!model.error){
        await redirect(model, response);
      }
    } else {
      submitData = values as TribunalTypeResourceForCreate;
      delete submitData.id;
      const response = await model.insertTribunalType(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <Formik
      initialValues={TribunalTypeModel.getInitialValues(tribunalType)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                <TribunalTypeForm 
                   model={model}
                   tribunalType={values}
                   errors={errors}
                   touched={touched}
                   handleBlur={handleBlur}
                   onHandleChange={handleChange}
                   setFieldValue={setFieldValue}
                />
              </Grid>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={tribunalType.id ? Mode.edit : Mode.add}
                onIsAuth={(l) => setIsAuth(l)}
              >
                <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={{...btnSubmit, mt: 1 }} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} sx={btnReset} />
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

TribunalTypeEditForm.propTypes = {
  tribunalType: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

TribunalTypeEditForm.defaultProps = {
  onSubmit: null
};

export default TribunalTypeEditForm;
