import { DocumentLockResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchHtmlContent = (content: string, isLoading: boolean): {
    type: "FETCH_HTML_CONTENT",
    payload: {
        content: string;
        isLoading: boolean;
    }
}  => ({
    type: actionTypes.FETCH_HTML_CONTENT,
    payload: {
        content,
        isLoading
    }
})

export const fetchHtmlContentLock = (documentLock: DocumentLockResource): {
    type: "FETCH_HTML_CONTENT_LOCK",
    payload: {
        documentLock: DocumentLockResource;
    }
} => ({
    type: actionTypes.FETCH_HTML_CONTENT_LOCK,
    payload: {
        documentLock
    }
})