import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {$enum} from "ts-enum-util";
import {
  Box,
  Button,
  SvgIcon,
  TableBody
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  AccessType,
  DocCollectionModel,
  getOpacity
} from 'src/common';
import EnhancedTableHead from 'src/components/EnhancedTableHead';
import type { DocumentCollectionResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import { CheckSquare as CheckIcon } from 'react-feather';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import printMessage from 'src/views/errors/MessageError';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: DocCollectionModel;
  collections: DocumentCollectionResource[];
  onDeleteDocCollection: (id: number) => Promise<void>;
  isEmptyList: boolean;
}

const Results: FC<ResultsProps> = ({
  model,
  collections,
  onDeleteDocCollection,
  isEmptyList
}) => {
  const path = model.Path;
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();
  
  useEffect(() => setOrderBy('nameFr'),[]);
  
  const renderTableCell = (row: DocumentCollectionResource) => {
    const opacity = getOpacity(row.active);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.code}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.nameFr}</CaijTableCell>
        <CaijTableCell opacity={opacity} whiteSpace='pre-wrap'>{row.descriptionFr}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{$enum(AccessType).getValueOrDefault(row.access)}</CaijTableCell>
        <CaijTableCellActive active={row.active} />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          handleToggle={() => DocCollectionModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.id) !== -1}
            onSubmit={async () => {
              await onDeleteDocCollection(row.id);
              setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
        </CaijTableCellAction>
      </>
    )
  }

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <Box display='flex' flexDirection='row-reverse' justifyContent='space-between' sx={{margin:'20px 15px'}}>
        <Box sx={{height: "56", marginBottom: '20px'}}>
          <Button
            startIcon={
              <SvgIcon>
                <CheckIcon />
              </SvgIcon>
            }
            onClick={async () => {
              const results = await model.clearAllSearchTokens();
              if(!model.error){
                const { data, status, message } = results;
                if(data)
                  printMessage({status: status, message: message});
                else
                  printMessage({message: "Les jetons de recherche n'ont pu être réinitialisés."});
              }
            }}
          >
            RÉINITIALISER LES JETONS DE RECHERCHE
          </Button>
        </Box>
      </Box>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={model.getHeadCells(labelConfig.status)}
        />
        <TableBody>
          { sort<DocumentCollectionResource>(collections, page).map((row: DocumentCollectionResource) => (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  {renderTableCell(row)}
                </CaijTableRowClickable>
              ) 
            )
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight 
        len={collections.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  collections: PropTypes.array.isRequired,
  onDeleteDocCollection: PropTypes.func.isRequired,
  isEmptyList: PropTypes.bool.isRequired
};

export default Results;
