import { forwardRef, useState } from 'react';
import type { 
  LibraryTypeResource, 
  CustomerResource, 
  DatabankItemResource, 
  DocumentCollectionItemResource, 
  DatabankResource, 
  DocumentCollectionResource, 
  SubscriptionResource, 
  LibraryServiceLibraryOptionsDto, 
  SubscriptionProductResource, 
  ProductItemResource,
  ProductResource,
  LibraryServiceResource,
  LibraryItemResource
} from 'src/common/types';
import {
  Collapse, 
  ListItem,
  Typography, 
  ListItemIcon, 
  TableRow,
  TableCell,
  ClassNameMap,
  Grid,
  Divider,
  Box,
  List
} from '@mui/material';
import { Box as BoxIcon } from 'react-feather';
import { 
  Link as RouterLink,
  ListType,
  Label,
  CARD_HEADER_TITLE_COLLECTION,
  CARD_HEADER_TITLE_DATABANK,
  CARD_HEADER_TITLE_PRODUCT_SERVICE,
  CARD_HEADER_TITLE_SUBSCRIPTION,
  EXCLUS_CARD_HEADER,
  INCLUS_CARD_HEADER,
  CARD_HEADER_TITLE_SUBSCRIPTION2,
  CustomerModel,
  CARD_HEADER_TITLE_REMOTE_ACCESS_DATABANK,
  CARD_HEADER_TITLE_LIBRARY,
  ProductModel,
  DocCollectionModel,
  CARD_HEADER_TITLE_DOCUMENT_TYPE
} from 'src/common';
import { NavigateFunction } from 'react-router';
import MyCard from 'src/components/card/MyCard';
import CardHeaderListInfo from 'src/components/card/CardHeaderListInfo';
import CaijListItemText from 'src/components/CaijListItemText';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

export interface Size {
  md: number;
  xs: number;
  lg: number;
};

type TypeListArray =  LibraryServiceLibraryOptionsDto[] | SubscriptionProductResource[] | ProductItemResource[] | DatabankItemResource[] | DocumentCollectionItemResource[] | number[];
type TypeDataArray =  LibraryServiceResource[] | SubscriptionResource[] | ProductResource[] | DatabankResource[] | DocumentCollectionResource[] | SubscriptionResource[];

export interface Result {
  success: boolean,
  message: JSX.Element
}

export interface TypeList {
  id?: number | string;
  sx?: any;
  path?: string;
  name?: string;
  accessType?: string;
  lists?: TypeListArray;
  listType?: ListType,
  active?: boolean;
  appType?: string;
  allowedAccessType?: boolean;
  parameters?: string | number;
}

// #region ListInfo
export const ListInfos = () => (function() {
  return {
    getMessageError: function(message: string) : JSX.Element {
      return (
        <ListItem>
          <Typography variant="body2" sx={{color: 'text.error'}}>{message}</Typography>
        </ListItem>
      )
    },
    isListValid: function(data: TypeDataArray | TypeListArray, text: string) : Result {
      if(!Array.isArray(data)){
        return {
          message: this.getMessageError('Données invalides'),
          success: false
        }
      }else if(data.length === 0){
        return {
          message: this.getMessageError(text),
          success: false
          }
      }
      return { success: true, message: null };
    },
    getList: function ({ id, path, name, sx, accessType, listType, active, appType} : TypeList) : JSX.Element {
      return (
        <ListItem 
          sx={sx}
          key={id}
          component={RouterLink} 
          to={path} 
          target="_blank"
        >
          <ListItemIcon>
            <BoxIcon />
          </ListItemIcon>
        {
          (listType === ListType.Collection || listType === ListType.CustomerCollection) ? (
            <CaijListItemText 
              name={name} 
              active={active} 
              allowedAccessType={true}
              accessType={DocCollectionModel.getAccessTypeByKey(accessType)}
              color="secondary" 
            />
          ) : (
            <CaijListItemText name={name} active={active} color="secondary" appType={appType}/>
          )
        }
        </ListItem>
      )
    },
    getRequiredList: function ({ id, path, name, accessType , allowedAccessType, sx, active, parameters } : TypeList) : JSX.Element {
      return (
        <ListItem 
          sx={sx} 
          key={id}
          component={RouterLink} 
          to={path} 
          target="_blank"
        >
          <ListItemIcon>
            <BoxIcon />
          </ListItemIcon>
            <CaijListItemText 
              name={name} 
              active={active}
              allowedAccessType={allowedAccessType}
              accessType={ProductModel.getAccessTypeByKey(accessType)}  
              color="secondary" 
            />
            { parameters && (
                <Label color="success">
                  {parameters}
                </Label>
              ) 
            } 
        </ListItem>
      )
    },
    renderExclusList: function(exclusList: JSX.Element | JSX.Element[], size: Size) : JSX.Element {
      const { md, xs, lg } = size;
      return (
        <Grid item md={md} xs={xs} lg={lg}>
          <MyCard>
            <CardHeaderListInfo title={EXCLUS_CARD_HEADER} />
            <Divider />
              <Box display="flex" flexDirection="column">
                <List component="nav" aria-label="secondary">
                  { exclusList }
                </List>
              </Box>
          </MyCard>
        </Grid>
        )
    },
    renderInclusList: function(inclusList: JSX.Element | JSX.Element[], size: Size) : JSX.Element {
      const { md, xs, lg } = size;
      return (
        <Grid item md={md} xs={xs} lg={lg}>
          <MyCard>
            <CardHeaderListInfo title={INCLUS_CARD_HEADER} />
            <Divider />
              <Box display="flex" flexDirection="column">
                <List component="nav" aria-label="secondary">
                  { inclusList }
                </List>
              </Box>
          </MyCard>
        </Grid>
      );
    },
    renderBothList: function(exclusList: JSX.Element | JSX.Element[], inclusList: JSX.Element | JSX.Element[]) : JSX.Element {
      const size: Size = { md: 6,xs: 12,lg: 6 };
      return (
        <>
        {
          this.renderExclusList(exclusList, size)
        }
        {
          this.renderInclusList(inclusList, size)
        }
        </>
      )
    },
    renderCheckListTitle: function(listType: ListType): string {
      switch(listType){
        case ListType.CollectionGroupAccess:
        case ListType.Collection:
          return CARD_HEADER_TITLE_COLLECTION; 
        case ListType.DatabankGroupAcccess:
        case ListType.Databank:
          return CARD_HEADER_TITLE_REMOTE_ACCESS_DATABANK;
        case ListType.DatabankLibrary:
          return CARD_HEADER_TITLE_DATABANK;
        case ListType.Subscription:
        case ListType.CollectionSubscription:
          return CARD_HEADER_TITLE_SUBSCRIPTION2;
        case ListType.DocumentType:
          return CARD_HEADER_TITLE_DOCUMENT_TYPE;
      }
    },
    renderRequiredParameterTitle: function (listType: ListType): string {
      switch(listType){
        case ListType.ProductGroupAccess:
        case ListType.Product:
          return CARD_HEADER_TITLE_PRODUCT_SERVICE; 
        case ListType.LibraryService: 
          return CARD_HEADER_TITLE_PRODUCT_SERVICE;
        case ListType.Subscription:
          return CARD_HEADER_TITLE_SUBSCRIPTION;
        case ListType.LibraryServiceLibrary:
          return CARD_HEADER_TITLE_LIBRARY
      }
    },
    compareSelectedLists: <U, T>(selectedItems: U[], items: T[]) => !(selectedItems?.length === items?.length),
    selectAll: function<T, R>(hasSelected: boolean, items: T[], fn: (value: R[]) => void, prop: string = "id") : void {
      let idx = 0;
      if(hasSelected){
        const selectedItems = [];
        new Promise( (resolve) => {
          items.forEach((item: T) => {
            selectedItems.push(item[prop]);
            idx++;
            idx == items.length - 1 ? resolve(selectedItems) : '';
          });
        }).then((results: R[]) => fn(results));
      }else{
        fn([]);
      }
    }
  }
})();
//#endregion

export const getEmptyListSearch = (numCols: number, classes?: ClassNameMap<'removeBottomLineTable'>): JSX.Element => (
  <>
    <TableRow className={classes ? classes.removeBottomLineTable : ''}>
      <TableCell colSpan={numCols} align='center'>
          <Typography sx={{color:'text.error'}} variant="body2">Aucune donnée trouvée</Typography>
      </TableCell>
    </TableRow>
  </>
);

export const setEditHash = (text: string, navigate: NavigateFunction) => {
  const hash = location.hash;
  if(text){
    let value = `#${text}`;
    if(hash){
      value += `-${hash.substring(1, hash.length)}`;
    }
    navigate(value, { replace: true });
  }
}

export const setHash = (text: string, navigate: NavigateFunction, subscriptionPath?: string) => {
  if(text){
    if(subscriptionPath){
      navigate(`${subscriptionPath}#${text}`, { replace: true });
    }else{
      navigate(`#${text}`, { replace: true });
    }
  }
}

export const renderLibraryTypeMenuItem = (isAuthorize: boolean, libraryTypes: LibraryTypeResource[]): JSX.Element[] => {
  if(isAuthorize && Array.isArray(libraryTypes)) {
    return libraryTypes.map((libraryType: LibraryTypeResource) => (
      <option value={libraryType.id} key={libraryType.id}>
        {libraryType.nameFr}
      </option>
    ));
  }
}

export const getMessageError = (msg: string, withCollapse?: boolean, open?: boolean): JSX.Element => {
  if(withCollapse) {
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem>
          <Typography variant="body2" sx={{color:"text.error"}}>{ msg }</Typography>
        </ListItem>
      </Collapse>
    )
  } else {
    return (
      <ListItem>
        <Typography variant="body2" sx={{color:"text.error"}}>{ msg }</Typography>
      </ListItem>
    )
  }
}

export const useToggle = (initialVal = false): [boolean, () => void] => {
   const [toggle, setState] = useState(initialVal);
   const setToggle = () => {
     setState(!toggle);
   };
   return [toggle, setToggle];
}

export const printErrorMessageEmail = (customer: CustomerResource, emailError: string, emailTouched: boolean): JSX.Element => {
  if(emailError && emailTouched){
      if(emailError.indexOf('existe déjà') !== -1) {
        return (
          <span style={{ marginLeft: '0.5em', fontSize: '1em', color: 'text.error' }}>
               Cette adresse courriel est déjà utlisée par{' '}
              <a href={CustomerModel.getInstance().Path.getDetail(customer.id)} style={{fontSize: '0.88em', textDecoration:'none', color: '#F44336', fontStyle:'italic'}} target='blank'>
                {`${customer.firstname} ${customer.lastname}`}
              </a>
          </span>
        )
      } else{
        return (
          <span style={{ marginLeft: '0.5em', fontSize: '1em', color: 'text.error' }}>
            {emailError}
          </span>
        )
      }
   }
};

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});















