export const grayed = {
    color: '#808080',
};

export const container = {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center'
};

export const padding ={
    padding: '1em',
};

export const big = {
    fontSize: '1.7em',
};

export const rightContainer = {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: '0.5em',
    alignItems: 'center',
    justifyContent: 'flex-start',
};

export const squareBtn = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3.5em',
    width: '3.5em',
    border: '1px solid rgba(90, 80, 150 ,0.5)',
    borderRadius: '0.3em',
};

export const box = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginRight: '2em',
    padding: '2em',
};

export const row = {
    display: 'flex',
};

export const darker = {
    backgroundColor: (theme:any) => theme.palette.background.dark,
    marginRight: '1em',
    width: 'inherit',
};

export const bigAndBold = {
    padding: '0.5em',
    fontWeight: 'bold',
    fontSize: '1.4em',
};

export const bold = {
    display: 'flex',
    fontWeight: 'bold',
};

export const flex = {
    marginTop: '1em',
    display: 'flex',
    width: 'auto',
};

export const date = {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
};

export const messageTxt = {
    paddingLeft: '1em',
    marginTop: '0.5em',
    display: 'flex',
    fontSize: '1.1em',
};

export const box2 = {
    display: 'flex',
    flexDirection: 'column',
};

export const btnReturn = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 0 0 0.8em',
    fontSize: '1em',
  };
