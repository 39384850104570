import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  Typography,
  Table, 
  TableBody, 
  TableRow, 
  TableCell, 
  Divider 
} from '@mui/material';
import type { EmailTemplateDto } from 'src/common/types';
import { EmailTemplateModel, labelConfig, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/emailTemplate';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import parse from 'html-react-parser';

export interface EmailTemplateEnInfoProps {
  model: EmailTemplateModel;
  emailTemplate: EmailTemplateDto;
}

const EmailTemplateEnInfo: FC<EmailTemplateEnInfoProps> = ({ model, emailTemplate }) => {
  const { SubjectEn } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Email template" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>{SubjectEn.Label}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {emailTemplate.subjectEn}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>{labelConfig.messageEn}</TableCell>
            <TableCell>
              {
                emailTemplate.contentEn && (
                  <div>{parse(emailTemplate.contentEn)}</div>
                )
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

EmailTemplateEnInfo.propTypes = {
  emailTemplate: PropTypes.object.isRequired
};

export default EmailTemplateEnInfo;
