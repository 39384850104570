import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { LawDomainFieldResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { LawDomainFieldModel, tableRow } from 'src/common';
import { tableCell } from 'src/styles/lawDomainField';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow } from 'src/components/table/CaijTableRow';

export interface LawDomainFieldEnInfoProps {
  model: LawDomainFieldModel;
  lawDomainField: LawDomainFieldResource;
}

const LawDomainFieldEnInfo: FC<LawDomainFieldEnInfoProps> = ({
  model,
  lawDomainField
}) => {
  return (
    <MyCard>
      <CardHeaderDetail title="Law domain" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableRow={tableRow} sxTableCell={tableCell} label={model.NameEn.Label}>
            {lawDomainField.nameEn}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LawDomainFieldEnInfo.propTypes = {
  lawDomainField: PropTypes.object.isRequired
};

export default LawDomainFieldEnInfo;
