import type { WfLibraryDto, WfProfileDto } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchLibrariesList = (
  libraries: WfLibraryDto[],
  isLoading?: boolean,
  isEmptyList?: boolean
): {
  type: 'FETCH_LIBRARIES_SYMPHONY';
  payload: { libraries: WfLibraryDto[]; isLoading?: boolean; isEmptyList?: boolean; };
} => ({
  type: actionTypes.FETCH_LIBRARIES_SYMPHONY,
  payload: {
    libraries,
    isLoading,
    isEmptyList
  },
});

export const fetchProfilesList = (
  profiles: WfProfileDto[],
  isLoading?: boolean,
  isEmptyList?: boolean
): {
  type: 'FETCH_PROFILES_SYMPHONY';
  payload: { profiles: WfProfileDto[]; isLoading?: boolean; isEmptyList?: boolean; };
} => ({
  type: actionTypes.FETCH_PROFILES_SYMPHONY,
  payload: {
    profiles,
    isLoading,
    isEmptyList
  },
});


