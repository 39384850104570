import PropTypes from 'prop-types';
import type { FC } from 'react';
import { CustomerModel } from 'src/common';
import type { CustomerResource } from 'src/common/types';
import CustomerLawPraticeFieldForm from './Forms/CustomerLawPraticeFieldForm';

interface CustomerLawPraticeFieldInfoProps {
  model: CustomerModel;
  customer: CustomerResource;
  editMode?: boolean;
}

const CustomerLawPraticeFieldInfo: FC<CustomerLawPraticeFieldInfoProps> = ({
  model,
  customer,
  editMode
}) => {
  return (
    <>
      <CustomerLawPraticeFieldForm
        model={model}
        editMode={editMode}
        customer={customer}
      />
    </>
  );
};

CustomerLawPraticeFieldInfo.propTypes = {
  customer: PropTypes.object.isRequired
};

CustomerLawPraticeFieldInfo.defaultProps = {
  editMode: false
};

export default CustomerLawPraticeFieldInfo;
