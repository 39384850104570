import React, { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';
import {
  _subscription,
  ListType
} from 'src/common';
import type { 
  SubscriptionGroupResource, 
  SubscriptionResource 
} from 'src/common/types';
import CheckList from 'src/components/listInfo/checkList';
import RequireParameterList from 'src/components/requiredParameter';
import Memo from '../memo';

interface TypeSelected {
  ids?:number[],
  selected?: boolean
};

type SubscriptionAccessGroupProps = {
  subscription: SubscriptionResource,
  group: SubscriptionGroupResource,
  setSelectedGroupProducts: Dispatch<SetStateAction<TypeSelected>>,
  selectedGroupProducts: TypeSelected,
  setSelectedGroupDatabanks: Dispatch<SetStateAction<TypeSelected>>,
  selectedGroupDatabanks: TypeSelected,
  setSelectedGroupDocCollections: Dispatch<SetStateAction<TypeSelected>>,
  selectedGroupDocCollections: TypeSelected,
  setSelectedGroupParameters: Dispatch<SetStateAction<Map<number, string>>>
}

class SubscriptionAccessGroup extends React.Component<SubscriptionAccessGroupProps> {
  render() {
    return(
      <>
        <Box mb={2}>
          <Memo>
            <RequireParameterList
              listType={ListType.ProductGroupAccess}
              listIncluded={this.props.group.products}
              lists={this.props.subscription.products}
              onHandleItemsSelected={l =>
                this.props.setSelectedGroupProducts({
                  ...this.props.selectedGroupProducts,
                  ids: l as number[],
                  selected: true,
                })}
              onHandleRequireParmeters={l => this.props.setSelectedGroupParameters(l)}
            />
          </Memo>
        </Box>
        <Box mb={2}>
          <Memo>
            <CheckList
              listType={ListType.DatabankGroupAcccess}
              listIncluded={this.props.group.databanks}
              lists={this.props.subscription.databanks}
              onHandleSelectedItems={l =>
                this.props.setSelectedGroupDatabanks({
                  ...this.props.selectedGroupDatabanks,
                  ids: l as number[],
                  selected: true,
                })
              }
            />
          </Memo>
        </Box>
        <Box mb={2}>
          <Memo>
            <CheckList
              listType={ListType.CollectionGroupAccess}
              listIncluded={this.props.group.documentCollections}
              lists={this.props.subscription.documentCollections}
              onHandleSelectedItems={l =>
                this.props.setSelectedGroupDocCollections({
                  ...this.props.selectedGroupDocCollections,
                  ids: l as number[],
                  selected: true,
                })
              }
            />
          </Memo>
        </Box>
      </>
    );
  }
};

export default SubscriptionAccessGroup;
