import { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  FormikErrors, 
  FormikTouched, 
  LibraryModel 
} from 'src/common';
import type { LibraryResource } from 'src/common/types';
import { Grid } from '@mui/material';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';

interface StaffProps {
  model: LibraryModel;
  library: LibraryResource, 
  errors: FormikErrors<LibraryResource>;
  touched: FormikTouched<LibraryResource>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const Staff: FC<StaffProps> = ({ 
  model,
  library,
  errors,
  touched,
  setFieldValue 
}) => {
  const { StaffFr, StaffEn } = model;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
           title={StaffFr.Label}
           dataTestId={StaffFr.Name}
           value={library.staffFr}
           name={StaffFr.Name}
           touched={touched.staffFr}
           setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={StaffEn.Label}
          dataTestId={StaffEn.Name}
          value={library.staffEn}
          name={StaffEn.Name}
          touched={touched.staffEn}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
}

Staff.propTypes = {
  library: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default Staff;