import type { DocumentTypeResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchDocumentTypesAction = {
    type: 'FETCH_DOCUMENT_TYPES';
    payload: {
      documentTypes: DocumentTypeResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
}

type FetchDocumentTypeAction = {
  type: 'FETCH_DOCUMENT_TYPE';
  payload: {
    documentType: DocumentTypeResource;
    isLoading?: boolean;
  }
};

type FetchDetailsDocumentTypeAction = {
  type: 'FETCH_DETAILS_DOCUMENT_TYPE';
  payload: {
    documentType: DocumentTypeResource;
  }
};

type DeleteDocumentTypeAction = {
  type: 'DELETE_DOCUMENT_TYPE';
  payload: {
    code: string;
  }
};

type Action = FetchDocumentTypesAction |
              FetchDocumentTypeAction |
              FetchDetailsDocumentTypeAction |
              DeleteDocumentTypeAction;

interface DocumentTypeReturnState {
  documentTypes?: DocumentTypeResource[];
  documentType?: DocumentTypeResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface DocumentTypeState {
  documentTypes: DocumentTypeResource[];
  documentType: DocumentTypeResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: DocumentTypeState, action: Action): DocumentTypeReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_TYPES: {
      const { documentTypes, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        documentTypes,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_DOCUMENT_TYPE: {
      const { documentType, isLoading } = action.payload;
      return {
        ...state,
        documentType,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_DOCUMENT_TYPE: {
      const { documentType } = action.payload;
      return {
        ...state,
        documentType
      };
    }
    case actionTypes.DELETE_DOCUMENT_TYPE: {
      return {
        ...state,
        documentTypes: [...state.documentTypes].filter(({code}) => code !== action.payload.code)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer

