import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

interface CaijTypographyProps {
  variant? : 'body1'
  | 'body2'
  | 'button'
  | 'caption'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'inherit'
  | 'overline'
  | 'subtitle1'
  | 'subtitle2';
  align?: 'center'
  | 'inherit'
  | 'justify'
  | 'left'
  | 'right';
  sx?: any;
  noWrap?: boolean;
  children: ReactNode;
  [x: string]: any;
}

const CaijTypography: FC< CaijTypographyProps> = ({children, ...rest}) => {
  return <Typography {...rest}>{children}</Typography> 
};

CaijTypography.propTypes = {
  children: PropTypes.node
};

export default CaijTypography;