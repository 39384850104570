import { CustomerModel } from "src/common";

export default class PathModel
{
  private _pathName: string;
  readonly _baseUrl: string = '/app/management';
  private static readonly _root: string = '/';

  static get Root(){
    return PathModel._root;
  }

  set PathName(pathName: string){
    this._pathName = pathName;
  }

  get PathName(){
    return this._pathName;
  }

  get Home(){
    return this._baseUrl + this.PathName;
  }

  get Create(){
    return this._baseUrl + this.PathName + '/create';
  }
  
  getEdit(id: number | string){
    return this._baseUrl + this.PathName + '/' + id + '/edit';
  }

  getDetail(id: number | string, wc: string = ''){
    return wc ? this._baseUrl + this.PathName + wc + '/' + id : this._baseUrl + this.PathName + '/' + id;
  }

  getEditCustomerProfile(id: number){
    return this._baseUrl + (new CustomerModel).Path.PathName + '/' + id + '#edit-profile';
  }

  getSubscriptionDetail(id: number){
    return this._baseUrl + this.PathName + '/' + id;
  }

  getSubscriptionGroupList(id: number){
    return this._baseUrl + this.PathName + '/' + id + '/' + '#groups';
  }

  getSubscriptionGroupDetail(id: number, groupId: number){
    return this._baseUrl + this.PathName + '/' + id + '/group/' + groupId + '#members';
  }

  getSubscriptionGroupEdit(id: number, groupId: number){
    return this._baseUrl + this.PathName + '/' + id + '/group/' + groupId + '/edit/#members';
  }
}