import React from "react";
import { Typography } from "@mui/material";

const Version = () => {

  const build = process.env.REACT_APP_BUILD || 'local';
  const commit = process.env.REACT_APP_COMMIT || 'local';

  return (
    <div style={{ textAlign: 'center',marginTop: '5px'}}>
      <Typography color="textSecondary" variant="caption">
        Build: {build} Commit: {commit.substr(0,7)}
      </Typography>
    </div>
  );

}

export default Version;