import type { DatabankResource } from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchDatabanks = (
  databanks: DatabankResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
): {
  type: 'FETCH_DATABANKS';
  payload: { databanks: DatabankResource[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean };
} => ({
  type: actionTypes.FETCH_DATABANKS,
  payload: {
    databanks,
    isLoading,
    isEmptyList,
    isAuthorize,
  },
});

export const fetchDatabank = (databank: DatabankResource, isLoading?: boolean) : {
  type: 'FETCH_DATABANK';
  payload: {
    databank: DatabankResource,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_DATABANK,
  payload: {
    databank,
    isLoading
  },
});

export const fetchDetailsDatabank = (
  databank: DatabankResource,
): {
  type: 'FETCH_DETAILS_DATABANK';
  payload: {
    databank: DatabankResource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_DATABANK,
  payload: {
    databank,
  },
});

export const deleteDatabank = (id:number) : {
  type: 'DELETE_DATABANK';
  payload: {
    id: number;
  };
} => ({
  type: actionTypes.DELETE_DATABANK,
  payload: {id},
});
