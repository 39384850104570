import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography
} from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import AzureAdLogin from './AzureAdLogin';
import Version from 'src/components/Version';
import CaijImage from 'src/components/image/CaijImage';
import { Theme } from 'src/theme';
import AppModel from 'src/model/App';

const methodIcons = {
  Auth0: '/static/images/auth0.svg',
  FirebaseAuth: '/static/images/firebase.svg',
  JWT: '/static/images/jwt.svg',
  AzureAd: '/static/images/windows.svg'
};

const root = {
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
};
  
const banner = {
  backgroundColor: (theme: Theme) => theme.palette.background.default,
  paddingBottom: (theme: Theme) => theme.spacing(1),
  paddingTop: (theme: Theme) => theme.spacing(1),
  borderBottom: (theme: Theme) => `1px solid ${theme.palette.divider}`
};

const currentMethodIcon = {
  color: 'red',
  height: '40px',
  '& > img': {
    width: 'auto',
    maxHeight: '100%'
  }
}

const cardContainer = {
   paddingTop: (theme: Theme) => theme.spacing(10)
};

const cardContent = {
  padding: (theme: Theme) => theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  minHeight: 400
};

const LoginView: FC = () => {
  const { method } = useAuth() as any;
  return (
    <Page
      sx={root}
      title="Login"
    >
      <Box sx={banner}>
        <Container>
          <Typography
            align="center"
            sx={{color:"#ffffff", fontSize: 33}}
          >
            {AppModel.ADMIN_CAIJ_TITLE}
          </Typography>
        </Container>
      </Box>
      <Container
        sx={cardContainer}
        maxWidth="sm"
      >
        <Box
          mb={8}
          display="flex"
          justifyContent="center"
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
        </Box>
        <Card>
          <CardContent sx={{...cardContent, backgroundColor: (theme: Theme) => theme.palette.background.paper}}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div>
                <Typography
                  sx={{ color: "text.primary", fontSize: 28}}
                  gutterBottom
                >
                  Authentification
                </Typography>
                <Typography
                  variant="body2"
                  sx={{color:"text.secondary"}}
                >
                  Veuillez vous identifier
                </Typography>
              </div>
              <div style={currentMethodIcon}>
                <CaijImage style={{width: '40px'}} src={methodIcons[method]} alt="Auth method" />
              </div>
            </Box>
            <Box
              flexGrow={1}
              mt={3}
            >
              {method === 'AzureAd' && <AzureAdLogin /> }
            </Box>
            <Box my={3}>
              <Divider />
              <Version />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default LoginView;
