import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useNavigate,
  AccessModel,
  UxpertiseModel,
  _uxpertise,
  fetchUxpertises
} from 'src/common';
import { 
  Theme, 
  UxpertiseDto, 
  CreateResponse, 
  Error
} from 'src/common/types';
import Page from 'src/components/Page';
import Results from './Results';
import printMessage from 'src/views/errors/MessageError';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const root = {
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  minHeight: '100%',
  paddingTop: (theme: Theme) => theme.spacing(3),
  paddingBottom: (theme: Theme) => theme.spacing(3)
}

const initialState = {
  uxpertises: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false
};

const getMessage = (model: UxpertiseModel, response: Error | CreateResponse) : boolean => {
  if (!model.error) {
    printMessage({
      status: response.status,
      message: response.message
    });
    return true
  }
  return false;
}

const UxpertiseListView: FC = () => {
 
  const model = UxpertiseModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_uxpertise, initialState);
  
  const getUxpertiseList = useCallback(async () : Promise<void> => {
    model.PathName = location.pathname;
    const uxpertises = await model.getUxpertises();
    if (!model.error) {
      uxpertises.sort((a:UxpertiseDto,b:UxpertiseDto) => a?.name?.localeCompare(b?.name));
      dispatch(fetchUxpertises(uxpertises,true,!Array.isArray(uxpertises) || uxpertises.length === 0, true));
    }else model.resetError();
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname } = location;
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            let uxpertises = await model.getUxpertises();
            uxpertises.sort((a:UxpertiseDto,b:UxpertiseDto) => a?.name?.localeCompare(b?.name));
            if (!model.error) {
              const { Filters: { query } } = model;
              const searchTerm = model.getQueryParam(query).toLowerCase();
              if(searchTerm){
                uxpertises = uxpertises.filter(({name}) => name && name.toLowerCase().search(searchTerm) >= 0);
                dispatch(fetchUxpertises(uxpertises, true,!Array.isArray(uxpertises) || uxpertises.length === 0, true));
              }else{
                dispatch(fetchUxpertises(uxpertises, true,!Array.isArray(uxpertises) || uxpertises.length === 0, true));
              }
            }else model.resetError();
          }
        }else{
          await getUxpertiseList();
        }
      }
    })();
  }, [location.search]);
  
  const deActivate = async (id: number, name: string) : Promise<void> => {
    const response = await model.deleteUxpertiseProfile(id, name);
    if(!model.error){
      getMessage(model, response as Error);
      const uxpertises = state.uxpertises.filter((uxpertise: UxpertiseDto) => {
        if(uxpertise.id === id) uxpertise.activated = false;
        return uxpertise; 
      });
      dispatch(fetchUxpertises(uxpertises, true,!Array.isArray(uxpertises) || uxpertises.length === 0, true));
    }else model.resetError();
  };

  const activate = async (uxpertise: UxpertiseDto) : Promise<void> => {
    const response = await model.insertUxpertise(uxpertise);
    if(!model.error){
      getMessage(model, response as CreateResponse);
      const uxpertises = state.uxpertises.filter((item: UxpertiseDto) => {
        if(item.id === uxpertise.id) item.activated = true;
        return item; 
      });
      dispatch(fetchUxpertises(uxpertises, true,!Array.isArray(uxpertises) || uxpertises.length === 0, true));
    }else model.resetError();
  };

  const { uxpertises, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            uxpertises={uxpertises}
            isEmptyList={isEmptyList}
            onDeactivate={deActivate}
            onActivate={activate}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default UxpertiseListView;
