import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Grid
} from '@mui/material';
import type { LibraryResource, WorkingHour } from 'src/common/types';
import { EmptyList, tableRow } from 'src/common';
import LibraryOpenHoursModel from 'src/model/library/LibraryOpenHours';
import { tableCell } from 'src/styles/library';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

interface OpenHoursInfoProps {
  model: LibraryOpenHoursModel;
  library: LibraryResource;
}

const OpenHoursInfo: FC<OpenHoursInfoProps> = ({
  model,
  library
}) => {
  const { openHours } = library;

  if(openHours){
    const openHourFromBD: any = model.convertStringToJson(openHours);
    openHourFromBD.forEach((v:WorkingHour, k: number) => {
      model.OpenHours.set(k,{day:k, hours:[{
        start: model.parseHours(v.hours[0]?.start), 
        end: model.parseHours(v.hours[0]?.end)}]
      });
    });
  }

  const renderOpenHours = (hours: { start: string, end: string}) => {
    if(!hours?.start && !hours?.end){
      return (
        <span>Fermé</span>
      )
    }
    const { start, end } = hours;
    const str = 'Invalid date';
    const startCond = start && start !== str;
    const endCond = end && end !== str;
    if(startCond || endCond) {
      const empty = Array(10).fill('\xa0').join('');
      return (
        <span>
          { startCond ? start : empty }
          {' '}
          -
          {' '}
          { endCond ? end :  empty }
        </span>
      );
    }
    return;
  };
  
  return (
    <Grid container spacing={3}>
      {
        !model.OpenHours ? (<Grid item md={12} lg={12} xs={12}><EmptyList /></Grid>)
        : (        
            <Grid item md={4} lg={4} xs={12}>
              <MyCard>
                <CardHeaderDetail title={'Heures d\'ouverture'} />
                <Divider />
                  <Table>
                    <TableBody>
                      { 
                          Array.from(model.OpenHours.values()).map((openHour: WorkingHour, index: number) => {
                            return (
                              <TableRow key={openHour.day} sx={tableRow}>
                                <TableCell sx={tableCell}>
                                  {model.Days[index]}
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    variant="body2"
                                    sx={{color:"text.secondary"}}
                                  >
                                    { renderOpenHours(openHour.hours[0]) }
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })
                      }
                    </TableBody>
                  </Table>
              </MyCard>
            </Grid>
          )
      }
    </Grid>
  );
};

OpenHoursInfo.propTypes = {
  library: PropTypes.object
};

export default OpenHoursInfo;
