import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { DocumentTypeResource, Error } from 'src/common/types';
import DocumentTypeModel from 'src/model/content/DocumentType';

export interface DocumentTypeContextValue {
  documentTypes: DocumentTypeResource[],
  isAuthorize?: boolean;
  isLoading?: boolean;
  isEmptyList?: boolean;
  error?:Error;
  selectedDocumentTypes: DocumentTypeResource[];
  setSelectedDocumentTypes: (documentTypes: DocumentTypeResource[]) => void;
}

interface DocumentTypeProviderProps {
  children: ReactNode;
}

type fectchDocumentTypeAction = {
  type: 'FETCH_DOCUMENT_TYPES';
  payload: {
    documentTypes: DocumentTypeResource[],
    isAuthorize?: boolean;
    isLoading?: boolean;
    isEmptyList?: boolean,
    error?:Error
  };
};

type fetchSelectedDocumentTypesAction = {
  type: 'FETCH_SELECTED_DOCUMENT_TYPE';
  payload: {
    selectedDocumentTypes: DocumentTypeResource[];
  };
};

type Action = fectchDocumentTypeAction | fetchSelectedDocumentTypesAction;

interface DocumentTypeState {
  documentTypes: DocumentTypeResource[];
  selectedDocumentTypes: DocumentTypeResource[]; 
  isLoading?: boolean,
  isAuthorize?: boolean,
  isEmptyList?: boolean,
  error?: Error
}

const initialDocumentTypeState = {
  selectedDocumentTypes: [],
  documentTypes: [],
  isLoading: false,
  isAuthorize: false,
  isEmptyList: true,
  error: null
};

const reducer = (state: DocumentTypeState, action: Action) => {
  switch (action.type) {
    case 'FETCH_DOCUMENT_TYPES': {
      const { documentTypes, isAuthorize, isLoading, isEmptyList, error } = action.payload;
      return {
        ...state,
        documentTypes,
        isAuthorize,
        isLoading,
        isEmptyList,
        error
      };
    }
    case 'FETCH_SELECTED_DOCUMENT_TYPE': {
      const { selectedDocumentTypes } = action.payload;
      return {
        ...state,
        selectedDocumentTypes
      };
    }
    default: {
      return { ...state };
    }
  }
};

const DocumentTypeContext = createContext<DocumentTypeContextValue>({
  ...initialDocumentTypeState,
  setSelectedDocumentTypes: (contentTypes: DocumentTypeResource[]) => {}
});

export const DocumentTypeProvider: FC<DocumentTypeProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDocumentTypeState);
  
  const setSelectedDocumentTypes = (documentTypes: DocumentTypeResource[]) => {
    if(documentTypes){
     dispatch({
       type: 'FETCH_SELECTED_DOCUMENT_TYPE',
       payload: {
         selectedDocumentTypes: documentTypes
       }
     });
    }
  };

  useEffect(() => {
    const initialise = async () => {
      const model = new DocumentTypeModel();
      const documentTypes = await model.getDocumentTypes();
      const { error } = model;
      if(!error)
        dispatch({
          type: 'FETCH_DOCUMENT_TYPES',
          payload: {
            documentTypes: documentTypes,
            isLoading: true,
            isAuthorize: true,
            isEmptyList: !Array.isArray(documentTypes) || documentTypes.length === 0
          }
        });
    };
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: 'FETCH_DOCUMENT_TYPES', payload: initialDocumentTypeState}) }
  }, [dispatch]);

  return (
    <DocumentTypeContext.Provider
      value={{
        ...state,
        setSelectedDocumentTypes
      }}
    >
      {children}
    </DocumentTypeContext.Provider>
  );
};

DocumentTypeProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default DocumentTypeContext;
