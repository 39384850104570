import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { CustomerResource, LibraryCardTransaction} from 'src/common/types';
import { RadioGroup, FormControl } from '@mui/material';
import { AccessModel, CaijCard, FormikTouched, labelConfig, Operations } from 'src/common';
import CaijRadio from 'src/components/inputs/CaijRadio';
import {$enum} from "ts-enum-util";

export interface OperationTypeFormProps {
  customer: CustomerResource;
  libraryCardTransaction : LibraryCardTransaction;
  touched: FormikTouched<LibraryCardTransaction>;
  onHandleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const OperationTypeForm: FC<OperationTypeFormProps> = ({
  customer,
  libraryCardTransaction,
  onHandleChange,
  setFieldValue,
  touched
}) => (
  <CaijCard title={labelConfig.operation}>
    <FormControl component='fieldset'>
      <RadioGroup
      row
      aria-label='transactionType'
      name='transactionType'
      value={libraryCardTransaction.transactionType.toString()}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
          for(let key in libraryCardTransaction){
              if(key !== 'transactionType'){
                setFieldValue(key, key == 'amount' ? 0 : '');
              }else{
                onHandleChange(event, setFieldValue);
                touched.amount = false;
              }
          }      
      }}
      >
      { $enum(Operations).map((value, key) => (
          (value === Operations.Refill && (!AccessModel.accessLibrary(customer) && customer.card)) ? null 
          : <CaijRadio name='transactionType' key={key} label={value} value={key} inputProps={{'data-testid': key}}/>
        )) 
      }
      </RadioGroup>
    </FormControl>
  </CaijCard>
);


OperationTypeForm.propTypes = {
  libraryCardTransaction: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default OperationTypeForm;
