import { createContext, useReducer, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { 
  Forbidden, 
  Unauthorized, 
  UxpertiseModel, 
} from 'src/common';
import { UxpertiseDto } from 'src/common/types';

interface UxpertiseState {
  productId?: number;
  productCode?: string;
  value: string;
  uxpertises: UxpertiseDto[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean
};

export interface UxpertiseContextValue extends UxpertiseState{
  handleChange: (value: string) => void;
}

interface UxpertiseProviderProps {
  children: ReactNode;
}

type FetchUxpertisesAction = {
  type: 'FETCH_UXPERTISES';
  payload: {
    value?: string;
    uxpertises: UxpertiseDto[];
    isAuthorize?: boolean;
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

type HandleChangeUxpertiseAction = {
  type: 'HANDLE_CHANGE_UXPERTISE';
  payload: {
    value: string;
  };
};

type Action = FetchUxpertisesAction 
              | HandleChangeUxpertiseAction;

const initialState = {
  productId: null,
  productCode: null,
  value: '',
  uxpertises: [],
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false
}; 

const reducer = (state: UxpertiseState, action: Action): UxpertiseState => {
  switch (action?.type) {
    case 'FETCH_UXPERTISES':
      const { uxpertises, isAuthorize, isLoading, isEmptyList } = action.payload;
      return {
        ...state,
        uxpertises,
        isAuthorize,
        isLoading,
        isEmptyList
      };
    case 'HANDLE_CHANGE_UXPERTISE':
        const { value } = action.payload;
        return {
          ...state,
          value
        };
    default:
      return { ...state };
  }
};

const UxpertiseContext = createContext<UxpertiseContextValue>({
  ...initialState,
  handleChange: (value: string) : void => {}
});

export const UxpertiseProvider: FC<UxpertiseProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  const handleChange = (value: string) : void => {
    dispatch({
      type: 'HANDLE_CHANGE_UXPERTISE',
      payload: {
        value
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      const model = new UxpertiseModel();
      model.skipHandleError = true;
      let uxpertises = await model.getUxpertises();
      const { error } = model;
      if(error){
        if (error.status === Forbidden || error.status === Unauthorized) {
         dispatch({
          type: 'FETCH_UXPERTISES',
          payload: {
            uxpertises: [],
            isAuthorize:false,
            isLoading: true,
            isEmptyList: true
          }
        });
        }
      }else {
        uxpertises = uxpertises.sort((a:UxpertiseDto,b:UxpertiseDto) => a?.name?.localeCompare(b?.name))
                               .filter(({activated}) => activated)
        dispatch({
          type: 'FETCH_UXPERTISES',
          payload: {
            uxpertises,            
            isAuthorize:true,
            isLoading: true,
            isEmptyList: !Array.isArray(uxpertises) || uxpertises.length === 0
          }
        });
      }
    };
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: 'FETCH_UXPERTISES', payload: initialState }) }
  }, []);

  return (
    <UxpertiseContext.Provider
      value={{
        ...state,
        handleChange
      }}
    >
      {children}
    </UxpertiseContext.Provider>
  );

};

UxpertiseProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default UxpertiseContext;
