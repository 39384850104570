import { useCallback, useReducer } from "react";
import { 
  fetchGroup,
  fetchGroupList, 
  SubscriptionModel, 
  useLocation, 
  _employee, 
  _subscription, 
} from "src/common";

export function useAccessGroup() {
  const location = useLocation();
	const [stateGroup, dispatchGroup] = useReducer(_subscription, {group: {}, isLoading: true, allowedInsert: false});
  const [stateGroupList, dispatchGroupList] = useReducer(_subscription, {isLoading: false,isEmptyList: true, groupList: []});
  
  const getGroup = useCallback(async (groupId: number, subscriptionId: number, allowedInsert: boolean = false) : Promise<void> => {
    if (groupId && subscriptionId) {
      const model = new SubscriptionModel();
      model.PathName = location.pathname;
      const group = await model.getGroup(subscriptionId, groupId);
      if (!model.error) {
        dispatchGroup(fetchGroup(group, false, allowedInsert));
      }
    }else{
      dispatchGroup(fetchGroup({name: ''}, false, allowedInsert));
    }
  }, []);

  const getGroupList = useCallback(async (subscriptionId: number) : Promise<void> => {
    const model = new SubscriptionModel();
    model.PathName = location.pathname;
    const groupList = await model.getGroupList(subscriptionId);
    if (!model.error) {
      dispatchGroupList(fetchGroupList(groupList, true,!Array.isArray(groupList) || groupList.length === 0));
    }
  }, []);

  return {
    group: stateGroup.group,
    isLoadingGroup: stateGroup.isLoading,
    allowedInsert: stateGroup.allowedInsert,
    isEmptyList: stateGroupList.isEmptyList, 
    isLoading: stateGroupList.isLoading,
    groupList: stateGroupList.groupList,
    getGroup, getGroupList, dispatchGroup, dispatchGroupList
  };
}
