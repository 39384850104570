import { ErrorType, ProductModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: ProductModel) => {
  const { Code, NameFr, NameEn } = model;
  return Yup.object().shape({
    nameEn: NameEn.required(true),
    nameFr: NameFr.required(true),
    code: Code.required(true)
      .test(Code.Label, ProductModel.formatError(ErrorType.invalid, Code.Label), (value: string) => {
        if (value) {
          return ProductModel.verifyWord(value);
        }
        return true;
      }),
  });
}

export default validateSchema;