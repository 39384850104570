import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  useParams,
  AccessModel,
  useNavigate,
  root,
  DocCollectionsModel,
  _docCollections,
  fetchCollection
} from 'src/common';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import DocCollectionsEditForm from './DocCollectionsEditForm';

const DocCollectionsEditView: FC = () => {
  const model = DocCollectionsModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_docCollections, { collection: {}, isLoading: true});
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new DocCollectionsModel();
        const pathName =  location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const collection = await model.getCollectionDocument(id);
          if (!model.error) {
            dispatch(fetchCollection(collection, false));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchCollection({...collection}, false));
        }
      } 
    };
    initialise();
  }, [id, location]);

  const { collection, isLoading } = state;

  if(isLoading) return <LoadingScreen />;

  model.Title = collection.title;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
      <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <DocCollectionsEditForm model={model} collection={collection} />
        </Box>
      </Container>
    </Page>
  );
};

export default DocCollectionsEditView;
