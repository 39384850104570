import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box
} from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import {
  GoogleMap,
  LoadScript,
  Marker,
  googleApiKey
} from 'src/common';

export interface GoogleMapInfoProps {
  library?: LibraryResource;
}

const GoogleMapInfo: FC<GoogleMapInfoProps> = ({
  library
}) => {
  const { latitude, longitude } = library;
  return (
    <Box>
      <LoadScript
        googleMapsApiKey={googleApiKey.key}
      >
        <GoogleMap
          id="googleMap"
          mapContainerStyle={{
            width: '100%',
            height: '380px'
          }}
          center={{
            lat: latitude || 0,
            lng: longitude || 0
          }}
          zoom={15}
        >
          <Marker
            title="Marker"
            position={{
              lat: latitude || 0,
              lng: longitude || 0
            }}
          />
        </GoogleMap>
      </LoadScript>
    </Box>
  );
};

GoogleMapInfo.propTypes = {
  library: PropTypes.object
};

export default GoogleMapInfo;
