import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { LibraryServiceResource } from 'src/common/types';
import LibraryServiceFrInfo from './LibraryServiceFrInfo';
import LibraryServiceEnInfo from './LibraryServiceEnInfo';
import { LibraryServiceModel } from 'src/common';

interface DetailsProps {
  model: LibraryServiceModel;
  libraryService: LibraryServiceResource;
}

const Details: FC<DetailsProps> = ({
  model,
  libraryService
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <LibraryServiceFrInfo model={model} libraryService={libraryService} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <LibraryServiceEnInfo model={model} libraryService={libraryService} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  libraryService: PropTypes.object.isRequired,
};

export default Details;
