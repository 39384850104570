import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent,
  Grid,
} from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  CaijButtonReset,
  Authorize,
  LibraryServiceModel,
  CaijButtonSubmit,
  Mode,
  btnSubmit
} from 'src/common';
import type {
  LibraryServiceResource,
  LibraryServiceResourceForCreate,
  LibraryServiceResourceForEdit,
  CreateResponse,
  Error
} from 'src/common/types';
import LibraryServiceForm from 'src/components/library/libraryService/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';
import Libraries from 'src/components/library/libraryService/Forms/Libraries';

export interface LibraryServiceEditFormProps {
  model: LibraryServiceModel;
  libraryService: LibraryServiceResource;
  onSubmit?: (values: LibraryServiceResource) => void;
}

async function redirect(model: LibraryServiceModel, response: Error | CreateResponse) : Promise<void> {
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const LibraryServiceEditForm: FC<LibraryServiceEditFormProps> = ({
  model,
  libraryService,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedLibraries, setSelectedLibraries] = useState<number[]>([]);
  const [selectedQtyRequired, setSelectedQtyRequired] = useState<Map<number, string>>();
  const [isListValid, setIsListValid] = useState<boolean>(true);

  const handleSubmit = async (values:LibraryServiceResource) => {
    if (onSubmit){
      onSubmit(values);
      return;
    }
    if(!isListValid) return;
    const model = new LibraryServiceModel();
    let submitData = values as LibraryServiceResourceForEdit;
    if (submitData && submitData.id) {
      model.SelectedQtyRequired = selectedQtyRequired;
      model.saveListRecords(selectedLibraries, submitData);
      const response = await model.updateLibraryService(submitData);
      if (!model.error) {
        await redirect(model,response);
      }
    } else {
      submitData = values as LibraryServiceResourceForCreate;
      model.SelectedQtyRequired = selectedQtyRequired;
      model.saveListRecords(selectedLibraries, submitData);
      delete submitData.id;
      const response = await model.insertLibraryService(submitData);
      if (!model.error) {
        await redirect(model,response);
      }
    }
  };

  return (
    <Formik
      initialValues={LibraryServiceModel.getInitialValues(libraryService)}
      validationSchema={validateSchema(model)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <LibraryServiceForm 
                  allowedRequireQty
                  model={model}
                  libraryService={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                  setFieldValue={setFieldValue}
                />
                <Libraries
                  setIsListValid={(l) => setIsListValid(l)}
                  allowedQtyRequired={values.requireParameters || false}
                  lists={values.libraryServiceLibraries}
                  onHandleItemsSelected={l => setSelectedLibraries(l)}
                  onHandleRequireParmeters={l => setSelectedQtyRequired(l)}
                />
              </Grid>
              <Box>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={libraryService.id ? Mode.edit : Mode.add}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

LibraryServiceEditForm.propTypes = {
  libraryService: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

LibraryServiceEditForm.defaultProps = {
 onSubmit: null
};

export default LibraryServiceEditForm;
