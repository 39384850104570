import React, { useCallback, FC, useEffect, useReducer } from 'react';
import { Box, Container } from '@mui/material';
import Page from 'src/components/Page';
import PlaceholderList from './PlaceholderList';
import {
  useLocation,
  fetchMessagePlaceholders,
  _message,
  _messagePlaceholder,
  MessagePlaceholderModel,
  EmptyList,
  AccessModel,
  MessageModel,
  root,
} from 'src/common';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialPlcState = {
  isEmptyList: true,
  placeholders: [],
  isLoading: false
};

const MessagesView: FC = () => {
  const model = MessagePlaceholderModel.getInstance();
  const access = new AccessModel(MessageModel.getInstance().ResourceCode);
  const location = useLocation();
  const [placeholderState, plcDispatch] = useReducer(_messagePlaceholder, initialPlcState);

  const getMessagePlaceholders = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new MessagePlaceholderModel();
      model.PathName = location.pathname;
      const placeholders = await model.getMessagePlaceholders();
      if (!model.error) {
        plcDispatch(fetchMessagePlaceholders(placeholders, true, !Array.isArray(placeholders) || placeholders.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getMessagePlaceholders();
    })();
  },[]);

  const { placeholders, isEmptyList, isLoading } = placeholderState;

  if(!isLoading){
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        {
            isEmptyList ? (<Box mt={3}><EmptyList /></Box>) : (
            <Box mt={3} display='flex'>
              <PlaceholderList model={model} placeholders={placeholders} />
            </Box>
            )
        }
      </Container>
    </Page>
  );
};

export default MessagesView;
