import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import React from 'react';
import { 
  CaijInput, 
  labelConfig, 
  FormikErrors, 
  FormikTouched, 
  CustomerModel
} from 'src/common';
import type { CustomerResource } from 'src/common/types';

interface CaijCardFormProps {
  model: CustomerModel;
  customer: CustomerResource;
  errors: FormikErrors<CustomerResource>;
  touched: FormikTouched<CustomerResource>;
  handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const flex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start'
};

const text = {
  width: '100%',
  marginTop: '1em'
};

const CaijCardForm: FC<CaijCardFormProps> = ({
  model,
  customer,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  const { card } = customer;
  const { MemberCard } = model;

  return (
    <>
      <Box sx={{...flex}}>
        <Box width='100%' mr={2}>
          <CaijInput
            required
            label={MemberCard.Label}
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={MemberCard.Label}
            variant='outlined'
            name={MemberCard.Name}
            value={card}
            inputProps={{autoFocus: true}}
            inputAttr={{ maxLength: MemberCard.MaxLength, 'data-testid' : 'card'}}
            helperText={touched.card && errors.card}
            error={Boolean(touched.card && errors.card)}
            onHandleBlur={handleBlur}
            onHandleChange={handleChange}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
              e.preventDefault();
              const { target } = e;
              const extensionStarts = target.value.lastIndexOf('');
              target.focus();
              target.setSelectionRange(0, extensionStarts);
            }}
            setFieldValue={setFieldValue}
          />
        </Box>
        <Typography sx={{...text}}>
          Entrez le numéro de la nouvelle carte ou scannez la carte à l'aide du lecteur de carte.
        </Typography>
      </Box>
      { 
        /* <TextField multiline rows={3} className={classes.note} variant='outlined' label='Note interne facultative' /> */
      }
    </>
  );
};

CaijCardForm.propTypes = {
  customer: PropTypes.object.isRequired,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default CaijCardForm;
