import { useState, useEffect, ChangeEvent } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
  SvgIcon,
  DialogTitle,
  IconButton,
  Autocomplete,
  TextField,
  Chip,
  DialogActions
} from '@mui/material';
import type { 
  DatabankResource,
  LawDomainFieldResource
} from 'src/common/types';
import { 
  Formik, 
  Mode,
  dialogTitle,
  button,
  CaijCard,
  NO_AUTHORIZE_ACCESS_MSG,
  DatabankModel,
  CaijTooltip
} from 'src/common';
import Authorize from 'src/components/Authorize';
import { 
  PlusSquare as AddIcon,
  XCircle as DeleteIcon 
} from 'react-feather';
import { XSquare as CancelIcon } from 'react-feather';
import { CheckCircle as OkIcon } from 'react-feather';
import { deleteIcon, dialog } from 'src/styles/customer';
import { btnAdd, listItemStyle } from 'src/styles/databank';
import useLawDomainField from 'src/hooks/useLawDomainField';

interface DatabankLawDomainFieldInfoProps {
  databank: DatabankResource;
  model: DatabankModel;
}

interface LawDomainField{
  lawDomainFields: LawDomainFieldResource[];
  seletedItems: LawDomainFieldResource[]
};

const DatabankLawDomainFieldInfo: FC<DatabankLawDomainFieldInfoProps> = ({ databank, model }) => {
  const { setSelectedLawDomainFields, selectedLawDomainFields, lawDomainFields, isLoading, isAuthorize } = useLawDomainField();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<LawDomainField>({
    lawDomainFields: [],
    seletedItems: []
  });
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const title = 'AJOUTER UN DOMAINE DE DROIT';
  
  useEffect(() => {
    const initialise = () => {
      if(databank){
        if(Array.isArray(databank.lawDomains) && databank.lawDomains.length > 0){
          const seletedItems = lawDomainFields.filter(({id}) => databank.lawDomains.includes(id));
          setState((prevState: LawDomainField) => {
            return {
              ...prevState,
              lawDomains: lawDomainFields.filter(({id}) => !databank.lawDomains.includes(id)),
              seletedItems
            }
          });
          setSelectedLawDomainFields(seletedItems.sort((a: LawDomainFieldResource, b: LawDomainFieldResource) => a.nameFr.localeCompare(b.nameFr)));
        }else{
          setState((prevState: LawDomainField) => {
            return {
              ...prevState,
              lawDomainFields
            }
          });
        }
      }
    }
    initialise();
  }, [isLoading]);

  const handleClickOpen = () => {
    const { seletedItems } = state;
    if(seletedItems.length > 0){
      setState({
        ...state,
        seletedItems,
        lawDomainFields: lawDomainFields.filter(({id: parentId}) => seletedItems.findIndex(({id}) => id === parentId) === -1)
      });
    }
    setOpen(true);
  };

  const handleClose = (isClosed: boolean) => {
    setOpen(isClosed);
  };

  const handleChange = (event:ChangeEvent<{}>, selectedLawDomainFields : (LawDomainFieldResource)[]) => {
    if(selectedLawDomainFields)
      setState({
        ...state, 
        seletedItems: selectedLawDomainFields,
        lawDomainFields: state.lawDomainFields.filter(({id: lawDomainFieldId}) => selectedLawDomainFields.findIndex(({id}) => id === lawDomainFieldId) === -1)
      });
  }

  const deleteLawDomainField = (lawDomainFieldId : number) => {
    if(lawDomainFieldId){
      const newSeletedItems = state.seletedItems.filter(({id}) => id !== lawDomainFieldId);
      setState({
        ...state,
        seletedItems: newSeletedItems,
        lawDomainFields: lawDomainFields.filter(({id: parentId}) => newSeletedItems.findIndex(({id}) => id === parentId) == -1)
      });
      setSelectedLawDomainFields(newSeletedItems);
    }
  };

  const renderAddButton = () => {
    if(!isLoading && !isAuthorize){
        return (
          <Typography variant="subtitle1" color='text.error'>
            {NO_AUTHORIZE_ACCESS_MSG}
          </Typography>
        ) 
    }
    return (
        <Button
            startIcon={
                <SvgIcon>
                <AddIcon />
                </SvgIcon>
            }
            onClick={handleClickOpen}
            sx={btnAdd}
            >
            {title}
        </Button>
    )
  }

  const renderListItem = (lawDomainField: LawDomainFieldResource) : JSX.Element => {
    const { id, nameFr } = lawDomainField;
    return (
      <Box key={id} sx={listItemStyle}>
        <Typography variant='body2' sx={{color:'text.primary'}}>{nameFr}</Typography>
        <Authorize
          resourceCode={model.ResourceCode}
          mode={Mode.delete}
          onIsAuth={l => setIsDeleteAuth(l)}
        >
          <CaijTooltip title="Supprimer" disabled={!isDeleteAuth}>
            <IconButton sx={{...deleteIcon}} onClick={() => deleteLawDomainField(id)} disabled={!isDeleteAuth}>
              <SvgIcon fontSize='small'>
                <DeleteIcon />
              </SvgIcon>
            </IconButton>
          </CaijTooltip>
        </Authorize>
      </Box>
    )
  }
  const dialogRender = () => {
    return (
      <Formik
        initialValues={{}}
        onSubmit={() => {
          setSelectedLawDomainFields(state.seletedItems.sort((a: LawDomainFieldResource, b: LawDomainFieldResource) => a.nameFr.localeCompare(b.nameFr)));
          handleClose(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
            <DialogContent sx={{...dialog, padding: '2em', width: 'auto'}} dividers >
              <Autocomplete
                multiple
                disableClearable
                value={state.seletedItems}
                id="LawDomain"
                options={state.lawDomainFields}
                getOptionLabel={({nameFr}) => nameFr}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Domaine de droit"
                    placeholder="Rechercher..."
                    InputLabelProps={{shrink: true}}
                  />
                )}
                onChange={(event, value, reason) => {
                  handleChange(event,value as LawDomainFieldResource[])
                }}
                renderTags={(tagValue, getTagProps) => 
                    tagValue.map((option, index) => (
                      <Chip 
                        key={option.id} 
                        label={option.nameFr} 
                        color="default" 
                        {...getTagProps({index})}
                        disabled={state.seletedItems.indexOf(option) !== -1}
                       />
                    ))
                }
              />
            </DialogContent>
            <DialogActions sx={{...dialog, padding: '15px 10px 15px 0'}}>
              <Button 
                type="submit" 
                variant="outlined" 
                disabled={isSubmitting} 
                sx={button}
                startIcon={(
                <SvgIcon fontSize="small">
                  <OkIcon />
                </SvgIcon>
                )}
              >OK</Button>
              <Button  
                variant="outlined"
                type="reset"
                onClick={() => handleClose(false)}
                disabled={isSubmitting}
                startIcon={(
                  <SvgIcon fontSize="small">
                    <CancelIcon />
                  </SvgIcon>
                )}
              >Annuler</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <CaijCard title={'Domaines de droit'} allowedBackgroundColor={false}>
      <Box display="flex" flexWrap='wrap'>
        { selectedLawDomainFields && selectedLawDomainFields.map((selectedLawDomainField: LawDomainFieldResource) => renderListItem(selectedLawDomainField)) } 
        { renderAddButton() }
      </Box>
      <Dialog maxWidth='md' fullWidth open={open}>
          {dialogRender()}
      </Dialog>
    </CaijCard>
  );
};

DatabankLawDomainFieldInfo.propTypes = {
  databank: PropTypes.object.isRequired,
  model: PropTypes.any
};

export default DatabankLawDomainFieldInfo;
