import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  AccessModel,
  _document,
  DocumentModel,
  fetchDocument,
  root
} from 'src/common';
import Page from 'src/components/Page';
import DocumentEditForm from './DocumentEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import parse from 'html-react-parser';
import GenericHeader from 'src/components/header/GenericHeader';

const DocumentEditView: FC = () => {
  const model = DocumentModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_document, { isLoading: false, document: {} });
  const { documentId } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(documentId)){
        const model = new DocumentModel();
        const pathName =  location.pathname;
        if (documentId) {
          model.PathName = pathName;
          const document = await model.getDocumentById(documentId);
          if (!model.error) {
            dispatch(fetchDocument(document, true));
          }
        }
      } 
    };
    initialise();
  }, [documentId, location]);

  const { document, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = parse(document.title.trim()).toString();

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(document.documentId)}
    >
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.Title} />
        <Box mt={3}>
          <DocumentEditForm 
            model={model}
            document={document} 
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocumentEditView;
