import React, { FC } from 'react';
import { Box } from '@mui/material';
import SymphonyList from 'src/components/customer/symphony/SymphonyList';
import { Submitting, WfTypeDto } from 'src/common/types';
import { Root } from 'src/components/styled';
import { SymProfile } from 'src/functions/hooks/symphony';
import { SymphonyModel } from 'src/common';

interface ProfilesSymphonyProps {
    model: SymphonyModel;
    state: SymProfile;
    onActivate: (value: WfTypeDto) => void;
    onDeActivate: (value: string) => void;
    submitting: Submitting;
};
  
const ProfilSymphony : FC<ProfilesSymphonyProps> = ({ model, state, onActivate, onDeActivate, submitting }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="left">
            <Root>
                <SymphonyList 
                    model={model}
                    lists={state.profiles} 
                    isLoading={state.isLoading} 
                    isEmptyList={state.isEmptyList} 
                    onActivate={(l: WfTypeDto) => onActivate(l)}
                    onDeActivate={(l:string) => onDeActivate(l)}
                    submitting={submitting}
                />
            </Root>
        </Box>    
    ); 
};

export default ProfilSymphony;
