import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import useSubscription from 'src/hooks/useSubscription';
import {
  CaijInputEmail,
  labelConfig,
  FormikErrors,
  FormikTouched,
  CaijInput,
  _symphony,
  AccountExpiryMode,
  CaijDatePicker,
  CaijInputDefaultValue,
  CustomerModel,
  GroupType,
  SubscriptionModel,
  AccessModel,
  INACTIF,
  INACTIF_COLOR,
} from 'src/common';
import type { 
  CustomerResource,
  EndorsedCustomerResource,
  GroupDto, 
  SubscriptionGroupResource, 
  SubscriptionResource, 
} from 'src/common/types';
import Symphony from 'src/components/symphony';
import { ProductSubscription } from 'src/contexts/SubscriptionContext';

interface CustomerFormProps {
  model: CustomerModel;
  disabledSubscription?: boolean;
  allowedRestrictedAccountCreation?: boolean
  customer: CustomerResource;
  initialSubscriptionGroup?: GroupDto;
  errors?: FormikErrors<CustomerResource>;
  touched?: FormikTouched<CustomerResource>;
  handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  handleExpiredDateChange?: (startDate: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  showExpiredDate?: boolean;
}

function getOptionsSubscription(access: AccessModel, isEmptyList: boolean, allowedRestrictedAccountCreation: boolean, subscriptions: SubscriptionResource[]){
  if(!isEmptyList && subscriptions){
    if(allowedRestrictedAccountCreation){
      return subscriptions.map(({id, name, restrictedAccountCreation, active}) => (
        (!restrictedAccountCreation || access.UserAdmin || (access.UserUser && access.Gestion)) && (
          <option value={id} key={id} style={{color: !active ? INACTIF_COLOR : ''}}>
            {name} {!active ? ` - ${INACTIF}` : ''}
          </option>
        )));
    }else{
      return subscriptions.map(({id, name, active}) => (
        <option value={id} key={id} style={{color: !active ? INACTIF_COLOR : ''}}>
          {name} {!active ? ` - ${INACTIF}` : ''}
        </option>
      ));
    }
  }
  return null;
}

const CustomerForm: FC<CustomerFormProps> = ({
  model,
  disabledSubscription,
  allowedRestrictedAccountCreation,
  customer,
  initialSubscriptionGroup,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleExpiredDateChange,
  setFieldValue,
  showExpiredDate
}) => {
  const { id, subscriptionId, email, firstname, lastname, wfLibrary, wfProfile, expiration, endorsedBy, extIdentifier } = customer;
  const { MemberEmail, MemberFirstname, MemberLastname, MemberExpiration, MemberExtIdentifier } = model;
  const dateSpecifiedAtCreation = SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.DateSpecifiedAtCreation);
  const access = new AccessModel(model.ResourceCode);
  
  const { 
    getGroups, 
    groups, 
    subscriptionGroupId, 
    setSubscriptionGroupId, 
    customerSubscription, 
    subscriptions, 
    withoutSubscription,
    isEmptyList, 
    productSubscription, 
    getProductSubscription 
  } = useSubscription();
  const [selected, setSelected] = useState<number>(0);
  
  useEffect(() => {
    const initialise = async () => {
      if(subscriptionId){
        await getGroups(subscriptionId);
      }else{
        setFieldValue('subscriptionGroup',null);
        setSubscriptionGroupId(null);
        groups.length = 0;
      }
    }
    initialise();
  },[selected, subscriptionId, setFieldValue]);
 
  useEffect(() => {
    if((selected > 0 || customerSubscription) && groups.length > 0){
      const defaultGroup = groups.find((group:SubscriptionGroupResource) => group.isDefault);
      setFieldValue('subscriptionGroup',defaultGroup);
      setSubscriptionGroupId(String(defaultGroup.id));
    }
  },[selected, groups, customerSubscription]);

  useEffect(() => {
    if(initialSubscriptionGroup){
      setFieldValue('subscriptionGroup',initialSubscriptionGroup);
      setSubscriptionGroupId(String(initialSubscriptionGroup.id));
    }
  },[initialSubscriptionGroup]);
  
  useEffect(() => {
    const initialise = async () => {
      if(subscriptionId){
        await getProductSubscription(subscriptionId);
      }
    }
    (async () => {
      await initialise();
    })();
  },[subscriptionId]);

  const renderGroups = (endorsedBy: EndorsedCustomerResource, groups: SubscriptionGroupResource[]) : JSX.Element => {
    if(endorsedBy && groups){
      return (
        <TextField
          label={labelConfig.groups}
          id='subscriptionGroupId'
          name='subscriptionGroupId'
          disabled={true}
          select
          SelectProps={{ native: true }}
          value={subscriptionGroupId}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          inputProps={{ 'aria-label': 'subscriptionGroupId', 'data-testid': 'subscriptionGroupId' }}
          sx={{ marginTop: 2, mb: 3, width: '100%' }}
        >
          <option value="">Sélectionner un groupe</option>
          {
            groups.map((group: SubscriptionGroupResource) => (
              <option value={group.id} key={group.id}>
                {group.name}
              </option> 
            ))
          }
        </TextField>
      )
    }else if(groups){
      return (
        <TextField
          label={labelConfig.groups}
          id='subscriptionGroupId'
          name='subscriptionGroupId'
          onChange={(e:ChangeEvent<HTMLInputElement>) => {
            const groupId = e.target.value;
            customer.subscriptionGroup = groupId ? groups.find((group: SubscriptionGroupResource) => group.id === +groupId) as GroupDto : null;
            setSubscriptionGroupId(groupId);
            customer.wfLibrary = customer.wfProfile = '';
            if(productSubscription.subscriptionId === subscriptionId && !productSubscription.groups.includes(+groupId)){
              const wf = ['wfLibrary','wfProfile'];
              wf.forEach((value: string) => setFieldValue(value, ''));
            }
            handleChange(e,setFieldValue);
          }}
          select
          SelectProps={{ native: true }}
          value={subscriptionGroupId}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          inputProps={{ 'aria-label': 'subscriptionGroupId', 'data-testid': 'subscriptionGroupId' }}
          sx={{ mb: 3, width: '100%' }}
        >
          <option value="">Sélectionner un groupe</option>
          {
            groups.map((group: SubscriptionGroupResource) => group.groupType === GroupType.Normal && (
                <option value={group.id} key={group.id}>
                  {group.name}
                </option> 
              )
            )
          }
        </TextField>
      )
    }
  };

  const renderSymphony = (endorsedBy: EndorsedCustomerResource, subscriptionId: number, productSubscription: ProductSubscription) : JSX.Element => {
    if(endorsedBy && 
      productSubscription.subscriptionId == subscriptionId && 
      productSubscription.groups.includes(subscriptionGroupId)
    ){
      return (
        <>
          <CaijInputDefaultValue
            name="wfLibrary"
            type="text"
            disabled={true}
            label={labelConfig.wfLibrary}
            defaultValue={wfLibrary}
            sx={{mb:2}}
          />
          <CaijInputDefaultValue
            name="wfProfile"
            type="text"
            disabled={true}
            label={labelConfig.wfProfile}
            defaultValue={wfProfile}
            sx={{mb:2}}
          />
        </>
      )
    }else if(productSubscription.subscriptionId == subscriptionId && 
       productSubscription.groups.includes(subscriptionGroupId)){
      return (
        <Symphony 
          wfLibrary={wfLibrary}
          wfProfile={wfProfile}
          onHandleChange={(wfLibrary,wfProfile) => {
            setFieldValue('wfLibrary', wfLibrary);
            setFieldValue('wfProfile', wfProfile);
          }}
        />
      )
    }
  }; 

  return (
    <>
      <CaijInput
        name={MemberFirstname.Name}
        id={MemberFirstname.Name}
        required
        label={MemberFirstname.Label}
        value={firstname}
        error={Boolean(touched.firstname && errors.firstname)}
        helperText={touched.firstname && errors.firstname}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{maxLength: MemberFirstname.MaxLength, 'data-testid':'firstname', autoComplete: 'none'}}
        onHandleBlur={handleBlur}
        onHandleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        name={MemberLastname.Name}
        id={MemberLastname.Name}
        required
        label={MemberLastname.Label}
        value={lastname}
        error={Boolean(touched.lastname && errors.lastname)}
        helperText={touched.lastname && errors.lastname}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{maxLength: MemberLastname.MaxLength, 'data-testid': 'lastname'}}
        onHandleBlur={handleBlur}
        onHandleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInputEmail
        name={MemberEmail.Name}
        id={MemberEmail.Name}
        value={email}
        error={Boolean(touched.email && errors.email)}
        helperText={touched.email && errors.email}
        label={MemberEmail.Label}
        required
        InputLabelProps={{ shrink: true, required: true }}
        inputProps={{ maxLength: MemberEmail.MaxLength, 'data-testid': 'email' }}
        onHandleBlur={handleBlur}
        onHandleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      { !id && (
          <CaijInput
            name={MemberExtIdentifier.Name}
            id={MemberExtIdentifier.Name}
            label={MemberExtIdentifier.Label}
            value={extIdentifier}
            InputLabelProps={{ shrink: true }}
            inputAttr={{ maxLength: MemberExtIdentifier.MaxLength, 'data-testid': MemberExtIdentifier.Name }}
            onHandleBlur={handleBlur}
            onHandleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        )
      }
      {
        !withoutSubscription && (
          <CaijInput
            label={labelConfig.subscription}
            id='subscriptionId'
            name='subscriptionId'
            required
            onHandleChange={(e:ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              customer.wfLibrary = customer.wfProfile = '';
              const subscription = subscriptions.find(({id}) => id === +value);
              if(subscription?.wfProfile){
                setFieldValue('wfProfile', subscription?.wfProfile);
              }
              setSelected(selected+1);
              setFieldValue('subscriptionId', value);
            }}
            disabled={disabledSubscription}
            select
            value={subscriptionId}
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            inputAttr={{ 'aria-label': 'subscriptionId', 'data-testid': 'subscriptionId' }}
            sx={{ margin: '1em 0', width: '100%' }}
          >
            <option value=''>Sélectionner un abonnement</option>
            { getOptionsSubscription(access, isEmptyList, allowedRestrictedAccountCreation, subscriptions) }
          </CaijInput>
        )
      }
      { renderGroups(endorsedBy,groups) }
      { renderSymphony(endorsedBy, subscriptionId, productSubscription) }
      {
        showExpiredDate && customer.subscriptionGroup?.accountExpiryMode === dateSpecifiedAtCreation && (
          <Box sx={{mt:2}}>
            <CaijDatePicker 
              name={MemberExpiration.Name}
              required
              value={String(expiration)}
              label={MemberExpiration.Label}
              inputProps={{"data-testid": MemberExpiration.Name, 'aria-label': MemberExpiration.Name}}
              helperText={touched.expiration && errors.expiration}
              onHandleChange={(l) => handleExpiredDateChange(l, setFieldValue)}
            />
          </Box>
        )
      }
    </>
  );
};

CustomerForm.propTypes = {
  disabledSubscription: PropTypes.bool,
  allowedRestrictedAccountCreation: PropTypes.bool,
  customer: PropTypes.object.isRequired,
  errors: PropTypes.any,
  touched: PropTypes.any,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showExpiredDate: PropTypes.bool,
  handleExpiredDateChange: PropTypes.func
};

CustomerForm.defaultProps = {
  disabledSubscription: false,
  showExpiredDate: false,
  allowedRestrictedAccountCreation: false
};

export default CustomerForm;
