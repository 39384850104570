import { ErrorType, INVALID_DATE, labelConfig, SbGroup, SubscriptionModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: SubscriptionModel, startDate: Date) => {
  const { 
    Name, WfProfile, ContactName, ContactPhone, ContactEmail, 
    AddressLine1, AddressLine2, AddressCity, AddressState,
    AddressZip, AddressCountry
  } = model;
  const B2B = SubscriptionModel.getSbGroupByVal(SbGroup.B2B);
  return Yup.object().shape({
    name: Name.required(true),
    contact: Yup.object().when('group',{
      is: B2B,
      then: Yup.object().shape({
          name: ContactName.nullable(true),
          phone: ContactPhone.nullable(true),
          email: ContactEmail.nullable(true, true),
          address: Yup.object().nullable().shape({ 
              line1: AddressLine1.nullable(true),
              line2: AddressLine2.nullable(true),
              city: AddressCity.nullable(true),
              zip: AddressZip.nullable(true),
              state: AddressState.nullable(true),
              country: AddressCountry.nullable(true)
          })
      })
    }),
    startDate: Yup.string()
      .transform((curr, orig) => orig === null ? '' : curr)
      .required(SubscriptionModel.formatError(ErrorType.required, labelConfig.startDate))
      .test(labelConfig.startDate, SubscriptionModel.formatError(ErrorType.invalid, labelConfig.startDate), (startDate: string) => {
        return startDate === INVALID_DATE ? false : true;
      }),
    endDate: Yup.string().nullable()
      .transform((curr, orig) => orig === null ? '' : curr)
      .test(labelConfig.endDate, SubscriptionModel.formatError(ErrorType.invalid, labelConfig.endDate), (endDate: string) => {
        return endDate === INVALID_DATE ? false : true;
      })
      .test(labelConfig.endDate, SubscriptionModel.formatError(ErrorType.endDateMustBeLater, labelConfig.endDate), (endDate: string) => {
        if((startDate && startDate.toString() !== INVALID_DATE) && (endDate && endDate !== INVALID_DATE)){
          return SubscriptionModel.endDateMustBeGreaterThanStartDate(startDate, (endDate as unknown) as Date);
        }
        return true;
      }),
    wfProfile: WfProfile.nullable(true)
  });
};

export default validateSchema;