import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Hidden, Box, IconButton, SvgIcon, Typography} from '@mui/material';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import Account from './Account';
import Settings from './Settings';
import { Title } from 'src/components/styled';
import { Theme } from 'src/theme';
import AppModel from 'src/model/App';
import { CaijTooltip } from 'src/common';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => (
  <AppBar {...rest}>
    <Toolbar sx={{maxHeight: 64, backgroundColor: (theme:Theme) => theme.palette.background.default}}>
      <Hidden lgUp only="lg">
        <CaijTooltip title="Menu">
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </CaijTooltip>
      </Hidden>
      <Hidden mdDown only="md">
        <RouterLink to="/">
          <CaijTooltip title={AppModel.ADMIN_CAIJ_TITLE}>
            <Logo />
          </CaijTooltip>
        </RouterLink>
      </Hidden>
      <Box ml={2} flexGrow={1}>
        <Title>
          <Typography variant="h4" sx={{fontWeight: "bold"}} noWrap>
            {AppModel.ADMIN_CAIJ_TITLE}
          </Typography>
        </Title>
      </Box>
      <Settings />
      <Box ml={2}>
        <Account />
      </Box>
    </Toolbar>
  </AppBar>
);

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
