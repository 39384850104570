import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  PathModel
} from 'src/common';
import type {
  Error,
  CoveoSourceDto,
  CoveoTaskDto,
  CoveoTaskHistoryDto,
  IHeader,
  CoveoLogs,
  GetCoveoLogsListOptionalParams,
  GetSearchEngineTaskHistoryListOptionalParams
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import printMessage from 'src/views/errors/MessageError';
import { FormModel } from '../Form';
import { PagedList } from 'src/types/pagination';

export default class SearchEngineModel extends AppModel 
{
  private static _instance: SearchEngineModel;
  private _searchIndexResourceCode: string;
  private _coveoTaskHistoryHome: string;
  private _coveoLogsHome: string;

  constructor(){
    super('/content/searchengine');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'SEARCH_ENGINE';
    this._searchIndexResourceCode = 'SEARCH_INDEX';
    this.Path.PathName = '/coveo/source';
    this._coveoTaskHistoryHome = (new PathModel)._baseUrl + '/coveo/history';
    this._coveoLogsHome = (new PathModel)._baseUrl + '/coveo/logs';
    this._headerTitle = 'Liste des sources';
  }

  get Section(){
    return {
      resourceCode: this.ResourceCode,
      href: this.Path.Home,
      title: 'Source',
      visible: true
    }
  }

  get SectionHistory(){
    return {
      resourceCode: this.ResourceCode,
      href: this.CoveoTaskHistoryHome,
      title: 'Historique',
      visible: true
    }
  }

  get SectionLogs(){
    return {
      resourceCode: this.SearchIndexResourceCode,
      href: this.CoveoLogsHome,
      title: 'Logs',
      visible: true
    }
  }

  get CoveoTaskHistoryHome(){
    return this._coveoTaskHistoryHome;
  }

  get CoveoLogsHome(){
    return this._coveoLogsHome;
  }
  
  get SearchIndexResourceCode(){
    return this._searchIndexResourceCode;
  }
  
  get CoveoIdentifier(){
    return new FormModel('coveoIdentifier',"ID Coveo", 100);;
  }

  get Name(){
    return new FormModel('name',"Nom de la source", 150);
  }

  get Collection(){
    return new FormModel('collection',"Contenu", 45);
  }

  get LastRefresh(){
    return new FormModel('lastRefresh',"Dernier rafraîchissement");
  }

  get Juridiction(){
    return new FormModel('juridiction',"Juridiction", 20);
  }

  get Status(){
    return "Statut";
  }

  get Task(){
    return "Type de tâche";
  }

  get LastestStatus(){
    return "Statut";
  }

  get ModifiedBy(){
    return "Responsable";
  };

  get Created(){
    return 'Date'
  }

  get CoveoSource(){
    return 'Source Coveo';
  }

  get Message(){
    return 'Message';
  }

  get ID(){
    return 'ID';
  }

  get CoveoLogsHomeHeadCells(){
    return [
      {
        id: '', width: '10%', numeric: false, disablePadding: false, label: this.ID
      },
      {
        id: 'created', width: '15%', numeric: false, disablePadding: false, label: this.Created
      },
      {
        id: '', width: '20%',  numeric: false, disablePadding: false, label: this.CoveoSource
      },
      {
        id: '', width: '60%',  numeric: false, disablePadding: false, label: this.Message
      },
    ];
  }

  get CoveoSourceHomeHeadCells(){
    return [
      {
        id: this.Name.Name, width: '40%', numeric: false, disablePadding: false, label: this.Name.Label
      },
      {
        id: '', width: '30%', numeric: false, disablePadding: false, label: this.LastestStatus
      },
      {
        id: '', width: '30%'
      },
    ];
  }

  getHeadCells(){ 
    return [
      {
        id: '', width: ''
      },
      {
        id: '', width: '30%', numeric: false, disablePadding: false, label: this.Name.Label
      },
      {
        id: '', width: '15%', numeric: false, disablePadding: false, label: this.Collection.Label
      },
      {
        id: '', width: '15%', numeric: false, disablePadding: false, label: this.CoveoIdentifier.Label
      },
      {
        id: '', width: '15%', numeric: false, disablePadding: false, label: this.Juridiction.Label
      },
      {
        id: '', width: '10%', numeric: false, disablePadding: false, label: this.LastestStatus
      },
      {
        id: '', width: '15%', numeric: false, disablePadding: false, label: this.LastRefresh.Label
      },
      {
        id: '', width: ''
      }
    ];
  };

  getCoveoSourceHeadCells(){ 
    const headers: IHeader[] = [
      { colName: '', width: '', type: 'none' },
      { colName: this.Name.Label, width: '25%', type: 'search', property: 'name' },
      { colName: this.Collection.Label, width: '15%', type: 'search', property: 'collection' },
      { colName: this.CoveoIdentifier.Label, width: '15%', type: 'search', property: 'coveoIdentifier' },
      { colName: this.Juridiction.Label, width: '15%', type: 'dropdown', property: 'juridiction' },
      { colName: this.LastestStatus, width: '15%', type: 'none' },
      { colName: this.LastRefresh.Label, width: '15%', type: 'none' },
      { colName: '', width: '', type: 'none' }
    ];
    return headers;
  }

  getCoveoTaskHistoryHeadCells(){ 
    const headers: IHeader[] = [
      { colName: this.Name.Label, width: '20%', type: 'search', property: 'name' },
      { colName: this.Collection.Label, width: '15%', type: 'search', property: 'collection' },
      { colName: this.CoveoIdentifier.Label, width: '15%', type: 'search', property: 'coveoIdentifier' },
      { colName: 'Date', width: '15%', type: 'dateRange', property: 'startDate|endDate' },
      { colName: this.Status, width: '10%', type: 'search', property: 'status' },
      { colName: this.Task, width: '20%', type: 'search', property: 'task'},
      { colName: this.ModifiedBy, width: '10%', type: 'dropdown', property: 'email' },
    ];
    return headers;
  }

  static getInstance(): SearchEngineModel {
    if (!SearchEngineModel._instance) {
      SearchEngineModel._instance = new SearchEngineModel();
    }
    return SearchEngineModel._instance;
  }

  async getLists(collection?: string): Promise<CoveoSourceDto[]> {
    const params:{collection: string} = { collection };
    let coveoSources: CoveoSourceDto[] = [];
    await axios.get<CoveoSourceDto[]>(`${this.route}/source/`, { params }).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          coveoSources = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return coveoSources;
  }

  async post(coveoSource: CoveoSourceDto): Promise<Error> {
    let result: Error;
    await axios.post<CoveoSourceDto, Error>(`${this.route}/source`, coveoSource).then(async (response) => {
      result = response;
      result.message = 'Source coveo créé.'
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async put(id: number, coveoSource: CoveoSourceDto): Promise<Error> {
    let result: Error;
    await axios.put<CoveoSourceDto, Error>(`${this.route}/source/${id}`, coveoSource).then(async (response) => {
      result = response;
      result.message = 'Source coveo modifié.'
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/source/${id}`).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Source coveo supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }

  async createCoveoTask(coveoTask: CoveoTaskDto): Promise<Error> {
    let result: Error;
    await axios.post<CoveoTaskDto, Error>(`${this.route}/task`, coveoTask).then(async (response) => {
      result = response;
      result.message = 'Tâche coveo créé.'
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async updateCoveoTask(id: number, coveoTask: CoveoTaskDto): Promise<Error> {
    let result: Error;
    await axios.put<CoveoTaskDto, Error>(`${this.route}/task/${id}`, coveoTask).then(async (response) => {
      result = response;
      result.message = 'Tâche coveo modifié.'
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async getCoveoTaskHistories(params: GetSearchEngineTaskHistoryListOptionalParams): Promise<PagedList<CoveoTaskHistoryDto>> {
		let pagedList: PagedList<CoveoTaskHistoryDto>;
		await axios.get<PagedList<CoveoTaskHistoryDto>>(`${this.route}/history`, { params }).then(
			async (response) => {
        const { status, data } = response;
        if (status === Success) {
          pagedList = data;
        }
			},
			async (error) => {
				this.error = error;
				if (!this.skipHandleError) {
					await this.handleError(this.error);
				}
			},
		);
		return pagedList;
	}

  async getCoveoLogsLists(params: GetCoveoLogsListOptionalParams): Promise<PagedList<CoveoLogs>> {
    let pagedList: PagedList<CoveoLogs>;
    await axios.get<PagedList<CoveoLogs>>(`${this.route}/logs`, { params }).then(async (response) => {
      const { status, data } = response;
				if (status === Success) {
					pagedList = data;
				}
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return pagedList;
  }
}
