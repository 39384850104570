import React from 'react';
import type { FC } from 'react';
import {
  Box,
  LinearProgress
} from '@mui/material';
import type { Theme } from 'src/theme';

const root = {
  alignItems: 'center',
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  padding: (theme: Theme) => theme.spacing(3),
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 2000
}

const SlashScreen: FC = () => {
  return (
    <Box sx={root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </Box>
  );
};

export default SlashScreen;
