import PropTypes from "prop-types";
import { FC, ReactNode, useCallback } from "react";
import { createContext, useEffect, useReducer } from "react";
import { Forbidden, TribunalTypeModel, Unauthorized, _tribunal, _tribunalType } from "src/common";
import { TribunalTypeResource } from "src/common/types";
import { fetchTribunalTypes } from '../store/actions/content/caseLaw/TribunalTypeAction';

export interface TribunalContextValue {
  tribunalTypes?: TribunalTypeResource[];
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
}

interface TribunalProviderProps {
	children: ReactNode;
}

const initialTribunalTypeState = {
	tribunalTypes: [],
	isLoading: false,
	isAuthorize: false,
	isEmptyList: false
};

const TribunalContext = createContext<TribunalContextValue>({
  ...initialTribunalTypeState
});

export const TribunalProvider: FC<TribunalProviderProps> = ({
    children,
}) => {
	const [state, dispatch] = useReducer(_tribunalType, initialTribunalTypeState);

	const getTribunalTypes = useCallback(async () => {
		const model = new TribunalTypeModel();
		model.skipHandleError = true;
		const tribunalTypes = await model.getTribunalTypes();
        const { error } = model;
		if (error) {
			if(error.status === Forbidden || error.status === Unauthorized) {
			  dispatch(fetchTribunalTypes(tribunalTypes, true, true, false));
			}else{
			  dispatch(fetchTribunalTypes(tribunalTypes, true, true, true));
			}
		}else{
			dispatch(fetchTribunalTypes(tribunalTypes, true, !Array.isArray(tribunalTypes) || tribunalTypes.length === 0, true));
		}
	}, []);

	useEffect(() => {
	  getTribunalTypes();
	}, [getTribunalTypes]);

	return (
    <TribunalContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </TribunalContext.Provider>
  );
};

TribunalProvider.propTypes = {
	children: PropTypes.any.isRequired,
};

export default TribunalContext;
