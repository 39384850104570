import { TableCell, Typography } from "@mui/material";
import { ReactNode } from "react";
import { labelConfig } from "src/common";
import Label from "../Label";

interface Params {
  children?: ReactNode;
  active?: boolean;
  confirm?: boolean;
  whiteSpace?: any;
  allowedBaseColor?: boolean,
  opacity?: string | number;
  allowedLabel?: boolean;
  txtActive?: string;
  txtInActive?: string;
  cellAlign?: "right" | "left" | "inherit" | "center" | "justify";
  colSpan?: number;
  cellWidth?: string;
};

export const CaijTableCell = ({
  cellAlign, 
  allowedBaseColor, 
  allowedLabel, 
  txtActive, 
  txtInActive, 
  active, 
  children,
  colSpan,
  cellWidth,
  ...rest
}: Params) => (
  <TableCell width={cellWidth} align={cellAlign} colSpan={colSpan}>
    { allowedLabel ? (
        <Label color={active ? 'success' : 'error'}>
          {active ? txtActive : txtInActive}
        </Label>
      ) : (
        <Typography component="div" variant='body2' sx={{...rest,color: allowedBaseColor ? 'text.primary' : 'text.secondary'}}>
          {children}
        </Typography>
      )
    }
  </TableCell>
);

export const CaijTableCellActive = ({active, cellWidth, cellAlign}: Params) => (
  <TableCell width={cellWidth} align={cellAlign}>
    <Label color={active ? 'success' : 'error'}>
      {active ? labelConfig.active : labelConfig.inactive}
    </Label>
  </TableCell>
);

export const CaijTableCellConfirm = ({confirm, cellWidth, cellAlign}: Params) => (
  <TableCell width={cellWidth} align={cellAlign}>
    <Label color={confirm ? 'success' : 'error'}>
      {confirm ? labelConfig.yes : labelConfig.no}
    </Label>
  </TableCell>
);

