import { Box, IconButton, Link, SvgIcon, TableCell, TableRow } from '@mui/material';
import { useState, type FC } from 'react';
import { Authorize, Mode, ReperageModel, formatDateTime, CaijTooltip } from 'src/common';
import { ContentItemResource, KnownUserContentItemType, UserContentItemsResource } from 'src/common/types';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import Image from '@mui/icons-material/Image';
import Attachment from '@mui/icons-material/Attachment';
import Article from '@mui/icons-material/Article';
import SavedSearch from '@mui/icons-material/SavedSearch';
import ContentItemPdf from './ContentItemPdf';
import ReperageNameForm from 'src/components/reperages/Forms/ReperageNameForm';
import {
    Edit as EditIcon,
} from 'react-feather';

interface ContentItemProps {
    item: ContentItemResource;
    archived: boolean;
    onDelete: Function;
    onChangeContentItemName: (item: UserContentItemsResource, title: string) => Promise<void>;
    model: ReperageModel;
    isLoading?: boolean;
}

const ContentItem: FC<ContentItemProps> = ({ item, archived, onDelete, onChangeContentItemName, model, isLoading }) => {
    const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>(false);
    const [isEditAuth, setIsEditAuth] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);

    const handleChangeContentItemName = async (item: UserContentItemsResource, title: string) => {
        await onChangeContentItemName(item, title);
        setEditMode(false);
    }

    const getItemIcon = (item: ContentItemResource) => {
        switch (item.contentTypeId) {
            case KnownUserContentItemType.Document:
                return <Article />;
            case KnownUserContentItemType.Query:
                return <SavedSearch />;
            case KnownUserContentItemType.File:
                if (item.title.toLocaleLowerCase().includes('.pdf') || (item.fileContentType && item.fileContentType.indexOf('pdf') > -1))
                    return <PictureAsPdf />;
                else if (item.title.match(/\.(png|jpeg|jpg|svg|gif)/))
                    return <Image />
                return <Attachment />
            default:
                return <></>;
        }
    }

    const renderItemTitle = (item: ContentItemResource) => {
        switch (item.contentTypeId) {
            case KnownUserContentItemType.Document:
            case KnownUserContentItemType.Query:
                return <Link target="_blank" href={item.url}>{item.title}</Link>;
            case KnownUserContentItemType.File:
                return (
                    <Box display="flex" alignItems="center" width={"100%"}>
                        {editMode ?
                            <ReperageNameForm
                                id={item.id}
                                rowVal={item.title}
                                label={model.ContentItemName.Label}
                                maxLength={model.ContentItemName.MaxLength}
                                isSubmitting={!isLoading}
                                onSave={(value: string) => handleChangeContentItemName(item, value)}
                                onCancel={() => setEditMode(false)}
                            />
                            :
                            <>
                                {
                                    item.path && (item.title.toLocaleLowerCase().includes('.pdf') || (item.fileContentType && item.fileContentType.indexOf('pdf') > -1)) ? 
                                        <ContentItemPdf item={item} model={model} /> : item.title
                                }
                                { !archived && 
                                    <Authorize
                                        resourceCode={model.ResourceCode}
                                        mode={Mode.edit}
                                        onIsAuth={(l) => setIsEditAuth(l)}
                                    >
                                        <CaijTooltip title="Éditer" disabled={!isEditAuth}>
                                            <IconButton
                                                onClick={() => setEditMode(true)}
                                                disabled={!isEditAuth}
                                            >
                                                <SvgIcon fontSize='small'>
                                                    <EditIcon />
                                                </SvgIcon>
                                            </IconButton>
                                        </CaijTooltip>
                                    </Authorize>
                                }
                            </>
                        }
                    </Box>
                );
            default:
                return item.title;
        }
    }

    return <TableRow>
        <TableCell>{getItemIcon(item)}</TableCell>
        <TableCell>{renderItemTitle(item)}</TableCell>
        <TableCell>{formatDateTime(item.dateCreated)}</TableCell>
        <TableCell>{formatDateTime(item.dateModified)}</TableCell>
        <TableCell>
            {!archived &&
                <Authorize
                    resourceCode={model.ResourceCode}
                    mode={Mode.delete}
                    onIsAuth={(l) => setIsDeleteAuth(l)}
                >
                    <CaijTooltip title="Supprimer" disabled={!isDeleteAuth}>
                        <IconButton
                            onClick={() => onDelete(item)}
                            disabled={!isDeleteAuth}
                        >
                            <SvgIcon fontSize='small'>
                                <DeleteIcon />
                            </SvgIcon>
                        </IconButton>
                    </CaijTooltip>
                </Authorize>
            }
        </TableCell>
    </TableRow>
}

export default ContentItem;