import type { FC} from 'react';
import PropTypes from 'prop-types';

interface CaijImageProps {
  id?: string;
  src: string;
  alt?: string;
  style?: any;
}

const CaijImage: FC<CaijImageProps> = ({
  id,
  src,
  alt,
  style
 }) => {
  return (
    <img
      id={id} 
      data-testid={id}
      src={src} 
      alt={alt} 
      style={{...style, maxWidth: 200,maxHeight: 200}}
      onContextMenu={(e)=> e.preventDefault()}
    />
  );
};

CaijImage.propTypes = {
  id:  PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.any
};

CaijImage.defaultProps = {
  alt: '',
  style: { display: 'inline-block' }
};

export default CaijImage;
