import { 
  AppBar,
  Dialog, 
  DialogContent, 
  IconButton, 
  Link, 
  MenuItem, 
  Toolbar, 
  Typography 
} from "@mui/material";
import { useState } from "react";
import { 
  dialog, 
  _customer,
  Transition,
} from "src/common";
import { CustomerResource } from "src/common/types";
import CloseIcon from '@mui/icons-material/Close';
import ModificationsHistoryInfo from "./ModificationsHistoryInfo";
import CaijAppBar from "src/components/AppBar";

export default function History(props: {customer: CustomerResource, setAnchorEl: () => void}){
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link href='#' onClick={() => {props.setAnchorEl(); setOpen(true)}} underline='none'>
        <MenuItem>
          <Typography variant="body1">Voir l'historique</Typography>
        </MenuItem>
      </Link>
      <Dialog fullScreen open={open} TransitionComponent={Transition} onClose={handleClose}>
        <CaijAppBar title="Historique du membre" handleClose={handleClose} />
        <DialogContent dividers sx={{...dialog, width: 'auto' }}>
          <ModificationsHistoryInfo custId={props.customer.id} />
        </DialogContent>
      </Dialog>
    </>
   );
};
