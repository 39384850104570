import React, { Children, useEffect, useState } from 'react';
import type { ChangeEvent, FC, ReactNode, ReactPortal } from 'react';
import {
  Box,
  Card,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material';
import CaijListItemText from 'src/components/CaijListItemText';
import { CaijCheckbox, CaijInputRank, labelConfig } from 'src/common';
import { GroupDto, SubscriptionGroupProductsResource } from 'src/common/types';

type Input = {
  name: string;
  value: string;
  isRequire: boolean;
  maxLength: number;
  handleLeaveInput: (e: ChangeEvent<HTMLInputElement>, groupId?: number) => void;
};

interface IAccessListItem{
  name: string;
  active: boolean;
  sx?: any;
  children: ReactNode;
};

type Data = {
  selectedSubscription?: number[],
  selectedGroups?: number[],
  selectedSubscriptionGroupProducts?: SubscriptionGroupProductsResource[]
};

interface AccessListItemProps {
  id: number;
  name: string;
  active?: boolean;
  data: Data;
  groups?: GroupDto[];
  input?: Input;
  handleToggle: (value: number, allowedSelectedGroup: boolean) => void;
  handleChange?: (id: number, value: number) => void;
  handleLeaveList: () => void;
}

const AccessListItemRoot = (props: IAccessListItem) => {
  const childrens: ReactPortal[] = [];
  if(Children.count(props.children) > 0){
    Children.forEach(props.children, (child: ReactPortal) => childrens.push(child));
  }
  return (
    <ListItem role={undefined} dense sx={props.sx}>
      <Box width='100%' display='flex' alignItems='center' justifyContent='flex-start'>
        <Box>
          <ListItemIcon>
            {childrens[0]}
          </ListItemIcon>
        </Box>
        <Box sx={{pr: 2}}>
          <CaijListItemText name={props.name} active={props.active} color="primary" />
        </Box>
        <Box flexGrow={1}>{childrens[1]}</Box>
      </Box>
    </ListItem>
  )
};

const AccessListItem : FC<AccessListItemProps> = ({
  id, 
  name, 
  active,  
  data, 
  groups,
  input,
  handleToggle,
  handleLeaveList
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const newGroups = groups?.filter(g => !g.isDefault);

  useEffect(() => setOpen(data.selectedSubscription.indexOf(id) !== -1));

  const handleClick = () => setOpen(!open);

  const isChecked = (grpId: number) => data.selectedGroups ? data.selectedGroups.indexOf(grpId) !== -1 : data.selectedSubscriptionGroupProducts.findIndex(({groupId}) => groupId == grpId) !== -1;
  
  const getSelectedSubscriptionGroupProductsValue = (grpId: number) => {
    const { selectedSubscriptionGroupProducts } = data;
    if(selectedSubscriptionGroupProducts.some(({groupId}) => groupId === grpId)){
      const group = selectedSubscriptionGroupProducts.find(({groupId}) => groupId === grpId);
      return group.parameter ? group.parameter.toString() : '';
    }
    return '';
  }

  return (
    <>
      <AccessListItemRoot name={name} active={active}>
        <CaijCheckbox
          edge="end"
          checked={data.selectedSubscription.indexOf(id) !== -1}
          disableRipple
          onHandleChangeCheckBox={() => { handleClick(); handleToggle(id, false)}} 
          onHandleBlurCheckBox={handleLeaveList}
        />  
        { input && input.isRequire && (
            <CaijInputRank
              isListRecord
              disabled={!(data.selectedSubscription.indexOf(id) !== -1)}
              label={labelConfig.requiredParameter}
              name={input.name}
              value={input.value}
              onHandleBlur={(e: ChangeEvent<HTMLInputElement>) => input.handleLeaveInput(e)}
              inputProps={{ maxLength: input.maxLength}}
            />
          )
        }  
      </AccessListItemRoot> 
      { (newGroups && newGroups.length > 0) && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card sx={{margin: '15px 0 15px 50px'}}>
              <List component="div">
                { newGroups.map(({id, name}, index: number) => (
                  <Box key={index}>
                    <AccessListItemRoot name={name} active={active}>
                        <CaijCheckbox
                          edge="end"
                          checked={isChecked(id)}
                          disableRipple
                          onHandleChangeCheckBox={() => handleToggle(id, true)} 
                          onHandleBlurCheckBox={() => handleLeaveList()}
                        /> 
                        { ((input && input.isRequire) && data.selectedSubscriptionGroupProducts) && (
                            <CaijInputRank
                              isListRecord
                              disabled={!isChecked(id)}
                              label={labelConfig.requiredParameter}
                              name={input.name}
                              value={getSelectedSubscriptionGroupProductsValue(id)}
                              onHandleBlur={(e: ChangeEvent<HTMLInputElement>) => input.handleLeaveInput(e, id)}
                              inputProps={{ maxLength: input.maxLength}}
                            />
                          )
                        }        
                      </AccessListItemRoot>
                      { index < (newGroups?.length - 1) && <Divider /> }
                    </Box>
                  ))
                }
              </List>
            </Card>
          </Collapse>
        )
      }
    </>
  );
}

export default AccessListItem;
