import { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useParams,
  useLocation,
  fetchDatabank,
  _databank,
  DatabankModel,
  AccessModel,
  useNavigate,
  root,
} from 'src/common';
import { DatabankResource, KnownDatabankStatusOption } from 'src/common/types';
import Page from 'src/components/Page';
import DatabankEditForm from './DatabankEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { LawDomainFieldProvider } from 'src/contexts/LawDomainFieldContext';
import { DocumentTypeProvider } from 'src/contexts/DocumentTypeContext';
import GenericHeader from 'src/components/header/GenericHeader';

interface stateProps {
  databank: DatabankResource;
  isLoading: boolean;
}
export const initialState: stateProps = {
  isLoading: false,
  databank: {
    nameEn: '',
    nameFr: '',
    descriptionFr: '',
    descriptionEn: '',
    accessInfoEn: '',
    accessInfoFr: '',
    bannerFr: '',
    bannerEn: '',
    databankStatus: KnownDatabankStatusOption.Active,
    unavailableMessage: {
      fr: '',
      en: ''
    },
    logo: '',
    probe: false,
    enabled: false,
    disclaimer: false,
    note: '',
    created: new Date(),
    createdBy: '',
    modified: new Date(),
    modifiedBy: '',
    libraries: [],
    subscriptions: [],
    lawDomains: [],
    databankAccesses: [],
  },
};

const DatabankEditView: FC = () => {
  const model = DatabankModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_databank, initialState);
  const { id } = useParams();
  
  useEffect(() => {
    const initialise = async () => {
      if(access.canEdit(id)){
        const model = new DatabankModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const databank = await model.getDatabankById(id);
          if (!model.error) {
            dispatch(fetchDatabank({...initialState.databank, ...databank}, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchDatabank(initialState.databank, true));
        }
      }
    };
    initialise();
  }, [id]);

  const { databank, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = databank.nameFr
  return (
    <Page sx={root} title={model.getEditPageTitle(id)}>
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <LawDomainFieldProvider>
            <DocumentTypeProvider>
                <DatabankEditForm model={model} databank={databank} />
            </DocumentTypeProvider>
          </LawDomainFieldProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default DatabankEditView;