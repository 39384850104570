import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, SvgIcon, TableCell, TableRow, TextField, Theme, createFilterOptions, useTheme } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { CheckSquare } from "react-feather";
import CaijTypography from "src/components/typography";
import CancelIcon from '@mui/icons-material/Cancel';
import { btnSubmit } from "src/styles";
import { CaijSelect } from "src/common";
import { labelConfig } from "src/config";
import { ReperageFolderStatus, ReperageFolderStatusColor } from "src/enum";
import { ReperageResource } from "src/common/types";
import PropTypes from 'prop-types';

const option = {
    cursor: "pointer",
    "&:hover": {
        filter: "brightness(2)",
        transition: "filter 0.1s",
    }
}

interface ReasonOptionType {
    inputValue?: string;
    title: string;
};
const filter = createFilterOptions<ReasonOptionType>();

const reasonOptions: readonly ReasonOptionType[] = [
    { title: "Client ne donne pas suite" },
    { title: "Ouvrage non disponible" },
    { title: "Trop pour les droits d'auteurs" },
    { title: "Demande annulée par le client" },
    { title: "PEB Externe" },
    { title: "Livraison" },
    { title: "Demande de cueillete" },
];

export interface StatusFormProps {
    onChangeReperageStatus: (status: string, reason?: string) => void;
    isLoading: boolean;
    reperage: ReperageResource;
}

const StatusForm: FC<StatusFormProps> = ({ onChangeReperageStatus, isLoading, reperage }) => {
    const theme: Theme = useTheme();
    const [reason, setReason] = useState<ReasonOptionType | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<string>(reperage.status);
    
    useEffect(() => {
        setSelectedStatus(reperage.status);
    }, []);

    useEffect(() => {
        if (selectedStatus !== "CANCELLED" && selectedStatus !== reperage.status) {
            onChangeReperageStatus(selectedStatus);
        }
    }, [selectedStatus]);

    const getStatusOptionStyles = (colorName: string): React.CSSProperties => {
        const themedColor = theme.palette[colorName].dark;
        return { fontSize: "1rem", padding: "1rem 2rem", backgroundColor: themedColor, color: theme.palette.getContrastText(themedColor) };
    }

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={null}>
                    {labelConfig.status}
                </TableCell>
                <TableCell>
                    <CaijSelect
                        inputProps={{ 'data-testid': 'select' }}
                        onHandleChangeSelect={(e) => setSelectedStatus(e.target.value)}
                        value={reperage.status}
                        native={false}
                        renderValue={(value: string) => <Chip sx={{ fontSize: "1rem" }} label={ReperageFolderStatus[value]} color={ReperageFolderStatusColor[value]}></Chip>}
                    >
                        <MenuItem sx={option} style={getStatusOptionStyles("info")} value="NEW">{ReperageFolderStatus.NEW}</MenuItem>
                        <MenuItem sx={option} style={getStatusOptionStyles("warning")} value="ON_HOLD">{ReperageFolderStatus.ON_HOLD}</MenuItem>
                        <MenuItem sx={option} style={getStatusOptionStyles("success")} value="OPEN">{ReperageFolderStatus.OPEN}</MenuItem>
                        <MenuItem sx={option} style={getStatusOptionStyles("error")} value="CANCELLED">{ReperageFolderStatus.CANCELLED}</MenuItem>
                    </CaijSelect>
                </TableCell>
            </TableRow >
            <TableRow>
                <TableCell style={{ paddingTop: 0 }} colSpan={6}>
                    <Dialog open={selectedStatus === "CANCELLED" && selectedStatus !== reperage.status} onClose={() => setSelectedStatus(reperage.status)}>
                        <DialogTitle>Raison d'annulation</DialogTitle>
                        <DialogContent>
                            <CaijTypography sx={{ fontWeight: 'bold' }}>Choisir la raison d'annulation : </CaijTypography>
                            <Autocomplete
                                data-testid="autocomplete"
                                sx={{ flexGrow: 1 }}
                                value={reason}
                                freeSolo
                                options={reasonOptions}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setReason({
                                            title: newValue
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        setReason({
                                            title: newValue.inputValue,
                                        });
                                    } else {
                                        setReason(newValue);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    const isExisting = options.some((option) => inputValue === option.title);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            title: `Créer "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                                getOptionLabel={(option: ReasonOptionType) => option.title || ""}
                            />
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                type="submit"
                                onClick={() => onChangeReperageStatus(selectedStatus, reason.title)}
                                startIcon={<SvgIcon fontSize='small'><CheckSquare /></SvgIcon>}
                                loading={!isLoading}
                                disabled={!reason}
                                loadingPosition="start"
                                variant="contained"
                                sx={btnSubmit}
                            >Sauvegarder</LoadingButton>
                            <Button
                                variant="outlined"
                                type="reset"
                                onClick={() => setSelectedStatus(reperage.status)}
                                disabled={!isLoading}
                                startIcon={(
                                    <SvgIcon fontSize="small">
                                        <CancelIcon />
                                    </SvgIcon>
                                )}>Annuler</Button>
                        </DialogActions>
                    </Dialog>
                </TableCell>
            </TableRow>
        </>
    )
}

StatusForm.propTypes = {
    onChangeReperageStatus: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    reperage: PropTypes.object.isRequired
}

export default StatusForm;