import { Table, TableBody, TableRow, TableCell, Typography, Grid, Divider } from "@mui/material";
import { FC } from "react";
import reperage from "src/__mocks__/reperage";
import { ReperageResource } from "src/common/types";
import CardHeaderDetail from "src/components/card/CardHeaderDetail";
import MyCard from "src/components/card/MyCard";
import PropTypes from 'prop-types';
import { labelConfig } from "src/config";
import { tableRow } from "src/common";

export interface ClientInfoProps {
    reperage: ReperageResource;
}

const ClientInfo: FC<ClientInfoProps> = ({ reperage }) => {

    return (
        <MyCard>
            <CardHeaderDetail title="Client" />
            <Divider />
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={null}>
                            {labelConfig.clientName}
                        </TableCell>
                        <TableCell>
                            <Typography
                                variant="body2"
                                sx={{ color: "text.secondary" }}
                            >
                                {reperage.clientLabel}
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={tableRow}>
                        <TableCell sx={null}>
                            {labelConfig.contactEmail}
                        </TableCell>
                        <TableCell>
                            {reperage.courriel}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </MyCard>
    )
}

ClientInfo.propTypes = {
    reperage: PropTypes.object.isRequired
}

export default ClientInfo;