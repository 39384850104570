import type { TribunalTypeResource } from 'src/common/types';
import * as actionTypes from '../../../actions/ActionTypes';

type FetchTribunalTypesAction = {
  type: 'FETCH_TRIBUNAL_TYPES';
  payload: {
    tribunalTypes: TribunalTypeResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

type FetchTribunalTypeAction = {
  type: 'FETCH_TRIBUNAL_TYPE';
  payload: {
    tribunalType: TribunalTypeResource;
    isLoading?: boolean;
  };
};

type DeleteTribunalTypeAction = {
  type: 'DELETE_TRIBUNAL_TYPE';
  payload: {
    id: number
  };
};

type Action = FetchTribunalTypesAction |
              FetchTribunalTypeAction |
              DeleteTribunalTypeAction;

interface TribunalTypeReturnState {
  tribunalTypes?: TribunalTypeResource[];
  tribunalType?: TribunalTypeResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface TribunalTypeState {
  tribunalTypes: TribunalTypeResource[];
  tribunalType: TribunalTypeResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: TribunalTypeState, action: Action): TribunalTypeReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_TRIBUNAL_TYPES: {
      const { tribunalTypes, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        tribunalTypes,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_TRIBUNAL_TYPE: {
      const { tribunalType, isLoading } = action.payload;
      return {
        ...state,
        tribunalType,
        isLoading
      };
    }
    case actionTypes.DELETE_TRIBUNAL_TYPE: {
      return {
        ...state,
        tribunalTypes: [...state.tribunalTypes].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

