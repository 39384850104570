import React, { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from '@mui/material';
import {
  Permissions,
  CARD_HEADER_TITLE_PERMISSION,
  getEspaceMenuCAIJ,
  EmployeeModel,
  tableRow,
  GESTION,
  SYSTEM_PREFERENCES,
  NO_AUTHORIZE_ACCESS_MSG
} from 'src/common';
import type {
  EmployeeAccessDto,
  Resource,
  Item, 
  Section
} from 'src/common/types';
import EmptyList from 'src/components/EmptyList';
import CaijCard from 'src/components/card/CaijCard';
import CaijCheckbox from 'src/components/inputs/CaijCheckbox';
import useResource from 'src/hooks/useResource';
import CaijTable from 'src/components/table/CaijTable';
import _ from 'lodash';
import { sections } from 'src/layouts/Section';

interface ICounter {
  index: number;
}

const counter: ICounter = {
  index: 0
};

interface EmployeeAccessProps {
  employeeAccesses: EmployeeAccessDto[];
  onHandleSelectPermissions?: (permissions: string[]) => void;
  allowedEdit?: boolean;
}

const error = {
  padding: '30px 0 30px 0',
  textAlign: 'center'
}

function getResource(index: number, previousValue:Map<number | string,string>, resources: Resource[]) : void{
  if(index >= 0){
    const resource = resources[index]
    if(resource.id && !previousValue.has(resource.id)){
      previousValue.set(resource.id, resource.name);
    }
  }
};

function getResourceItem (section: Section, item: Item[], resources: Resource[], index:number, data: Map<number | string,string>) : void {
  if(index > item.length - 1){
    return;
  }
  if(!Array.isArray(item[index].items)){
    const idx = resources.findIndex((res: Resource) => res.code === item[index].resourceCode);
    getResource(idx, data, resources);
    getResourceItem(section,item, resources, ++index, data);
  }else{
    let val = index;
    counter.index = ++val;
    const newItems = item[index].items;
    getResourceItem(section,newItems, resources, 0, data);
  }
  getResourceItem(section, section.items, resources, counter.index, data);
};

const EmployeeAccess: FC<EmployeeAccessProps> = ({
  employeeAccesses,
  onHandleSelectPermissions,
  allowedEdit
}) => {
  const [checked, setChecked] = React.useState([]);
  const { resources, isEmptyList, isLoading, isAuthorize } = useResource();
  
  useEffect(() => {
    if (employeeAccesses) {
      const model = new EmployeeModel();
      const permissions = model.getResourcesChecked(employeeAccesses);
      setChecked(permissions);
      if(onHandleSelectPermissions){
        onHandleSelectPermissions(permissions);
      }
    }
  }, [employeeAccesses]);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) /* Checked */{
      const sValue:string[] = value.split('&');
      if(sValue[1] === 'delete') {
        const addVal = `${sValue[0]}&${Permissions.Read}`;
        const readIndex = checked.indexOf(addVal);
        if(readIndex === -1) {
          newChecked.push(addVal);
        }
        const editVal = `${sValue[0]}&${Permissions.Edit}`;
        const editIndex = checked.indexOf(editVal);
        if(editIndex === -1) {
          newChecked.push(editVal);
        }
      }else if(sValue[1] === 'edit') {
        const addVal = `${sValue[0]}&${Permissions.Read}`;
        const readIndex = checked.indexOf(addVal);
        if(readIndex === -1) {
          newChecked.push(addVal);
        }
      }
      newChecked.push(value);
    } else /* Unchecked */ {
      const sValue:string[] = value.split('&');
      if(sValue[1] === 'read') {
        const editIndex = checked.indexOf(`${sValue[0]}&${Permissions.Edit}`);
        if(editIndex >= 0) {
          newChecked.splice(editIndex, 1);
        }
        const deleteIndex = newChecked.indexOf(`${sValue[0]}&${Permissions.Delete}`);
        if(deleteIndex >= 0) {
          newChecked.splice(deleteIndex, 1);
        }
      }else if(sValue[1] === 'edit') {
        const deleteIndex = newChecked.indexOf(`${sValue[0]}&${Permissions.Delete}`);
        if(deleteIndex >= 0) {
          newChecked.splice(deleteIndex, 1);
        }
      }
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    if(onHandleSelectPermissions){
      onHandleSelectPermissions(newChecked);
    }
  };
  
  const renderItemCheckBoxes = (id: number) => (
    <>
      <TableCell key={`1-${id}`} align="center">
        <CaijCheckbox
          checked={checked.indexOf(`${id}&${Permissions.Read}`) !== -1}
          onHandleChangeCheckBox={handleToggle(`${id}&${Permissions.Read}`)}
          disabled={!allowedEdit}
        />
      </TableCell>
      <TableCell key={`2-${id}`} align="center">
        <CaijCheckbox
          checked={checked.indexOf(`${id}&${Permissions.Edit}`) !== -1}
          onHandleChangeCheckBox={handleToggle(`${id}&${Permissions.Edit}`)}
          disabled={!allowedEdit}
        />
      </TableCell>
      <TableCell key={`3-${id}`} align="center">
        <CaijCheckbox
          checked={checked.indexOf(`${id}&${Permissions.Delete}`) !== -1}
          onHandleChangeCheckBox={handleToggle(`${id}&${Permissions.Delete}`)}
          disabled={!allowedEdit}
        />
      </TableCell>
    </>
  );

  const renderTablePermission = (): JSX.Element[] => {
    if (isLoading && Array.isArray(resources)) {
      const espaceMenuCAIJ = [];
      getEspaceMenuCAIJ(sections, 0, espaceMenuCAIJ);
      const grpSections = _.chain([...sections])
                          .groupBy("subheader")
                          .map((value, key) => ({ subheader: key, resources: value }))
                          .value(); 
      let newSections = new Map<number | string,string>();
      for (const [key, section] of Object.entries(grpSections)) {
        if(section.subheader !== "undefined"){
          newSections.set(`subheader-${key}`, section.subheader);
          const sections = grpSections.find((item:{subheader:string, resources: Section[]}) => item.subheader === section.subheader);
          getResourceItem(sections.resources[0], sections.resources[0].items, resources, 0, newSections);
        }
      }
      //Enlever la resource id 18 (RESOURCE_GROUP)
      if(newSections.has(18))
        newSections.delete(18);
      return Array.from(newSections, ([key, value]) => {
        const cond = value == GESTION || value == SYSTEM_PREFERENCES;
        if(cond){
          return (
            <TableRow hover key={key} sx={tableRow}>
              <TableCell colSpan={4}>
                <Typography variant='body2' color='text.primary'>
                  {value}
                </Typography>
              </TableCell>
            </TableRow>
          )
        }
        return (
          <TableRow hover key={key} sx={tableRow}>
            <TableCell>
              <Typography variant='body2' color={'text.secondary'} sx={{paddingLeft:'2em'}} >
                {value}
              </Typography>
            </TableCell>
            {renderItemCheckBoxes(key as number)}
          </TableRow>
        )
      });
    }
    return null;
  };

  const renderContent = () : JSX.Element=> {
    if(isLoading && !isAuthorize){
      return (
        <Typography variant="subtitle1" sx={{...error,color: 'text.error'}}>
          {NO_AUTHORIZE_ACCESS_MSG}
        </Typography>
      )
    }else if(isLoading && isEmptyList){
      return <EmptyList noCard />;
    }else{
      return (
        <CaijTable>
          <TableHead>
            <TableRow>
            {
               ['Modules', 'Consultation', 'Édition', 'Gestion'].map((value: string, key: number) => (
                <TableCell width={value === 'Modules'? "40%": "20%"} align={key !== 0 ? "center" : "left"} key={value}>
                  {value}
                </TableCell>
               ))
            }
            </TableRow>
          </TableHead>
          <TableBody>
            { renderTablePermission() }
          </TableBody>
        </CaijTable>
      )
    }
 }

  return (
    <Grid item md={12} xs={12}>
      { allowedEdit ? (
          <CaijCard title={CARD_HEADER_TITLE_PERMISSION}>
            {renderContent()}
          </CaijCard>
        ) : renderContent()
      }
    </Grid>
  );
};

EmployeeAccess.defaultProps = {
  allowedEdit: true
};

EmployeeAccess.propTypes = {
  employeeAccesses: PropTypes.array.isRequired,
  onHandleSelectPermissions: PropTypes.func,
  allowedEdit: PropTypes.bool
};

export default EmployeeAccess;
