import type {
  EmployeesResource, Resource, TabsEmployee
} from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchEmployees = (
  employees: EmployeesResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
): {
  type: 'FETCH_EMPLOYEES';
  payload: { employees: EmployeesResource[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean };
} => ({
  type: actionTypes.FETCH_EMPLOYEES,
  payload: {
    employees,
    isLoading,
    isEmptyList,
    isAuthorize,
  },
});

export const fetchEmployee = (employee: EmployeesResource, isLoading?: boolean) : {
  type: 'FETCH_EMPLOYEE';
  payload: { 
    employee: EmployeesResource,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_EMPLOYEE,
  payload: {
    employee,
    isLoading
  },
});

export const fetchDetailsEmployee = (
  employee: EmployeesResource,
  tabs?: TabsEmployee[],
): {
  type: 'FETCH_DETAILS_EMPLOYEE';
  payload: {
    employee: EmployeesResource;
    tabs?: TabsEmployee[];
  };
} => ({
  type: actionTypes.FETCH_DETAILS_EMPLOYEE,
  payload: {
    employee,
    tabs,
  },
});

export const deleteEmployee = (id: number) : {
  type: 'DELETE_EMPLOYEE';
  payload: {
    id: number;
  };
} => ({
  type: actionTypes.DELETE_EMPLOYEE,
  payload: {
    id
  },
});
