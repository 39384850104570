import React, { ChangeEvent, useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  TableRow
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  formatDate,
  ListType,
  LawModel,
  getEmptyListSearch,
  LawType,
  CaijInput,
  NavigateFunction,
  EnhancedTableHead
} from 'src/common';
import type { LawResource, SearchFilter } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import Search from 'src/components/search';
import {$enum} from "ts-enum-util";
import { filters } from 'src/styles/law';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';
import CaijDialogs from 'src/components/dialog';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  laws: LawResource[];
  isEmptyList: boolean;
  model: LawModel;
  location: {search: string};
  navigate: NavigateFunction;
  onDeleteLaw: (id: number) => Promise<void>;
}

const Results: FC<ResultsProps> = ({
  laws,
  isEmptyList,
  model,
  location,
  navigate,
  onDeleteLaw
}) => {
  const path = model.Path;
  const [page, setPage] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState([]);
  const query = new URLSearchParams(location?.search).get("query");
  const [filter, setFilter] = useState<SearchFilter>({query, type: '', doSearch: false, page});
  const headCells = model.getHeadCells();
  const { order, orderBy, setOrderBy, sort, handleRequestSort } = useSort();
  
  useEffect(() => setOrderBy('titleFr'),[]);

  useEffect(() => {
    const { Filters: { query, type, page } } = model;
    const queryVal = model.getQueryParam(query);
    const typeVal = model.getQueryParam(type);
    const pageVal = +model.getQueryParam(page);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        type: typeVal,
        page: pageVal,
        doSearch: queryVal || typeVal ? true : false
      }
    });
    setPage(pageVal);
  },[model]);

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) : void => {
    model.resetPageValue(filter);
    model.getConfigParameters(filter,7,event.target.value);
    navigate(model.getUrlEncode.apply(model,model.getParams()));
  };

  const renderContent = (): JSX.Element => {
    if (isEmptyList && !filter.doSearch) {
      return (
        <EmptyList />
      );
    }
    return (
      <MyCard>
        <Box minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
          <Box sx={{margin:'0 0 20px 15px'}}>
            <Search 
              listType={ListType.Law}
              placeholder='Recherche la/les loi(s)' 
              filter={filter}
              width="400px"
              path={path.Home}
            />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='space-between' sx={{margin:'20px 15px'}}>
            <CaijResetFilterButton path={path.Home} navigate={navigate} />
            <CaijInput
              label={labelConfig.lawType}
              name='type'
              onHandleChange={handleTypeChange}
              select
              value={filter.type}
              sx={filters}
              InputLabelProps={{ shrink: true }}
              >
                <option value="">Tous</option>
                {
                  $enum(LawType).map((value: string, key: string) => (
                    <option value={value} key={key}>
                      {value}
                    </option>
                  ))
                }
            </CaijInput>
          </Box>
        </Box>
        <CaijTable>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
            notAllowedSort={isEmptyList}
          />
          <TableBody>
            { laws.length === 0 ? getEmptyListSearch(headCells.length) :
              sort<LawResource>(laws, page).map((row: LawResource) => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <CaijTableCell>{row.code}</CaijTableCell>
                    <CaijTableCell>{row.titleFr}</CaijTableCell>
                    <CaijTableCell>{row.type}</CaijTableCell>
                    <CaijTableCell>{formatDate(row.version)}</CaijTableCell>
                    <CaijTableCellAction 
                      pageEditUrl={path.getEdit(row.id)}
                      resourceCode={model.ResourceCode}
                      handleToggle={() => LawModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                    >
                      <CaijDialogs
                        dialog={model.Dialog}
                        isOpen={selectedRow.indexOf(row.id) !== -1}
                        onSubmit={async () => {
                          await onDeleteLaw(row.id);
                          setSelectedRow([]);
                        }}
                        setSelectedRow={setSelectedRow}
                      />
                    </CaijTableCellAction>
                  </TableRow>
              ))}
          </TableBody>
        </CaijTable>
        <CaijTablePagination 
          paged={{totalCount: laws.length}} 
          model={model} 
          filter={filter} 
          page={page}
          navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
        />
      </MyCard>
    );
  };

  return (
    <>
      { renderContent() }
    </>
  );
};

Results.propTypes = {
  laws: PropTypes.array,
  isEmptyList: PropTypes.bool,
  model: PropTypes.instanceOf(LawModel)
};

export default Results;
