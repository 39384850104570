import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent
} from '@mui/material';
import type { 
  Error, 
  CreateResponse, 
  SubscriptionResource, 
  SubscriptionResourceForEdit, 
  SubscriptionResourceForCreate 
} from 'src/common/types';
import {
  Formik,
  handleChangeCheckBox,
  handleChangeDateChange,
  SbGroup,
  SubscriptionModel,
  Authorize,
  CaijButtonSubmit,
  CaijButtonReset,
  CaijCard,
  Mode,
  FormikProps,
  CaijTextarea,
  ACCOUNT_TITLE,
  btnSubmit,
  handleChangeImageUrl,
  Other,
  SUBCRIPTION_CONTACT_ERROR
} from 'src/common';
import SubscriptionForm from 'src/components/subscription/Forms';
import MemberAccessForm from 'src/components/subscription/Forms/MemberAccessForm';
import AccountForm from 'src/components/subscription/Forms/AccountForm';
import SubscriptionAccess from 'src/components/access/SubscriptionAccess';
import Contact from 'src/components/subscription/Forms/Contact';
import { Root } from 'src/components/styled';
import MyCard from 'src/components/card/MyCard';
import printMessage from 'src/views/errors/MessageError';
import useDoorAccessProfile from 'src/hooks/useDoorAccessProfile';
import Memo from 'src/components/memo';
import validateSchema from '../Schema';
import useUxpertise from 'src/hooks/useUxpertise';

export interface SubscriptionEditFormProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  onSubmit?: (values: SubscriptionResource) => void;
}

async function redirect(model: SubscriptionModel, response: Error | CreateResponse, id: number) : Promise<void>{
  await model.redirect(model.Path.getDetail(id),{
    status: response.status,
    message: response.message
  });
};

const B2B = SubscriptionModel.getSbGroupByVal(SbGroup.B2B);

const SubscriptionEditForm: FC<SubscriptionEditFormProps> = ({
  model,
  subscription,
  onSubmit
}) => {
  const { value, handleChange } = useDoorAccessProfile();
  const { value: uxpertiseValue, handleChange: handleChangeUxpertise } = useUxpertise();
  const [isAuth, setIsAuth] = useState<boolean>();
  const formRef = useRef<FormikProps<SubscriptionResource>>(null);
  const [isExpireOnValid, setIsExpireOnValid] = useState<boolean>(true);
  const [selectedSubscriptionProducts, setSelectedSubscriptionProducts] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  const [selectedSubscriptionDatabanks, setSelectedSubscriptionDatabanks] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  const [selectedSubscriptionDocCollections, setSelectedSubscriptionDocCollections] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  const [selectedParameters, setSelectedParameters] = useState<Map<number, string>>();

  useEffect(() => {
    handleChange(String(subscription.doorCardProfile));
    handleChangeUxpertise(String(subscription.uxpertiseGroup));
  },[]);

  const handleSubmit = async (values: SubscriptionResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new SubscriptionModel();
    const submitData = values as SubscriptionResourceForEdit;
    submitData.doorCardProfile = +value;
    submitData.uxpertiseGroup = +uxpertiseValue;
    let { subscriptionGroup } = submitData;
    if(!model.validateOnApprovalRenewMode(subscriptionGroup.accountRenewMode))
      subscriptionGroup.formUrl = "";
    if (submitData && submitData.id) {
      model.SelectedProductParameters = selectedParameters;
      model.getSubmitListData(
        submitData,
        subscription,
        selectedSubscriptionProducts, 
        selectedSubscriptionDatabanks, 
        selectedSubscriptionDocCollections
      );
      if(model.validateContact(values, B2B, () => printMessage({status: Other, message: SUBCRIPTION_CONTACT_ERROR}))){
        const response = await model.updateSubscription(submitData);
        if (!model.error) {
          await redirect(model, response, submitData.id);
        }
      }
    } else {
      const submitData = values as SubscriptionResourceForCreate;
      model.SelectedProductParameters = selectedParameters;
      model.getSubmitListData(
        submitData,
        subscription,
        selectedSubscriptionProducts,
        selectedSubscriptionDatabanks,
        selectedSubscriptionDocCollections
      );
      delete submitData.id;
      if(model.validateContact(values, B2B, () => printMessage({status: Other, message: SUBCRIPTION_CONTACT_ERROR}))){
        const response = await model.insertSubscription(submitData);
        if (!model.error) {
          await redirect(model, response, response.data.id);
        }
      }
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={SubscriptionModel.getInitialValues(subscription)}
      validationSchema={validateSchema(model, formRef.current?.values.startDate)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, values, touched, isSubmitting, setErrors }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Root>
                  <SubscriptionForm
                    model={model}
                    subscription={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    onHandleChange={handleChange}
                    onHandleChangeCheckBox={handleChangeCheckBox}
                    onHandleStartDateChange={(d, s) => handleChangeDateChange('startDate', d, s)}
                    onHandleEndDateChange={(d, s) => handleChangeDateChange('endDate', d, s)}
                    onHandleChangeImageUrl={handleChangeImageUrl}
                    setFieldValue={setFieldValue}
                  />
                  </Root>
                  <Root>
                    <MemberAccessForm
                      subscription={values}
                      onHandleChangeCheckBox={handleChangeCheckBox}
                      setFieldValue={setFieldValue}
                    />
                 </Root>
                {values.group === B2B && (
                  <Root>
                    <Contact 
                      model={model}
                      subscription={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  </Root>
                )}
                <Root>
                  <CaijCard title={ACCOUNT_TITLE} sx={{mb:2}}>
                    <AccountForm
                      model={model}
                      subscription={values}
                      onHandleChange={handleChange}
                      onHandleChangeCheckBox={handleChangeCheckBox}
                      setFieldValue={setFieldValue}
                      setIsExpireOnValid={setIsExpireOnValid}
                    />
                  </CaijCard>
                </Root>
                <Root>
                  <Memo>
                    <SubscriptionAccess 
                      subscription={subscription}
                      setSelectedProducts={setSelectedSubscriptionProducts}
                      selectedProducts={selectedSubscriptionProducts}
                      setSelectedDatabanks={setSelectedSubscriptionDatabanks}
                      selectedDatabanks={selectedSubscriptionDatabanks}
                      setSelectedDocCollections={setSelectedSubscriptionDocCollections}
                      selectedDocCollections={selectedSubscriptionDocCollections}
                      setSelectedParameters={setSelectedParameters}
                    />
                  </Memo>
                </Root>
                <Root>
                  <CaijTextarea
                    name={model.Note.Name}
                    id={model.Note.Name}
                    rows="4"
                    margin="none"
                    value={values.note || ''}
                    label={model.Note.Label}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{'aria-label': model.Note.Name, 'data-testid': model.Note.Name }}
                    onHandleChange={handleChange}
                  />
                </Root>
              </Box>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={subscription.id ? Mode.edit : Mode.add}
                onIsAuth={(l) => setIsAuth(l)}
              >
                <CaijButtonSubmit disabled={!isAuth || isSubmitting || !isExpireOnValid} sx={btnSubmit} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

SubscriptionEditForm.propTypes = {
  subscription: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

SubscriptionEditForm.defaultProps = {
  onSubmit: null
};

export default SubscriptionEditForm;
