export const publishVersion = {
    margin: '0.5em 0.5em 0.5em 0'
};

export const lawState = {
    background: '#404040', 
    padding: 1
}

export const filters = {
    margin: '0em 0.5em 0em 0.5em'
};