import { FC } from 'react';
import { Box, Button, Typography, SvgIcon } from '@mui/material';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import type { CustomerResource } from 'src/common/types';
import { 
  Formik, 
  AccessModel,
  CardRequestPendingModel,
  MSG_SEND_EMAIL_MEMBER,
  CustomerStatus
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import CustomerModel from 'src/model/customer/Customer';
import { ArrowLeftCircle } from 'react-feather';
import CaijCardForm from 'src/components/customer/customer/Forms/CaijCardForm';
import { XSquare as CancelIcon, CheckSquare as SaveIcon } from 'react-feather';
import { box4, btnMarginRight, btnReturn, marginTop, warning } from 'src/styles/customer';
import MyCard from 'src/components/card/MyCard';
import CarteCaij from 'src/components/customer/customer/Details/CarteCaij';

interface CaijCardReplaceFormProps {
  cardRequestPendingModel: CardRequestPendingModel;
  model: CustomerModel;
  customer: CustomerResource;
  onHandleRefreshCustomer: () => Promise<void>;
  modifyCaijCard: (action: string) => void;
  onSubmit?: (values: CustomerResource) => void;
}

const icon = {
  height: '2em',
  width: '2em',
}

const CaijCardReplaceForm: FC<CaijCardReplaceFormProps> = ({ 
  model,
  cardRequestPendingModel,
  customer, 
  modifyCaijCard, 
  onHandleRefreshCustomer,
  onSubmit
}) => {
  const accessCarteCaijModel = new AccessModel(cardRequestPendingModel.ResourceCode);
  const accessCustomerModel = new AccessModel(model.ResourceCode);

  const handleSubmit = async (customer: CustomerResource): Promise<void> => {
    if(onSubmit){
      onSubmit(customer);
      return;
    }
    const model = new CustomerModel();
    if (customer && customer.id) {
		const response = await model.assignCard(customer.id, customer.card);
		if (!model.error) {
      const { status, message } = response;
      if(customer.status === CustomerModel.getStatusByVal(CustomerStatus.Active)){
        model.printEmailMessage(status,MSG_SEND_EMAIL_MEMBER +  'pour l\'informer qu\'une nouvelle carte lui a été assignée');
      }
      printMessage({status,message});
			await onHandleRefreshCustomer();
		}
	}
  }; 

  return (
    <Formik
      initialValues={CustomerModel.getInitialValues(customer)}
      validationSchema={Yup.object().shape({
        card: model.MemberCard.required(true)
      })}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ handleSubmit, handleBlur, values, touched, errors, handleChange, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Button
              onClick={() => modifyCaijCard('return')}
              sx={btnReturn}
              startIcon={
                <SvgIcon sx={icon} fontSize='small'>
                  <ArrowLeftCircle />
                </SvgIcon>
              }
            >
              Remplacer la carte
            </Button>
            <CarteCaij>
              <MyCard>
                <Box sx={box4}>
                  <Box sx={warning}>
                    <Typography>
                      L'ancienne carte ne sera plus active. Le solde du compte pourra être utilisé avec la nouvelle carte.
                    </Typography>
                    <Typography>Cette action est irréversible.</Typography>
                  </Box>
                  <CaijCardForm
                    model={model} 
                    customer={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </MyCard>
            </CarteCaij>
            <Box sx={marginTop}>
                <Button
                  type='submit'
                  disabled={!accessCustomerModel.UserAdmin && (accessCustomerModel.UserUser && !accessCarteCaijModel.Edition && !accessCarteCaijModel.Gestion) || isSubmitting}
                  sx={btnMarginRight}
                  color='secondary'
                  variant='contained'
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <SaveIcon />
                    </SvgIcon>
                  )}
                >
                  Émettre la nouvelle carte
                </Button>
                <Button 
                  type='reset' 
                  onClick={() => modifyCaijCard('return')} 
                  color='secondary' variant='outlined'
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <CancelIcon />
                    </SvgIcon>
                  )}
                >
                  Annuler
                </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CaijCardReplaceForm.propTypes = {
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired,
  modifyCaijCard: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

CaijCardReplaceForm.defaultProps = {
  onSubmit: null
};

export default CaijCardReplaceForm;
