import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FORMAT_MONTH_PICKER } from 'src/common';
import { Box } from '@mui/material';
import { CaijLocalizationProvider } from './LocalizationProvider';

interface CaijMonthPickerProps {
  name: string;
  value?: number;
  label?: string;
  helperText?: ReactNode;
  required?: boolean;
  inputProps?: {'data-testid'?: string, 'aria-label'?:string};
  onHandleChange?: (date: number | string) => void;
}

const getMonth = (date: Date) => {
  const m = date.getMonth() + 1;
  if (m.toString().length === 1) {
      return `0${m}`;
  } else {
      return m;
  }
};

const getDateFromMonth = (date: Date, value: number) : Date => {
  date.setMonth(value - 1);
  return date;
};

const CaijMonthPicker: FC<CaijMonthPickerProps> = ({
  value,
  label,
  inputProps,
  helperText,
  required,
  onHandleChange,
  ...rest
}) => {
  const [cleared, setCleared] = useState<boolean>(true);
  const [month, setMonth] = useState<(Date | null)>(null);
 
  useEffect(() => {
    const d = new Date();
    if(value){
      setMonth(getDateFromMonth(d, value));
    }
  },[]);

  return (
    <Box display="flex" flexDirection="column">
      <CaijLocalizationProvider>
        <DatePicker
          {...rest}
          label={label}
          value={month}
          format={FORMAT_MONTH_PICKER}
          openTo='month'
          views={["month"]}
          slotProps={{
            field: { clearable: cleared, onClear: () => setCleared(false) },
            textField: {
              helperText: helperText,
              inputProps: inputProps,
              required: required,
              InputLabelProps: {shrink: true}
            },
          }}
          onChange={(e) => {
            if(e){
              onHandleChange(getMonth(e));
              setCleared(true);
            }
          }}
        />
      </CaijLocalizationProvider>
    </Box>
  );
}

CaijMonthPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  onHandleChange: PropTypes.func.isRequired
};

export default CaijMonthPicker;
