import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import { 
  useLocation,
  fetchEmployees,
  _employee,
  EmployeeModel,
  AccessModel,
  deleteEmployee,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  employees: [],
  isLoading: false,
  isEmptyList: true,
};

const EmployeeListView: FC = () => {
  const model = EmployeeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_employee, initialState);

  const getEmployees = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new EmployeeModel();
      model.PathName = location.pathname;
      const employees = await model.getEmployees();
      if (!model.error) {
        dispatch(fetchEmployees(employees, true, !Array.isArray(employees) || employees.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getEmployees();
    })();
  },[]);

  const handleDelete: (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteEmployee(id));
    }
  };

  const { employees, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results 
            model={model}
            employees={employees} 
            onDeleteEmployee={handleDelete} 
            isEmptyList={isEmptyList} 
            dispatch={dispatch}
            getEmployees={getEmployees}
          /> 
        </Box>
      </Container>
    </Page>
  );
};

export default EmployeeListView;
