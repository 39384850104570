import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import { LibraryModel, tableRow } from 'src/common';
import CaijImage from 'src/components/image/CaijImage';
import { tableCell } from 'src/styles/library';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import parse from 'html-react-parser';

export interface LibraryFrInfoProps {
  model: LibraryModel;
  library: LibraryResource;
}

const LibraryFrInfo: FC<LibraryFrInfoProps> = ({
  model,
  library
}) => {
  const { nameFr, descriptionFr, imgUrlFr, quoteFr, tempClosingMsgFr, staffFr } = library;
  const { NameFr, ImageUrlFr, TempClosingMsgFr, StaffFr, QuoteFr, DescriptionFr } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Bibliothèque" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              { NameFr.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                {nameFr}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { DescriptionFr.Label }
            </TableCell>
            <TableCell>
              {
                descriptionFr && (
                  <div>{parse(descriptionFr)}</div>
                )
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { ImageUrlFr.Label }
            </TableCell>
            <TableCell>
              { imgUrlFr && (<CaijImage id="imgUrlFr" src={imgUrlFr} alt={imgUrlFr} />) }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { QuoteFr.Label }
            </TableCell>
            <TableCell>
              {
                quoteFr && (
                  <div>{parse(quoteFr)}</div>
                )
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { TempClosingMsgFr.Label }
            </TableCell>
            <TableCell>
              {
                tempClosingMsgFr && (
                  <div>{parse(tempClosingMsgFr)}</div>
                )
              }
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              { StaffFr.Label }
            </TableCell>
            <TableCell>
              {
                staffFr && (
                  <div>{parse(staffFr)}</div>
                )
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LibraryFrInfo.propTypes = {
  library: PropTypes.object.isRequired
};

export default LibraryFrInfo;
