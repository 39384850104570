import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentResource } from 'src/common/types';
import {Box,SvgIcon} from '@mui/material';
import {DocumentModel, labelConfig} from 'src/common';
import { FilePlus as FileIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import CaijLabel from 'src/components/label/CaijLabel';

export interface GeneralInfoProps {
  document: DocumentResource;
  model: DocumentModel;
}

const getMetadata = (metadata: string) => {
  try{
    const metadatas = JSON.parse(metadata);
    const ptv = ';'
    if(metadatas){
      return Object.keys(metadatas).map((key: string, idx: number) => {
        if(metadatas[key].indexOf(ptv) !== -1){
          return (
            <div key={idx}>
              <li style={{marginLeft: '20px'}}>
                {`${key} => `}
              </li>
              <ul>{metadatas[key].split(ptv).map((value: string, index: number) => <li style={{ marginLeft: '40px'}} key={index}>{value}</li>)}</ul>
            </div>
          )
        }else{
          if(key === 'pdfurl'){
            return (
              <li style={{marginLeft: '20px'}} key={key}>
                <span>{`${key} => `}</span>
                <a href={metadatas[key]} target='_blank' style={{textDecoration: 'none',color: '#ffffff'}}>{metadatas[key]}</a>
              </li>
            )
          }
          return (
            <li style={{marginLeft: '20px'}} key={key}>{`${key} => ${metadatas[key]}`}</li>
          )
        }
      })
    }
  }catch(ex){}
  return null;
}

const GeneralInfo: FC<GeneralInfoProps> = ({model, document}) => {
  const { documentId, title, identifier, documentType, filepath, lang, otherLangDocId, metadatas } = document;
  return (
    <Box sx={{mb:'2em'}} display='flex'>
      <SvgIcon fontSize='small'>
        <FileIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <CaijTypography sx={{ fontWeight: 'bold' }}>Informations générale</CaijTypography>
        { documentId && <CaijLabel label={model.DocumentId}>{documentId}</CaijLabel> }
        { title && <CaijLabel label={model.Titre.Label}>{title.trim()}</CaijLabel> }
        { identifier && <CaijLabel label={model.Identifier.Label}>{identifier}</CaijLabel> }
        { documentType && <CaijLabel label={model.DocumentType.Label}>{documentType}</CaijLabel> }
        { filepath && <CaijLabel label={model.Filepath.Label}>{filepath}</CaijLabel> }
        { lang && <CaijLabel label={model.Lang.Label}>{DocumentModel.getDocLanguageByKey(lang)}</CaijLabel> }
        { otherLangDocId && <CaijLabel label={model.OtherLangDocId.Label}>{otherLangDocId}</CaijLabel> }
        { metadatas && <CaijLabel label={model.Metadatas.Label}>
            <div>
              <ul>{getMetadata(metadatas)}</ul>
            </div>
          </CaijLabel>
        }
      </Box>
    </Box>
  );
};

GeneralInfo.propTypes = {
  document: PropTypes.object.isRequired
};

export default GeneralInfo;