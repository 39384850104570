import axios from 'axios';
import { 
  apiConfig, 
  Unauthorized, 
  Forbidden, 
  MSG_SESSION_EXPIRE, 
  MSG_FORBIDEN_ERROR,
  ServiceUnavailable, 
  MSG_SERVICE_UNAVAILABLE_ERROR, 
  Conflit, 
  MSG_CONFLIT_ERROR, 
  MSG_CUSTOMER_CARD_EXISTS, 
  MSG_DUPLICATE_FOLDER_NAME 
} from 'src/common';
import type { Error } from 'src/common/types';
import AppModel from 'src/model/App';

const axiosInstance = axios.create({
  baseURL: apiConfig.url,
  headers: {
    'Content-Type': 'application/json'
  }
});

const token = localStorage.getItem('accessToken');
axiosInstance.defaults.headers.common = { Authorization: `Bearer ${token}` };

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const model = new AppModel();
    const err = error.toJSON() as Error;
    if (err.message.indexOf('401') >= 0) {
      model.error = { status: Unauthorized, message: MSG_SESSION_EXPIRE };
      return Promise.reject<Error>((model.error));
    }else if(err.message.indexOf('403') >= 0) {
      model.error = { status: Forbidden, message: MSG_FORBIDEN_ERROR };
      return Promise.reject<Error>((model.error));
    }else if(err.message.indexOf('Network Error') >= 0){
      model.error = { status: ServiceUnavailable, message: MSG_SERVICE_UNAVAILABLE_ERROR };
      return Promise.reject<Error>((model.error));
    }else if(err.message.indexOf('409') >= 0){
      switch(error.response.data.message){
        case 'MSG_CUSTOMER_CARD_EXISTS':
          model.error = { status: Conflit, message: MSG_CUSTOMER_CARD_EXISTS };
          break;
        case 'MSG_DUPLICATE_FOLDER_NAME':
          model.error = { status: Conflit, message: MSG_DUPLICATE_FOLDER_NAME };
          break;
        default:
          model.error = { status: Conflit, message: MSG_CONFLIT_ERROR };   
      }
      return Promise.reject<Error>((model.error));
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;