import { ReperageModel } from 'src/common';
// import type { ProductResource } from 'src/common/types';
import * as actionTypes from './ActionTypes';
import { ReperageResource } from 'src/common/types';
import Pagination, { Paged } from 'src/types/pagination';

export const loadingReperage = (): {
  type: 'LOADING_REPERAGE';
} => ({
  type: actionTypes.LOADING_REPERAGE,
})

export const fetchReperages = (
  reperages: ReperageResource[],
  paged?: Paged,
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean
): {
  type: 'FETCH_REPERAGES';
  payload: {
    reperages: ReperageResource[];
    paged: Paged;
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
} => ({
  type: actionTypes.FETCH_REPERAGES,
  payload: {
    reperages,
    paged,
    isLoading,
    isEmptyList,
    isAuthorize
  },
});

export const fetchReperage = (reperage: ReperageResource, isLoading?: boolean) : {
  type: 'FETCH_REPERAGE';
  payload: {
    reperage: ReperageResource;
    isLoading?: boolean;
  };
} => ({
  type: actionTypes.FETCH_REPERAGE,
  payload: {
    reperage,
    isLoading
  },
});

