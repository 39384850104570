import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { Resource } from 'src/common/types';
import ResourceInfo from './ResourceInfo';
import OtherActions from './OtherActions';

interface DetailsProps {
  resource?: Resource;
}

const Details: FC<DetailsProps> = ({
  resource
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={8}
      md={8}
      xl={8}
      xs={12}
    >
      <ResourceInfo resource={resource} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <OtherActions resource={resource} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  resource: PropTypes.object
};

Details.defaultProps = {
  resource: {}
};

export default Details;
