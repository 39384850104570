import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { Forbidden, Unauthorized } from 'src/common';
import { Resource, Error } from 'src/common/types';
import ResourceModel from 'src/model/employee/Resource';
import printMessage from 'src/views/errors/MessageError';

export interface ResourceContextValue {
  resources: Resource[];
  isAuthorize?: boolean;
  isLoading?: boolean;
  isEmptyList?: boolean;
  error?: Error;
}

interface ResourceProviderProps {
  children: ReactNode;
}

type fetchResourcesAction = {
  type: 'FETCH_RESOURCES';
  payload: {
    resources: Resource[];
    isAuthorize?: boolean;
    isLoading?: boolean;
    isEmptyList?: boolean;
    error?: Error;
  };
};

type Action = fetchResourcesAction;

interface ResourceState {
  resources: Resource[];
  isLoading: boolean;
  isAuthorize: boolean;
  isEmptyList: boolean;
  error: Error;
}

const initialResourceState = {
  resources: [],
  isLoading: false,
  isAuthorize: false,
  isEmptyList: false,
  error: null
};

const reducer = (state: ResourceState, action: Action): ResourceState => {
  switch (action.type) {
    case 'FETCH_RESOURCES': {
      const { resources, isAuthorize, isLoading, isEmptyList, error } = action.payload;
      return {
        ...state,
        resources,
        isAuthorize,
        isLoading,
        isEmptyList,
        error
      };
    }
    default: {
      return { ...state };
    }
  }
};

const ResourceContext = createContext<ResourceContextValue>({
  ...initialResourceState
});

export const ResourceProvider: FC<ResourceProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialResourceState);
  useEffect(() => {
    const initialise = async () => {
      const model = new ResourceModel();
      model.skipHandleError = true;
      const resources = await model.getResources();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch({
            type: 'FETCH_RESOURCES',
            payload: {
              resources,
              isAuthorize: false,
              isLoading: true,
              isEmptyList: true,
              error
            }
          });
        }else{
          dispatch({
            type: 'FETCH_RESOURCES',
            payload: {
              resources,
              isLoading: true,
              isAuthorize: true,
              isEmptyList: true,
              error
            }
          });
          printMessage(error);
        }
      }else{
        dispatch({
          type: 'FETCH_RESOURCES',
          payload: {
            resources,
            isLoading: true,
            isAuthorize: true,
            isEmptyList: !Array.isArray(resources) || resources.length === 0
          }
        });
      }
    };
    initialise();
  }, [dispatch]);

  return (
    <ResourceContext.Provider
      value={{
        ...state
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};

ResourceProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ResourceContext;
