import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { TribunalTypeResource } from 'src/common/types';
import { Grid } from '@mui/material';
import { labelConfig, CaijInput, FormikErrors, FormikTouched, TribunalTypeModel } from 'src/common';

interface TribunalTypeFormProps {
  model: TribunalTypeModel;
  tribunalType: TribunalTypeResource;
  errors: FormikErrors<TribunalTypeResource>;
  touched: FormikTouched<TribunalTypeResource>;
  handleBlur: (e: any) => void;
  onHandleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const TribunalTypeForm: FC<TribunalTypeFormProps> = ({
  model,
  tribunalType,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const { LabelFr, LabelEn } = model;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={LabelFr.Name}
          required
          value={tribunalType.labelFr}
          label={LabelFr.Label}
          error={Boolean(touched.labelFr && errors.labelFr)}
          helperText={touched.labelFr && errors.labelFr}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: LabelFr.MaxLength, 'data-testid': LabelFr.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
        <CaijInput
          name={LabelEn.Name}
          required
          value={tribunalType.labelEn}
          label={LabelEn.Label}
          error={Boolean(touched.labelEn && errors.labelEn)}
          helperText={touched.labelEn && errors.labelEn}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: LabelEn.MaxLength, 'data-testid': LabelEn.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
};

TribunalTypeForm.propTypes = {
  tribunalType: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default TribunalTypeForm;
