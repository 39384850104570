import { FC, useState } from "react";
import { Authorize, CaijButtonDeleteOtherAction, Mode } from "src/common";
import CaijDialogs from "src/components/dialog";
import AppModel from "src/model/App";

interface HeaderButtonProps {
    id: string|number;
    model?: AppModel;
    mode: Mode;
}

const HeaderDeleteButton: FC<HeaderButtonProps> = ({ id, mode, model }) => {
    let [isDeleteAuth, setIsDeleteAuth] = useState<boolean>(false)
    let [openDialog, setOpenDialog] = useState<boolean>(false)
    
    return (
        <Authorize
            resourceCode={model.ResourceCode}
            mode={mode}
            onIsAuth={l => setIsDeleteAuth(l)}>
            <CaijButtonDeleteOtherAction 
                size="large" 
                sx={{width: '15px', paddingLeft: '35px'}} 
                color="error"
                variant="contained" 
                onDelete={() => setOpenDialog(true)} 
                disabled={!isDeleteAuth}
                />
            <CaijDialogs
                dialog={model.Dialog}
                isOpen={openDialog}
                onSubmit={async () => {
                    await model.delete(id, true);
                    setOpenDialog(false);
                }}
                close={() => setOpenDialog(false)}
                />
        </Authorize>
    )
}

export default HeaderDeleteButton;