import React, { useState, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, SvgIcon } from '@mui/material';
import { 
  CaijTextarea,
  CaijTooltip,
  Mode,
  TranslationModel
} from 'src/common';
import Authorize from 'src/components/Authorize';
import { Save as SaveIcon } from 'react-feather';

interface TranslationFormProps {
  id: number;
  rowVal: string;
  label: string;
  text: string;
  isSubmitting?: boolean;
  maxLength: number;
  onSave: (id: number, value: string) => void;
  onHandleBlur: (id: number, value: string) => void;
}

const TranslationForm: FC<TranslationFormProps> = ({
  id,
  rowVal,
  label,
  text,
  isSubmitting,
  maxLength,
  onSave,
  onHandleBlur
}) => {
  const model = TranslationModel.getInstance();
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  const [disableButton, setDisableButton] = useState<boolean>(isSubmitting);
  const [helperText, setHelpertext] = useState<string>('');
  const [error, setError] = useState<boolean>();
  const [value, setValue] = useState<string>(rowVal);
  
  useEffect(() => {
    if(isSubmitting) {
    setDisableButton(isSubmitting);
    }
  }, [setDisableButton, isSubmitting]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if(!value){
      setDisableButton(true);
      setError(true);
      setHelpertext(`${label} est obligatoire`);
      setValue(value);
      return;
    }
    setDisableButton(false);
    setError(false);
    setHelpertext('');
    setValue(value);
  };
  
  const save = (id: number, value: string) => {
    setDisableButton(true);
    onSave(id, value);
  };

  return (
    <>
     <Box pb={1} display="flex" flexDirection="row" alignItems="center">
        <Box sx={{width: '90%'}}>
          <Authorize
            resourceCode={model.ResourceCode}
            mode={Mode.edit}
            onIsAuth={(l) => setIsEditAuth(l)}
          >
            <CaijTextarea
              label={label}
              name={`${text}-${id}`}
              id={`${text}-${id}`}
              required
              rows={4}
              disabled={!isEditAuth}
              value={value}
              error={error}
              InputLabelProps={{required: true}}
              helperText={helperText}
              inputProps={{maxLength}}
              onHandleChange={(e) => handleChange(e)}
              onHandleBlur={() => onHandleBlur(id, value)}
            />
          </Authorize>
        </Box>
        <Authorize
            resourceCode={model.ResourceCode}
            mode={Mode.edit}
            onIsAuth={(l) => setIsEditAuth(l)}
        >
            <Box sx={{marginTop: '10px', ml: '10px', display: disableButton ? 'none' : 'block'}}>
              <CaijTooltip title="Sauvegarde" marginTop="18px">
                <IconButton title='SAUVEGARDE' name={`${text}-${id}`} disabled={!isEditAuth} onClick={(e) => save(id, value)}>
                  <SvgIcon fontSize='small'>
                    <SaveIcon />
                  </SvgIcon>
                </IconButton>
              </CaijTooltip>
            </Box>
        </Authorize>
      </Box>
    </>
  );
};

TranslationForm.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onHandleBlur: PropTypes.func.isRequired
};

export default TranslationForm;
