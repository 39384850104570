import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { EmployeesResource } from 'src/common/types';
import { 
  Divider, 
  Link, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  Typography
} from '@mui/material';
import { labelConfig, EmployeeRoleLabel, tableRow, link, EmployeeModel } from 'src/common';
import Label from 'src/components/Label';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/employee';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface EmployeeInfoProps {
  model: EmployeeModel;
  employee: EmployeesResource;
}

const EmployeeInfo: FC<EmployeeInfoProps> = ({ model, employee }) => {
  const { Titre, Email, Card } = model;
  return (
    <MyCard>
      <CardHeaderDetail title='Utilisateur' />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>{labelConfig.nameFr}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {`${employee.firstname} ${employee.lastname}`}
              </Typography>
            </TableCell>
          </TableRow>
         <TableRow>
            <TableCell sx={tableCell}>{Titre.Label}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {employee.title}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>{labelConfig.role}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {EmployeeRoleLabel[employee.role]}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>{Email.Label}</TableCell>
            <TableCell>
              <Link 
                underline="none"
                href={`mailto:${employee.email}`} 
                sx={{...link, color:'text.secondary'}} 
              >
                {employee.email}
              </Link>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>{Card.Label}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {employee.card}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>{labelConfig.status}</TableCell>
            <TableCell>
              <Label color={employee.active ? 'success' : 'error'}>
                { employee.active ? labelConfig.active : labelConfig.inactive}
              </Label>
            </TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

EmployeeInfo.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default EmployeeInfo;
