
import { ErrorType, LawPracticeFieldModel } from 'src/common';
import { LawPracticeFieldResource } from 'src/common/types';
import useLawPraticeField from 'src/hooks/useLawPraticeField';
import * as Yup from 'yup';

const validateSchema = (model: LawPracticeFieldModel, lawPracticeField: LawPracticeFieldResource) => {
  const { verifyLawPraticeFieldCode } = useLawPraticeField();
  const { Code, NameFr, NameEn } = model;
  return Yup.object().shape({
  nameEn: NameEn.max(),
  nameFr: NameFr.max(),
  code: Code.required(true)
    .test(Code.Label, LawPracticeFieldModel.formatError(ErrorType.exist, Code.Label), (value: string) => {
      if (value) {
        return verifyLawPraticeFieldCode(value, lawPracticeField.code);
      }
      return true;
    })
  })
};

export default validateSchema;