import { LibraryModel } from 'src/common';
import type { LibraryResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchLibraries = (
  libraries: LibraryResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
  model?: LibraryModel
): {
  type: 'FETCH_LIBRARIES';
  payload: { libraries: LibraryResource[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean, model?: LibraryModel };
} => ({
  type: actionTypes.FETCH_LIBRARIES,
  payload: {
    libraries,
    isLoading,
    isEmptyList,
    isAuthorize,
    model
  },
});

export const fetchLibrary = (library: LibraryResource, isLoading?: boolean) : { 
  type: 'FETCH_LIBRARY'; 
  payload: { 
    library: LibraryResource, 
    isLoading?: boolean 
  } 
} => ({
  type: actionTypes.FETCH_LIBRARY,
  payload: {
    library,
    isLoading
  },
});

export const fetchDetailsLibrary = (
  library: LibraryResource,
): {
  type: 'FETCH_DETAILS_LIBRARY';
  payload: {
    library: LibraryResource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_LIBRARY,
  payload: {
    library,
  },
});

export const deleteLibrary = (id: number): {
  type: 'DELETE_LIBRARY';
  payload: {
    id: number,
  };
} => ({
  type: actionTypes.DELETE_LIBRARY,
  payload: {
    id,
  },
});
