import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { 
  Box, 
  Card, 
  ClassNameMap, 
  Table, 
  TableBody, 
  TableCell, 
  tableCellClasses, 
  TableHead, 
  TableRow, 
  Typography 
} from "@mui/material";
import { 
  CustomerModel,
  formatDateTimeZoneTZ2, 
  getCustomerStatusLabelColor, 
  Label, 
  labelConfig, 
  tableRow 
} from "src/common";
import { tableCell, title } from 'src/styles/customer';
import { styled } from '@mui/material/styles';
import HistoryModel, { CustomerAddressDto, LawDomainDto } from 'src/model/customer/History';
import { EmployeesResource } from "src/common/types";
import CompareHistory from './CompareHistory';
import { ReactNode } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#28282B',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type RenderIconNoteProps = {
  add?: boolean, 
  addIconStyle?: { color: string }, 
  deletedIconStyle?: { color: string }, 
  children: ReactNode
}

const RenderIconNote = (props: RenderIconNoteProps) : JSX.Element => {
  return (
    <Box display="flex" alignItems="center">
      { props.add ? <AddCircleIcon sx={{...props.addIconStyle, mr: 1}} /> : <RemoveCircleIcon sx={{...props.deletedIconStyle, mr: 1}} /> }
      { props.children }
    </Box>
  )
}

export default class CustomerHistory extends CompareHistory
{
  private getStatus(status : number) : string {
    if(status === 0){
      return 'Inactive';
    }else if(status === 1){
      return 'Active';
    }else if(status === 2){
      return 'ActiveUponApproval';
    }else{
      return 'ActiveOnLogin';
    }
  }
  
  private getRole(role: number) : string {
    if(role === 0){
      return 'User';
    }else if(role === 1){
      return 'Admin'
    }else if(role=== 3){
      return 'Owner';
    }
  };

  public getCustomerInfo = (history: HistoryModel, prevHistory: HistoryModel, note: string) : JSX.Element => {
    const { 
      FullName, FirstName, LastName, BirthDay, Email, Phone, ExtPhone, CellPhone,
      Job, Organization, NoCaij, Card, Language, WfProfile, WfLibrary, ExtIdentifier,
      LcapNewsletter, LcapImprovement, LcapCommercials, Status, Role, PendingValue,
      Expiration, Created, AdditionalInfo, MemberAdditionalInfo, MemberAccessBetaCaij, AccessBetaCaij,
      MemberExtIdentifier, LogoUrl
    } = history;
    const hasLogo = LogoUrl !== null;
    return (
      <Box mb={1}>
        <Typography variant="h6" component="div" sx={title}>
          Information personnelle
        </Typography>
        <Table size="small" aria-label="Information personnelle">
          <TableBody>
            <TableRow sx={tableRow}>
              <TableCell sx={{...tableCell, width: "23%" }}>
                { this.compareTo(FullName, prevHistory.FullName, labelConfig.nameFr) }
                { this.compareTo(FirstName, prevHistory.FirstName, labelConfig.firstname) }
                { this.compareTo(LastName, prevHistory.LastName, labelConfig.lastname) }
                { this.compareTo(BirthDay, prevHistory.BirthDay, labelConfig.birthdate) }
                { this.compareTo(Phone, prevHistory.Phone, labelConfig.contactPhone) }
                { this.compareTo(ExtPhone, prevHistory.ExtPhone, labelConfig.contactPhoneExt) }
                { this.compareTo(CellPhone, prevHistory.CellPhone, labelConfig.cellPhone) }
                { this.compareTo(Email, prevHistory.Email, labelConfig.contactEmail) }
                { this.compareTo(PendingValue, prevHistory.PendingValue, labelConfig.pendingValue) }
                { this.compareTo(
                    this.getStatus(+Status),
                    this.getStatus(+prevHistory.Status), 
                    labelConfig.status, 
                    <Label color={getCustomerStatusLabelColor(this.getStatus(+Status))}>{CustomerModel.getStatusByKey(this.getStatus(+Status))}</Label>,
                  ) 
                }
                { this.compareTo(this.getRole(+Role), this.getRole(+prevHistory.Role), labelConfig.role) }
                { this.compareBoolean(
                    hasLogo, 
                    prevHistory.LogoUrl !== null,
                    "Photo",
                    <Label color={hasLogo ? 'success' : 'error'}>
                      {hasLogo ? labelConfig.yes : labelConfig.no}
                    </Label>
                  ) 
                }
              </TableCell>
              <TableCell  sx={tableCell}>
                { this.compareTo(Created, prevHistory.Created, labelConfig.createdDate) }
                { this.compareTo(Expiration, prevHistory.Expiration, labelConfig.expiryDate) }
              </TableCell>
              <TableCell sx={tableCell}>
                { this.compareTo(Job, prevHistory.Job, labelConfig.job) }
                { this.compareTo(Organization, prevHistory.Organization, labelConfig.organization) }
                { this.compareTo(NoCaij, prevHistory.NoCaij, labelConfig.noCaij) }
                { this.compareTo(ExtIdentifier, prevHistory.ExtIdentifier, MemberExtIdentifier.Label) }
                { this.compareTo(Card, prevHistory.Card, labelConfig.noCard, null, note) }
                { this.compareTo(Language, prevHistory.Language, labelConfig.language,) }
                { this.compareBoolean(
                    AccessBetaCaij, 
                    prevHistory.AccessBetaCaij,
                    MemberAccessBetaCaij.Label,
                    <Label color={AccessBetaCaij ? 'success' : 'error'}>
                      {AccessBetaCaij ? labelConfig.yes : labelConfig.no}
                    </Label>
                  ) 
                }
                { this.compareTo(Language, prevHistory.Language, labelConfig.language) }
                { this.compareTo(AdditionalInfo, prevHistory.AdditionalInfo, MemberAdditionalInfo.Label) }
              </TableCell>
              <TableCell sx={tableCell}>
                { this.compareTo(WfProfile, prevHistory.WfProfile, labelConfig.wfProfile) }
                { this.compareTo(WfLibrary, prevHistory.WfLibrary, labelConfig.wfLibrary) }
              </TableCell>
              <TableCell sx={tableCell}>
                { this.compareBoolean(
                    LcapCommercials, 
                    prevHistory.LcapCommercials,
                    labelConfig.lcapCommercials,
                    <Label color={LcapCommercials ? 'success' : 'error'}>
                      {LcapCommercials ? labelConfig.yes : labelConfig.no}
                    </Label>
                  ) 
                }
                { this.compareBoolean(
                    LcapNewsletter, 
                    prevHistory.LcapNewsletter, 
                    labelConfig.lcapNewsletter,
                    <Label color={LcapNewsletter ? 'success' : 'error'}>
                      {LcapNewsletter ? labelConfig.yes : labelConfig.no}
                    </Label>
                  ) 
                }
                { this.compareBoolean(
                    LcapImprovement,
                    prevHistory.LcapImprovement, 
                    labelConfig.lcapImprovement, 
                    <Label color={LcapImprovement ? 'success' : 'error'}>
                      {LcapImprovement ? labelConfig.yes : labelConfig.no}
                    </Label>
                  ) 
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    )
  }

  public getSubscriptionInfo = (history:HistoryModel, prevHistory: HistoryModel) : JSX.Element => {
    if(history.Subscription){
        return (
          <Box mb={1} mt={1}>
            <Typography variant="h6" component="div" sx={title}>
              Abonnement
            </Typography>
            <Table size="small" aria-label="Abonnement">
              <TableBody>
                <TableRow sx={tableRow}>
                  <TableCell sx={tableCell}>
                    { this.compareTo(history?.Subscription?.Name, prevHistory?.Subscription?.Name, labelConfig.nameFr) }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )
      }
  }

  public getGroupInfo = (history:HistoryModel, prevHistory:HistoryModel) : JSX.Element => {
    if(history.Group){
        const isDefault = history?.Group?.IsDefault;
        return (
          <Box mb={1} mt={1}>
            <Typography variant="h6" component="div" sx={title}>
              Groupe d'abonnement
            </Typography>
            <Table size="small" aria-label="Groupe d'abonnement">
              <TableBody>
                <TableRow sx={tableRow}>
                  <TableCell sx={tableCell}>
                    { this.compareTo(history?.Group?.Name, prevHistory?.Group?.Name, labelConfig.nameFr) }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        )
    }
  }

  public getLawDomainInfo = (history:HistoryModel, prevHistory:HistoryModel) : JSX.Element => {
    if(history.LawDomains && history.LawDomains.length > 0){
      return (
        <Box mb={1} mt={1}>
          <Typography variant="h6" component="div" sx={title}>
            Champs de pratique
          </Typography>
          <Card>
            <Table size="small" aria-label="Champs de pratique">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="10%">{labelConfig.code}</StyledTableCell>
                  <StyledTableCell width="45%">{labelConfig.nameFr}</StyledTableCell>
                  <StyledTableCell width="45%">{labelConfig.nameEn}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  history.LawDomains.map((lawDomain: LawDomainDto, index: number) => {
                      const { LawDomains } = prevHistory;
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {this.compareTo(lawDomain?.Code, LawDomains ? LawDomains[index]?.Code : '') }
                          </StyledTableCell >
                          <StyledTableCell>
                            {this.compareTo(lawDomain?.NameFr, LawDomains ? LawDomains[index]?.NameFr : '')}
                          </StyledTableCell>
                          <StyledTableCell>
                            {this.compareTo(lawDomain?.NameEn, LawDomains ? LawDomains[index]?.NameEn : '')}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                  })
                }
              </TableBody>
            </Table>
          </Card>
        </Box>
      )
    }
  }

  public getAddressInfo = (history:HistoryModel, prevHistory:HistoryModel) : JSX.Element => {
    if(history.Addresses && history.Addresses.length > 0){
      return (
        <Box mb={1} mt={1}>
          <Typography variant="h6" component="div" sx={title}>
            Adresse
          </Typography>
          <Card>
            <Table size="small" aria-label="Adresse">
              <TableHead>
                <TableRow>
                  <StyledTableCell>{labelConfig.nameFr}</StyledTableCell>
                  <StyledTableCell>{labelConfig.line1}</StyledTableCell>
                  <StyledTableCell>{labelConfig.line2}</StyledTableCell>
                  <StyledTableCell>{labelConfig.city}</StyledTableCell>
                  <StyledTableCell>{labelConfig.country}</StyledTableCell>
                  <StyledTableCell>{labelConfig.state}</StyledTableCell>
                  <StyledTableCell>{labelConfig.zip}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  history.Addresses.map((addresse : CustomerAddressDto, index: number) => {
                    const { Addresses } = prevHistory;
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {this.compareTo(addresse?.Name, Addresses ? Addresses[index]?.Name : '')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {this.compareTo(addresse?.Line1, Addresses ? Addresses[index]?.Line1 : '')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {this.compareTo(addresse?.Line2, Addresses ? Addresses[index]?.Line2 : '')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {this.compareTo(addresse?.City, Addresses ? Addresses[index]?.City : '')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {this.compareTo(addresse?.Country, Addresses ? Addresses[index]?.Country : '')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {this.compareTo(addresse?.State, Addresses ? Addresses[index]?.State : '')}
                        </StyledTableCell>
                        <StyledTableCell>
                          {this.compareTo(addresse?.Zip, Addresses ? Addresses[index]?.Zip : '')}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </Card>
        </Box>
      )
    }
  }

  public getCustomerEndorsedInfo = (history:HistoryModel, prevHistory:HistoryModel) : JSX.Element => {
    if(history.CustomerEndorsed && history.CustomerEndorsed.length > 0){
        return (
          <Box mb={1} mt={1}>
            <Typography variant="h6" component="div" sx={title}>
              Cautionnement
            </Typography>
            <Card>
              <Table size="small" aria-label="Cautionnement">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{labelConfig.noCaij}</StyledTableCell>
                    <StyledTableCell>Nom</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {
                      history.CustomerEndorsed.map(({NoCaij, Fullname}, index: number) => {
                        const { CustomerEndorsed } = prevHistory;
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              {this.compareTo(NoCaij, CustomerEndorsed ? CustomerEndorsed[index]?.NoCaij : '')}
                            </StyledTableCell>
                            <StyledTableCell>
                              {this.compareTo(Fullname, CustomerEndorsed ? CustomerEndorsed[index]?.Fullname: '')}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })
                    }
                </TableBody>
              </Table>
            </Card>
          </Box>
        )
      }
  }

  public getCustomerNoteInfo = (history:any, prevHistory:any, employees: EmployeesResource[], stateBefore: string) : JSX.Element => {
    let addedEmployee: EmployeesResource;
    let deletedEmployee:EmployeesResource;
    let deletedNote: any;
    if(history !== null){
      addedEmployee = employees.find((employee:EmployeesResource) => employee.email === history['CreatedBy']);
    }else{
      deletedNote = JSON.parse(stateBefore);
      deletedEmployee = employees.find((employee:EmployeesResource) => employee.email === deletedNote['CreatedBy']);
    }
    let deleted = history === null && prevHistory !== null;
    if(history == null && prevHistory == null){
      deletedNote = JSON.parse(stateBefore);
      deletedEmployee = employees.find((employee:EmployeesResource) => employee.email === deletedNote['CreatedBy']);
      deleted = true;
    }
    return (
        <Box mb={1} mt={1}>
          <Typography variant="h6" component="div" sx={title}>
            Notes
          </Typography>
          <Card>
            <Table size="small" aria-label="Notes">
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="55%">Note</StyledTableCell>
                    <StyledTableCell width="20%">Crée par</StyledTableCell>
                    <StyledTableCell width="20%">{labelConfig.createdDate}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>
                      { deleted ? (
                          <RenderIconNote deletedIconStyle={this.deletedIconStyle}> 
                            <div>{deletedNote['Note']}</div>
                          </RenderIconNote>
                        ) : (
                          <RenderIconNote add addIconStyle={this.addIconStyle}> 
                            <div>{history['Note']}</div>
                          </RenderIconNote>
                        )
                      }
                    </StyledTableCell>
                    <StyledTableCell>
                      { deleted ? (
                          <RenderIconNote deletedIconStyle={this.deletedIconStyle}> 
                            <div>{deletedEmployee && deletedEmployee.fullname}</div>
                          </RenderIconNote>
                        ) : (
                          <RenderIconNote add addIconStyle={this.addIconStyle}> 
                            <div>{addedEmployee && addedEmployee.fullname}</div>
                          </RenderIconNote>
                        )
                      }
                    </StyledTableCell>
                    <StyledTableCell>
                      { deleted ? (
                          <RenderIconNote deletedIconStyle={this.deletedIconStyle}> 
                            <div>{formatDateTimeZoneTZ2(deletedNote['Created'], 11,19)}</div>
                          </RenderIconNote>
                        ) : (
                          <RenderIconNote add addIconStyle={this.addIconStyle}> 
                            <div>{formatDateTimeZoneTZ2(history['Created'], 11,19)}</div>
                          </RenderIconNote>
                        )
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
            </Table>
          </Card>
        </Box>
      );
    }
};
