import { EmailTemplateModel } from 'src/common';
import type { EmailTemplateDto } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchEmailTemplates = (
  emailTemplates: EmailTemplateDto[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
  model?: EmailTemplateModel
): {
  type: 'FETCH_EMAIL_TEMPLATES';
  payload: {
    emailTemplates: EmailTemplateDto[];
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
    model?: EmailTemplateModel;
  };
} => ({
  type: actionTypes.FETCH_EMAIL_TEMPLATES,
  payload: {
    emailTemplates,
    isEmptyList,
    isLoading,
    isAuthorize,
    model
  },
});

export const fetchEmailTemplate = (emailTemplate: EmailTemplateDto, isLoading?: boolean) : {
  type: 'FETCH_EMAIL_TEMPLATE';
  payload: {
    emailTemplate: EmailTemplateDto,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_EMAIL_TEMPLATE,
  payload: {
    emailTemplate,
    isLoading
  },
});

export const deleteEmailTemplate = (id: number) : {
  type: 'DELETE_EMAIL_TEMPLATE';
  payload: {
    id: number
  };
} => ({
  type: actionTypes.DELETE_EMAIL_TEMPLATE,
  payload: {
    id,
  },
});

export const fetchTags = (tags: Array<string>): {
  type: 'FETCH_TAGS';
  payload: {
    tags: Array<string>;
  };
} => ({
  type: actionTypes.FETCH_TAGS,
  payload: {
    tags
  },
});