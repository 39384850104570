import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  GeolocationModel,
} from 'src/common';
import type {
  Error,
  CreateResponse,
  LibraryResource,
  LibraryResourceForCreate,
  LibraryResourceForEdit,
  LibraryServiceResource,
  Contact,
  AddressDto,
  GoogleMaps,
  WorkingHour,
  LibraryServiceLibraryOptionsDto
} from 'src/common/types';
import AppModel from '../App';
import printMessage from 'src/views/errors/MessageError';
import axios from 'src/utils/axios';
import { FormModel } from '../Form';

type TypeResourceEdit = LibraryResourceForCreate | LibraryResourceForEdit;

interface TypeSelectedData {
  data: number[];
  selected: boolean;
};

type TypeMap = Map<number, string>;

interface TypeImageUrl {
   Fr: string;
   En: string;
};

export default class LibraryModel extends AppModel 
{
  private _selectedLibraryLibraryServiceParameters: TypeMap;
  private _selectedOpenHours: string;
 
  private static _instance: LibraryModel;
  readonly Enabled = new FormModel('enabled','Actif');
  readonly NameFr = new FormModel('nameFr','Nom',50);
  readonly CloakroomInfo = new FormModel('cloakroomInfo', 'Information vestiaire',1000);
  readonly RequiredParameter = new FormModel('requiredParameter','', 11);
  readonly ImageUrlFr = new FormModel('imageUrlFr', 'Image',500);
  readonly ImageUrlEn =  new FormModel('imageUrlEn','Image', 500);
  readonly TempClosingMsgFr = new FormModel('tempClosingMsgFr','Fermeture temporaire');
  readonly TempClosingMsgEn =  new FormModel('tempClosingMsgEn', 'Temporary Closure');
  readonly DescriptionFr = new FormModel('descriptionFr','Description (Français)');
  readonly DescriptionEn =  new FormModel('descriptionEn', 'Description (English)');
  readonly QuoteFr = new FormModel('quoteFr','Citation');
  readonly QuoteEn =  new FormModel('quoteEn', 'Quote');
  readonly StaffFr = new FormModel('staffFr','Personnel');
  readonly StaffEn =  new FormModel('staffEn', 'Staff');
  readonly ByodPrinterUrl = new FormModel('byodPrinterUrl', 'Lien BYOD', 1024);

  //Contact
  readonly ContactTelephone =  new FormModel('telephone', 'Téléphone',45);
  readonly ContactFax =  new FormModel('fax', 'Fax',20);
  readonly ContactTollfree =  new FormModel('tollfree', 'Fax sans frais',45);

  //Address
  readonly AddressLine1 = new FormModel('addressFr.line1',"Ligne d'adresse 1", 200);
  readonly AddressLine2 = new FormModel('addressFr.line2',"Ligne d'adresse 2", 200);
  readonly AddressCity = new FormModel('addressFr.city',"Ville", 200);
  readonly AddressState = new FormModel('addressFr.state',"Province/État", 45);
  readonly AddressZip = new FormModel('addressFr.zip',"Code postal", 45);
  readonly AddressCountry = new FormModel('addressFr.country',"Pays", 75);

  constructor(){
    super('/library');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'LIBRARY';
    this._headerTitle = 'Liste des bibliothèques';
    this._btnAddText = 'Ajouter une bibliothèque';
    this.Path.PathName = '/libraries/list-of-libraries';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Bibliothèques',
      href: this.Path.Home,
      visible: true
    }
  }

  get SelectedOpenHours(){
    return this._selectedOpenHours;
  }

  set SelectedLibraryLibraryServiceParameters(selectedLibraryLibraryServiceParameters: TypeMap) {
    this._selectedLibraryLibraryServiceParameters = selectedLibraryLibraryServiceParameters;
  }

  get SelectedLibraryLibraryServiceParameters(): TypeMap {
    return this._selectedLibraryLibraryServiceParameters;
  }

  getHeadCells(status: string, type: string){
    return [
      {
        id: this.NameFr.Name, width: '45%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: 'libraryType.nameFr', width: '45%', numeric: false, disablePadding: false, label: type
      },
      {
        id: '', width: '10%', numeric: false, disablePadding: false, label: status
      },
      {
        id: '', width: '0%'
      }
    ];
  };

  static getInstance(): LibraryModel {
    if (!LibraryModel._instance) {
      LibraryModel._instance = new LibraryModel();
    }
    return LibraryModel._instance;
  }

  static getInitialValues(values: LibraryResource) : LibraryResource {
    return {
      ...values,
      descriptionFr: values.descriptionFr || '',
      descriptionEn: values.descriptionEn || '',
      contactUs: values.contactUs,
      openHours: values.openHours || '',
      longitude:  values.longitude || 0,
      latitude:  values.latitude || 0,
      addressFr:  values.addressFr || {
        line1: '',
        line2: '',
        city: '',
        zip: '',
        state: '',
        country: ''
      },
      addressEn:  values.addressEn || {},
      quoteEn:  values.quoteEn || '',
      quoteFr:  values.quoteFr || '',
      imgUrlFr:  values.imgUrlFr || '',
      imgUrlEn:  values.imgUrlEn || '',
      cloakroom:  values.cloakroom || false,
      enabled:  values.enabled || false,
      created:  values.created || new Date(),
      modified:  values.modified || new Date(),
      staffFr:  values.staffFr || '',
      staffEn: values.staffEn || '',
      byodPrinterUrl: values.byodPrinterUrl || '',
      libraryServices: values.libraryServices || [],
      databanks:  values.databanks || []
    }
  }

  async getLibraries(): Promise<LibraryResource[]> {
    let libraries: LibraryResource[];
    await axios.get<LibraryResource[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraries = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return libraries;
  }

  async getLibraryById(id: string): Promise<LibraryResource> {
    let library: LibraryResource;
    await axios.get<LibraryResource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            library = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return library;
  }

  async updateLibrary(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<LibraryResourceForEdit, Error>(`${this.route}/${submitData.id}`,submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Bibliothèque modifiée.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertLibrary(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<LibraryResourceForCreate, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Bibliothèque créée.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean  = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Bibliothèque supprimée.'
					});
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  async synchronize() : Promise<Error> {
    let result: Error;
    await axios.get<void, Error>(`${this.route}/synchro-all`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Bibliothèques synchronisées.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  static convertContactStringToJson(contactUs: string): Contact {
    if (contactUs) {
      return JSON.parse(contactUs);
    }
    return null;
  }

  static convertContactJsonToString(library: Contact): string {
    if (library) {
      return JSON.stringify({
        telephone: library.telephone,
        fax: library.fax,
        tollfree: library.tollfree,
      });
    }
    return null;
  }

  setSelectedOpenHours(selectedOpenHours: WorkingHour[]){
    let newSelectedOpenHours: any[] = [];
    selectedOpenHours.forEach((selectedOpenHour: WorkingHour) => {
      if(!selectedOpenHour.hours[0].start && !selectedOpenHour.hours[0].end){
        newSelectedOpenHours.push({day: selectedOpenHour.day, hours: []});
       }else{
        const start = selectedOpenHour.hours[0].start;
        const end = selectedOpenHour.hours[0].end;
        newSelectedOpenHours.push({day: selectedOpenHour.day, hours: [{start,end}]});
       }
    });
    this._selectedOpenHours = JSON.stringify(newSelectedOpenHours);
  };
  
  private getSelectedLibraryLibraryServicParameters = (key: number): string => {
    let data: string;
    if (this.SelectedLibraryLibraryServiceParameters && this.SelectedLibraryLibraryServiceParameters.has(key)) {
      data = this.SelectedLibraryLibraryServiceParameters.get(key);
    }
    return data;
  };

  getSubmitDataImageUrl(imgUrlFr: string, imgUrlEn: string): TypeImageUrl {
    return {
      Fr: imgUrlFr,
      En: imgUrlEn
    }
  }

  saveListDatabank(selectedLibraryDatabanks: TypeSelectedData, submitData: TypeResourceEdit){
    if (selectedLibraryDatabanks.selected) {
      const { data } = selectedLibraryDatabanks;
      submitData.databanks = data && data.length > 0 ? data.map((item) =>  ({id: item})) : [];
    }
  }

  saveListLibraryService(selectedLibraryLibraryServices: TypeSelectedData, submitData: TypeResourceEdit, libraryServices: LibraryServiceResource[]){
    if (selectedLibraryLibraryServices.selected) {
      const { data } = selectedLibraryLibraryServices;
      submitData.libraryServices = data.reduce((initialValues: LibraryServiceLibraryOptionsDto[], selectedLibraryService: number) => {
        const parameters = this.getSelectedLibraryLibraryServicParameters(selectedLibraryService);
        initialValues.push({
          service: libraryServices ? libraryServices.find((libraryServices: LibraryServiceResource) => libraryServices.id === selectedLibraryService) : null,
          parameters
        });
        return initialValues;
      }, []);
    }
  }

  saveRecords(values: LibraryResource, submitData: TypeResourceEdit){
    const imageUrl = this.getSubmitDataImageUrl(values.imgUrlFr, values.imgUrlEn);
    submitData.imgUrlFr = imageUrl.Fr;
    submitData.imgUrlEn = imageUrl.En;
    submitData.cloakroomInfo = values.cloakroom ? values.cloakroomInfo : '';
    submitData.openHours = this._selectedOpenHours;
    submitData.nameEn = values.nameFr;
  }

  async searchGeolocation(address: string): Promise<GoogleMaps> {
    if (address) {
      const geolocationModel = new GeolocationModel();
      const geolocations = await geolocationModel.getGeolocation(address);
      const { error } = geolocationModel;
      if (!error) {
        return geolocations;
      }else{
        printMessage(error);
      }
    }
  };

  validateAddressFields(city: string, state: string, zip:string, country: string) : boolean {
    const { AddressCity, AddressState, AddressCountry, AddressZip } = this;
    if(city.length > AddressCity.MaxLength ||
       state.length > AddressState.MaxLength ||
       country.length > AddressCountry.MaxLength ||
       zip.length > AddressZip.MaxLength) {
      return false;
    }
    return true;
  }
  
  getFullAddress(address: AddressDto) : string {
      const newAddress: AddressDto[] = [];
      const { id } = address;
      delete address.id;
      Object.values(address).forEach((value:AddressDto) => {
        if(value){
          newAddress.push(value);
        } 
      });
      address.id = id;
      return newAddress.join(" ");
  }

  async handleGeolocation(values: LibraryResource): Promise<GoogleMaps>{
      const { addressFr, addressEn} = values;
      let address ='';
      if(addressFr.line1 || addressFr.zip || addressFr.state && this.validateAddressFields(addressFr.city, addressFr.state, addressFr.zip, addressFr.country)) {
        address = this.getFullAddress(addressFr);
      }else if(addressEn.line1 || addressEn.zip || addressEn.state && this.validateAddressFields(addressEn.city, addressEn.state, addressEn.zip, addressEn.country)){
        address = this.getFullAddress(addressEn);
      }
      return await this.searchGeolocation(address); 
  } 

  verifyContactTelephone({ telephone } : Contact) : boolean{
    if(telephone && telephone.length > this.ContactTelephone.MaxLength){
      return false;
    }
    return true;
  }

  verifyContactFax({ fax } : Contact) : boolean{
    if(fax && fax.length > this.ContactFax.MaxLength){
      return false;
    }
    return true;
  }

  verifyContactTollfree({ tollfree } : Contact) : boolean {
    if(tollfree && tollfree.length > this.ContactTollfree.MaxLength){
      return false;
    }
    return true;
  }
}
