import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid } from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeImageUrl,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit,
  PartnersModel
} from 'src/common';
import type {
  PartnerResource,
  PartnerResourceForCreate,
  PartnerResourceForEdit,
  CreateResponse,
  Error
} from 'src/common/types';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';
import PartnersForm from 'src/components/content/partners/Forms';

export interface PartnersEditFormProps {
  model: PartnersModel;
  partner: PartnerResource;
  onSubmit?: (values: PartnerResource) => void;
}

async function redirect(model: PartnersModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const PartnersEditForm: FC<PartnersEditFormProps> = ({
  model,
  partner,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isCreate] = useState<boolean>(!partner.code);
  
  const handleSubmit = async (values:PartnerResource) => {
    if (onSubmit) {
		  onSubmit(values);
      return;
    }
    const newLogoUrlFr = values.logoUrlFr;
    const newLogoUrlEn = values.logoUrlEn;
    let submitData = values as PartnerResourceForEdit;
    submitData.logoUrlFr = newLogoUrlFr;
    submitData.logoUrlEn = newLogoUrlEn;
    const model = new PartnersModel();
    if (!isCreate) {
      const response = await model.updatePartner(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as PartnerResourceForCreate;
      submitData.logoUrlFr = newLogoUrlFr;
      submitData.logoUrlEn = newLogoUrlEn;
      const response = await model.insertPartner(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  }

  return (
    <Formik
      initialValues={{...partner}}
      validateOnChange={false}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                 <PartnersForm
                     model={model}
                     partner={values} 
                     isCreate={isCreate}
                     errors={errors}
                     touched={touched}
                     onHandleBlur={handleBlur}
                     onHandleChange={handleChange}
                     onHandleChangeImageUrl={handleChangeImageUrl}
                     setFieldValue={setFieldValue}
                 />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={partner.code ? Mode.edit : Mode.add}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

PartnersEditForm.propTypes = {
  partner: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

PartnersEditForm.defaultProps = {
  onSubmit: null
}

export default PartnersEditForm;
