const greyedColor = '#808080';

export const greyed = {
    marginTop: '1em',
    fontSize: '0.9em',
    color: greyedColor,
};


export const filters = {
    margin: '0em 0.5em 0em 0.5em'
};