import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@mui/material';
import {
  EmptyList,
  EnhancedTableHead,
  TribunalTypeModel
} from 'src/common';
import type { TribunalTypeResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: TribunalTypeModel;
  tribunalTypes: TribunalTypeResource[];
  onDeleteTribunalType: (id: number) => Promise<void>;
  isEmptyList: boolean;
}

const Results: FC<ResultsProps> = ({
  model,
  tribunalTypes,
  onDeleteTribunalType,
  isEmptyList
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const [page, setPage] = useState<number>(0);
  const { order, orderBy, sort, handleRequestSort } = useSort();

  return isEmptyList ? <EmptyList /> : (
    <MyCard>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={model.getHeadCells()}
        />
        <TableBody>
          { sort<TribunalTypeResource>(tribunalTypes, page).map((row: TribunalTypeResource) => (
              <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                <CaijTableCell>{row.labelFr}</CaijTableCell>
                <CaijTableCell>{row.labelEn}</CaijTableCell>
                <CaijTableCellAction 
                  pageEditUrl={path.getEdit(row.id)}
                  resourceCode={model.ResourceCode}
                  handleToggle={() => TribunalTypeModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                >
                  <CaijDialogs
                    dialog={model.Dialog}
                    isOpen={selectedRow.indexOf(row.id) !== -1}
                    onSubmit={async () => {
                      await onDeleteTribunalType(row.id);
                      setSelectedRow([]);
                    }}
                    setSelectedRow={setSelectedRow}
                  />
                </CaijTableCellAction>
              </CaijTableRowClickable>
            ))}
        </TableBody>
      </CaijTable>
      <CaijTablePagination
        showTablePaginationLight 
        len={tribunalTypes.length}
        page={page}
        setPage={(l) => setPage(l)}
      />
    </MyCard>
  );
};

Results.propTypes = {
  tribunalTypes: PropTypes.array.isRequired,
  onDeleteTribunalType: PropTypes.func.isRequired,
  isEmptyList: PropTypes.bool.isRequired
};

export default Results;
