import { 
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Link, 
  MenuItem, 
  SvgIcon, 
  Typography 
} from "@mui/material";
import { useReducer, useState } from "react";
import { 
  CaijInput, 
  CustomerModel, 
  CustomerStatus, 
  dialog, 
  dialogTitle, 
  fetchResetPassword, 
  _customer 
} from "src/common";
import { CustomerResource } from "src/common/types";
import { CheckCircle as ConfirmIcon } from 'react-feather';
import { BtnOk } from "src/components/dialog/CaijDialog";
import CaijDialogs from "src/components/dialog";
import CaijButtonCopy from "src/components/buttons/CaijButtonCopy";

export default function ResetPassword(props: {customer: CustomerResource, setAnchorEl: (value: string) => void, refresh: () => Promise<void>}){
  const [open, setOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [state, dispatch] = useReducer(_customer,{ password:'' });
  const model = new CustomerModel();
  model.Dialog.Header = 'Réinitialiser le mot de passe';
  model.Dialog.BtnText = BtnOk.RESETPASSWORD;
  model.Dialog.Name = 'le mot de passe';

  const copyToClipBoard = async (password: string) => {
    if(password){
      try {
        await navigator.clipboard.writeText(password);
        setCopySuccess('Copié');
      } catch (err) {
        setCopySuccess('Copié avec sans succès');
      }
    }
  };
  
  const getMessage = (password: string, copySuccess: string) : JSX.Element => {
    if(!password){
      return (<Typography variant="body2" sx={{color: 'text.error'}}>Mot de passe invalid</Typography>);
    }else if(copySuccess && copySuccess !== 'Copié'){
      return (<Typography variant="body2" sx={{color: 'text.success'}}>{copySuccess}</Typography>); 
    }
  };

  const dialogRender = () => {
      return (
        <>
          <DialogTitle sx={dialogTitle}>MOT DE PASSE RÉINITIALISÉ!</DialogTitle>
            <DialogContent dividers sx={{...dialog, width: 'auto' }}>
              <Box mb={2}>
                <Typography variant='body2' sx={{color: 'text.secondary'}}>Le mot de passe a été réinitialisé par celui-ci. Veuillez le communiquer au client.</Typography>
              </Box>
              <Box display='flex' alignItems='center' justifyContent="space-between">
                <Box mr={2} flexGrow="1">
                  <CaijInput 
                    name='password' 
                    label="Réinitialiser le mot de passe"
                    size='small' 
                    placeholder='Réinitialiser le mot de passe'
                    InputLabelProps={{ shrink: true}}
                    inputProps={{readOnly: true}}
                    value={state.password}
                  />
                </Box>
                <Box mb={1}>
                  <CaijButtonCopy value={state.password} />
                </Box>
              </Box>  
              {getMessage(state.password, copySuccess)}  
          </DialogContent>
          <DialogActions sx={dialog}>
            <Button  
              variant="outlined"
              type="reset"
              onClick={() => {
                setOpenDialog(false);
                if(copySuccess) setCopySuccess('');
                props.customer.status === CustomerModel.getStatusByVal(CustomerStatus.ActiveOnLogin) ? props.refresh() : '';
              }}
              startIcon={(
                <SvgIcon fontSize="small">
                  <ConfirmIcon />
                </SvgIcon>
              )}>OK</Button>
          </DialogActions>
        </>
      );
  };

  const handleResetPassword = async () : Promise<void> => {
    const model = new CustomerModel();
    const password = await model.getResetPassword(String(props.customer.id));
    if(!model.error && password){
      if(password){
        dispatch(fetchResetPassword(password)); 
      }
    }
    setOpenDialog(true);
    setOpen(false);
  };

  return (
    <>
      <Link href='#' onClick={() => setOpen(true)} underline='none'>
        <MenuItem>
          <Typography variant="body1">
            Réinitialiser le mot de passe
          </Typography>
        </MenuItem>
      </Link>
      <CaijDialogs
        dialog={model.Dialog}
        isOpen={open}
        onSubmit={() => (async () => {
          await handleResetPassword();
          props.setAnchorEl(null);
        })()}
        close={() => {
          setOpen(false); 
          props.setAnchorEl(null);
        }}
      /> 
      <Dialog maxWidth='sm' fullWidth open={openDialog}>
        {dialogRender()}
      </Dialog>
    </>
   );
};
