import { TableCell, TableHead, TableRow } from "@mui/material";

export type Align = "left" | "center" | "right" | "justify" | "inherit";

export interface TableHeader {
  data: { 
    width?: string | number, 
    align?: Align,
    name?: string 
  }[]; 
};

export const CaijTableHeader = ({data,...rest}: TableHeader) => (
  <TableHead>
    <TableRow>
      {data.map(({width, align, name}, index: number) =>
        name ? <TableCell key={index} sx={{...rest}} width={width} align={align}>{name}</TableCell> : <TableCell key={index} align={align} />
      )}
    </TableRow>
  </TableHead>
);
