import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  fetchDocCollections,
  _docCollection,
  DocCollectionModel,
  AccessModel,
  deleteDocCollection,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  docCollections: [],
  isLoading: false,
  isEmptyList: true
};

const DocCollectionListView: FC = () => {
  const model = DocCollectionModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_docCollection, initialState);
  
  const getCollections = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new DocCollectionModel();
      model.PathName = location.pathname;
      const docCollections = await model.getDocCollections();
      if (!model.error) {
        dispatch(fetchDocCollections(docCollections, true, !Array.isArray(docCollections) || docCollections.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getCollections();
    })();
  },[]);
 
  const handelDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(model.delete(id))
        dispatch(deleteDocCollection(id));
    }
  };

  const { docCollections, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
      <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            collections={docCollections}
            onDeleteDocCollection={handelDelete}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocCollectionListView;
