import type { CustomerPendingChangeResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchCardRequestPendingAction = {
  type: 'FETCH_CARD_REQUEST_PENDING';
  payload: {
    customerPendingChanges: CustomerPendingChangeResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
};

export type CardRequestPendingAction = FetchCardRequestPendingAction;

export interface CardRequestPendingReturnState {
  customerPendingChanges?: CustomerPendingChangeResource[];
  isEmptyList?: boolean;
  isLoading?: boolean;
}

interface CardRequestPendingState {
  customerPendingChanges: CustomerPendingChangeResource[];
  isEmptyList: boolean;
  isLoading: boolean;
}

export default (state: CardRequestPendingState, action: CardRequestPendingAction) => {
  switch (action.type) {
    case actionTypes.FETCH_CARD_REQUEST_PENDING: {
      const { customerPendingChanges, isLoading, isEmptyList} = action.payload;
      return {
        ...state,
        customerPendingChanges,
        isLoading,
        isEmptyList
      };
    }
    default: {
      return state;
    }
  }
};


