import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { 
  Select, 
  FormControl, 
  InputLabel, 
  SelectChangeEvent, 
  OutlinedInput 
} from '@mui/material';

interface CaijMultipleSelectCheckmarksProps {
  values: string[];
  data: {id: string, name: string}[];
  label?: string;
  required?: boolean;
  sx?: any;
  children: ReactNode;
  onHandleChange?: (event: SelectChangeEvent<any>) => void;
}

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CaijMultipleSelectCheckmarks: FC<CaijMultipleSelectCheckmarksProps> = ({
  required,
  sx,
  label,
  values,
  data,
  children,
  onHandleChange,
  ...rest
}) => {

  const renderValue = (selected: string[]) => {
    let results: string[] = []
    if(data && data.length > 0)
      data.forEach(({id, name}) => selected.includes(id) ? results.push(name) : '');
    return results.join(', ');
  }

  return (
    <>
      <FormControl required={required} sx={sx}>
        <InputLabel shrink>{label}</InputLabel>
        <Select
          {...rest}
          multiple
          value={values}
          input={<OutlinedInput notched label={label} />}
          renderValue={(selected: string[]) => renderValue(selected)}
          onChange={onHandleChange}
          MenuProps={MenuProps}
        >
          {children}
        </Select>
      </FormControl>
    </>
  );
}

CaijMultipleSelectCheckmarks.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  sx: PropTypes.any,
  children: PropTypes.node
};

export default CaijMultipleSelectCheckmarks;
