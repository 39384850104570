import { ProductModel } from 'src/common';
import type { ProductResource } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchProductsAction = {
    type: 'FETCH_PRODUCTS';
    payload: {
      products: ProductResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
      model?: ProductModel;
    }
}

type FetchProductAction = {
  type: 'FETCH_PRODUCT';
  payload: {
    product: ProductResource;
    isLoading?: boolean;
  }
};

type DeleteProductAction = {
  type: 'DELETE_PRODUCT';
  payload: {
    id: number
  }
};

type Action = FetchProductsAction |
              FetchProductAction |
              DeleteProductAction;

export interface ProductReturnState {
  products?: ProductResource[];
  product?: ProductResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  model?: ProductModel;
}

interface ProductState {
  products: ProductResource[];
  product: ProductResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  model: ProductModel;
}

const reducer = (state: ProductState, action: Action): ProductReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS: {
      const { products, model, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        products,
        model,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_PRODUCT: {
      const { product, isLoading } = action.payload;
      return {
        ...state,
        product,
        isLoading
      };
    }
    case actionTypes.DELETE_PRODUCT: {
      return {
        ...state,
        products: [...state.products].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

