import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { LawPracticeFieldResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { LawPracticeFieldModel, tableRow } from 'src/common';
import { tableCell } from 'src/styles/lawDomain';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface LawPraticeFieldFrInfoProps {
  model: LawPracticeFieldModel;
  lawPracticeField: LawPracticeFieldResource;
}

const LawPraticeFieldFrInfo: FC<LawPraticeFieldFrInfoProps> = ({
  model,
  lawPracticeField
}) => {
  const { NameFr } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Champs de pratique" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              { NameFr.Label}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { lawPracticeField.nameFr }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LawPraticeFieldFrInfo.propTypes = {
  lawPracticeField: PropTypes.object.isRequired
};

export default LawPraticeFieldFrInfo;
