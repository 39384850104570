import { useState, useEffect, ChangeEvent } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
  Grid,
  SvgIcon,
  DialogTitle,
  IconButton,
  Autocomplete,
  TextField,
  Chip
} from '@mui/material';
import type { 
  Theme, 
  CustomerResource,
  LawPracticeFieldDto
} from 'src/common/types';
import { 
  Formik, 
  Mode,
  dialogTitle,
  button,
  NO_AUTHORIZE_ACCESS_MSG,
  CustomerModel,
  CaijTooltip
} from 'src/common';
import Authorize from 'src/components/Authorize';
import { 
  BookOpen as BookIcon, 
  Edit as EditIcon, 
  XCircle as DeleteIcon 
} from 'react-feather';
import { XSquare as CancelIcon } from 'react-feather';
import { CheckCircle as OkIcon } from 'react-feather';
import useLawPraticeField from 'src/hooks/useLawPraticeField';
import { deleteIcon, dialog } from 'src/styles/customer';

interface CustomerLawDomainInfoProps {
  model: CustomerModel;
  customer: CustomerResource;
  editMode?: boolean;
}
  
const listItemStyle = {
  display: 'flex',
  color: (theme: Theme) => theme.palette.text.primary,
  alignItems: 'center',
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  padding: '0 0.8em',
  margin: '0 0.5em 0.2em 0',
  height: '2em',
  borderRadius: '0.4em',
  whiteSpace: 'nowrap',
  transition: '0.2s',
  '&:hover': {
  transition: '0.2s',
  backgroundColor: (theme: Theme) => theme.palette.divider,
  },
}

const CustomerLawDomainInfo: FC<CustomerLawDomainInfoProps> = ({
  model,
  customer,
  editMode
}) => {
  const [open, setOpen] = useState(false);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [avLawDomains, setAvLawDomains] = useState<LawPracticeFieldDto[]>([]);
  const [selectedCustomerLawDomains, setSelectedCustomerLawDomains] = useState<{ data: number[]; selected: boolean }>({
    data: [],
    selected: false,
  });
  const [incLawDomains, setIncLawDomains] = useState<LawPracticeFieldDto[]>(customer?.lawDomains);
  const [tempVal, setTempVal] = useState<LawPracticeFieldDto[]>([]);
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const { setSelectedLawPraticeFields, lawPracticeFields, isLoading, isAuthorize } = useLawPraticeField();
  const btnText = 'MODIFIER LES CHAMPS DE PRATIQUE';

  useEffect(() => {
    let isCurrent = true;
    if(customer){
      if(Array.isArray(customer.lawDomains) && customer.lawDomains.length > 0){
        setSelectedCustomerLawDomains({...selectedCustomerLawDomains, selected: true, data: customer.lawDomains.map((lawDomain: LawPracticeFieldDto) => lawDomain.id)})
        setAvLawDomains(lawPracticeFields && lawPracticeFields.filter((ld: LawPracticeFieldDto) => customer.lawDomains.findIndex((lawDomain: LawPracticeFieldDto) => lawDomain.id == ld.id) === -1));
        setTempVal(customer.lawDomains as LawPracticeFieldDto[]);
        setSelectedLawPraticeFields(customer.lawDomains);
      }else{
        isCurrent && setAvLawDomains(lawPracticeFields);
      } 
    }
  }, [isLoading]);

  const resetLawDomains = async () => {
    if(tempVal){
      setIncLawDomains(tempVal);
    }
  }

  const handleClickOpen = async () => {
    setOpen(true);
    resetLawDomains();
    if(tempVal.length > 0){
      setAvLawDomains(lawPracticeFields.filter((ld: LawPracticeFieldDto) => tempVal.findIndex((x: LawPracticeFieldDto) => x.id == ld.id) === -1));
      setSelectedCustomerLawDomains({...selectedCustomerLawDomains, selected: true, data: tempVal.map((x: LawPracticeFieldDto) => x.id)});
    }
  };

  const handleClose = (isClosed: boolean) => {
    setOpen(isClosed);
  };

  const handleChange = (event:ChangeEvent<{}>, selectedLawDomains : (LawPracticeFieldDto)[]) => {
    setIncLawDomains(selectedLawDomains);
    setAvLawDomains(lawPracticeFields.filter((lawDomain: LawPracticeFieldDto) => selectedLawDomains.findIndex((item : LawPracticeFieldDto) => item.id === lawDomain.id) === -1));
    setSelectedCustomerLawDomains({...selectedCustomerLawDomains, selected: true, data: selectedLawDomains.map((item: LawPracticeFieldDto) => item.id)});
  }

  const handleTagDelete = (lawDomain: LawPracticeFieldDto) => {
    setIncLawDomains(incLawDomains.filter((value: LawPracticeFieldDto) => value.id !== lawDomain.id));
    const selectedValues = [...selectedCustomerLawDomains.data];
    const data = selectedValues.filter((selectedValue: number) => selectedValue !== lawDomain.id);
    setSelectedCustomerLawDomains({...selectedCustomerLawDomains, selected: true, data });
    const removedItems = incLawDomains.filter((incLawDomain: LawPracticeFieldDto) => incLawDomain.id !== lawDomain.id);
    setAvLawDomains(lawPracticeFields.filter((lawDomain: LawPracticeFieldDto) => removedItems.findIndex((removedItem: LawPracticeFieldDto) => removedItem.id === lawDomain.id) === -1));
  }

  const handleSubmit = async (selLawDomain: { data: number[]; selected: boolean}) : Promise<boolean> => {
    if(selLawDomain.selected){
      const newLawDomains = lawPracticeFields.filter((lawDomain : LawPracticeFieldDto) => selLawDomain.data.includes(lawDomain.id));
      setTempVal((prev: LawPracticeFieldDto[]) => prev = newLawDomains);
      setSelectedLawPraticeFields(newLawDomains);
      return true;
    }
    return false;
  };

  const deleteLawDomain = async (lawDomainId : number) : Promise<void> => {
    if(lawDomainId){
      const lds = [...selectedCustomerLawDomains.data];
      const data = lds.filter((ld: number) => ld !== lawDomainId);
      const result = await handleSubmit({ data, selected: selectedCustomerLawDomains.selected });
      if(result){
        const lawDomain = lawPracticeFields.find((lawDomain : LawPracticeFieldDto) => lawDomain.id === lawDomainId);
        handleTagDelete(lawDomain);
      }
    }
  };

  const renderListItem = (lawDomain: LawPracticeFieldDto, isDelete?: boolean) : JSX.Element => {
    const { id, nameFr } = lawDomain;
    return (
      <Grid item key={id}>
        <Box sx={listItemStyle} >
          <Typography variant='body2' sx={{color:'text.primary'}}>
            {nameFr}
          </Typography>
          {
            isDelete && (
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.delete}
                  onIsAuth={l => setIsDeleteAuth(l)}
                >
                  <CaijTooltip title="Supprimer" disabled={!isDeleteAuth}>
                    <IconButton sx={{...deleteIcon}} onClick={() => deleteLawDomain(id)} disabled={!isDeleteAuth}>
                      <SvgIcon fontSize='small'>
                        <DeleteIcon />
                      </SvgIcon>
                    </IconButton>
                  </CaijTooltip>
                </Authorize>
            )
          }
        </Box>
      </Grid>
    )
  }
  const dialogRender = () => {
    return (
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          await handleSubmit(selectedCustomerLawDomains);
          handleClose(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle sx={dialogTitle}>MODIFICATION DES CHAMPS DE PRATIQUE</DialogTitle>
            <DialogContent sx={{...dialog, padding: '2em', width: 'auto'}} dividers >
              <Autocomplete
                multiple
                disableClearable
                value={incLawDomains}
                id="LawDomain"
                options={avLawDomains}
                getOptionLabel={(option: LawPracticeFieldDto) => option.nameFr}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Champs de pratique"
                    placeholder="Rechercher..."
                    InputLabelProps={{shrink: true}}
                  />
                )}
                onChange={(event, value, reason) => {
                  handleChange(event,value as LawPracticeFieldDto[])
                }}
                renderTags={(tagValue, getTagProps) => 
                    tagValue.map((option, index) => {
                     // const x = tempVal.findIndex((value: LawDomainResource) => value.id === option.id);
                      //if(x >= 0){
                       // return <Chip key={option.id} label={option.nameFr} color="default" />
                     // }else{
                        return <Chip key={option.id} label={option.nameFr} color="default" onDelete={() => handleTagDelete(option)} />
                      //}
                    })
                }
              />
              <Box mt={2}>
                {
                  customer && (
                  <Authorize 
                    resourceCode={model.ResourceCode}
                    mode={Mode.edit}
                    onIsAuth={l => setIsAuth(l)}
                  >
                    <Button 
                       type="submit" 
                       variant="outlined" 
                       disabled={!isAuth || isSubmitting} 
                       sx={button}
                       startIcon={(
                        <SvgIcon fontSize="small">
                          <OkIcon />
                        </SvgIcon>
                        )}
                    >OK</Button>
                  </Authorize>
                )}
                <Button  
                  variant="outlined"
                  type="reset"
                  onClick={() => handleClose(false)}
                  disabled={isSubmitting}
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <CancelIcon />
                    </SvgIcon>
                  )}>Annuler</Button>
              </Box>
            </DialogContent>
          </form>
        )}
      </Formik>
    );
  };

  const renderEditButton = () => {
    if(isLoading && !isAuthorize){
        return (
          <Typography variant="subtitle1" color='text.error'>
            {NO_AUTHORIZE_ACCESS_MSG}
          </Typography>
        ) 
    }
    return (
        <Button
            startIcon={
                <SvgIcon>
                <EditIcon />
                </SvgIcon>
            }
            onClick={handleClickOpen}
        >
          {btnText}
        </Button>
    )
  }
  
  return (
    <Box display='flex'>
      <SvgIcon fontSize='small'>
        <BookIcon />
      </SvgIcon>
      <Box marginLeft='0.5em' display='flex' flexDirection='column'>
        <Typography sx={{ fontWeight: 'bold', marginBottom: '1em' }}>Champs de pratique</Typography>
        <Grid container spacing={1}>
          {tempVal && ( tempVal.length === 0 ? (
            <Box display='flex' flexDirection='column'>
              <Grid item sx={{...listItemStyle, marginBottom: '1em', width: '100%' }}>
                <Typography variant='body2' sx={{color:'text.secondary', paddingRight: '8px'}}>
                  Non spécifié
                </Typography>
              </Grid>
              {editMode && renderEditButton()}
            </Box>
          ) : (
            <Box>
              { !editMode ? ( <Grid container spacing={1} sx={{ marginBottom: '1em', display: 'flex' }}>
               {tempVal.map((lawDomain: LawPracticeFieldDto) => renderListItem(lawDomain))}
              </Grid> ) :
               (<>
                  <Grid container spacing={1} sx={{ marginBottom: '1em', display: 'flex' }}>
                    {tempVal.map((lawDomain: LawPracticeFieldDto) => renderListItem(lawDomain, true))}
                  </Grid> 
                  {renderEditButton()}
                </>
              )}
            </Box>
          ))}
        </Grid>
      </Box>
      <Dialog maxWidth='md' fullWidth open={open}>
        {dialogRender()}
      </Dialog>
    </Box>
  );
};

CustomerLawDomainInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  editMode: PropTypes.bool
};

export default CustomerLawDomainInfo;
