import React, { FC, useState } from 'react';
import { MessagePlaceholderResource, MessageResource } from 'src/common/types';
import { 
  Table, 
  TableBody, 
  TableRow, 
  TableCell, 
  Typography
} from '@mui/material';
import Label from 'src/components/Label';
import { 
  labelConfig, 
  formatDateTime, 
  MessageModel,
  tableRow,
  EnhancedTableHead
} from 'src/common';
import { Color } from 'src/model/Message';
import MyCard from 'src/components/card/MyCard';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface MessagesListProps {
  model: MessageModel;
  placeholder?: MessagePlaceholderResource;
  colorRender: (item: MessageResource) => Color;
  onDeleteMessage?:  (id: number) => Promise<void>;
}

const MessagesList: FC<MessagesListProps> = ({ model, placeholder, colorRender, onDeleteMessage }) => {
  const [selectedRow, setSelectedRow] = useState([]);
  const path = model.Path;
  const { order, orderBy, sort, handleRequestSort } = useSort();
  const headCells = model.getHeadCells(labelConfig.status);
  return (
    <MyCard>
      <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
          {  placeholder.messages && placeholder.messages.length > 0 ? (
              sort<MessageResource>(placeholder.messages,0).map(item => {
              return (
                <CaijTableRowClickable key={item.id} sx={tableRow} path={path.getDetail(item.id)}>
                  <CaijTableCell>{formatDateTime(new Date(item.startDate))}</CaijTableCell>
                  <CaijTableCell>{formatDateTime(new Date(item.endDate))}</CaijTableCell>
                  <CaijTableCell>{item.name}</CaijTableCell>
                  <CaijTableCell>
                    <Label color={colorRender(item)}>{MessageModel.activeCheck(item)}</Label>
                  </CaijTableCell>
                  <CaijTableCell cellAlign='right'>{item.rank}</CaijTableCell>
                  <CaijTableCellAction 
                    pageEditUrl={path.getEdit(item.id)}
                    resourceCode={model.ResourceCode}
                    handleToggle={() => MessageModel.handleToggle(item.id, selectedRow, setSelectedRow)}
                  >
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(item.id) !== -1}
                      onSubmit={async () => {
                          await onDeleteMessage(item.id);
                          setSelectedRow([]);
                      }}
                      setSelectedRow={setSelectedRow}
                    />
                  </CaijTableCellAction>
                </CaijTableRowClickable>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={headCells.length}>
                <Typography sx={{ marginBottom: '1em', marginLeft: '1em', marginTop: '1em', width: '100%' }}>
                  Désolé, il ne semble pas y avoir de messages associés à cet emplacement.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </MyCard>
  );
};

export default MessagesList;
