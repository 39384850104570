import { useEffect, useReducer } from "react";
import { 
  DatabankModel, 
  DocCollectionModel, 
  fetchDatabanks, 
  fetchDocCollections, 
  fetchProducts, 
  Forbidden, 
  ProductModel, 
  Unauthorized, 
  _databank, 
  _docCollection, 
  _product 
} from "src/common";
import { FETCH_DATABANKS, FETCH_DOCUMENT_COLLECTIONS, FETCH_PRODUCTS } from "src/store/actions/ActionTypes";
import { DatabankReturnState } from "src/store/reducers/DatabankReducer";

const initialOthersState = {
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false,
};

function useDatabank() : DatabankReturnState {
  const initialState = {
		...initialOthersState,
    databanks: []
	};
  const [state, dispatch] = useReducer(_databank, initialState);
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      const model = new DatabankModel();
      model.skipHandleError = true;
      const databanks = await model.getDatabanks();
      const { error } = model;
      if (error) {
          if(error.status === Forbidden || error.status === Unauthorized) {
            dispatch(fetchDatabanks(databanks, true, false, false));
          }else {
            dispatch(fetchDatabanks(databanks, true, true, true));
          }
      }else{
        dispatch(fetchDatabanks(databanks, true, !Array.isArray(databanks) || databanks.length === 0, true));
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({ type: FETCH_DATABANKS, payload: initialState }); }
  },[]);
  const { databanks, isLoading, isEmptyList, isAuthorize } = state;
	return { databanks, isLoading, isEmptyList, isAuthorize };
};

function useCollection() {
  const initialState = {
		...initialOthersState,
    docCollections: []
	};
  const [state, dispatch] = useReducer(_docCollection, initialState);
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      const model = new DocCollectionModel();
      model.skipHandleError = true;
      const collections = await model.getDocCollections();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchDocCollections(collections, true, false, false));
        }else{
          dispatch(fetchDocCollections(collections, true, true, true));
        }
      } else {
        dispatch(fetchDocCollections(collections, true, !Array.isArray(collections) || collections.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({ type: FETCH_DOCUMENT_COLLECTIONS, payload: initialState }); }
  },[]);
  const { docCollections, isLoading, isEmptyList, isAuthorize } = state;
	return { docCollections, isLoading, isEmptyList, isAuthorize };
};
  
export function useProduct() {
  const initialState = {
		...initialOthersState,
		products: []
	};
  const [state, dispatch] = useReducer(_product, initialState);
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      const model = new ProductModel();
      model.skipHandleError = true;
      const products = await model.getProducts();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchProducts(products, true, false, false));
        }else{
          dispatch(fetchProducts(products, true, true, true));
        }
      } else {
        dispatch(fetchProducts(products, true, !Array.isArray(products) || products.length === 0, true));
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({ type: FETCH_PRODUCTS, payload: initialState }); }
  },[]);
  const { products, isLoading, isEmptyList, isAuthorize } = state;
	return { products, isLoading, isEmptyList, isAuthorize };
};

export function useAccesses() {
  return {
    sdatabank: useDatabank(),
    scollection: useCollection(),
    sproduct: useProduct()
  }
}