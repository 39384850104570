import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import { Delete as TrashIcon} from '@mui/icons-material';

interface CaijButtonDeleteOtherActionProps {
  disabled?: boolean;
  onDelete: () => void;
  children?: ReactNode;
  size?: 'small'| 'medium' | 'large',
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  sx?: any
}

const CaijButtonDeleteOtherAction: FC<CaijButtonDeleteOtherActionProps> = ({ 
  children,
  onDelete,
  ...rest
}) => (
  <>
    <Button
      startIcon={
        <SvgIcon fontSize='large'>
          <TrashIcon />
        </SvgIcon>
      }
      {...rest}
      fullWidth
      onClick={() => onDelete()}
    >
      {children}
    </Button>
  </>
);

CaijButtonDeleteOtherAction.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained' , 'outlined' , 'text']),
  color: PropTypes.oneOf(['inherit' , 'primary' , 'secondary' , 'success' , 'error' , 'info' , 'warning']),
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  children: PropTypes.node,
};

CaijButtonDeleteOtherAction.defaultProps = {
  disabled: false,
};

export default CaijButtonDeleteOtherAction;
