import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid } from '@mui/material';
import {
  Formik,
  handleChange,
  labelConfig,
  TranslationModel,
  CaijInput,
  Authorize,
  CaijButtonSubmit,
  CaijButtonReset,
  Mode,
  button,
  CaijTextarea
} from 'src/common';
import type { TranslationResource, TranslationResourceForCreate } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';

export interface TranslationEditFormProps {
  model : TranslationModel;
  onSubmit?: (values: TranslationResource) => void;
};

const TranslationEditForm: FC<TranslationEditFormProps> = ({model, onSubmit}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const { Code, Fr, En } = model;

  const handleSubmit = async (values: TranslationResource) : Promise<void> => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new TranslationModel();
    let submitData = values as TranslationResourceForCreate;
    const response = await model.insertTranslation(submitData);
    if (!model.error) {
      await model.redirect(model.Path.Home,{
        status: response.status,
        message: response.message
      });
    }
  };

  return (
    <Formik
      initialValues={{
        code: '',
        fr: '',
        en: ''
      }}
      validationSchema={Yup.object().shape({
        code: Code.required(true),
        fr: Fr.required(true),
        en: En.required(true)
      })}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                  <CaijInput 
                    name={Code.Name}
                    label={Code.Label}
                    value={values.code}
                    required
                    helperText={touched.code && errors.code}
                    error={Boolean(touched.code && errors.code)}
                    InputLabelProps={{ shrink: true, required: true }}
                    inputAttr={{maxLength: Code.MaxLength, 'data-testid': Code.Name}}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <CaijTextarea 
                    name={Fr.Name}
                    label={Fr.Label}
                    value={values.fr}
                    required
                    rows={4}
                    helperText={touched.fr && errors.fr}
                    error={Boolean(touched.fr && errors.fr)}
                    InputLabelProps={{ shrink: true, required: true }}
                    inputProps={{maxLength: Fr.MaxLength,'data-testid': Fr.Name}}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <CaijTextarea 
                    name={En.Name}
                    label={En.Label}
                    value={values.en}
                    required
                    rows={4}
                    helperText={touched.en && errors.en}
                    error={Boolean(touched.en && errors.en)}
                    InputLabelProps={{ shrink: true, required: true }}
                    inputProps={{maxLength: En.MaxLength, 'data-testid' : En.Name}}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                  <Authorize 
                    resourceCode={model.ResourceCode} 
                    mode={Mode.add}
                    onIsAuth={l => setIsAuth(l)}>
                    <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={button} />
                  </Authorize>
                  <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

TranslationEditForm.propTypes = {
  onSubmit: PropTypes.func
};

TranslationEditForm.defaultProps = {
  onSubmit: null
};

export default TranslationEditForm;
