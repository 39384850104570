import { LibraryTypeResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchLibraryTypesAction = {
  type: 'FETCH_LIBRARY_TYPES';
  payload: {
    libraryTypes: LibraryTypeResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

type FetchLibraryTypeAction = {
  type: 'FETCH_LIBRARY_TYPE';
  payload: {
    libraryType: LibraryTypeResource;
    isLoading?: boolean;
  };
};

type FetchDetailsLibraryTypeAction = {
  type: 'FETCH_DETAILS_LIBRARY_TYPE';
  payload: {
    libraryType: LibraryTypeResource;
  };
};

type DeleteLibraryTypeAction = {
  type: 'DELETE_LIBRARY_TYPE';
  payload: {
    id: number;
  };
};

type Action = FetchLibraryTypesAction |
              FetchDetailsLibraryTypeAction |
              FetchLibraryTypeAction |
              DeleteLibraryTypeAction;

interface LibraryTypeReturnState {
  libraryTypes?: LibraryTypeResource[];
  libraryType?: LibraryTypeResource;
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
}

interface LibraryTypeState {
  libraryTypes: LibraryTypeResource[];
  libraryType: LibraryTypeResource;
  isLoading: boolean;
  isEmptyList: boolean;
  isAuthorize: boolean;
}

const reducer = (state: LibraryTypeState, action: Action): LibraryTypeReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_LIBRARY_TYPES: {
      const { libraryTypes, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        libraryTypes,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_LIBRARY_TYPE: {
      const { libraryType, isLoading } = action.payload;
      return {
        ...state,
        libraryType,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_LIBRARY_TYPE: {
      const { libraryType } = action.payload;
      return {
        ...state,
        libraryType
      };
    }
    case actionTypes.DELETE_LIBRARY_TYPE: {
      return {
        ...state,
        libraryTypes: [...state.libraryTypes].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
