
import type { LawPracticeFieldResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchLawPracticeFields = (
  lawPracticeFields: LawPracticeFieldResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
): {
  type: 'FETCH_LAW_PRATICE_FIELDS';
  payload: {
    lawPracticeFields: LawPracticeFieldResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
} => ({
  type: actionTypes.FETCH_LAW_PRATICE_FIELDS,
  payload: {
    lawPracticeFields,
    isLoading,
    isEmptyList,
    isAuthorize,
  },
});

export const fetchLawPracticeField = (lawPracticeField: LawPracticeFieldResource, isLoading?: boolean): {
  type: 'FETCH_LAW_PRATICE_FIELD';
  payload: { 
    lawPracticeField: LawPracticeFieldResource,
    isLoading?: boolean,
  };
} => ({
  type: actionTypes.FETCH_LAW_PRATICE_FIELD,
  payload: {
    lawPracticeField,
    isLoading
  },
});

export const fetchDetailsLawPracticeField = (
  lawPracticeField: LawPracticeFieldResource,
): {
  type: 'FETCH_DETAILS_LAW_PRATICE_FIELD';
  payload: {
    lawPracticeField: LawPracticeFieldResource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_LAW_PRATICE_FIELD,
  payload: {
    lawPracticeField,
  },
});

export const deleteLawPracticeField = (
  id: number,
): {
  type: 'DELETE_LAW_PRATICE_FIELD';
  payload: {
    id: number;
  };
} => ({
  type: actionTypes.DELETE_LAW_PRATICE_FIELD,
  payload: {
    id,
  },
});
