import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SubscriptionModel,
  Authorize,
  CaijButton,
  Mode,
  tableRow,
	CaijButtonDeleteOtherAction
} from 'src/common';
import type { SubscriptionResource } from 'src/common/types';
import SubscriptionAccessGroupList from 'src/components/access/SubscriptionAccessGroupList';
import { 
  Box, 
  CardHeader, 
  Divider, 
  Grid,  
  Table, 
  TableBody, 
  Typography
} from '@mui/material';
import MyCard from 'src/components/card/MyCard';
import { useAccessGroup } from 'src/functions/hooks/accessGroup';
import CaijDialogs from 'src/components/dialog';
import { EditAccessGroup } from 'src/types/accessGroup';
import { CaijTableRow } from 'src/components/table/CaijTableRow';
import LoadingScreen from 'src/components/loading/LoadingScreen';

export interface AccessGroupViewedDetailsViewProps {
  model: SubscriptionModel;
  isDefault?: boolean;
  groupId?: number;
  subscription: SubscriptionResource;
  onEditAccessGroup?: (value: EditAccessGroup) => void;
};

const tabelCell = {
	width: '160px'
};

const AccessGroupViewedDetailsView: FC<AccessGroupViewedDetailsViewProps> = ({
  model, 
  isDefault,
  groupId,
  subscription,
  onEditAccessGroup
}) => {
   const [openDialog, setOpenDialog] = useState<boolean>(false);
   const [isEditAuth, setIsEditAuth] = useState<boolean>();
   const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
	 const { isLoadingGroup, group, getGroup } = useAccessGroup();
   const { AccessGroupName, AccessGroupNote, AccessGroupWfProfile } = model;
	 model.Dialog.Header = "Supprimer le groupe d'accès";
   model.Dialog.Name = "le groupe d'accès";

	 useEffect(() => {
		(async () => await getGroup(groupId, subscription.id))();
	 },[]);

	 const handleDelete: (subscriptionId: number, groupId: number) => Promise<void> = async (subscriptionId, groupId) => {
    if(groupId){
      if(await model.deleteGroup(subscriptionId, groupId)){
        onEditAccessGroup({isEdit: false, detailsPage: false});
			}
    }
  };

  if(isLoadingGroup) return <LoadingScreen />

  return (
		<Grid container spacing={3}>
			{onEditAccessGroup ? (
				<Grid item md={12} xs={12} lg={12}>
					<MyCard>
						<CardHeader
							action={
								<Box display="flex" justifyContent="right">
									<Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={(l) => setIsEditAuth(l)}>
										<CaijButton
											type='edit'
											color='secondary'
											variant='contained'
											onHandleClick={() => onEditAccessGroup({isEdit:true, groupId: group.id})}
											disabled={!isEditAuth}
											sx={{mr:2}}
										>
											Modifier
										</CaijButton>
									</Authorize>
									{!isDefault && (
										<>
											<Authorize
												resourceCode={model.ResourceCode}
												mode={Mode.delete}
												onIsAuth={(l) => setIsDeleteAuth(l)}
											>
												<CaijButtonDeleteOtherAction 
													size="small" 
													sx={{width: '15px', paddingLeft: '20px'}} 
													color="error"
													variant="contained" 
													onDelete={() => setOpenDialog(true)} 
													disabled={!isDeleteAuth}
												/>
												<CaijDialogs
													dialog={model.Dialog}
													isOpen={openDialog}
													onSubmit={() => handleDelete(subscription.id, group.id)}
													close={() => setOpenDialog(false)}
												/>
											</Authorize>
										</>
									)}
								</Box>
							}
						/>
						<MyCard>
							<CardHeader
								title={
									<Typography variant='h5' sx={{ color: 'text.primary', mb: 3 }}>
										Visualiser un groupe
									</Typography>
								}
							/>
							<Divider />
							<Table>
								<TableBody>
								  <CaijTableRow label={AccessGroupName.Label} sxTableCell={tabelCell}>{group.name}</CaijTableRow>
									<CaijTableRow label={AccessGroupWfProfile.Label} sxTableCell={tabelCell}>{group.wfProfile}</CaijTableRow>
									<CaijTableRow sxTableRow={tableRow} label={AccessGroupNote.Label} sxTableCell={tabelCell} whiteSpace='pre-wrap'>{group.note}</CaijTableRow>
								</TableBody>
							</Table>
						</MyCard>
						<Grid container>
							<Grid item md={12} xs={12} lg={12}>
								<Box m={2}>
									<SubscriptionAccessGroupList subscription={subscription} group={group} />
								</Box>
							</Grid>
						</Grid>
					</MyCard>
				</Grid>
			) : ( /* liste des groupes d'abonnement des membres */
				<SubscriptionAccessGroupList subscription={subscription} />
			)}
		</Grid>
   );
 }

 AccessGroupViewedDetailsView.propTypes = {
  isDefault: PropTypes.bool,
  subscription: PropTypes.object,
  onEditAccessGroup:PropTypes.func
 };

 AccessGroupViewedDetailsView.defaultProps = {
  onEditAccessGroup: null
 }

 export default AccessGroupViewedDetailsView;