import { Box, Container } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { 
  AccessModel,
  fetchLawDomainFields,  
  LawDomainFieldModel,
  useLocation, 
  useNavigate, 
  _lawDomainField,
  root,
  ITEMS_PER_PAGE
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { GetLawDomainFieldsAsyncOptionalParams } from 'src/common/types';

const initialState = {
  lawDomainFields: [],
  isLoading: true,
  isEmptyList: true,
  isAuthorize: false,
};

const LawDomainFieldListView: FC = () => {
  const access = new AccessModel(LawDomainFieldModel.getInstance().ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_lawDomainField, initialState);
  const navigate = useNavigate();
  const [deleted, setDeleted] = useState<number>(0);
  
  const getLawDomainFields = useCallback(async (model: LawDomainFieldModel,  params: GetLawDomainFieldsAsyncOptionalParams) : Promise<void> => {
    model.PathName = location.pathname;
    const results = await model.getLawDomainFields(params);
    if (!model.error) {
      dispatch(fetchLawDomainFields(results.list, {...results}, false, !Array.isArray(results.list) || results.list.length === 0, true, model));
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const model = new LawDomainFieldModel();
        const { search, pathname} = location;
        let params: GetLawDomainFieldsAsyncOptionalParams = { pageNumber: 1, pageSize: ITEMS_PER_PAGE };
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            const { Filters: { page, query, sort, sortedBy } } = model;
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1,
              searchTerm: model.getQueryParam(query),
              sortOptions: model.getQueryParam(sort),
              sortedBy: model.getQueryParam(sortedBy)
            }
          }
        }
        await getLawDomainFields(model, params);
      }
    })();
  },[deleted, location]);

  const handleDelete : (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      const model = new LawDomainFieldModel();
      if(await model.delete(id)){
        setDeleted(deleted + 1);
      }
    }
  };
  
  const { lawDomainFields, isLoading, isEmptyList, model, paged } = state;

  if (isLoading) {
    return <LoadingScreen />;
  }

  model.Dialog.Header = 'Supprimer le domaine de droit';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'le domaine de droit';
  
  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results 
            lawDomainFields={lawDomainFields} 
            isEmptyList={isEmptyList} 
            paged={paged}
            model={model}
            navigate={navigate}
            location={location}
            onDeleteLawDomainField={handleDelete}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default LawDomainFieldListView;
