import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentCollectionsResource } from 'src/common/types';
import {
  Divider,
  Link,
  Table,
  TableBody
} from '@mui/material';
import { DocCollectionsModel, labelConfig, link, tableRow } from 'src/common';
import { tableCell } from 'src/styles/docCollection';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow, CaijTableRowActive } from 'src/components/table/CaijTableRow';
import usePartners from 'src/functions/hooks/partners';
import { useCollections } from 'src/functions/hooks/docCollection';

export interface DocCollectionsFrInfoProps {
  model: DocCollectionsModel;
  collection: DocumentCollectionsResource;
}

const DocCollectionsFrInfo: FC<DocCollectionsFrInfoProps> = ({model, collection}) => {
  const sPartners = usePartners();
  const sCollection = useCollections();
  const { Titre, DescriptionFr, PartnerId, DocumentType, DocumentTypeId, AccessCollectionCode, DocUrl, CollectionCode, InfoBox, CopyrightZone } = model;
  const { active, title, descriptionFr, partnerId, documentType, documentTypeId, docUrl, accessCollectionCode, collectionCode, infobox, copyrightZone } = collection;
  return (
    <MyCard>
      <CardHeaderDetail title="Collection" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRowActive label={labelConfig.status} sxTableCell={tableCell} active={active} />
          <CaijTableRow sxTableCell={tableCell} label={CollectionCode.Label}>
            {collectionCode}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={Titre.Label}>
            {title}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={DescriptionFr.Label} whiteSpace='pre-wrap'>
            {descriptionFr}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={InfoBox.Label} whiteSpace='pre-wrap'>
            {infobox}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={CopyrightZone.Label} whiteSpace='pre-wrap'>
            {copyrightZone}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={DocUrl.Label} >
            <Link
              underline="none"
              sx={{...link, color:"text.secondary"}}
              href={docUrl}
              rel="noopener noreferrer"
              target="_blank"
            >{docUrl}</Link>
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={PartnerId.Label} >
            {sPartners.allChecked && sPartners.partners.find(({id}) => id == partnerId)?.nameFr}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={DocumentType.Label} >
            {documentType}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={DocumentTypeId.Label} >
            {documentTypeId}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} sxTableRow={tableRow} label={AccessCollectionCode.Label}>
            {sCollection.allChecked && sCollection.docCollections.find(({code}) => code == accessCollectionCode)?.nameFr}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

DocCollectionsFrInfo.propTypes = {
  collection: PropTypes.object.isRequired
};

export default DocCollectionsFrInfo;
