import React, { FC } from 'react';
import {Box,SvgIcon,Typography} from '@mui/material';
import { CustomerNoteDto } from 'src/common/types';
import { CaijTooltip, formatDateTime } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import Link from '@mui/material/Link';
import { greyed } from 'src/styles/customer';
import { getCustomerNoteDate } from '../../CustomerDetailsView/Note/NotesInfo';
import PinIcon from '@mui/icons-material/PushPinSharp';

export interface PinNotesInfoProps {
  notes: CustomerNoteDto[];
  switchTabNote: () => void;
}

export default class PinNotesInfo extends React.Component<PinNotesInfoProps>{
  note = this.props.notes.filter(({pin}) => pin).map((row:CustomerNoteDto, index: number) => 
    <Box key={index} flexDirection="column" sx={{mb: 2}}>
      <MyCard>
        <Box display="flex" flexDirection="column" p={1}>
          <Box display="flex" justifyContent="flex-end">
            <CaijTooltip title="Épingler">
              <SvgIcon fontSize='small'>
                <PinIcon />
              </SvgIcon>
            </CaijTooltip>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" sx={{marginBottom: '0.3em', whiteSpace: 'pre-wrap'}}>{row.note}</Typography>
          </Box>
          <Typography color={greyed}>
            Par <span style={{fontStyle: 'italic'}}>{row.employeeName}</span> - {getCustomerNoteDate(formatDateTime(row.created))}
          </Typography>
        </Box>
      </MyCard>
    </Box>
  )
  render() {
    return (
      <>
        <Box display="flex" justifyContent="flex-end" sx={{mb:2}}>
          <Link
            underline='none'
            component="button"
            variant="body2"
            onClick={() => this.props.switchTabNote()}
          >
            Voir toutes les notes
          </Link>
        </Box>
        { this.note }
      </>
    )
  }
}

