import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { PartnerResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
} from '@mui/material';
import { PartnersModel, labelConfig, tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRowActive } from 'src/components/table/CaijTableRow';

export interface PartnersVisibilityInfoProps {
  model: PartnersModel;
  partner: PartnerResource;
}

const PartnersVisibilityInfo: FC<PartnersVisibilityInfoProps> = ({
  model,
  partner
}) => {
  const { isShownOnHomePage, isPinnedOnTop } = partner;
  const { IsShownOnHomePage, IsPinnedOnTop } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Visibilté" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRowActive label={IsShownOnHomePage.Label} active={isShownOnHomePage} activeTxt={labelConfig.yes} inactiveTxt={labelConfig.no}/>
          <CaijTableRowActive sxTableRow={tableRow} label={IsPinnedOnTop.Label}  active={isPinnedOnTop} activeTxt={labelConfig.yes} inactiveTxt={labelConfig.no}/>
        </TableBody>
      </Table>
    </MyCard>
  );
};

PartnersVisibilityInfo.propTypes = {
  partner: PropTypes.object.isRequired
};

export default PartnersVisibilityInfo;
