import type { PartnerResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchPartners = (partners: PartnerResource[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
   type: 'FETCH_PARTNERS',
   payload: {
     partners: PartnerResource[],
     isLoading?: boolean,
     isEmptyList?: boolean,
     isAuthorize?: boolean
   }
} => ({
  type: actionTypes.FETCH_PARTNERS,
  payload: {
    partners,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchPartner = (partner: PartnerResource, isLoading?: boolean): { 
  type: 'FETCH_PARTNER', 
  payload: { 
    partner: PartnerResource,
    isLoading?: boolean
  } 
} => ({
  type: actionTypes.FETCH_PARTNER,
  payload: {
    partner,
    isLoading
  }
});

export const fetchDetailsPartner = (partner: PartnerResource) : {
  type: 'FETCH_DETAILS_PARTNER'
  payload: {
    partner: PartnerResource,
  }
} => ({
  type: actionTypes.FETCH_DETAILS_PARTNER,
  payload: {
    partner
  }
});

export const deletePartner = (code: string) : {
  type: 'DELETE_PARTNER'
  payload: {
    code: string,
  }
} => ({
  type: actionTypes.DELETE_PARTNER,
  payload: {
    code
  }
});
