import {
  Box,
  Container
} from '@mui/material';
import { FC } from 'react';
import { useEffect, useReducer } from 'react';
import {
  AccessModel,
  fetchLawPracticeField, 
  LawPracticeFieldModel, 
  useLocation, 
  useNavigate, 
  useParams, 
  _lawPracticeField,
  root
} from 'src/common';
import Page from 'src/components/Page';
import { LawPraticeFieldProvider } from 'src/contexts/LawPraticeFieldContext';
import LawPraticeFieldEditForm from './LawPraticeFieldEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import LawPraticeFieldModel from 'src/model/customer/LawPracticeField';
import GenericHeader from 'src/components/header/GenericHeader';

const LawPraticeFieldEditView: FC = () => {
  const model = LawPraticeFieldModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_lawPracticeField, { isLoading: false, lawPracticeField: {} });
  const { id } = useParams();
 
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new LawPracticeFieldModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const lawPraticeField = await model.getLawPracticeFieldById(id);
          if (!model.error) {
            dispatch(fetchLawPracticeField(lawPraticeField, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchLawPracticeField({}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { lawPracticeField, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = lawPracticeField.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <LawPraticeFieldProvider>
            <LawPraticeFieldEditForm
              model={model}
              lawPracticeField={lawPracticeField}
            />
          </LawPraticeFieldProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default LawPraticeFieldEditView;
