import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {$enum} from "ts-enum-util";
import type { DocumentCollectionResource } from 'src/common/types';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { AccessType, labelConfig, tableRow } from 'src/common';
import { tableCell } from 'src/styles/docCollection';
import MyCard from 'src/components/card/MyCard';

export interface AccessInfoProps {
  collection: DocumentCollectionResource;
}

const AccessInfo: FC<AccessInfoProps> = ({
  collection
}) => {
  const { access } = collection;
  return (
    <MyCard>
      <Table>
        <TableBody>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>{labelConfig.accessType}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {$enum(AccessType).getValueOrDefault(access)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

AccessInfo.propTypes = {
  collection: PropTypes.object.isRequired
};

export default AccessInfo;
