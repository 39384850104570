import React, { useState } from "react";
import { DESKTOP_NAV_CLOSED_PERSIST } from "src/common";
import LocalStorageModel from "src/model/LocalStorage";

export interface IMenuContext {
  showIcon: boolean;
  isDesktopNavOpen: boolean;
  isDesktopNavOverlapOpen: boolean;
  setDesktopNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowIcon:  React.Dispatch<React.SetStateAction<boolean>>;
  setDesktopNavOverlapOpen: React.Dispatch<React.SetStateAction<boolean>>;
  localStorage: LocalStorageModel;
}

export const MenuContext = React.createContext<IMenuContext | undefined>(undefined);

export const MenuContextProvider = ({ children }) => {
  const localStorage = new LocalStorageModel();
  const [isDesktopNavOpen, setDesktopNavOpen] = useState<boolean>(!localStorage.hasItem(DESKTOP_NAV_CLOSED_PERSIST));
  const [isDesktopNavOverlapOpen, setDesktopNavOverlapOpen] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(localStorage.hasItem(DESKTOP_NAV_CLOSED_PERSIST));
  const value = { 
    showIcon, 
    setShowIcon, 
    isDesktopNavOpen, 
    setDesktopNavOpen, 
    isDesktopNavOverlapOpen, 
    setDesktopNavOverlapOpen,
    localStorage
  };
  return (
    <MenuContext.Provider value={{...value}}>
      {children}
    </MenuContext.Provider>
  );
};


