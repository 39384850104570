import { FC, useEffect, useState } from "react";
import NProgress from 'nprogress';
import { ContentItemResource, RequestedDocumentFile } from "src/common/types";
import CircularProgress from "@mui/material/CircularProgress";
import { ReperageModel } from "src/common";
import CaijPdf from "src/components/pdf";
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
    return {
        contentItemPdf: {
            cursor: 'pointer',
            textDecoration: 'underline',
            textDecorationColor: 'rgba(138, 133, 255, 0.4)',
            color: '#8a85ff',
            ":hover":{
                textDecorationColor: 'inherit'
            }
        }
    }
});

interface ContentItemPdfProps {
    item: ContentItemResource;
    model: ReperageModel;
}

const ContentItemPdf: FC<ContentItemPdfProps> = ({ item, model }) => {
    const { classes } = useStyles();
    const [loading, setLoading] = useState<boolean>(false);
    const [pdf, setPdf] = useState<any>();
    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    const loadPdf = async () => {
        var result: RequestedDocumentFile = await model.getDocumentFile(item);
        if (result) {
            setPdf(result.data);
        }
        setLoading(false);
    }

    const openPdfClick = () => {
        if (!loading) {
            setLoading(true);
            loadPdf();
        }
    }

    return <>
        <span className={classes.contentItemPdf} onClick={openPdfClick}>
            {loading && <CircularProgress />}
            {item.title}
        </span>
        {pdf && <CaijPdf title="test" base64String={pdf} setShown={() => setPdf(null)} />}
    </>
}

export default ContentItemPdf;