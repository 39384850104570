import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  useParams,
  fetchTribunalType,
  _tribunalType,
  TribunalTypeModel,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import TribunalTypeEditForm from './TribunalTypeEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const TribunalTypeEditView: FC = () => {
  const model = TribunalTypeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_tribunalType, { isLoading: false, tribunalType: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new TribunalTypeModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const tribunalType = await model.getTribunalTypeById(id);
          if (!model.error) {
            dispatch(fetchTribunalType(tribunalType, true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchTribunalType({}, true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { tribunalType, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = tribunalType.labelFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <TribunalTypeEditForm model={model} tribunalType={tribunalType} />
        </Box>
      </Container>
    </Page>
  );
};

export default TribunalTypeEditView;
