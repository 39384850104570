import { ListItem, listItemClasses, TableRow, tableRowClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({theme}) => ({
  margin: '0 0 0.5em 0',
  [theme.breakpoints.up('sm')]:{
    width:"100%"
  },
  [theme.breakpoints.up('lg')]:{
    width:"50%"
  }
}));

export const Title = styled('div')(({theme}) => ({
  fontWeight: 'bold',
  marginLeft: 0,
  [theme.breakpoints.up('sm')]: {
    marginLeft: 20
  }
}));

export const StyledTableRow = styled(TableRow)(({theme}) => ({
  [`&.${tableRowClasses.root}`]: {
    backgroundColor: theme.palette.action.hover,
  }
}));

export const StyledInactiveRow = styled(TableRow)(({theme}) => ({
  [`&.${tableRowClasses.root}`]: {
    backgroundColor: theme.palette.action.hover,
    opacity: 0.5
  }
}));

export const StyledListItem = styled(ListItem)(({theme}) => ({
  width: '100%',
  [`&.${listItemClasses.root}`]: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}));

export const StyledOperation = styled('div')(({theme}) => ({
  margin: '0 0 0.5em 0',
  [theme.breakpoints.up('sm')]:{
    width:"100%"
  },
  [theme.breakpoints.up('lg')]:{
    width:"52%"
  }
}));

export const StyledList = styled('div')(({theme}) => ({
  marginTop: '25px',
  [theme.breakpoints.up('sm')]:{
    minWidth: '1675px'
  }
}));


