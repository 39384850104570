import type {  CustomerPendingChangeResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchCardRequestPending = (
  customerPendingChanges: CustomerPendingChangeResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
): {
  type: 'FETCH_CARD_REQUEST_PENDING';
  payload: { customerPendingChanges: CustomerPendingChangeResource[]; isLoading?: boolean; isEmptyList?: boolean };
} => ({
  type: actionTypes.FETCH_CARD_REQUEST_PENDING,
  payload: {
    customerPendingChanges,
    isLoading,
    isEmptyList,
  },
});