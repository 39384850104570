import { PartnerResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchpartnersAction = {
    type: 'FETCH_PARTNERS';
    payload: {
      partners: PartnerResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchPartnerAction = {
    type: 'FETCH_PARTNER';
    payload: {
      partner: PartnerResource;
      isLoading?: boolean;
    }
};

type FetchDetailsPartnerAction = {
  type: 'FETCH_DETAILS_PARTNER';
  payload: {
    partner: PartnerResource
  }
};

type deletePartnerAction = {
  type: 'DELETE_PARTNER';
  payload: {
    code: string
  }
};

type Action = FetchpartnersAction |
              FetchPartnerAction |
              FetchDetailsPartnerAction |
              deletePartnerAction;

interface PartnersReturnState {
  partners?: PartnerResource[];
  partner?: PartnerResource;
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
}

interface PartnersState {
  partners: PartnerResource[];
  partner: PartnerResource;
  isLoading: boolean;
  isEmptyList: boolean;
  isAuthorize: boolean;
}

const reducer = (state: PartnersState, action: Action): PartnersReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_PARTNERS: {
      const { partners, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        partners,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_PARTNER: {
      const { partner, isLoading } = action.payload;
      return {
        ...state,
        partner,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_PARTNER: {
      const { partner } = action.payload;
      return {
        ...state,
        partner
      };
    }
    case actionTypes.DELETE_PARTNER: {
      return {
        ...state,
        partners: [...state.partners].filter(({code}) => code !== action.payload.code)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
