import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  SvgIcon,
  FormControl
} from '@mui/material';
import PropTypes from 'prop-types';
import type { 
  CustomerResource, 
  LibraryCardTransaction, 
  Theme, 
  CardTransactionType 
} from 'src/common/types';
import { 
  CaijInput, 
  Formik, 
  Mode, 
  Operations, 
  handleChange, 
  CustomerModel, 
  ErrorType,
  labelConfig,
  button,
  CardRequestPendingModel
} from 'src/common';
import { ArrowLeftCircle } from 'react-feather';
import Authorize from 'src/components/Authorize';
import OperationTypeForm from 'src/components/customer/customer/Forms/OperationTypeForm';
// import formatCurrency from 'format-currency';
import MethodForm from 'src/components/customer/customer/Forms/MethodForm';
import { XSquare as CancelIcon, CheckSquare as SaveIcon } from 'react-feather';
import printMessage from 'src/views/errors/MessageError';
import { btnReturn } from 'src/styles/customer';
import CaijInputAmount from 'src/components/inputs/CaijInputAmount';
import { Root, StyledOperation } from 'src/components/styled';

interface TransactionFormProps {
  model: CardRequestPendingModel;
  customer: CustomerResource;
  onHandleRefreshCustomer: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const icon = {
  height: '2em',
  width: '2em',
};

const refill = CustomerModel.getOperationByVal(Operations.Refill);
const payment = CustomerModel.getOperationByVal(Operations.Payment);
const refund = CustomerModel.getOperationByVal(Operations.Refund);
const withdrawal = CustomerModel.getOperationByVal(Operations.Withdrawal);

function getAmountLabel(operation: CardTransactionType, text: string){
  switch(operation){
     case refill :
      text += 'de la recharge';
      break;
    case payment :
      text += 'du paiement';
      break;
    case refund :
      text += 'du remboursement';
      break;
  }
  return text;
}

function getPaymentMethodLabel(operation: CardTransactionType){
  switch(operation){
     case refill:
      return 'Mode de paiement';
    case payment :
    case refund :
      return 'Service';
  }
}

const TransactionForm: FC<TransactionFormProps> = ({ 
  model,
  customer, 
  onSwitchMode, 
  onHandleRefreshCustomer
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  //const opts: { format: string; code: string; symbol: string } = { format: '%v %c', code: 'CAD', symbol: '$' };

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };
  
  const handleSubmit = async (values: LibraryCardTransaction): Promise<void> => {
    const model = new CustomerModel();
    const { id } = customer;
    if (id) {
      if(values.transactionType === withdrawal){
        values.amount = null;
        values.reason = '';
      }
      const response = await model.insertCustomerTransaction(id, values);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await onHandleRefreshCustomer();
      }
    }
  };

  return (
    <Formik
      initialValues={{
        transactionType: refill,
        amount: 0,
        reason: '',
        comment: ''
      }}
      validationSchema={Yup.object().shape({
        amount: Yup.string().when('transactionType',{
            is: (transactionType:CardTransactionType) => { return (transactionType !== withdrawal) },
            then : Yup.string().required(CustomerModel.formatError(ErrorType.required, labelConfig.amount)),
            otherwise: Yup.string()
           })         
      })}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleSubmit, handleBlur, setFieldValue, values, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Button
            onClick={() => switchMode(false)}
            sx={btnReturn}
            startIcon={
              <SvgIcon sx={icon} fontSize='small'>
                <ArrowLeftCircle />
              </SvgIcon>
            }
          >
            Effectuer une transaction
          </Button>
          <FormControl fullWidth sx={{minWidth: 300}} required>
            <StyledOperation>
              <OperationTypeForm 
                customer={customer}
                libraryCardTransaction={values} 
                touched={touched}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </StyledOperation>
            {
              values.transactionType !== withdrawal && (
                <>
                  <Root>
                    <CaijInputAmount
                      name="amount"
                      id='amount'
                      required
                      onHandleBlur={handleBlur}
                      label={getAmountLabel(values.transactionType, 'Montant ')}
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={(touched.amount && errors.amount) || ' '}
                      value={String(values.amount)}
                      onHandleChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e,setFieldValue)}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputProps={{ maxLength: 10 }}
                    />
                    <Box mb={2}>
                      <MethodForm
                        label={getPaymentMethodLabel(values.transactionType)}
                        libraryCardTransaction={values} 
                        onHandleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                  </Root>
                </>
              )
            }
            <Root>
              <CaijInput 
                name="comment"
                margin="normal"
                value={values.comment}
                label="Note additionnelle (facultative)" 
                variant='outlined' 
                onHandleChange={handleChange}
                onHandleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
            </Root>
        </FormControl>
        <Authorize 
          resourceCode={model.ResourceCode} 
          mode={Mode.edit} 
          onIsAuth={l => setIsAuth(l)}
        >
          <Button 
            variant='contained' 
            type='submit' 
            disabled={!isAuth || isSubmitting} color='primary' 
            sx={button}
            startIcon={(
              <SvgIcon fontSize="small">
                <SaveIcon />
              </SvgIcon>
            )}
          >
            Effectuer la transaction
          </Button>
        </Authorize>
        <Button 
          variant='outlined' 
          type='reset' 
          onClick={() => switchMode(false)}
          startIcon={(
            <SvgIcon fontSize="small">
              <CancelIcon />
            </SvgIcon>
          )}
        >
          Annuler
        </Button>
        </form>
      )}
    </Formik>
  );
};

TransactionForm.propTypes = {
  customer: PropTypes.object.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired
};

export default TransactionForm;
