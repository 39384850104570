import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { labelConfig, link } from 'src/common';
import { ClassNameMap, Link, Typography } from '@mui/material';

const typo = {
  color: 'text.secondary', 
  display: 'flex', 
  alignItems: 'center'
};

export default class CompareHistory
{
  private prevChange: Map<string, any>;
  private readonly greenColor='rgb(0, 203, 23)';
  private readonly yellowColor='rgb(255, 135, 29)';
  private readonly redColor='#FF0000';
  private readonly textDecoration='line-through';

  set PrevChange(prevChange: Map<string, any>){
    this.prevChange = prevChange;
  }

  get PrevChange() : Map<string, any> {
    return this.prevChange;
  }

  get addIconStyle(){
    return {
      color:this.greenColor
    }
  }

  get modifIconStyle(){
    return {
      color: this.yellowColor
    }
  }

  get deletedIconStyle(){
    return {
      color: this.redColor
    }
  }

  protected getIcon = (value: String, prevValue: String) : JSX.Element => {
    if(!this.PrevChange){
      return <span><AddCircleIcon sx={this.addIconStyle} /></span>;
    }else if(value && prevValue){
      if(prevValue){
        if(value !== prevValue){
          return <span><ChangeCircleIcon sx={this.modifIconStyle} /></span>;
        }else{
          return;
        }
      }
    }else if(value && !prevValue){
      return <span><AddCircleIcon sx={this.addIconStyle} /></span>;
    }else if(!value && prevValue){
      return <span><RemoveCircleIcon sx={this.deletedIconStyle} /></span>;
    }
  }

  protected getBooleanIcon = (value: Boolean, prevValue: Boolean) : JSX.Element => {
    if(!this.PrevChange){
      return <span><AddCircleIcon sx={this.addIconStyle} /></span>;
    }else if(value !== prevValue){
      return <span><ChangeCircleIcon  sx={this.modifIconStyle} /></span>;
    }else{
      return;
    }
  }

  private getText(title: string, text: string | JSX.Element, allowedHref: boolean = true) : JSX.Element {
    return (
      <>
        {
          title && (<span>{title}: </span>)}
          <span>{
            allowedHref && ((title === labelConfig.contactEmail) || (title === labelConfig.pendingValue)) ? 
            <Link 
              underline="none"
              href={`mailto:${text}`} 
              sx={{...link, color:'text.secondary'}}   
            >
              {text}
            </Link> : text
        }</span>
      </>
    )
  }

  private getRow(labelValue: JSX.Element, value: string, prevValue: string, title: string,note?: string) : JSX.Element {
    const icon = this.getIcon(value, prevValue);
    if(labelValue){//With Label
      return (
        <>
          <Typography variant="body2" sx={typo}>
            <span>{icon}</span>&nbsp;
            <span>{this.getText(title, labelValue)}</span>
          </Typography>
        </>
      );
    }else if(value){//Add && Modif Operation
      return (
        <Typography variant="body2" sx={typo}>
          <span>{icon}</span>&nbsp;
          <span>{this.getText(title, value)}</span>
        </Typography>
      );
    }else if(!value && prevValue){//Delete Operation
      return (
        <>
        <Typography variant="body2" sx={typo}>
          <span>{icon}</span>&nbsp;
          <span style={{textDecoration: this.textDecoration}}>{this.getText(title, prevValue, false)}</span>
        </Typography>
        { note && (<Typography variant="body2" sx={{color: 'text.secondary', paddingLeft: '5px'}}>Note: {note}</Typography>)}
        </>
      );
    }
  }

  protected compareTo = (value: string, prevValue: string, title?: string, labelValue?: JSX.Element, note?: string) : JSX.Element => {
    return this.getRow(labelValue,value, prevValue, title, note);
  }

  protected compareBoolean = (value: Boolean, prevValue: Boolean, title: string, labelValue: JSX.Element) : JSX.Element => {
    const icon = this.getBooleanIcon(value, prevValue);
    return (
      <Typography variant="body2" sx={{display: 'flex', alignItems: 'center', color: 'text.secondary'}}>
        <span>{icon}</span>&nbsp;
        <span>{title && (<span>{title}: </span>)}<span>{labelValue}</span></span>
      </Typography>
    )
  }
}