import type { FC } from 'react';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { tableRow } from 'src/common';
import MyCard from 'src/components/card/MyCard';
import parse from 'html-react-parser';
import { Theme } from "@mui/material";

export interface NoteProps {
  allowedHTML?: boolean;
  note: string;
  label: string;
}

const Note : FC<NoteProps> = ({ allowedHTML, note, label }) => {
  return (
    <MyCard>
      <Table>
        <TableBody>
          <TableRow sx={tableRow}>
            <TableCell sx={{width: '190px', fontWeight: (theme: Theme) => theme.typography.fontWeightMedium}}>{label}</TableCell>
            <TableCell>
              { allowedHTML ? (
                  <div>{note ? parse(note) : ''}</div>
                ) : (
                  <Typography variant='body2' sx={{color:'text.secondary', whiteSpace: 'pre-wrap'}}>
                    {note}
                  </Typography>
                )
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

export default Note;
