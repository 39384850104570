import { Box, Container } from "@mui/material";
import { FC, useCallback, useEffect, useReducer, useState } from "react";
import Page from "src/components/Page";
import Results from "./Results";
import SpecialFilesModel from "src/model/content/SpecialFiles";
import { GetDocumentListAsyncOptionalParams} from "src/common/types";
import { useLocation, useNavigate } from "react-router";
import AccessModel from "src/model/Access";
import { fetchSpecialFiles } from "src/store/actions/content/SpecialFilesAction";
import { ITEMS_PER_PAGE, _specialFiles, root } from "src/common";
import LoadingScreen from "src/components/loading/LoadingScreen";
import { BtnOk } from "src/components/dialog/CaijDialog";
import { EditableDocumentModel } from "src/model/EditableDocument";
import printMessage from "src/views/errors/MessageError";
import GenericHeader from "src/components/header/GenericHeader";

const initialState = {
    specialFiles: [],
    paged: {
        totalCount: 0,
        pageSize: ITEMS_PER_PAGE,
        currentPage: 1,
        totalPages: 0,
      },
    isLoading: false,
    isEmptyList: true
}

const SpecialFilesListView: FC = () => {
    const navigate = useNavigate();
    const model = SpecialFilesModel.getInstance();
    const documentContentModel = new EditableDocumentModel(model);
    const access = new AccessModel(model.ResourceCode);
    const location = useLocation();
    const [state, dispatch] = useReducer(_specialFiles, initialState);
    const [deleted, setDeleted] = useState<number>(0);
    //Dialog
    model.Dialog.Header = 'Supprimer le document';
    model.Dialog.BtnText = BtnOk.DELETED;
    model.Dialog.Name = 'le document';
    
    const getSpecialFiles = useCallback(async (model: SpecialFilesModel, params: GetDocumentListAsyncOptionalParams): Promise<void> => {
        model.PathName = location.pathname + location.search;
        const results = await model.getSpecialFiles(params);
        if (!model.error) {
            dispatch(fetchSpecialFiles(results.list,{...results}, true, !Array.isArray(results.list)))
        }
    }, [location])

    useEffect(() => {
        (async () => {
            if(access.canRead()){
              let params: GetDocumentListAsyncOptionalParams = { 
                pageNumber: 1, 
                pageSize: ITEMS_PER_PAGE, 
                collection: 'special_files',
                sortOptions: 'asc', 
                sortedBy: 'title',
              };
              const model = new SpecialFilesModel();
              if(location.search){
                if(await model.setQueryParams(location.search, model, navigate)){
                  const { Filters: { page, sort, sortedBy }} = model;
                  params = {
                    ...params,
                    pageNumber: +model.getQueryParam(page) + 1,
                    sortOptions: model.getQueryParam(sort),
                    sortedBy: model.getQueryParam(sortedBy)
                  };
                }
              }
              await getSpecialFiles(model,params);
            }
          })();
    }, [location, deleted]);

    const handleDelete: (id: string) => Promise<void> = async id => {
        if (id && access.canDelete()) {
            if (await model.deleteDocument(id)) {
                setDeleted(deleted + 1);
            }
        }
    };

    const handleRemoveLock: (id: string) => Promise<void> = async id => {
        if (id) {
            const response = await documentContentModel.removeDocumentLock(id);
            const { status, message } = response;
            if (!model.error) {
                setDeleted(deleted + 1);
            }
            printMessage({status, message});
        }
    }

    const { specialFiles, isEmptyList, paged, isLoading } = state;

    if (!isLoading) return <LoadingScreen />;

    return (
        <Page sx={root} title={model.PageTitle}>
            <Container maxWidth={false}>
                <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
                <Box mt={3}>
                    <Results
                        model={model}
                        paged={paged}
                        specialFiles={specialFiles}
                        isEmptyList={isEmptyList}
                        onDeleteSpecialFile={handleDelete}
                        onRemoveLock={handleRemoveLock}
                        navigate={navigate}
                    />
                </Box>
            </Container>
        </Page>
    )
}

export default SpecialFilesListView;