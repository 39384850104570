import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs
} from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  AccessPermissions,
  CaijCard,
  fetchProduct,
  fetchSubscriptions, 
  Forbidden, 
  ListType,
  ProductModel,
  setEditHash,
  setHash,
  SubscriptionModel, 
  SUBSCRIPTION_TITLE, 
  Unauthorized, 
  useLocation, 
  useNavigate, 
  useParams,
  _product,
  _subscription,
  root
} from 'src/common';
import type {ProductResource} from 'src/common/types';
import Page from 'src/components/Page';
import Details from './Details';
import DetailsEditForm from './Forms/DetailsEditForm';
import SubscriptionForm from './Forms/SubscriptionForm';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import SubscriptionsInfo from 'src/components/requiredParameter/listInfo';
import AccessInfo from './Details/accessInfo';
import MyCard from 'src/components/card/MyCard';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

interface TabsProduct {
  value: string, label: string
}

const tabs: TabsProduct[] = [
  { value: 'details', label: 'Détails' },
  { value: 'subscription', label: 'Permissions' }
];

const initialSubscriptionState = {
  subscriptions: [],
  isEmptyList: true,
  isLoading: false,
  isAuthorize: false,
};

const ProductDetailsView: FC = () => {
  const model = ProductModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_product, { product: {} });
  const [stateSubscription, dispatchSubscription] = useReducer(_subscription, initialSubscriptionState);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    subscription: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  model.Dialog.Header = 'Supprimer le produit et service';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'le produit et service';
  
  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsProduct) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }

  const getSubscriptions = useCallback(async () : Promise<void> => {
    const model = new SubscriptionModel();
    model.skipHandleError = true;
    const subscriptions = await model.getSubscriptions();
    const { error } = model;
    if (error) {
      if (error.status === Forbidden || error.status === Unauthorized) {
        dispatchSubscription(fetchSubscriptions(subscriptions, true, false, false));
      }else{
        dispatchSubscription(fetchSubscriptions(subscriptions, true, true, true));
      }
    } else {
      dispatchSubscription(fetchSubscriptions(subscriptions, true, !Array.isArray(subscriptions) || subscriptions.length === 0, true));
    }
  },[]);

  const getProductById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const model = new ProductModel();
      const product = await model.getProductById(id);
      if (!model.error) {
        dispatch(fetchProduct(product)); 
      }
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      handleBrowserRefresh();
      await Promise.all([getSubscriptions(), getProductById()]);
    })();
  }, []);

  useEffect(() => {
    if(access.canRead()){
      const { hash, pathname } = location;
      model.PathName = pathname + hash;
    }
  },[location]);

  const { product } = state;

  if (Object.keys(product).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${product.nameFr} - ${product.code}`;

  const { subscriptions } = stateSubscription;

  const setEditModeProduct = (isEdit: boolean, value?: string) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeProduct(isEdit, value);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    event.preventDefault();
    setHash(value, navigate);
    setCurrentTab(value);
    setEditModeProduct(false, value);
  };

  const handleRefreshProduct = async (product: ProductResource, value: string) : Promise<void> => {
    setHash(value, navigate);
    await getSubscriptions();
    dispatch(fetchProduct(product));
    setEditModeProduct(false, value);
  };

  const editProduct = () => {
    setEditHash('edit', navigate);
    setEditModeProduct(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case tabs[0].value:
        if (editMode.details) {
          return (
            <DetailsEditForm
              model={model}
              product={product}
              onHandleRefreshProduct={(a) => handleRefreshProduct(a, tabs[0].value)}
              onSwitchMode={(l) => switchMode(l, tabs[0].value)}
            />
          );
        }
        return (
          <Details model={model} product={product} />
        );
      case tabs[1].value:
        if (editMode.subscription) {
          return (
            <SubscriptionProvider>
              <SubscriptionForm
                model={model}
                product={product}
                onHandleRefreshProduct={(a) => handleRefreshProduct(a, tabs[1].value)}
                onSwitchMode={(l) => switchMode(l, tabs[1].value)}
              />
            </SubscriptionProvider>
          );
        }
        return (
            <Grid container spacing={3}>
               <Grid item md={12} xs={12} lg={12}>
                  <MyCard>
                    <AccessInfo product={product}/>
                    {
                      product.access === AccessPermissions.Private && (
                        <Grid item md={12} xs={12} lg={12}>
                          <Box m={2}>
                              <CaijCard title={SUBSCRIPTION_TITLE}>
                                <SubscriptionsInfo 
                                  isAuthorize={stateSubscription.isAuthorize}
                                  listType={ListType.Subscription}
                                  data={subscriptions}
                                  lists={product.subscriptions}
                                />
                              </CaijCard>
                            </Box>
                        </Grid>
                      )
                    }
                  </MyCard>
            </Grid>
          </Grid>
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={product.nameFr} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editProduct()}
          titleBadge={{label: product.code}}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default ProductDetailsView;
