import { DocCollectionsModel} from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: DocCollectionsModel) => {
  const { CollectionCode, Titre, DocumentTypeId, DocumentType, AccessCollectionCode, DocumentCollectionsSorts } = model;
  return Yup.object().shape({
    collectionCode: CollectionCode.required(true),
    title: Titre.required(true),
    documentTypeId: DocumentTypeId.required(true),
    documentType: DocumentType.nullable(true),
    accessCollectionCode: AccessCollectionCode.required(true),
    documentCollectionsSort : Yup.object().shape({
      order1: DocumentCollectionsSorts[0].required(),
      order2: DocumentCollectionsSorts[1].required(),
    })
  });
};

export default validateSchema;