import { PartnersModel, ErrorType } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: PartnersModel) => {
  const { Code, NameFr, NameEn, HomeUrlFr, HomeUrlEn, ContentUrlFr, ContentUrlEn, LogoUrlFr, LogoUrlEn, } = model;
  return Yup.object().shape({
    code: Code.required(true),
    nameFr: NameFr.required(true),
    nameEn: NameEn.required(true),
    homeUrlFr: HomeUrlFr.nullable(true), 
    homeUrlEn: HomeUrlEn.nullable(true), 
    contentUrlFr: ContentUrlFr.nullable(true), 
    contentUrlEn:  ContentUrlEn.nullable(true), 
    logoUrlFr: LogoUrlFr.nullable()
      .test(LogoUrlFr.Label, PartnersModel.formatError(ErrorType.max, LogoUrlFr.Label, LogoUrlFr.MaxLength), (logoUrlFr: string) => {
        return PartnersModel.verifyImageUrlLength(logoUrlFr, LogoUrlFr.MaxLength);
    }),
    logoUrlEn: LogoUrlEn.nullable()
      .test(LogoUrlEn.Label, PartnersModel.formatError(ErrorType.max, LogoUrlEn.Label, LogoUrlEn.MaxLength), (logoUrlEn: string) => {
        return PartnersModel.verifyImageUrlLength(logoUrlEn, LogoUrlEn.MaxLength);
      }),
    });
};

export default validateSchema;