import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { LawDomainFieldResource } from 'src/common/types';
import LawDomainFieldFrInfo from './LawDomainFieldFrInfo';
import LawDomainFieldEnInfo from './LawDomainFieldEnInfo';
import { LawDomainFieldModel } from 'src/common';

interface DetailsProps {
  model: LawDomainFieldModel;
  lawDomainField: LawDomainFieldResource;
}

const Details: FC<DetailsProps> = ({
  model,
  lawDomainField
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <LawDomainFieldFrInfo model={model} lawDomainField={lawDomainField} />
    </Grid>
    <Grid
      item
      lg={6}
      md={6}
      xl={6}
      xs={12}
    >
      <LawDomainFieldEnInfo model={model} lawDomainField={lawDomainField} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  lawDomainField: PropTypes.object.isRequired
};

export default Details;
