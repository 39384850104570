import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio } from '@mui/material';
import { InputProps2, LabelPlacement } from 'src/types/input';

interface CaijRadioProps {
  name: string;
  id?: string;
  value: string | boolean | number;
  sx?:any;
  label: string;
  disabled?: boolean;
  inputProps?: InputProps2;
  labelPlacement?: LabelPlacement;
}

const CaijRadio: FC<CaijRadioProps> = ({
  value,
  disabled,
  inputProps,
  ...rest
} : CaijRadioProps) => (
  <> 
    <FormControlLabel
        {...rest}
        value={value || ''}
        control={<Radio color="primary" inputProps={inputProps} />}
      />
  </>
);

CaijRadio.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  sx:PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
  label: PropTypes.string,
  inputProps: PropTypes.object,
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
};

CaijRadio.defaultProps = {
  inputProps: { 'aria-labelledby': '', 'aria-label': '' },
  labelPlacement: 'end',
  disabled: false
}

export default CaijRadio;
