import React, { Suspense } from 'react';
import type { FC } from 'react';
import { SnackbarProvider } from 'notistack';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { AuthProvider } from 'src/contexts/AzureAdAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme/index';
import routes from 'src/routes';
import { SnackbarUtilsConfigurator } from 'src/utils/snackbar';
import { HelmetProvider } from 'react-helmet-async';
import 'src/styles/styles.css';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

const App: FC = () => {
  const { settings } = useSettings();

  const router = createBrowserRouter(routes);

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={10} autoHideDuration={4000} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <SnackbarUtilsConfigurator />
            <AuthProvider>
              <GlobalStyles styles={() => ({
                  '*': {
                    boxSizing: 'border-box',
                    margin: 0,
                    padding: 0,
                    fontFamily: "Roboto,Helvetica,Arial,sans-serif"
                  },
                  html: {
                    height: '100%',
                    width: '100%'
                  },
                  body: {
                    height: '100%',
                    width: '100%',
                    padding: 0,
                  },
                  '#root': {
                    width: '100%',
                    height: '100%'
                  }
              })}/>
              <Suspense fallback={<div>Loading...</div>}>
                <RouterProvider router={router} />
              </Suspense>
            </AuthProvider>
          </SnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
