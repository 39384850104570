import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  useParams,
  fetchLibraryType,
  _libraryType,
  LibraryTypeModel,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import LibraryTypeEditForm from './LibraryTypeEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const LibraryTypeEditView: FC = () => {
  const model = LibraryTypeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_libraryType, { isLoading: false,libraryType: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new LibraryTypeModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const libraryType = await model.getLibraryTypeById(id);
          if (!model.error) {
            dispatch(fetchLibraryType(libraryType,true));
          }
        }else{
          model.PathName = pathName;
          dispatch(fetchLibraryType({},true));
        }
      }
    };
    initialise();
  }, [id, location]);

  const { libraryType, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = libraryType.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <LibraryTypeEditForm model={model} libraryType={libraryType} />
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryTypeEditView;
