import { Dispatch, FC, ReactNode, useState} from 'react';
import PropTypes from 'prop-types';
import { SetFieldValue } from 'src/common/types';
import { Trash2 as TrashIcon } from 'react-feather';
import { Box, IconButton, SvgIcon } from '@mui/material';
import { PageModel } from 'src/model/App';
import { CaijTooltip } from 'src/common';

interface CaijImageProps {
  id?: string;
  src: string;
  alt?: string;
  style?: any;
  model: PageModel;
  name: string;
  setFieldValue: SetFieldValue;
}

const maxWidth = 200;
const maxHeight = 200;
 
const container = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth,
  maxHeight: maxHeight + 20,
  marginBottom:'15px'
};

interface Image {
  children: ReactNode;
  open: boolean; 
  model: PageModel;
  name: string;
  setFieldValue: SetFieldValue; 
  setOpen: Dispatch<React.SetStateAction<boolean>>;
};

function Image(props: Image) {
  const { model, open, setOpen, children, setFieldValue, name } = props;

  const deleteLogo = async () => {
    setFieldValue(name,'');
  };

  return (
    <>
      <Box sx={container}>
        <div 
          onMouseOver={() => setOpen(true)}  
          onMouseLeave={() => setOpen(false)}  
          style={{alignSelf: 'end',marginRight: '-18px',marginBottom: '-18px', visibility: open ? 'visible' : 'hidden'}}>
          <CaijTooltip title="Supprimer" marginTop="18px">
            <IconButton onClick={() => deleteLogo()} style={{background: 'rgba(0,0,0,0.8)'}}>
              <SvgIcon fontSize="small" style={{color: 'rgba(255,255,255)'}}>
                <TrashIcon />
              </SvgIcon>
            </IconButton>
          </CaijTooltip>
        </div>
        { children } 
      </Box>
    </>
  );
}
const CaijImage: FC<CaijImageProps> = ({
  style,
  model,
  setFieldValue,
  name,
  ...rest
 }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Image 
      model={model}
      setFieldValue={setFieldValue}
      open={open}
      setOpen={setOpen}
      name={name}
    >
      <img
        {...rest}
        data-testid={rest.id}
        style={{ maxWidth: 200, maxHeight: 200}} 
        onContextMenu={(e)=> e.preventDefault()}
        onMouseOver={() => setOpen(true)} 
        onMouseLeave={() => setOpen(false)}
      />
    </Image>
  );
};

CaijImage.propTypes = {
  id:  PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired
};

CaijImage.defaultProps = {
  alt: '',
  style: { display: 'inline-block' }
};

export default CaijImage;
