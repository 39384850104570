import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { DocumentResource } from 'src/common/types';
import { Box, SvgIcon } from '@mui/material';
import {formatDate, labelConfig} from 'src/common';
import { Calendar as DateIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import CaijLabel from 'src/components/label/CaijLabel';

export interface DateInfoProps {
  document: DocumentResource;
}

const DateInfo: FC<DateInfoProps> = ({document}) => {
  const { published, modified, downloaded } = document;
  const metadatas = document.metadatas ? JSON.parse(document.metadatas) : null;
  return (
    <Box sx={{mb:'2em'}} display='flex'>
    <SvgIcon fontSize='small'>
      <DateIcon />
    </SvgIcon>
    <Box display='flex' marginLeft='0.5em' flexDirection='column'>
      <Box display='flex' alignItems='center'>
        <CaijTypography sx={{ fontWeight: 'bold' }}>Date</CaijTypography>
      </Box>
      { modified && <CaijLabel label={labelConfig.updatedDate}>{formatDate(modified)}</CaijLabel> }
      { published && <CaijLabel label={labelConfig.publishedDate}>{formatDate(published)}</CaijLabel> }
      { downloaded && <CaijLabel label="Date de téléchargement">{formatDate(downloaded)}</CaijLabel> }
      { metadatas?.archived && <CaijLabel label={labelConfig.archivedDate}>{formatDate(metadatas.archived)}</CaijLabel>}
    </Box>
  </Box>
  );
};

DateInfo.propTypes = {
  document: PropTypes.object.isRequired
};

export default DateInfo;