import React from "@mui/material"
import { FC } from "react";
import {  
  PathModel, 
} from "src/common"
import { CustomerResource } from "src/common/types";
import TableCells from "./TableCells";
import { CaijTableRowClickable } from "src/components/table/CaijTableRowClickable";

interface ExpertProps {
  path: PathModel;
  customer: CustomerResource;
}

const Expert : FC<ExpertProps> = ({customer, path}) => (
  <CaijTableRowClickable key={customer.id} path={path.getDetail(customer.id)}>
    <TableCells customer={customer} />
  </CaijTableRowClickable>
)  

export default Expert;