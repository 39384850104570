import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import { XSquare as CancelIcon } from 'react-feather';
import { useNavigate } from 'src/common';

interface CaijButtonResetProps {
  disabled?: boolean;
  pathName?: string;
  sx?:any;
  value?: string;
  onSwitchMode?: (value: boolean) => void;
  handleReset?: (e: any) => void;
}

const CaijButtonReset: FC<CaijButtonResetProps> = ({
  disabled,
  pathName,
  sx,
  value,
  handleReset,
  onSwitchMode
}) => {
  const navigate = useNavigate();
  if(pathName){
    return (
      <Button
        variant="outlined"
        type="reset"
        disabled={disabled}
        sx={sx}
        onClick={() => navigate(pathName)}
        startIcon={(
          <SvgIcon fontSize="small">
            <CancelIcon />
          </SvgIcon>
        )}
      >
        {value}
      </Button>
    )
  }else if(handleReset){
    return (
      <Button
        variant="outlined"
        type="reset"
        disabled={disabled}
        sx={sx}
        onClick={(e) => handleReset(e)}
        startIcon={(
          <SvgIcon fontSize="small">
            <CancelIcon />
          </SvgIcon>
        )}
      >
        {value}
      </Button>
    )
  }else if(onSwitchMode){
    return (
      <Button
        variant="outlined"
        type="reset"
        disabled={disabled}
        sx={sx}
        onClick={() => onSwitchMode(false)}
        startIcon={(
          <SvgIcon fontSize="small">
            <CancelIcon />
          </SvgIcon>
        )}
      >
        {value}
      </Button>
    )
  }
  return null;
};

CaijButtonReset.propTypes = {
  disabled: PropTypes.bool,
  pathName: PropTypes.string,
  sx: PropTypes.any,
  onSwitchMode: PropTypes.func
};

CaijButtonReset.defaultProps = {
  disabled: false,
  value: 'Annuler'
};

export default CaijButtonReset;
