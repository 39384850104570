import { Theme } from "@mui/material";

export const tableCell = {
    width: 1/5,
    fontWeight: (theme: Theme) => theme.typography.fontWeightMedium
};

export const filters = {
    margin: '0em 0.5em 0em 0.5em'
};

