import React, { useState } from 'react';
import {Box,CardContent,Grid} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import {
  Authorize, btnSubmit, CaijButtonReset,
  CaijButtonSubmit,
  CaijCheckbox,
  Formik,
  handleChangeCheckBox,
  LibraryServiceModel,
  Mode,
} from 'src/common';
import type {
  LibraryServiceResource, 
  LibraryServiceResourceForEdit 
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import printMessage from 'src/views/errors/MessageError';
import Libraries from 'src/components/library/libraryService/Forms/Libraries';

interface LibraryFormProps {
  model: LibraryServiceModel;
  libraryService: LibraryServiceResource;
  onHandleRefreshLibaryService: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const LibraryForm: FC<LibraryFormProps> = ({
  model,
  libraryService,
  onHandleRefreshLibaryService,
  onSwitchMode
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedLibraries, setSelectedLibraries] = useState<number[]>([]);
  const [selectedQtyRequired, setSelectedQtyRequired] = useState<Map<number, string>>();
  const [isListValid, setIsListValid] = useState<boolean>(true);

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  return (
    <>
      <Formik
        initialValues={LibraryServiceModel.getInitialValues(libraryService)}
        onSubmit={async (values: LibraryServiceResource) => {
          if(!isListValid) return;
          const submitData = values as LibraryServiceResourceForEdit;
          if (submitData && submitData.id) {
            const model = new LibraryServiceModel();
            model.SelectedQtyRequired = selectedQtyRequired;
            model.saveListRecords(selectedLibraries, submitData);
            const response = await model.updateLibraryService(submitData);
            if (!model.error) {
              const { status, message } = response
              printMessage({status,message});
              await onHandleRefreshLibaryService();
            }
          }
        }}
      >
        {({handleSubmit, isSubmitting, values, setFieldValue}) => (
          <form onSubmit={handleSubmit}>
            <MyCard>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <CaijCheckbox
                      name={model.RequiredParameter.Name}
                      value={values.requireParameters}
                      checked={values.requireParameters}
                      label={model.RequiredParameter.Label}
                      onHandleChangeCheckBox={handleChangeCheckBox}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Libraries
                    setIsListValid={(l) => setIsListValid(l)}
                    allowedQtyRequired={values.requireParameters || false}
                    lists={values.libraryServiceLibraries}
                    onHandleItemsSelected={l => setSelectedLibraries(l)}
                    onHandleRequireParmeters={l => setSelectedQtyRequired(l)}
                  />
                </Grid>
                <Box>
                  <Authorize
                    resourceCode={model.ResourceCode}
                    mode={Mode.edit}
                    onIsAuth={(l) => setIsAuth(l)}
                  >
                    <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                  </Authorize>
                  <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
                </Box>
              </CardContent>
            </MyCard>
          </form>
        )}
      </Formik>
    </>
  );
};

LibraryForm.propTypes = {
  libraryService: PropTypes.object.isRequired,
  onHandleRefreshLibaryService: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
};

export default LibraryForm;

