import snackbar from 'src/utils/snackbar';
import type { Error } from 'src/common/types';
import { Success, Created } from 'src/common';

const printMessage = (err: Error): void => {
  const {
    status, message, data, errors, title
  } = err;
  if (status === Created && typeof message === 'string') {
    snackbar.success(message);
  } else if (status === Success) {
    if (data) snackbar.success(data);
    else snackbar.success(message);
  } else if (!(status === Success || status === Created)) {
    if (message || title) {
      if(message){
        snackbar.error(message);
      }else{
        snackbar.error(title);
      }
    } else if (errors) {
      Object.keys(errors).forEach(error => {
        errors[error].forEach((value: string) => {
          snackbar.error(value);
        });
      });
    }
  } else if (typeof err === 'string') {
    snackbar.error(err);
  } else if (err.message && typeof err.message) {
    snackbar.error(err.message);
  }
};

export default printMessage;
