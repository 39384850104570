import { useState } from "react";
import { ITEMS_PER_PAGE, NavigateFunction, PaginationModel } from "src/common";
import { SearchFilter } from "src/common/types";

interface IModel {
  getConfigParameters : (filter: SearchFilter, index: number, value: string | number) => void;
  getParams : () => void;
  getUrlEncode : (...args: any[]) => string;
};

export default function useSort(tab?: string) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const pagination = new PaginationModel();

    function handleRequestSort<ModelType extends IModel>(event: any, property: React.SetStateAction<string>, model?: ModelType, filter?: SearchFilter, navigate?: NavigateFunction){
      const isAsc = orderBy === property && order === 'asc';
      const sort = isAsc ? 'desc' : 'asc';
      setOrder(sort);
      setOrderBy(property);
      if(model && filter){
        const newFilter: SearchFilter = {...filter};
        if(newFilter?.sort)
          newFilter.sort = sort;
        else
          newFilter['sort'] = sort;
        model.getConfigParameters(newFilter,28,property.toString());
        if(tab)
          navigate(model.getUrlEncode.apply(model, model.getParams()) +  (tab ? `#${tab}` : ""));
        else
          navigate(model.getUrlEncode.apply(model, model.getParams()));
      }
    };

    function sort<T>(data: T[], page: number){
      if(orderBy)
        return pagination.stableSort(data, pagination.getComparator(order, orderBy))
                         .slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE);
      return data.slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE);
    }

    return { order, setOrder, orderBy, setOrderBy, sort, handleRequestSort };
};
