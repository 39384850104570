import { 
  AppBar,
  Dialog, 
  DialogContent, 
  IconButton, 
  Link, 
  MenuItem, 
  TableBody, 
  TableRow, 
  Toolbar, 
  Typography 
} from "@mui/material";
import { 
  useCallback, 
  useEffect, 
  useReducer, 
  useState 
} from "react";
import { 
  dialog, 
  _customer,
  CustomerModel,
  fetchEmailStatus,
  formatDate,
  EmptyList,
  Transition,
  tableRow,
} from "src/common";
import { CustomerResource, EmailStatusDto } from "src/common/types";
import CloseIcon from '@mui/icons-material/Close';
import CaijTable from "src/components/table/CaijTable";
import { CaijTableHeader } from "src/components/table/CaijTableHeader";
import { CaijTableCell, CaijTableCellConfirm } from "src/components/table/CaijTableCell";
import LoadingScreen from "src/components/loading/LoadingScreen";
import CaijAppBar from "src/components/AppBar";

const headCells = [
  { width: "15%", name: "Destinataire"},
  { width: "25%", name: "Sujet"},
  { width: "40%", name: "Message"},
  { width: "10%", name: "Statut"},
  { width: "10%", name: "Date"}
];

export default function EmailStatus(props: {customer: CustomerResource, setAnchorEl: () => void}){
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useReducer(_customer,{ emailStatus:[], isLoading: false, isEmptyList: true });
  
  const handleClose = () => {
    setOpen(false);
  };

  const getEmailStatus = useCallback(async () : Promise<void> =>  {
    const model = CustomerModel.getInstance();
    const emailStatus = await model.getCustomerMailStatus(props.customer.id);
    if(!model.error){
      dispatch(fetchEmailStatus(emailStatus, true, !Array.isArray(emailStatus) || emailStatus.length === 0));
    }
  },[]);

  useEffect(() => {
    (async () => {
      await getEmailStatus();
    })();
  },[]);

  const { emailStatus, isLoading, isEmptyList } = state;

  if(!isLoading) return <LoadingScreen />

  return (
    <>
      <Link href='#' onClick={() => {props.setAnchorEl(); setOpen(true)}} underline='none'>
        <MenuItem>
          <Typography variant="body1">
            {`Voir les courriels envoyés (${emailStatus.length})`}
          </Typography>
        </MenuItem>
      </Link>
      <Dialog fullScreen open={open} TransitionComponent={Transition} onClose={handleClose}>
        <CaijAppBar title="Historique des courriels envoyés" handleClose={handleClose} />
        <DialogContent dividers sx={{...dialog, width: 'auto' }}>
        { isEmptyList ? (<EmptyList />) : (
            <CaijTable>
              <CaijTableHeader data={headCells} />
              <TableBody>
              { emailStatus.map((row: EmailStatusDto, index: number) => (
                  <TableRow key={index} hover tabIndex={-1} sx={tableRow}>
                    <CaijTableCell>{row.recipient}</CaijTableCell>
                    <CaijTableCell>{row.subject}</CaijTableCell>
                    <CaijTableCell whiteSpace='pre-wrap'>{row.details}</CaijTableCell>
                    <CaijTableCellConfirm confirm={row.status === 'delivered' ? true : false} />
                    <CaijTableCell>{formatDate(row.date)}</CaijTableCell>
                  </TableRow>
                ))
              }
              </TableBody>
            </CaijTable>
          )
        }
        </DialogContent>
      </Dialog>
    </>
   );
};
