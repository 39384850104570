import { Alert, Box, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import { FC, useCallback, useEffect, useReducer } from "react";
import { AccessModel, CaijButton, EmployeeRole, NoContent, _editableDocument, btnSubmit } from "src/common";
import HtmlContentEditor from "src/components/content/HtmlContentEditor";
import LoadingScreen from "src/components/loading/LoadingScreen";
import useAuth from "src/hooks/useAuth";
import { EditableDocumentModel } from "src/model/EditableDocument"
import SpecialFilesModel from "src/model/content/SpecialFiles";
import { fetchHtmlContentLock, fetchHtmlContent } from "src/store/actions/content/EditableDocumentAction";

const initialState = {
    content: null,
    documentLock: null,
    isLoading: true,
}

export interface ContentEditFormProps {
    model: SpecialFilesModel;
    access: AccessModel;
    documentId: string;
}

const ContentEditForm: FC<ContentEditFormProps> = ({ model, documentId, access }) => {
    const documentContentModel = new EditableDocumentModel(model);
    const [state, dispatch] = useReducer(_editableDocument, initialState);
    const authContext = useAuth();
    const employee = authContext.user.employee;

    const getDocumentContent = useCallback(async () => {
        if (access.canRead()) {
            const lockedResult = await documentContentModel.getDocumentLockStatus(documentId);
            if (lockedResult) {
                dispatch(fetchHtmlContentLock(lockedResult));
            }
            const content = await documentContentModel.getContent(documentId);
            if (!model.error) {
                dispatch(fetchHtmlContent(content, false));
            } else if (model.error.status === NoContent) {
                dispatch(fetchHtmlContent("", false));
                model.resetError();
            }
        }
    }, [documentId]);

    const lockDocument = async () => {
        await documentContentModel.lockDocumentContentEditing(documentId);
        if (!model.error) {
            const lockedResult = await documentContentModel.getDocumentLockStatus(documentId);
            dispatch(fetchHtmlContentLock(lockedResult));
        }
        model.resetError();
    }

    const removeDocumentLock = async () => {
        const result = await documentContentModel.removeDocumentLock(documentId);
        if (!model.error) {
            dispatch(fetchHtmlContentLock(null));
            if (content == null) {
                await getDocumentContent();
            }
        }
        model.resetError();
    }

    const { documentLock, content, isLoading } = state;

    useEffect(() => {
        (async () => {
            await getDocumentContent();
        })();
    }, [getDocumentContent]);

    if (isLoading) return <LoadingScreen />

    if (documentLock && employee.identifier !== documentLock?.lockedBy) {
        return (<>
            <Typography variant='h6' sx={{ color: 'text.primary' }}>
                <span>Document verrouillé</span>
            </Typography>
            <Box mt={2}>
                <Alert severity="warning">
                    <p>Le contenu de ce document est verrouillé, car un(e) autre utilisateur ({documentLock.lockedBy}) a apporté des modifications non enregistrées. Veuillez soit attendre que les modifications soient sauvegardées ou contacter un admin pour déverrouiller ce document.</p>
                </Alert>
                <Box mt={2}>
                    {documentLock && employee.role === EmployeeRole.Admin && <CaijButton
                        sx={btnSubmit}
                        onHandleClick={removeDocumentLock}
                    >Déverrouiller</CaijButton>}
                </Box>
            </Box>
        </>)
    }

    return (
        <HtmlContentEditor
            pageModel={model}
            documentModel={documentContentModel}
            initialContent={content}
            documentLock={documentLock}
            lockDocument={lockDocument}
            removeDocumentLock={removeDocumentLock}
            docId={documentId}
            redirectPath={model.Path.getDetail(documentId)}
        />
    )
}

ContentEditForm.propTypes = {
    documentId: PropTypes.string.isRequired
}

export default ContentEditForm;