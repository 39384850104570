import type { TranslationResource } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';
import type Pagination from 'src/types/pagination';
import { TranslationModel } from 'src/common';
import { Paged } from 'src/types/pagination';

type FetchTranslationsAction = {
    type: 'FETCH_TRANSLATIONS';
    payload: {
      translations: TranslationResource[],
      paged: Paged,
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
      model?: TranslationModel;
    }
}

type FetchTranslationAction = {
  type: 'FETCH_TRANSLATION'
  payload: {
    translation: TranslationResource
  }
};

export type TranslationAction = FetchTranslationsAction |
                                FetchTranslationAction;

interface TranslationReturnState {
  translations?: TranslationResource[];
  translation?: TranslationResource;
  paged?: Paged;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  model?:TranslationModel;
}

interface TranslationState {
  translations: TranslationResource[];
  translation: TranslationResource;
  pagination: Pagination;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  model:TranslationModel;
}

const reducer = (state: TranslationState, action: TranslationAction): TranslationReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSLATIONS: {
      const { translations, paged, isEmptyList, isLoading, isAuthorize, model } = action.payload;
      return {
        ...state,
        translations,
        paged,
        isLoading,
        isEmptyList,
        isAuthorize,
        model
      };
    }
    case actionTypes.FETCH_TRANSLATION: {
      const { translation } = action.payload;
      return {
        ...state,
        translation
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;


