import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  AccessModel,
  root,
  _tribunal,
  fetchTribunalCodesNotAssigned,
  TribunalCodesNotAssignedModel
} from 'src/common';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import Results from './Results';

const initialState = {
  tribunalCodesNotAssigned: [],
  isLoading: false,
  isEmptyList: true
};

const TribunalCodesNotAssignedListView: FC = () => {
  const model = TribunalCodesNotAssignedModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_tribunal, initialState);
  
  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname } = location;
        model.PathName = pathname + search;
        const tribunalCodesNotAssigned = await model.getTribunalCodesNotAssigned();
        if (!model.error) {
          dispatch(fetchTribunalCodesNotAssigned(tribunalCodesNotAssigned, true, !Array.isArray(tribunalCodesNotAssigned) || tribunalCodesNotAssigned.length === 0));
        }
      }
    })();
  },[]);

  const { tribunalCodesNotAssigned, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            tribunalCodesNotAssigned={tribunalCodesNotAssigned}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default TribunalCodesNotAssignedListView;
