import React, { FC } from 'react';
import { Grid } from '@mui/material';
import {
  ListType,
  DATABANK_TITLE,
  COLLECTION_TITLE,
  PRODUCT_TITLE,
  CaijCard,
  AccessPermissions,
} from 'src/common';
import type { 
  DatabankResource,
  SubscriptionResource
} from 'src/common/types';
import AccessList from 'src/components/listInfo';
import AccessProductList from 'src/components/requiredParameter/listInfo';
import MyCard from '../card/MyCard';
import { useAccesses } from 'src/functions/hooks/access';

interface TypeAuthorize {
  product?: boolean;
  databank?: boolean;
  collection?:boolean;
};

interface AccessTypeListProps {
  authorize: TypeAuthorize;
  databanks: DatabankResource[];
  lists: SubscriptionResource;
}

const AccessTypeList : FC<AccessTypeListProps> = ({ 
  authorize,
  databanks,
  lists 
}) => {
  const { sproduct, scollection } = useAccesses();
  const margin = {m: 2};
  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <MyCard>
          <CaijCard title={PRODUCT_TITLE} sx={margin}>
            { sproduct.isLoading && (
                <AccessProductList
                  isAuthorize={sproduct.isAuthorize}
                  listType={ListType.Product}
                  data={sproduct.products?.filter(({access}) => access === AccessPermissions.Private)}
                  lists={lists.products}
                />
              )
            }
          </CaijCard>
          <CaijCard title={DATABANK_TITLE} sx={margin}>
            <AccessList
              isAuthorize={authorize.databank}
              listType={ListType.Databank}
              data={databanks}
              lists={lists.databanks}
            />
          </CaijCard>
          <CaijCard title={COLLECTION_TITLE} sx={margin}>
            { scollection.isLoading && (
                <AccessList
                  isAuthorize={scollection.isAuthorize}
                  listType={ListType.Collection}
                  data={scollection.docCollections?.filter(({access}) => access === AccessPermissions.Private)}
                  lists={lists.documentCollections}
                />
              )
            }
          </CaijCard>
        </MyCard>
      </Grid>
    </Grid>
  );
};

export default AccessTypeList;

