import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Link,
  Table,
  TableBody
} from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import { 
  tableRow, 
  labelConfig, 
  _libraryType, 
  LibraryModel,
  link
} from 'src/common';
import { tableCell } from 'src/styles/library';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow, CaijTableRowActive } from 'src/components/table/CaijTableRow';

export interface OthersInfoProps {
  model: LibraryModel;
  library: LibraryResource;
}

const OthersInfo: FC<OthersInfoProps> = ({ model, library }) => {
  const { CloakroomInfo, ByodPrinterUrl } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Autres Informations"  />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableCell={tableCell} label={labelConfig.libraryType}>
            {library.libraryType?.nameFr}
          </CaijTableRow>
          <CaijTableRowActive 
            sxTableRow={tableRow} 
            sxTableCell={tableCell} 
            label={labelConfig.cloakroom} 
            active={library.cloakroom}
            activeTxt={labelConfig.cloakroom}
            inactiveTxt={labelConfig.noCloakroom}
          />
          <CaijTableRow sxTableCell={tableCell} label={CloakroomInfo.Label}>
            {library.cloakroomInfo}
          </CaijTableRow>
          <CaijTableRow sxTableCell={tableCell} label={ByodPrinterUrl.Label}>
            { library.byodPrinterUrl && (
                <Link
                  underline="none"
                  href={library.byodPrinterUrl}
                  sx={{ ...link, color: 'text.secondary' }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {library.byodPrinterUrl}
                </Link>
              )
            }
          </CaijTableRow>
          <CaijTableRowActive 
            sxTableRow={tableRow} 
            sxTableCell={tableCell} 
            label={labelConfig.status} 
            active={library.enabled}
          />
        </TableBody>
      </Table>
    </MyCard>
  );
};

OthersInfo.propTypes = {
  library: PropTypes.object.isRequired
};

export default OthersInfo;
