import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Select, FormControl, InputLabel, FormHelperText, SelectChangeEvent } from '@mui/material';
import { InputProps2, Variant } from 'src/types/input';

interface CaijSelectProps {
  value?: string | boolean | number;
  label?: string;
  labelId?: string;
  id?: string
  error?: any;
  name?: string;
  native?: boolean;
  disabled?: boolean;
  sx?: any;
  helperText?: any;
  className?: string;
  children: ReactNode;
  inputProps?: InputProps2;
  variant?: Variant;
  required?: boolean;
  renderValue?: (value: string | number | boolean) => React.ReactNode;
  onHandleBlur?: (e: any) => void;
  onHandleChangeSelect?: (event: SelectChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijSelect: FC<CaijSelectProps> = ({
  required,
  variant,
  sx,
  error,
  children,
  labelId,
  label,
  value,
  onHandleChangeSelect,
  onHandleBlur,
  setFieldValue,
  helperText,
  native = true,
  ...rest
}) => {
  const [labelWidth, setLabelWidth] = React.useState(0);
  const inputLabel = React.useRef(null);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);
  return (
    <>
      <FormControl
        required={required}
        variant={variant}
        sx={sx}
        error={error}
      >
        <InputLabel id={labelId} shrink ref={inputLabel}>{label}</InputLabel>
        <Select
          {...rest}
          autoWidth={true}
          native={native}
          labelId={labelId}
          value={value}
          onChange={(event) => onHandleChangeSelect(event, setFieldValue)}
          onBlur={onHandleBlur}
          label={label}
        >
          {children}
        </Select>
        <FormHelperText sx={{ color: 'text.error' }}>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
}

CaijSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  labelId: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.any,
  sx: PropTypes.any,
  children: PropTypes.node,
  inputProps: PropTypes.object,
  variant: PropTypes.oneOf(['outlined', 'filled']),
  onHandleBlur: PropTypes.func,
  onHandleChangeSelect: PropTypes.func,
  setFieldValue: PropTypes.func
};

CaijSelect.defaultProps = {
  name: '',
  labelId: '',
  disabled: false,
  required: false,
  onHandleBlur: () => { },
  onHandleChangeSelect: () => { },
  setFieldValue: () => { },
  helperText: '',
  variant: "outlined",
  id: ''
}

export default CaijSelect;
