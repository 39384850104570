import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { labelConfig } from 'src/common';
import type { 
  WfLibraryDto, 
  WfProfileDto
} from 'src/common/types';
import { useEnabledSymLibraries, useEnabledSymProfiles } from 'src/functions/hooks/symphony';

interface SymphonyProps {
  wfLibrary?: string;
  wfProfile?: string;
  onHandleChange: (wfLibrary:string,wfProfile:string) => void;
}

interface Symphony {
  wfLibrary: string;
  wfProfile: string;
};

const Symphony: FC<SymphonyProps> = ({
  wfLibrary,
  wfProfile,
  onHandleChange
}) => {
  const [state, setState] = useState<Symphony>({wfLibrary,wfProfile});
  const enabledSymProfile = useEnabledSymProfiles();
  const enabledSymLibrary = useEnabledSymLibraries();
  
  useEffect(() => {
    setState({...state, wfLibrary, wfProfile});
  },[wfLibrary,wfProfile])
 
  const renderEnabledSymLibraries = (): JSX.Element => {
    const { isLoading, isEmptyList, libraries } = enabledSymLibrary;
    return (
      <TextField
        label={labelConfig.wfLibrary}
        id='wfLibrary'
        name='wfLibrary'
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const wfLibrary = e.target.value;
          onHandleChange(wfLibrary,state.wfProfile);
          setState({...state, wfLibrary});
        }}
        select
        SelectProps={{ native: true }}
        value={state.wfLibrary || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        inputProps={{ 'aria-label': 'wfLibrary', 'data-testid': 'wfLibrary' }}
        sx={{ mt: 2, mb: 3, width: '100%' }}
      >
        <option value=''>Sélectionner une bibliothèque symphony</option>
        {(isLoading && !isEmptyList) && libraries.map((library: WfLibraryDto) => (
          <option value={library.name} key={library.name}>
            {library.name}
          </option>
        ))}
      </TextField>
    )
  }

  const renderEnabledSymProfiles = (): JSX.Element => {
    const { isLoading, isEmptyList, profiles } = enabledSymProfile;
    return (
      <TextField
          label={labelConfig.wfProfile}
          id='wfProfile'
          name='wfProfile'
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const wfProfile = e.target.value;
            onHandleChange(state.wfLibrary, wfProfile);
            setState({...state, wfProfile});
          }}
          select
          SelectProps={{ native: true }}
          value={state.wfProfile || ''}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          inputProps={{ 'aria-label': 'wfProfile', 'data-testid': 'wfProfile' }}
          sx={{ mt: 2, mb: 3, width: '100%' }}
        >
          <option value=''>Sélectionner un profil symphony</option>
          {(isLoading && !isEmptyList) && profiles.map((profile: WfProfileDto) => (
            <option value={profile.name} key={profile.name}>
              {profile.name}
            </option>
          ))}
      </TextField>
    )
  }

  return (
    <>
    { renderEnabledSymLibraries() }
    { renderEnabledSymProfiles() }
    </>
  );
};

Symphony.propTypes = {
  wfLibrary: PropTypes.string,
  wfProfile: PropTypes.string,
  onHandleChange: PropTypes.func.isRequired,
};

Symphony.defaultProps = {
  wfLibrary: '',
  wfProfile: ''
}

export default Symphony;
