import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type {
  SubscriptionResource, 
  AccountExpiryType, 
  AccountRenewalExpiryOptions, 
  SubscriptionGroupResource
} from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableRow,
  Link
} from '@mui/material';
import { 
  AccExpRenewMode, 
  AccountExpiryMode, 
  ACCOUNT_TITLE,
  tableRow,
  SubscriptionModel,
  SbGroup,
  pad,
  link,
  EmailTemplateModel
} from 'src/common';
import { tableCell } from 'src/styles/subscription';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow, CaijTableRowWarning } from 'src/components/table/CaijTableRow';

export interface AccountInfoProps {
  model: SubscriptionModel;
  subscription: SubscriptionResource;
  groupList: SubscriptionGroupResource[];
}

function renderContent(model: SubscriptionModel, accountExpiryMode: AccountExpiryType, accountExpiry: AccountRenewalExpiryOptions) : JSX.Element {
  if (accountExpiryMode === SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.ExpireOnDate)) {
    return (
      <CaijTableRow sxTableCell={tableCell} label="Date d'expiration" >{`${pad(accountExpiry.expireOn.day)}-${pad(accountExpiry.expireOn.month)}`}</CaijTableRow>
    );
  } else if (accountExpiryMode === SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.ExpireAfterDays)) {
    return (
      <CaijTableRow sxTableCell={tableCell} label={model.getAccountDurationDays('accountExpiry.expireInDays').Label} >{accountExpiry.expireInDays} jour(s)</CaijTableRow>
    );
  }
}

const neverExpire = SubscriptionModel.getAccountExpiryModeByVal(AccountExpiryMode.NeverExpire);

const AccountInfo: FC<AccountInfoProps> = ({
  model,
  subscription,
  groupList
}) => {
  const { group, subscriptionGroup:{accountExpiryMode, accountExpiry, accountRenewMode, formUrl}, sendLcapNotif} = subscription;
  const [emailTemplate, setEmailTemplate] = useState<string>('');
  
  useEffect(() => {
    let isCurrent = true;
    const getEmailTemplate = async () : Promise<void> => {
      const emailTemplateModel = new EmailTemplateModel();
      const { subscriptionGroup } = subscription;
      const group = groupList.find(({isDefault}) => isDefault);
      if(group){
        const emailTempateChanged = group.emailTemplateId != subscriptionGroup?.emailTemplateId;
        if(emailTempateChanged)
          subscriptionGroup.emailTemplateId = group.emailTemplateId;
      }
      if(subscriptionGroup?.emailTemplateId){
        const emailTemplate = await emailTemplateModel.getEmailTemplateById(subscriptionGroup?.emailTemplateId?.toString())
        if(!model.error && isCurrent) {
          setEmailTemplate(emailTemplate?.name);
        }
      }
    }
    (async () => await getEmailTemplate())();
    return () => {isCurrent = false;}
  },[])

  return (
    <MyCard>
      <CardHeaderDetail title={ACCOUNT_TITLE} />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableCell={tableCell} label={model.getAccountExpiryMode('accountExpiryMode').Label}>{AccountExpiryMode[accountExpiryMode]}</CaijTableRow>
          { renderContent(model, accountExpiryMode, accountExpiry) }
          {
            accountExpiryMode !== neverExpire && (
              <CaijTableRow sxTableCell={tableCell} label={model.getAccountRenewMode('accountRenewMode').Label}>{AccExpRenewMode[accountRenewMode]}</CaijTableRow>
            )
          }
          {
            accountExpiryMode !== neverExpire && accountRenewMode !== SubscriptionModel.getAccountRenewModeByVal(AccExpRenewMode.SelfRenewal) && (
              <CaijTableRow sxTableCell={tableCell} label={model.getEmailTemplateId('emailTemplateId').Label}>{emailTemplate}</CaijTableRow>
            )
          }
          { accountExpiryMode !== neverExpire && accountRenewMode == SubscriptionModel.getAccountRenewModeByVal(AccExpRenewMode.OnApproval) && (
              <CaijTableRow sxTableCell={tableCell} label={model.getFormUrl('formUrl').Label}>
                { formUrl && (
                    <Link
                      underline="none"
                      href={formUrl}
                      sx={{ ...link, color: 'text.secondary' }}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {formUrl}
                  </Link>
                )
                }
              </CaijTableRow>
            )
          }
          { group === SubscriptionModel.getSbGroupByVal(SbGroup.B2B) ? (
              <CaijTableRowWarning warning={sendLcapNotif} label={model?.SendLcapNotif.Label} sxTableRow={tableRow} sxTableCell={tableCell} />
            ) : (<TableRow sx={tableRow} />)
          }
        </TableBody>
      </Table>
    </MyCard>
  );
};

AccountInfo.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default AccountInfo;

