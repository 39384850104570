import {Box,CardContent,Grid} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import {
  Formik,
  CaijButtonSubmit,
  CaijButtonReset,
  Authorize,
  LibraryModel,
  Mode,
  ListType,
  btnSubmit
} from 'src/common';
import type { LibraryResource, LibraryResourceForEdit } from 'src/common/types';
import printMessage from 'src/views/errors/MessageError';
import RequireParameterList from 'src/components/requiredParameter';
import MyCard from 'src/components/card/MyCard';
import { useLibraryServices } from 'src/components/requiredParameter/hook';

interface LibraryLibraryServiceFormProps {
  model: LibraryModel;
  library: LibraryResource;
  onHandleRefreshLibrary: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const LibraryLibraryServiceForm: FC<LibraryLibraryServiceFormProps> = ({
  model,
  library,
  onHandleRefreshLibrary,
  onSwitchMode
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedLibraryLibraryServices, setSelectedLibraryLibraryServices] = useState<{ data: number[], selected: boolean }>({
    data: [],
    selected: false
  });
  const [selectedLibraryLibraryServiceParameters, setSelectedLibraryLibraryServiceParameters] = useState<Map<number, string>>();
  const { libraryServices } = useLibraryServices();
  
  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        const model = new LibraryModel();
        const submitData = library as LibraryResourceForEdit;
        const libraryId = submitData.id;
        if (submitData && libraryId) {
          const imageUrl = model.getSubmitDataImageUrl(submitData.imgUrlFr, submitData.imgUrlEn);
          submitData.imgUrlFr = imageUrl.Fr;
          submitData.imgUrlEn = imageUrl.En;
          model.SelectedLibraryLibraryServiceParameters = selectedLibraryLibraryServiceParameters;
          model.saveListLibraryService(selectedLibraryLibraryServices, submitData, libraryServices);
          const response = await model.updateLibrary(submitData);
          if (!model.error) {
            printMessage({
              status: response.status,
              message: response.message
            });
            await onHandleRefreshLibrary();
          }
        }
      }}
    >
      {({
        handleSubmit, isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2} >
                <Grid item md={12} lg={12} xs={12} >
                  <RequireParameterList
                    listType={ListType.LibraryService}
                    lists={library.libraryServices}
                    onHandleItemsSelected={l =>
                      setSelectedLibraryLibraryServices({
                        ...selectedLibraryLibraryServices,
                        data: l,
                        selected: true,
                      })}
                    onHandleRequireParmeters={l => setSelectedLibraryLibraryServiceParameters(l)}
                  />
                </Grid>
              </Grid>
              <Box>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

LibraryLibraryServiceForm.propTypes = {
  library: PropTypes.object.isRequired,
  onHandleRefreshLibrary: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired
};

export default LibraryLibraryServiceForm;
