import { FC, useState, useEffect, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { CaijInputPhone, contactText, ErrorType, LibraryModel } from 'src/common';
import { Contact, LibraryResource } from 'src/common/types';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Typography } from '@mui/material';

interface LibraryContactFormProps {
  model: LibraryModel;
  library: LibraryResource;
  handleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
}

const error = {
  fontSize: '12px',
  marginLeft: 15,
  marginTop: 3
};

const LibraryContactForm: FC<LibraryContactFormProps> = ({ model, library, handleBlur }) => {
  const isMountedRef = useIsMountedRef();
  const [contact, setContact] = useState<Contact>(null);
  const [contactIsValid, setContactIsValid] = useState<string[]>([]);
  const { contactUs } = library;
  const { ContactTelephone, ContactFax, ContactTollfree } = model;

  useEffect(() => {
    const initialise = () => {
      if(isMountedRef.current && contactUs) {
        const { telephone , fax, tollfree } = LibraryModel.convertContactStringToJson(contactUs);
        setContact({telephone,fax,tollfree});
        isMountedRef.current = false;
      }
    }
    initialise();
  },[isMountedRef, contactUs, setContact]);
  
  return (
    <>
      <CaijInputPhone
        name={ContactTelephone.Name}
        id={ContactTelephone.Name}
        value={contact ? contact.telephone : ''}
        label={ContactTelephone.Label}
        InputLabelProps={{ shrink: true }}
        inputProps={{ maxLength: ContactTelephone.MaxLength }}
        onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
          if(contact){
            if(!model.verifyContactTelephone(contact)){
              setContactIsValid([...contactIsValid, contactText.telephone]);
            }else{
              setContactIsValid(contactIsValid.filter((value: string) => value !== contactText.telephone));
            }
          }
          library.contactUs = LibraryModel.convertContactJsonToString(contact);
          handleBlur(e);
        }}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setContact({...contact, [e.target.name]: e.target.value})}
      />
       {contactIsValid.includes(contactText.telephone) ? <Typography sx={{...error, color: 'text.error'}}>{LibraryModel.formatError(ErrorType.max, ContactTelephone.Label, ContactTelephone.MaxLength)}</Typography> : ''}
      <CaijInputPhone
        name={ContactFax.Name}
        value={contact ? contact.fax : ''}
        label={ContactFax.Label}
        InputLabelProps={{ shrink: true }}
        inputProps={{ maxLength: ContactFax.MaxLength }}
        onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
          if(contact){
            if(!model.verifyContactFax(contact)){
              setContactIsValid([...contactIsValid, contactText.fax]);
            }else{
              setContactIsValid(contactIsValid.filter((value: string) => value !== contactText.fax));
            }
          }
          library.contactUs = LibraryModel.convertContactJsonToString(contact);
          handleBlur(e);
        }}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setContact({...contact, [e.target.name]: e.target.value})}
      />
      {contactIsValid.includes(contactText.fax) ? <Typography sx={{...error, color:'text.error'}}>{LibraryModel.formatError(ErrorType.max, ContactFax.Label, ContactFax.MaxLength)}</Typography> : ''}
      <CaijInputPhone
        name={ContactTollfree.Name}
        value={contact ? contact.tollfree : ''}
        label={ContactTollfree.Label}
        InputLabelProps={{ shrink: true }}
        inputProps={{ maxLength: ContactTollfree.MaxLength}}
        onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
          if(contact){
            if(!model.verifyContactTelephone(contact)){
              setContactIsValid([...contactIsValid, contactText.tollfree]);
            }else{
              setContactIsValid(contactIsValid.filter((value: string) => value !== contactText.tollfree));
            }
          }
          library.contactUs = LibraryModel.convertContactJsonToString(contact);
          handleBlur(e)
        }}
        onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setContact({...contact, [e.target.name]: e.target.value})}
      />
       {contactIsValid.includes(contactText.tollfree) ? <Typography sx={{...error, color: 'text.error'}}>{LibraryModel.formatError(ErrorType.max, ContactTollfree.Label, ContactTollfree.MaxLength)}</Typography> : ''}
    </>
  );
};

LibraryContactForm.propTypes = {
  library: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired
};

export default LibraryContactForm;


