import React, { FC } from 'react';
import { Grid } from '@mui/material';
import {
  ListType,
  DATABANK_TITLE,
  COLLECTION_TITLE,
  PRODUCT_TITLE,
  CaijCard,
  AccessModel,
  ProductModel,
  DocCollectionModel,
  DatabankModel
} from 'src/common';
import type { 
  SubscriptionGroupResource,
  SubscriptionResource
} from 'src/common/types';
import AccessGroupList from 'src/components/listInfo';
import AccessProductGroupList from 'src/components/requiredParameter/listInfo';
import PropTypes from 'prop-types';

interface SubscriptionAccessGroupProps {
  subscription: SubscriptionResource;
  group?: SubscriptionGroupResource;
}

const SubscriptionAccessGroup: FC<SubscriptionAccessGroupProps> = ({ 
  subscription,
  group
}) => {
  const accesses: AccessModel[] = [];
  const resourceCodes = [
    ProductModel.getInstance().ResourceCode,
    DatabankModel.getInstance().ResourceCode,
    DocCollectionModel.getInstance().ResourceCode
  ];
  resourceCodes.forEach((resourceCode) => accesses.push(new AccessModel(resourceCode)));
  return (
    <Grid item md={12} xs={12} lg={12}>
      <CaijCard title={PRODUCT_TITLE}>
        <AccessProductGroupList
          isAuthorize={accesses[0].Consultation}
          listType={ListType.CustomerProduct}
          data={subscription?.products}
          lists={group ? group.products : subscription?.products}
        />
      </CaijCard>
      <CaijCard title={DATABANK_TITLE}>
        <AccessGroupList
          isAuthorize={accesses[1].Consultation}
          listType={ListType.CustomerDatabank}
          data={subscription?.databanks}
          lists={group ? group.databanks : subscription?.databanks}
        />
      </CaijCard>
      <CaijCard title={COLLECTION_TITLE}>
        <AccessGroupList
          isAuthorize={accesses[2].Consultation}
          listType={ListType.CustomerCollection}
          data={subscription?.documentCollections}
          lists={group ? group.documentCollections : subscription?.documentCollections}
        />
      </CaijCard>
    </Grid>
  )
};

SubscriptionAccessGroup.propTypes = {
  subscription: PropTypes.object.isRequired,
  group: PropTypes.object,
};

export default SubscriptionAccessGroup;

