import type { DoorCardProfileDto } from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchDoorCardProfiles = (
  doorCardProfiles: DoorCardProfileDto[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean
): {
  type: 'FETCH_DOOR_CARD_PROFILES';
  payload: { doorCardProfiles: DoorCardProfileDto[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean };
} => ({
  type: actionTypes.FETCH_DOOR_CARD_PROFILES,
  payload: {
    doorCardProfiles,
    isLoading,
    isEmptyList,
    isAuthorize
  },
});
