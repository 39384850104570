import { CoveoTaskHistoryDto } from 'src/common/types';
import * as actionTypes from '../../../actions/ActionTypes';
import { Paged } from 'src/types/pagination';

type FetchCoveoTaskHistoriesAction = {
  type: 'FETCH_COVEO_TASK_HISTORIES';
  payload: {
    coveoTaskHistories: CoveoTaskHistoryDto[];
    paged?: Paged;
    isLoading?: boolean;
    isEmptyList?: boolean;
  }
};

type Action = FetchCoveoTaskHistoriesAction;

interface CoveoTaskHistoryState {
  coveoTaskHistories: CoveoTaskHistoryDto[];
  paged: Paged;
  isLoading: boolean;
  isEmptyList: boolean;
}

const reducer = (state: CoveoTaskHistoryState, action: Action) => {
  switch (action.type) {
    case actionTypes.FETCH_COVEO_TASK_HISTORIES: {
      const { coveoTaskHistories, paged, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        coveoTaskHistories,
        paged,
        isLoading,
        isEmptyList
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
