import React from 'react';
import type { FC, ChangeEvent } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import type { CustomerNoteDto } from 'src/common/types';
import { 
  Button, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  SvgIcon 
} from '@mui/material';
import { 
  AccessModel,
  CaijButtonSubmit, 
  CaijSwitch, 
  CaijTextarea, 
  CustomerModel,  
  dialog, 
  dialogTitle, 
  useFormik 
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import { XSquare as CancelIcon } from 'react-feather';

export interface NoteEditFormProps {
  access: AccessModel;
  model: CustomerModel;
  custId: number;
  note: CustomerNoteDto;
  reloadNotes: () => Promise<void>;
  setOpen: (value: boolean) => void;
  onSubmit?: (value: CustomerNoteDto) => void;
}

const validationSchema = (model: CustomerModel) => {
  return Yup.object({
    note: model.Note.required()
  });
};

const NoteEditForm: FC<NoteEditFormProps> = ({
  model,
  custId,
  note,
  onSubmit,
  reloadNotes,
  setOpen,
  access
}) => {
  const { errors, setFieldValue, handleSubmit, handleChange, values, touched, isSubmitting } = useFormik({
    initialValues: {...note},
    validationSchema: validationSchema(model),
    validateOnChange: true,
    onSubmit: async values => {
      if(onSubmit){
        onSubmit(values);
        return;
      }
      const model = new CustomerModel();
      const response = note.id ? await model.updateCustomerNote(custId, values as CustomerNoteDto) : await model.insertCustomerNote(custId,{...values});
      if(!model.error){
        printMessage({...response});
        setOpen(false);
        await reloadNotes();
      }
    },
  });
 
  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle sx={dialogTitle}>{note?.id ? 'Éditer une note' : 'Créer une note'}</DialogTitle>
      <DialogContent sx={{...dialog, paddingBottom: '5px', width: 'auto'}} dividers >
        <CaijSwitch
          name={model.Pin.Name}
          label={model.Pin.Label}
          checked={values.pin}
          value={values.pin}
          inputProps={{'data-testid': model.Pin.Name}}
          onHandleChangeSwitch={(event:ChangeEvent<HTMLInputElement>) => handleChange(event)}
          sx={{mb:1}}
        />
        <CaijTextarea
          name={model.Note.Name}
          rows="4"
          value={values.note}
          label={model.Note.Label}
          InputLabelProps={{ shrink: true, required: true }}
          error={Boolean(touched.note && errors.note)}
          helperText={touched.note && errors.note}
          inputProps={{'data-testid': model.Note.Name}}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </DialogContent>
      <DialogActions sx={dialog}>
        <CaijButtonSubmit disabled={isSubmitting || (access && !access.Consultation)} value="Sauvegarde" />
        <Button  
          variant="outlined"
          type="reset"
          onClick={() => setOpen(false)}
          disabled={isSubmitting}
          startIcon={(
            <SvgIcon fontSize="small">
              <CancelIcon />
            </SvgIcon>
        )}>Annuler</Button>
      </DialogActions>
    </form>
  );
};

NoteEditForm.propTypes = {
  custId: PropTypes.number.isRequired,
  reloadNotes: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default NoteEditForm;
