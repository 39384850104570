import { ProductModel } from 'src/common';
import type { ProductResource } from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchProducts = (
  products: ProductResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
  model?: ProductModel
): {
  type: 'FETCH_PRODUCTS';
  payload: {
    products: ProductResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
    model?: ProductModel;
  };
} => ({
  type: actionTypes.FETCH_PRODUCTS,
  payload: {
    products,
    isLoading,
    isEmptyList,
    isAuthorize,
    model
  },
});

export const fetchProduct = (product: ProductResource, isLoading?: boolean) : {
  type: 'FETCH_PRODUCT';
  payload: {
    product: ProductResource,
    isLoading?: boolean
  };
} => ({
  type: actionTypes.FETCH_PRODUCT,
  payload: {
    product,
    isLoading
  },
});

export const deleteProduct = (id: number) : {
  type: 'DELETE_PRODUCT';
  payload: {
    id: number;
  };
} => ({
  type: actionTypes.DELETE_PRODUCT,
  payload: {
   id
  },
});

