import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';
import { 
  CaijButtonDelete,
  CaijCard, 
  FORMAT_TIME_PICKER,
  tableRow,
  CaijTimePicker
} from 'src/common';
import LibraryOpenHoursModel from 'src/model/library/LibraryOpenHours';
import { WorkingHour } from 'src/common/types';
import dayjs from 'dayjs';

interface LibraryOpenHoursProps {
  model: LibraryOpenHoursModel;
  openHours?: string;
  onHandleOpenHoursChange: (openHours: WorkingHour[]) => void;
}

const initialOpenHours: WorkingHour[] = [
  { day: 0, hours: [{start:null, end:null}] },
  { day: 1, hours: [{start:null, end:null}] },
  { day: 2, hours: [{start:null, end:null}] },
  { day: 3, hours: [{start:null, end:null}] },
  { day: 4, hours: [{start:null, end:null}] },
  { day: 5, hours: [{start:null, end:null}] },
  { day: 6, hours: [{start:null, end:null}] },
];

const paddingRight = '10px';
const marginBottom = {xs:'10px',sm:'10px', md:0, lg:0};

function Row(props: { day: number, dayName: string, model: LibraryOpenHoursModel, setOpenHour: (day: number, start: string, end: string) => void}) {
  const { day, dayName, model, setOpenHour } = props;
  const start = model.getStartHour(day);
  const end = model.getEndHour(day);

  const handleTimeStartChange = (time: string) => {
    if(time){
      model.setOpenHours(day, {day:day, hours:[{start:time,end}]});
      setOpenHour(day, time, model.getEndHour(day));
    }else{
      setOpenHour(day,null, model.getEndHour(day));
    }
  };

  const handleTimeEndChange = (time: string) => {
    if(time){
      model.setOpenHours(day, {day, hours:[{start,end:time}]})
      setOpenHour(day,model.getStartHour(day), time);
    }else{
      setOpenHour(day,model.getStartHour(day), null);
    }
  };

  const resetHour = () => {
    model.setOpenHours(day, {day, hours: [{start:null,end:null}]})
    setOpenHour(day, null, null);
  }

  return (
    <ListItem sx={tableRow}>
      <ListItemText>
        <Box display={{xs:"column",sm:"column",md:"flex",lg:"flex"}} alignItems='center' justifyContent="space-between">
          <Box sx={{minWidth: '80px', marginBottom}}>{dayName}</Box>
          <Box sx={{paddingRight, marginBottom}}>
            <CaijTimePicker
              name={`start${day}`}
              inputProps={{'data-testid': `start${day}`}}
              value={dayjs(start, FORMAT_TIME_PICKER).toString()} 
              onHandleTimeChange={handleTimeStartChange}
            />
          </Box>
          <Box sx={{paddingRight, marginBottom}}>
            <CaijTimePicker 
              name={`end${day}`}
              inputProps={{'data-testid': `end${day}`}}
              value={dayjs(end, FORMAT_TIME_PICKER).toString()}
              onHandleTimeChange={handleTimeEndChange}
            />
          </Box>
          <Box>
            <CaijButtonDelete 
               disabled={(!model.getStartHour(day) && !model.getEndHour(day))} 
               onDelete={resetHour}
            >SUPPRIMER</CaijButtonDelete>
          </Box>
        </Box>
      </ListItemText>
    </ListItem>
   )
};

const LibraryOpenHoursForm: FC<LibraryOpenHoursProps> = ({
  model,
  openHours,
  onHandleOpenHoursChange
}) => {
  const [selectedOpenHours, setSelectedOpenHours] = useState<WorkingHour[]>(initialOpenHours);

  const setOpenHour = (day: number, start: string, end: string) => {
    const newSelectedOpenHours = [...selectedOpenHours];
    const newOpenHours = newSelectedOpenHours.map((newSelectedOpenHour: WorkingHour) => {
      if (newSelectedOpenHour.day === day) {
        newSelectedOpenHour.hours = [{ start, end }];
      }
      return newSelectedOpenHour;
    });
    setSelectedOpenHours(newOpenHours);
  };

  useEffect(() => {
    const openHourFromBD = model.convertStringToJson(openHours);
    model.initialiseOpenHour(openHourFromBD,setOpenHour);
  },[]);

  useEffect(() => {
    onHandleOpenHoursChange(selectedOpenHours);
  },[selectedOpenHours]);

  return (
    <>
      <CaijCard title={'Heures d\'ouverture'}>
          <List>
                { model.Days.map((value: string, index: number) => 
                    <Row 
                      key={index} 
                      day={index} 
                      dayName={value} 
                      model={model} 
                      setOpenHour={setOpenHour}
                    />
                  )
                }
          </List>
      </CaijCard>
    </>
  );
};

LibraryOpenHoursForm.propTypes = {
  openHours: PropTypes.string,
  onHandleOpenHoursChange: PropTypes.func.isRequired
};

export default LibraryOpenHoursForm;
