import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  Link
} from '@mui/material';
import {
  EmptyList,
  CaijButton,
  Label,
  labelConfig,
  tableRow,
  link,
  formatDate,
  AccessModel,
  getCustomerStatusLabelColor,
  CustomerModel,
} from 'src/common';
import { EndorsedCustomerResource } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';

export interface ResultsProps {
  model: CustomerModel;
  limitEndorsed?: number;
  totalEndorsed?: number;
  customersEndorsed: EndorsedCustomerResource[];
  onEditCustomerEndorsed: (isEdit: boolean, customerEndorsedId?: number) => void;
}

const getHeadCells = () => { 
  return [
    {
      width:'50%', label: labelConfig.nameFr
    },
    {
      width:'20%', label: labelConfig.customerStatus
    },
    {
      width: '20%', label: 'Fin du cautionnement'
    },
  ];
};

const Results: FC<ResultsProps> = ({
  model,
  limitEndorsed,
  totalEndorsed,
  customersEndorsed,
  onEditCustomerEndorsed
}) => {
  const path = model.Path;
  const access = new AccessModel(model.ResourceCode);
 
  return (
    <MyCard>
      <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
        <Box>
          <Typography variant="body2" sx={{color:'text.secondary'}}>Nombre de cautionné</Typography>
          <Typography variant="h6" sx={{color:'text.secondary'}}>{`${totalEndorsed} / ${limitEndorsed}`}</Typography>
        </Box>
        <Box display='flex' alignItems='right' justifyContent='space-between'>
          <CaijButton
            type='add'
            color='secondary'
            variant='contained'
            disabled={access.Disabled || totalEndorsed >= limitEndorsed}
            onHandleClick={() => onEditCustomerEndorsed(true)}
          >
            Ajouter un cautionné
          </CaijButton>
        </Box>
      </Box>
      { 
        (customersEndorsed && customersEndorsed.length === 0) ? <EmptyList /> : (
          <>
            <CaijTable>
              <TableHead>
                <TableRow>
                  {getHeadCells().map(({width, label}, index: number) => <TableCell key={index} width={width}>{label}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {customersEndorsed.map((customer : EndorsedCustomerResource)=> {
                  return (
                    <CaijTableRowClickable key={customer.id} sx={tableRow} target="_blank" path={path.getDetail(customer.id)}>
                      <CaijTableCell>
                        <Box display='flex' flexDirection="column" alignItems="flex-start">
                          <Link 
                            component="button"
                            underline="none" 
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(path.getDetail(customer.id), '_blank');
                            }}
                            sx={{...link,color:'text.secondary'}} 
                          >
                            {`${customer.firstname} ${customer.lastname}`}
                          </Link>
                          <Typography variant='body2' sx={{color:'text.secondary'}}>
                            {customer.email}
                          </Typography>
                          <Typography variant='body2' sx={{color:'text.secondary'}}>
                            {customer.noCaij}
                          </Typography>
                        </Box>
                      </CaijTableCell>
                      <CaijTableCell>
                        <Label color={getCustomerStatusLabelColor(customer.status)}>{CustomerModel.getStatusByKey(customer.status)}</Label>
                      </CaijTableCell>
                      <CaijTableCell>{formatDate(customer.expiration)}</CaijTableCell>
                    </CaijTableRowClickable>
                  );
                })}
              </TableBody>
              </CaijTable>
          </>
        )
      }
    </MyCard>
  );
};

Results.propTypes = {
  limitEndorsed: PropTypes.number,
  totalEndorsed: PropTypes.number,
  customersEndorsed: PropTypes.array.isRequired,
  onEditCustomerEndorsed: PropTypes.func.isRequired
};

export default Results;
