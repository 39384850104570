import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  ApiKeyModel,
  getOpacity
} from 'src/common';
import type { ApiKeyDto } from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { 
  CaijTableCell, 
  CaijTableCellActive 
} from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';

export interface ResultsProps {
  model: ApiKeyModel;
  apiKeys?: ApiKeyDto[];
  isEmptyList?: boolean;
  onDeleteApiKey: (id: string) => Promise<void>;
}

const Results: FC<ResultsProps> = ({
  model,
  apiKeys,
  isEmptyList,
  onDeleteApiKey
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const { ApiKey, Name, Description } = model;

  const renderTableCell = (row: ApiKeyDto) => {
    const opacity = getOpacity(row.active);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.apiKey}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{row.name}</CaijTableCell>
        <CaijTableCell opacity={opacity} whiteSpace='pre-wrap'>{row.description}</CaijTableCell>
        <CaijTableCellActive active={row.active} />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(row.id)}
          resourceCode={model.ResourceCode}
          handleToggle={() => ApiKeyModel.handleToggle(row.id, selectedRow, setSelectedRow)}
        >
          <CaijDialogs
            dialog={model.Dialog}
            isOpen={selectedRow.indexOf(row.id) !== -1}
            onSubmit={async () => {
              await onDeleteApiKey(row.id)
              setSelectedRow([]);
            }}
            setSelectedRow={setSelectedRow}
          />
        </CaijTableCellAction>
      </>
    )
  }

  const renderContent = (): JSX.Element => {
    if (isEmptyList) {
      return (
        <EmptyList />
      );
    }
    return (
      <MyCard>
        <CaijTable>
          <TableHead>
            <TableRow>
              <TableCell width="10%">{ApiKey.Label}</TableCell>
              <TableCell width="15%">{Name.Label}</TableCell>
              <TableCell width="60%">{Description.Label}</TableCell>
              <TableCell width="10%">{labelConfig.status}</TableCell>
              <TableCell width="5%"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {apiKeys.map((row: ApiKeyDto, index: number) => (
                  <TableRow key={row.id}>{renderTableCell(row)}</TableRow>
                )
              )
            }
          </TableBody>
        </CaijTable>
      </MyCard>
    );
  };

  return (
    <>
      { renderContent() }
    </>
  );
};

Results.propTypes = {
  apiKeys: PropTypes.array,
  isEmptyList: PropTypes.bool,
  onDeleteApiKey: PropTypes.func.isRequired
};

export default Results;
