import { DatabankAccessType, DatabankAppType, DatabankModel, LIBRARY_ACCESS, SubscriptionModel } from 'src/common';
import type {
  SubscriptionResource, 
  ProductResource, 
  DatabankResource, 
  DocumentCollectionResource,
  SubscriptionGroupResource,
  DatabankItemResource
} from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchSubscriptionsAction = {
    type: 'FETCH_SUBSCRIPTIONS';
    payload: {
      subscriptions: SubscriptionResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
      model?: SubscriptionModel;
    }
};

type FetchSubscriptionAction = {
  type: 'FETCH_SUBSCRIPTION';
  payload: {
    subscription: SubscriptionResource;
    isLoading?: boolean;
  };
};

type FetchDetailsSubscriptionAction = {
  type: 'FETCH_DETAILS_SUBSCRIPTION';
  payload: {
    subscription: SubscriptionResource;
    databanks: DatabankResource[];
  };
};

type FetchGroupListAction = {
  type: 'FETCH_GROUP_LIST';
  payload: {
    groupList: SubscriptionGroupResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  }
};

type FetchGroupAction = {
  type: 'FETCH_GROUP';
  payload: {
    group: SubscriptionGroupResource;
    isLoading: boolean;
    allowedInsert: boolean;
  };
};

type AddGroupAction = {
  type: 'ADD_GROUP';
  payload: {
    group: SubscriptionGroupResource;
  };
};

type UpdateGroupAction = {
  type: 'UPDATE_GROUP';
  payload: {
    groupId: number;
    group: SubscriptionGroupResource;
  };
};

export type Action = FetchSubscriptionsAction |
            FetchSubscriptionAction |
            FetchDetailsSubscriptionAction |
            FetchGroupListAction |
            FetchGroupAction |
            AddGroupAction |
            UpdateGroupAction;

export interface SubscriptionReturnState {
  subscriptions?: SubscriptionResource[];
  subscription?: SubscriptionResource;
  groupList?: SubscriptionGroupResource[];
  group?: SubscriptionGroupResource;
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
  accesses?: ProductResource[];
  databanks?: DatabankResource[];
  docCollections?: DocumentCollectionResource[];
  model?: SubscriptionModel;
  allowedInsert?: boolean;
}

interface SubscriptionState {
  subscriptions: SubscriptionResource[];
  subscription: SubscriptionResource;
  groupList: SubscriptionGroupResource[];
  group: SubscriptionGroupResource;
  isLoading: boolean;
  isEmptyList: boolean;
  isAuthorize: boolean;
  accesses: ProductResource[];
  databanks: DatabankResource[];
  docCollections: DocumentCollectionResource[];
  model: SubscriptionModel;
  allowedInsert: boolean;
}

const reducer = (state: SubscriptionState, action: Action): SubscriptionReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_SUBSCRIPTIONS: {
      const { subscriptions, isLoading, isEmptyList, isAuthorize, model } = action.payload;
      return {
        ...state,
        subscriptions,
        isLoading,
        isEmptyList,
        isAuthorize,
        model
      };
    }
    case actionTypes.FETCH_SUBSCRIPTION: {
      const { subscription, isLoading } = action.payload;
      return {
        ...state,
        subscription,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_SUBSCRIPTION: {
      const { subscription, databanks } = action.payload;
      if(subscription.products?.some(({code}) => code == LIBRARY_ACCESS)){
        const accessesLibrary = databanks?.filter(({enabled, appType}) => enabled && appType === DatabankAppType.Desktop)
        DatabankModel.getAvailableDatabankList(subscription, accessesLibrary);
      }
      return {
        ...state,
        subscription
      };
    }
    case actionTypes.FETCH_GROUP_LIST: {
      const { groupList, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        groupList,
        isLoading,
        isEmptyList
      };
    }
    case actionTypes.FETCH_GROUP: {
      const { group, isLoading, allowedInsert } = action.payload;
      return {
        ...state,
        group,
        isLoading,
        allowedInsert
      };
    }
    case actionTypes.ADD_GROUP: {
      const groupList = [...state.groupList, action.payload.group]
      return {
        ...state,
        isLoading: true,
        isEmptyList: !Array.isArray(groupList) || groupList.length === 0,
        groupList
      };
    }
    case actionTypes.UPDATE_GROUP: {
      const { groupId, group } = action.payload;
      return {
        ...state,
        groupList: [...state.groupList].map((grp: SubscriptionGroupResource) => grp.id === groupId ? grp = group : grp)
      };
    }
    /*case actionTypes.ADD_ACCESS_LIBRARY_DATABANK:
      const subscription = Object.assign({}, state.subscription);
      const allDatabanks = [...action.payload.databanks];
      const allowedAccessLibrary = subscription.products?.some(({code}) => code == LIBRARY_ACCESS);
      if(allowedAccessLibrary){
        //Access Desktop & Online
        subscription.databanks = [...subscription.databanks].map((databank: DatabankItemResource) => { 
          if(allDatabanks.findIndex(({id, databankAccesses}) => id == databank.id && databankAccesses.some(({accessType}) => accessType === DatabankModel.getAccessTypeByVal(DatabankAccessType.Library))) != -1)
            databank.appType = DatabankAppType.Unknown;
            return databank;
        })
        //Access Desktop
        const databanks = allDatabanks.filter(({appType}) => appType === DatabankAppType.Desktop);
        databanks.forEach(({id, nameFr, enabled, appType}) => subscription.databanks.push({id,nameFr,enabled, appType}));
      }
      return {
        ...state,
        subscription
      }*/
    default: {
      return state;
    }
  }
};

export default reducer;

