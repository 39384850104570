import type { CustomerHistoryResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';
import { Paged } from 'src/types/pagination';

type FetchCustomerHistoryListAction = {
  type: 'FETCH_CUSTOMER_HISTORY_LIST';
  payload: {
    customerHistory: CustomerHistoryResource[];
    paged?: Paged;
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
  };
};

export type HistoryAction = FetchCustomerHistoryListAction;

export interface HistoryReturnState {
  paged?: Paged;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  customerHistory?: CustomerHistoryResource[];
}

interface HistoryState {
  paged: Paged;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  customerHistory: CustomerHistoryResource[];
}

export default (state: HistoryState, action: HistoryAction): HistoryReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_HISTORY_LIST:{
      const { customerHistory, isEmptyList, isLoading, isAuthorize, paged } = action.payload;
      return {
        ...state,
        customerHistory,
        isEmptyList,
        isLoading,
        isAuthorize,
        paged
      };
    }
    default: {
      return state;
    }
  }
};


