import { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import { 
  useLocation, 
  fetchDatabanks, 
  _databank, 
  DatabankModel, 
  AccessModel,
  deleteDatabank,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  databanks: [],
  isLoading: false,
  isEmptyList: true,
};

const DataBankListView: FC = () => {
  const model = DatabankModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_databank, initialState);
  //Dialog
  model.Dialog.Header = 'Supprimer la base de donnée';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'la base de donnée';

  const getDataBanks = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new DatabankModel();
      model.PathName = location.pathname;
      const databanks = await model.getDatabanks();
      if (!model.error) {
        dispatch(fetchDatabanks(databanks, true, !Array.isArray(databanks) || databanks.length === 0));
      }
    }
  },[location]);

  useEffect(() => {
    (async () => {
      await getDataBanks();
    })();
  },[]);

  const handleDelete : (id: number) => Promise<void> = async id => {
    if(id && access.canDelete()){
      if(await model.delete(id)){
        dispatch(deleteDatabank(id));
      }
    }
  };

  const { databanks, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results 
            model={model}
            databanks={databanks} 
            isEmptyList={isEmptyList} 
            onDeleteDataBank={handleDelete} />
        </Box> 
      </Container>
    </Page>
  );
};

export default DataBankListView;
