import {
    Success,
    NoContent,
    MSG_NO_CONTENT_ERROR,
    Created
} from 'src/common';
import type {
    LawPracticeFieldResource, 
    Error, 
    CreateResponse,
    LawPracticeFieldForCreate, 
    LawPracticeFieldForEdit
} from 'src/common/types';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import axios from 'src/utils/axios';
import AppModel from '../App';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';
  
type TypeResourceEdit = LawPracticeFieldForCreate | LawPracticeFieldForEdit;
  
export default class LawPracticeFieldModel extends AppModel 
{
  private static _instance: LawPracticeFieldModel;
  readonly Code = new FormModel('code','Code',3);
  readonly NameFr = new FormModel('nameFr','Nom',75);
  readonly NameEn = new FormModel('nameEn','Name',75);

  constructor(){
    super('/LawPracticeField');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'LAW_DOMAIN';
    this._headerTitle =  'Liste des champs de pratique';
    this._btnAddText = 'Ajouter un champs de pratique';
    this.Path.PathName = '/members/fields-of-practice';

    //Dialog
    this.Dialog.Header = 'Supprimer le champs de pratique';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le champs de pratique';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Champs de pratique',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: 'code', width: '15%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: 'nameFr', width: '40%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: 'nameEn', width: '40%', numeric: false, disablePadding: false, label: this.NameEn.Label
      },
      {
        id: '', width: '5%'
      }
    ];
  }

  static getInstance(): LawPracticeFieldModel {
    if (!LawPracticeFieldModel._instance) {
      LawPracticeFieldModel._instance = new LawPracticeFieldModel();
    }
    return LawPracticeFieldModel._instance;
  }

  static getInitialValues(values: LawPracticeFieldResource) : LawPracticeFieldResource {
    return {
      id: values.id,
      nameEn : values.nameEn || '',
      nameFr : values.nameFr || '',
      code : values.code || ''
    }
  }

  async getLawPracticeFields(): Promise<LawPracticeFieldResource[]> {
    let lawDomains: LawPracticeFieldResource[];
    await axios.get<LawPracticeFieldResource[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            lawDomains = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return lawDomains;
  }

  async getLawPracticeFieldById(id: string): Promise<LawPracticeFieldResource> {
    let lawDomain: LawPracticeFieldResource;
    await axios.get<LawPracticeFieldResource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            lawDomain = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return lawDomain;
  }

  async updateLawPracticeField(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<LawPracticeFieldForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Champs de pratique modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertLawPracticeField(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<LawPracticeFieldForCreate, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Champs de pratique créé.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Champs de pratique supprimé.'
					});
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  async getRefreshLawPracticeField(id: string): Promise<LawPracticeFieldResource> {
    let newLawDomain : LawPracticeFieldResource;
    const lawDomain = await this.getLawPracticeFieldById(id);
    if(!this.error){
      newLawDomain = lawDomain;
    }
    return newLawDomain;
  }
}
  