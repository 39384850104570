import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { DocumentCollectionResource } from 'src/common/types';
import DocCollectionFrInfo from './DocCollectionFrInfo';
import DocCollectionEnInfo from './DocCollectionEnInfo';
import { DocCollectionModel } from 'src/common';
import Note from 'src/components/note';
import ContentTypes from './ContentTypes';

interface DetailsProps {
  model: DocCollectionModel;
  docCollection: DocumentCollectionResource
}

const Details: FC<DetailsProps> = ({
  model,
  docCollection
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={12}
      md={12}
      xl={12}
      xs={12}
    >
      <Note label={model.Note.Label} note={docCollection.note} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <DocCollectionFrInfo model={model} docCollection={docCollection} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <DocCollectionEnInfo model={model} docCollection={docCollection} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <ContentTypes model={model} docCollection={docCollection} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  docCollection: PropTypes.object.isRequired
};

export default Details;
