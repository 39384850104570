import { Resource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchResourcesAction = {
  type: 'FETCH_RESOURCES';
  payload: {
    resources: Resource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

type FetchResourceAction = {
  type: 'FETCH_RESOURCE';
  payload: {
    resource: Resource;
    isLoading?: boolean;
  };
};

type FetchDetailsResourceAction = {
  type: 'FETCH_DETAILS_RESOURCE';
  payload: {
    resource: Resource;
    isLoading?: boolean;
  };
};

type Action = FetchResourcesAction |
              FetchResourceAction |
              FetchDetailsResourceAction;

interface ResourceReturnState {
  resources?: Resource[];
  resource?: Resource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface ResourceState {
  resources: Resource[];
  resource: Resource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

const reducer = (state: ResourceState, action: Action): ResourceReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_RESOURCES: {
      const { resources, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        resources,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_RESOURCE: {
      const { resource,isLoading } = action.payload;
      return {
        ...state,
        resource,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_RESOURCE: {
      const { resource } = action.payload;
      return {
        ...state,
        resource
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
