import PropTypes from 'prop-types';
import React, { ChangeEvent, FC, useCallback, useEffect, useReducer, useState } from 'react';
import { 
  CaijCheckbox,
  CaijInput, 
  FormikErrors, 
  FormikTouched, 
  LawDomainFieldModel,
  _lawDomainField,
  fetchLawDomainFields
} from 'src/common';
import type { 
  HandleChange, 
  LawDomainFieldResource, 
  SetFieldValue 
} from 'src/common/types';

interface LawDomainFieldFormProps {
  isParent: boolean;
  model: LawDomainFieldModel;
  lawDomainField: LawDomainFieldResource;
  errors: FormikErrors<LawDomainFieldResource>;
  touched: FormikTouched<LawDomainFieldResource>;
  onHandleBlur: (e: any) => void;
  onHandleChange: HandleChange;
  setFieldValue: SetFieldValue;
  setIsParent: (value: boolean) => void;
}

const LawDomainFieldForm: FC<LawDomainFieldFormProps> = ({
  isParent,
  model,
  lawDomainField,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
  setIsParent
}) => {
  const { id, nameFr, nameEn, parentId } = lawDomainField;
  const {NameFr, NameEn, ParentId } = model;
  const [state, dispatch] = useReducer(_lawDomainField, {lawDomainFields: [], isEmptyList: true, isLoading: true});
  const [disable, setDisable] = useState<boolean>(false);
  
  const getLawDomainFields = useCallback(async () => {
    const results = await model.getLawDomainFields({ 
      pageNumber: 1, 
      sortOptions: 'asc', 
      pageSize: 999
    });
    if(!model.error)
      dispatch(fetchLawDomainFields(results.list,{...results}, false,!Array.isArray(results.list) || results.list.length === 0, true));
  },[]);
  
  useEffect(() => {
    (async () => await getLawDomainFields())();
    if(id && !parentId){
      setDisable(true);
    }
  },[]);

  return (
    <>
      <CaijCheckbox
        label="Est Parent"
        checked={isParent}
        value={isParent}
        disabled={disable}
        onHandleChangeCheckBox={() => setIsParent(!isParent)}
        sx={{mb: 2}}
      />
      <CaijInput
        required
        name={NameFr.Name}
        value={nameFr}
        helperText={touched.nameFr && errors.nameFr}
        error={Boolean(touched.nameFr && errors.nameFr)}
        label={NameFr.Label}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name}}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
        sx={{mb:2}}
      />
      <CaijInput
        required
        name={NameEn.Name}
        margin="none"
        value={nameEn}
        label={NameEn.Label}
        helperText={touched.nameEn && errors.nameEn}
        error={Boolean(touched.nameEn && errors.nameEn)}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{maxLength: NameEn.MaxLength,  'data-testid': NameEn.Name}}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      { !isParent && !state.isLoading &&
        <CaijInput
            fullWidth
            label={ParentId.Label}
            name={ParentId.Name}
            required={!isParent ? true : false}
            onHandleChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e, setFieldValue)}
            select
            value={parentId || ''}
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            inputAttr={{'data-testid': ParentId.Name}}
        >
            <option value=''>Sélectionner une domaine de droit (Parent)</option>
            {
              !state.isEmptyList && state.lawDomainFields?.map(({id, nameFr}) => (
                <option key={id} value={id} >
                  {nameFr}
                </option>
              ))
            }
        </CaijInput>
      }
    </>
  );
};

LawDomainFieldForm.propTypes = {
  lawDomainField: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default LawDomainFieldForm;

