import React from 'react';
import { ReactNode } from 'react';
import {withAppContext, PropsWithContext}from 'src/functions/withAppContext';

interface CollapseDrawerProps extends PropsWithContext {
  children: ReactNode;
}

class CollapseDrawer extends React.Component<CollapseDrawerProps>{
  render() {
    const {children,context} = this.props;
    return  (
      <div 
        style={{height:'100%', display:'flex', flexDirection:'column'}} 
        onMouseEnter={() => {
          context.setShowIcon(true);
          if(!context.isDesktopNavOverlapOpen && !context.isDesktopNavOpen){
            context.setDesktopNavOverlapOpen(true);
          }
        }}
        onMouseLeave={() =>{
          if(context.isDesktopNavOpen)
            context.setShowIcon(false);
          context.setDesktopNavOverlapOpen(false);
        }}
      >
        {children}
      </div>
    );
  }
};

export default withAppContext(CollapseDrawer);