import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  SvgIcon,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import {
	labelConfig,
	Authorize,
	Mode,
	ListType,
	getEmptyListSearch,
	DocumentModel,
	Success,
	InternalServerError,
  FIRST_PAGE,
  NavigateFunction,
  getOpacity,
  CaijTooltip
} from 'src/common';
import { DownloadCloud } from 'react-feather';
import type { 
  SubscriptionResource, 
  DocumentOptionResource,
  SearchFilter,
} from 'src/common/types';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import parse from 'html-react-parser';
import { Paged } from 'src/types/pagination';
import printMessage from 'src/views/errors/MessageError';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell, CaijTableCellActive } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';

export interface ResultsProps {
  documents?: DocumentOptionResource[];
  getCollection: (code: string) => JSX.Element;
  subscriptions?: SubscriptionResource[];
  getCollectionList: () => JSX.Element[];
  isAuthorize: boolean;
  isEmptyList?: boolean;
  isLoading?: boolean;
  paged?: Paged;
  model: DocumentModel;
  navigate: NavigateFunction;
}

const Results: FC<ResultsProps> = ({
  documents,
  getCollection,
  getCollectionList,
  isAuthorize,
  paged,
  isLoading,
  isEmptyList,
  model,
  navigate
}) => {
  const path = model.Path;
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  let currentPage = paged?.currentPage - 1;
  const [filter, setFilter] = useState<SearchFilter>({
    page: currentPage,
    collection: '',
    docId: '',
    docTitle: ''
  });
  const headCells =  model.getHeadCells(labelConfig.status);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const { Filters: { page, docId, docTitle, collection } } = model;
    let docIdVal = '', pageVal = FIRST_PAGE, docTitleVal = '', collectionVal = '';
    for (const [key, value] of urlParams.entries()) {
      switch(key){
        case page:
          pageVal = +value;
          break;
        case docId:
          docIdVal = value;
          break;
        case docTitle:
          docTitleVal = value;
          break;
        case collection:
          collectionVal = value;
          break;
      }
    }
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        page: pageVal,
        docId: docIdVal,
        docTitle: docTitleVal,
        collection: collectionVal
      }
    });
  },[location.search]);

  const download = async (docId: string) => {
    const result = await model.triggerDownload(docId);
    if (!model.error){
      printMessage({
			status: result === true ? Success : InternalServerError,
			message: result === true ? 'Le téléchargement du document à la source a été demandé' : 'La demande de téléchargement a échoué.',
		});
    }
  }

  const renderTableCell = (row: DocumentOptionResource) => {
    const opacity = getOpacity(row.visible);
    return (
      <>
        <CaijTableCell opacity={opacity}>{row.documentId}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{parse(row.title.trim())}</CaijTableCell>
        <CaijTableCell opacity={opacity}>{getCollection(row.collection)}</CaijTableCell>
        <CaijTableCellActive active={row.visible} />
        <CaijTableCellAction 
          pageEditUrl={path.getEdit(encodeURIComponent(row.documentId))}
          resourceCode={model.ResourceCode}
          showRemoveIcon={false}
        >
          { row.crawlerId && row.crawlerId > 0 && (
            <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsEditAuth(l)}>
              <CaijTooltip title='Retélécharger le document à la source' disabled={!isEditAuth}>
                <IconButton onClick={() => download(row.documentId)} disabled={!isEditAuth}>
                  <SvgIcon fontSize='small'>
                    <DownloadCloud />
                  </SvgIcon>
                </IconButton>
              </CaijTooltip>
            </Authorize>
            )
          }
        </CaijTableCellAction>
      </>
    )
  }

  return (
    <MyCard>
      <Box sx={{overflowX: 'auto', marginLeft: '1em'}} display='flex' alignItems='center'>
        <Search 
          listType={ListType.Document}
          filter={filter}
          width="300px"
          path={path.Home}
          collections={getCollectionList()}
          isAuthorize={isAuthorize}
        />
        <CaijResetFilterButton path={model.Path.Home} navigate={navigate} sx={{margin: '0 0 0 15px'}} />
      </Box>
      { (isLoading && documents) && (
          <>
            <CaijTable>
              <TableHead>
                <TableRow>
                  { headCells.map(({width, label, align}, index: number) => <TableCell key={index} width={width} align={align}>{label}</TableCell>) }
                </TableRow>
              </TableHead>
              <TableBody>
                { isEmptyList ? getEmptyListSearch(headCells.length) :
                  ( documents.map((row: DocumentOptionResource, index: number) => (
                        <CaijTableRowClickable key={index} path={path.getDetail(encodeURIComponent(row.documentId),'/wc')}>
                          {renderTableCell(row)}
                        </CaijTableRowClickable>
                      )
                    )
                  )
                }
              </TableBody>
            </CaijTable>
            <CaijTablePagination 
                paged={paged} 
                model={model} 
                filter={filter} 
                page={currentPage}
                navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
              />
          </>
        )
      }
    </MyCard>
  )
};

Results.propTypes = {
  documents: PropTypes.array,
  subscriptions: PropTypes.array,
  isEmptyList: PropTypes.bool,
  isLoading: PropTypes.bool,
  getCollection: PropTypes.func.isRequired,
  getCollectionList: PropTypes.func.isRequired
};

export default Results;
