import type { DocumentTypeResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchDocumentTypes = (documentTypes: DocumentTypeResource[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean): {
   type: 'FETCH_DOCUMENT_TYPES',
   payload: {
    documentTypes: DocumentTypeResource[],
     isLoading?: boolean,
     isEmptyList?: boolean,
     isAuthorize?:boolean
   }
} => ({
  type: actionTypes.FETCH_DOCUMENT_TYPES,
  payload: {
    documentTypes,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchDocumentType = (documentType: DocumentTypeResource, isLoading?: boolean): {
  type: 'FETCH_DOCUMENT_TYPE', 
  payload: { 
    documentType: DocumentTypeResource,
    isLoading?: boolean
  }
} => ({
  type: actionTypes.FETCH_DOCUMENT_TYPE,
  payload: {
    documentType,
    isLoading
  }
});

export const fetchDetailsDocumentType = (documentType: DocumentTypeResource): {
  type: 'FETCH_DETAILS_DOCUMENT_TYPE'
  payload: {
    documentType: DocumentTypeResource,
  }
} => ({
  type: actionTypes.FETCH_DETAILS_DOCUMENT_TYPE,
  payload: {
    documentType
  }
});

export const deleteDocumentType = (code: string) : {
  type: 'DELETE_DOCUMENT_TYPE'
  payload: {
    code: string,
  }
} => ({
  type: actionTypes.DELETE_DOCUMENT_TYPE,
  payload: {
    code
  }
});
