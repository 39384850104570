import { Box } from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import { ErrorType,labelConfig, CaijInputRank } from "src/common";
import PropTypes from 'prop-types';
import AppModel from "src/model/App";

interface QtyRequiredInputProps {
  id: number;
  value: string;
  handleLeaveInput: (event: ChangeEvent<HTMLInputElement>) => void;
  addId:(value:number) => void;
  removeId:(value:number) => void;
  maxLength: number;
  required?: boolean;
}

const QtyRequiredInput: FC<QtyRequiredInputProps> = ({ 
  addId, 
  removeId, 
  id, 
  value, 
  handleLeaveInput, 
  maxLength,
  ...rest
}) => {
  const [error, setError] = useState<{isValid: boolean, helperText: string}>({
    isValid: false,
    helperText: '',
  });
  
  const handleError = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    event.preventDefault();
    const { value } = event.target;
    if(!value){
      setError({isValid: false, helperText: AppModel.formatError(ErrorType.required, labelConfig.requireQty)});
      addId(id);
      return;
    }
    removeId(id)
    setError({isValid: true, helperText: ''});
  };
  
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box width="100%">
          <CaijInputRank
            value={value}
            {...rest}
            isListRecord
            label={labelConfig.requireQty}
            name={id.toString()}
            onHandleBlur={(e) => {
              handleError(e, id);
              handleLeaveInput(e);
            }}
            error={Boolean(!error?.isValid && error?.helperText)}
            helperText={error?.helperText}
            InputLabelProps={{ required: rest.required, shrink: true }}
            inputProps={{ maxLength }}
            sx={{height: '60px'}}
          />
        </Box>
      </Box>
    </>
  );
}

QtyRequiredInput.propTypes = {
  id: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  handleLeaveInput: PropTypes.func.isRequired,
  addId: PropTypes.func.isRequired,
  removeId: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired 
};

export default QtyRequiredInput