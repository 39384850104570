import { FC, ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import { CaijInputAddress, FormikErrors, FormikTouched, CaijInput, input, LibraryModel } from 'src/common';
import type { AddressDto, HandleBlur, LibraryResource, SetFieldValue } from 'src/common/types';

interface ContactAddressFormProps {
  model: LibraryModel;
  contactAddress?: AddressDto;
  errors: FormikErrors<LibraryResource>;
  touched: FormikTouched<LibraryResource>;
  handleBlur: HandleBlur;
  setFieldValue: SetFieldValue;
}

const ContactAddressForm: FC<ContactAddressFormProps> = ({
  model,
  contactAddress,
  errors,
  touched,
  handleBlur,
  setFieldValue
}) => {
  const [line1, setLine1] = useState<string | undefined>(contactAddress.line1 || undefined);
  const [line2, setLine2] = useState<string | undefined>(contactAddress.line2 || undefined);
  const [city, setCity] = useState<string | undefined>(contactAddress.city || undefined);
  const [state, setState] = useState<string | undefined>(contactAddress.state || undefined);
  const [country, setCountry] = useState<string | undefined>(contactAddress.country || undefined);
  const [zip, setZip] = useState<string | undefined>(contactAddress.zip || undefined);
  const { AddressLine1, AddressLine2, AddressCity, AddressState, AddressZip, AddressCountry } = model;
  return (
      <>
        <CaijInputAddress
          name={AddressLine1.Name}
          label={AddressLine1.Label}
          value={line1}
          InputLabelProps={{ shrink: true }}
          sx={input}
          inputProps={{'data-testid': AddressLine1.Name}}
          error={Boolean(touched?.addressFr?.line1 && errors?.addressFr?.line1)}
          helperText={touched?.addressFr?.line1 && errors?.addressFr?.line1}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine1(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            setFieldValue(AddressLine1.Name, line1);
            handleBlur(e);
          }}
        />
        <CaijInputAddress
          name={AddressLine2.Name}
          label={AddressLine2.Label}
          value={line2}
          InputLabelProps={{ shrink: true }}
          sx={input}
          error={Boolean(touched?.addressFr?.line2 && errors?.addressFr?.line2)}
          helperText={touched?.addressFr?.line2 && errors?.addressFr?.line2}
          inputProps={{maxLength: AddressLine2.MaxLength, 'data-testid': AddressLine2.Name}}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setLine2(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            setFieldValue(AddressLine2.Name,line2);
            handleBlur(e);
          }}
        />
        <CaijInput
          name={AddressCity.Name}
          value={city}
          label={AddressCity.Label}
          sx={input}
          error={Boolean(touched?.addressFr?.city && errors?.addressFr?.city)}
          helperText={touched?.addressFr?.city && errors?.addressFr?.city}
          inputAttr={{maxLength: AddressCity.MaxLength, 'data-testid': AddressCity.Name, autoComplete: 'none'}}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            contactAddress.city = city;
            setFieldValue(AddressCity.Name,city);
            handleBlur(e);
          }}
        />
        <CaijInput
          name={AddressState.Name}
          label={AddressState.Label}
          value={state}
          sx={input}
          error={Boolean(touched?.addressFr?.state && errors?.addressFr?.state)}
          helperText={touched?.addressFr?.state && errors?.addressFr?.state}
          inputAttr={{ maxLength: AddressState.MaxLength, 'data-testid': AddressState.Name, autoComplete: 'none'}}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setState(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            setFieldValue(AddressState.Name, state);
            handleBlur(e);
          }}
        />
        <CaijInput
          name={AddressZip.Name}
          label={AddressZip.Label}
          value={zip}
          sx={input}
          error={Boolean(touched?.addressFr?.zip && errors?.addressFr?.zip)}
          helperText={touched?.addressFr?.zip && errors?.addressFr?.zip}
          inputAttr={{maxLength: AddressZip.MaxLength,'data-testid': AddressZip.Name, autoComplete: 'none'}}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setZip(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            setFieldValue(AddressZip.Name,zip);
            handleBlur(e);
          }}
        />
        <CaijInput
          name={AddressCountry.Name}
          label={AddressCountry.Label}
          value={country}
          sx={input}
          error={Boolean(touched?.addressFr?.country && errors?.addressFr?.country)}
          helperText={touched?.addressFr?.country && errors?.addressFr?.country}
          inputAttr={{maxLength: AddressCountry.MaxLength, 'data-testid': AddressCountry.Name, autoComplete: 'none' }}
          InputLabelProps={{ shrink: true }}
          onHandleChange={(e:ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
          onHandleBlur={(e:ChangeEvent<HTMLInputElement>) => {
            setFieldValue(AddressCountry.Name,country);
            handleBlur(e);
          }}
        />
      </>
    );
  }
  
ContactAddressForm.propTypes = {
  contactAddress: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default ContactAddressForm;
