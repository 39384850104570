import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Link, 
  Typography, 
  MenuItem, 
  MenuList, 
  Dialog, 
  SvgIcon, 
  Divider
} from '@mui/material';
import { 
  CustomerModel,  
  CustomerStatus,
  ActionType,
  DialogModel,
  _customer,
  MSG_SEND_EMAIL_MEMBER,
  formatDate,
  AccountExpiryMode,
  ONLINE_TRAINING,
  Success,
} from 'src/common';
import type { CustomerResource, Error } from 'src/common/types';
import printMessage from 'src/views/errors/MessageError';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import CaijDialogs from 'src/components/dialog';
import ResetPassword from 'src/components/customer/customer/Details/ResetPassword';
import ResetAccountExpiryModeByDateSpecifiedAtCreation from 'src/components/customer/customer/Details/ResetAccountExpiryModeByDateSpecifiedAtCreation';
import History from 'src/components/customer/customer/Details/History';
import LoadingButton from '@mui/lab/LoadingButton';
import {CheckSquare as CheckIcon} from 'react-feather';
import EmailStatus from 'src/components/customer/customer/Details/EmailStatus';
import { $enum } from 'ts-enum-util';

interface OtherActionsProps {
  model: CustomerModel;
  customer: CustomerResource;
  setAnchorEl: React.Dispatch<any>;
  onHandleRefreshCustomer: () => Promise<void>;
}

interface Params{
  actionType: string, 
  id: number;
  expiration?: Date;
  modified?: Date;
  refresh?: () => Promise<void>;
  setAnchorEl: (value: string) => void;
};

interface State {
  openDialog: boolean;
  openAccountExpiryModeByDateSpecifiedAtCreationDialog: boolean;
  actionType: string;
}

function getDialog(actionType: string, model: CustomerModel) : DialogModel {
  const BtnText = {
    [ActionType.activate]   : BtnOk.ACTIVATE_STATUS,
    [ActionType.reactivate] : BtnOk.REACTIVATE_STATUS,
    [ActionType.restrict]   : BtnOk.RESTRICT_STATUS,
    [ActionType.deleted]    : BtnOk.DELETED_STATUS,
    [ActionType.deactivate] : BtnOk.DEACTIVATE_STATUS,
    [ActionType.sendEmail]  : BtnOk.SENDEMAIL
  };
  switch(actionType){
    case ActionType.sendEmail: 
      model.Dialog.Header = "Envoi du courriel d'activation de compte";
      model.Dialog.BtnText = BtnText[actionType];
      model.Dialog.Name =  "le courriel d'activation";
      return model.Dialog;
    case ActionType.activate:
    case ActionType.deleted: 
      model.Dialog.Header = "Changement du statut d'un membre";
      model.Dialog.BtnText = BtnText[actionType];
      model.Dialog.Name = 'le compte';
      return model.Dialog;
    case ActionType.reactivate:
    case ActionType.restrict:
      model.Dialog.Header = "Changement l'accès d'un membre";
      model.Dialog.BtnText = BtnText[actionType];
      model.Dialog.Name = actionType === ActionType.restrict ? "le compte" : "du compte";
      return model.Dialog;
    case ActionType.deactivate:
      model.Dialog.Header = "Changement du statut d'un membre";
      model.Dialog.BtnText = BtnText[actionType];
      model.Dialog.Name = '';
      return model.Dialog;
  }
  return;
}

async function modifyCustomerStatus({id, modified, expiration, actionType, refresh, setAnchorEl} : Params){
  const messages = {
    [ActionType.activate]   : 'activé',
    [ActionType.reactivate] : 'réactivé',
    [ActionType.restrict]   : 'restreint',
    [ActionType.deactivate] : 'désactivé'
  };
  const sendEmailMessages = {
    [ActionType.activate]   : 'lors de l\'activation.',
    [ActionType.restrict]   : 'lors de la restriction.',
    [ActionType.reactivate] : 'lors de la levée de restriction.',
    [ActionType.deactivate] : 'lors de la désactivation.'
  };
  if(id && !isNaN(id)){
    const model = new CustomerModel();
    if(actionType == ActionType.deleted){
      await model.delete(id);
    }else if(actionType == ActionType.sendEmail){
      await model.sendAccountActivationEmail(id);
    }else if(await model.modifyCustomerStatus(id, { custModified: modified, expiration, actionType: $enum(ActionType).getKeyOrDefault(actionType)}, `Compte ${messages[actionType]}.`)){
      model.printEmailMessage(Success,MSG_SEND_EMAIL_MEMBER + sendEmailMessages[actionType]);
      refresh();
    }
  }
};

function Execute(props: Params){
  const { id, setAnchorEl, actionType, refresh } = props;
  const [loading, setLoading] = useState<boolean>(null);
  
  const submit = async () : Promise<number> => {
    let result: number | undefined;
    setLoading(true);
    if(id && !isNaN(id)){
      const model = new CustomerModel();
      let response: Error;
      if(actionType === ActionType.synchronize){
        response = await model.synchronize(id);
      }else{
        response = await model.createUxpertiseAccount(id);
        result = +response.data;
      }
      const { status, message } = response;
      if(!model.error && response){
        printMessage({status, message});
      }
    }
    setLoading(false);
    return result;
  }

  return (
    <LoadingButton
      size="small"
      loading={loading}
      loadingPosition="start"
      variant="text"
      startIcon={<SvgIcon fontSize='small'><CheckIcon /></SvgIcon>}
      onClick={async () => {
        const value = await submit();
        setAnchorEl(null);
        if(value){
          if(actionType === ActionType.uxpertise){
            refresh();
          }
        }
      }}	
    >
      { actionType === ActionType.synchronize ? ActionType.synchronize : ActionType.uxpertise }
    </LoadingButton>
   );
}

function Item(props: {actionType: string, showDialog: (value: string) => void}){
  return (
    <>
      <Link href='#' onClick={() => props.showDialog(props.actionType)} underline='none'>
        <MenuItem>
          <Typography variant="body1">
            {props.actionType}
          </Typography>
        </MenuItem>
      </Link>
    </>
   );
};

const OtherActions: FC<OtherActionsProps> = ({ 
  model,
  customer, 
  onHandleRefreshCustomer, 
  setAnchorEl
}) => {
  const [state, setState] = useState<State>({
    openDialog: false,
    openAccountExpiryModeByDateSpecifiedAtCreationDialog: false,
    actionType: ''
  });
  
  const showDialog = (actionType: string) : void => {
    setAnchorEl(null);
    setState((prevState: State) => {
      return {
        ...prevState,
        openDialog: true,
        actionType
      }
    });
  };

  const closeDialog = (): void => {
    setState((prevState: State) => {
      return {
        ...prevState,
        openDialog: false,
        openAccountExpiryModeByDateSpecifiedAtCreationDialog: false
      }
    })
  }

  const verifyAccountExpiryModeByDateSpecifiedAtCreation = () => {
    if((state.actionType === ActionType.activate) && 
       (formatDate(customer.expiration) <= formatDate(new Date)) && 
       (customer.subscriptionGroup?.accountExpiryMode === CustomerModel.getAccountExpiryModeByVal(AccountExpiryMode.DateSpecifiedAtCreation))){
        setState((prevState: State) => {
        return {
          ...prevState,
          openAccountExpiryModeByDateSpecifiedAtCreationDialog: true
        }
      });
      return true;
    }
    return false;
  }
  
  const renderStatusContent = () => {
    const { blocked, status} = customer;
    let actionTypes:string[];
    if(blocked && status === CustomerModel.getStatusByVal(CustomerStatus.Active)){
      actionTypes = [ActionType.reactivate, ActionType.deactivate];
    }else if(status === CustomerModel.getStatusByVal(CustomerStatus.Active)){
      actionTypes=[ActionType.restrict, ActionType.deactivate]; 
    }else if(status === CustomerModel.getStatusByVal(CustomerStatus.Inactive)){
      actionTypes=[ActionType.activate, ActionType.deleted];
    }else if(status === CustomerModel.getStatusByVal(CustomerStatus.ActiveUponApproval)){
      actionTypes=[ActionType.activate, ActionType.deleted];  
    }else if(status === CustomerModel.getStatusByVal(CustomerStatus.ActiveOnLogin)){
      actionTypes=[ActionType.sendEmail, ActionType.deactivate, ActionType.deleted]; 
    }
    return actionTypes?.map((actionType, index) => <Item actionType={actionType} key={index} showDialog={showDialog} />);
  };

  return (
    <Box>
      <MenuList>
        <History customer={customer} setAnchorEl={() => setAnchorEl(null)} />
        <EmailStatus customer={customer} setAnchorEl={() => setAnchorEl(null)} />
        <Divider sx={{mb:1}} />
        <MenuItem sx={{
          flexDirection:"column", 
          alignItems:'flex-start', 
          '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'default'
            }}}
        >
          <Execute 
            actionType={ActionType.synchronize} 
            id={customer.id} 
            setAnchorEl={setAnchorEl} 
          />
          { (!customer.uxpertiseId && customer.subscriptionGroup?.products?.some(({product}) => product.code === ONLINE_TRAINING)) && (
              <Execute 
                actionType={ActionType.uxpertise} 
                id={customer.id} 
                setAnchorEl={setAnchorEl} 
                refresh={onHandleRefreshCustomer} 
              />
            )
          }
        </MenuItem>
        <Divider />
        { customer.status !== CustomerModel.getStatusByVal(CustomerStatus.Inactive) 
          && customer.status !== CustomerModel.getStatusByVal(CustomerStatus.ActiveUponApproval) && (
            <ResetPassword customer={customer} setAnchorEl={setAnchorEl} refresh={onHandleRefreshCustomer} />
          )
        }
        {renderStatusContent()}
      </MenuList>
      { state.actionType && (
          <CaijDialogs
            dialog={getDialog(state.actionType, model)}
            isOpen={state.openDialog}
            onSubmit={() => (async () => {
              if(!verifyAccountExpiryModeByDateSpecifiedAtCreation()){
                const { id, modified } = customer;
                modifyCustomerStatus({
                  actionType: state.actionType, 
                  id,
                  modified,
                  refresh: onHandleRefreshCustomer, 
                  setAnchorEl
                });
                closeDialog();
              }
            })()}
            close={() => closeDialog()}
          />
        )
      }
      <Dialog open={state.openAccountExpiryModeByDateSpecifiedAtCreationDialog} maxWidth="md">
        <ResetAccountExpiryModeByDateSpecifiedAtCreation
          model={model} 
          customer={customer}
          onSubmit={(l) => {
            const { id, modified } = customer;
            modifyCustomerStatus({
              actionType: state.actionType, 
              id,
              modified,
              expiration: l, 
              refresh: onHandleRefreshCustomer, 
              setAnchorEl
            });
            closeDialog()
          }}
          handleClose={() => closeDialog()}
        />
      </Dialog>
    </Box>
  );
};

OtherActions.propTypes = {
  customer: PropTypes.object.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired
};

export default OtherActions;
