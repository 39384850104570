import { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  FormikErrors, 
  FormikTouched, 
  LibraryModel, 
} from 'src/common';
import type { LibraryResource } from 'src/common/types';
import { Grid } from '@mui/material';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';

interface TempClosingProps {
  model: LibraryModel;
  library: LibraryResource, 
  errors: FormikErrors<LibraryResource>;
  touched: FormikTouched<LibraryResource>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const TempClosing: FC<TempClosingProps> = ({ 
  model,
  library,
  errors,
  touched,
  setFieldValue 
}) => {
  const { TempClosingMsgFr, TempClosingMsgEn } = model;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={TempClosingMsgFr.Label}
          dataTestId={TempClosingMsgFr.Name}
          value={library.tempClosingMsgFr}
          name={TempClosingMsgFr.Name}
          touched={touched.tempClosingMsgFr}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijDraftEditor
          title={TempClosingMsgEn.Label}
          dataTestId={TempClosingMsgEn.Name}
          value={library.tempClosingMsgEn}
          name={TempClosingMsgEn.Name}
          touched={touched.tempClosingMsgEn}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
}

TempClosing.propTypes = {
  library: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default TempClosing;
