import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { EmployeesResource, EmployeeResourceForEdit } from 'src/common/types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  EmployeeModel,
  _employee,
  Mode,
  btnSubmit
} from 'src/common';
import EmployeeForm from 'src/components/employee/employee/Forms';
import printMessage from 'src/views/errors/MessageError';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: EmployeeModel;
  employee: EmployeesResource;
  onHandleRefreshEmployee: (value: EmployeesResource) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: EmployeesResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  employee,
  onHandleRefreshEmployee,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: EmployeesResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new EmployeeModel();
    //const { employeeResourceGroup } = employee;
    //const resourceGroupIds = employeeResourceGroup.map((empResourceGroup: EmployeeResourceGroup) => empResourceGroup.resourceGroupId);
    const submitData = values as EmployeeResourceForEdit;
    const employeeId = submitData.id;
    if (submitData && employeeId) {
      const response = await model.updateEmployee(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message,
        });
        await onHandleRefreshEmployee(employee);
      }
    }
  };
  
  return (
    <Formik
      initialValues={EmployeeModel.getInitialValues(employee)}
      validationSchema={validateSchema(model, employee)}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                <EmployeeForm
                  model={model}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleChangeCheckBox={handleChangeCheckBox}
                  setFieldValue={setFieldValue}
                  employee={values}
                />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  employee: PropTypes.object.isRequired,
  onHandleRefreshEmployee: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
