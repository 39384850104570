import { useEffect, useReducer } from "react";
import { 
  Forbidden, 
  Unauthorized, 
  _emailTemplate, 
  EmailTemplateModel, 
  fetchEmailTemplates
} from "src/common";
import { EmailTemplateDto } from "src/common/types";
import { FETCH_EMAIL_TEMPLATES } from "src/store/actions/ActionTypes";

export interface EmailTemplate {
  emailTemplates: EmailTemplateDto[];
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

export default function useEmailTemplates(): EmailTemplate {
  const initialState: EmailTemplate = {
    emailTemplates: [],
    isEmptyList: true,
    isLoading: false,
    isAuthorize: false,
  };
  const [state, dispatch] = useReducer(_emailTemplate, initialState);
  useEffect(() => {
    const initialise = async () => {
      const model = new EmailTemplateModel();
      model.skipHandleError = true;
      const emailTemplates = await model.getEmailTemplates();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchEmailTemplates(emailTemplates, true, false, false));
        }else{
          dispatch(fetchEmailTemplates(emailTemplates, true, true, true));
        }
      } else {
        dispatch(fetchEmailTemplates(emailTemplates, true, !Array.isArray(emailTemplates) || emailTemplates.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: FETCH_EMAIL_TEMPLATES, payload: initialState}); }
  },[]);
  const { emailTemplates, isEmptyList, isLoading, isAuthorize } = state;
  return { emailTemplates, isEmptyList, isLoading, isAuthorize }; 
};
