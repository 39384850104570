import { Box, Button, SvgIcon, TextField } from "@mui/material";
import { FC, useState } from "react";
import { 
  btnSubmit,
  CaijInput, 
  CaijInputEmail, 
  CustomerModel, 
  CustomerStatus, 
  EmptyList, 
  ErrorType, 
  labelConfig,
  useFormik, 
  _customer 
} from "src/common";
import {  
  EndorsedCustomerResource, 
  SubscriptionProductDto
} from "src/common/types";
import * as Yup from 'yup';
import { ICaijDialog } from "src/components/dialog/CaijDialog";
import { XSquare as CancelIcon } from 'react-feather';
import CaijLoadingButton from "src/components/buttons/CaijLoadingButton";

interface ModifyCustomerEndorsedProps {
  endorser: EndorsedCustomerResource;
  setState: React.Dispatch<React.SetStateAction<ICaijDialog>>;
}

interface ICustomer{
  id: number;
  fullName: string;
  endorsed: number;
  limitEndorsed: number;
}

type Customer = ICustomer[];

const ModifyCustomerEndorsed : FC<ModifyCustomerEndorsedProps> = ({ endorser, setState }) =>  {
  const model = CustomerModel.getInstance();
  const [value, setValue] = useState<string>('');
  const [done, setDone] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Customer>([]);
  const { MemberEmail, MemberNoCaij } = model;
  
  const renderResults = () => {
    if(!done) return;
    if(done && customers && customers.length === 0){
      return (
        <EmptyList sx={{paddingTop: '10px', height: '10px'}} value="Aucun cautionneur n'a été trouvé" noCard />  
      )
    }
    if(done && customers && customers.length > 0){
      return (
        <TextField
          value={value}
          label="Cautionneur"
          select
          SelectProps={{ native: true }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const { value } = e.target;
            setValue(e.target.value);
            setState((prevState: ICaijDialog) => {
              return {
                ...prevState,
                customerId: +value
              }
            })
          
          }}
          sx={{mt:4}}
        >
          <option value=''>Sélectionner un cautionneur</option>
          { customers.map((customer: ICustomer, index: number) => (
              <option key={index} value={customer.id}>
                {`${customer.fullName} (${customer.endorsed} / ${customer.limitEndorsed})`}
              </option>
            ) 
          )}
        </TextField>
      )
    }
  }

  const resetCustomerId = () => {
    setState((prevState: ICaijDialog) => {
      return {
        ...prevState,
        customerId: null
      }
    });
  }

  const validationSchema = () => {
    return Yup.object({
      email: Yup.string().nullable()
        .email(CustomerModel.formatError(ErrorType.invalid,labelConfig.contactEmail))
        .test(labelConfig.contactEmail, CustomerModel.formatError(ErrorType.exist, 'Utilisateur'), async (value: string) => {
          return value === endorser.email ? false : true;
      }),
      noCaij: Yup.string().nullable()
        .test(labelConfig.noCaij, CustomerModel.formatError(ErrorType.exist,labelConfig.noCaij), async (value: string) => {
          return value === endorser.noCaij ? false : true;
        })
    });
  };

  const { errors, handleBlur, setFieldValue, handleSubmit, handleReset, handleChange, values, touched } = useFormik({
    initialValues: {email: '', noCaij: ''},
    validationSchema: validationSchema(),
    validateOnBlur: false,
    onSubmit: async values => {
      const { email, noCaij } = values;
      setDone(false);
      if(email || noCaij){
        setLoading(true)
        const results = await model.getCustomers({
          pageNumber: 1,
          pageSize: 500,
          email: values.email,
          noCaij: values.noCaij,
          isEndorsedBy: true
        });
        if(!model.error){
          const newCustomers:Customer = [];
          for(const customer of results.list){
            const cust = await model.getCustomerById(customer.id);
            const product = cust?.subscriptionGroup?.products?.find((product: SubscriptionProductDto) => product.product.code === "ENDORSEMENT");
            if(customer.status === CustomerModel.getStatusByVal(CustomerStatus.Active) && !customer.blocked && product){
              newCustomers.push({id: customer.id, fullName: customer.fullname, endorsed: cust?.customersEndorsed?.length || 0, limitEndorsed: product?.parameter || 0});
            }
          }
          setCustomers(newCustomers);
          setDone(true);
          setLoading(false);
        }
      }
    },
  });
  
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column">
        <CaijInputEmail
          name={MemberEmail.Name}
          id={MemberEmail.Name}
          value={values.email}
          error={Boolean(touched.email && errors.email)}
          label={MemberEmail.Label}
          helperText={touched.email && errors.email}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: MemberEmail.MaxLength}}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
          onFocus={() => {
            if(customers.length === 0){
              resetCustomerId();
              setDone(false);
            }
          }}
        />
        <CaijInput
          name={MemberNoCaij.Name}
          id={MemberNoCaij.Name}
          label={MemberNoCaij.Label}
          value={values.noCaij}
          error={Boolean(touched.noCaij && errors.noCaij)}
          helperText={touched.noCaij && errors.noCaij}
          InputLabelProps={{ shrink: true }}
          inputAttr={{ maxLength: MemberNoCaij.MaxLength }}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          onFocus={() => {
            if(customers.length === 0){
              resetCustomerId();
              setDone(false);
            }
          }}
          setFieldValue={setFieldValue}
          sx={{mb: 1}}
        />
      <Box display="flex" alignItems="center" justifyContent='center'>
        <CaijLoadingButton
          variant="contained" 
          loading={loading}
          loadingPosition="start"
          type="submit"
          sx={{...btnSubmit, width: '140px'}}
        >
          Rechercher
        </CaijLoadingButton>
        <Button 
          variant="contained" 
          onClick={handleReset} 
          sx={{width: '120px'}} 
          startIcon={
            <SvgIcon fontSize='small'>
              <CancelIcon />
            </SvgIcon>
          }
        >Effacer</Button>
      </Box>
      { renderResults() }
      </Box>
    </form>
  )
}

export default ModifyCustomerEndorsed;
