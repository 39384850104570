import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  _cardRequestPending,
  fetchCardRequestPending,
  CustomerModel,
  AccessModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import printMessage from 'src/views/errors/MessageError';
import CardRequestPendingModel from 'src/model/customer/CardRequestPending';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  customerPendingChanges: [],
  isEmptyList: true,
  isLoading: false,
};

const CardRequestPendingListView: FC = () => {
  const model = CardRequestPendingModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_cardRequestPending, initialState);
 
  const getCardRequestPending = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      model.PathName = location.pathname;
      const customerPendingChanges = await model.getCardRequestPending();
      if (!model.error) {
        dispatch(fetchCardRequestPending(customerPendingChanges, true, !Array.isArray(customerPendingChanges) || customerPendingChanges.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getCardRequestPending();
    })();
  },[]);

  const deleteCardRequestPending: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      const model = new CustomerModel();
      if (id) {
        const response = await model.cancelCardRequest(id);
        if (!model.error) {
          printMessage({
            status: response.status,
            message: response.message,
          });
          const customerPendingChanges = [...state.customerPendingChanges].filter(({customer}) => customer.id !== id);
          dispatch(fetchCardRequestPending(customerPendingChanges, true, !Array.isArray(customerPendingChanges) || customerPendingChanges.length === 0));
        }
		  }
    }
  };

  const { customerPendingChanges, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            customerPendingChanges={customerPendingChanges}
            isEmptyList={isEmptyList}
            reload={() => getCardRequestPending()}
            onDeleteCardRequestPending={(l) => deleteCardRequestPending(l)}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CardRequestPendingListView;
