import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { SubscriptionGroupResource, SubscriptionResource } from 'src/common/types';
import SubscriptionInfo from './SubscriptionInfo';
import ContactInfo from './ContactInfo';
import { SbGroup, SubscriptionModel } from 'src/common';
import AccountInfo from './AccountInfo';
import Note from 'src/components/note';

interface DetailsProps {
  model: SubscriptionModel,
  subscription: SubscriptionResource;
  groupList:  SubscriptionGroupResource[];
}

const Details: FC<DetailsProps> = ({
  model,
  subscription,
  groupList
}) => (
  <Grid container spacing={3}>
    <Grid item lg={12} md={12} xl={12} xs={12}>
      <Note label={model.Note.Label} note={subscription.note} />
    </Grid>
    <Grid item lg={4} md={6} xl={4} xs={12}>
      <SubscriptionInfo model={model} subscription={subscription} />
    </Grid>
    <Grid item lg={4} md={6} xl={4} xs={12}>
      <AccountInfo subscription={subscription} model={model} groupList={groupList} />
    </Grid>
    { subscription.group === SubscriptionModel.getSbGroupByVal(SbGroup.B2B) && (
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <ContactInfo model={model} subscription={subscription} />
        </Grid>
      )
    }
  </Grid>
);

Details.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default Details;
