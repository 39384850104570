import { FC } from 'react';
import { 
  CaijCard,
  CaijSwitch,
  DatabankModel
} from 'src/common';
import { 
  KnownDatabankStatusOption,
  type DatabankResource, 
  type HandleChange,
  type SetFieldValue 
} from 'src/common/types';
import { Box } from '@mui/material';
import DatabankStatusForm from './DatabankStatusForm';

export interface OtherActionFormProps {
  model: DatabankModel;
  databank: DatabankResource;
  setFieldValue: SetFieldValue;
  handleChange: HandleChange;
  handleChangeSwitchEnum: HandleChange;
}

const OtherActionForm: FC<OtherActionFormProps> = ({ 
  model,
  databank,
  handleChange,
  handleChangeSwitchEnum,
  setFieldValue 
}) => {
  const { disclaimer, probe, databankStatus } = databank;
  return (
    <CaijCard title='Autres Actions' allowedBackgroundColor={false}>
      <Box mb={1}>
        <CaijSwitch
          name={model.Disclaimer.Name}
          checked={disclaimer}
          value={disclaimer}
          inputProps={{ 'data-testid': model.Disclaimer.Name }}
          onHandleChangeSwitch={handleChange}
          setFieldValue={setFieldValue}
          label={model.Disclaimer.Label}
        />
      </Box>
      <Box mb={1}>
        <CaijSwitch
          name={model.Probe.Name}
          checked={probe}
          value={probe}
          inputProps={{ 'data-testid': model.Probe.Name }}
          onHandleChangeSwitch={handleChange}
          setFieldValue={setFieldValue}
          label={model.Probe.Label}
        />
      </Box>
      <Box>                             
        <CaijSwitch
          name={model.DatabankStatus.Name}
          checked={databankStatus == KnownDatabankStatusOption.Active ? false : true }
          value={databankStatus == KnownDatabankStatusOption.Active ? KnownDatabankStatusOption.Inactive:KnownDatabankStatusOption.Active}
          inputProps={{ 'data-testid': model.DatabankStatus.Name }}
          onHandleChangeSwitch={handleChangeSwitchEnum}
          setFieldValue={setFieldValue}
          label={model.DatabankStatus.Label}
        />
      </Box>
      { databankStatus == KnownDatabankStatusOption.Inactive && <DatabankStatusForm model={model} databank={databank} onHandleChange={handleChange} /> }
    </CaijCard>
  );
}

export default OtherActionForm;