import type { Theme } from 'src/common/types';

export const tableCell = {
    width: '260px',
    fontWeight: (theme: Theme) => theme.typography.fontWeightMedium
};
  
export const tableCell2 = {
    width: '270px',
    fontWeight: (theme: Theme) => theme.typography.fontWeightMedium
};

export const tableCell3 = {
  width: '190px',
  fontWeight: (theme: Theme) => theme.typography.fontWeightMedium
};

export const listItemStyle = {
  display: 'flex',
  color: (theme: Theme) => theme.palette.text.primary,
  alignItems: 'center',
  backgroundColor: (theme: Theme) => theme.palette.background.dark,
  paddingLeft: '0.5em',
  margin: '0 1em 1em 0',
  height: '2em',
  borderRadius: '0.4em',
  whiteSpace: 'nowrap',
  transition: '0.2s',
  '&:hover': {
    transition: '0.2s',
    backgroundColor: (theme: Theme) => theme.palette.divider,
  },
};

export const btnAdd = {
  height: '34px', 
  width: '250px', 
  fontSize: '12px'
}
  
export const error = {
  padding: '30px 0 30px 0',
  textAlign: 'center'
}