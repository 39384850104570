import type { DocumentCollectionsResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';
import { Paged } from 'src/types/pagination';

export const fetchCollections = (collections: DocumentCollectionsResource[], paged?: Paged, isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean) : {
  type: 'FETCH_COLLECTIONS',
  payload: {
    collections: DocumentCollectionsResource[],
    paged?: Paged,
    isEmptyList?: boolean,
    isLoading?: boolean,
    isAuthorize?: boolean;
  }
} => ({
  type: actionTypes.FETCH_COLLECTIONS,
  payload: {
    collections,
    paged,
    isLoading,
    isEmptyList,
    isAuthorize
  }
});

export const fetchCollection = (collection: DocumentCollectionsResource, isLoading?: boolean): {
  type: 'FETCH_COLLECTION',
  payload: {
    collection: DocumentCollectionsResource,
    isLoading?: boolean,
  }
} => ({
  type: actionTypes.FETCH_COLLECTION,
  payload: {
    collection,
    isLoading
  }
});

