import { ContentPublicationsResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';
import { Paged } from 'src/types/pagination';

type FetchContentPublicationsAction = {
    type: 'FETCH_CONTENT_PUBLICATIONS';
    payload: {
      contentPublications: ContentPublicationsResource[];
      paged?: Paged,
      isLoading?: boolean,
      isEmptyList?: boolean,
      isAuthorize?: boolean
    }
};

type FetchContentPublicationAction = {
    type: 'FETCH_CONTENT_PUBLICATION';
    payload: {
      contentPublication: ContentPublicationsResource,
      isLoading?: boolean
    }
};

type FetchDetailsContentPublicationAction = {
  type: 'FETCH_DETAILS_CONTENT_PUBLICATIONS';
  payload: {
    contentPublication: ContentPublicationsResource
  }
};

type Action = FetchContentPublicationsAction |
              FetchContentPublicationAction |
              FetchDetailsContentPublicationAction;

export interface ContentPublicationsReturnState {
  contentPublications?: ContentPublicationsResource[];
  contentPublication?: ContentPublicationsResource;
  paged?: Paged;
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
}

interface ContentPublicationsState {
  contentPublications: ContentPublicationsResource[];
  contentPublication: ContentPublicationsResource;
  paged: Paged;
  isLoading: boolean;
  isEmptyList: boolean;
  isAuthorize: boolean;
}

const reducer = (state: ContentPublicationsState, action: Action): ContentPublicationsReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_CONTENT_PUBLICATIONS: {
      const { contentPublications, paged, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        contentPublications,
        paged,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_CONTENT_PUBLICATION: {
      const { contentPublication, isLoading } = action.payload;
      return {
        ...state,
        contentPublication,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_CONTENT_PUBLICATIONS: {
      const { contentPublication } = action.payload;
      return {
        ...state,
        contentPublication
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
