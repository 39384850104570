import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ProductResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { ProductModel, tableRow} from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { tableCell } from 'src/styles/product';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ProductEnInfoProps {
  model: ProductModel;
  product: ProductResource;
}

const ProductEnInfo: FC<ProductEnInfoProps> = ({
  model,
  product
}) => {
  const { nameEn, descriptionEn } = product;
  const { NameEn, DescriptionEn } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Products and services" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              { NameEn.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { nameEn }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              { DescriptionEn.Label }
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary", whiteSpace: 'pre-wrap'}}
              >
                { descriptionEn }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ProductEnInfo.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductEnInfo;
