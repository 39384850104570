import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { HandleChange, PartnerResource, SetFieldValue,} from 'src/common/types';
import { Box, Grid } from '@mui/material';
import { 
  labelConfig, 
  CaijInput, 
  FormikErrors, 
  FormikTouched, 
  CaijImage,
  CaijInputLink, 
  PartnersModel, 
  CaijSwitch,
  CaijCard,
  ListType
} from 'src/common';
import UploadImage from 'src/components/image/UploadImage';
import Memo from 'src/components/memo';
import DocumentTypes from 'src/components/listInfo/checkList';

interface PartnersFormProps {
  model: PartnersModel;
  partner: PartnerResource;
  isCreate: boolean;
  errors: FormikErrors<PartnerResource>;
  touched: FormikTouched<PartnerResource>;
  onHandleBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  onHandleChange: HandleChange;
  onHandleChangeImageUrl:(name: string,imageUrl: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: SetFieldValue;
}

const PartnersForm: FC<PartnersFormProps> = ({
  model,
  partner,
  isCreate,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  onHandleChangeImageUrl,
  setFieldValue,
}) => {
  const { nameFr, nameEn, code, logoUrlFr, homeUrlFr, contentUrlFr, homeUrlEn, contentUrlEn, logoUrlEn, isShownOnHomePage, isPinnedOnTop, partnerDocumentTypes } = partner;
  const { Code, HomeUrlFr, HomeUrlEn, NameFr, NameEn, ContentUrlFr, ContentUrlEn, LogoUrlFr, LogoUrlEn, IsShownOnHomePage, IsPinnedOnTop, PartnerDocumentTypes } = model;
  return (
    <>
      <Grid item md={12} xs={12}>
        <CaijInput
            disabled={!isCreate}
            required
            name={Code.Name}
            id={Code.Name}
            value={code}
            helperText={touched.code && errors.code}
            error={Boolean(touched.code && errors.code)}
            label={Code.Label}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{maxLength: Code.MaxLength, 'data-testid': Code.Name }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
    </Grid>
    <Grid item md={6} xs={12}>
        <Box mb={2}>
          <CaijInput
              required
              name={NameFr.Name}
              id={NameFr.Name}
              margin="none"
              value={nameFr}
              helperText={touched.nameFr && errors.nameFr}
              error={Boolean(touched.nameFr && errors.nameFr)}
              label={NameFr.Label}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{ maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name}}
              onHandleBlur={onHandleBlur}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
          />
        </Box>
        { logoUrlFr && (
            <Box mb={2}>
              <CaijImage id='logoUrlFr' src={logoUrlFr} alt={logoUrlFr} />
            </Box>
          )
        }
        <Box mb={3}>
          <UploadImage
            imgLabel={LogoUrlFr.Label}
            maxLength={LogoUrlFr.MaxLength}
            resourceCode={model.ResourceCode} 
            btnText={logoUrlFr ? labelConfig.logoEditBtnFr : labelConfig.logoBtnFr}
            imgName={LogoUrlFr.Name}
            onHandleChangeImageUrl={onHandleChangeImageUrl}
            setFieldValue={setFieldValue}
          />
        </Box>
        <CaijInputLink
            name={HomeUrlFr.Name}
            id={HomeUrlFr.Name}
            value={homeUrlFr}
            label={HomeUrlFr.Label}
            helperText={touched.homeUrlFr && errors.homeUrlFr}
            error={Boolean(touched.homeUrlFr && errors.homeUrlFr)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: HomeUrlFr.MaxLength, 'data-testid': HomeUrlFr.Name }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
        <CaijInputLink
            name={ContentUrlFr.Name}
            id={ContentUrlFr.Name}
            value={contentUrlFr}
            label={ContentUrlFr.Label}
            helperText={touched.contentUrlFr && errors.contentUrlFr}
            error={Boolean(touched.contentUrlFr && errors.contentUrlFr)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: ContentUrlFr.MaxLength, 'data-testid': ContentUrlFr.Name }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
    </Grid>
    <Grid item md={6} xs={12}>
        <Box mb={2}>
          <CaijInput
              name={NameEn.Name}
              id={NameEn.Name}
              margin="none"
              required
              value={nameEn}
              label={NameEn.Label}
              helperText={touched.nameEn && errors.nameEn}
              error={Boolean(touched.nameEn && errors.nameEn)}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{ maxLength: NameEn.MaxLength, 'data-testid': NameEn.Name }}
              onHandleBlur={onHandleBlur}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
          />
        </Box>
        { logoUrlEn && (
            <Box mb={2}>
              <CaijImage id='logoUrlEn' src={logoUrlEn} alt={logoUrlEn} />
            </Box>
          )
        }
        <Box mb={3}>
          <UploadImage
            imgLabel={LogoUrlEn.Label}
            maxLength={LogoUrlFr.MaxLength}
            resourceCode={model.ResourceCode} 
            btnText={logoUrlEn ? labelConfig.logoEditBtnEn : labelConfig.logoBtnEn}
            imgName={LogoUrlEn.Name}
            onHandleChangeImageUrl={onHandleChangeImageUrl}
            setFieldValue={setFieldValue}
          />
        </Box>
        <CaijInputLink
            name={HomeUrlEn.Name}
            id={HomeUrlEn.Name}
            value={homeUrlEn}
            label={HomeUrlEn.Label}
            helperText={touched.homeUrlEn && errors.homeUrlEn}
            error={Boolean(touched.homeUrlEn && errors.homeUrlEn)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: HomeUrlEn.MaxLength, 'data-testid': HomeUrlEn.Name }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
        <CaijInputLink
            name={ContentUrlEn.Name}
            id={ContentUrlEn.Name}
            value={contentUrlEn}
            label={ContentUrlEn.Label}
            helperText={touched.contentUrlEn && errors.contentUrlEn}
            error={Boolean(touched.contentUrlEn && errors.contentUrlEn)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: ContentUrlEn.MaxLength, 'data-testid': ContentUrlEn.Name }}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
        />
    </Grid>
    <Grid item  md={12} xs={12}>
      <CaijCard title="Visibilité" allowedBackgroundColor={false}>
        <Box>
          <CaijSwitch          
            name={IsShownOnHomePage.Name}
            checked={isShownOnHomePage}
            value={isShownOnHomePage}
            inputProps={{ 'data-testid': IsShownOnHomePage.Name }}
            onHandleChangeSwitch={(event:ChangeEvent<HTMLInputElement>) => setFieldValue(IsShownOnHomePage.Name, event.target.checked)}
            label={IsShownOnHomePage.Label}
          />
        </Box>
        <Box>
          <CaijSwitch          
            name={IsPinnedOnTop.Name}
            checked={isPinnedOnTop}
            value={isPinnedOnTop}
            inputProps={{ 'data-testid': IsPinnedOnTop.Name }}
            onHandleChangeSwitch={(event:ChangeEvent<HTMLInputElement>) => setFieldValue(IsPinnedOnTop.Name, event.target.checked)}
            label={IsPinnedOnTop.Label}
          />
        </Box>
      </CaijCard>
    </Grid>
    <Grid item md={6} xs={12}>
      <Memo>
        <DocumentTypes
          listType={ListType.DocumentType}
          onHandleSelectedItems={l => {setFieldValue(PartnerDocumentTypes.Name, l)}}
          lists={partnerDocumentTypes}
        />
      </Memo>	
    </Grid>
    </>
  );
};

PartnersForm.propTypes = {
  partner: PropTypes.object.isRequired,
  isCreate: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  onHandleChangeImageUrl: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default PartnersForm;
