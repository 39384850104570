import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  PathModel
} from 'src/common';
import type {
  Error, 
  CreateResponse,
  LawDomainFieldResource,
  LawDomainFieldResourceForEdit,
  LawDomainFieldResourceForCreate,
  GetLawDomainFieldsAsyncOptionalParams
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';
import { PagedList } from 'src/types/pagination';

type TypeResourceEdit = LawDomainFieldResourceForCreate | LawDomainFieldResourceForEdit;

export default class LawDomainFieldModel extends AppModel 
{
  private static _instance: LawDomainFieldModel;
  readonly NameFr = new FormModel('nameFr','Nom',45);
  readonly NameEn = new FormModel('nameEn','Name',45);
  readonly ParentId = new FormModel('parentId', 'Parent');
  private _lawDomainChildHome: string;

  constructor(){
    super('content/lawdomainfield');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'LAW_DOMAIN_FIELD';
    this._headerTitle = 'Liste des domaines de droit';
    this._btnAddText= 'Ajouter un domaine de droit';
    this.Path.PathName = '/area-of-law';
    this._lawDomainChildHome = (new PathModel)._baseUrl + this.Path.PathName + '/children';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Domaines de droit',
      href: this.Path.Home,
      visible: true
    }
  }

  get LawDomainChildHome(){
    return this._lawDomainChildHome;
  }
  
  static getInstance(): LawDomainFieldModel {
    if (!LawDomainFieldModel._instance) {
      LawDomainFieldModel._instance = new LawDomainFieldModel();
    }
    return LawDomainFieldModel._instance;
  }

  getHeadCells(){ 
    return [
      {
        id: this.NameFr.Name, width: '40%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: this.NameEn.Name, width: '40%', numeric: false, disablePadding: false, label: this.NameEn.Label
      },
      {
        id: '', width: '20%'
      }
    ];
  };

  static getInitialValues(values: LawDomainFieldResource) : LawDomainFieldResource {
    return {
      ...values,
      nameEn : values.nameEn || '',
      nameFr : values.nameFr || ''
    }
  }

  async getLawDomainFields(params: GetLawDomainFieldsAsyncOptionalParams): Promise<PagedList<LawDomainFieldResource>> {
    let pagedList: PagedList<LawDomainFieldResource>;
    await axios.get<PagedList<LawDomainFieldResource>>(this.route, { params }).then(
      async response => {
        const { status, data } = response;
        if (status === Success) {
          pagedList = data;
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return pagedList;
  }

  async getLawDomainFieldById(id: string): Promise<LawDomainFieldResource> {
    let lawDomainField: LawDomainFieldResource;
    await axios.get<LawDomainFieldResource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            lawDomainField = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return lawDomainField;
  }

  async updateLawDomainField(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<LawDomainFieldResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Domaine de droit modifiée.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertLawDomainField(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<LawDomainFieldResourceForCreate, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Domaine de droit créée.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Domaine de droit supprimée.'
					});
					if(allowedRedirect)
          	this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }
}
