import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  MSG_KANTECH_DESACTIVATE_CONFLIT,
  Conflit
} from 'src/common';
import type {
  CreateResponse,
  DoorCardProfileDto,
  Error
} from 'src/common/types';
import AppModel from './App';
import axios from 'src/utils/axios';

export default class DoorAccessProfileModel extends AppModel 
{
  private static _instance: DoorAccessProfileModel;
  readonly Status: string = 'Statut';
  readonly Name: string = 'Nom';
  readonly Action: string = 'Action';
  
  constructor(){
    super('/Library/door-access-profile');
    this._headerTitle = 'Liste des Kantech - profils de cartes';
    this._resourceCode= 'LIBRARY_DOOR_ACCESS_PROFILE';
    this.Path.PathName = '/door-access-profile';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Kantech',
      href: this.Path.Home,
      visible: true
    }
  }

  setConflit(error: Error) {
		const { status } = error;
		switch(status){
			case Conflit:
				this.error.message = MSG_KANTECH_DESACTIVATE_CONFLIT; 
        break;
			default:
				this.error = error;
				break;
		}
	}

  get Conflit(){
		return this.error;
	}

  static getInstance(): DoorAccessProfileModel {
    if (!DoorAccessProfileModel._instance) {
      DoorAccessProfileModel._instance = new DoorAccessProfileModel();
    }
    return DoorAccessProfileModel._instance;
  }

  async getDoorCardProfiles(): Promise<DoorCardProfileDto[]> {
    let libraries: DoorCardProfileDto[];
    await axios.get<DoorCardProfileDto[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraries = data
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return libraries;
  }

  async insertDoorAccessProfile(submitData: DoorCardProfileDto): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<DoorCardProfileDto, CreateResponse>(this.route, submitData).then(
      async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = `${submitData.name} activé.`;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async deleteDoorAccessProfile(id: number, name: string): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = `${name} désactivé.`;
        }
      },
      async error => {
        this.error = error;
        this.setConflit(this.error);
        await this.handleError(this.error);
      },
    );
    return result;
  }
}
