import {
  Box,
  Container
} from '@mui/material';
import { FC, useState } from 'react';
import React, { useCallback, useEffect, useReducer } from 'react';
import {
  AccessModel,
  fetchSubscriptions,
  SelectedStatus,
  SubscriptionModel, useLocation,
  useNavigate,
  _subscription,
  root
} from 'src/common';
import Page from 'src/components/Page';
import printMessage from 'src/views/errors/MessageError';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  subscriptions: [],
  isLoading: false,
  isEmptyList: false,
  model:null
};

const SubscriptionListView: FC = () => {
  const access = new AccessModel(SubscriptionModel.getInstance().ResourceCode);
  const location = useLocation();
  const [deleted, setDeleted] = useState<number>(0);
  const [state, dispatch] = useReducer(_subscription, initialState);
  const navigate = useNavigate();
  
  const getSubscriptions = useCallback(async (model: SubscriptionModel) : Promise<void> => {
    model.PathName = location.pathname;
    const subscriptions = await model.getSubscriptions();
    if (!model.error) {
      dispatch(fetchSubscriptions(subscriptions,true,!Array.isArray(subscriptions) || subscriptions.length === 0, true, model));
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      if(access.canRead()){
        const { search, pathname} = location;
        const model = new SubscriptionModel();
        if(search){
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            let subscriptions = await model.getSubscriptions();
            if (!model.error) {
              const { Filters: { status } } = model;
              const statusTerm = model.getQueryParam(status).toLowerCase();
              if(statusTerm === SelectedStatus.Active){
                subscriptions = subscriptions.filter(({active}) => active);
              }else if(statusTerm === SelectedStatus.Inactive){
                subscriptions = subscriptions.filter(({active}) => !active);
              }
              dispatch(fetchSubscriptions(subscriptions,true,!Array.isArray(subscriptions) || subscriptions.length === 0, true, model));
            }
          }
        }else{
          await getSubscriptions(model);
        }
      }
    })();
  }, [deleted, location]);

  const deleteSubscription: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      const model = new SubscriptionModel();
      const response = await model.deleteSubscription(id);
      if (!model.error) {
        printMessage({ 
          status: response.status, 
          message: response.message 
        });
        setDeleted(deleted + 1);
      }
    }
  };

  const { subscriptions, isEmptyList, isLoading, model } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  model.Dialog.Header = "Supprimer l'abonnement";
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = "l'abonnement";

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            subscriptions={subscriptions}
            isEmptyList={isEmptyList}
            onDeleteSubscription={deleteSubscription}
            model={model}
            navigate={navigate}
          />
          </Box>
      </Container>
    </Page>
  );
};

export default SubscriptionListView;
