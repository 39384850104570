import type { CoveoSourceDto } from 'src/common/types';
import * as actionTypes from '../../ActionTypes';

export const fetchCoveoSources = (coveoSources: CoveoSourceDto[], isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_COVEO_SOURCES',
  payload: {
    coveoSources: CoveoSourceDto[],
    isLoading?: boolean,
    isEmptyList?: boolean
  }
} => ({
  type: actionTypes.FETCH_COVEO_SOURCES,
  payload: {
    coveoSources,
    isLoading,
    isEmptyList
  }
});

export const deleteCoveoSource = (id: number) : {
  type: 'DELETE_COVEO_SOURCE'
  payload: {
    id: number,
  }
} => ({
  type: actionTypes.DELETE_COVEO_SOURCE,
  payload: {
    id
  }
});
