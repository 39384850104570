import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  Conflit,
  MSG_PROFILE_DESACTIVATE_CONFLIT,
  MSG_LIBRARY_DESACTIVATE_CONFLIT
} from 'src/common';
import type {
  Error,
  CreateResponse,
  WfLibraryDto,
  WfProfileDto
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';

export default class SymphonyModel extends AppModel 
{
  private static _instance: SymphonyModel;

  constructor(){
    super('/symphony');
    this._resourceCode = 'WORKFLOWS';
    this._headerTitle = 'Liste des profils Symphony';
    this.Path.PathName = '/symphony';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Symphony',
      href: this.Path.Home,
      visible: true
    }
  }

  setConflit(error: Error, section: string) {
		const { status } = error;
		switch(status){
			case Conflit:
        if(section === 'profile')
				  this.error.message = MSG_PROFILE_DESACTIVATE_CONFLIT;
        else
          this.error.message = MSG_LIBRARY_DESACTIVATE_CONFLIT;
				break;
			default:
				this.error = error;
				break;
		}
	}

  get Conflit(){
		return this.error;
	}

  static getInstance(): SymphonyModel {
    if (!SymphonyModel._instance) {
      SymphonyModel._instance = new SymphonyModel();
    }
    return SymphonyModel._instance;
  }

//#region Library
  async getLibrariesList(): Promise<WfLibraryDto[]> {
    let libraries: WfLibraryDto[];
    await axios.get<WfLibraryDto[]>(`${this.route}/library`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraries = data
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return libraries;
  }

  async getActiveLibrariesList(): Promise<WfLibraryDto[]> {
    let libraries: WfLibraryDto[];
    await axios.get<WfLibraryDto[]>(`${this.route}/library/active`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraries = data
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return libraries;
  }

  async getLibraryById(id: string): Promise<WfLibraryDto> {
    let library: WfLibraryDto;
    await axios.get<WfLibraryDto>(`${this.route}/library/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            library = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return library;
  }

  async insertLibrary(submitData: WfLibraryDto): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<WfLibraryDto, CreateResponse>(`${this.route}/library`, submitData).then(
      async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = `${submitData.name} activé.`;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async deleteLibrary(name: string): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/library/${name}`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = `${name} désactivé.`;
        }
      },
      async error => {
        this.error = error;
        this.setConflit(this.error, 'library');
        await this.handleError(this.error);
      },
    );
    return result;
  }
//#endregion

//#region Profile
  async getProfilesList(): Promise<WfProfileDto[]> {
    let profiles: WfProfileDto[];
    await axios.get<WfProfileDto[]>(`${this.route}/profile`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            profiles = data
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return profiles;
  }

  async getActiveProfilesList(): Promise<WfProfileDto[]> {
    let profiles: WfProfileDto[];
    await axios.get<WfProfileDto[]>(`${this.route}/profile/active`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            profiles = data
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return profiles;
  }

  async getProfileById(id: string): Promise<WfProfileDto> {
    let profile: WfProfileDto;
    await axios.get<WfLibraryDto>(`${this.route}/profile/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            profile = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return profile;
  }

  async insertProfile(submitData: WfProfileDto): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<WfProfileDto, CreateResponse>(`${this.route}/profile`, submitData).then(
      async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = `${submitData.name} activé.`;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async deleteProfile(name: string): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/profile/${name}`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = `${name} désactivé.`;
        }
      },
      async error => {
        this.error = error;
        this.setConflit(this.error, 'profile');
        await this.handleError(this.Conflit);
      },
    );
    return result;
  }
}
//#endregion
