import { Sort } from "src/types/pagination";

export default class PaginationModel {
  applyFilters<T>(items: T[], properties: string[], query: string, filters: any[]): T[] {
    return items.filter((item) => {
      let matches = true;
      if (query) {
        let containsQuery = false;
        properties.forEach((property) => {
          if (item[property].toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        });
        if (!containsQuery) {
          matches = false;
        }
      }
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value && item[key] !== value) {
          matches = false;
        }
      });
      return matches;
    });
  }

  applyPagination<T>(items: T[], page: number, limit: number): T[] {
    return items.slice(page * limit, page * limit + limit);
  }

  convertTypeVal<T>(val : T) : [string, T]{
    if(typeof(val) === "string"){
      return ['string', val];
    }else{
      return ['others', val];
    }
  }

  compare<T>(valA: [string, T], valB: [string, T]){
    if(valA[0] === 'string' || valB[0] === 'string'){
      const val1 = (valA[1] as string);
      const val2 = (valB[1] as string);
      if(!val1) return -1;
      if(!val2) return 1;
      return val2 ? (val1)?.localeCompare(val2.trim(), "fr", { ignorePunctuation: true }) : -1;
    }else if (valB[1] < valA[1]) {
      return 1;
    }else if (valB[1] > valA[1]) {
      return -1;
    }
    return 0;
  }

  compareOneLevel<T>(a: T, b: T, orderBy: string): number {
    const valA = this.convertTypeVal(a[orderBy]);
    const valB = this.convertTypeVal(b[orderBy]);
    return this.compare<T>(valA, valB);
  }

  compareTwoLevel<T>(a: T, b: T, properties: string[]): number {
    const [user, property] = properties;
    const valB = this.convertTypeVal(b[user][property]);
    const valA = this.convertTypeVal(a[user][property]);
    return this.compare<T>(valA,valB);
  }

  descendingComparator<T>(a: T, b: T, orderBy: string): number {
    const properties = orderBy.split('.');
    if (properties.length === 2) {
      return this.compareTwoLevel(a, b, properties);
    }
    return this.compareOneLevel(a, b, orderBy);
  }

  getComparator<T>(order: string, orderBy: string): any {
    return order === 'asc'
      ? (a: T, b: T) => this.descendingComparator(a, b, orderBy)
      : (a: T, b: T) => -this.descendingComparator(a, b, orderBy);
  }

  applySort<T>(items: T[], sort: Sort): T[] {
    const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
    const comparator = this.getComparator(order, orderBy);
    const stabilizedThis = items.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const ord = comparator(a[0], b[0]);
      if (ord !== 0) return ord;
      // @ts-ignore
      return a[1] - b[1];
    });
    // @ts-ignore
    return stabilizedThis.map((el) => el[0]);
  }

  stableSort(array: any[], comparator: (arg0: any, arg1: any) => any): any[] {
    try {
      const stabilizedThis = array.map((el: any, index: any) => [el, index]);
      stabilizedThis.sort((a: number[], b: number[]) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el: any[]) => el[0]);
    } catch (err) {
      console.log(err);
    }
    return [];
  }
}
