import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CaijLocalizationProvider } from './LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs, { Dayjs } from 'dayjs';
import { FORMAT_TIME_PICKER, FormikErrors } from 'src/common';
import useDatePicker from 'src/functions/hooks/datePicker';

interface CaijTimePickerProps {
  name: string;
  value?: string;
  label?: string;
  inputProps?: {'data-testid'?: string};
  helperText?: FormikErrors<Date>;
  required?: boolean;
  onHandleTimeChange?: (date: string | null) => void;
}

const CaijTimePicker: FC<CaijTimePickerProps> = ({
  value,
  label,
  inputProps,
  helperText,
  required,
  onHandleTimeChange,
  ...rest
}) => {
  const { error, message, setMessage, onError} = useDatePicker(helperText);
  const [cleared, setCleared] = useState<boolean>(true);
  const [val, setVal] = React.useState<Dayjs | null>(dayjs(value));
 
  useEffect(() => {
    setVal(dayjs(value))
  },[value])

  useEffect(() => {
    if(helperText !== undefined)
      setMessage(String(helperText));
  },[helperText])

  const handleChange = (date: Dayjs): void => {
    if (date && date.isValid()) {
      onHandleTimeChange(`${date.hour()}:${date.minute()}`);
    } else {
      onHandleTimeChange('');
    }
  };

  return (
      <CaijLocalizationProvider useAdapterDayjs>
        <TimePicker
          {...rest}
          ampm={false}
          label={label}
          value={val && val?.isValid() ? val : null}
          format={FORMAT_TIME_PICKER}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock
          }}
          onError={(l) => onError(l)}
          sx={{width: '200px'}}
          slotProps={{
            field: { clearable: cleared, onClear: () => {
              setMessage('');
              setCleared(false);
              setVal(null);
            }},
            textField: {
              helperText: message,
              inputProps: inputProps,
              required: required,
              error,
              InputLabelProps: {shrink: true}
            },
          }}
          onChange={(e) => {
            handleChange(e)
            setVal(e);
            setCleared(true);
          }}
        />
      </CaijLocalizationProvider>
  );
}

CaijTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  inputProps: PropTypes.object,
  onHandleTimeChange: PropTypes.func.isRequired
};

export default CaijTimePicker;
