import { Box, ListItemText, MenuItem } from "@mui/material";
import { ChangeEvent, Component } from "react";
import { 
  CaijCheckbox, 
  CustomerModel, 
  ExpertListFilter, 
  NavigateFunction 
} from "src/common";
import { SearchFilter } from "src/common/types";

interface FilterItemProps {
  navigate: NavigateFunction;
  model: CustomerModel;
  filter: SearchFilter;
  idx: number;
  id: string;
  name: string;
  expertListFilter: ExpertListFilter;
}

export class FilterItem extends Component<FilterItemProps>{
  render(){
    const { navigate, model, filter, idx, id, name, expertListFilter } = this.props;
    return (
      <MenuItem dense>
        <Box width='100%' display='flex' alignItems='center'>
          <CaijCheckbox
            edge="end"
            checked={model.getSelectedFilterItem(expertListFilter).indexOf(id) !== -1}
            value={id}
            disableRipple
            onHandleChangeCheckBox={(e: ChangeEvent<HTMLInputElement>) => {
              if(expertListFilter === ExpertListFilter.Subscription){
                model.clearSelectedGroup();
                filter.group = "";
              }
              model.setSelectedFilterItem(expertListFilter,id);
              model.resetPageValue(filter);
              model.getConfigParameters(filter,idx,model.getSelectedFilterItemParamsValue(expertListFilter));
              navigate(model.getUrlEncode.apply(model,model.getParams()));
            }}
          />
            <ListItemText primary={name} color="primary" />
        </Box>
      </MenuItem>
    )
  }
}