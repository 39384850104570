import { UxpertiseDto } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchUxpertisesAction = {
  type: 'FETCH_UXPERTISES';
  payload: {
    uxpertises: UxpertiseDto[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

export type UxpertiseAction = FetchUxpertisesAction;

export interface UxpertiseReturnState {
  uxpertises: UxpertiseDto[];
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
}

interface UxpertiseState {
  uxpertises: UxpertiseDto[];
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
}

export default (state: UxpertiseState, action: UxpertiseAction): UxpertiseReturnState => {
    switch (action.type) {
      case actionTypes.FETCH_UXPERTISES: {
        const { uxpertises, isEmptyList, isLoading, isAuthorize } = action.payload;
        return {
          ...state,
          uxpertises,
          isLoading,
          isEmptyList,
          isAuthorize
        };
      }
      default: {
        return state;
      }
    }
}

