import { useState } from "react";
import { FormikErrors, INVALID_DATE } from "src/common";

export default function useDatePicker(helperText: FormikErrors<Date>) {
  const [message, setMessage] = useState<string | null>(helperText === undefined ? '' : String(helperText));
  const [error, setError] = useState<boolean>(false);
  const onError = (l: string, label?: string) => {
    if(l === 'invalidDate'){
      setMessage(INVALID_DATE);
      setError(true)
    }else if(l === 'required'){
      setMessage(`${label} est obligatoire`);
      setError(true);
    }else{
      setMessage('');
      setError(false)
    }
  }
  return {error, message, setMessage, onError}
}
