import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  CustomerRole,
  CustomerStatus,
  Conflit,
	ActionType,
	Operations,
	AccountExpiryMode,
	LanguageProfile,
	PendingChangeType,
	ExpertListFilter
} from 'src/common';
import type {
  CustomerResource,
  Error,
  CustomerResourceForCreate,
  CustomerResourceForEdit,
  CustomerAddressDto,
  CreateResponse,
  InvoiceInfo,
  GroupDto,
  EndorsedCustomerResource,
  LibraryCardTransaction,
  CustomerLibraryAccountInfoResource,
  CustomerCardTransactionsResource,
  CustomerNoteDto,
  StatsDto,
  GetResponse,
  CardResource,
  LawPracticeFieldDto,
	EmailStatusDto,
	DocumentOrderedDto,
	InvoiceFileDto,
	IHeader,
	GetCustomersAsyncOptionalParams,
	CustomerNoteForCreate,
	ImportCustomerResource,
	CustomerStatusDto
} from 'src/common/types';
import {$enum} from "ts-enum-util";
import { PagedList } from 'src/types/pagination';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { AxiosResponse } from 'axios';
import { FormModel } from '../Form';
import { HandlerModel } from '../handler';
import printMessage from 'src/views/errors/MessageError';
import { AccessGroup } from 'src/types/subscription';

type TypeResourceEdit = CustomerResourceForCreate | CustomerResourceForEdit;

type TypePreferreds = Map<number, boolean>;

export default class CustomerModel extends AppModel {
	private _selectedPreferreds: TypePreferreds;
	readonly Toggle = {
		yes: 'yes',
		no: 'no',
	};
	readonly ColName = {
		lastName: 'Nom',
		firstName: 'Prénom',
		email: 'Courriel',
		noCaij: 'ID CAIJ',
		subscription: 'Abonnement',
		extIdentifier: 'ID Externe',
		card: 'Carte',
		status: 'Statut',
		group: "Groupe d'accès"
	};
	private messages = {
		FIRSTNAME_REQUIRED: 'Le prénom est obligatoire',
		LASTNAME_REQUIRED: 'Le nom est obligatoire',
		EMAIL_REQUIRED: 'Le courriel est obligatoire',
		EXPIRATION_REQUIRED: "Une date d'expiration du compte est requise",
		EMAIL_ALREADY_EXISTS: 'Le courriel existe déjà pour cet abonnement',
		INVALID_SUBSCRIPTION: "L'abonnement est expiré et ne permet pas l'action demandé",
		INVALID_SUBSCRIPTION_GROUP: "Le groupe de l'abonnement est invalide",
		MSG_CUSTOMER_ENDORSER_INVALID:
			'Le cautionneur spécifié est invalide. Un cautionneur valide doit être actif, dans le même abonnement et ne pas être cautionné',
		MSG_CUSTOMER_ENDORSEMENT_NO_ACCESS: "L'abonnement ne permet pas de cautionnement",
		MSG_CUSTOMER_ENDORSED_LIMIT: 'La limite de cautionnement a été atteinte pour ce cautionneur',
		MSG_CUSTOMER_ENDORSED_ACTIVATE: "L'activation n'est pas permise. La limite de cautionnement a été atteinte"
	};
	private static _instance: CustomerModel;
	private handlerModel: HandlerModel<CustomerResource>;
	private _selectedAccountStatus: Set<string>;
	private _selectedSubscription: Set<string>;
	private _selectedGroup: Set<string>;
	private _logos: Map<number, string>;
	private _invoicePdf: Map<number, string>;

	readonly MemberFirstname = new FormModel('firstname', 'Prénom', 100);
	readonly MemberLastname = new FormModel('lastname', 'Nom de famille', 100);
	readonly MemberEmail = new FormModel('email', 'Courriel', 320);
	readonly MemberPhone = new FormModel('phone', 'Téléphone', 45);
	readonly MemberPhoneExt = new FormModel('phoneExt', 'Poste', 20);
	readonly MemberCellPhone = new FormModel('cellPhone', 'Téléphone cellulaire', 20);
	readonly MemberOrganization = new FormModel('organization', 'Organisation', 200);
	readonly MemberJob = new FormModel('job', 'Emploi', 200);
	readonly MemberNoCaij = new FormModel('noCaij', 'Numéro CAIJ', 15);
	readonly MemberExtIdentifier = new FormModel('extIdentifier', 'Identifiant externe', 320);
	readonly MemberCard = new FormModel('card', 'Numéro de la carte', 20);
	readonly MemberAdditionalInfo = new FormModel('additionalInfo', 'Information additionnelle', 200);
	readonly MemberExpiration = new FormModel('expiration', "Date d'expiration");
	readonly MemberAccessBetaCaij = new FormModel('accessBetaCaij', 'Accès bêta Espace CAIJ');
	readonly MemberBirthday = new FormModel('birthdate', 'Date de naissance');
	readonly MemberLanguage = new FormModel('language', 'Langue de préférence');
  
	constructor() {
		super('/customer');
		this.initialize();
	}

	private initialize() {
		this._resourceCode = 'CUSTOMER';
		this._headerTitle = 'Liste des membres';
		this._btnAddText = 'Ajouter un membre';
		this.Path.PathName = '/members/list-of-members';
		this.handlerModel = new HandlerModel<CustomerResource>();
		this._selectedAccountStatus = new Set<string>();
		this._selectedSubscription = new Set<string>();
		this._selectedGroup = new Set<string>();
		this._logos = new Map<number, string>();
		this._invoicePdf = new Map<number, string>();
	}

	get Section() {
		return {
			resourceCode: this._resourceCode,
			title: 'Liste des membres',
			href: this.Path.Home,
			visible: true,
		};
	}

	set SelectedPreferreds(selectedPreferreds: TypePreferreds) {
		this._selectedPreferreds = selectedPreferreds;
	}

	get SelectedPreferreds(): TypePreferreds {
		return this._selectedPreferreds;
	}

	set BadRequest(error: Error) {
		const { message } = error;
		if (Object.keys(this.messages).includes(message)) {
			this.error.message = this.messages[message];
		} else {
			this.error = error;
		}
	}

	get BadRequest() {
		return this.error;
	}

	setLogos(key: number, value: string) {
		this._logos.set(key, value);
	}

	setInvoicePdf(key: number, value: string) {
		this._invoicePdf.set(key, value);
	}

	getLogos(key: number) {
		return this._logos.get(key);
	}

	//#region Expert List Filter
	setSelectedFilterItem(expertListFilter: ExpertListFilter, value: string, allowedDeleted: boolean = true) {
		switch(expertListFilter){
			case ExpertListFilter.Subscription:
        if (allowedDeleted)
					!this._selectedSubscription.has(value) ? this._selectedSubscription.add(value) : this._selectedSubscription.delete(value);
				else if (!this._selectedSubscription.has(value))
				   this._selectedSubscription.add(value);
				break;
			case ExpertListFilter.Group:
        if (allowedDeleted)
					!this._selectedGroup.has(value) ? this._selectedGroup.add(value) : this._selectedGroup.delete(value);
				else if (!this._selectedGroup.has(value))
					this._selectedGroup.add(value);
				break;
			case ExpertListFilter.Status:
				if (allowedDeleted)
					!this._selectedAccountStatus.has(value) ? this._selectedAccountStatus.add(value) : this._selectedAccountStatus.delete(value);
				else if (!this._selectedAccountStatus.has(value))
					this._selectedAccountStatus.add(value);
				break;
		}
	}

	getSelectedFilterItemParamsValue(expertListFilter: ExpertListFilter){
		switch(expertListFilter){
			case ExpertListFilter.Subscription:
				return Array.from(this._selectedSubscription).join();
			case ExpertListFilter.Group:
        return Array.from(this._selectedGroup).join();
			case ExpertListFilter.Status:
				return Array.from(this._selectedAccountStatus).join();
		}
	}

	getSelectedFilterItem(expertListFilter: ExpertListFilter){
		switch(expertListFilter){
			case ExpertListFilter.Subscription:
				return Array.from(this._selectedSubscription);
			case ExpertListFilter.Group:
        return Array.from(this._selectedGroup);
			case ExpertListFilter.Status:
				return Array.from(this._selectedAccountStatus);
		}
	}

	clearSelectedGroup() {
		this._selectedGroup.clear();
	}

	clearSelectedSubscription() {
		this._selectedSubscription.clear();
	}

	clearSelectedAccountStatus() {
		this._selectedAccountStatus.clear();
	}

	clearSelectedFilterItem() {
		this._selectedSubscription.clear();
		this._selectedAccountStatus.clear();
		this._selectedGroup.clear();
	}

	reSetSelectedFilterItem(status: string, subscriptionId: string, groupId: string, groups: AccessGroup) {
		if (this._selectedAccountStatus.size === 0 && status) {
			this._selectedAccountStatus = new Set(status.split(','));
		}else if(this._selectedAccountStatus.size > 0 && !status){
			this._selectedAccountStatus.clear();
		}
		//Subscription
		if (this._selectedSubscription.size === 0 && subscriptionId) {
			this._selectedSubscription = new Set(subscriptionId.split(','));
		}else if(this._selectedSubscription.size > 0 && !subscriptionId){
			this._selectedSubscription.clear();
		}
		//Subscription group
		if (this._selectedGroup.size === 0 && groupId) {
			const Ids = groupId.split(',');
			const groupVals = Object.values(groups);
			const newSelectedGroup = new Set<string>();
			for(let i = 0 ; i < Ids.length; i++){
					const val = groupVals.find((val) => val.includes(Ids[i]));
					if(val && !newSelectedGroup.has(val)){
						newSelectedGroup.add(val);
					}
			}
			this._selectedGroup = newSelectedGroup;
		}else if(this._selectedGroup.size > 0 && !groupId){
			this._selectedGroup.clear();
		}
	}
//#endregion Expert List Filter

	get Note() { 
		return new FormModel('note', 'Note à ajouter');
	}

	get Pin() { 
		return new FormModel('pin', 'Épingler');
	}

	static getStatusByVal = (value: string) => {
		return $enum(CustomerStatus).getKeyOrDefault(value);
	};

	static getStatusByKey = (key: string) => {
		return $enum(CustomerStatus).getValueOrDefault(key);
	};

	static getOperationByVal = (value: string) => {
		return $enum(Operations).getKeyOrDefault(value);
	};

	static getAccountExpiryModeByKey = (key: string) => {
		return $enum(AccountExpiryMode).getValueOrDefault(key);
	};

	static getAccountExpiryModeByVal = (value: string) => {
		return $enum(AccountExpiryMode).getKeyOrDefault(value);
	};

	static getLanguageProfileByKey = (key: string) => {
		return $enum(LanguageProfile).getValueOrDefault(key);
	};

	static getPendingChangeTypeByVal(value: number){
    return $enum(PendingChangeType).getKeyOrDefault(value);
  }
	
	static getInstance(): CustomerModel {
		if (!CustomerModel._instance) {
			CustomerModel._instance = new CustomerModel();
		}
		return CustomerModel._instance;
	}

	static getInitialValues(values: CustomerResource): CustomerResource {
		return {
			...values,
			phone: values.phone || '',
			phoneExt: values.phoneExt || '',
			cellPhone: values.cellPhone || '',
			birthdate: values.birthdate || null,
			organization: values.organization || '',
			job: values.job || '',
			extIdentifier: values.extIdentifier || '',
			role: values.role || CustomerRole.User,
			status: values.status || this.getStatusByVal(CustomerStatus.ActiveOnLogin),
			card: values.card || '',
			subscriptionGroup: values.subscriptionGroup || null,
			wfLibrary: values.wfLibrary || '',
			wfProfile: values.wfProfile || '',
		};
	}

	getHeadCells() {
		const headers: IHeader[] = [
			{ colName: this.ColName.lastName, width: '10%', type: 'search', property: 'lastName' },
			{ colName: this.ColName.firstName, width: '10%', type: 'search', property: 'firstName' },
			{ colName: this.ColName.email, width: '10%', type: 'search', property: 'email' },
			{ colName: this.ColName.noCaij, width: '10%', type: 'search', property: 'noCaij' },
			{ colName: this.ColName.subscription, width: '20%', type: 'list' },
			{ colName: this.ColName.group, width: '20%', type: 'list' },
			{ colName: this.ColName.extIdentifier, width: '10%', type: 'search', property: 'extIdentifier'},
			{ colName: this.ColName.card, width: '5%', type: 'search', property: 'card' },
			{ colName: this.ColName.status,width: '5%', type: 'list' }
		];
		return headers;
	}

	getStatsHeadCells(){
		return [
			{
				id: '', width: '5%', numeric: false, disablePadding: false, label: ''
			},
			{
				id: 'subscriptionName', width: '25%', numeric: false, disablePadding: false, label: 'Abonnement'
			},
			{
				id: '', width: '20%', numeric: false, disablePadding: false, label: 'Groupe d\'accès'
			},
			{
				id: 'active', width: '10%', numeric: false, disablePadding: false, label: 'Actif'
			},
			{
				id: 'activeOnLogin', width: '20%', numeric: false, disablePadding: false, label: "En attente d'activation"
			},
			{
				id: 'blocked', width: '10%', numeric: false, disablePadding: false, label: 'Suspendu'
			},
			{
				id: '', width: '10%', numeric: true, disablePadding: false, label: 'Total'
			}
		];
	}
	
	async getCustomers(params: GetCustomersAsyncOptionalParams): Promise<PagedList<CustomerResource>> {
		let pagedList: PagedList<CustomerResource>;

		await axios.get<PagedList<CustomerResource>>(this.route, { params }).then(
			async (response) => {
				const { status, data } = response;
				if (status === Success) {
					pagedList = data;
				}
			},
			async (error) => {
				this.error = error;
				if (!this.skipHandleError) {
					await this.handleError(this.error);
				}
			},
		);
		return pagedList;
	}

	// TODO: ajouter argument adresse
	async requestCard(id: number): Promise<Error> {
		let result: Error;
		// TODO: remplacer {} par adresse une foit que l'argument est ajouter
		await axios.post<any, Error>(`${this.route}/${id}/card/request`, {}).then(
			async (response) => {
				const { status } = response;
				try {
					if (status === Success) {
						result = response;
						result.message = 'Demande de carte créée.';
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					if (!this.skipHandleError) {
						await this.handleError(this.error);
					}
				}
			},
			async (error) => {
				this.error = error;
				if (!this.skipHandleError) {
					await this.handleError(this.error);
				}
			},
		);
		return result;
	}

	async importCustomer(file: FormData, uploadParams: ImportCustomerResource): Promise<string[]> {
		let results: string[];
		for (let key of Object.keys(uploadParams)) {
			file.append(key, uploadParams[key]);
		}
		await axios({
			method: 'post',
			url: `${this.route}/import`,
			data: file,
			headers: { 'Content-Type': 'multipart/form-data' },
		}).then(
			(response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						results = data;
						this.errorStatus = status;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					this.handleError(this.error);
				}
			},
			(error) => {
				this.error = error;
				this.handleError(this.error);
			},
		);
		return results;
	}

	async getCustomerById(id: number, since?: Date): Promise<CustomerResource> {
		if (since) {
			return await this.handlerModel.getById(this.route, id, { since });
		} else {
			return await this.handlerModel.getById(this.route, id);
		}
	}

	async getApiCustomerCardTransactionsById(id: string): Promise<CustomerCardTransactionsResource> {
		let customerCardTransaction: CustomerCardTransactionsResource;
		await axios.get<CustomerCardTransactionsResource>(`${this.route}/${+id}/cardtransactions`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						customerCardTransaction = {
							prints: data.prints,
							transactions: data.transactions,
						};
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return customerCardTransaction;
	}

	async getApiCustomerInvoicesById(id: number): Promise<InvoiceInfo[]> {
		let customerInvoices: InvoiceInfo[];
		await axios.get<InvoiceInfo[]>(`${this.route}/${id}/invoices`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						customerInvoices = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return customerInvoices;
	}

	async getApiCustomerInvoice(id: number, transactionId: number): Promise<InvoiceFileDto> {
		let customerInvoice: InvoiceFileDto;
		await axios.get<InvoiceFileDto>(`${this.route}/${id}/invoice/${transactionId}`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						customerInvoice = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return customerInvoice;
	}

	async getApiCustomerOrdersById(id: number): Promise<DocumentOrderedDto[]> {
		let customerOrders: DocumentOrderedDto[];
		await axios.get<DocumentOrderedDto[]>(`${this.route}/${id}/orders`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						customerOrders = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return customerOrders;
	}

	async getApiCustomerOrderById(id: number, orderId: string): Promise<string> {
		let orderDocument;
		await axios.get<string>(`${this.route}/${id}/order/${orderId}`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						orderDocument = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return orderDocument;
	}

	async getApiCustomerLibraryTransactionsById(id: string): Promise<CustomerLibraryAccountInfoResource> {
		let customerLibraryAccount: CustomerLibraryAccountInfoResource;
		await axios.get<CustomerLibraryAccountInfoResource>(`${this.route}/${+id}/libraryaccount`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						customerLibraryAccount = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return customerLibraryAccount;
	}

	//#region Picture
	async getCustomerPicture(id: number): Promise<string> {
		let result: string;
		await axios.get<string>(`${this.route}/${id}/picture`).then(
			async (response) => {
				const { status, data } = response;
				if (status === Success) {
					result = data;
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async deleteCustomerPicture(id: number): Promise<Error> {
		let result: Error;
		await axios.delete<any, Error>(`${this.route}/${id}/picture`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Photo supprimée.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}
	//#endregion

	//#region Customer Notes
	async getNotes(id: number): Promise<CustomerNoteDto[]> {
		let notes: CustomerNoteDto[];
		await axios.get<CustomerNoteDto[]>(`${this.route}/${+id}/note`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						notes = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return notes;
	}
	
	async insertCustomerNote(id: number,customerNoteParams: CustomerNoteForCreate): Promise<Error> {
		let result: Error;
		await axios.post<string, Error>(`${this.route}/${id}/note`, customerNoteParams).then(
			(response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Note créée.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async updateCustomerNote(id: number,customerNote: CustomerNoteDto): Promise<Error> {
		let result: Error;
		await axios.put<string, Error>(`${this.route}/${id}/note`, customerNote).then(
			(response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Note modifié.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async deleteCustomerNote(custId: number, noteId: number): Promise<Error> {
		let result: Error;
		await axios.delete<any, Error>(`${this.route}/${custId}/note/${noteId}`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Note supprimée.';
				}
			},
			async (error) => {
				this.error = error;
				this.handleError(this.error);
			},
		);
		return result;
	}
	//#endregion Customer Note

	async updateCustomer(submitData: TypeResourceEdit): Promise<Error> {
		let result: Error;
		await axios.put<CustomerResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Membre modifié.';
				}
			},
			async (error) => {
				this.error = error;
				this.BadRequest = this.error;
				await this.handleError(this.BadRequest);
			},
		);
		return result;
	}

	async modifyCustomerEndorsed(id: number, endorserId: number): Promise<Error> {
		let result: Error;
		await axios.put<CustomerResourceForEdit, Error>(`${this.route}/${id}/endorser`, endorserId).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Membre modifié.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async insertCustomer(submitData: TypeResourceEdit): Promise<CreateResponse> {
		let result: CreateResponse;
		await axios.post<CustomerResourceForCreate, CreateResponse>(this.route, submitData).then(
			async (response) => {
				const { status } = response;
				if (status === Created) {
					result = response;
					result.message = 'Membre créé.';
				}
			},
			async (error) => {
				this.error = error;
				this.BadRequest = this.error;
				await this.handleError(this.BadRequest);
			},
		);
		return result;
	}

	async insertCustomerTransaction(id: number, submitData: LibraryCardTransaction): Promise<Error> {
		let result: Error;
		await axios.post<LibraryCardTransaction, Error>(`${this.route}/${id}/cardtransaction`, submitData).then(
			async (response) => {
				const { status } = response;
				try {
					if (status === Success) {
						result = response;
						result.message = 'Transaction créée.';
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async delete(id: string|number) : Promise<boolean> {
		await axios.delete<any, Error>(`${this.route}/${id}`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					this.redirect(this.Path.Home, {status: status,message: 'Membre supprimé.'});
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return null;
	}
  //CustomerStatusDto
	async modifyCustomerStatus(id: number, data: CustomerStatusDto, message: string) {
		let success: boolean = false;
		await axios({
			method: 'post',
			url: `${this.route}/${id}/status`,
			data,
			headers: { 'Content-Type': 'application/json' },
		}).then((response: any) => {
			  const { status } = response;
				if (status === Success) {
					printMessage({status,message});
					success = true;
				}
			},
			async (error) => {
				this.error = error;
				this.BadRequest = this.error;
				await this.handleError(this.error);
			},
		);
		return success;
	}

	async sendAccountActivationEmail(id: number) : Promise<void> {
		await axios.post<void, Error>(`${this.route}/${id}/status/send`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					printMessage({status,message: "Courriel d'activation de compte envoyé."});
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
	}

	async getResetPassword(id: string): Promise<string> {
		let password: string;
		await axios.post<any, AxiosResponse<string>>(`${this.route}/${+id}/passwordreset`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						password = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return password;
	}

	async getResetPIN(id: number, pin: string): Promise<string> {
		let result: string;
		await axios.post<any, AxiosResponse<string>>(`${this.route}/${+id}/library/pin`, pin).then(
			async (response) => {
				const { status, data } = response;
				if (status === Success) {
					result = data;
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async unassignCard(customerId: number, note: string): Promise<Error> {
		let result: Error = { message: '' };
		await axios({
			method: 'delete',
			url: `${this.route}/${+customerId}/card`,
			data: note,
			headers: { 'Content-Type': 'application/json' },
		}).then(
			(response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						result = response;
						result.message = 'Carte supprimée.';
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					this.handleError(this.error);
				}
			},
			(error) => {
				this.error = error;
				this.handleError(this.error);
			},
		);
		return result;
	}

	async assignCard(customerId: number, card: string): Promise<Error> {
		let result: Error;
		await axios.post<CardResource, Error>(`${this.route}/${+customerId}/card`, { card, useNow: true }).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Carte assignée.';
				} 
			},
			async (error) => {
				this.error = error;
				this.handleError(this.error);
			},
		);
		return result;
	}

	async cancelCardRequest(customerId: number): Promise<Error> {
		let result: Error;
		await axios.delete<void, Error>(`${this.route}/${+customerId}/card/request`).then(
			async (response) => {
				const { status } = response;
				try {
					if (status === Success) {
						result = response;
						result.message = 'Demade de carte annulée.';
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				if (error.status !== Conflit) {
					await this.handleError(this.error);
				}
			},
		);
		return result;
	}

	async synchronize(customerId: number): Promise<Error> {
		let result: Error;
		await axios.post<void, Error>(`${this.route}/${customerId}/sync`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Compte synchronisé.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async createUxpertiseAccount(id: number): Promise<Error> {
		let result: Error;
		await axios.post<void, Error>(`${this.route}/${id}/learning`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Compte formation créé.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	//#region Pending Change Request
	async resendCustomerRequest(id: number, requestId: string): Promise<Error> {
		let result: Error;
		await axios.post<void, Error>(`${this.route}/${id}/request/${requestId}/resend`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Message envoyé.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async validateCustomerRequest(requestId: string): Promise<Error> {
		let result: Error;
		await axios.post<void, Error>(`${this.route}/request/${requestId}/validate`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Demande de modification du courriel approuvée.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async deleteCustomerRequest(id: number, requestId: string): Promise<Error> {
		let result: Error;
		await axios.delete<void, Error>(`${this.route}/${id}/request/${requestId}`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Demande de modification du courriel supprimée.';
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	//#endregion Pending Change Request

	saveRecords(
		submitData: TypeResourceEdit,
		subscriptionGroup: GroupDto,
		endorsedBy: EndorsedCustomerResource,
		lawPracticeFields: LawPracticeFieldDto[],
	): void {
		submitData.lawDomains = Array.isArray(lawPracticeFields)
			? lawPracticeFields.map((lawPracticeField: LawPracticeFieldDto) => lawPracticeField.id)
			: [];
		submitData.subscriptionGroupId = subscriptionGroup?.id;
		submitData.endorsedBy = endorsedBy?.id;
	}

	static getCustomerAddress(address: CustomerAddressDto) {
		const addr: string[] = [];
		if (address.line1) {
			addr.push(address.line1);
		}
		if (address.line2) {
			addr.push(address.line2);
		}
		if (address.city || address.state || address.zip) {
			let addr2 = address.city ? `${address.city}, ` : '';
			addr2 += address.state ? `${address.state}, ` : '';
			addr2 += address.zip;
			addr.push(addr2);
		}
		if (address.country) {
			addr.push(address.country);
		}
		return addr;
	}

	// #region Statistic
	async getActiveCustomersBySubscription(): Promise<StatsDto[]> {
		let stats: StatsDto[];
		await axios.get<StatsDto[]>(`${this.route}/stats/subscription`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						stats = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
				return;
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return stats;
	}

	async getActiveCustomersByGroup(): Promise<StatsDto[]> {
		let stats: StatsDto[];
		await axios.get<StatsDto[]>(`${this.route}/stats/groups`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						stats = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
				return;
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return stats;
	}

	async getActiveCustomer(): Promise<GetResponse> {
		let result: GetResponse;
		await axios.get<void, GetResponse>(`${this.route}/stats/active`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						result = response;
						result.message = 'Membres actifs';
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async getCardRequestPendingCustomer(): Promise<GetResponse> {
		let result: GetResponse;
		await axios.get<void, GetResponse>(`${this.route}/stats/card-request`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						result = response;
						result.message = 'Demande de carte CAIJ en attente';
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}

	async getDelayAccountCustomer(): Promise<GetResponse> {
		let result: GetResponse;
		await axios.get<void, GetResponse>(`${this.route}/stats/expiring`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						result = response;
						result.message = 'Compte de membres arrivant à échéance';
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return result;
	}
	// #endregion

	async getCustomerMailStatus(id: number): Promise<EmailStatusDto[]> {
		let emailStatus: EmailStatusDto[];
		await axios.get<EmailStatusDto[]>(`${this.route}/${id}/mailStatus`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						emailStatus = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return emailStatus;
	}
}
