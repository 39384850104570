import { Box, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { CustomerModel } from "src/common";
import { Theme } from "src/theme";

interface CarteCaijProps {
  children: ReactNode;
}

const CarteCaij: FC<CarteCaijProps> = ({children}) => {
  const carteCaijRank = process.env.REACT_APP_CARTE_CAIJ_RANK;
  return (
    <>
      { carteCaijRank && (
          <Box flexDirection='column' sx={{backgroundColor: (theme: Theme) => theme.palette.warning.main, padding:'15px', borderRadius:'3px'}}>
            <Typography variant="body2" color='#ffffff'>
              Le numéro de carte doit être entre { carteCaijRank }
            </Typography>
          </Box>
        ) 
      }
      <Box mt={2}>{children}</Box>
    </>
  )
}

export default CarteCaij;
