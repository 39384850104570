import StorageItemModel from './StorageItem';

export default class LocalStorageModel {
    private _localStorageSupported: boolean;

    constructor() {
      this._localStorageSupported = typeof window.localStorage !== 'undefined' && window.localStorage != null;
    }

    add(key: string, item: string): void {
      if (this._localStorageSupported) {
        localStorage.setItem(key, item);
      }
    }

    getAllItems(): Array<StorageItemModel> {
      const list = new Array<StorageItemModel>();
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
        list.push(new StorageItemModel({
          key,
          value
        }));
      }
      return list;
    }

    getAllValues(): Array<any> {
      const list = new Array<any>();

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
        list.push(value);
      }
      return list;
    }

    get(key: string): string {
      if (this._localStorageSupported) {
        const item = localStorage.getItem(key);
        return item;
      }
      return null;
    }

    remove(key: string): void {
      if (this._localStorageSupported) {
        localStorage.removeItem(key);
      }
    }

    clear(): void {
      if (this._localStorageSupported) {
        localStorage.clear();
      }
    }

    hasItem(key: string): boolean {
      return localStorage.getItem(key) !== null;
    }
}
