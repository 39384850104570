import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { Resource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { labelConfig, tableRow } from 'src/common';
import { tableCell } from 'src/styles/resource';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface ResourceInfoProps {
  resource: Resource;
}

const ResourceInfo: FC<ResourceInfoProps> = ({
  resource
}) => {
  return (
    <MyCard>
      <CardHeaderDetail title="Ressource" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>
              { labelConfig.code}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { resource.code }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={tableCell}>
              { labelConfig.nameFr}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { resource.name }
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>
              {labelConfig.description}
            </TableCell>
            <TableCell>
              <Typography
                variant="body2"
                sx={{color:"text.secondary"}}
              >
                { resource.description }
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

ResourceInfo.propTypes = {
  resource: PropTypes.object.isRequired
};

export default ResourceInfo;
