import { useEffect, useReducer } from "react";
import { 
  DocCollectionModel, 
  fetchDocCollections, 
  Forbidden, 
  Unauthorized, 
  _docCollection
} from "src/common";

export function useCollections() {
  const initialState = {
    docCollections: [],
    isEmptyList: true,
    isLoading: true,
    isAuthorize: false,
  };
  const [state, dispatch] = useReducer(_docCollection, initialState);
  useEffect(() => {
    let isCurrent = true;
    const initialise = async () => {
      const model = new DocCollectionModel();
      model.skipHandleError = true;
      const collections = isCurrent && await model.getDocCollections();
      const { error } = model;
      if (error) {
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchDocCollections(collections, false, true, false));
        }else{
          dispatch(fetchDocCollections(collections, false, true, true));
        }
      } else {
        dispatch(fetchDocCollections(collections, false, !Array.isArray(collections) || collections.length === 0, true));  
      }
    }
    (async () => await initialise())();
    return () => { isCurrent = false; }
  },[]);
  const { docCollections, isEmptyList, isLoading, isAuthorize } = state;
  return { 
    docCollections, 
    allChecked: !isEmptyList && !isLoading && isAuthorize  
  };
};
