import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { LibraryResource } from 'src/common/types';
import AddressFrInfo from './AddressFrInfo';
import GoogleMapInfo from './GoogleMapInfo';
import { LibraryModel } from 'src/common';

interface AddressesProps {
  model: LibraryModel;
  library?: LibraryResource;
}

const Addresses: FC<AddressesProps> = ({
  model,
  library
}) => (
  <Grid container spacing={3}>
    <Grid item lg={6} md={6} xl={6} xs={12}>
      <AddressFrInfo model={model} library={library} />
    </Grid>
    <Grid item lg={6} md={6} xl={6} xs={12}>
      <GoogleMapInfo library={library} />
    </Grid>
  </Grid>
);

Addresses.propTypes = {
  library: PropTypes.any
};

export default Addresses;
