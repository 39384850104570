import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import {
  CaijInput, 
  CaijTextarea, 
  FormikErrors, 
  FormikTouched
} from 'src/common';
import type { Resource } from 'src/common/types';
import ResourceModel from 'src/model/employee/Resource';

interface ResourceFormProps {
  model: ResourceModel;
  resource: Resource;
  errors: FormikErrors<Resource>;
  touched: FormikTouched<Resource>;
  handleBlur: (e: any) => void;
  handleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ResourceForm: FC<ResourceFormProps> = ({
  model,
  resource,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue
}) => {
  const { code, name, description } = resource;
  const { Code, Name, Description } = model;
  return (
    <>
        <Grid item md={12} xs={12} >
            <CaijInput
                name={Code.Name}
                required
                value={code}
                helperText={touched.code && errors.code}
                error={Boolean(touched.code && errors.code)}
                label={Code.Label}
                InputLabelProps={{ shrink: true, required: true }}
                inputAttr={{maxLength: Code.MaxLength, 'data-testid': Code.Name}}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
            />
            <CaijInput
                name={Name.Name}
                required
                value={name}
                label={Name.Label}
                helperText={touched.name && errors.name}
                error={Boolean(touched.name && errors.name)}
                InputLabelProps={{ shrink: true, required: true }}
                inputAttr={{maxLength: Name.MaxLength, 'data-testid': Name.Name}}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
            />
            <CaijTextarea
                name={Description.Name}
                rows="4"
                value={description}
                label={Description.Label}
                InputLabelProps={{ shrink: true }}
                inputProps={{'data-testid': Description.Name}}
                onHandleBlur={handleBlur}
                onHandleChange={handleChange}
                setFieldValue={setFieldValue}
            />
        </Grid>
    </>
  );
};

ResourceForm.propTypes = {
  resource: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default ResourceForm;
