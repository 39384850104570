import type { EmployeesResource, TabsEmployee } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchEmployeesAction = {
  type: 'FETCH_EMPLOYEES';
  payload: {
    employees: EmployeesResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
    isAuthorize?: boolean;
  };
};

type FetchEmployeeAction = {
  type: 'FETCH_EMPLOYEE';
  payload: {
    employee: EmployeesResource;
    isLoading?: boolean;
  };
};

type FetchDetailsEmployeeAction = {
  type: 'FETCH_DETAILS_EMPLOYEE';
  payload: {
    employee: EmployeesResource;
    tabs?: TabsEmployee[];
  };
};

type DeleteEmployeeAction = {
  type: 'DELETE_EMPLOYEE';
  payload: {
    id: number
  };
};

type Action = FetchEmployeesAction |
              FetchEmployeeAction |
              FetchDetailsEmployeeAction |
              DeleteEmployeeAction;

export interface EmployeeReturnState {
  employees?: EmployeesResource[];
  employee?: EmployeesResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  tabs?: TabsEmployee[];
}

interface EmployeeState {
  employees: EmployeesResource[];
  employee: EmployeesResource;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  tabs: TabsEmployee[];
}

const reducer = (state: EmployeeState, action: Action): EmployeeReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEES: {
      const { employees, isEmptyList, isLoading, isAuthorize } = action.payload;
      return {
        ...state,
        employees,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_EMPLOYEE: {
      const { employee, isLoading } = action.payload;
      return {
        ...state,
        employee,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_EMPLOYEE: {
      const { employee, tabs } = action.payload;
      return {
        ...state,
        employee,
        tabs
      };
    }
    case actionTypes.DELETE_EMPLOYEE: {
      return {
        ...state,
        employees: [...state.employees].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
