import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  fetchCustomers,
  _customer,
  ITEMS_PER_PAGE,
  CustomerModel,
  _subscription,
  useNavigate,
  AccessModel,
  root,
  Mode
} from 'src/common';
import type { CustomerResource, GetCustomersAsyncOptionalParams, Sort } from 'src/common/types';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import Results from './Results';
import { StyledList } from 'src/components/styled';
import { useCustomerList } from 'src/functions/hooks/customer';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  customers: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false,
  model: null
};

const CustomerListView: FC = () => {
  const customerModel = CustomerModel.getInstance();
  const access = new AccessModel(customerModel.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_customer, initialState);
  const navigate = useNavigate();
  const { subscriptions, groups, resetGroups } = useCustomerList();
  
  const getCustomerPictures = useCallback(async (model:CustomerModel, customers: CustomerResource[]) : Promise<void> => {
    for(var customer of customers){
      if(customer.logoUrl){
        const newLogo = await model.getCustomerPicture(customer.id);
        if(newLogo){
          const extension = customer.logoUrl?.split('.').pop();
          model.setLogos(customer.id, `data:image/${extension};base64,${newLogo}`);
        }
      }
    }
  },[]);
  
  const getCustomers = useCallback(async (model: CustomerModel, params: GetCustomersAsyncOptionalParams) : Promise<void> => {
      const { search, pathname } = location;
      model.PathName = pathname + search;
      const results = await model.getCustomers(params);
      if (!model.error) {
        await getCustomerPictures(model, results.list);
        dispatch(fetchCustomers(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0 , true, model));
      }
  },[location]);

  useEffect(() => {
    const initialise = async () => {
      if(access.canRead()){
        const { pathname, search } = location;
        let params: GetCustomersAsyncOptionalParams;
        params = {pageNumber: 1, pageSize: ITEMS_PER_PAGE};
        if(search){
          const model = new CustomerModel;
          model.PathName = pathname + search;
          if(await model.setQueryParams(search, model, navigate)){
            const { Filters: { 
              page, sort, firstName, email, lastName, status, subscription, query, delayAccountCustomer, card, extIdentifier, noCaij, group
            }} = model; 
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1,
              sortOptions: model.getQueryParam(sort) as Sort || 'asc',
              status: model.getQueryParam(status),
              searchTerm: model.getQueryParam(query),
              delayAccountCustomer: Boolean(model.getQueryParam(delayAccountCustomer)),
              subscription: model.getQueryParam(subscription),
              firstname: model.getQueryParam(firstName),
              lastname: model.getQueryParam(lastName),
              extIdentifier: model.getQueryParam(extIdentifier),
              card: model.getQueryParam(card),
              email: model.getQueryParam(email),
              noCaij: model.getQueryParam(noCaij),
              group: model.getQueryParam(group)
            };
            await getCustomers.apply(null,[model,params]);
          }
        }else{
          await getCustomers.apply(null,[new CustomerModel, params]);
        }
      }
    };
    (async () => {
      initialise();
    })();
  }, [location.search]);

  const { customers, paged, isEmptyList, isLoading, model } = state;

  if (!isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Page sx={root} title={customerModel.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader mode={Mode.edit} model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        { (new URLSearchParams(location.search)).get('toggle') === 'yes' ? (
              <StyledList>
                <Results
                  customerModel={customerModel}
                  customers={customers}
                  subscriptions={subscriptions}
                  groups={groups}
                  isEmptyList={isEmptyList}
                  paged={paged}
                  model={model}
                  resetGroups={(ids) => resetGroups(ids)}
                  navigate={navigate}
                  location={location}
                />
              </StyledList>
            ) : (
              <Box mt={3}>
                <Results
                  customerModel={customerModel}
                  customers={customers}
                  subscriptions={subscriptions}
                  isEmptyList={isEmptyList}
                  paged={paged}
                  model={model}
                  navigate={navigate}
                  location={location}
                />
              </Box>
            )
        }
      </Container>
    </Page>
  );
};

export default CustomerListView;
