import React, { FC } from 'react';
import { Box, Button, SvgIcon } from '@mui/material';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import type { CustomerResource } from 'src/common/types';
import { 
  Formik, 
  AccessModel, 
  FormikHelpers, 
  CustomerStatus,
  MSG_SEND_EMAIL_MEMBER
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import CustomerModel from 'src/model/customer/Customer';
import { ArrowLeftCircle } from 'react-feather';
import CaijCardForm from 'src/components/customer/customer/Forms/CaijCardForm';
import { XSquare as CancelIcon, CheckSquare as SaveIcon } from 'react-feather';
import { btnMarginRight, btnReturn, marginTop } from 'src/styles/customer';
import MyCard from 'src/components/card/MyCard';
import CardRequestPendingModel from 'src/model/customer/CardRequestPending';
import CarteCaij from 'src/components/customer/customer/Details/CarteCaij';

export interface CaijCardAssignFormProps {
  model: CustomerModel;
  cardRequestPendingModel: CardRequestPendingModel;
  customer: CustomerResource;
  onHandleRefreshCustomer: () => Promise<void>;
  modifyCaijCard: (action: string) => void;
  onSubmit?: (values: CustomerResource) => void;
}

const icon = {
  height: '2em',
  width: '2em',
}

const CaijCardAssignForm: FC<CaijCardAssignFormProps> = ({
  model,
  cardRequestPendingModel,
  customer, 
  modifyCaijCard, 
  onHandleRefreshCustomer,
  onSubmit
}) => {
  const accessCarteCaij = new AccessModel(cardRequestPendingModel.ResourceCode);
  const accessCustomer = new AccessModel(model.ResourceCode);
  
  const handleSubmit = async (customer: CustomerResource, formikHelpers: FormikHelpers<typeof customer>): Promise<void> => {
    if(onSubmit){
      onSubmit(customer);
      return;
    }
    formikHelpers.validateForm(customer);
    const model = new CustomerModel();
    if (customer && customer.id) {
      const response = await model.assignCard(customer.id, customer.card);
      if (!model.error) {
        const { status, message } = response;
        if(customer.status === CustomerModel.getStatusByVal(CustomerStatus.Active)){
          model.printEmailMessage(status,MSG_SEND_EMAIL_MEMBER +  'pour l\'informer qu\'une carte lui a été assignée');
        }
        printMessage({status,message});
        await onHandleRefreshCustomer();
      }
	  }
  }; 

  return (
    <Formik
      initialValues={CustomerModel.getInitialValues(customer)}
      validationSchema={Yup.object().shape({
        card: model.MemberCard.required(true)
      })}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit, handleBlur, handleChange, values, touched, errors, setFieldValue, isSubmitting}) => (
        <form onSubmit={handleSubmit}>
          <Box>
            <Button
              onClick={() => modifyCaijCard('return')}
              sx={btnReturn}
              startIcon={
                <SvgIcon sx={icon} fontSize='small'>
                  <ArrowLeftCircle />
                </SvgIcon>
              }
            >
              Assigner une carte
            </Button>
            <CarteCaij>
              <MyCard>
                <Box p={3}>
                  <CaijCardForm 
                    model={model}
                    customer={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                </Box>
              </MyCard>
            </CarteCaij>
            <Box sx={marginTop}>
                <Button
                  type='submit'
                  disabled={!accessCustomer.UserAdmin && (accessCustomer.UserUser && !accessCarteCaij.Edition && !accessCarteCaij.Gestion) || isSubmitting}
                  sx={btnMarginRight}
                  color='secondary'
                  variant='contained'
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <SaveIcon />
                    </SvgIcon>
                  )}
                >
                  Émettre la carte
                </Button>
                <Button 
                  type='reset' 
                  onClick={() => modifyCaijCard('return')} 
                  color='secondary' 
                  variant='outlined'
                  startIcon={(
                    <SvgIcon fontSize="small">
                      <CancelIcon />
                    </SvgIcon>
                  )}
                >
                  Annuler
                </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CaijCardAssignForm.propTypes = {
  customer: PropTypes.object.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired,
  modifyCaijCard: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

CaijCardAssignForm.defaultProps = {
  onSubmit: null
};

export default CaijCardAssignForm;
