import { FC, useState } from 'react';
import { MessagePlaceholderResource, MessageResource} from 'src/common/types';
import { CaijButton, labelConfig, Mode, Link as RouterLink, MessageModel, link, deleteAction, MessagePlaceholderModel, CaijTooltip } from 'src/common';
import { Typography, Box, Link, IconButton, Grid, SvgIcon, Button } from '@mui/material';
import Authorize from 'src/components/Authorize';
import Label from 'src/components/Label';
import { Edit as EditIcon, Mail as AllIcon, Eye as VisibleIcon } from 'react-feather';
import MessagePublishForm from '../PlaceholderDetailsView/Forms/MessagePublishForm';
import type { Color } from 'src/model/Message';
import { big, container, grayed, padding, rightContainer } from 'src/styles/message';
import MyCard from 'src/components/card/MyCard';
import CaijDialogs from 'src/components/dialog';
import printMessage from 'src/views/errors/MessageError';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { makeStyles } from 'tss-react/mui';
import { NavigateFunction } from 'react-router';

const useStyles = makeStyles()(() => {
  return {
    squareBtn : {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '3.5em',
      width: '3.5em',
      border: '1px solid rgba(90, 80, 150 ,0.5)',
      borderRadius: '0.3em',
    },
    label: {
      fontSize: '1.2em',
    },
  }
});

interface DetailsRowProps {
  model: MessageModel;
  message?: MessageResource;
  placeholder: MessagePlaceholderResource;
  createMsg: (value: boolean) => void;
  isCreating: boolean;
  messageView: boolean;
  navigate?: NavigateFunction;
  colorRender: (item: MessageResource) => Color;
}

const DetailsRow: FC<DetailsRowProps> = ({
  model,
  placeholder,
  colorRender,
  isCreating,
  message,
  messageView,
  navigate,
  createMsg,
}) => {
  const { classes } = useStyles();
  const [isCreateAuth, setIsCreateAuth] = useState<boolean>();
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
 
  const visibleCount = () => {
    let count = 0;
    placeholder.messages.forEach(item => {
      if (item.visible === true && new Date(item.startDate) < new Date() && new Date(item.endDate) > new Date()) {
        count = count + 1;
      }
    });
    return count;
  };

  const deleteMessage = async (id: number) => {
    if (id) {
      const model = new MessageModel();
      const messagePlaceholderModel = new MessagePlaceholderModel();
      const response = await model.removeMessage(id);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        model.redirect(messagePlaceholderModel.Path.getDetail(message.placeholderId));
      }
    }
  }

  return (
    <Grid spacing={2} container>
      <Grid xs={9} item>
        <MyCard>
          <Box sx={container}>
            <Box width='100%' mr={2}>
              <Box sx={padding}>
                <Box>
                  <Typography sx={grayed}>Nom de l'emplacement {messageView ? 'de ce message' : ':'}</Typography>
                </Box>
                <Box>
                  <Typography sx={big}>{placeholder.name}</Typography>
                </Box>
              </Box>
            </Box>
            {messageView ? (
              <Box mr={1}>
                <Box display='flex' justifyContent='space-between' flexDirection='column' alignItems='flex-end' sx={padding}>
                  <Box>
                    <Typography sx={{...grayed, mr:1}}>
                      Statut:
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Label className={classes.label} color={MessageModel.colorRender(message)}>
                      {MessageModel.activeCheck(message)}
                    </Label>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box mr={2}>
                <Box display='flex' justifyContent='flex-end' flexDirection='column' alignItems='flex-end' sx={padding}>
                  <Box>
                    <Typography sx={{...grayed, mr:1}}>
                      Messages:
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Typography sx={{...big,mr: 1}}>
                      {placeholder.messages ? placeholder.messages.length : 0}
                    </Typography>
                    <SvgIcon sx={{...big,mr:1}} fontSize='small'>
                      <AllIcon />
                    </SvgIcon>
                  </Box>
                </Box>
              </Box>
            )}
            {messageView ? (
              ''
            ) : (
              <Box mr={2}>
                <Box display='flex' justifyContent='flex-end' flexDirection='column' alignItems='flex-end' sx={padding}>
                  <Box>
                    <Typography
                      sx={{...grayed, marginRight: '0.3em'}}
                    >
                      Visible:
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Typography sx={{...big, marginRight: '0.3em'}}>
                      {placeholder.messages ? visibleCount() : 0}
                    </Typography>
                    <SvgIcon sx={{...big, marginRight: '0.3em'}} fontSize='small'>
                      <VisibleIcon />
                    </SvgIcon>
                  </Box>
                </Box>
              </Box>
            )}
            {messageView ? (
              <Box mr={1}>
                <Box display='flex' justifyContent='space-between' flexDirection='column' alignItems='flex-end' sx={padding}>
                  <Box>
                    <Typography sx={{...grayed, marginRight: '0.3em'}}>
                      Fermable:
                    </Typography>
                  </Box>
                  <Box display='flex' alignItems='center'>
                    <Label className={classes.label} color={message.closable ? 'success' : 'error'}>
                      {message.closable ? labelConfig.isClosable : labelConfig.isNotClosable}
                    </Label>
                  </Box>
                </Box>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </MyCard>
      </Grid>
      <Grid xs={3} item>
        <Box sx={rightContainer}>
          <Box display='flex' height='100%' marginRight='1em' flexDirection='column' justifyContent='space-between'>
            {messageView ? (
              <MessagePublishForm holder={placeholder} message={message} />
            ) : (
              !isCreating && (
                <Authorize 
                  resourceCode={model.ResourceCode} 
                  mode={Mode.add}
                  onIsAuth={l => setIsCreateAuth(l)}
                >
                  <CaijButton
                    type='add'
                    color='secondary'
                    variant='contained'
                    disabled={!isCreateAuth || isCreating}
                    onHandleClick={() => navigate(`/app/management/placeholders/${placeholder.id}/create`, {replace: true})}
                  >
                    Nouveau Message
                  </CaijButton>
                </Authorize>
              )
            )}
            {messageView ? (
              <>
                <Authorize 
                  resourceCode={model.ResourceCode}
                  mode={Mode.delete}
                  onIsAuth={l => setIsDeleteAuth(l)}
                >
                  <Button
                    startIcon={
                      <SvgIcon fontSize='large'>
                        <DeleteIcon />
                      </SvgIcon>
                    }
                    sx={{...deleteAction()}}
                    disabled={!isDeleteAuth}
                    onClick={() => setOpenDialog(true)}
                  >
                    Supprimer
                  </Button>
                </Authorize>
                <CaijDialogs
                  dialog={model.Dialog}
                  isOpen={openDialog}
                  onSubmit={() => deleteMessage(message.id)}
                  close={() => setOpenDialog(false)}
                />
              </>
            ) : (
              ''
            )}
          </Box>
          {messageView ? (
            <Authorize 
              resourceCode={model.ResourceCode} 
              mode={Mode.edit} 
              onIsAuth={l => setIsCreateAuth(l)}
            >
              <Link underline="none" sx={link} component={RouterLink} to={model.Path.getEdit(message.id)}>
                <CaijTooltip title="Éditer" disabled={!isCreateAuth || isCreating}>
                  <IconButton
                    color='secondary'
                    classes={{root:classes.squareBtn}}
                    disabled={!isCreateAuth || isCreating}
                  >
                    <SvgIcon>
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                </CaijTooltip>
              </Link>
            </Authorize>
          ) : (
            ''
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DetailsRow;
