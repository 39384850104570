import { ChangeEvent, FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  DocumentCollectionsResource, 
  HandleBlur, 
  HandleChange,
  SetFieldValue 
} from 'src/common/types';
import { Grid } from '@mui/material';
import {
  CaijInput,
  CaijTextarea,
  FormikErrors,
  FormikTouched,
  DocCollectionsModel,
  handleChange,
  CaijCheckbox,
  DocTypeOptions,
  validateCode,
  getOptionLabel,
  DocCollectionsSortOption,
  CaijCard,
  DOCUMENT_COLLECTION_SORT,
  DOCUMENT_COLLECTION_TITLE
} from 'src/common';
import { $enum } from 'ts-enum-util';
import useDocumentTypes from 'src/functions/hooks/documentType';
import usePartners from 'src/functions/hooks/partners';
import { useCollections } from 'src/functions/hooks/docCollection';

interface DocCollectionsFormProps {
  model: DocCollectionsModel;
  collection: DocumentCollectionsResource;
  errors: FormikErrors<DocumentCollectionsResource>;
  touched: FormikTouched<DocumentCollectionsResource>;
  handleBlur: HandleBlur;
  onHandleChange: HandleChange;
  onHandleChangeCheckBox: HandleChange;
  setFieldValue: SetFieldValue;
}

const DocCollectionsForm: FC<DocCollectionsFormProps> = ({
  model,
  collection,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  onHandleChangeCheckBox,
  setFieldValue
}) => {
  const sPartners = usePartners();
  const sCollection = useCollections();
  const sDocumentType = useDocumentTypes();
  const { active, collectionCode, title, accessCollectionCode, descriptionFr, descriptionEn, infobox, copyrightZone, partnerId, documentType, documentTypeId, documentCollectionsSort } = collection;
  const { Active, CollectionCode, Titre, DescriptionFr, DescriptionEn, InfoBox, CopyrightZone, PartnerId, DocumentType, DocumentTypeId, AccessCollectionCode, DocumentCollectionsSorts } = model;
  return (
    <>
      <Grid  item md={12} xs={12}>
        <CaijCheckbox
          name={Active.Name}
          label={Active.Label}
          checked={active}
          value={active}
          inputProps={{'data-testid': Active.Name}}
          onHandleChangeCheckBox={onHandleChangeCheckBox}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          required
          name={CollectionCode.Name}
          id={CollectionCode.Name}
          value={collectionCode}
          helperText={touched.collectionCode && errors.collectionCode}
          error={Boolean(touched.collectionCode && errors.collectionCode)}
          label={CollectionCode.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: CollectionCode.MaxLength, 'data-testid': CollectionCode.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(CollectionCode.Name, validateCode(e.target.value.toLowerCase()))}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          required
          name={Titre.Name}
          id={Titre.Name}
          margin='none'
          value={title}
          label={Titre.Label}
          helperText={touched.title && errors.title}
          error={Boolean(touched.title && errors.title)}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{maxLength: Titre.MaxLength, 'data-testid': Titre.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijTextarea
          name={DescriptionFr.Name}
          id={DescriptionFr.Name}
          rows="4"
          margin="none"
          value={descriptionFr}
          label={DescriptionFr.Label}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: DescriptionFr.MaxLength, 'data-testid': DescriptionFr.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijTextarea
          name={DescriptionEn.Name}
          id={DescriptionEn.Name}
          rows="4"
          margin="none"
          value={descriptionEn}
          label={DescriptionEn.Label}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: DescriptionEn.MaxLength, 'data-testid': DescriptionEn.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijTextarea
          name={InfoBox.Name}
          id={InfoBox.Name}
          margin='none'
          rows="4"
          value={infobox}
          label={InfoBox.Label}
          InputLabelProps={{ shrink: true }}
          inputProps={{'data-testid': InfoBox.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijTextarea
          name={CopyrightZone.Name}
          id={CopyrightZone.Name}
          margin='none'
          rows="4"
          value={copyrightZone}
          label={CopyrightZone.Label}
          InputLabelProps={{ shrink: true }}
          inputProps={{'data-testid': CopyrightZone.Name}}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          select
          name={PartnerId.Name}
          id={PartnerId.Name}
          label={PartnerId.Label}
          value={partnerId}
          margin="none"
          InputLabelProps={{ shrink: true }}
          inputAttr={{'data-testid': PartnerId.Name}}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
          >
            <option value="">{getOptionLabel(PartnerId.Label)}</option>
            { sPartners.allChecked && sPartners.partners.map(({id, nameFr}, key) => (
                <option value={id} key={key}>{nameFr}</option>
              ))
            }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          select
          name={DocumentType.Name}
          id={DocumentType.Name}
          label={DocumentType.Label}
          value={documentType}
          margin="none"
          helperText={touched.documentType && errors.documentType}
          error={Boolean(touched.documentType && errors.documentType)}
          InputLabelProps={{ shrink: true }}
          inputAttr={{'data-testid': DocumentType.Name}}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
          >
            <option value="">{getOptionLabel(DocumentType.Label)}</option>
            { $enum(DocTypeOptions).map((value, key) => (
                <option value={key} key={key}>{value}</option>
              ))
            }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          select
          required
          name={DocumentTypeId.Name}
          id={DocumentTypeId.Name}
          label={DocumentTypeId.Label}
          value={documentTypeId}
          margin="none"
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{'data-testid': DocumentTypeId.Name}}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
          >
            <option value="" disabled>{getOptionLabel(DocumentTypeId.Label)}</option>
            { sDocumentType.allChecked && sDocumentType.documentTypes.map(({nameFr}, key) => (
                <option value={nameFr} key={key}>{nameFr}</option>
              ))
            }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          select
          required
          name={AccessCollectionCode.Name}
          id={AccessCollectionCode.Name}
          label={AccessCollectionCode.Label}
          value={accessCollectionCode}
          margin="none"
          helperText={touched.accessCollectionCode && errors.accessCollectionCode}
          error={Boolean(touched.accessCollectionCode && errors.accessCollectionCode)}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{'data-testid': AccessCollectionCode.Name}}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
          >
            <option value="" disabled>{getOptionLabel(AccessCollectionCode.Label)}</option>
            { sCollection.allChecked && sCollection.docCollections.map(({code, nameFr}, key) => (
                <option value={code} key={key}>{nameFr}</option>
              ))
            }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijCard title={DOCUMENT_COLLECTION_TITLE} allowedBackgroundColor={false}>
        { DocumentCollectionsSorts.map((form, index: number) => {
           const required = form.Name == DOCUMENT_COLLECTION_SORT.order1 || form.Name == DOCUMENT_COLLECTION_SORT.order2;
           const prop = form.Name.split('.')[1];
           return (
              <div key={index}>
                <CaijInput
                  select
                  required={required}
                  label={form.Label}
                  id={form.Name}
                  name={form.Name}
                  value={documentCollectionsSort[prop] || ''}
                  margin="none"
                  helperText={touched.documentCollectionsSort?.[prop] && errors.documentCollectionsSort?.[prop]}
                  error={Boolean(touched.documentCollectionsSort?.[prop] && errors.documentCollectionsSort?.[prop])}
                  InputLabelProps={{ shrink: true, required }}
                  inputAttr={{'data-testid': form.Name}}
                  sx={{ marginBottom: index < DocumentCollectionsSorts.length - 1 ? 2 : 0 }}
                  onHandleChange={onHandleChange}
                  setFieldValue={setFieldValue}
                >
                  <option value="">{getOptionLabel(DOCUMENT_COLLECTION_TITLE)}</option>
                  { $enum(DocCollectionsSortOption).map((value, key) => <option value={key} key={key}>{value}</option>) }
                </CaijInput>
              </div>
            )
          })
        }
        </CaijCard>
      </Grid>
    </>
  )
};

DocCollectionsForm.propTypes = {
  collection: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default DocCollectionsForm;
