import { Box, Button, DialogActions, DialogContent, DialogTitle, SvgIcon, Typography } from "@mui/material";
import { dialog, dialogTitle } from "src/common";
import { RefreshCw as RefreshDocIcon } from 'react-feather';
import { Dispatch, SetStateAction } from "react";

export default function RefreshProfilePageDialog(props:{
    setConflit: Dispatch<SetStateAction<boolean>>, 
    onHandleRefreshCustomer: () => void, 
    setIsRunning?: Dispatch<SetStateAction<boolean>>
}): JSX.Element {
    const { onHandleRefreshCustomer, setConflit, setIsRunning} = props;
    const refreshProfilePage = () => {
      setConflit((prevState: boolean) => !prevState);
      onHandleRefreshCustomer();
      if(setIsRunning) setIsRunning(true);
    }
    return (
       <>
        <DialogTitle sx={dialogTitle} id='form-dialog-title'>Raffraichir la page</DialogTitle>
        <DialogContent sx={{...dialog, width: 'auto'}} dividers>
          <Box display="flex" alignItems="center" justifyContent="center" width='100%' >
            <Typography variant="body2" sx={{color:'text.secondary'}}>
              Les informations du membre ont été mise à jour. Veuillez recharger la page.
            </Typography>
           </Box>
        </DialogContent>
        <DialogActions sx={dialog}>
          <Box mt={1} mb={1} display="flex" alignItems="center" justifyContent="center" width='100%' >
            <Button 
              type="button" 
              variant="outlined" 
              color="primary"
              sx={{ marginRight: '5px'}}
              startIcon={(
                <SvgIcon fontSize="small">
                  <RefreshDocIcon />
                </SvgIcon>
                )}
              onClick={refreshProfilePage}
            >Raffraichir la page</Button>
          </Box>
        </DialogActions>
      </>
     )
  };