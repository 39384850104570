import { FC } from "react";
import AppModel from "src/model/App";
import HeaderAddButton from "./HeaderAddButton";
import HeaderEditButton from "./HeaderEditButton";
import HeaderDeleteButton from "./HeaderDeleteButton";
import { Mode } from "src/common";

interface HeaderButtonProps {
    model?: AppModel;
    type?: string;
    id? : string|number;
    mode?: Mode;
    editMode?: boolean;
    deleteMode?: Mode;
    callback: () => Promise<boolean>;
}

const HeaderButton: FC<HeaderButtonProps> = ({
    type,
    model,
    id,
    mode,
    editMode,
    deleteMode,
    callback
}) => {
    if(type=="add")
        return <HeaderAddButton model={model} mode={mode} />
    else if(type=="edit" || type == "editDetail")
        return <HeaderEditButton id={id} editMode={editMode} model={model} onClick={callback} />
    else if(type=="delete")
        return <HeaderDeleteButton id={id} model={model} mode={deleteMode} />
    else
        return <></>
}

export default HeaderButton;