import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';
import { Color, Edge, InputProps2, LabelPlacement, Margin, Size } from 'src/types/input';

interface CaijSwitchProps {
  value?: string | boolean | number;
  checked?: boolean;
  label?: string;
  name?: string;
  error?: any;
  sx?: any;
  disabled?: boolean;
  disableRipple?: boolean;
  edge?: Edge;
  size?: Size;
  margin?: Margin;
  color?: Color;
  required?: boolean;
  inputProps?: InputProps2;
  labelPlacement?: LabelPlacement;
  onHandleChangeSwitch?: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijSwitch: FC<CaijSwitchProps> = (props : CaijSwitchProps) => {
  const { label, labelPlacement, sx, disabled, value, onHandleChangeSwitch, setFieldValue, ...rest} = props;
  return (
    <>
      <FormControlLabel
        labelPlacement={labelPlacement}
        control={
          <Switch
            {...rest}
            tabIndex={-1}
            onChange={event => onHandleChangeSwitch(event, setFieldValue)}
            disabled={disabled}
            value={value}
          />
        }
        label={label}
        sx={sx}
        disabled={disabled}
      />
    </>
  );
};
CaijSwitch.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  disableRipple: PropTypes.bool,
  sx: PropTypes.any,
  edge: PropTypes.oneOf([false, 'start', 'end']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['medium', 'small']),
  onHandleChangeSwitch: PropTypes.func,
  setFieldValue: PropTypes.func,
};

CaijSwitch.defaultProps = {
  label: '',
  disabled: false,
  size: 'medium',
  required: false,
  edge: false,
  disableRipple: false,
  onHandleChangeSwitch: () => {},
  setFieldValue: () => {},
};

export default CaijSwitch;
