import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit
} from 'src/common';
import type { 
  Resource, 
  ResourceForCreate, 
  ResourceForEdit,
  Error,
  CreateResponse
} from 'src/common/types';
import ResourceModel from 'src/model/employee/Resource';
import Authorize from 'src/components/Authorize';
import ResourceForm from 'src/components/employee/resource/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';

export interface ResourceEditFormProps {
  model: ResourceModel;
  resource: Resource;
  onSubmit?: (values: Resource) => void;
}

async function redirect(model: ResourceModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const ResourceEditForm: FC<ResourceEditFormProps> = ({
  model,
  resource,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const handleSubmit = async (values: Resource): Promise<void> => {
    if (onSubmit) {
      onSubmit(values);
      return;
    }
    const model = new ResourceModel();
    let submitData = values as ResourceForEdit;
    if (submitData && submitData.id) {
      const response = await model.updateResource(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = values as ResourceForCreate;
      delete submitData.id;
      const response = await model.insertResource(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <Formik
      initialValues={ResourceModel.getInitialValues(resource)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                  <ResourceForm 
                    model={model}
                    resource={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={resource.id ? Mode.edit : Mode.add}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

ResourceEditForm.propTypes = {
  resource: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

ResourceEditForm.defaultProps = {
  onSubmit: null
};

export default ResourceEditForm;
