export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
 // UNICORN: 'UNICORN',
};

export const ITEMS_PER_PAGE = 50;
export const FIRST_PAGE = 0;
export const UNAUTHORIZE_ACCESS_CONTENT_PUBLISHER = 'Aucun accès autorisé à l\'éditeur de contenu';
export const UNAUTHORIZE_ACCESS_CONTENT_TYPE = 'Aucun accès autorisé au type de contenu';
export const RESET_STATUS_SUCCESS_CUSTOMER = 'Statut du membre a été désactivé';
export const SAVE_WIHTOUT_SUCCESS_CUSTOMER = 'Membre n\'a pas été sauvegardé';
export const UNAUTHORIZE_ACCESS_PERMISSIONS = 'Aucun accès autorisé sur des permissions'
export const SAVE_SUCCESS_RESOURCE_GROUP = "Groupe d'accès a été sauvegardé";
export const MSG_NO_CONTENT_ERROR = 'Données obtenues invalides';
export const MSG_FORBIDEN_ERROR = "L'accès n'est pas autorisé";
export const MSG_SERVICE_UNAVAILABLE_ERROR = "Service n'est pas disponible";
export const MSG_CONFLIT_ERROR = 'Suppression impossible. Cet élément est utilisé par une autre ressource';
export const MSG_SESSION_EXPIRE = 'Votre session est expirée';
export const MSG_UNEXPECTED_TOKEN_JSON = 'Unexpected token u in JSON at position 0';
export const CARD_HEADER_TITLE_GROUPS = 'Choisir le/les group(s)';
export const CARD_HEADER_TITLE_PERMISSION = 'Choisir la/les permission(s)';
export const CARD_HEADER_TITLE_EMPLOYEE = 'Choisir le/les utilisateur(s)';
export const CARD_HEADER_TITLE_DATABANK = 'Choisir la/les bases des données';
export const CARD_HEADER_TITLE_REMOTE_ACCESS_DATABANK = 'Choisir la/les bases de données accessible à distance';
export const CARD_HEADER_TITLE_COLLECTION = 'Choisir le/les classements du contenu';
export const CARD_HEADER_TITLE_LIBRARY = 'Choisir la/les bibliothèques';
export const CARD_HEADER_TITLE_SUBSCRIPTION = 'Choisir le/les abonnements';
export const CARD_HEADER_TITLE_SUBSCRIPTION2 = 'Abonnements ayant accès lorsque les utilisateurs sont à distance';
export const CARD_HEADER_TITLE_PRODUCT_SERVICE = 'Choisir le/les produit(s) et service(s)';
export const CARD_HEADER_TITLE_ACCESS = 'Accès';
export const CARD_HEADER_TITLE_LAW_DOMAIN = 'Choisir le/les champs de pratique';
export const CARD_HEADER_TITLE_ADDRESS = 'Choisir le/les adresse(s)';
export const CARD_HEADER_TITLE_DOCUMENT_TYPE = 'Choisir le/les type(s) de document';
export const MSG_MESSAGE_TEMPLATE_INVALID = 'Un des messages est obligatoire';
export const FORMAT_TIME_DATE_PICKER = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_DATE_PICKER = 'YYYY-MM-DD';
export const FORMAT_DAY_PICKER = 'dd';
export const FORMAT_MONTH_PICKER = 'MM';
export const FORMAT_YEAR_PICKER = 'yyyy';
export const FORMAT_TIME_PICKER = 'HH:mm';
export const INITIAL_TIME = '00:00';
export const MSG_EMPTY_LIST = 'La liste est vide';
export const PRODUCT_TITLE = 'Les produits et services';
export const SUBSCRIPTION_TITLE = 'Les abonnements';
export const LIBRARY_TITLE = 'Les bibliothèques';
export const DATABANK_TITLE = 'Les bases de données';
export const COLLECTION_TITLE= 'Les classements du contenu';
export const DEACTIVATE = 'DÉSACTIVER';
export const PRIVATE_PERMISSION_MSG = 'Cette ressource doit être ajouté à un abonnement par les gestionnaires d\'abonnements pour que les membres puissent y avoir accès';
export const ANIMATION_TIMING = 300;
export const DELAY_LONG_POLLING = 5000;
export const ANIMATION_TIMING_COLLAPSE = {
   enter: 1000,
   exit: 300
}
export const NUMBER_OF_MONTH = 12;
export const FIELD_REQUIRED = 'Champs ne doit pas être vide';
export const ACCOUNT_TITLE = 'Paramètres des comptes utilisateurs';
export const EmployeeRoleLabel = {
  USER: 'Utilisateur',
  ADMIN: 'Admin',
};

export const USER_ROLE = 'Utilisateur';
export const ADMIN_ROLE = 'Admin';

export const Permissions = {
  Add: 'add',
  Read: 'read',
  Edit: 'edit',
  Delete: 'delete',
};

export const EXCLUS_CARD_HEADER = "Exclus";
export const INCLUS_CARD_HEADER = "Inclus";
export const MSG_NOT_EXLCUS = "Aucune exclusion";
export const MSG_NOT_INCLUS = "Aucune inclusion";

export const UPLOAD_CUSTOMER = 'Téléverser';

export const GESTION = "GESTION";
export const SYSTEM_PREFERENCES = "PRÉFÉRENCES SYSTÈME";
export const ITEM_NOT_FOUND = "Item n'existe pas"
export const SUBCRIPTION_CONTACT_ERROR = "Le nom et le courriel de la personne ressource sont obligatoire pour activer cette fonctionnalité";

export const MSG_SUBSCRIPTION_DELETED_CONFLIT = "Suppression impossible. Les membres doivent d'abord être effacés avant de supprimer l'abonnement";
export const MSG_PROFILE_DESACTIVATE_CONFLIT = "Impossible de désactiver ce profil. Il est actuellement utilisé par un membre, un abonnement ou un groupe d'accès";
export const MSG_LIBRARY_DESACTIVATE_CONFLIT = "Impossible de désactiver une bibliothèque qui est actuellement actif dans le membre";
export const MSG_UXPERTISE_DESACTIVATE_CONFLIT = "Impossible de désactiver ce groupe uxpertise. Il est actuellement utilisé par un abonnement";
export const MSG_KANTECH_DESACTIVATE_CONFLIT = "Impossible de désactiver ce profile de carte. Il est actuellement utilisé par un abonnement";
export const INVALID_DATE = 'Invalid Date';
export const NO_AUTHORIZE_ACCESS_MSG = "L'accès non autorisé";
export const SETTINGS = 'Réglages';
export const ENDORSEMENT = "ENDORSEMENT";
export const ONLINE_TRAINING = 'ONLINE_TRAINING';
export const LIBRARY_ACCESS = 'LIBRARY_ACCESS';
export const INACTIF = 'INACTIF';
export const INACTIF_COLOR = 'rgba(255, 255, 255, 0.5)'
export const MSG_SEND_EMAIL_MEMBER = 'Un courriel a été envoyé au membre ';
export const ITEM_HEIGHT = 120;
export const ITEM_PADDING_TOP = 8;
export const DESKTOP_NAV_CLOSED_PERSIST = "desktopNavClosedPersist";
export const MSG_CUSTOMER_CARD_EXISTS = 'Le numéro de carte est déjà utilisé par un autre compte';
export const MSG_DUPLICATE_FOLDER_NAME = 'Le nom du dossier existe déjà, veuillez en choisir un autre';
export const PAPER_DIGITAL_SPOTTING = 'PAPER_DIGITAL_SPOTTING';
export const TINY_MCE_SCRIPT_CDN = "https://cdn.caij.qc.ca/assets/libraries/tinymce/5.9.2/tinymce.min.js";
export const DOCUMENT_COLLECTION_SORT = {
  order1: 'documentCollectionsSort.order1',
  order2: 'documentCollectionsSort.order2'
};
export const DOCUMENT_COLLECTION_TITLE = 'Tri pour la navigation';





