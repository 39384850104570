import { Box } from "@mui/material";
import { FC, useState } from "react";
import PropTypes from 'prop-types';
import { 
  AccessModel, 
  Authorize, 
  CaijButton, 
  CardRequestPendingModel, 
  CustomerModel, 
  Mode 
} from "src/common";
import { CustomerResource } from "src/common/types";

interface EditButtonProps {
  customer?: CustomerResource;
  model: CustomerModel;
  isEditing?: boolean;
	onEditCustomer?: () => void;
  tab?: 'card' | 'subscription';
  cardRequestPendingModel?: CardRequestPendingModel;
  onTransactionMode?: () => void;
}

const EditButton: FC<EditButtonProps> = ({
  isEditing, 
  model, 
  onEditCustomer, 
  customer, 
  tab, 
  cardRequestPendingModel, 
  onTransactionMode
}) => {
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  if(tab === 'card'){//New transaction button
    const carteCaijAccess = new AccessModel(cardRequestPendingModel.ResourceCode);
    return (carteCaijAccess.Edition || carteCaijAccess.Gestion) && (
      <Authorize 
        resourceCode={cardRequestPendingModel.ResourceCode} 
        mode={Mode.edit}
        onIsAuth={l => setIsEditAuth(l)}
      >
        <CaijButton
          type='edit'
          color='secondary'
          variant='contained'
          onHandleClick={() => onTransactionMode()}
          disabled={!isEditAuth || customer.cardBalance === null || isEditing}
        >
          Nouvelle Transaction
        </CaijButton>
      </Authorize>
    )
  }else if(tab === 'subscription'){//Edit subscription button
    const access = new AccessModel(model.ResourceCode);
    return (
      <Box display="flex" justifyContent="right">
        <CaijButton
          type='edit'
          color='secondary'
          variant='contained'
          onHandleClick={() => onEditCustomer()}
          disabled={!access.Gestion}
        >
          Modifier
        </CaijButton>
      </Box>
    )
  }
  //Edit customer profile button
  return (
    <Box display="flex" justifyContent="right">
      <Authorize 
        resourceCode={model.ResourceCode} 
        mode={Mode.edit}
        onIsAuth={l => setIsEditAuth(l)}
			>
        <CaijButton
          type='edit'
          color='secondary'
          variant='contained'
          onHandleClick={() => onEditCustomer()}
          disabled={!isEditAuth || isEditing}
        >
          Modifier
        </CaijButton>
			</Authorize>
    </Box>
  )
}

EditButton.propTypes = {
  onEditCustomer: PropTypes.func,
  onTransactionMode: PropTypes.func,
  tab: PropTypes.oneOf(['card', 'subscription']),
};

export default EditButton;
