import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export type LabelColor = 'primary' 
                         | 'secondary' 
                         | 'error' 
                         | 'warning' 
                         | 'success' 
                         | 'warning2' 
                         | 'warning3' 
                         | 'warning4' 
                         | 'warning5';

interface LabelProps {
  className?: string;
  color?: LabelColor;
  children?: ReactNode;
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      fontFamily: theme.typography.fontFamily,
      alignItems: 'center',
      borderRadius: 2,
      display: 'inline-flex',
      flexGrow: 0,
      whiteSpace: 'nowrap',
      cursor: 'default',
      flexShrink: 0,
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightMedium,
      height: 20,
      justifyContent: 'center',
      letterSpacing: 0.5,
      minWidth: 20,
      padding: theme.spacing(0.5, 1),
      textTransform: 'uppercase'
    },
    primary: {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.08)
    },
    secondary: {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.08)
    },
    error: {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.12)
    },
    success: {
      color: theme.palette.success.main,
      backgroundColor: alpha(theme.palette.success.main, 0.12)
    },
    warning: {
      color: theme.palette.warning.main,
      backgroundColor: alpha(theme.palette.warning.main, 0.08)
    },
    warning2: {
      color: theme.palette.warning.dark,
      backgroundColor: alpha(theme.palette.warning.main, 0.14)
    },
    warning3: {
      color: theme.palette.common.black,
      backgroundColor: alpha(theme.palette.common.black, 0.1)
    },
    warning4: {
      color: theme.palette.common.black,
      backgroundColor: alpha(theme.palette.common.white, 0.3)
    },
    warning5: {
      color: theme.palette.common.white,
      backgroundColor: '#8B8000'
    }
  }
});

const Label: FC<LabelProps> = ({
  className = '',
  color = 'secondary',
  children,
  ...rest
}) => {
  const { classes } = useStyles();
  return (
    <span
      className={
        clsx(classes.root, {
          [classes[color]]: color
        }, className)
      }
      {...rest}
    >
      {children}
    </span>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary', 
    'secondary', 
    'error', 
    'warning', 
    'success', 
    'warning2', 
    'warning3', 
    'warning4', 
    'warning5'
  ])
};

export default Label;
