import {
  Success,
  Created
} from 'src/common';
import type {
  Error,
  PartnerResourceForCreate,
  PartnerResourceForEdit,
  PartnerResource,
  CreateResponse
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

export default class PartnersModel extends AppModel 
{
  private static _instance: PartnersModel;
  readonly Code = new FormModel('code', 'Code', 50)
  readonly NameFr = new FormModel('nameFr','Nom',250);
  readonly NameEn = new FormModel('nameEn','Name',250);
  readonly HomeUrlFr = new FormModel('homeUrlFr','Lien Url', 500);
  readonly HomeUrlEn = new FormModel('homeUrlEn','Url Link', 500);
  readonly ContentUrlFr = new FormModel('contentUrlFr','Contenu Url',500);
  readonly ContentUrlEn = new FormModel('contentUrlEn','Url Content',500);
  readonly LogoUrlFr = new FormModel('logoUrlFr','Logo',1000);
  readonly LogoUrlEn = new FormModel('logoUrlEn','Logo',1000);
  readonly IsShownOnHomePage = new FormModel('isShownOnHomePage',"Affiché sur la page d'accueil");
  readonly IsPinnedOnTop = new FormModel('isPinnedOnTop',"Épinglé en haut");
  readonly PartnerDocumentTypes = new FormModel('partnerDocumentTypes', 'Type de document');
  
  constructor(){
    super('/admin/partners');
    this.initialize();
  }
  
  private initialize(){
    this._resourceCode = 'PARTNERS';
    this._headerTitle = 'Liste des partenaires';
    this._btnAddText= 'Ajouter un partenaire';
    this.Path.PathName = '/contents/partners';

    //Dialog
    this.Dialog.Header = "Supprimer le partenaire";
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = "le partenaire";
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Partenaires',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: this.Code.Name, width: '15%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.NameFr.Name, width: '37%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: this.NameEn.Name, width: '38%', numeric: false, disablePadding: false, label: this.NameEn.Label
      },
      {
        id: '', width: '0%'
      }
    ];
  }

  static getInstance(): PartnersModel {
    if (!PartnersModel._instance) {
      PartnersModel._instance = new PartnersModel();
    }
    return PartnersModel._instance;
  }

  async getPartners(): Promise<PartnerResource[]> {
    let partners: PartnerResource[];
    await axios.get<PartnerResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      if (status === Success) 
        partners = data;
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return partners;
  }

  async getPartnerByCode(code: string): Promise<PartnerResource> {
    let partner: PartnerResource;
    await axios.get<PartnerResource>(`${this.route}/${code}`).then(async (response) => {
      const { status, data } = response;
      if (status === Success) 
        partner = data;
    },async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return partner;
  }

  async updatePartner(submitData: PartnerResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<PartnerResourceForEdit, Error>(`${this.route}/${submitData.code}`, submitData).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        result = response;
        result.message = 'Partenaire modifié.';
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertPartner(submitData: PartnerResourceForCreate): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<PartnerResourceForCreate, CreateResponse>(this.route, submitData).then((response) => {
      const { status } = response;
      if (status === Created) {
        result = response;
        result.message = 'Partenaire créé.';
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(code: string, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${code}`).then((response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Partenaire supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }
}
