import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, SvgIcon } from '@mui/material';
import { Mail as MailIcon } from 'react-feather';

interface CaijInputEmailProps {
  value?: string;
  label?: string;
  name?: string;
  title?: string;
  id?: string;
  disabled?: boolean;
  error?: any;
  sx?: any;
  size?: "medium" | "small";
  variant?: "outlined" | "filled";
  margin?: 'normal' | 'dense' | 'none';
  inputProps?: { autoComplete?: string, maxLength?: number,  required?: boolean, autoFocus?: boolean, 'data-testid'?: string };
  helperText?: any;
  InputLabelProps?: { shrink?: boolean, required?: boolean };
  required?: boolean;
  onHandleBlur?: (e: any) => void;
  onHandleChange?: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onFocus?: (event:  React.FocusEvent<HTMLInputElement>) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijInputEmail: FC<CaijInputEmailProps> = ({
  value,
  variant,
  onHandleBlur,
  onHandleChange,
  onFocus,
  setFieldValue,
  ...rest
} : CaijInputEmailProps) => (
  <>
    <TextField
      {...rest}
      type="email"
      autoComplete="none"
      fullWidth
      onBlur={onHandleBlur}
      onChange={(event) => onHandleChange(event, setFieldValue)}
      onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocus(event)}
      value={value || ''}
      variant={variant}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon
              fontSize="small"
              color="action"
            >
              <MailIcon />
            </SvgIcon>
          </InputAdornment>
        )
      }}
    />
  </>
);

CaijInputEmail.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  error: PropTypes.any,
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['outlined', 'filled']),
  margin: PropTypes.oneOf(['normal', 'dense', 'none']),
  helperText: PropTypes.any,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  onHandleBlur: PropTypes.func,
  onHandleChange: PropTypes.func,
  onFocus: PropTypes.func,
  setFieldValue: PropTypes.func
};

CaijInputEmail.defaultProps = {
  value: '',
  label: '',
  required: false,
  disabled: false,
  variant: "outlined",
  margin: "normal",
  size: "medium",
  id: '',
  helperText: ' ',
  sx:{ marginBottom: 0},
  onFocus:() => {},
}

export default CaijInputEmail;
