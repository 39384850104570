import { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  CaijInput,
  DatabankModel
} from 'src/common';
import type { 
  DatabankResource, 
  HandleChange,
  SetFieldValue 
} from 'src/common/types';
import { Grid } from '@mui/material';

interface DatabankBannerProps {
  model: DatabankModel;
  databank: DatabankResource;
  setFieldValue: SetFieldValue;
  handleChange: HandleChange;
}

const DatabankBanner: FC<DatabankBannerProps> = ({ 
  model,
  databank,
  handleChange,
  setFieldValue 
}) => {
  const { BannerFr, BannerEn } = model;
  const { bannerFr, bannerEn } = databank;
  return (
    <>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={BannerFr.Name}
          value={bannerFr}
          label={BannerFr.Label}
          InputLabelProps={{shrink: true}}
          inputAttr={{'data-testid': BannerFr.Name}}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={BannerEn.Name}
          value={bannerEn}
          label={BannerEn.Label}
          inputAttr={{'data-testid': BannerEn.Name}}
          InputLabelProps={{shrink: true}}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
}

DatabankBanner.propTypes = {
  databank: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default DatabankBanner;