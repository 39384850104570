import { Box, Card, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Formik, ListType, Mode, CaijButtonReset, CaijButtonSubmit, btnSubmit } from 'src/common';
import type { LibraryResource, LibraryResourceForEdit } from 'src/common/types';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import Databanks from 'src/components/listInfo/checkList';
import LibraryModel from 'src/model/library/Library';
import printMessage from 'src/views/errors/MessageError';

interface LibraryDatabankFormProps {
  model: LibraryModel;
  library: LibraryResource;
  onHandleRefreshLibrary: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const LibraryDatabankForm: FC<LibraryDatabankFormProps> = ({ model, library, onHandleRefreshLibrary, onSwitchMode }) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedLibraryDatabanks, setSelectedLibraryDatabanks] = useState<{ data: number[]; selected: boolean }>({
    data: [],
    selected: false,
  });

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={async () => {
        const model = new LibraryModel();
        const submitData = library as LibraryResourceForEdit;
        const libraryId = submitData.id;
        if (submitData && libraryId) {
          const imageUrl = model.getSubmitDataImageUrl(submitData.imgUrlFr, submitData.imgUrlEn);
          submitData.imgUrlFr = imageUrl.Fr;
          submitData.imgUrlEn = imageUrl.En;
          model.saveListDatabank(selectedLibraryDatabanks, submitData);
          const response = await model.updateLibrary(submitData);
          if (!model.error) {
            printMessage({
              status: response.status,
              message: response.message
            });
            await onHandleRefreshLibrary();
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} lg={12} xs={12}>
                  <Databanks
                    listType={ListType.DatabankLibrary}
                    onHandleSelectedItems={l =>
                      setSelectedLibraryDatabanks({
                        ...selectedLibraryDatabanks,
                        data: l as number[],
                        selected: true,
                      })
                    }
                    lists={library.databanks}
                  />
                </Grid>
              </Grid>
              <Box>
                <Authorize 
                  resourceCode={model.ResourceCode} 
                  mode={Mode.edit}
                  onIsAuth={l => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

LibraryDatabankForm.propTypes = {
  library: PropTypes.object.isRequired,
  onHandleRefreshLibrary: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
};

export default LibraryDatabankForm;
