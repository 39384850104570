import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  AccessPermissions,
  Created,
  LIBRARY_ACCESS,
  AccessType
} from 'src/common';
import type {
  ProductResource,
  Error,
  ProductResourceForCreate,
  ProductResourceForEdit,
  CreateResponse,
  SubscriptionProductResource
} from 'src/common/types';
import AppModel from './App';
import axios from 'src/utils/axios';
import { FormModel } from './Form';
import { $enum } from 'ts-enum-util';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceEdit = ProductResourceForCreate | ProductResourceForEdit;
interface TypeSelected { ids: number[], selected: boolean }
type TypeMap = Map<number, string>;
export interface ProductSubscription {
  subscriptionId: number[];
};

export default class ProductModel extends AppModel 
{
  private _selectedSubscriptionParameters: TypeMap; 
  private static _instance: ProductModel;
  readonly Code = new FormModel('code', 'Code',50);
  readonly NameEn = new FormModel('nameEn','Name',255);
  readonly NameFr = new FormModel('nameFr','Nom',255);
  readonly DescriptionFr = new FormModel('descriptionFr', 'Description (Français)');
  readonly DescriptionEn = new FormModel('descriptionEn', 'Description (English)');
  readonly Active = new FormModel('active', 'Active');
  readonly ParametersRequired = new FormModel('parametersRequired', 'Paramètre requis');
  
  constructor(){
    super('/product');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'PRODUCT';
    this._headerTitle = 'Liste des produits et services';
    this._btnAddText = 'Ajouter un produit et service';
    this.Path.PathName = '/products-services';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Produits et services',
      href: this.Path.Home,
      visible: true
    }
  }

  set SelectedSubscriptionParameters(selectedSubscriptionParameters: TypeMap) {
    this._selectedSubscriptionParameters = selectedSubscriptionParameters;
  }

  get SelectedSubscriptionParameters(): TypeMap {
    return this._selectedSubscriptionParameters;
  }

  static getAccessTypeByKey = (key: string) => {
		return $enum(AccessType).getValueOrDefault(key);
	}

  getHeadCells(accessType: string, status: string){
    return [
      {
        id: this.Code.Name, width: '15%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.NameFr.Name, width: '37%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: 'access', width: '30%', numeric: false, disablePadding: false, label: accessType
      },
      {
        id: '', width: '15%', numeric: false, disablePadding: false, label: status
      },
      {
        id: '', width: '5%'
      }
    ];
  }

  static getInstance(): ProductModel {
    if (!ProductModel._instance) {
      ProductModel._instance = new ProductModel();
    }
    return ProductModel._instance;
  }

  static getInitialValues(values: ProductResource) : ProductResource {
    return {
      id: values.id,
      nameFr: values.nameFr || '',
      nameEn: values.nameEn || '',
      code: values.code || '',
      descriptionFr: values.descriptionFr || '',
      descriptionEn: values.descriptionEn || '',
      active: values.active,
      parametersRequired: values.parametersRequired,
      access: values.access || AccessPermissions.Private,
      subscriptions: values.subscriptions || [],
      subscriptionGroupProducts: values.subscriptionGroupProducts || []
    }
  }
  
  async getProducts(): Promise<ProductResource[]> {
    let products: ProductResource[];
    await axios.get<ProductResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          products = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return products;
  }

  async getProductById(id: string): Promise<ProductResource> {
    let product: ProductResource;
    await axios.get<ProductResource>(`${this.route}/${+id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          product = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return product;
  }

  async getProductSubscriptions(): Promise<ProductSubscription> {
    let productSubscription: ProductSubscription;
    await axios.get<ProductSubscription>(`${this.route}/subscriptions`,{
      params: {
        productCode: LIBRARY_ACCESS
      }
    }).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          productSubscription = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
      }
    },
    async (error) => {
      this.error = error;
    });
    return productSubscription;
  }

  async updateProduct(submitData: TypeResourceEdit) : Promise<Error> {
    let result: Error;
    await axios.put<ProductResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Success) {
          result = response;
          result.message = 'Produit et service modifié.';
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertProduct(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<ProductResourceForCreate, CreateResponse>(this.route, submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Created) {
          result = response;
          result.message = 'Produit et service modifié.';
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Produit et service supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }

  async getRefreshProduct(id: string): Promise<ProductResource> {
    let newProduct : ProductResource;
    const product = await this.getProductById(id);
    if(!this.error){
      newProduct = product;
    }
    return newProduct;
  }

  private getSelectedSubscriptionParameters = (key: number): string => {
    let data: string = null;
    if (this.SelectedSubscriptionParameters && this.SelectedSubscriptionParameters.has(key)) {
      data = this.SelectedSubscriptionParameters.get(key);
    }
    return data;
  };

  setSubmitListData(submitData: TypeResourceEdit, selectedSubscriptions?: TypeSelected) : void {
      const { parametersRequired } = submitData;
      submitData.subscriptions = [];
      if(selectedSubscriptions.selected && submitData.access === AccessPermissions.Private){
        submitData.subscriptions = selectedSubscriptions.ids.reduce((initialValues: SubscriptionProductResource[], id: number) => {
          const parameter = +this.getSelectedSubscriptionParameters(id);
          initialValues.push({
            id,
            parameter: isNaN(parameter) || !parametersRequired ? null : parameter,
          });
          return initialValues;
        }, []); 
      }
  }

  verifyAddedAccess(source: ProductResource[], dest: TypeSelected, products: ProductResource[]) : ProductResource[] {
    let { ids, selected } = dest;
    if(selected){
      if(source.length === ids.length && source.every(({id}) => ids.includes(id))){
        return;
      }else{
        ids = ids.filter((id: number) => source.findIndex(({id: srcId}) => srcId === id) === -1);
        if(ids.length > 0){
          return products.filter(({id}) => ids.includes(id)); 
        }
      }
    }
  }

  verifyDeletedAccess(source: ProductResource[], dest: TypeSelected, products: ProductResource[]) : ProductResource[] {
    let { ids, selected } = dest;
    if(selected){
      if(ids && ids.length > 0){
        ids = ids.filter((id: number) => source.findIndex(({id: srcId}) => srcId === id) !== -1);
        if(ids.length > 0){
          const isNotMoved = source.every(({id}) => ids.includes(id));
          if(!isNotMoved){//au moins un element supprimé de la liste
            const itemRemoved = source.filter(({id}) => !ids.includes(id));
            return products.filter(({id}) => itemRemoved.findIndex((item:ProductResource) => item.id === id) !== -1);
          }
        }
      }else if(source.length > 0){
          return source;
      }
    }
  }
}


