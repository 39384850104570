import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import type { CustomerResource } from 'src/types/generated/models';
import { Box, Container } from '@mui/material';
import {
  _subscription,
  _customer,
  AccessModel,
  CustomerModel,
  root,
} from 'src/common';
import Page from 'src/components/Page';
import CustomerEditForm from './CustomerEditForm';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const CustomerEditView: FC = () => {
  const model = CustomerModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    if(access.canEditionGestion()){
      setLoading(true);
    }
  },[setLoading]);

  if(!loading) return <LoadingScreen />;

  return (
    <Page sx={root} title={model.getEditPageTitle(null)}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.BtnAddText} />
        <Box mt={3}>
          <SubscriptionProvider>
            <CustomerEditForm 
              model={model}
              customer={{ lawDomains: [], endorsedBy: null } as CustomerResource}
            />
          </SubscriptionProvider>
        </Box>
      </Container>
    </Page>
  )
};

export default CustomerEditView;
