import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import {
  Box,
  Container
} from '@mui/material';
import {
  useLocation,
  useParams,
  fetchDocumentType,
  _documentType,
  AccessModel,
  root,
} from 'src/common';
import Page from 'src/components/Page';
import DocumentTypeModel from 'src/model/content/DocumentType';
import DocumentTypeEditForm from './DocumentTypeEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const DocumentTypeEditView: FC = () => {
  const model = DocumentTypeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_documentType, { isLoading: false, documentType: {} });
  const { code } = useParams();
  
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(code)){
        const model = new DocumentTypeModel();
        const pathName = location.pathname;
        if (code) {
          model.PathName = pathName;
          const documentType = await model.getDocumentTypeById(code);
          if (!model.error) {
            dispatch(fetchDocumentType(documentType, true));
          }
        } else{
          model.PathName = pathName;
          dispatch(fetchDocumentType({}, true));
        }
      }
    };
    initialise();
  }, [code,location]);

  const { documentType, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;

  model.Title = documentType.nameFr;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(code) }
    >
      <Container maxWidth={false}>
        <GenericHeader id={code} model={model} headerTitle={code ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <DocumentTypeEditForm 
            model={model}
            documentType={documentType} 
            location={location} 
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocumentTypeEditView;
