import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { TribunalResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { jurisdictionConfig, labelConfig, tableRow, TribunalModel } from 'src/common';
import { tableCell } from 'src/styles/tribunal';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface TribunalFrInfoProps {
  model: TribunalModel;
  tribunal: TribunalResource;
}

const TribunalFrInfo: FC<TribunalFrInfoProps> = ({
  model,
  tribunal
}) => {
  const { CodeFr, LabelFr, Coverage, OtherCodesInput } = model;
  return (
    <MyCard>
    <CardHeaderDetail title="Tribunal" />
    <Divider />
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={tableCell}>
            { CodeFr.Label }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { tribunal.codeFr}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={tableCell}>
            { LabelFr.Label }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { tribunal.labelFr }
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={tableCell}>
            { labelConfig.juridiction }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { jurisdictionConfig[tribunal.juridiction] }
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={tableCell}>
            { labelConfig.type }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { tribunal.tribunalTypeObj?.labelFr }
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={tableCell}>
            { Coverage.Label }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { tribunal.coverage }
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow sx={tableRow}>
          <TableCell sx={tableCell}>
            { OtherCodesInput.Label }
          </TableCell>
          <TableCell>
            <Typography
              variant="body2"
              sx={{color:"text.secondary"}}
            >
              { tribunal.otherCodes.join(', ')}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </MyCard>
  );
};

TribunalFrInfo.propTypes = {
  tribunal: PropTypes.object.isRequired
};

export default TribunalFrInfo;
