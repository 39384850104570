import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, FormGroup, TextField, SvgIcon, IconButton, Typography, ListItemIcon } from '@mui/material';
import { 
  formatError, 
  labelConfig,
  errorConfig, 
  LawModel,
  CaijTooltip,
} from 'src/common';
import { X as DeleteIcon, XCircle as XCircleIcon } from 'react-feather';
import { PlusSquare as PlusSquareIcon } from 'react-feather';
import { StyledListItem } from 'src/components/styled';
import { Theme } from 'src/theme';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import CaijDialogs from 'src/components/dialog';

interface IndexingKeywordProps {
  model: LawModel;
  lawId?: number;
  lawIndexKeywords: string[];
  setLawIndexKeyword?: (lawIndexKeyword: string[]) => void;
}

interface IError {
    err: boolean;
    helperText: string;
};

async function verfiyLawIndexKeyword(lawId:number, word: string, lawIndexKeywords: string[]) : Promise<boolean> {
    if(lawIndexKeywords.findIndex((keyword : string) => keyword.toLowerCase() === word.toLowerCase()) != -1){
        return true;
    }else if(lawId){
      const model = new LawModel();
      const law = await model.getLawById(lawId.toString());
      if(!model.error) {
        return law.lawIndexKeywords.findIndex((keyword : string) => keyword.toLowerCase() === word.toLowerCase()) != -1;
      }
    }
    return false;
};

const IndexingKeyword: FC<IndexingKeywordProps> = ({
  model,
  lawId,
  lawIndexKeywords,
  setLawIndexKeyword
}) => {
    const [lawIndexingKeywordInput, setLawIndexingKeywordInput] = useState<string | undefined>('');
    const [state, setState] = useState<string[]>(lawIndexKeywords || []);
    const [selectedRow, setSelectedRow] = useState([]);
    const [error, setError] = useState<IError>({err: false, helperText: ''});
    model.Dialog.Header = "Supprimer le mot-clé d'indexation de la loi";
    model.Dialog.BtnText = BtnOk.DELETED;
    model.Dialog.Name = "le mot-clé d'indexation de la loi";

    useEffect(() => {
        setLawIndexKeyword(state);
    },[state]);

    const empty = () => {
        setLawIndexingKeywordInput('')
        setError({...error, err: false, helperText: ''});
    }

    const handleLawIndexingKeyWord = (lawIndexingKeyword: string): Promise<void> => {
      const newLawIndexingKeywords = [...state];
      const currentIndex = newLawIndexingKeywords.indexOf(lawIndexingKeyword);
      if (currentIndex === -1) {
          newLawIndexingKeywords.push(lawIndexingKeyword);
      } else {
          newLawIndexingKeywords.splice(currentIndex, 1);
      }
      setState(newLawIndexingKeywords);
      setError({err: false, helperText: ''});
      return;
    };

    const save = async (lawIndexingKeywordInput: string) => {
        if(!lawIndexingKeywordInput) {
            setError({
                err: true,
                helperText:(formatError(errorConfig.required, ['&1'], [labelConfig.lawIndexingKeyword])) 
            });
        }else if(await verfiyLawIndexKeyword(lawId, lawIndexingKeywordInput, state)){
            setError({
                err: true,
                helperText:(formatError(errorConfig.exist, ['&1'], [labelConfig.lawIndexingKeyword])) 
            });
        }else{
            setState([...state, lawIndexingKeywordInput]);
            setLawIndexingKeywordInput('');
            setError({...error, err: false, helperText: ''});
        }
    }
    return (
        <FormGroup>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <TextField 
                    type="text"
                    autoComplete="off"
                    name={model.IndexingKeyword.Name}
                    label={model.IndexingKeyword.Label}
                    value={lawIndexingKeywordInput}
                    sx={{ width: '100%'}}
                    helperText={error.helperText || ' '}
                    error={Boolean(error.err)}
                    margin="normal"
                    variant='outlined'
                    inputProps={{ maxLength: model.IndexingKeyword.MaxLength, autoComplete: 'off' }}
                    InputLabelProps={{ shrink: true}}
                    onChange={(e) => {
                        if(error.err){
                            setError({...error,err: false, helperText: ' '})
                        }
                        setLawIndexingKeywordInput(e.target.value)
                    }}
                    onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                        if(e.key === 'Enter'){
                          save(lawIndexingKeywordInput);
                          e.preventDefault();
                        }
                    }}
                    InputProps={{
                    endAdornment: (
                        <>
                            { lawIndexingKeywordInput && (
                                <CaijTooltip title="Effacer">
                                  <a onClick={empty} style={{padding: '0.4em 0 0 0', cursor:'pointer', fontSize:'14px'}}>
                                      <SvgIcon fontSize='small'>
                                        <DeleteIcon />
                                      </SvgIcon>
                                  </a>
                                </CaijTooltip>
                            )}
                        </>
                        )
                    }}
                />
                <CaijTooltip title="Ajouter" marginTop="-2px">
                  <IconButton sx={{marginLeft: '5px', marginBottom: '10px',marginRight: '5px', height: '40px', width:'40px'}} component="button" onClick={
                    async () => {
                      save(lawIndexingKeywordInput);
                    }
                  }>
                    <SvgIcon fontSize="small">
                      <PlusSquareIcon />
                    </SvgIcon>
                  </IconButton>
                </CaijTooltip>
            </Box>
            <Box>
               {
                    state && state.map((row: string, index: number) => 
                      <StyledListItem key={index}>
                        <Box display="flex" alignItems="center" width="100%">
                          <Box width="95%">
                            <Typography variant="body2" sx={{color: 'text.secondary'}}>{row}</Typography>
                          </Box>
                          <Box width="5%">
                            <ListItemIcon>
                              <CaijTooltip title="Supprimer">
                                <IconButton onClick={() => LawModel.handleToggle(row, selectedRow, setSelectedRow)}>
                                  <SvgIcon fontSize="small">
                                    <XCircleIcon />
                                  </SvgIcon>
                                </IconButton>
                              </CaijTooltip>
                            </ListItemIcon>
                          </Box>
                        </Box>
                        <Box>
                          <CaijDialogs
                            dialog={model.Dialog}
                            isOpen={selectedRow.indexOf(row) !== -1}
                            onSubmit={() => handleLawIndexingKeyWord(row)}
                            setSelectedRow={setSelectedRow}
                          />  
                        </Box> 
                      </StyledListItem>
                  )
                }
            </Box>
        </FormGroup>         
    );
};

IndexingKeyword.propTypes = {
  lawId: PropTypes.number,
  lawIndexKeywords: PropTypes.array,
  setLawIndexKeyword: PropTypes.func
};

export default IndexingKeyword;
