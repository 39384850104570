import { useState, useEffect, ChangeEvent } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  Box,
  Typography,
  SvgIcon,
  DialogTitle,
  IconButton,
  Autocomplete,
  TextField,
  Chip,
  DialogActions
} from '@mui/material';
import type { 
  DatabankResource, DocumentTypeResource,
} from 'src/common/types';
import { 
  Formik, 
  Mode,
  dialogTitle,
  button,
  CaijCard,
  NO_AUTHORIZE_ACCESS_MSG,
  DatabankModel,
  CaijTooltip
} from 'src/common';
import Authorize from 'src/components/Authorize';
import { 
  PlusSquare as AddIcon,
  XCircle as DeleteIcon 
} from 'react-feather';
import { XSquare as CancelIcon } from 'react-feather';
import { CheckCircle as OkIcon } from 'react-feather';
import { deleteIcon, dialog } from 'src/styles/customer';
import useDocumentType from 'src/hooks/useDocumentType';
import { btnAdd, listItemStyle } from 'src/styles/databank';

interface DatabankContentTypeInfoProps {
  databank: DatabankResource;
  model: DatabankModel;
}

interface DocumentType {
  documentTypes: DocumentTypeResource[];
  seletedItems: DocumentTypeResource[]
};

const DatabankContentTypeInfo: FC<DatabankContentTypeInfoProps> = ({ databank, model }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<DocumentType>({
    documentTypes: [],
    seletedItems: []
  });
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const { setSelectedDocumentTypes, selectedDocumentTypes, documentTypes, isLoading, isAuthorize } = useDocumentType();
  const title = 'AJOUTER UN TYPE DE CONTENU';
  
  useEffect(() => {
    if(databank){
      if(Array.isArray(databank.contentTypes) && databank.contentTypes.length > 0){
        const seletedItems = documentTypes.filter(({code}) => databank.contentTypes.includes(code));
        setState((prevState: DocumentType) => {
          return {
            ...prevState,
            contentTypes: documentTypes.filter(({code}) => !databank.contentTypes.includes(code)),
            seletedItems
          }
        });
        setSelectedDocumentTypes(seletedItems.sort((a: DocumentTypeResource, b: DocumentTypeResource) => a.nameFr.localeCompare(b.nameFr)));
      }else{
        setState((prevState: DocumentType) => {
          return {
            ...prevState,
            documentTypes
          }
        });
      }
    }
  }, [isLoading]);

  const handleClickOpen = () => {
    setOpen(true);
    const { seletedItems } = state;
    if(seletedItems.length > 0){
      setState({
        ...state,
        seletedItems: selectedDocumentTypes,
        documentTypes: documentTypes.filter(({code}) => selectedDocumentTypes.findIndex(({code : selectedCode}) => selectedCode == code) === -1)
      });
    }
  };

  const handleClose = (isClosed: boolean) => {
    setOpen(isClosed);
  };

  const handleChange = (event:ChangeEvent<{}>, selectedContentTypes : (DocumentTypeResource)[]) => {
    setState({
      ...state, 
      seletedItems: selectedContentTypes,
      documentTypes: documentTypes.filter(({code}) => selectedContentTypes.findIndex(({code : selectedCode}) => selectedCode === code) === -1)
    });
  }

  const deleteDocumentType = (code: string) : Promise<void> => {
    if(code){
      const { seletedItems } = state;
      const newSeletedItems = seletedItems.filter(({code: selectedCode}) => selectedCode !== code);
      setState({
        ...state,
        seletedItems: newSeletedItems,
        documentTypes: documentTypes.filter(({code}) => newSeletedItems.findIndex(({code: selectedCode}) => selectedCode == code) == -1)
      });
      setSelectedDocumentTypes(newSeletedItems);
    }
    return;
  };

  const renderAddButton = () => {
    if(isLoading && !isAuthorize){
        return (
          <Typography variant="subtitle1" color='text.error'>
            {NO_AUTHORIZE_ACCESS_MSG}
          </Typography>
        ) 
    }
    return (
        <Button
            startIcon={
                <SvgIcon>
                <AddIcon />
                </SvgIcon>
            }
            onClick={handleClickOpen}
            sx={btnAdd}
            >
            {title}
        </Button>
    )
  }

  const renderListItem = (code: string, nameFr: string) : JSX.Element => {
    return (
      <Box key={code} sx={listItemStyle}>
        <Typography variant='body2' sx={{color:'text.primary'}}>{nameFr}</Typography>
        <Authorize
          resourceCode={model.ResourceCode}
          mode={Mode.delete}
          onIsAuth={l => setIsDeleteAuth(l)}
        >
          <CaijTooltip title="Supprimer" disabled={!isDeleteAuth}>
            <IconButton sx={{...deleteIcon}} onClick={() => deleteDocumentType(code)} disabled={!isDeleteAuth}>
              <SvgIcon fontSize='small'>
                <DeleteIcon />
              </SvgIcon>
            </IconButton>
          </CaijTooltip>
        </Authorize>
      </Box>
    )
  }
  const dialogRender = () => {
    return (
      <Formik
        initialValues={{}}
        onSubmit={() => {
          setSelectedDocumentTypes(state.seletedItems.sort((a: DocumentTypeResource, b: DocumentTypeResource) => a.nameFr.localeCompare(b.nameFr)));
          handleClose(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
            <DialogContent sx={{...dialog, padding: '2em', width: 'auto'}} dividers >
              <Autocomplete
                multiple
                disableClearable
                value={state.seletedItems}
                options={state.documentTypes}
                getOptionLabel={({nameFr}) => nameFr}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Type de contenu"
                    placeholder="Rechercher..."
                    InputLabelProps={{shrink: true}}
                  />
                )}
                onChange={(event, value, reason) => handleChange(event,value as DocumentTypeResource[])}
                renderTags={(tagValue, getTagProps) => 
                    tagValue.map((option, index) => (
                      <Chip 
                        key={option.code} 
                        label={option.nameFr} 
                        color="default" 
                        {...getTagProps({index})}
                        disabled={state.seletedItems.indexOf(option) !== -1}
                       />
                    ))
                }
              />
            </DialogContent>
            <DialogActions sx={{...dialog, padding: '15px 10px 15px 0'}}>
              <Button 
                type="submit" 
                variant="outlined" 
                disabled={isSubmitting} 
                sx={button}
                startIcon={(
                  <SvgIcon fontSize="small">
                    <OkIcon />
                  </SvgIcon>
                )}
              >OK</Button>
              <Button  
                variant="outlined"
                type="reset"
                onClick={() => handleClose(false)}
                disabled={isSubmitting}
                startIcon={(
                  <SvgIcon fontSize="small">
                    <CancelIcon />
                  </SvgIcon>
                )}
              >Annuler</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <CaijCard title={'Type de contenu'} allowedBackgroundColor={false}>
      <Box display="flex" flexWrap='wrap'>
        { selectedDocumentTypes.map(({code, nameFr}) => renderListItem(code, nameFr)) } 
        { renderAddButton() }
      </Box>
      <Dialog maxWidth='md' fullWidth open={open}>
        {dialogRender()}
      </Dialog>
    </CaijCard>
  );
};

DatabankContentTypeInfo.propTypes = {
  databank: PropTypes.object.isRequired,
  model: PropTypes.any
};

export default DatabankContentTypeInfo;
