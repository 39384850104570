import React from 'react';
import type { FC } from 'react';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';

const Logo: FC<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = props => {
  const { settings } = useSettings();

  return (
    <img
      alt='Logo'
      src={/*settings.theme === THEMES.UNICORN ? '/static/logo-caij21.svg' : */'/static/logo-inverse.png'} // https://cdn.caij.qc.ca/assets/images/logo-caij21.svg
      width='100'
      {...props}
      onContextMenu={e => e.preventDefault()}
    />
  );
}

export default Logo;