import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type { LibraryTypeResource } from 'src/common/types';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  useLocation,
  useParams,
  fetchDetailsLibraryType,
  _libraryType,
  LibraryTypeModel,
  AccessModel,
  useNavigate,
  root
} from 'src/common';
import Page from 'src/components/Page';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

const LibraryTypeDetailsView: FC = () => {
  const model = LibraryTypeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_libraryType, { libraryType: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    subscription: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  const tabs = [
    { value: 'details', label: 'Détails' }
  ];
  model.Dialog.Header = 'Supprimer le type de la bibliothèque';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'le type de la bibliothèque';

  const getLibraryTypeById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const model = new LibraryTypeModel();
      model.PathName = location.pathname;
      const libraryType = await model.getLibraryTypeById(id);
      if (!model.error) {
        dispatch(fetchDetailsLibraryType(libraryType));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getLibraryTypeById();
    })();
  },[]);

  const setEditModeLibraryType = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeLibraryType(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setEditModeLibraryType(false);
    setCurrentTab(value);
  };

  const handleRefreshLibraryType = (libraryType: LibraryTypeResource) => {
    dispatch(fetchDetailsLibraryType(libraryType));
    setEditModeLibraryType(false);
  };

  const editLibraryType = () => {
    setEditModeLibraryType(true);
  };

  const { libraryType } = state;

  if (Object.keys(libraryType).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = libraryType.nameFr;

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <DetailsEditForm
              model={model}
              libraryType={libraryType}
              onHandleRefreshLibraryType={handleRefreshLibraryType}
              onSwitchMode={(l) => switchMode(l)}
            />
          );
        }
        return (
          <Details model={model} libraryType={libraryType} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editLibraryType()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default LibraryTypeDetailsView;
