import { CoveoLogs, CoveoSourceDto } from 'src/common/types';
import * as actionTypes from '../../../actions/ActionTypes';

type FetchCoveoLogsAction = {
    type: 'FETCH_COVEO_LOGS';
    payload: {
      coveoLogs: CoveoLogs[];
      coveoSources: CoveoSourceDto[];
      isLoading?: boolean;
      isEmptyList?: boolean;
    }
};

type Action = FetchCoveoLogsAction;

interface CoveoLogsState {
  coveoLogs: CoveoLogs[];
  coveoSources: CoveoSourceDto[];
  isLoading: boolean;
  isEmptyList: boolean;
}

const reducer = (state: CoveoLogsState, action: Action) => {
  switch (action.type) {
    case actionTypes.FETCH_COVEO_LOGS: {
      const { coveoLogs, coveoSources, isEmptyList, isLoading } = action.payload;
      return {
        ...state,
        coveoLogs,
        coveoSources,
        isLoading,
        isEmptyList
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
