import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ChangeEvent, FC, useState } from 'react';
import { CaijLocalizationProvider } from './LocalizationProvider';
import PropTypes from 'prop-types';
import { FORMAT_DATE_PICKER } from 'src/constants';
import dayjs, { Dayjs } from 'dayjs';
import useDatePicker from "src/functions/hooks/datePicker";
import { FormikErrors } from "formik";

interface CaijDatePickerProps {
  name: string;
  value?: string;
  label: string;
  required?: boolean;
  inputProps?: {'data-testid'?: string, 'aria-label'?:string};
  helperText?: FormikErrors<Date>;
  onHandleChange?: (date: Dayjs) => void;
  onHandleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  sx?: any;
}

const CaijDatePicker: FC<CaijDatePickerProps> = ({
  value,
  label,
  required,
  helperText,
  inputProps,
  onHandleChange,
  ...rest
}) => {
  const { error, message, setMessage, onError} = useDatePicker(helperText);
  const [cleared, setCleared] = useState<boolean>(true);
  const [val, setVal] = useState<Dayjs | null>(dayjs(value));
  
  return (
    <CaijLocalizationProvider useAdapterDayjs>
       <DatePicker
          {...rest}
          label={label} 
          value={val && val?.isValid() ? val : null}
          onError={(l) => onError(l)}
          format={FORMAT_DATE_PICKER}
          slotProps={{
            field: { clearable: cleared, onClear: () => {
              setMessage('');
              setCleared(false);
              setVal(null);
            }},
            textField: {
              helperText: message || ' ',
              inputProps: {...inputProps},
              required: required,
              error,
              InputLabelProps: {shrink: true},
              onBlur:() => {
                if(required && !val){
                  onError('required', label);
                }
              }
            },
          }}
          onChange={(e) => {
            onHandleChange(e)
            setVal(e);
            setCleared(true);
            if(error) onError('');
            if(message) setMessage('')
          }}
        />
    </CaijLocalizationProvider>
  );
}

CaijDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
  helperText: PropTypes.any,
  onHandleChange: PropTypes.func.isRequired,
};

CaijDatePicker.defaultProps = {
  required: false,
  helperText: ' ',
};

export default CaijDatePicker;
