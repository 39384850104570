import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import * as Msal from 'msal';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import { 
  Forbidden, 
  InternalServerError, 
  ServiceUnavailable, 
  Success, 
  Unauthorized 
} from 'src/common';
import { EmployeeUser, Error } from 'src/common/types';
import SplashScreen from 'src/components/SplashScreen';
import { apiConfig } from 'src/config';
import { User } from 'src/types/user';
import { loginRequest, msalConfig } from 'src/utils/authConfig';
import axios from 'src/utils/axios';

const myMSALObj = new Msal.UserAgentApplication(msalConfig);

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  isServiceAvailable: boolean;
  isInternalServerError: boolean;
  isForbidden: boolean;
  user: User | null;
  fullPath: string;
}

export interface AuthContextValue extends AuthState {
  method: 'AzureAd';
  login: () => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated?: boolean;
    isServiceAvailable?: boolean;
    isInternalServerError?: boolean;
    isForbidden?: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    isAuthenticated?: boolean;
    isServiceAvailable?: boolean;
    isInternalServerError?: boolean;
    isForbidden?: boolean;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitialiseAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isServiceAvailable: true,
  isInternalServerError: false,
  isForbidden: false,
  isInitialised: false,
  user: null,
  fullPath: null
};

interface IToken {
  exp: number;
}

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: IToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, isServiceAvailable, isInternalServerError, isForbidden, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        isServiceAvailable,
        isInternalServerError,
        isForbidden,
        user
      };
    }
    case 'LOGIN': {
      const { user, isInternalServerError, isAuthenticated, isServiceAvailable, isForbidden } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInternalServerError,
        isServiceAvailable,
        isForbidden,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'AzureAd',
  login: () => Promise.resolve(),
  logout: () => {}
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const getTokenPopup: (request: { scopes: string[] }) => Promise<Msal.AuthResponse> = (request: {
    scopes: string[];
  }) => myMSALObj.acquireTokenSilent(request).catch(async (error) => {
    console.log('silent token acquisition fails. acquiring token using popup', error);
    // fallback to interaction when silent call fails
    try {
      const tokenResponse = await myMSALObj.acquireTokenPopup(request);
      return tokenResponse;
    } catch (err) {
      console.log(err);
      return null;
    }
  });

  const getQuery = () => {
    return new Promise<AxiosResponse<EmployeeUser, any>>(async (resolve, reject) => {
      axios.get<EmployeeUser>(`${apiConfig.route.employee}/me`).then(
        (response) => {
          resolve(response);
        }
      ,(error) => { reject(error); })
    });
  }

  const getUser = (employee: EmployeeUser) => {
    const user: User = {
      name: `${employee.firstname} ${employee.lastname}`,
      email: employee.email,
      avatar: '',
      id: employee.email,
      employee
    };
    return user;
  }

  const dispatchUser = async (user:User, error:Error, type:'INITIALISE' | 'LOGIN') => {
    switch(error.status){
      case InternalServerError:
        dispatch({
          type,
          payload: {
            isInternalServerError: true,
            user: null
          }
        })
        break;
      case Forbidden:
      case Unauthorized:
        dispatch({
          type,
          payload: {
            isForbidden: true,
            user: null
          }
        })
        break;
      case ServiceUnavailable :
        dispatch({
          type,
          payload: {
            isServiceAvailable: false,
            user: null
          }
        })
        break;
      default :
        dispatch({
          type,
          payload: {
            isAuthenticated: true,
            isServiceAvailable: true,
            user
          }
        })
        break;  
    }
  };

  const dispatchError = () => {
    dispatch({
      type: 'INITIALISE',
      payload: {
        isAuthenticated: false,
        isServiceAvailable: true,
        user: null
      }
    });
  }

  const login = async () => {
    myMSALObj
      .loginPopup(loginRequest)
      .then(() => {
        if (myMSALObj.getAccount()) {
          getTokenPopup(loginRequest)
            .then(async (response) => {
              if (response) {
                setSession(response.accessToken);
                getQuery().then((response) => {
                  const { status, data} = response;
                  if(status === Success){
                    const user = getUser(data);
                    let error: Error = { status };
                    dispatchUser(user, error, 'LOGIN');
                  }
                },(error: Error) => { dispatchUser(null, error, 'LOGIN'); });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = () => {
    myMSALObj.logout();
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          getQuery().then((response) => {
            const { status, data } = response;
            if(status === Success){
              const user = getUser(data);
              let error: Error = { status };
              dispatchUser(user, error, 'INITIALISE');
              setSession(accessToken);
            }
          },(error: Error) => { dispatchUser(null, error, 'INITIALISE'); });
        } else {
          setSession(null);
          dispatchError();
        }
      } catch (err) {
        dispatchError();
      }
    };
    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'AzureAd',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default AuthContext;
