import axios from 'src/utils/axios';
import { MSG_NO_CONTENT_ERROR, NoContent, Success } from 'src/common';
import AppModel from './App';

export class HandlerModel<T> extends AppModel{

  async getById(route: string, id: number, params?: any) : Promise<T>{
    let result: T;
    await axios.get<T>(`${route}/${id}`, { params }).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						result = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
    return result;
  }
}