import { useEffect, useCallback, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Mode, EmployeeRole, Navigate } from 'src/common';
import useAuth from 'src/hooks/useAuth';
import { EmployeeUser } from 'src/common/types';

export interface AuthorizeProps {
  children?: ReactNode;
  resourceCode: string;
  mode: Mode;
  onIsAuth?: (val: boolean) => void;
}

export const getAuthorize = (employee: EmployeeUser, mode: Mode, resourceCode: string): boolean => {
  const { accesses, role } = employee;
  let isAuthorize: boolean;
  if (role === EmployeeRole.Admin) {
    isAuthorize = true;
  } else if (employee.role === EmployeeRole.User && accesses[resourceCode]) {
    switch (mode) {
      case Mode.add:
        isAuthorize = accesses[resourceCode].add;
        break;
      case Mode.read:
        isAuthorize = accesses[resourceCode].read;
        break;
      case Mode.edit:
        isAuthorize = accesses[resourceCode].edit;
        break;
      case Mode.delete:
        isAuthorize = accesses[resourceCode].delete;
        break;
    }
  }
  return isAuthorize;
};

const Authorize: FC<AuthorizeProps> = ({
  children,
  resourceCode,
  onIsAuth,
  mode
}) => {
  let employee: EmployeeUser;
  let isAuthenticated: boolean;
  const [show, setShow] = useState<boolean>();

  const authContext = useAuth();
  isAuthenticated = authContext.isAuthenticated;
  employee = authContext.user.employee;

  const authenticated = useCallback((employee: EmployeeUser) =>  {
    let isAuthorize: boolean;
    let show : boolean = true;
    isAuthorize = getAuthorize(employee, mode, resourceCode);
    if(mode === Mode.read){
      show = isAuthorize;
    }else if(mode == Mode.admin){
      show = employee.role === EmployeeRole.Admin;
    }
    onIsAuth(isAuthorize);
    setShow(show);
  },[setShow,onIsAuth]);

  useEffect(() => {
    authenticated(employee);
  },[employee, authenticated]);

  if (!isAuthenticated) {
    return <Navigate  to="/login" />;
  }

  return (
    <>
      { show && children }
    </>
  );
};

Authorize.propTypes = {
  children: PropTypes.node,
  resourceCode: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([1, 2, 3, 4, 5]),
  onIsAuth: PropTypes.func
};

Authorize.defaultProps = {
  onIsAuth: () => {}
};

export default Authorize;
