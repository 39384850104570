import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { DatabankResource, KnownDatabankStatusOption } from 'src/common/types';
import { DatabankModel, Label, labelConfig, tableRow } from 'src/common';
import { tableCell } from 'src/styles/databank';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import parse from 'html-react-parser';
import { CaijTableRow } from 'src/components/table/CaijTableRow';

export interface DatabankDetailEnInfoProps {
  model: DatabankModel;
  databank: DatabankResource;
}

const DatabankDetailEnInfo: FC<DatabankDetailEnInfoProps> = ({
  model,
  databank
}) => {
  const {NameEn, BannerEn, DescriptionEn, UnavailableMessageEn} = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Databank" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow label={NameEn.Label}>{databank.nameEn}</CaijTableRow>
          <CaijTableRow label={BannerEn.Label}>{databank.bannerEn}</CaijTableRow>
          <TableRow>
            <TableCell sx={tableCell}>{DescriptionEn.Label}</TableCell>
            <TableCell>
              { databank.descriptionEn && (
                  <div>{parse(databank.descriptionEn)}</div>
                )
              }
            </TableCell>
          </TableRow>
          <TableRow sx={databank.databankStatus == KnownDatabankStatusOption.Active ? tableRow : null}>
            <TableCell sx={tableCell}>Momentarily unavailable</TableCell>
            <TableCell>
              <div>
                <Label color={databank.databankStatus == KnownDatabankStatusOption.Inactive ? 'error' : 'success'}>{databank.databankStatus == KnownDatabankStatusOption.Inactive ? 'yes' : 'no'}</Label>
              </div>
            </TableCell>
          </TableRow>
          {
            databank.databankStatus == KnownDatabankStatusOption.Inactive && (
              <TableRow>
                <TableCell sx={tableCell}>{UnavailableMessageEn.Label}</TableCell>
                <TableCell>
                {
                  databank.unavailableMessage?.en && (
                    <div>{parse(databank.unavailableMessage?.en)}</div>
                  )
                }
                </TableCell>
              </TableRow>
            )
          }
          <TableRow sx={!databank.probe ? tableRow : null}>
            <TableCell sx={tableCell}>Automatically probed</TableCell>
            <TableCell>
              <div>
                <Label color={databank.probe ? 'error' : 'success'}>{databank.probe ? 'yes' : 'no'}</Label>
              </div>
            </TableCell>
          </TableRow>
          <CaijTableRow 
            label={labelConfig.libraryAccessInfoEn} 
            sxTableRow={tableRow} 
            sxTableCell={tableCell} 
            whiteSpace='pre-wrap'
          >
            {databank.accessInfoEn}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

DatabankDetailEnInfo.propTypes = {
  databank: PropTypes.object.isRequired
};

export default DatabankDetailEnInfo;
