import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Grid, 
  Button, 
  ListItem, 
  SvgIcon
} from '@mui/material';
import type { 
  CustomerNoteDto,
  CustomerResource, 
  CustomerResourceForEdit, 
  EndorsedCustomerResource
} from 'src/common/types';
import CustomerInfo from './CustomerInfo';
import PreferenceInfo from './PreferenceInfo';
import CaijCardInfo from './CaijCardInfo';
import CustomerLawPraticeFieldInfo from '../Forms/CustomerLawPraticeFieldForm';
import SubscriptionInfo from './SubscriptionInfo';
import CustomerEndorsedInfo from './CustomerEndorsedInfo';
import { 
  Authorize, 
  CustomerModel,  
  CustomerStatus,  
  Mode,
  _customer
} from 'src/common';
import { CheckCircle as ConfirmIcon } from 'react-feather';
import AccountInfo from './AccountInfo';
import printMessage from './../../../../errors/MessageError';
import AccessModel from 'src/model/Access';
import CaijDialogs from 'src/components/dialog';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import LibraryFeeInfo from './LibraryFeeInfo';
import PinNotesInfo from './PinNotesInfo';

interface DetailsProps {
  model: CustomerModel;
  endorsedBy?: EndorsedCustomerResource;
  customer?: CustomerResource;
  libraryFees: number;
  notes: CustomerNoteDto[];
  onTransactionMode: () => void;
  onHandleRefreshCustomer: () => Promise<void>;
  modifyCaijCard: (action: string) => void;
  switchTabNote: () => void;
}

const noStyle = {
  width: '100%',
  justifyContent: 'flex-start',
  fontWeight: 'normal',
  justifyItems: 'flex-start',
  display: 'flex',
};

const handleApprovedAccount = async (customer: CustomerResource, onHandleRefreshCustomer: () => Promise<void>) => {
  const { subscriptionGroup, lawDomains, endorsedBy} = customer;
  let submitData = customer as CustomerResourceForEdit;
  if(submitData && submitData.id){
    const model = new CustomerModel();
    model.saveRecords(submitData, subscriptionGroup, endorsedBy, lawDomains);
    submitData.status = CustomerModel.getStatusByVal(CustomerStatus.ActiveOnLogin);
    const response = await model.updateCustomer(submitData);
    if (!model.error) {
      printMessage({
        status: response.status,
        message: response.message
      });
      await onHandleRefreshCustomer();
    }  
  }
};

const Details: FC<DetailsProps> = ({
  model,
  endorsedBy,
  customer,
  libraryFees,
  notes,
  onTransactionMode,
  modifyCaijCard,
  onHandleRefreshCustomer,
  switchTabNote
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [open,setOpen] = useState<boolean>(false);

  const renderAccountApprovedButton = () : JSX.Element => {
    const model = new CustomerModel();
    model.Dialog.Header = "Approuver ce compte";
    model.Dialog.BtnText = BtnOk.APPROVED;
    model.Dialog.Name = "ce compte";
    return (
      <>
        <Authorize 
          resourceCode={model.ResourceCode}
          mode={Mode.edit}
          onIsAuth={l => setIsAuth(l)}
        >
          <Button  
            type="button"
            onClick={() => setOpen(true)}
            disabled={!isAuth}
            startIcon={(
              <SvgIcon fontSize="small">
                <ConfirmIcon />
              </SvgIcon>
          )}>Approuver ce compte</Button>
        </Authorize>   
        <CaijDialogs
          dialog={model.Dialog}
          isOpen={open}
          onSubmit={() => (async () => {
            await handleApprovedAccount(customer, onHandleRefreshCustomer);
            setOpen(false);
          })()}
          close={() => setOpen(false)}
        /> 
      </>
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        { notes && notes.length > 0 && (
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <PinNotesInfo notes={notes} switchTabNote={switchTabNote}/>
            </Grid>
          )
        }
        <Grid item lg={4} md={6} xl={4} xs={12}>
          {
            customer.endorsedBy && (
              <ListItem sx={{mb:2}}>
                { <CustomerEndorsedInfo 
                    model={model} 
                    endorsedBy={endorsedBy} 
                    customerId={customer.id} 
                    onHandleRefreshCustomer={onHandleRefreshCustomer} 
                  /> 
                }
              </ListItem>
            )
          }
          <ListItem sx={noStyle}>
            <CustomerInfo model={model} customer={customer} />
          </ListItem>
          <ListItem sx={noStyle}>
            <CustomerLawPraticeFieldInfo model={model} customer={customer} />{' '}
          </ListItem>
        </Grid>
        <Grid item lg={4} md={6} xl={4} xs={12}>
          <ListItem>
            <SubscriptionInfo customer={customer} />
          </ListItem>
          <ListItem>
            <LibraryFeeInfo libraryFees={libraryFees} />
          </ListItem>
          {
            AccessModel.accessCarteCaij(customer) && (
              <ListItem sx={noStyle}>
                <CaijCardInfo
                  summaryView
                  modifyCaijCard={modifyCaijCard}
                  onHandleRefreshCustomer={onHandleRefreshCustomer}
                  onTransactionMode={onTransactionMode}
                  customer={customer}
                />
              </ListItem>
            )
          }
        </Grid>
        <Grid item container direction='column' spacing={3} lg={4} md={6} xl={4} xs={12}>
          <Grid item>
            <ListItem>
              <PreferenceInfo customer={customer} />
            </ListItem>
          </Grid>
          <Grid item>
            <ListItem>
              <AccountInfo customer={customer} />
            </ListItem>
          </Grid>
        </Grid>
      </Grid>
      { customer.status === CustomerModel.getStatusByVal(CustomerStatus.ActiveUponApproval) && (
          <Grid container>
            <Grid item>
              <Box ml={2} display="flex" flexDirection="column" alignItems="flex-start">  
                {renderAccountApprovedButton()} 
              </Box>
            </Grid>
          </Grid>
        )
      }
    </>
  );
};

Details.propTypes = {
  customer: PropTypes.object.isRequired,
  endorsedBy: PropTypes.object,
  libraryFees: PropTypes.number.isRequired,
  notes: PropTypes.array.isRequired,
  onHandleRefreshCustomer: PropTypes.func,
  onTransactionMode: PropTypes.func
};

export default Details;
