import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  LibraryServiceResource,
  Error,
  LibraryServiceResourceForCreate,
  LibraryServiceResourceForEdit,
  LibraryServiceLibraryDto,
} from 'src/common/types';
import AppModel from '../App';
import { CreateResponse } from './../../types/createResponse';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceEdit = LibraryServiceResourceForCreate | LibraryServiceResourceForEdit;
type TypeMap = Map<number, string>;

export default class LibraryServiceModel extends AppModel 
{
  private static _instance: LibraryServiceModel;
  readonly NameFr = new FormModel('nameFr', 'Nom', 300);
  readonly NameEn = new FormModel('nameEn', 'Name', 300);
  readonly DetailsFr = new FormModel('detailsFr', 'Détail', 200);
  readonly DetailsEn = new FormModel('detailsEn', 'Detail', 200);
  readonly RequiredParameter = new FormModel('requireParameters','Quantité requise', 100);
  private _selectedQtyRequired: TypeMap;

  constructor(){
    super('/library/service');
    this.initialize();
  }
  
  private initialize(){
    this._resourceCode = 'LIBRARY';
    this._headerTitle =  'Liste des services de bibliothèques';
    this._btnAddText = 'Ajouter un service de bibliothèque';
    this.Path.PathName = '/libraries/services';

    //Dialog
    this.Dialog.Header = 'Supprimer le service de la bibliothèque';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le service de la bibliothèque';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Services',
      href: this.Path.Home,
      visible: true
    }
  }

  set SelectedQtyRequired(selectedQtyRequired: TypeMap) {
    this._selectedQtyRequired = selectedQtyRequired;
  }

  get SelectedQtyRequired(): TypeMap {
    return this._selectedQtyRequired;
  }

  getHeadCells(requireQty: string){
    return [
      {
        id: 'nameFr', width: '20%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: this.NameEn.Name, width: '20%', numeric: false, disablePadding: false, label: this.NameEn.Label
      },
      {
        id: 'detailsFr', width: '20%', numeric: false, disablePadding: false, label: this.DetailsFr.Label
      },
      {
        id: 'detailsEn', width: '20%', numeric: false, disablePadding: false, label: this.DetailsEn.Label
      },
      {
        id: '', width: '10%', numeric: false, disablePadding: false, label: requireQty
      },
      {
        id: '', width: '0%'
      }
    ];
  }
  
  static getInstance(): LibraryServiceModel {
    if (!LibraryServiceModel._instance) {
      LibraryServiceModel._instance = new LibraryServiceModel();
    }
    return LibraryServiceModel._instance;
  }

  static getInitialValues(values: LibraryServiceResource) : LibraryServiceResource {
    return {
      id: values.id,
      nameFr: values.nameFr || '',
      nameEn: values.nameEn || '',
      requireParameters: values.requireParameters,
      detailsFr: values.detailsFr || '',
      detailsEn: values.detailsEn || '',
      libraryServiceLibraries: values.libraryServiceLibraries || []
    }
  }

  async getLibraryServices(): Promise<LibraryServiceResource[]> {
    let libraryServices: LibraryServiceResource[];
    await axios.get<LibraryServiceResource[]>(this.route).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraryServices = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return libraryServices;
  }

  async getLibraryServiceById(id: string): Promise<LibraryServiceResource> {
    let libraryService: LibraryServiceResource;
    await axios.get<LibraryServiceResource>(`${this.route}/${+id}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            libraryService = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return libraryService;
  }

  async updateLibraryService(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<LibraryServiceResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
      async response => {
        const { status } = response;
        try {
          if (status === Success) {
            result = response;
            result.message = 'Service de bibliothèque modifié.';
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertLibraryService(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<LibraryServiceResourceForCreate, CreateResponse>(this.route, submitData).then(response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Service de bibliothèque créé.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          printMessage({
						status: status, 
						message: 'Service de bibliothèque supprimé.'
					});
          if(allowedRedirect)
            this.redirect(this.Path.Home);
          success = true;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  private getSelectedQtyRequired = (key: number): string => {
    let data: string = null;
    if (this.SelectedQtyRequired && this.SelectedQtyRequired.has(key)) {
      data = this.SelectedQtyRequired.get(key);
    }
    return data;
  };

  saveListRecords = (libraries: number[], submitData: TypeResourceEdit) => {
    const { requireParameters } = submitData;
    submitData.libraryServiceLibraries = libraries.reduce((initialValues: LibraryServiceLibraryDto[], id: number) => {
      const qtyRequired = +this.getSelectedQtyRequired(id);
      initialValues.push({
        libraryId: id,
        parameters: isNaN(qtyRequired) || !requireParameters ? null : String(qtyRequired),
      });
      return initialValues;
    }, []);
  }
}