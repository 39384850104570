import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import {
  Formik,
  handleChange,
  labelConfig,
  CaijInput,
  Authorize,
  Mode,
  button,
  ReperageModel,
  CustomerModel,
} from 'src/common';
import type { CustomerResource, GetCustomersAsyncOptionalParams } from 'src/common/types';
import CaijLoadingButton from 'src/components/buttons/CaijLoadingButton';
import CustomerSearchResults from './CustomerSearchResults';

export interface CustomerSearchDialogProps {
    model: ReperageModel;
    onClose: () => void;
    onSubmit?: (params: GetCustomersAsyncOptionalParams) => void;
    onSelect: (customer: CustomerResource) => void;
    open: boolean;
}

const CustomerSearchDialog: FC<CustomerSearchDialogProps> = ({onClose, onSelect, onSubmit, open, model}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [customers, setCustomers] = useState(null);
  const [loadingSearchResults, setLoadingSearchResults] = useState<boolean>(false);
  const { SearchCourriel, Firstname, Lastname, CarteCaij, NoBarreau } = model;
  
  const getCustomers = async (model: CustomerModel, params: GetCustomersAsyncOptionalParams) => {
    if(onSubmit) {
      onSubmit(params);
      return;
    }
    setLoadingSearchResults(true);
    const results = await model.getCustomers({...params, pageNumber: 1, pageSize: 5, status:"Active,ActiveOnLogin,ActiveUponApproval", productAccess:"PAPER_DIGITAL_SPOTTING" });
    setCustomers(results?.list);
    setLoadingSearchResults(false);
  }

  const handleSelect = (customer: CustomerResource) => {
    onSelect(customer);
    onClose();
  }

  return (
    <Dialog maxWidth='lg' open={open} onClose={onClose}>
        <DialogTitle>Recherche de membre</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              email: "",
              card: "",
              firstname: "",
              lastname: "",
              extIdentifier: ""
            }}
            onSubmit={(values) => getCustomers(new CustomerModel, values)}
          >
            {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CaijInput
                      name={Lastname.Name}
                      label={Lastname.Label}
                      value={values.lastname}
                      inputAttr={{ maxLength: Lastname.MaxLength, 'aria-label': labelConfig.code, "data-testid": "lastname" }}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CaijInput
                      name={Firstname.Name}
                      label={Firstname.Label}
                      value={values.firstname}
                      inputAttr={{ maxLength: Firstname.MaxLength, 'aria-label': labelConfig.code, "data-testid": "firstname" }}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CaijInput
                      name={NoBarreau.Name}
                      label={NoBarreau.Label}
                      value={values.extIdentifier}
                      inputAttr={{ maxLength: NoBarreau.MaxLength, 'aria-label': labelConfig.code, "data-testid": "noBarreau" }}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CaijInput
                      name={CarteCaij.Name}
                      label={CarteCaij.Label}
                      value={values.card}
                      inputAttr={{ maxLength: CarteCaij.MaxLength, 'aria-label': labelConfig.code, "data-testid": "carteCaij" }}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CaijInput
                      name={SearchCourriel.Name}
                      label={SearchCourriel.Label}
                      value={values.email}
                      inputAttr={{ maxLength: SearchCourriel.MaxLength, 'aria-label': labelConfig.code, "data-testid": "searchCourriel" }}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Authorize
                    resourceCode={model.ResourceCode}
                    mode={Mode.add}
                    onIsAuth={l => setIsAuth(l)}>
                    <CaijLoadingButton
                      disabled={!isAuth || isSubmitting}
                      loading={loadingSearchResults}
                      sx={button}
                      loadingPosition='start'
                      type="submit"
                    >Rechercher</CaijLoadingButton>
                  </Authorize>
                </Box>
              </form>
            )
            }
          </Formik>
          {customers &&
            <CustomerSearchResults customers={customers} onSelect={handleSelect} />
          }
        </DialogContent>
      </Dialog>
  )
}

CustomerSearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool
}

export default CustomerSearchDialog;