import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  Typography,
  Table, 
  TableBody, 
  TableRow, 
  TableCell, 
  Divider 
} from '@mui/material';
import type { EmailTemplateDto } from 'src/common/types';
import { labelConfig, tableRow, EmailTemplateModel } from 'src/common';
import { tableCell } from 'src/styles/emailTemplate';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import parse from 'html-react-parser';

export interface EmailTemplateFrInfoProps {
  model: EmailTemplateModel;
  emailTemplate: EmailTemplateDto;
}

const EmailTemplateFrInfo: FC<EmailTemplateFrInfoProps> = ({ model, emailTemplate }) => {
  const { SubjectFr } = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Gabarit courriel" />
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={tableCell}>{SubjectFr.Label}</TableCell>
            <TableCell>
              <Typography variant='body2' sx={{color:'text.secondary'}}>
                {emailTemplate.subjectFr}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={tableRow}>
            <TableCell sx={tableCell}>{labelConfig.messageFr}</TableCell>
            <TableCell>
              {
                emailTemplate.contentFr && (
                  <div>{parse(emailTemplate.contentFr)}</div>
                )
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

EmailTemplateFrInfo.propTypes = {
  emailTemplate: PropTypes.object.isRequired
};

export default EmailTemplateFrInfo;
