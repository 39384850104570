import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Divider, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { DatabankResource, KnownDatabankStatusOption } from 'src/common/types';
import { CaijImage, DatabankModel, Label, labelConfig, tableRow } from 'src/common';
import { tableCell } from 'src/styles/databank';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import parse from 'html-react-parser';
import { CaijTableRow, CaijTableRowActive, CaijTableRowConfirm } from 'src/components/table/CaijTableRow';

export interface DatabankDetailFrInfoProps {
  model: DatabankModel;
  databank: DatabankResource;
}

const DatabankDetailFrInfo: FC<DatabankDetailFrInfoProps> = ({ model, databank }) => {
  const { NameFr, BannerFr, DescriptionFr, Disclaimer, DatabankStatus, UnavailableMessageFr, Probe } = model;
  return (
    <MyCard>
      <CardHeaderDetail  title='Base de données' />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow label={NameFr.Label} sxTableCell={tableCell}>{databank.nameFr}</CaijTableRow>
          <CaijTableRow label={BannerFr.Label} sxTableCell={tableCell}>{databank.bannerFr}</CaijTableRow>
          <CaijTableRowActive label="Statut" sxTableCell={tableCell} active={databank.enabled} />
          <TableRow>
            <TableCell sx={tableCell}>{DescriptionFr.Label}</TableCell>
            <TableCell>
              { databank.descriptionFr && (
                  <div>{parse(databank.descriptionFr)}</div>
                )
              }
            </TableCell>
          </TableRow>
           <TableRow>
            <TableCell sx={tableCell}>{labelConfig.logo}</TableCell>
            <TableCell>{databank.logo && <CaijImage id="logo" src={databank.logo} alt={databank.logo} />}</TableCell>
          </TableRow>
          <TableRow sx={databank.databankStatus == KnownDatabankStatusOption.Inactive ? tableRow : null}>
            <TableCell sx={tableCell}>{DatabankStatus.Label}</TableCell>
            <TableCell>
              <div>
                 <Label color={databank.databankStatus == KnownDatabankStatusOption.Inactive ? 'error' : 'success'}>{databank.databankStatus == KnownDatabankStatusOption.Inactive ? labelConfig.yes : labelConfig.no}</Label>
              </div>
            </TableCell>
          </TableRow>
          {
            databank.databankStatus == KnownDatabankStatusOption.Inactive && (
              <TableRow>
                <TableCell sx={tableCell}>{UnavailableMessageFr.Label}</TableCell>
                <TableCell>
                {
                  databank.unavailableMessage?.fr && (
                    <div>{parse(databank.unavailableMessage?.fr)}</div>
                  )
                }
                </TableCell>
              </TableRow>
            )
          }
            <TableRow sx={!databank.probe ? tableRow : null}>
            <TableCell sx={tableCell}>{Probe.Label}</TableCell>
            <TableCell>
              <div>
                <Label color={databank.probe ? 'error' : 'success'}>{databank.probe ? labelConfig.yes : labelConfig.no}</Label>
              </div>
            </TableCell>
          </TableRow>
          <CaijTableRowConfirm label={Disclaimer.Label} confirm={databank.disclaimer} />
          <CaijTableRow 
            label={labelConfig.libraryAccessInfoFr} 
            sxTableRow={tableRow} 
            sxTableCell={{width: '300px'}}
            whiteSpace='pre-wrap'
          >
            {databank.accessInfoFr}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

DatabankDetailFrInfo.propTypes = {
  databank: PropTypes.object.isRequired,
};

export default DatabankDetailFrInfo;
