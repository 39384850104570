import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  fetchContentMostVieweds,
  _contentMostViewed,
  ContentMostViewedModel,
  AccessModel,
  deleteContentMostViewed,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  contentMostVieweds: [],
  isLoading: false,
  isEmptyList: true,
};

const ContentMostViewedListView: FC = () => {
  const model = ContentMostViewedModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_contentMostViewed, initialState);

  const getContentMostVieweds = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new ContentMostViewedModel();
      model.PathName = location.pathname;
      const contentMostVieweds = await model.getContentMostVieweds();
      if (!model.error) {
        dispatch(fetchContentMostVieweds(contentMostVieweds, true,!Array.isArray(contentMostVieweds) || contentMostVieweds.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getContentMostVieweds();
    })();
  }, []);

  const handleDelete: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteContentMostViewed(id));
    }
  };

  const { contentMostVieweds, isEmptyList, isLoading } = state;

  if (!isLoading) return <LoadingScreen />;
  model.Title = model.HeaderTitle;
  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            contentMostVieweds={contentMostVieweds}
            onDeleteContentMostViewed={handleDelete}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ContentMostViewedListView;
