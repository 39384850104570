import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  Error,
  CreateResponse,
  TribunalTypeResourceForCreate,
  TribunalTypeResourceForEdit,
  TribunalTypeResource
} from 'src/common/types';
import AppModel from '../../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../../Form';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceForEdit = TribunalTypeResourceForCreate | TribunalTypeResourceForEdit;

export default class TribunalTypeModel extends AppModel
{
  private static _instance: TribunalTypeModel;
  readonly LabelFr = new FormModel('labelFr','Nom',100);
  readonly LabelEn = new FormModel('labelEn','Name',100);
  
  constructor(){
    super('/content/caselaw/tribunal/type');
    this.initialize();
  }
  
  private initialize(){
    this._resourceCode = 'TRIBUNAL';
    this._headerTitle = 'Liste des types de tribunaux';
    this._btnAddText = 'Ajouter un type de tribunal';
    this.Path.PathName = '/courts/types';

    //Dialog
    this.Dialog.Header = 'Supprimer le type de tribunal';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le type de tribunal';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Types',
      href: this.Path.Home,
      visible: true
    }
  }

  getHeadCells(){
    return [
      {
        id: this.LabelFr.Name, width: '47%', numeric: false, disablePadding: false, label: this.LabelFr.Label
      },
      {
        id: this.LabelEn.Name, width: '47%', numeric: false, disablePadding: false, label: this.LabelEn.Label
      },
      {
        id: '', width: '5%'
      }
    ];
  }

  static getInstance(): TribunalTypeModel {
    if (!TribunalTypeModel._instance) {
      TribunalTypeModel._instance = new TribunalTypeModel();
    }
    return TribunalTypeModel._instance;
  }

  static getInitialValues(values: TribunalTypeResource) : TribunalTypeResource {
    return {
      id: values.id,
      labelFr: values.labelFr,
      labelEn: values.labelEn,
    }
  }

  async getTribunalTypes(): Promise<TribunalTypeResource[]> {
    let tribunalTypes: TribunalTypeResource[];
    await axios.get<TribunalTypeResource[]>(this.route).then(async response => {
      const { status, data } = response;
      try {
        if (status === Success) {
          tribunalTypes = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return tribunalTypes;
  }

  async getTribunalTypeById(id: string): Promise<TribunalTypeResource> {
    let tribunalType: TribunalTypeResource;
    await axios.get<TribunalTypeResource>(`${this.route}/${+id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          tribunalType = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return tribunalType;
  }

  async updateTribunalType(submitData: TypeResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<TribunalTypeResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Success) {
          result = response;
          result.message = 'Type de tribunal modifié.'
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertTribunalType(submitData: TypeResourceForEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<TribunalTypeResourceForCreate, CreateResponse>(this.route,submitData).then((response) => {
      const { status } = response;
      if (status === Created) {
        result = response;
        result.message = 'Type de tribunal créé.';
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then((response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Type de tribunal supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }

  async getRefreshTribunalType(id: string): Promise<TribunalTypeResource> {
    let newTribunalType : TribunalTypeResource;
    const tribunalType = await this.getTribunalTypeById(id);
    if(!this.error){
      newTribunalType = tribunalType;
    }
    return newTribunalType;
  };
}
