import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ProductResource, ProductResourceForEdit } from 'src/common/types';
import { Box, CardContent, Grid } from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  CaijButtonSubmit,
  CaijButtonReset,
  Mode,
  button
} from 'src/common';
import ProductModel from 'src/model/Product';
import printMessage from 'src/views/errors/MessageError';
import Authorize from 'src/components/Authorize';
import ProductForm from 'src/components/product/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: ProductModel;
  product: ProductResource;
  onHandleRefreshProduct: (value: ProductResource) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: ProductResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({ 
  model,
  product, 
  onHandleRefreshProduct, 
  onSwitchMode, 
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: ProductResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const submitData = values as ProductResourceForEdit;
    const productId = submitData.id; 
    if (submitData && productId) {
      const model = new ProductModel();
      const response = await model.updateProduct(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await onHandleRefreshProduct(await model.getRefreshProduct(productId.toString()));
      }
    }
  };

  return (
    <Formik
      initialValues={ProductModel.getInitialValues(product)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <ProductForm
                  model={model}
                  product={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Box mt={2}>
                <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={button} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  product: PropTypes.object.isRequired,
  onHandleRefreshProduct: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
