import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  Error,
  CreateResponse,
  TranslationResourceForCreate,
  TranslationResourceForEdit,
  TranslationResource,
  GetPagedTranslationsAsyncOptionalParams
} from 'src/common/types';
import AppModel from './App';
import { PagedList } from 'src/types/pagination';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from './Form';

export default class TranslationModel extends AppModel 
{
  private static _instance: TranslationModel;
  readonly Code = new FormModel('code','Code',50);
  readonly Fr = new FormModel('fr','Français',10000);
  readonly En = new FormModel('en','English',10000);

  constructor(){
    super('/translation');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'TRANSLATION';
    this._headerTitle = 'Liste des traductions';
    this._btnAddText = 'Ajouter une traduction';
    this.Path.PathName = '/translations';

    //Dialog
    this.Dialog.Header = 'Supprimer la traduction';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'la traduction';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Traductions',
      href: this.Path.Home,
      visible: true
    }
  }

  static getInstance(): TranslationModel {
    if (!TranslationModel._instance) {
      TranslationModel._instance = new TranslationModel();
    }
    return TranslationModel._instance;
  }

  async getTranslations(params: GetPagedTranslationsAsyncOptionalParams): Promise<PagedList<TranslationResource>> {
    let pagedList: PagedList<TranslationResource>;
    await axios.get<PagedList<TranslationResource>>(`${this.route}/paged`, { params }).then(
      async response => {
        const { status, data } = response;
        if (status === Success) {
          pagedList = data;
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return pagedList;
  }

  async getTranslationById(id: string | number): Promise<TranslationResource> {
    let translation: TranslationResource;
    await axios.get<TranslationResource>(`${this.route}/${typeof(id) === 'string' ? +id : id }`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            translation = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return translation;
  }

  async updateTranslation(submitData: TranslationResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<any, Error>(`${this.route}/${submitData.id}`, submitData).then(
      response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Traduction modifiée.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async insertTranslation(submitData: TranslationResourceForCreate): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<TranslationResourceForCreate, CreateResponse>(this.route, submitData).then(
      response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Traduction créée.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async deleteTranslation(id: number): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Traduction modifiée.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }
}
