import { LawModel } from 'src/common';
import type { LawResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchLaws = (laws: LawResource[], isLoading?: boolean, isEmptyList?: boolean, isAuthorize?: boolean, model?: LawModel): {
  type: 'FETCH_LAWS',
  payload: {
    laws: LawResource[],
    isLoading?: boolean,
    isEmptyList?: boolean,
    isAuthorize?: boolean,
    model?: LawModel
  }
} => ({
  type: actionTypes.FETCH_LAWS,
  payload: {
    laws,
    isLoading,
    isEmptyList,
    isAuthorize,
    model
  }
});

export const fetchLaw = (law: LawResource, isLoading?: boolean): { 
  type: 'FETCH_LAW', 
  payload: { 
    law: LawResource,
    isLoading?: boolean
  } 
} => ({
  type: actionTypes.FETCH_LAW,
  payload: {
    law,
    isLoading
  }
});

