import { FC, useCallback, useEffect, useReducer, useState } from "react";
import {
    Authorize,
    Mode,
    ReperageFolderStatus,
    ReperageFolderStatusColor,
    ReperageModel,
    _customer,
    fetchCustomerReperages,
    formatDateTime,
    EmptyList,
    PAPER_DIGITAL_SPOTTING,
    CaijButton,
    useNavigate,
    AccessModel,
    getEmptyListSearch,
    labelConfig,
    EnhancedTableHead,
    CaijTooltip,
    useLocation
} from "src/common";
import PropTypes from 'prop-types';
import MyCard from "src/components/card/MyCard";
import { Box, Chip, IconButton, SvgIcon, Tab, TableBody, Tabs } from "@mui/material";
import CaijTable from "src/components/table/CaijTable";
import { CustomerResource, ReperageResource } from "src/common/types";
import { Edit as EditIcon } from 'react-feather';
import CaijTablePagination from "src/components/pagination/CaijTablePagination";
import LoadingScreenCard from "src/components/loading/LoadingScreenCard";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CaijDialogs from "src/components/dialog";
import { BtnOk } from "src/components/dialog/CaijDialog";
import { CaijTableRowClickable } from "src/components/table/CaijTableRowClickable";
import useSort from "src/functions/hooks/sort";
import { CaijTableCell } from "src/components/table/CaijTableCell";

interface CustomerReperagesListProps {
    customer: CustomerResource;
}

const CustomerReperagesList: FC<CustomerReperagesListProps> = ({ customer }) => {
    const navigate = useNavigate();
    const { order, orderBy, handleRequestSort, sort } = useSort();
    const repModel = new ReperageModel();
    repModel.Dialog.Header = 'Supprimer le dossier de repérage';
    repModel.Dialog.BtnText = BtnOk.DELETED;
    repModel.Dialog.Name = 'le dossier de repérage';
    const access = new AccessModel(repModel.ResourceCode);
    const path = repModel.Path;
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [currentTab, setCurrentTab] = useState("in-progress");
    const [isCreateAuth, setIsCreateAuth] = useState<boolean>();
    const [selectedRow, setSelectedRow] = useState([]);
    const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
    const [isEditAuth, setIsEditAuth] = useState<boolean>();
    // const [deleted, setDeleted];
    const { noCaij, id, subscriptionGroup } = customer;
    const headCells = repModel.getProfilHeadCells(labelConfig.status);
    const location = useLocation();
    
    const [state, dispatch] = useReducer(_customer, {
        customerReperages: [],
        isLoading: false,
        isEmptyList: true
    });

    const handleTabsChange = (value: string) => {
        setCurrentTab(value);
        setPageNumber(0);
    }

    const getCustomerReperages = useCallback(async (): Promise<void> => {
        const reperages = await repModel.getReperages({
            pageNumber: 1,
            pageSize: 999,
            noCaij,
            sortOptions: 'asc', 
            sortedBy: 'name',
        });
        const { list } = reperages;
        dispatch(fetchCustomerReperages(list, true, !Array.isArray(list) || list.length === 0))
    }, [noCaij]);

    const onDeleteReperage: (id: number) => Promise<void> = async (id) => {
        if (id && access.canDelete()) {
            if (await repModel.deleteReperage(id)) {
                await getCustomerReperages();
            }
        }
    };

    useEffect(() => {
        (async () => {
            await getCustomerReperages();
        })();
    }, [getCustomerReperages]);


    const { customerReperages, isLoading, isEmptyList } = state;

    const displayReperages = customerReperages.filter(rep => currentTab === 'in-progress' ? !rep.archived : rep.archived);

    const printReperages = () => {
        if (!isLoading) {
            return <LoadingScreenCard />
        } else if (isLoading && isEmptyList) {
            return <EmptyList />
        }
        return (
            <>
                <CaijTable>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody>
                        {displayReperages.length === 0 ? getEmptyListSearch(headCells.length) : 
                            sort(displayReperages, pageNumber).map((row: ReperageResource) => {
                            return (
                                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                                    <CaijTableCell>{row.id}</CaijTableCell>
                                    <CaijTableCell>{row.name}</CaijTableCell>
                                    <CaijTableCell>{row.createdByLabel}</CaijTableCell>
                                    <CaijTableCell>{formatDateTime(row.dateCreated)}</CaijTableCell>
                                    <CaijTableCell>{formatDateTime(row.dateModified)}</CaijTableCell>
                                    <CaijTableCell>
                                        <Chip sx={{ padding: "0 1rem" }} color={ReperageFolderStatusColor[row.status]} label={ReperageFolderStatus[row.status]} />
                                    </CaijTableCell>
                                    <CaijTableCell cellAlign='right'>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Authorize resourceCode={repModel.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsEditAuth(l)}>
                                                <CaijTooltip title="Éditer" disabled={!isEditAuth}>
                                                    <IconButton disabled={!isEditAuth} onClick={(e) => {
                                                        e.stopPropagation();
                                                        navigate(path.getEdit(row.id), { state: { key: `${location.pathname}#reperages` } });
                                                    }}>
                                                        <SvgIcon fontSize='small'>
                                                            <EditIcon />
                                                        </SvgIcon>
                                                    </IconButton>
                                                </CaijTooltip>
                                            </Authorize>
                                            {currentTab !== "archived" &&
                                                <Authorize resourceCode={repModel.ResourceCode} mode={Mode.delete} onIsAuth={l => setIsDeleteAuth(l)}>
                                                    <CaijTooltip title="Supprimer" disabled={!isDeleteAuth}>
                                                        <IconButton title="Supprimer" disabled={!isDeleteAuth} onClick={(e) => {
                                                            e.stopPropagation();
                                                            ReperageModel.handleToggle(row.id, selectedRow, setSelectedRow);
                                                        }}>
                                                            <SvgIcon fontSize='small'>
                                                                <DeleteIcon />
                                                            </SvgIcon>
                                                        </IconButton>
                                                    </CaijTooltip>
                                                    <CaijDialogs
                                                        dialog={repModel.Dialog}
                                                        isOpen={selectedRow.indexOf(row.id) !== -1}
                                                        onSubmit={() => onDeleteReperage(row.id)}
                                                        setSelectedRow={setSelectedRow}
                                                    />
                                                </Authorize>
                                            }
                                        </Box>
                                    </CaijTableCell>
                                </CaijTableRowClickable>
                            )
                        }
                        )}
                    </TableBody>
                </CaijTable>
                <CaijTablePagination
                    showTablePaginationLight
                    len={displayReperages.length}
                    page={pageNumber}
                    setPage={(l) => setPageNumber(l)}
                />
            </>
        )
    }

    return (
        <MyCard>
            {subscriptionGroup.products?.findIndex(({ product }) => product.code === PAPER_DIGITAL_SPOTTING) != -1 && (
                <Box display="flex" justifyContent="flex-end">
                    <Authorize
                        resourceCode={repModel.ResourceCode}
                        mode={Mode.add}
                        onIsAuth={l => setIsCreateAuth(l)}
                    >
                        <CaijButton
                            type="add"
                            color='secondary'
                            variant='contained'
                            disabled={!isCreateAuth || customer.cardBalance === null}
                            onHandleClick={() => navigate(`${path.Create}/${id}`, { state: { key: `${location.pathname}#reperages` } })}
                        >
                            Ouvrir une nouvelle demande de repérage
                        </CaijButton>
                    </Authorize>
                </Box>
            )}
            <Box mt={2}>
                <Tabs
                    textColor="secondary"
                    scrollButtons='auto'
                    value={currentTab}
                    onChange={(e, value) => handleTabsChange(value)}
                >
                    <Tab
                        label="En Cours"
                        value="in-progress"
                    />
                    <Tab
                        label="Archivés"
                        value="archived"
                    />
                </Tabs>
                {printReperages()}
            </Box>
        </MyCard>
    )
}

CustomerReperagesList.propTypes = {
    customer: PropTypes.object.isRequired
}

export default CustomerReperagesList;