import { useEffect, useReducer } from "react";
import { _documentType, DocumentTypeModel, fetchDocumentTypes, Forbidden, Unauthorized } from "src/common";
import { FETCH_DOCUMENT_TYPES } from "src/store/actions/ActionTypes";

export default function useDocumentTypes() {
  const initialState = {
    isEmptyList: true,
    isLoading: true,
    documentTypes: []
  };
  const [state, dispatch] = useReducer(_documentType, initialState);
  useEffect(() => {
    const initialise = async () => {
      const model = new DocumentTypeModel();
      model.skipHandleError = true;
      const documentTypes = await model.getDocumentTypes();
      const { error } = model;
      if(error){
        if (error.status === Forbidden || error.status === Unauthorized) {
          dispatch(fetchDocumentTypes(documentTypes, false, true, false));
        }else{
          dispatch(fetchDocumentTypes(documentTypes, false, true, true));
        }
      }else{
        dispatch(fetchDocumentTypes(documentTypes, false, !Array.isArray(documentTypes) || documentTypes.length === 0, true));  
      }
    }
    (async () => {
      await initialise();
    })();
    return () => { dispatch({type: FETCH_DOCUMENT_TYPES, payload: initialState}); }
  },[]);
  const { documentTypes, isEmptyList, isLoading, isAuthorize } = state;
  return { 
    documentTypes, 
    isEmptyList,
    isLoading,
    isAuthorize,
    allChecked: !isEmptyList && !isLoading && isAuthorize
  };
};