import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { LibraryTypeResource } from 'src/common/types';
import LibraryTypeFrInfo from './LibraryTypeFrInfo';
import LibraryTypeEnInfo from './LibraryTypeEnInfo';
import { LibraryTypeModel } from 'src/common';

interface DetailsProps {
  model: LibraryTypeModel;
  libraryType: LibraryTypeResource;
}

const Details: FC<DetailsProps> = ({
  model,
  libraryType
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={4}
      md={6}
      xl={4}
      xs={12}
    >
      <LibraryTypeFrInfo model={model} libraryType={libraryType} />
    </Grid>
    <Grid
      item
      lg={4}
      md={6}
      xl={4}
      xs={12}
    >
      <LibraryTypeEnInfo model={model} libraryType={libraryType} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  libraryType: PropTypes.object.isRequired
};

export default Details;
