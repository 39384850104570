import { Box, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
import {
  Authorize,
  CaijButtonReset, 
  CaijButtonSubmit, 
  CaijCard,
  CaijSwitch, 
  DatabankModel, 
  Formik,
  handleChangeSwitch,
  handleChangeImageUrl,
  labelConfig, 
  Mode,
  btnSubmit,
  AccessModel,
  DatabankAppType,
  handleChangeSwitchEnum
} from 'src/common';
import {
  Error,
  CreateResponse,
  DatabankResource,
  DatabankResourceForCreate,
  DatabankResourceForEdit,
  KnownDatabankStatusOption
} from 'src/common/types';
import MyCard from 'src/components/card/MyCard';
import LibraryAccess from 'src/components/databank/Forms/LibraryAccess';
import CaijDialogs from 'src/components/dialog';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import CaijImage from 'src/components/image/CaijFormImage';
import UploadImage from 'src/components/image/UploadImage';
import useContentType from 'src/hooks/useDocumentType';
import useLawDomainField from 'src/hooks/useLawDomainField';
import printMessage from 'src/views/errors/MessageError';
import DatabankContentType from './DatabankContentType';
import DatabankLawDomainField from './DatabankLawDomainField';
import DatabankName from 'src/components/databank/DatabankName';
import CaijDescription from 'src/components/description';
import DatabankNote from 'src/components/databank/DatabankNote';
import DatabankBanner from 'src/components/databank/DatabankBanner';
import OtherActionForm from 'src/components/databank/Forms/OtherActionForm';

export interface DatabankEditFormProps {
  model: DatabankModel;
  databank: DatabankResource;
  onSubmit?: (values: DatabankResource) => void;
}

async function redirect(model: DatabankModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const DatabankEditForm: FC<DatabankEditFormProps> = ({ model, databank, onSubmit }) => {
  const { selectedLawDomainFields } = useLawDomainField();
  const { selectedDocumentTypes } = useContentType();
  const access = new AccessModel(model.ResourceCode);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<number[]>([]);
  const [selectedLibraries, setSelectedLibraries] = useState<number[]>([]);

  model.Dialog.Header = "Désactiver la base de donnée";
  model.Dialog.BtnText = BtnOk.DISABLED;
  model.Dialog.Name = 'la base de donnée';
  const { NameFr, NameEn, Note, Logo } = model;

  const handleSubmit = async (values: DatabankResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new DatabankModel();
    let submitData = JSON.parse(JSON.stringify(values)) as DatabankResourceForEdit;
    if (submitData && submitData.id) {
      model.saveRecords(submitData);
      model.saveListRecords(submitData, selectedSubscriptions, selectedLawDomainFields, selectedDocumentTypes, selectedLibraries);
      const response = await model.updateDatabank(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      submitData = JSON.parse(JSON.stringify(values)) as DatabankResourceForCreate;
      model.saveRecords(submitData);
      model.saveListRecords(submitData, selectedSubscriptions, selectedLawDomainFields, selectedDocumentTypes, selectedLibraries);
      delete submitData.id;
      const response = await model.insertDatabank(submitData);
      if (!model.error) {
        if(submitData.appType == DatabankAppType.Online){
          let { status, message } = response;
          model.redirectEmailMessage(status,'Un courriel a été envoyé aux gestionnaires d\'abonnements afin de valider les accès');
          printMessage({status,message});
        }else{
          await redirect(model, response);
        }
      }
    }
  }

  return (
    <Formik
      initialValues={{...databank}}
      validationSchema={Yup.object().shape({
        nameEn: NameEn.required(true),
        nameFr: NameFr.required(true),
        databankStatus: Yup.mixed<KnownDatabankStatusOption>().oneOf(Object.values(KnownDatabankStatusOption)),
      })}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, handleChange, setFieldValue, values, isSubmitting, setSubmitting, touched }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Box display="flex">
                    <CaijSwitch
                      disabled={(!access.UserAdmin && !access.Gestion)}
                      name={model.Enabled.Name}
                      checked={values.enabled}
                      value={values.enabled}
                      inputProps={{ 'data-testid': model.Enabled.Name }}
                      onHandleChangeSwitch={(event:ChangeEvent<HTMLInputElement>) => {
                        event.target.checked ? setOpenDialog(false) : setOpenDialog(true);
                        handleChangeSwitch(event, setFieldValue);
                      }}
                      label={model.Enabled.Label}
                    />
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={openDialog}
                      onSubmit={() => (async () => {
                        setFieldValue(model.Enabled.Name, false);
                      })()}
                      close={() => { 
                        !values.enabled ? setFieldValue(model.Enabled.Name, true) : '';
                        setOpenDialog(false);
                      }}
                    />
                  </Box>
                </Grid>
                <DatabankName 
                  model={model}
                  databank={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <DatabankBanner
                  model={model}
                  databank={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <CaijDescription
                  model={model}
                  data={values}
                  setFieldValue={setFieldValue}
                  allowedBackgroundColor={false}
                />
                <DatabankNote 
                  note={Note}
                  databank={values}
                  setFieldValue={setFieldValue}
                />
                <Grid item md={12} xs={12}>
                  <DatabankLawDomainField databank={databank} model={model} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <DatabankContentType databank={databank} model={model} />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CaijCard title={model.Logo.Label} allowedBackgroundColor={false}>
                    { values.logo && (
                        <CaijImage 
                          model={model}
                          id={model.Logo.Name} 
                          src={values.logo} 
                          alt={values.logo} 
                          setFieldValue={setFieldValue}
                          name={model.Logo.Name}
                        />
                      )
                    }
                    <UploadImage
                      imgLabel={Logo.Label}
                      maxLength={Logo.MaxLength}
                      resourceCode={model.ResourceCode} 
                      style={{ height: '3em' }}
                      btnText={values.logo ? labelConfig.logoEditBtnFr : labelConfig.logoBtnFr}
                      imgName={Logo.Name}
                      onHandleChangeImageUrl={handleChangeImageUrl}
                      setFieldValue={setFieldValue}
                    />
                  </CaijCard>
                </Grid>
                <Grid item md={12} xs={12}>
                  <CaijCard title={'Type de base de données'} allowedBackgroundColor={false}>
                    <LibraryAccess
                      model={model}
                      databank={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setSelectedSubscriptions={setSelectedSubscriptions}
                      setSelectedLibraries={setSelectedLibraries}
                      disableBtnSubmit={(l: boolean) => setSubmitting(l)}
                    />
                  </CaijCard>
                </Grid>
                <Grid item md={12} xs={12}>
                   <OtherActionForm
                      databank={values} 
                      model={model} 
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      handleChangeSwitchEnum={handleChangeSwitchEnum}
                    />
                </Grid>
              </Grid>
              <Box>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={databank.id ? Mode.edit : Mode.add}
                  onIsAuth={l => setIsAuth(l)}>
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} title="submit" />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} pathName={databank.id ? model.Path.getDetail(databank.id) : model.Path.Home} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DatabankEditForm.propTypes = {
  databank: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};

DatabankEditForm.defaultProps = {
  onSubmit: null
};

export default DatabankEditForm;
