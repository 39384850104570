import React, { useEffect, useCallback, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  fetchDocumentTypes,
  useLocation,
  _documentType,
  DocumentTypeModel,
  AccessModel,
  deleteDocumentType,
  root
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  documentTypes: [],
  isLoading: false,
  isEmptyList: true
};

const DocumentTypeListView: FC = () => {
  const model = DocumentTypeModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_documentType, initialState);

  const getDocumentTypes = useCallback(async () : Promise<void> => {
    if(access.canRead()){
      const model = new DocumentTypeModel();
      model.PathName = location.pathname;
      const documentTypes = await model.getDocumentTypes();
      if (!model.error) {
        dispatch(fetchDocumentTypes(documentTypes, true, !Array.isArray(documentTypes) || documentTypes.length === 0));
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      await getDocumentTypes();
    })();
  },[]);

  const handleDelete: (code: string) => Promise<void> = async (code) => {
    if(code && access.canDelete()) {
      if(await model.delete(code))
        dispatch(deleteDocumentType(code));
    }
  };

  const { documentTypes, isEmptyList, isLoading } = state;

  if (!isLoading) {
    return <LoadingScreen />
  }

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            model={model}
            documentTypes={documentTypes}
            onDeleteDocumentType={handleDelete}
            isEmptyList={isEmptyList}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default DocumentTypeListView;
