import { 
  Button, 
  DialogActions, 
  DialogContent, 
  DialogTitle,  
  SvgIcon, 
  Typography 
} from "@mui/material";
import { useState } from "react";
import { 
  Authorize, 
  CaijDatePicker, 
  CustomerModel,  
  dialog, 
  dialogTitle, 
  Formik, 
  Mode, 
  _customer 
} from "src/common";
import { CustomerResource } from "src/common/types";
import { validateAccountExpireSchema } from "../../../../views/customer/customer/Schema";
import { XSquare as CancelIcon, CheckSquare as SaveIcon} from 'react-feather';

export default function ResetAccountExpiryModeByDateSpecifiedAtCreation(props:{customer: CustomerResource, model: CustomerModel, handleClose: () => void, onSubmit: (value: Date) => void }){
  const { customer, model, handleClose, onSubmit} = props;
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  return (
    <>
    <Formik
      initialValues={{...customer, expiration: null}}
      validationSchema={validateAccountExpireSchema(model)}
      onSubmit={(values: CustomerResource) => onSubmit(values.expiration)}
      validateOnChange={true}
    >
      {({ handleSubmit, handleBlur, values, touched, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <DialogTitle sx={dialogTitle}>Mise à jour de la date d'expiration pour activer le compte</DialogTitle> 
          <DialogContent sx={dialog} dividers>
            <Typography variant="subtitle1" sx={{mb: '32px'}}>Nouvelle date d'expiration</Typography>
            <CaijDatePicker 
              name={model.MemberExpiration.Name}
              required
              value={String(values.expiration)}
              label="Choisir une date"
              inputProps={{"data-testid": model.MemberExpiration.Name, 'aria-label': 'expiration'}}
              helperText={touched.expiration && errors.expiration}
              onHandleChange={(l) => setFieldValue(model.MemberExpiration.Name, l)}
            />
          </DialogContent> 
          <DialogActions sx={{...dialog, flexDirection:'row'}}>
            <Authorize 
              resourceCode={model.ResourceCode} 
              mode={Mode.edit}
              onIsAuth={l => setIsEditAuth(l)}
            >
              <Button
                type='submit'
                disabled={!isEditAuth}
                color='secondary'
                variant='contained'
                startIcon={(
                  <SvgIcon fontSize="small">
                    <SaveIcon />
                  </SvgIcon>
                )}
              >
                Mettre à jour la date d'expiration et activer le compte
              </Button>
            </Authorize>
            <Button 
              type='reset' 
              onClick={() => handleClose()} 
              color='secondary' 
              variant='outlined'
              startIcon={(
                <SvgIcon fontSize="small">
                  <CancelIcon />
                </SvgIcon>
              )}
            >
              Annuler
            </Button>
            </DialogActions>
        </form>
      )}
    </Formik>
    </>
  )
}
