import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box, CardContent, Grid } from '@mui/material';
import {
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  useFormik,
  LawModel,
  btnSubmit,
  btnReset
} from 'src/common';
import type {
  Error,
  CreateResponse,
  LawResource,
  LawResourceForEdit,
  LawResourceForCreate
} from 'src/common/types';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import LawForm from 'src/components/content/law/Forms';
import { ILawHomePage } from 'src/types/law';

export interface LawEditFormProps {
  model: LawModel;
  law: LawResource;
  onSubmit?: (values: LawResource) => void
}

async function redirect(model: LawModel, response: Error | CreateResponse) : Promise<void>{
  await model.redirect(model.Path.Home, { 
    status: response.status, 
    message: response.message
  });
};

function validationSchema(model: LawModel) {
  const { Code, TitleFr, TitleEn, Jurisdiction, Type, Abreviation, FilterCaseLaw, LawReference, LawReferenceEn } = model;
  return Yup.object({
    code: Code.required(true),
    titleFr: TitleFr.required(true),
    titleEn: TitleEn.required(true),
    jurisdiction: Jurisdiction.required(true),
    lawReference: LawReference.required(true),
    lawReferenceEn: LawReferenceEn.required(true),
    type: Type.required(true),
    abreviation: Abreviation.nullable(true),
    filterCaseLaw: FilterCaseLaw.nullable(true)
  });
};

async function save(values: LawResource, lawHomePage: ILawHomePage, onSubmit: (values: LawResource) => void) : Promise<void> {
  if (onSubmit) {
    onSubmit(values);
    return;
  }
  const model = new LawModel();
  let submitData = values as LawResourceForEdit;
  if (submitData && submitData.id >= 0) {
    const newLawHomePage = [];
    for(const property in lawHomePage){
      if(lawHomePage[property]){
        newLawHomePage.push(lawHomePage[property]);
      }
    }
    submitData.lawHomePages = newLawHomePage;
    const response = await model.updateLaw(submitData);
    if (!model.error) {
      await redirect(model, response);
    }
  } else {
    submitData = values as LawResourceForCreate;
    const response = await model.insertLaw(submitData);
    if (!model.error) {
      await redirect(model, response); 
    }
  }
}

const LawEditForm: FC<LawEditFormProps> = ({
  model,
  law,
  onSubmit
}) => {
  const [lawHomePage, setLawHomePage] = useState<ILawHomePage>({
    fr: null,
    en: null
  });

  const { errors, handleBlur, setFieldValue, handleSubmit, handleChange, values, touched, isSubmitting } = useFormik({
    initialValues: {...law},
    validateOnChange:false,
    validationSchema: validationSchema(new LawModel),
    onSubmit: values => {
      save(values, lawHomePage, onSubmit);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <MyCard>
        <CardContent>
          <Grid container spacing={2}>
            <LawForm 
              model={model}
              law={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              onHandleChange={handleChange}
              setFieldValue={setFieldValue}
              onHandleLawHomePage={(l) => setLawHomePage(l)}
            />
          </Grid>
          <Authorize resourceCode={model.ResourceCode} mode={law.id ? Mode.edit : Mode.add}>
            <CaijButtonSubmit disabled={isSubmitting} sx={{...btnSubmit, mt: 1 }} />
          </Authorize>
          <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} sx={btnReset}/>   
        </CardContent>
      </MyCard>
    </form>
  );
};

LawEditForm.propTypes = {
  law: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

LawEditForm.defaultProps = {
  onSubmit : null
};

export default LawEditForm;
