import { LibraryServiceModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: LibraryServiceModel) => {
  const { NameFr, NameEn, DetailsFr, DetailsEn } = model;
  return Yup.object().shape({
    nameEn: NameEn.required(true),
    nameFr: NameFr.required(true),
    detailsFr: DetailsFr.nullable(true),
    detailsEn: DetailsEn.nullable(true)
  });
}

export default validateSchema;