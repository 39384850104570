import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
// import 'src/__mocks__'; //Only when testing with mocks
import React from 'react';// eslint-disable-line
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import reportWebVitals from './reportWebVitals';
import {createRoot} from "react-dom/client";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
  <SettingsProvider>
    <App />
  </SettingsProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
