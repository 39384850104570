import { EmployeeModel, ErrorType } from 'src/common';
import { EmployeesResource } from 'src/common/types';
import useEmployee from 'src/hooks/useEmployee';
import * as Yup from 'yup';

const validateSchema = (model: EmployeeModel, employee: EmployeesResource) => {
  const { verifyEmployeeEmail } = useEmployee();
  const { Firstname, Lastname, Card, Titre, Email } = model;
  return Yup.object().shape({
    firstname: Firstname.required(true),
    lastname: Lastname.required(true),
    card: Card.max(),
    title: Titre.max(),
    email: Email.required(true, true)
      .test(Email.Label, EmployeeModel.formatError(ErrorType.exist, Email.Label), async (value: string) => {
        if (value) {
          return await verifyEmployeeEmail(value, employee.id);
        }
        return true;
      })
  });
};

export default validateSchema;