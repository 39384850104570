import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  TableRow,
  Dialog, 
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  CustomerModel,
  CaijButton,
  tableRow,
  button,
  link,
  AccessModel,
  PendingChangeType,
  EnhancedTableHead,
} from 'src/common';
import type { CustomerAddressDto, CustomerPendingChangeResource } from 'src/common/types';
import { Link } from '@mui/material'
import CardRequestPendingModel from 'src/model/customer/CardRequestPending';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CardRequestPendingEditForm from '../CardRequestPendingEditView/CardRequestPendingEditForm';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  model: CardRequestPendingModel;
  customerPendingChanges: CustomerPendingChangeResource[];
  isEmptyList?: boolean,
  reload?: () => Promise<void>;
  onDeleteCardRequestPending?: (id: number) => Promise<void>;
}

const header = [
  { id: 'customer.noCaij', width: "20%", numeric: false, disablePadding: false, label: labelConfig.noCaij},
  { id: 'customer.fullname', width: "20%", numeric: false, disablePadding: false, label: labelConfig.nameFr},
  { id: 'customer.email', width: "20%", numeric: false, disablePadding: false, label: labelConfig.contactEmail},
  { id: '', width: "15%", numeric: false, disablePadding: false, label: "Type d'opération"},
  { id: '', width: "25%", numeric: false, disablePadding: true}
];

const Results: FC<ResultsProps> = ({
  model,
  customerPendingChanges,
  isEmptyList,
  reload,
  onDeleteCardRequestPending
}) => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedCardRequestPending, setSelectedCardRequestPending] = useState([]);
  const accessCarteCaijModel = new AccessModel(model.ResourceCode);
  const customerModel = CustomerModel.getInstance();
  const { order, orderBy, sort, handleRequestSort } = useSort();

  const renderContent = (): JSX.Element => {
    if (isEmptyList) {
      return <EmptyList />;
    }
    return (
      <MyCard>
        <CaijTable>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={header}
          />
          <TableBody>
            { sort<CustomerPendingChangeResource>(customerPendingChanges,0).map((row:CustomerPendingChangeResource) => {
              const allowedCardReplace = row.changeType ===  CustomerModel.getPendingChangeTypeByVal(PendingChangeType.CardReplace);
              const address: CustomerAddressDto = row.pendingValue ? JSON.parse(row.pendingValue) : null;
              return (
                <TableRow hover key={row.id} sx={tableRow}>
                  <CaijTableCell>{row.customer.noCaij}</CaijTableCell>
                  <CaijTableCell>
                    <Link 
                       underline="none" 
                       rel="noopener noreferrer"
                       target='_blank' 
                       variant='body2'
                       href={`${customerModel.Path.getDetail(row.customer.id)}#profile`}
                       sx={{...link,color:'text.secondary'}} 
                    >
                      {row.customer.fullname}
                    </Link>
                  </CaijTableCell>
                  <CaijTableCell>{row.customer.email}</CaijTableCell>
                  <CaijTableCell>{allowedCardReplace ? 'Remplacement de carte' : 'Demande de carte'}</CaijTableCell>
                  <CaijTableCell>
                    <Box display="flex" alignItems="center" justifyContent='right'>
                      <CaijButton 
                        disabled={(!accessCarteCaijModel.Edition && !accessCarteCaijModel.Gestion)} 
                        onHandleClick={() => CardRequestPendingModel.handleToggle(row.customer.id, selectedCardRequestPending, setSelectedCardRequestPending)} 
                        sx={{...button, minWidth: '200px'}}
                      >
                        Assigner la carte
                      </CaijButton>
                      <CaijButton 
                        variant="outlined" 
                        disabled={!accessCarteCaijModel.Gestion} 
                        sx={{minWidth: '230px'}}
                        onHandleClick={() => CardRequestPendingModel.handleToggle(row.customer.id,selectedRow,setSelectedRow)}
                      >
                        Supprimer la demande
                      </CaijButton>
                    </Box>
                    <Dialog open={selectedCardRequestPending.indexOf(row.customer.id) !== -1} maxWidth="md" fullWidth>
                      <CardRequestPendingEditForm
                        customerModel={customerModel} 
                        customer={row.customer}
                        handleClose={() => setSelectedCardRequestPending([])}
                        reload={reload} 
                        accessCarteCaijModel={accessCarteCaijModel}
                        address={address}
                      />
                    </Dialog>
                    <CaijDialogs
                      dialog={model.ConfigDialog}
                      isOpen={selectedRow.indexOf(row.customer.id) !== -1}
                      onSubmit={() => onDeleteCardRequestPending(row.customer.id)}
                      setSelectedRow={setSelectedRow}
                    />
                  </CaijTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </CaijTable>
      </MyCard>
    );
  };
  return <>{renderContent()}</>;
};

Results.propTypes = {
  customerPendingChanges: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool,
  reload: PropTypes.func,
  onDeleteCardRequestPending: PropTypes.func,
};

export default Results;
