import React, { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CardContent, Grid, SvgIcon } from '@mui/material';
import type {
  Error,
  CreateResponse,
  EmailTemplateDto
} from 'src/common/types';
import {
  Formik,
  CaijButtonSubmit,
  CaijButtonReset,
  Authorize,
  Mode,
  EmailTemplateModel,
  MSG_MESSAGE_TEMPLATE_INVALID,
  Other,
  btnSubmit
} from 'src/common';
import EmailTemplateForm from 'src/components/customer/emailTemplate/Forms';
import { Send as SendIcon } from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import printMessage from 'src/views/errors/MessageError';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../Schema';

export interface EmailTemplateEditFormProps {
  model: EmailTemplateModel;
  emailTemplate: EmailTemplateDto;
  onSubmit?: (values: EmailTemplateDto) => void;
}

async function redirect(model:EmailTemplateModel, response: Error | CreateResponse) : Promise<void> {
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const EmailTemplateEditForm: FC<EmailTemplateEditFormProps> = ({
  model,
  emailTemplate,
  onSubmit,
}) => {
  const ref = useRef(null);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isSending, setIsSending] = useState<boolean>(false);
  let { user } = useAuth();
 
  const initSending = () => {
    setIsSending(false);
    ref.current = null;
  }

  const handleSubmit = async (values: EmailTemplateDto) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new EmailTemplateModel();
    if(ref.current){//Send email test
      setIsSending(true);
      if(!model.verifyMessage(values.contentFr) && !model.verifyMessage(values.contentEn)){
        printMessage({
          status: 404,
          message: MSG_MESSAGE_TEMPLATE_INVALID
        });
        initSending();
        return;
      }
      const response = await model.testEmail(user.email, values);
      const { error } = model;
      if(!error){
        if(response.data){
          printMessage({
            status: response.status,
            message: response.message
          });
        }else{
          printMessage({
            status: Other,
            message: 'Courriel est envoyé sans succès'
          });
        }
      }
      initSending();
    }else if(values.id){
      const response = await model.updateEmailTemplate(values);
      if (!model.error) {
        await redirect(model, response);
      }
    }else{
      const response = await model.insertEmailTemplate(values);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{...emailTemplate}}
        validationSchema={validateSchema(model, emailTemplate.id)}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, setFieldValue, values, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <MyCard>
              <CardContent>
                <Grid container>
                  <EmailTemplateForm
                    model={model}
                    emailTemplate={values}
                    errors={errors}
                    touched={touched}
                    onHandleBlur={handleBlur}
                    onHandleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Box mt={2}>
                  <Authorize 
                    resourceCode={model.ResourceCode} 
                    mode={emailTemplate.id ? Mode.edit : Mode.add}
                    onIsAuth={l => setIsAuth(l)}
                  >
                    <Button
                      type="button" 
                      variant="outlined" 
                      color="primary"
                      disabled={isSending}
                      startIcon={
                        <SvgIcon>
                          <SendIcon />
                        </SvgIcon>
                      }
                      onClick={(e) => {
                        ref.current = true;
                        handleSubmit();
                      }}
                    >
                      Test courriel
                    </Button>
                  </Authorize>
                </Box>
                <Box mt={2}>
                  <Authorize 
                    resourceCode={model.ResourceCode} 
                    mode={emailTemplate.id ? Mode.edit : Mode.add}
                    onIsAuth={l => setIsAuth(l)}
                  >
                    <CaijButtonSubmit disabled={!isAuth || isSubmitting } sx={btnSubmit} />
                  </Authorize>
                  <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
                </Box>
              </CardContent>
            </MyCard>
          </form>
        )}
      </Formik>
    </>
  );
};

EmailTemplateEditForm.propTypes = {
  emailTemplate: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

EmailTemplateEditForm.defaultProps = {
  onSubmit: null,
};

export default EmailTemplateEditForm;
