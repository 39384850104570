import { DocumentTypeModel, ErrorType } from 'src/common';
import { DocumentTypeResource } from 'src/common/types';
import * as Yup from 'yup';

const verifyDocumentTypeCode = async (value: string, currentCode: string) : Promise<boolean> => {
  const model = DocumentTypeModel.getInstance();
  const documentTypes = await model.getDocumentTypes();
  const index = documentTypes.findIndex(({code}) => code.toLowerCase().trim() === value.toLowerCase().trim() && code !== currentCode);
    if (index >= 0) {
      return false;
    }
    return true;
}

const validateSchema = (model: DocumentTypeModel, documentType: DocumentTypeResource) => {
  const { Code, NameFr, NameEn } = model;
  return Yup.object().shape({
    nameEn: NameEn.required(true),
    nameFr: NameFr.required(true),
    code: Code.required(true)
      .test(Code.Label, DocumentTypeModel.formatError(ErrorType.exist, Code.Label), async (value: string) => {
        if (value) {
          return await verifyDocumentTypeCode(value, documentType.code);
        }
        return true;
      })
  });
};

export default validateSchema;