import { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { LawPracticeFieldResource } from 'src/common/types';
import LawPraticeFieldFrInfo from './LawPraticeFieldFrInfo';
import LawPraticeFieldEnInfo from './LawPraticeFieldEnInfo';
import { LawPracticeFieldModel } from 'src/common';

interface DetailsProps {
  model: LawPracticeFieldModel;
  lawPracticeField: LawPracticeFieldResource;
}

const Details: FC<DetailsProps> = ({
  model,
  lawPracticeField
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={4}
      md={6}
      xl={4}
      xs={12}
    >
      <LawPraticeFieldFrInfo model={model} lawPracticeField={lawPracticeField} />
    </Grid>
    <Grid
      item
      lg={4}
      md={6}
      xl={4}
      xs={12}
    >
      <LawPraticeFieldEnInfo model={model} lawPracticeField={lawPracticeField} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  lawPracticeField: PropTypes.object.isRequired
};

export default Details;
