import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  DatabankAppType
} from 'src/common';
import {
  DatabankResource,
  CreateResponse,
  DatabankResourceForCreate,
  DatabankResourceForEdit,
  DocumentTypeResource,
  LawDomainFieldResource,
  KnownDatabankAccessType,
  KnownDatabankStatusOption,
} from 'src/common/types';
import type {
	DatabankDto,
	Error, 
	GroupDto, 
	SubscriptionGroupResource
} from 'src/common/types'
import AppModel from './App';
import axios from 'src/utils/axios';
import { DatabankAccessLinkType } from 'src/common';
import { DatabankAccessType } from 'src/enum';
import { $enum } from 'ts-enum-util';
import { FormModel } from './Form';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceEdit = DatabankResourceForCreate | DatabankResourceForEdit;

export interface TypeSelected { ids: number[], selected: boolean }

export default class DatabankModel extends AppModel {
	private static _instance: DatabankModel;
	private accessesLibrary: DatabankResource[] = [];
	private _messages = {
		MSG_LIBRARY_PROXY_EMPTY: 'Le proxy ne doit pas avoir de valeur avec un accès à la bibliothèque'
	}

	readonly NameFr = new FormModel('nameFr', 'Nom', 255);
	readonly NameEn = new FormModel('nameEn', 'Name', 255);
	readonly AccessInfoFr = new FormModel('accessInfoFr', 'Conditions particulières à afficher', 500);
	readonly AccessInfoEn = new FormModel('accessInfoEn', 'Particulars terms to display', 500);
	readonly WebAccessUrl = new FormModel('webAccess.url', '', 2000);
	readonly EzproxyCollName = new FormModel('ezproxyCollName', 'Ezproxy', 45);
	readonly Logo = new FormModel('logo', 'Logo', 2000);
	readonly ShowInLibrary = new FormModel('showInLibrary', 'Afficher pour tous en bibliothèque');
  readonly Note = new FormModel('note','Note interne (non public)');
	readonly DescriptionFr = new FormModel('descriptionFr','Description (Français)');
  readonly DescriptionEn =  new FormModel('descriptionEn', 'Description (English)');
	readonly BannerFr = new FormModel('bannerFr','Bannière');
  readonly BannerEn = new FormModel('bannerEn', 'Banner');
	readonly Disclaimer = new FormModel('disclaimer', 'Afficher la clause de non-responsabilité');
  readonly Probe = new FormModel('probe', 'Tester automatiquement la disponibilité');
  readonly DatabankStatus = new FormModel('databankStatus', 'Momentanément indisponible');
  readonly Enabled = new FormModel('enabled', 'Active');
	readonly UnavailableMessageFr = new FormModel('unavailableMessage.fr', 'Message (Français)');
	readonly UnavailableMessageEn = new FormModel('unavailableMessage.en', 'Message (English)');

	constructor() {
		super('/databank');
		this.initialize();
	}

	private initialize() {
		this._resourceCode = 'DATABANK';
		this._headerTitle = 'Liste des bases de données';
		this._btnAddText = 'Ajouter une base de données';
		this.Path.PathName = '/databanks';
	}

	set Error(error: Error) {
		const { message } = error;
		if (Object.keys(this._messages).includes(message)) {
			this.error.message = this._messages[message];
		} else {
			this.error = error;
		}
	}

	get Error() {
		return this.error;
	}

	get Section() {
		return {
			resourceCode: this._resourceCode,
			title: 'Bases de données',
			href: this.Path.Home,
			visible: true,
		};
	}

	getHeadCells(status: string) {
		return [
			{
				id: 'nameFr',
				width: '75%',
				numeric: false,
				disablePadding: false,
				label: this.NameFr.Label,
			},
			{
				id: '',
				width: '15%',
				numeric: false,
				disablePadding: false,
				label: 'Disponibilité',
			},
			{
				id: '',
				width: '10%',
				numeric: false,
				disablePadding: false,
				label: status,
			},
			{
				id: '',
				width: '0%',
			},
		];
	}

	static getInstance(): DatabankModel {
		if (!DatabankModel._instance) {
			DatabankModel._instance = new DatabankModel();
		}
		return DatabankModel._instance;
	}

	static getAccessTypeByVal = (value: string) => {
		return $enum(DatabankAccessType).getKeyOrDefault(value);
	};

	static getAccessTypeByKey = (key: string) => {
		return $enum(DatabankAccessType).getValueOrDefault(key);
	};

	static getAccessLinkTypeByVal = (value: number) => {
		return $enum(DatabankAccessLinkType).getKeyOrDefault(value);
	};

	static getAccessLinkTypeByKey = (key: string) => {
		return $enum(DatabankAccessLinkType).getValueOrDefault(key);
	};

	set AccessesLibrary(accessesLibrary: DatabankResource[]){
		this.accessesLibrary = accessesLibrary;
	}

	get AccessesLibrary(){
		return this.accessesLibrary;
	}

	//#region CRUD
	async getDatabanks(): Promise<DatabankResource[]> {
		let databanks: DatabankResource[];
		await axios.get<DatabankResource[]>(this.route).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						databanks = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					if (!this.skipHandleError) {
						await this.handleError(this.error);
					}
				}
			},
			async (error) => {
				this.error = error;
				if (!this.skipHandleError) {
					await this.handleError(this.error);
				}
			},
		);
		return databanks;
	}

	async getDatabankById(id: string): Promise<DatabankResource> {
		let databank: DatabankResource;
		await axios.get<DatabankResource>(`${this.route}/${+id}`).then(
			async (response) => {
				const { status, data } = response;
				try {
					if (status === Success) {
						databank = data;
					} else if (status === NoContent) {
						throw new Error(MSG_NO_CONTENT_ERROR);
					}
				} catch (ex) {
					this.error = { status, message: ex.message };
					await this.handleError(this.error);
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return databank;
	}

	async updateDatabank(submitData: TypeResourceEdit) {
		let result: Error;
		await axios.put<DatabankResourceForEdit, Error>(`${this.route}/${submitData.id}`, submitData).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					result = response;
					result.message = 'Base de donnée modifiée.';
				} 
			},
			async (error) => {
				this.Error = error;
				await this.handleError(this.Error);
			},
		);
		return result;
	}

	async insertDatabank(submitData: TypeResourceEdit): Promise<CreateResponse> {
		let result: CreateResponse;
		await axios.post<DatabankResourceForCreate, CreateResponse>(this.route, submitData).then(
			async (response) => {
				const { status } = response;
				if (status === Created) {
					result = response;
					result.message = 'Base de donnée créée.';
				}
			},
			async (error) => {
				this.Error = error;
				await this.handleError(this.Error);
			},
		);
		return result;
	}

	async delete(id: string|number, allowedRedirect: boolean = false): Promise<boolean> {
		let success: boolean = false;
		await axios.delete<any, Error>(`${this.route}/${id}`).then(
			async (response) => {
				const { status } = response;
				if (status === Success) {
					printMessage({
						status: status, 
						message: 'Base de donnée supprimée.'
					});
					if(allowedRedirect)
          	this.redirect(this.Path.Home);
					success = true;
				}
			},
			async (error) => {
				this.error = error;
				await this.handleError(this.error);
			},
		);
		return success;
	}
	//#endregion

	saveRecords(submitData: DatabankResourceForCreate | DatabankResourceForEdit): void {
		if (submitData.databankStatus === KnownDatabankStatusOption.Active && submitData.unavailableMessage) {
			submitData.unavailableMessage.fr = null;
			submitData.unavailableMessage.en = null;
		}
	}

	saveListRecords(
		submitData: TypeResourceEdit,
		selectedSubscriptions: number[],
		selectedLawDomains: LawDomainFieldResource[],
		selectedContentTypes: DocumentTypeResource[],
		selectedLibraries: number[],
	): TypeResourceEdit {
		//Abonnement
		submitData.subscriptions = [];
		if (
			submitData.appType === DatabankAppType.Online &&
			submitData.databankAccesses.some(a => a.accessType === KnownDatabankAccessType.Remote)
		) {
			submitData.subscriptions = selectedSubscriptions;
		}
		//Bibliotheque
		submitData.libraries = [];
		if (
			submitData.appType === DatabankAppType.Desktop ||
			(submitData.appType === DatabankAppType.Online &&
				submitData.databankAccesses.length === 1 && submitData.databankAccesses.some(a => a.accessType === KnownDatabankAccessType.Library))
		) {
			submitData.libraries = selectedLibraries;
		}
		//Domaine de droit
		submitData.lawDomains = [];
		if (selectedLawDomains.length > 0) {
			submitData.lawDomains = selectedLawDomains.map((selectedLawDomain: LawDomainFieldResource) => selectedLawDomain.id);
		}
		//type de contenu
		submitData.contentTypes = [];
		if (selectedContentTypes.length > 0) {
			submitData.contentTypes = selectedContentTypes.map((selectedContentType: DocumentTypeResource) => selectedContentType.code);
		}
		return submitData;
	}

	verifyAddedAccess(source: DatabankResource[], dest: TypeSelected, databanks: DatabankResource[]): DatabankResource[] {
		let { ids, selected } = dest;
		if (selected) {
			if (source.length === ids.length && source.every(({id}) => ids.includes(id))) {
				return;
			} else {
				ids = ids.filter((id: number) => source.findIndex(({id: srcId}) => srcId === id) === -1);
				if (ids.length > 0) {
					return databanks.filter(({id}) => ids.includes(id));
				}
			}
		}
	}

	verifyDeletedAccess(source: DatabankResource[], dest: TypeSelected, databanks: DatabankResource[]): DatabankResource[] {
		let { ids, selected } = dest;
		if (selected) {
			if (ids && ids.length > 0) {
				ids = ids.filter((id: number) => source.findIndex(({id: srcId}) => srcId === id) !== -1);
				if (ids.length > 0) {
					const isNotMoved = source.every(({id}) => ids.includes(id));
					if (!isNotMoved) {
						//au moins un element supprimé de la liste
						const itemRemoved = source.filter(({id}) => !ids.includes(id));
						return databanks.filter(({id}) => itemRemoved.findIndex((item: DatabankResource) => item.id === id) !== -1);
					}
				}
			} else if (source.length > 0) {
				return source;
			}
		}
	}

	static async getAvailableDatabankList<T extends (GroupDto | SubscriptionGroupResource), V extends DatabankResource[]>(item: T, accessesLibrary : V) : Promise<void> {
		//Access Desktop & Online
		item.databanks = [...item.databanks].map((databank: DatabankDto) => { 
			if(databank?.databankAccesses?.some(({accessType}) => accessType === DatabankModel.getAccessTypeByVal(DatabankAccessType.Library)))
				databank.appType = DatabankAppType.Unknown;
				return databank;
		});
		//Access Desktop
		accessesLibrary?.forEach(({id, nameFr, enabled, appType}) => item.databanks.push({id,nameFr,enabled,appType}));	
	}

	static async getAccessLibraryDatabanks<T extends DatabankModel>(model: T) : Promise<DatabankResource[]>{
		model.skipHandleError = true;
		return (await model.getDatabanks())?.filter(({enabled, appType}) => enabled && appType === DatabankAppType.Desktop);
	}
}
