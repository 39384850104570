import {  
  CustomerModel,
  CustomerStatus,
  ExpertListFilter,
  useNavigate,
} from "src/common"
import { $enum } from 'ts-enum-util';
import { SearchFilter, SubscriptionResource } from "src/common/types";
import { AccessGroup } from "src/types/subscription";
import { FilterItem } from "./FilterItem";
import { Typography } from "@mui/material";

interface FilterListProps{
  model: CustomerModel, 
  colName: string;
  filter: SearchFilter;
  subscriptions: SubscriptionResource[];
  groups: AccessGroup;
}

const FilterList = (props: FilterListProps) => {
  const navigate = useNavigate();
  const { model, colName, filter, subscriptions, groups } = props;
  if(colName === model.ColName.status){
    return (
      <>
        { $enum(CustomerStatus).getValues().sort().map((value, index: number) => {
            if(value !== CustomerStatus.Restreint){
              return (
                <FilterItem
                  key={index}
                  navigate={navigate}
                  model={model}
                  filter={filter}
                  idx={3}
                  id={CustomerModel.getStatusByVal(value)}
                  name={value}
                  expertListFilter={ExpertListFilter.Status}
                />
              )
            }
          })    
        }
      </>
    )
  }else if(colName === model.ColName.group){
    return (
      <>
        { Object.keys(groups).length === 0 ? <Typography sx={{padding: '5px 10px'}}>Veuillez sélectionner un abonnement</Typography> 
            : Object.entries(groups)?.map(([key,value], index: number)=> (
            <FilterItem
              key={index}
              navigate={navigate}
              model={model}
              filter={filter}
              idx={21}
              id={value}
              name={key}
              expertListFilter={ExpertListFilter.Group}
            />
          )) 
        }
      </>
    )
  }
  return (
    <>
      { subscriptions && subscriptions.map(({id, name}, index: number) => (
          <FilterItem
            key={index}
            navigate={navigate}
            model={model}
            filter={filter}
            idx={13}
            id={id.toString()}
            name={name}
            expertListFilter={ExpertListFilter.Subscription}
          />
        )) 
      }
    </>
  )
}

export default FilterList;