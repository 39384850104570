import { Box, CardContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Formik, ListType, Mode, CaijButtonReset, CaijButtonSubmit, btnSubmit, PartnersModel } from 'src/common';
import type { PartnerResource, PartnerResourceForEdit } from 'src/common/types';
import Authorize from 'src/components/Authorize';
import MyCard from 'src/components/card/MyCard';
import printMessage from 'src/views/errors/MessageError';
import DocumentTypes from 'src/components/listInfo/checkList';

interface PartnerDocumentTypeFormProps {
  model: PartnersModel;
  partner: PartnerResource;
  reload: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
}

const PartnerDocumentTypeForm: FC<PartnerDocumentTypeFormProps> = ({ model, partner, reload, onSwitchMode }) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  return (
    <Formik
      initialValues={{...partner}}
      onSubmit={async (values: PartnerResource) => {
        const model = new PartnersModel();
        const submitData = values as PartnerResourceForEdit;
        const libraryId = submitData.id;
        if (submitData && libraryId) {
          const response = await model.updatePartner(submitData);
          if (!model.error) {
            printMessage({
              status: response.status,
              message: response.message
            });
            await reload();
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} lg={12} xs={12}>
                  <DocumentTypes
                    listType={ListType.DocumentType}
                    onHandleSelectedItems={l => setFieldValue(model.PartnerDocumentTypes.Name, l)}
                    lists={values.partnerDocumentTypes}
                  />
                </Grid>
              </Grid>
              <Box>
                <Authorize 
                  resourceCode={model.ResourceCode} 
                  mode={Mode.edit}
                  onIsAuth={l => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

PartnerDocumentTypeForm.propTypes = {
  partner: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
};

export default PartnerDocumentTypeForm;
