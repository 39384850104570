import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { PartnerResource } from 'src/common/types';
import PartnersFrInfo from './PartnersFrInfo';
import PartnersEnInfo from './PartnersEnInfo';
import { PartnersModel } from 'src/common';
import PartnersVisibilityInfo from './PartnersVisibilityInfo';

interface DetailsProps {
  model: PartnersModel;
  partner: PartnerResource;
}

const Details: FC<DetailsProps> = ({
  model,
  partner
}) => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <PartnersFrInfo model={model} partner={partner} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <PartnersEnInfo model={model} partner={partner} />
    </Grid>
    <Grid
      item
      lg={4}
      md={4}
      xl={4}
      xs={12}
    >
      <PartnersVisibilityInfo model={model} partner={partner} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  partner: PropTypes.object.isRequired
};

export default Details;
