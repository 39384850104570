import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext } from 'react';
import { EmployeeModel } from 'src/common';
import { EmployeesResource } from 'src/common/types';

export interface EmployeeContextValue {
  verifyEmployeeEmail: (value:string, id: number) => Promise<boolean>
}

interface EmployeeProviderProps {
  children: ReactNode;
}

const EmployeeContext = createContext<EmployeeContextValue>({
  verifyEmployeeEmail : (value:string, id: number) => new Promise(resolve => resolve(true))
});

export const EmployeeProvider: FC<EmployeeProviderProps> = ({ children }) => {
  
  const verifyEmployeeEmail = async (value: string, id: number) : Promise<boolean> => {
    const model = new EmployeeModel();
    const employees = await model.getEmployees();
    const index = employees.findIndex((employee: EmployeesResource) => employee.email.toLowerCase().trim() === value.toLowerCase().trim() && employee.id !== id);
      if (index >= 0) {
        return false;
      }
      return true;
  }

  return (
    <EmployeeContext.Provider
      value={{
        verifyEmployeeEmail
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

EmployeeProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default EmployeeContext;
