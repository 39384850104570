import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import Login from 'src/views/auth/LoginView'
import NotFound from 'src/views/errors/NotFoundView';
import ServiceUnavailableView from 'src/views/errors/ServiceUnavailableView';
import InternalServerErrorView from 'src/views/errors/InternalServerErrorView';
import ForbiddenView from 'src/views/errors/ForbiddenView';
import Acceuil from 'src/views/accueil';
import ReperageListView from 'src/views/reperages/ReperageListView';
import ProductListView from 'src/views/product/ProductListView';
import ProductEditView from 'src/views/product/ProductEditView';
import ProductDetailsView from 'src/views/product/ProductDetailsView';
import DatabankListView from 'src/views/databank/DatabankListView';
import DatabankEditView from 'src/views/databank/DatabankEditView';
import DatabankDetailsView from 'src/views/databank/DatabankDetailsView';
import LibraryListView from 'src/views/library/library/LibraryListView';
import LibraryEditView from 'src/views/library/library/LibraryEditView';
import LibraryDetailsView from 'src/views/library/library/LibraryDetailsView';
import LibraryTypeListView from 'src/views/library/libraryType/LibraryTypeListView';
import LibraryTypeEditView from 'src/views/library/libraryType/LibraryTypeEditView';
import LibraryTypeDetailsView from 'src/views/library/libraryType/LibraryTypeDetailsView';
import LibraryServiceListView from 'src/views/library/libraryService/LibraryServiceListView';
import LibraryServiceEditView from 'src/views/library/libraryService/LibraryServiceEditView';
import LibraryServiceDetailsView from 'src/views/library/libraryService/LibraryServiceDetailsView';
import DocCollectionListView from 'src/views/content/docCollection/DocCollectionListView';
import DocCollectionEditView from 'src/views/content/docCollection/DocCollectionEditView';
import DocCollectionDetailsView from 'src/views/content/docCollection/DocCollectionDetailsView';
import DocumentTypeListView from 'src/views/content/documentType/DocumentTypeListView';
import DocumentTypeEditView from 'src/views/content/documentType/DocumentTypeEditView';
import DocumentTypeDetailsView from 'src/views/content/documentType/DocumentTypeDetailsView';
import ContentMostViewedListView from 'src/views/content/contentMostViewed/ContentMostViewedListView';
import SubscriptionListView from 'src/views/subscription/SubscriptionListView';
import SubscriptionEditView from 'src/views/subscription/SubscriptionEditView';
import SubscriptionDetailsView from 'src/views/subscription/SubscriptionDetailsView';
import CustomerListView from 'src/views/customer/customer/CustomerListView';
import CustomerEditView from 'src/views/customer/customer/CustomerEditView';
import CustomerDetailsView from 'src/views/customer/customer/CustomerDetailsView';
import JugesAClasserListView from 'src/views/customer/jugesAClasser/JugesAClasserListView';
import SymphonyView from 'src/views/customer/symphony';
import LawPraticeFieldListView from 'src/views/customer/lawPraticeField/LawPraticeFieldListView';
import LawPraticeFieldEditView from 'src/views/customer/lawPraticeField/LawPraticeFieldEditView';
import LawPraticeFieldDetailsView from 'src/views/customer/lawPraticeField/LawPraticeFieldDetailsView';
import PlacholderListView from 'src/views/messages/PlacholderListView';
import PlaceholderDetailsView from 'src/views/messages/PlaceholderDetailsView';
import MessageCreateView from 'src/views/messages/PlaceholderDetailsView/MessageCreateView';
import MessageDetails from 'src/views/messages/PlaceholderDetailsView/MessageDetails';
import MessageEditView from 'src/views/messages/PlaceholderDetailsView/MessageEditView';
import TranslationListView from 'src/views/translation/TranslationListView';
import TranslationEditView from 'src/views/translation/TranslationEditView';
import TribunalListView from 'src/views/content/caseLaw/tribunal/TribunalListView';
import TribunalEditView from 'src/views/content/caseLaw/tribunal/TribunalEditView';
import TribunalDetailsView from 'src/views/content/caseLaw/tribunal/TribunalDetailsView';
import TribunalTypeListView from 'src/views/content/caseLaw/tribunalType/TribunalTypeListView';
import TribunalTypeEditView from 'src/views/content/caseLaw/tribunalType/TribunalTypeEditView';
import TribunalTypeDetailsView from 'src/views/content/caseLaw/tribunalType/TribunalTypeDetailsView';
import EmployeeListView from 'src/views/employee/employee/EmployeeListView';
import EmployeeEditView from 'src/views/employee/employee/EmployeeEditView';
import EmployeeDetailsView from 'src/views/employee/employee/EmployeeDetailsView';
import ResourceListView from 'src/views/employee/resource/ResourceListView';
import ResourceEditView from 'src/views/employee/resource/ResourceEditView';
import ResourceDetailsView from 'src/views/employee/resource/ResourceDetailsView';
import EmailTemplateListView from 'src/views/customer/emailTemplate/EmailTemplateListView';
import EmailTemplateEditView from 'src/views/customer/emailTemplate/EmailTemplateEditView';
import EmailTemplateDetailsView from 'src/views/customer/emailTemplate/EmailTemplateDetailsView';
import CardRequestPendingListView from 'src/views/customer/cardRequestPending/CardRequestPendingListView';
import DoorAccessProfileListView from 'src/views/doorAccessProfile/DoorAccessProfileListView';
import LawListView from 'src/views/content/Law/LawListView';
import LawEditView from 'src/views/content/Law/LawEditView';
import LawDomainFieldListView from 'src/views/lawDomainField/LawDomainFieldListView';
import LawDomainFieldEditView from 'src/views/lawDomainField/LawDomainFieldEditView';
import LawDomainFieldDetailsView from 'src/views/lawDomainField/LawDomainFieldDetailsView';
import Page from 'src/views';
import DocumentListView from 'src/views/content/document/DocumentListView';
import DocumentEditView from 'src/views/content/document/DocumentEditView';
import DocumentDetailsView from 'src/views/content/document/DocumentDetailsView';
import ApiKeyListView from 'src/views/apiKey/ApiKeyListView';
import ApiKeyEditView from 'src/views/apiKey/ApiKeyEditView';
import ContentPublicationsListView from 'src/views/content/contentPublications/ContentPublicationsListView';
import ContentPublicationsEditView from 'src/views/content/contentPublications/ContentPublicationsEditView';
import ContentPublicationsDetailsView from 'src//views/content/contentPublications/ContentPublicationsDetailsView';
import PendingApprovalListView from 'src/views/customer/pendingApproval/PendingApprovalListView';
import UxpertiseListView from 'src/views/uxpertise/UxpertiseListView';
import CommunauteJuridiqueView from './views/content/CommunauteJuridiqueView';
import { MenuContextProvider } from './contexts/MenuContext';
import SpecialFilesListView from './views/content/specialFiles/SpecialFilesListView';
import SpecialFileDetailsView from './views/content/specialFiles/SpecialFilesDetailsView';
import SpecialFilesEditView from './views/content/specialFiles/SpecialFilesEditView';
import CoveoSource from './views/coveo/source';
import CoveoHistory from './views/coveo/taskHistory';
import ReperageDetailsView from './views/reperages/ReperageDetailsView';
import ReperageEditView from './views/reperages/ReperageEditView';
import ReperageSendView from './views/reperages/ReperageSendView';
import CoveoLogs from './views/coveo/logs';
import { LoaderRoute } from './common';
import TribunalCodesNotAssignedListView from './views/content/caseLaw/tribunalCodesNotAssigned';
import LawDomainFieldChildListView from './views/lawDomainField/LawDomainFieldChildListView';
import { LawDomainFieldProvider } from './contexts/LawDomainFieldContext';
import PartnersListView from './views/content/partners/PartnersListView';
import PartnersEditView from './views/content/partners/PartnersEditView';
import PartnersDetailsView from './views/content/partners/PartnersDetailsView';
import DocCollectionsListView from './views/content/docCollections/DocCollectionsListView';
import DocCollectionsEditView from './views/content/docCollections/DocCollectionsEditView';
import DocCollectionsDetailsView from './views/content/docCollections/DocCollectionsDetailsView';

export type CaijRoutes = {
	path?: string;
	element?: any;
	children?: any;
	handle?: RouteHandles;
  }[];
  export type RouteHandles = {
	  crumb?:any;
  }
  const routes: CaijRoutes = [
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				index: true,
				element: <Navigate to='/app' />,
			},
			{
				path: '*',
				element: <Navigate to='/404' />,
			},
			{
				path: '404',
				element: <NotFound />,
			},
			{
				path: '/503',
				element: <ServiceUnavailableView />,
			},
			{
				path: '/403',
				element: <ForbiddenView />,
			},
			{
				path: '/500',
				element: <InternalServerErrorView />,
			},
		],
	},
	{
		path: '/login',
		element: (
			<GuestGuard>
				<Login />
			</GuestGuard>
		),
	},
	{
		path: '/app',
		element: (
			<AuthGuard>
				<MenuContextProvider>
					<DashboardLayout />
				</MenuContextProvider>
			</AuthGuard>
		),
		handle: {
			crumb: () => <span>Accueil</span>
		},
		children: [
			{
				index: true,
				element: <Acceuil />,
			},
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/subscription',
				element: <Navigate to="/app/management/subscriptions" />
			},
			/****************** END ********************/
		  {
				path: 'management/subscriptions',
				handle: {
					crumb: () => <span>Liste des abonnements</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <SubscriptionListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter un abonnement</span>
						},
						element: <SubscriptionEditView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
								element: <SubscriptionDetailsView />
							},
							{
								path: 'edit',
								element: <SubscriptionEditView />,
								handle: {
									crumb: () => <span>Éditer un abonnement</span>
								},
							},
							{
								path: 'group/:groupId',
								element: <SubscriptionDetailsView />
							},
							{
								path: 'group/:groupId/edit',
								element: <SubscriptionDetailsView />,
							}
							
						]
					},
				],
			},
			/************ TODO: À supprimer plus tard ***********/
			{
				path: 'management/databank',
				element: <Navigate to="/app/management/databanks" />
			},
			/****************** END ********************/
			{
				path: 'management/databanks',
				handle: {
					crumb: () => <span>Liste des bases de données</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <DatabankListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter une base de données</span>
						},
						element: <DatabankEditView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
								element: <DatabankDetailsView />
							},
							{
								path: 'edit',
								element: <DatabankEditView />,
								handle: {
									crumb: () => <span>Éditer une base de données</span>
								},
							}
						]
					}
				],
			},
//#region Library
			/************ TODO: À supprimer plus tard ***************/
			{
				path: 'management/library',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to="/app/management/libraries/list-of-libraries" />,
					},
					{
						path: 'library-service',
						element: <Navigate to="/app/management/libraries/services" />
					},
					{
						path: 'library-type',
						element: <Navigate to="/app/management/libraries/types" />
					}
				]
			},
			/****************** END ********************/
			{
				path: 'management/libraries',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to="list-of-libraries" />
					},
					{
						path: 'list-of-libraries',
						handle: {
							crumb: () => <span>Liste des bibliothèques</span>
						},
						children: [
							{
								index: true,
								element: <LibraryListView />,
							},
              {
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter une bibliothèque</span>
								},
								element: <LibraryEditView />,
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <LibraryDetailsView />,
									},
									{
										path: 'edit',
										element: <LibraryEditView />,
										handle: {
											crumb: () => <span>Éditer une bibliothèque</span>
										},
									}
								]
							}
						]
					},
					{
						path: 'services',
						handle: {
							crumb: () => <span>Liste des services de bibliothèques</span>
						},
						children: [
							{
								index: true,
								element: <LibraryServiceListView />,
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un service de bibliothèque</span>
								},
								element: <LibraryServiceEditView />,
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <LibraryServiceDetailsView />
									},
									{
										path: 'edit',
										element: <LibraryServiceEditView />,
										handle: {
											crumb: () => <span>Éditer un service de bibliothèque</span>
										},
									}
								]
							},
						],
					},
					{
						path: 'types',
						handle: {
							crumb: () => <span>Liste des types de bibliothèques</span>
						},
						children: [
							{
								index: true,
								element: <LibraryTypeListView />,
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un type de bibliothèque</span>
								},
								element: <LibraryTypeEditView />,
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <LibraryTypeDetailsView />
									},
									{
										path: 'edit',
										element: <LibraryTypeEditView />,
										handle: {
											crumb: () => <span>Éditer un type de bibliothèque</span>
										},
									}
								]
							}
						],
					}
				],
			},
//#endregion
//#region Contents
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/content',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to='/app' />,
					},
					{
						path: 'document-collection',
						element: <Navigate to='/app/management/contents/collections' />,
					},
					{
						path: 'content-most-viewed',
						element: <Navigate to='/app/management/contents/most-viewed' />,
					},
					{
						path: 'document',
						element: <Navigate to='/app/management/contents/documents' />,
					},
					{
						path: 'content-special-files',
						element: <Navigate to='/app/management/contents/special-files' />,
					},
					{
						path: 'content-publishers',
						element: <Navigate to='/app/management/contents/publishers' />,
					},
					{
						path: 'law',
						element: <Navigate to='/app/management/contents/annotated-laws' />,
					},
					{
						path: 'content-publications',
						element: <Navigate to='/app/management/contents/publications' />,
					},
					{
						path: 'document-type',
						element: <Navigate to='/app/management/contents/types' />,
					},
					{
						path: 'communaute-juridique',
						element: <Navigate to='/app/management/contents/legal-community' />,
					},
				]
			},
			/***************** END ***************/
			{
				path: 'management/contents',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to='/app' />,
					},
					{
						path: 'collections',
						element: <Page />,
						handle: {
							crumb: () => <span>Liste des classements du contenu</span>
						},
						children: [
							{
								index: true,
								element: <DocCollectionListView />,
							},
							{
								path: 'create',
								element: <DocCollectionEditView />,
								handle: {
									crumb: () => <span>Ajouter un classements du contenu</span>
								},
							},
							{  
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element:   <DocCollectionDetailsView />
									},
									{
										path: 'edit',
										element: <DocCollectionEditView />,
										handle: {
											crumb: () => <span>Éditer un classements du contenu</span>
										},
									}
								]
							},
						],
					},
					{
						path: 'document-collection',
						element: <Page />,
						handle: {
							crumb: () => <span>Liste des collections</span>
						},
						children: [
							{
								index: true,
								element: <DocCollectionsListView />,
							},
							{
								path: 'create',
								element: <DocCollectionsEditView />,
								handle: {
									crumb: () => <span>Ajouter une collection</span>
								},
							},
							{  
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <DocCollectionsDetailsView />
									},
									{
										path: 'edit',
										element: <DocCollectionsEditView />,
										handle: {
											crumb: () => <span>Éditer une collection</span>
										},
									}
								]
							},
						],
					},
					{
						path: 'most-viewed',
						handle: {
							crumb: () => <span>Liste des références pratiques - Doctrine</span>
						},
						children: [
							{
								index: true,
								element: <ContentMostViewedListView />,
							}
						],
					},
					{
						path: 'legal-community',
						handle: {
							crumb: () => <span>Éditer le document html pour Communauté Juridique</span>
						},
						element: <CommunauteJuridiqueView />,
					},
					{
						path: 'documents',
						handle: {
							crumb: () => <span>Liste des documents</span>
						},
						children: [
							{
								index: true,
								element: <DocumentListView />,
							},
							{
								path: ':documentId',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								loader: () => LoaderRoute.wc,
								children: [
									 {
										  path: 'edit',
											element: <DocumentEditView />,
											handle: {
												crumb: () => <span>Éditer un document</span>
											},
									 },
									
								]
							},
							{
								path: 'wc/:documentId',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								element: <DocumentDetailsView />,
							},
						]
					},
					{
						path: 'annotated-laws',
						handle: {
							crumb: () => <span>Liste des lois annotées</span>
						},
						children: [
							{
								index: true,
								element: <LawListView />,
							},
							{
								path: 'create',
								element: <LawEditView />,
								handle: {
									crumb: () => <span>Ajouter une loi annotée</span>
								},
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								loader: () => LoaderRoute.hidden,
								children: [
									{
										path: 'edit',
										element:<LawEditView />,
										handle: {
											crumb: () => <span>Éditer une loi annotée</span>
										},
									}
								]
							},
						],
					},
					{
						path: 'publications',
						handle: {
							crumb: () => <span>Liste des publications</span>
						},
						children: [
							{
								index: true,
								element: <ContentPublicationsListView />,
							},
							{
								path: 'create',
								element: <ContentPublicationsEditView />,
								handle: {
									crumb: () => <span>Ajouter une publication</span>
								},
							},
							{
								path: ':code',
								loader: () => LoaderRoute.wc,
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer une publication</span>
										},
										element: <ContentPublicationsEditView />,
									}
								]	
							},
							{
								path: 'wc/:code',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								element: <ContentPublicationsDetailsView />,
							},
						]
					},
					{
						path: 'types',
						handle: {
							crumb: () => <span>Liste des types de document</span>
						},
						children: [
							{
								index: true,
								element: <DocumentTypeListView />,
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un type de document</span>
								},
								element: <DocumentTypeEditView />,
							},
							{
								path: ':code',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								loader: () => LoaderRoute.wc,
								children: [
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer un type de document</span>
										},
										element: <DocumentTypeEditView />,
									}
								]
							},
							{
								path: 'wc/:code',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								element: <DocumentTypeDetailsView />,
							},
						],
					},
					{
						path: 'partners',
						handle: {
							crumb: () => <span>Liste des partenaires</span>
						},
						children: [
							{
								index: true,
								element: <PartnersListView />,
							},
							{
								path: 'create',
								element: <PartnersEditView />,
								handle: {
									crumb: () => <span>Ajouter un partenaire</span>
								},
							},
							{
								path: ':code',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								loader: () => LoaderRoute.wc,
								children: [
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer un partenaire</span>
										},
                    element: <PartnersEditView />,
									}
								]	
							},
							{
								path: 'wc/:code',
                handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								element: <PartnersDetailsView />,
							},
						]
					},
					{
						path: 'special-files',
						handle: {
							crumb: () => <span>Liste des dossiers spéciaux</span>
						},
						children: [
							{
								index: true,
								element: <SpecialFilesListView />
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un dossiers spécial</span>
								},
								element: <SpecialFilesEditView />
							},
							{
								path: ':documentId',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <SpecialFileDetailsView />
									},
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer un dossiers spécial</span>
										},
										element: <SpecialFilesEditView />
									}
								]
							}
						]
					}
				]
			},
//#endregion
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/law-domain-field',
				element: <Navigate to='/app/management/area-of-law' />,
			},
			/************* END **************/
			{
				path: 'management/area-of-law',
				handle: {
					crumb: () => <span>Liste des domaines de droit</span>
				},
				element: <Page />,
				children: [
					{ path: 'children/:parentId',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						element: <LawDomainFieldProvider><LawDomainFieldChildListView /></LawDomainFieldProvider>
					},
					{
						index: true,
						element: <LawDomainFieldListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter un domaine de droit</span>
						},
						element: <LawDomainFieldEditView />,
					},
					
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
								element: <LawDomainFieldDetailsView />
							},
							{
								path: 'edit',
								handle: {
									crumb: () => <span>Éditer un domaine de droit</span>
								},
								element: <LawDomainFieldEditView />,
							},
						]
					}
				],
			},
			{
				path: 'management/email-templates',
				handle: {
					crumb: () => <span>Liste des gabarits courriel</span>
				},
				children: [
					{
						index: true,
						element: <EmailTemplateListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter un gabarit courriel</span>
						},
						element: <EmailTemplateEditView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,

								element: <EmailTemplateDetailsView />
							},
							{
								path: 'edit',
								handle: {
									crumb: () => <span>Éditer un gabarit courriel</span>
								},
								element: <EmailTemplateEditView />
							}
						]
					}
				],
			},
//#region Members
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/customer',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to='/app/management/members/list-of-members' />,
					},
					{
						path: 'law-pratice-field',
						element: <Navigate to='/app/management/members/fields-of-practice' />,
					},
					{
						path: 'card-request-pending',
						element: <Navigate to='/app/management/members/card-request' />,
					},
					{
						path: 'pending-approval',
						element: <Navigate to='/app/management/members/pending-approval' />,
					},
					{
						path: 'juges-a-classer',
						element: <Navigate to='/app/management/members/new-judges' />,
					},
					{
						path: 'emailTemplate',
						element: <Navigate to='/app/management/email-templates' />,
					},
				]
			}
      /******************* END ***************** */
			,{
				path: 'management/members',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to='list-of-members' />,
					},
					{
						path: 'list-of-members',
						handle: {
							crumb: () => <span>Liste des membres</span>
						},
						children: [
							{
								index: true,
								element: <CustomerListView />,
							},
              {
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un membre</span>
								},
								element: <CustomerEditView />,
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								element: <CustomerDetailsView />,
							}
						]
					},
					{
						path: 'fields-of-practice',
						handle: {
							crumb: () => <span>Liste des champs de pratique</span>
						},
						children: [
							{
								index: true,
								element: <LawPraticeFieldListView />,
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un champs de pratique</span>
								},
								element: <LawPraticeFieldEditView />,
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <LawPraticeFieldDetailsView />
									},
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer un champs de pratique</span>
										},
										element:<LawPraticeFieldEditView />,
									}
								]
							}
						],
					},
					/*
					{
						path: 'upload',
						handle: {
							crumb: () => <span>Téléverser</span>
						},
						element: (
							<SubscriptionProvider>
								<UploadCustomerView />
							</SubscriptionProvider>
						),
					},*/
					{
						path: 'card-request',
						handle: {
							crumb: () => <span>Liste des demandes de carte CAIJ</span>
						},
						children: [
							{
								index: true,
								element: <CardRequestPendingListView />,
							}
						],
					},
					{
						path: 'new-judges',
						handle: {
							crumb: () => <span>Liste des juges à classer</span>
						},
						children: [
							{
								index: true,
								element: <JugesAClasserListView />,
							}
						],
					},
					{
						path: 'pending-approval',
						handle: {
							crumb: () => <span>Liste en attente d'approbation</span>
						},
						children: [
							{
								index: true,
								element: <PendingApprovalListView />,
							}
						],
					},
					/************* TODO: À supprimer plus tard *************/
					{
						path: 'emailTemplate',
						element: <Navigate to='/app/management/email-templates' />,
					}
					/****************** END ********************/
				],
			},
//#endregion
      {
				path: 'management/placeholders',
				handle: {
					crumb: () => <span>Liste d'emplacements</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <PlacholderListView />,
					},
					{
						path: ':id',
						loader: () => LoaderRoute.placeholder,
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
								element: <PlaceholderDetailsView />
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Créer un nouveau message</span>
								},
								element: <MessageCreateView />,
							},
						]
					},
					{
						path: 'messages',
						children: [
							{
								index: true,
								element: <Navigate to='/app/management/placeholders' />,
							},
							{
								path: ':id',
								loader: () => LoaderRoute.placeholder,
								handle: {
									crumb: (value:any) => <span>{value}</span>
								},
								children: [
                  {
										index: true,
										handle: {
											crumb: (value: any) => <span>{value}</span>
										},
										element: <MessageDetails />
									},
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer un message</span>
										},
										element:  <MessageEditView />
									}
								]
							}
						]
					}
				],
			},
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/product',
				element: <Navigate to='/app/management/products-services' />,
			},
			/***************** END ******************/
      {
				path: 'management/products-services',
				handle: {
					crumb: () => <span>Liste des produits et services</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <ProductListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter un produit et service</span>
						},
						element: <ProductEditView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
							  element: <ProductDetailsView />
							},
							{
								path: 'edit',
								handle: {
									crumb: () => <span>Éditer un produit et service</span>
								},
                element: <ProductEditView />
							}
						]
					}
				],
			},
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/reperages',
				element: <Page />,
				children: [
					{
						index: true,
            element: <Navigate to='/app/management/documentary-tracking' />,
					},
					{
						path: '#in-progress',
						element: <Navigate to='/app/management/documentary-tracking' />,
					}
				]
			},
			/**************** END **************/
			{
				path: 'management/documentary-tracking',
				element: <Page />,
				handle: {
					crumb: () => <span>Liste des repérages documentaires</span>
				},
				children: [
					{
						index: true,
						element: <ReperageListView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
								element: <ReperageDetailsView />,
							},
							{
								path: 'edit',
								element: <ReperageEditView />,
								handle: {
									crumb: () => <span>Édition de repérage</span>
								}
							},
							// {
							// 	path: 'send',
							// 	element: <ReperageSendView />,
							// 	handle: {
							// 		crumb: () => <span>Transmission de repérage</span>
							// 	},
							// }
						]
					},
					{
						path: 'create',
						element: <ReperageEditView />,
						handle:{
							crumb: () =><span>Nouvelle demande</span>
						},
						children:[
							{
								path: ':customerId'
							}
						]
					},
					{
						path: 'create/:customerId',
						element: <ReperageEditView />
					},
					{
						path: ':id/edit',
						element: <ReperageEditView />
					},
					{
						path: ':id/send',
						element: <ReperageSendView />
					}
				]
			},
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/translation',
				element: <Navigate to='/app/management/translations' />,

			},
			/***************** END ******************/
			{
				path: 'management/translations',
				handle: {
					crumb: () => <span>Liste des traductions</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <TranslationListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter une traduction</span>
						},
						element: <TranslationEditView />,
					},
				],
			},
//#region Tribunal
			/************* TODO: À supprimer plus tard *************/
			{
				path: 'management/tribunal',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to='/app/management/courts/list-of-courts' />,
					},
					{
						path: 'tribunal-type',
						element: <Navigate to='/app/management/courts/types' />,
					}
				]
			},
			/**************** END ***************/
			{
				path: 'management/courts',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to='list-of-courts' />,
					},
					{
						path: 'list-of-courts',
						handle: {
							crumb: () => <span>Liste des tribunaux</span>
						},
						children: [
							{
								index: true,
								element: <TribunalListView />,
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un tribunal</span>
								},
								element: <TribunalEditView />,
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <TribunalDetailsView />
									},
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer un tribunal</span>
										},
										element: <TribunalEditView />,
									}
								]
							}
						]
					},
					{
						path: 'types',
						handle: {
							crumb: () => <span>Liste des types de tribunaux</span>
						},
						children: [
							{
								index: true,
								element: <TribunalTypeListView />,
							},
							{
								path: 'create',
								handle: {
									crumb: () => <span>Ajouter un type de tribunal</span>
								},
								element: <TribunalTypeEditView />,
							},
							{
								path: ':id',
								handle: {
									crumb: (value: any) => <span>{value}</span>
								},
								children: [
									{
										index: true,
										element: <TribunalTypeDetailsView />
									},
									{
										path: 'edit',
										handle: {
											crumb: () => <span>Éditer un type de tribunal</span>
										},
										element: <TribunalTypeEditView />,
									}
								]
							}
						],
					},
					{
						path: 'codes-to-link',
						handle: {
							crumb: () => <span>Liste des codes tribunaux non assigné à un tribunal</span>
						},
						children: [
							{
								index: true,
								element: <TribunalCodesNotAssignedListView />,
							}
						],
					},
				],
			},
//#endregion
			{
				path: 'management/api-key',
				handle: {
					crumb: () => <span>Liste des clés d'API</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <ApiKeyListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter une clé d'API</span>
						},
						element: <ApiKeyEditView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						loader: () => LoaderRoute.hidden,
						children: [
							{
								path: 'edit',
								element: <ApiKeyEditView />,
								handle: {
									crumb: () => <span>Éditer une clé d'API</span>
								},
							}
						]
					},
				]
			},
//#region Coveo
			{
				path: 'management/coveo',
				element: <Page />,
				children: [
					{
						index: true,
						element: <Navigate to='source' />,
					},
					{
						path: 'source',
						handle: {
							crumb: () => <span>Liste des sources</span>
						},
						children:[
							{
								index: true,
								element: <CoveoSource />,
							}
						]
					},
					{
						path: 'history',
						handle: {
							crumb: () => <span>Historique des tâches</span>
						},
						children:[
							{
								index: true,
								element: <CoveoHistory />,
							}
						]
					},
					{
						path: 'logs',
						handle: {
							crumb: () => <span>Liste des logs</span>
						},
						children:[
							{
								index: true,
								element: <CoveoLogs />,
							}
						]
					}
				]
			},
//#endregion
			{
				path: 'management/door-access-profile',
				handle: {
					crumb: () => <span>Liste des Kantech - profils de cartes</span>
				},
				element: <DoorAccessProfileListView />,
			},
			{
				path: 'management/uxpertise',
				handle: {
					crumb: () => <span>Liste des groupes Uxpertise</span>
				},
				element: <UxpertiseListView />,
			},
			{
				path: 'management/symphony',
				handle: {
					crumb: () => <span>Liste des profils Symphony</span>
				},
				element: <SymphonyView />,
			},
			{
				path: 'management/employee',
				handle: {
					crumb: () => <span>Liste des utilisateurs</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <EmployeeListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter un utilisateur</span>
						},
						element: <EmployeeEditView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
								element: <EmployeeDetailsView />
							},
							{
								path: 'edit',
								element: <EmployeeEditView />,
								handle: {
									crumb: () => <span>Éditer un utilisateur</span>
								},
							}
						]
					}
				],
			},
			{
				path: 'management/resource',
				handle: {
					crumb: () => <span>Liste des resources</span>
				},
				element: <Page />,
				children: [
					{
						index: true,
						element: <ResourceListView />,
					},
					{
						path: 'create',
						handle: {
							crumb: () => <span>Ajouter une resource</span>
						},
						element: <ResourceEditView />,
					},
					{
						path: ':id',
						handle: {
							crumb: (value: any) => <span>{value}</span>
						},
						children: [
							{
								index: true,
								element: <ResourceDetailsView />
							},
							{
								path: 'edit',
								element: <ResourceEditView />,
								handle: {
									crumb: () => <span>Éditer une resource</span>
								},
							}
						]
					}
				],
			},
		],
	},
];

export const renderRoutes = (routesList: CaijRoutes = []): JSX.Element => useRoutes(routesList);

export default routes;
