import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import { CaijInput, FormikErrors, FormikTouched, LawPracticeFieldModel} from 'src/common';
import type { LawPracticeFieldResource } from 'src/common/types';

interface LawPraticeFieldFormProps {
  model: LawPracticeFieldModel;
  lawPraticeField: LawPracticeFieldResource;
  errors: FormikErrors<LawPracticeFieldResource>;
  touched: FormikTouched<LawPracticeFieldResource>;
  onHandleBlur: (e: any) => void;
  onHandleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const LawPraticeFieldForm: FC<LawPraticeFieldFormProps> = ({
  model,
  lawPraticeField,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const { code, nameFr, nameEn } = lawPraticeField;
  const { Code, NameFr, NameEn } = model;
  return (
    <>
       <Grid container spacing={2}>
        <Grid
          item
          md={12}
          xs={12}
        >
          <CaijInput
            name={Code.Name}
            required
            value={code}
            label={Code.Label}
            helperText={touched.code && errors.code}
            error={Boolean(touched.code && errors.code)}
            InputLabelProps={{ shrink: true, required: true }}
            inputAttr={{ maxLength: Code.MaxLength, 'data-testid': Code.Name}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CaijInput
            name={NameFr.Name}
            margin="none"
            value={nameFr}
            helperText={touched.nameFr && errors.nameFr}
            error={Boolean(touched.nameFr && errors.nameFr)}
            label={NameFr.Label}
            InputLabelProps={{ shrink: true }}
            inputAttr={{maxLength: NameFr.MaxLength, 'data-testid': NameFr.Name}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <CaijInput
            name={NameEn.Name}
            margin="none"
            value={nameEn}
            label={NameEn.Label}
            helperText={touched.nameEn && errors.nameEn}
            error={Boolean(touched.nameEn && errors.nameEn)}
            InputLabelProps={{ shrink: true }}
            inputAttr={{maxLength: NameEn.MaxLength,  'data-testid': NameEn.Name}}
            onHandleBlur={onHandleBlur}
            onHandleChange={onHandleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
    </>
  );
};

LawPraticeFieldForm.propTypes = {
  lawPraticeField: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default LawPraticeFieldForm;
