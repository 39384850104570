import {
  Success,
  Created,
  Conflit,
  MSG_UXPERTISE_DESACTIVATE_CONFLIT
} from 'src/common';
import type {
  CreateResponse,
  Error,
  UxpertiseDto
} from 'src/common/types';
import AppModel from './App';
import axios from 'src/utils/axios';

export default class UxpertiseModel extends AppModel 
{
  private static _instance: UxpertiseModel;
 
  readonly Status: string = 'Statut';
  readonly Name: string = 'Nom';
  readonly Action: string = 'Action';
  
  constructor(){
    super('/learning');
    this._headerTitle = 'Liste des groupes Uxpertise';
    this._resourceCode= 'LEARNING';
    this.Path.PathName = '/uxpertise';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Uxpertise',
      href: this.Path.Home,
      visible: true
    }
  }

  setConflit(error: Error) {
		const { status } = error;
		switch(status){
			case Conflit:
				this.error.message = MSG_UXPERTISE_DESACTIVATE_CONFLIT; 
        break;
			default:
				this.error = error;
				break;
		}
	}

  get Conflit(){
		return this.error;
	}

  static getInstance(): UxpertiseModel {
    if (!UxpertiseModel._instance) {
      UxpertiseModel._instance = new UxpertiseModel();
    }
    return UxpertiseModel._instance;
  }

  async getUxpertises(): Promise<UxpertiseDto[]> {
    let uxpertises: UxpertiseDto[];
    await axios.get<UxpertiseDto[]>(this.route).then(
      async response => {
        const { status, data } = response;
        if (status === Success) {
          uxpertises = data
        } 
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return uxpertises;
  }

  async insertUxpertise(submitData: UxpertiseDto): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<UxpertiseDto, CreateResponse>(this.route, submitData).then(
      async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = `${submitData.name} activé.`;
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async deleteUxpertiseProfile(id: number, name: string): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = `${name} désactivé.`;
        }
      },
      async error => {
        this.error = error;
        this.setConflit(this.error);
        await this.handleError(this.error);
      },
    );
    return result;
  }
}
