import { Button, ButtonProps, SvgIcon } from "@mui/material"
import { useState } from "react";
import {
    Copy as CopyIcon,
    CheckCircle as ConfirmIcon,
    XSquare as CancelIcon,
    CheckSquare as CheckIcon
} from 'react-feather';

interface ICaijButtonCopyProps extends ButtonProps {
    value: string
}

const CaijButtonCopy = ({ value, ...buttonProps }: ICaijButtonCopyProps) => {
    const [copySuccess, setCopySuccess] = useState<string>("");
    const copyToClipBoard = async (value: string) => {
        if (value) {
            try {
                await navigator.clipboard.writeText(value);
                setCopySuccess('Copié');
            } catch (err) {
                setCopySuccess('Copié avec sans succès');
            }
        }
    };

    return (
        <>
            <Button
                variant="outlined"
                type="button"
                onClick={async () => await copyToClipBoard(value)}
                {...buttonProps}
                startIcon={(
                    <SvgIcon fontSize="small">
                        <CopyIcon />
                    </SvgIcon>
                )}>Copier</Button>
            {copySuccess === 'Copié' && (<span style={{ color: '#31c110', margin: '0.5em' }}>{copySuccess}</span>)}
        </>

    )
}

export default CaijButtonCopy;