export enum AccessPermissions {
   Public = 'PUBLIC',
   Authentication = 'AUTHENTICATION',
   Private = 'PRIVATE'
};

export enum AccessType {
   PUBLIC = 'Public',
   AUTHENTICATION = 'Authentication requise',
   PRIVATE = 'Privé'
};

export enum SbGroup {
   B2B = 'Abonnement corporatif',
   B2C = 'Abonnement individuel'
};

export enum SelectedStatus {
   Active = "1",
   Inactive = "2"
};

export enum Status {
   Active = 1,
   Inactive = 0
};

export enum MemberGuaranteed {
   Yes = 1,
   No = 0
};

export enum SelOpt {
   labelFr = 'labelFr',
   labelEn = 'labelEn',
   fr = 'fr',
   en = 'en',
   coverage = 'coverage',
   jurisdiction = 'jurisdiction',
   tribunalType = 'tribunalType'
};

export enum Mode {
   read = 1,
   add = 2,
   edit = 3,
   delete = 4,
   admin = 5
};

export enum CustomerRole {
   User = 'User',
   Admin = 'Admin',
   Owner =  'Owner'
};

export enum CustomerStatus{
   Inactive = 'Inactif',
   Active = 'Actif',
   ActiveUponApproval = "En attente d'approbation",
   ActiveOnLogin = "En attente d'activation",
   Restreint = "Restreint"
};

export enum ActionType {
   activate = 'Activer le compte',
   restrict = 'Restreindre le compte',
   deactivate = 'Désactiver le compte',
   deleted = 'Supprimer le compte',
   reactivate = 'Lever la restriction du compte',
   sendEmail = "Envoyer le courriel d'activation de compte",
   synchronize = "Synchroniser le compte",
   uxpertise = "Créer un compte formation"
};

export enum AccountType {
   endorsedBy = 'Cautionné',
   regular = 'Régulier'
};

export enum EmployeeRole {
  User = 'USER',
  Admin = 'ADMIN',
};

export enum Language {
   FR = 'FR',
   EN = 'EN'
};

export enum DocLanguage {
   fr = 'Français',
   en = 'English'
};

export enum LanguageProfile {
   FR = 'Français',
   EN = 'English'
};

export enum ErrorType {
   max = 'max',
   required = 'required',
   invalid = 'invalid',
   exist = 'exist',
   numeric = 'numeric',
   extension = 'extension',
   endDateMustBeLater = 'endDateMustBeLater',
   mustBeGreaterThanToday = 'mustBeGreaterThanToday',
   mustBeGreaterThanZero = 'mustBeGreaterThanZero',
   deletedMessage = 'deletedMessage'
};

export enum contactText {
   telephone = 'telephone',
   fax = 'fax',
   tollfree = 'tollfree'
};

export enum ListType {
   LibraryService = 'LibraryService',
   LibraryServiceLibrary = 'LibraryServiceLibrary',
   CustomerProduct = 'CustomerProduct',
   CustomerDatabank = 'CustomerDatabank',
   Product = 'Product',
   Subscription = 'Subscription',
   Databank = 'Databank',
   Collection = 'Collection',
   CustomerCollection = 'CustomerCollection',
   DatabankLibrary = 'DatabankLibrary',
   LawDomain = 'LawDomain',
   LawDomainField = 'LawDomainField',
   Customer = 'Customer',
   Traduction = 'Traduction',
   Tribunal = 'Tribunal',
   Library = 'Library',
   CollectionSubscription = 'CollectionSubscription',
   EmailTemplate = 'EmailTemplate',
   Law = 'Law',
   DoorAccessProfile = 'DoorAccessProfile',
   Publication = 'Publication',
   Document = 'Document',
   MemberSubscription = 'MemberSubscription',
   DatabankGroupAcccess = 'DatabankGroupAcccess',
   CollectionGroupAccess = 'CollectionGroupAccess',
   ProductGroupAccess= 'ProductGroupAccess',
   Uxpertise = 'Uxpertise',
   CoveoSource = 'CoveoSource',
   CoveoTaskHistory = 'CoveoTaskHistory',
   Reperage = 'Reperage',
   DocumentType = 'DocumentType',
   Collections = 'Collections',
};

export enum AccExpRenewMode {
   NotRenewable = 'Aucun renouvellement possible', 
   OnApproval = 'Par le CAIJ ou le fournisseur de service', 
   SelfRenewal = 'Automatique',
   EmailConfirmRenew = 'Par courriel',
};

export enum AccountExpiryMode {
   NeverExpire = 'Aucune expiration dans le temps',
   ExpireOnDate = 'Expiration à une date fixe',
   ExpireAfterDays = 'Expiration après une limite de temps',
   DateSpecifiedAtCreation = 'Expiration personnalisée au moment de la création du compte'
};

export enum Operations {
   Refill = 'Recharger',
   Payment = 'Payer (Réduction du solde)',
   Refund = 'Rembourser (augmentation du solde)',
   Withdrawal = 'Retrait (solde remis à 0)'
};

export enum LawType {
   Codified = 'Lois codifiées',
   Annual = 'Lois annuelles',
   HistoryRecast = 'Lois refondues historiques',
   Rules = 'Règlements'
};

export enum CaseLaw {
   QC_SUPREME = 'Québec et cour suprême du Canada',
   QC_FEDERAL = 'Québec et fédéral'
};

export enum DatabankAccessType {
   Library = 'En bibliothèque',
   Remote = 'À distance'
};

export enum DatabankAccessLinkType {
   None = 0,
   Caij,
   Bibliomagis,
   Direct,
   Clavardage
};

export enum DatabankAppType {
   Unknown = 'Unknown',
   Desktop = 'Desktop',
   Online = 'Online'
};

export enum GroupType {
   Normal = 1,
   Endorsed = 2
};

export enum PendingChangeType
{
   Email = 1,
   Activation = 2,
   CardReplace = 4,
   CardRequest = 5
}

export enum FileType { PDF = 'PDF' };

export enum ExpertListFilter { 
   Subscription = 1,
   Group = 2,
   Status = 3
}

export enum CoveoTaskStatus {
   None = 'None',
   OnHold = 'En attente',
   Processing = 'En traitement',
   Interrupted = 'Interrompu'
}

export enum CoveoTaskTypeTaskType {
   Rebuild = 'Rebuild',
   Refresh = 'Refresh',
   Incremental = 'Incremental'
}

export enum ReperageFolderStatus {
   CLOSED = "Terminé",
   NEW = "Nouvelle demande",
   OPEN = "En cours",
   ON_HOLD = "En attente",
   CANCELLED = "Annulé"
};

export enum ReperageFolderStatusColor {
   CLOSED = "default",
   NEW = "info",
   OPEN = "success",
   ON_HOLD = "warning",
   CANCELLED = "error"
}

export enum LoaderRoute {
   wc = "wc",
   hidden = 'hidden',
   placeholder =  '/app/management/placeholders/'
}

export enum Juridictions {
   ab = 'Alberta',
   bc = 'Colombie-Britannique',
   ca = 'Canada (fédéral)',
   mb = 'Manitoba',
   nb = 'Nouveau-Brunswick',
   nl = 'Terre-Neuve-et-Labrador',
   ns = 'Nouvelle-Écosse',
   nt = 'Territoires du Nord-Ouest',
   nu = 'Nunavut',
   on = 'Ontario',
   pe = 'Île-du-Prince-Édouard',
   qc = 'Québec',
   sk = 'Saskatchewan',
   yk = 'Yukon'
}

export enum DocTypeOptions {
   'Chroniques et blogues' = 'Chroniques et blogues',
   'Congrès et conférences' = 'Congrès et conférences',
   'Périodiques et revues' = 'Périodiques et revues',
   'Livres' = 'Livres',
   'Modèles et formulaires' = 'Modèles et formulaires'
}

export enum DocCollectionsSortOption {
   'ASC' = 'Ascendant',
   'DESC' = 'Descendant'
}

