import {
  Box,
  Grid,
  Typography
} from '@mui/material';

const Header = (): any => (
  <Grid
    container
  >
    <Grid item>
      <Box mt={2}>
        <Typography
          variant="h6"
          sx={{color:"text.primary"}}
        >
          Portail d'administration du CAIJ
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export default Header;
