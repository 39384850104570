import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { MSG_EMPTY_LIST } from 'src/common';
import MyCard from './card/MyCard';

interface EmptyListProps {
  noCard?: boolean;
  sx?:any;
  value?: string;
}

const error = {
  padding: '30px 0 30px 0',
  textAlign: 'center'
}

const EmptyList: FC<EmptyListProps> = ({sx, value, noCard}) => {
  return (
    <>
      {
        noCard ? (
          <Typography variant="subtitle1" sx={{...error,color: 'text.error', ...sx}}>
            {value}
          </Typography>
        ) : (
          <MyCard>
            <Typography variant="subtitle1" sx={{...error, color: 'text.error', ...sx}}>
              {value}
            </Typography>
          </MyCard>
        )
      }
    </>
  );
};

EmptyList.propTypes = {
  noCard: PropTypes.bool,
  value: PropTypes.string
};

EmptyList.defaultProps = {
  noCard: false,
  value: MSG_EMPTY_LIST,
  sx: null
};

export default EmptyList;
