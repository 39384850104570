import React, { useEffect, useReducer, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Box, Container, Tab, Tabs } from '@mui/material';
import {
    useLocation,
    useParams,
    AccessModel,
    _document,
    DocumentModel,
    fetchDocument,
    setHash,
    useNavigate,
    root
} from 'src/common';
import type { Theme } from 'src/common/types';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import SpecialFilesModel from 'src/model/content/SpecialFiles';
import SpecialFilesEditForm from './SpecialFilesEditForm';
import { EditableDocumentModel } from 'src/model/EditableDocument';
import parse from 'html-react-parser';
import ContentEditForm from '../SpecialFilesDetailsView/Forms/ContentEditForm';
import GenericHeader from 'src/components/header/GenericHeader';

const container = {
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
}

interface Tabs {
    value: string, label: string
}

const SpecialFilesEditView: FC = () => {
    const model = SpecialFilesModel.getInstance();
    const documentContentModel = new EditableDocumentModel(model);
    const access = new AccessModel(model.ResourceCode);
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState<string>('details');
    const [state, dispatch] = useReducer(_document, { isLoading: false, document: {} });
    const { documentId } = useParams();
    const navigate = useNavigate();
    const createMode = location.pathname.endsWith("create");

    const editModeTabs: Tabs[] = [
        { value: 'details', label: 'Détails' },
        { value: 'contenu', label: 'Contenu' }
    ];

    const handleTabsChange = (event: ChangeEvent<any>, tabValue: string): void => {
        if (currentTab === "contenu" && !window.confirm("Si vous avez des modifications non enregistrées, elles seront perdues si vous quittez cette page. Êtes-vous sûr de vouloir quitter cette page?")) {
            return;
        }
        setCurrentTab(tabValue);
        setHash(tabValue, navigate);
    };

    const handleBrowserRefresh = () => {
        if(!createMode) {
            const hash = location.hash;
            if (hash) {
                const removedHashSymbol = hash.substring(1, hash.length);
                const hashVal = removedHashSymbol.split('-');
                const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
                const index = editModeTabs.findIndex((tab: Tabs) => tab.value === tabVal);
                if (index !== -1) {
                    setCurrentTab(tabVal);
                } else {
                    setHash(editModeTabs[0].value, navigate);
                }
            } else {
                setHash(editModeTabs[0].value, navigate);
            }
        }
    }

    useEffect(() => {
        handleBrowserRefresh();
    }, [])

    useEffect(() => {
        const initialise = async (): Promise<void> => {
            if (access.canEdit(documentId)) {
                const model = new DocumentModel();
                const pathName = location.pathname;
                if (documentId) {
                    model.PathName = pathName;
                    const document = await model.getDocumentById(documentId);
                    if (!model.error) {
                        dispatch(fetchDocument(document, true));
                    }
                } else {
                    model.PathName = pathName;
                    dispatch(fetchDocument({}, true))
                }
            }
        };
        initialise();
    }, [documentId, location]);


    const { document, isLoading } = state;

    if (!isLoading) return <LoadingScreen />

    model.Title = document?.title ? parse(document.title.trim()).toString() : "";

    return (
        <Page
            sx={root}
            title={model.getEditPageTitle(document.documentId)}
        >
            <Container maxWidth={false}>
                <GenericHeader id={documentId} model={model} headerTitle={documentId ? model.Title : model.BtnAddText} />
                {!createMode ?
                    <>
                        <Box mt={2} sx={container}>
                            <Tabs
                                onChange={handleTabsChange}
                                scrollButtons="auto"
                                value={currentTab}
                                variant="scrollable"
                                textColor="secondary"
                            >
                                {editModeTabs.map((tab) => (
                                    <Tab
                                        key={tab.value}
                                        label={tab.label}
                                        value={tab.value}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <Box>
                            {currentTab === 'details' ?
                                    <SpecialFilesEditForm
                                        model={model}
                                        documentContentModel={documentContentModel}
                                        document={document}
                                        createMode={createMode}
                                    />
                                :
                                <ContentEditForm
                                    model={model}
                                    access={access}
                                    documentId={documentId}
                                />
                            }
                        </Box>
                    </>
                    :
                    <Box mt={3}>
                            <SpecialFilesEditForm
                                model={model}
                                documentContentModel={documentContentModel}
                                document={document}
                                createMode={createMode}
                            />
                    </Box>
                }
            </Container>
        </Page>
    )
}

export default SpecialFilesEditView;