import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { Resource, ResourceForEdit } from 'src/common/types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  Mode,
  CaijButtonSubmit,
  CaijButtonReset,
  btnSubmit
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import ResourceModel from 'src/model/employee/Resource';
import Authorize from 'src/components/Authorize';
import ResourceForm from 'src/components/employee/resource/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: ResourceModel;
  resource: Resource;
  onHandleRefreshResource: (value: Resource) => void;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: Resource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  resource,
  onHandleRefreshResource,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: Resource): Promise<void> => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const submitData = values as ResourceForEdit;
    const resourceId = submitData.id;
    if (submitData && resourceId) {
      const model = new ResourceModel();
      const response = await model.updateResource(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        onHandleRefreshResource(await model.getRefreshResource(resourceId.toString()));
      }
    }
  };

  return (
    <Formik
      initialValues={ResourceModel.getInitialValues(resource)}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        handleChange,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid
                container
                spacing={2}
              >
                  <ResourceForm 
                    model={model}
                    resource={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  resource: PropTypes.object.isRequired,
  onHandleRefreshResource: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
