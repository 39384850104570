import type { DocumentOptionResource, DocumentResource } from 'src/common/types';
import DocumentModel from 'src/model/content/Document';
import { Paged } from 'src/types/pagination';
import * as actionTypes from '../ActionTypes';

export const fetchDocuments = (
  documents: DocumentOptionResource[],
  paged?: Paged,
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean,
  model?:DocumentModel
): {
  type: 'FETCH_DOCUMENTS';
  payload: {
    documents: DocumentOptionResource[];
    paged?: Paged;
    isEmptyList?: boolean;
    isLoading?: boolean;
    isAuthorize?: boolean;
    model?:DocumentModel;
  };
} => ({
  type: actionTypes.FETCH_DOCUMENTS,
  payload: {
    documents,
    paged,
    isEmptyList,
    isLoading,
    isAuthorize,
    model
  },
});

export const fetchDocument = (document: DocumentResource, isLoading?: boolean): {
  type: 'FETCH_DOCUMENT',
  payload: {
    document: DocumentResource,
    isLoading?: boolean
  }
} => ({
  type: actionTypes.FETCH_DOCUMENT,
  payload: {
    document,
    isLoading
  }
});

