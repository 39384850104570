import { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { 
  Menu,
  Box, 
  Grid, 
  Typography,
  IconButton,
  Link,
  MenuItem
} from '@mui/material';
import type { CustomerResource } from 'src/common/types';
import { CaijTooltip, CustomerModel, PAPER_DIGITAL_SPOTTING, ReperageModel } from 'src/common';
import OtherActions from 'src/views/customer/customer/CustomerDetailsView/Details/OtherActions';
import { avatar, container, greyed } from 'src/styles/customer';
import BreadCrum from 'src/components/breadcrumb';
import { popoverClasses } from "@mui/material/Popover";
import CustomerStatusDetail from 'src/components/customer/customer/Details/CustomerStatusDetail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CaijAvatar from 'src/components/customer/customer/Details/CaijAvatar';
import Warning from './warning';

interface HeaderProps {
  model: CustomerModel;
  customer?: CustomerResource;
  logo: string;
  libraryFees: number;
  onHandleRefreshCustomer: () => Promise<void>;
}

const popover = {
  [`& .${popoverClasses.paper}`]: {
    marginLeft: {xs: '-30px', md: '-30px'}
  }
};

const Header: FC<HeaderProps> = ({ 
  model,
  customer,
  logo,
  libraryFees,
  onHandleRefreshCustomer
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const limitFees = 50;
  const path = ReperageModel.getInstance().Path;

  const handleOpen = (event: { currentTarget: any; }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item>
          <BreadCrum title={`${customer.firstname} ${customer.lastname} - ${customer.noCaij}`} /> 
        </Grid>
      </Grid>
      <Box display='flex' sx={{...container, backgroundColor: theme => theme.palette.background.paper}} justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <CaijAvatar customer={customer} logo={logo} sx={{...avatar}} />
          <Box display='flex' marginTop='1em' flexDirection='column'>
            <Typography sx={{...greyed, fontSize: '0.9em' }}>
              <span>{customer.email.toUpperCase()}</span>
            </Typography>
            <Box display='flex' alignItems='flex-start'>
              <Box mr={3}>
                <Typography variant='h4' sx={{color:'text.primary'}}>
                  <span>{`${customer.firstname} ${customer.lastname}`}</span>
                </Typography>
              </Box>
              <Box sx={{display:{xs:'block',sm:'block',md:'flex',lg:'flex'}}}>
                <Box mr={3}>
                  <CustomerStatusDetail customer={customer} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box marginTop='1em' sx={{position: 'relative', display: 'flex', height: '37px'}}>
          <CaijTooltip title="Menu">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleOpen}
              sx={{ml:2}}
            >
              <MoreVertIcon />
            </IconButton>
          </CaijTooltip>
          <Menu
            onClose={handleClose}
            keepMounted
            sx={popover}
            anchorEl={anchorEl}
            open={open}
          >
            <OtherActions 
              model={model} 
              customer={customer} 
              onHandleRefreshCustomer={onHandleRefreshCustomer} 
              setAnchorEl={setAnchorEl}
            />
          </Menu>
        </Box>
      </Box>
      <Box flexDirection='column' sx={{backgroundColor: theme => theme.palette.background.paper}} justifyContent='space-between'>
        { customer.blocked && (
            <>
              <Box sx={{marginBottom: (libraryFees >= limitFees ? 2 : 0)}}>
                <Warning 
                  title="Restriction d'accès" 
                  message="Le compte de l'usager est restreint. Cela se traduit par une révocation de l'accès en bibliothèque et un accès limité sur l'espace CAIJ pour payer les frais au dossier afin de lever la restriction" 
                />
              </Box>
            </>
          )
        }
        { libraryFees >= limitFees && (
            <Warning 
              title="Frais excessifs au dossier" 
              message="Des frais non payés de plus de 50$ sont présents au dossier. L'utilisateur doit payer ses frais pour avoir accès au service du CAIJ" 
            />
          )
        }
      </Box>
    </Box>
  );
};

Header.propTypes = {
  customer: PropTypes.object.isRequired,
  libraryFees: PropTypes.number.isRequired,
  onHandleRefreshCustomer: PropTypes.func.isRequired
};

export default Header;

