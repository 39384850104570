import type {  MessageResource } from 'src/common/types';
import * as actionTypes from '../actions/ActionTypes';

type FetchMessagesAction = {
  type: 'FETCH_MESSAGES';
  payload: {
    messages: MessageResource[];
    isEmptyList?: boolean;
  };
};

type FetchMessageAction = {
  type: 'FETCH_MESSAGE';
  payload: {
    message: MessageResource;
    isLoading?: boolean;
  };
};

type FetchDetailsMessageAction = {
  type: 'FETCH_DETAILS_MESSAGE';
  payload: {
    message: MessageResource;
  };
};

type Action = FetchMessagesAction | FetchMessageAction | FetchDetailsMessageAction;

interface MessagesReturnState {
  messages?: MessageResource[];
  message?: MessageResource;
  isEmptyList?: boolean;
  isLoading?: boolean;
}

interface MessagesState {
  messages: MessageResource[];
  message: MessageResource;
  isEmptyList: boolean;
  isLoading?: boolean;
}

const reducer = (state: MessagesState, action: Action): MessagesReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_MESSAGES: {
      const { messages, isEmptyList } = action.payload;
      return {
        ...state,
        messages,
        isEmptyList,
      };
    }
    case actionTypes.FETCH_MESSAGE: {
      const { message, isLoading } = action.payload;
      return {
        ...state,
        message,
        isLoading
      };
    }
    case actionTypes.FETCH_DETAILS_MESSAGE: {
      const { message } = action.payload;
      return {
        ...state,
        message,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
