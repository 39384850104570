import { TableHead, TableRow, TableCell, TableBody, Box } from "@mui/material"
import { FC } from "react";
import { CaijButton, getEmptyListSearch } from "src/common"
import { CustomerResource } from "src/common/types";
import PropTypes from 'prop-types';
import CaijTable from "src/components/table/CaijTable"

export interface CustomerSearchResultsProps {
    customers: CustomerResource[];
    onSelect: (customer: CustomerResource) => void;
}

const CustomerSearchResults: FC<CustomerSearchResultsProps> = ({ customers, onSelect }) => {

    return (
        <Box mt={2}>
            <CaijTable>
                <TableHead>
                    <TableRow>
                        <TableCell>Nom</TableCell>
                        <TableCell>Prénom</TableCell>
                        {/* <TableCell>Profil</TableCell> */}
                        <TableCell># Carte caij</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customers.length === 0 ? getEmptyListSearch(4) : customers.map(customer =>
                        <TableRow hover tabIndex={-1} key={customer.id}>
                            <TableCell>{customer.lastname}</TableCell>
                            <TableCell>{customer.firstname}</TableCell>
                            {/* <TableCell>{customer.Lastname}</TableCell> */}
                            <TableCell>{customer.card}</TableCell>
                            <TableCell>
                                <CaijButton onHandleClick={() => onSelect(customer)}>Sélectionner</CaijButton>
                            </TableCell>
                        </TableRow>)}
                </TableBody>
            </CaijTable>
        </Box>
    )
}

CustomerSearchResults.propTypes = {
    customers: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired
}

export default CustomerSearchResults;