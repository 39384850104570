import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
  AccessPermissions,
  AccessType
} from 'src/common';
import type {
  DocumentCollectionResource,
  Error,
  DocumentCollectionResourceForCreate,
  DocumentCollectionResourceForEdit,
  CreateResponse
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import { $enum } from 'ts-enum-util';
import printMessage from 'src/views/errors/MessageError';

type TypeResourceEdit = DocumentCollectionResourceForCreate | DocumentCollectionResourceForEdit;

interface TypeSelected { ids: number[], selected: boolean }

export default class DocCollectionModel extends AppModel 
{
  private static _instance: DocCollectionModel;
  readonly Code = new FormModel('code','Code',45);
  readonly NameFr = new FormModel('nameFr','Nom', 150);
  readonly NameEn = new FormModel('nameEn', 'Name',150);
  readonly DescriptionFr = new FormModel('descriptionFr','Description (Français)', 500);
  readonly DescriptionEn = new FormModel('descriptionEn','Description (English)', 500);
  readonly Note = new FormModel('note','Note interne (non public)');
  readonly Active = new FormModel('active','Actif');
  readonly AccessType = new FormModel('access',"Type d'accès");

  constructor(){
    super('/content/collection');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'DOCUMENT_COLLECTION';
    this._headerTitle = 'Liste des classements du contenu';
    this._btnAddText = 'Ajouter un classement du contenu';
    this.Path.PathName = '/contents/collections';

    //Dialog
    this.Dialog.Header = 'Supprimer le classement du contenu';
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = 'le classement du contenu';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Classement du contenu',
      href: this.Path.Home,
      visible: true
    }
  }

  get DocumentType(){
    return new FormModel("documentTypes","Type(s) de contenu");
  }

  getHeadCells(status: string){ 
    return [
      {
        id: this.Code.Name, width: '15%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.NameFr.Name, width: '25%', numeric: false, disablePadding: false, label: this.NameFr.Label
      },
      {
        id: this.DescriptionFr.Name, width: '40%', numeric: false, disablePadding: false, label: this.DescriptionFr.Label
      },
      {
        id: this.AccessType.Name, width: '10%', numeric: false, disablePadding: false, label: this.AccessType.Label
      },
      {
        id: this.Active.Name, width: '5%', numeric: false, disablePadding: false, label: status
      },
      {
        id: '', width: '5%'
      }
    ];
  };

  static getInstance(): DocCollectionModel {
    if (!DocCollectionModel._instance) {
      DocCollectionModel._instance = new DocCollectionModel();
    }
    return DocCollectionModel._instance;
  }

  static getInitialValues(values: DocumentCollectionResource) : DocumentCollectionResource {
    return {
      id: values.id,
      nameFr: values.nameFr || '',
      nameEn: values.nameEn || '',
      code: values.code || '',
      descriptionFr: values.descriptionFr || '',
      descriptionEn: values.descriptionEn || '',
      note: values.note || '',
      active: values.active,
      access: values.access || AccessPermissions.Private,
      subscriptions: values.subscriptions || [],
      documentTypes: values.documentTypes || [],
      group: values.group || []
    }
  }
  
  static getAccessTypeByKey = (key: string) => {
		return $enum(AccessType).getValueOrDefault(key);
	}
  
  async getDocCollections(): Promise<DocumentCollectionResource[]> {
    let docCollections: DocumentCollectionResource[];
    await axios.get<DocumentCollectionResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          docCollections = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return docCollections;
  }

  async getDocCollectionById(id: string): Promise<DocumentCollectionResource> {
    let docCollection: DocumentCollectionResource;
    await axios.get<DocumentCollectionResource>(`${this.route}/${+id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          docCollection = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return docCollection;
  }

  async updateDocCollection(submitData: DocumentCollectionResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<DocumentCollectionResourceForEdit, Error>(`${this.route}/${submitData.id}`,submitData).then(async (response) => {
      result = response;
      result.message = 'Classement du contenu modifié.';
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertDocCollection(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<DocumentCollectionResourceForCreate, CreateResponse>(this.route, submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Created) {
          result = response;
          result.message = 'Classement du contenu créé.'
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async delete(id: number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(async (response) => {
      const { status } = response;
      if (status === Success) {
        printMessage({
          status: status, 
          message: 'Classement du contenu supprimé.'
        });
        if(allowedRedirect)
          this.redirect(this.Path.Home);
        success = true;
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return success;
  }

  async clearAllSearchTokens() : Promise<Error> {
    let result: Error;
    await axios.delete<void, Error>(`${this.route}/search-tokens`).then(
      async response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Les jetons de recherche ont été réinitialisés.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }
  
  setSubmitListData(submitData: TypeResourceEdit, selectedSubscriptions: TypeSelected): void {
    if(submitData.access === AccessPermissions.Private && selectedSubscriptions.selected) {
      submitData.subscriptions = selectedSubscriptions.ids;
    }else if(submitData.access !== AccessPermissions.Private){
      submitData.subscriptions = [];
    }
  }

  verifyAddedAccess(source: DocumentCollectionResource[], dest: TypeSelected, collections: DocumentCollectionResource[]) : DocumentCollectionResource[] {
    let { ids, selected } = dest;
    if(selected){
      if(source.length === ids.length && source.every(({id: srcId}) => ids.includes(srcId))){
        return;
      }else{
        ids = ids.filter((id: number) => source.findIndex(({id: srcId}) => srcId === id) === -1);
        if(ids.length > 0){
          return collections.filter(({id}) => ids.includes(id)); 
        }
      }
    }
  }

  verifyDeletedAccess(source: DocumentCollectionResource[], dest: TypeSelected, collections: DocumentCollectionResource[]) : DocumentCollectionResource[] {
    let { ids, selected } = dest;
    if(selected){
      if(ids && ids.length){
        ids = ids.filter((id: number) => source.findIndex(({id: srcId}) => srcId === id) !== -1);
        if(ids.length > 0){
          const isNotMoved = source.every(({id}) => ids.includes(id));
          if(!isNotMoved){//au moins un element supprimé de la liste
            const itemRemoved = source.filter(({id}) => !ids.includes(id));
            return collections.filter(({id}) => itemRemoved.findIndex((item: DocumentCollectionResource) => item.id === id) !== -1);
          }
        }
      }else if(source.length > 0){
        return source;
      }
    }
  }
  
  validateCode(text: string){
    const accents = {
      "\\s" : "_",
      "[àáâãäå]" : "a",
      "æ" : "ae",
      "ç" : "c",
      "[èéêë]" : "e",
      "[ìíîï]" : "i",
      "ñ" : "n",
      "[òóôõö]" : "o",
      "œ" : "oe",
      "[ùúûü]" : "u",
      "[ýÿ]" : "y",
      "\\W" : ""
    };
    for(let prop in accents){
      text = text.replace(new RegExp(prop, 'g'),accents[prop])
    }
    return text;
  }
}
