import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import { ApiKeyDto } from 'src/common/types';
import {
  useLocation,
  useParams,
  AccessModel,
  _apiKey,
  fetchApiKey,
  ApiKeyModel,
  root
} from 'src/common';
import Page from 'src/components/Page';
import ApiKeyEditForm from './ApiKeyEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

const ApiKeyEditView: FC = () => {
  const model = ApiKeyModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_apiKey, { isLoading: false, apiKey: {} });
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new ApiKeyModel();
        const pathName = location.pathname;
        if (id) {
          model.PathName = pathName;
          const apiKeys = await model.getApiKeys();
          if (!model.error) {
            dispatch(fetchApiKey(apiKeys.find((apiKey: ApiKeyDto) => apiKey.id === id), true));
          }
        } else{
          model.PathName = pathName;
          dispatch(fetchApiKey({}, true));
        }
      }
    };
    (async () => {
      await initialise();
    })();
  }, [id, location]);

  const { apiKey, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = apiKey.name;

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={3}>
          <ApiKeyEditForm model={model} apiKey={apiKey} />
        </Box>
      </Container>
    </Page>
  );
};

export default ApiKeyEditView;
