import React, { useEffect, useState } from 'react';
import {  Link, Typography } from '@mui/material';
import { ListInfos } from 'src/common';

interface CaijSelectedAllLinkProps<U, T, R> {
  property?: string;
  selectedItems: U[];
  items: T[];
  handleSelectedItems: (items: R[]) => void;
}

export default function CaijSelectedAllLink<U, T, R>(props: CaijSelectedAllLinkProps<U,T, R>){
  const list = ListInfos();
  const [selectedAll, setSelectedAll] = useState<boolean>(list.compareSelectedLists(props.selectedItems, props.items));
  
  useEffect(() => setSelectedAll(list.compareSelectedLists(props.selectedItems, props.items)),[props.selectedItems]);

  return (
    <Typography variant='body1' key='selectedAll' sx={{ml:2}}>
      <Link href='#' underline='none' onClick={() => {
        if(!props.property)
          list.selectAll(selectedAll, props.items, (value: R[]) => props.handleSelectedItems(value));
        else
          list.selectAll(selectedAll, props.items, (value: R[]) => props.handleSelectedItems(value), props.property);
      }}>Tout {selectedAll ? 'sélectionner' : 'désélectionner' }</Link>
    </Typography>
  );
}