import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  CaijButtonReset,
  CaijButtonSubmit,
  Authorize,
  LibraryServiceModel,
  Mode,
  btnSubmit
} from 'src/common';
import type { LibraryServiceResource, LibraryServiceResourceForEdit } from 'src/common/types';
import printMessage from 'src/views/errors/MessageError';
import LibraryServiceForm from 'src/components/library/libraryService/Forms';
import MyCard from 'src/components/card/MyCard';
import validateSchema from '../../Schema';

export interface DetailsEditFormProps {
  model: LibraryServiceModel;
  libraryService: LibraryServiceResource;
  onHandleRefreshLibaryService: () => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: LibraryServiceResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  libraryService,
  onHandleRefreshLibaryService,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: LibraryServiceResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new LibraryServiceModel();
    const submitData = values as LibraryServiceResourceForEdit;
    const libraryServiceId = submitData.id;
    if (submitData && libraryServiceId) {
      const response = await model.updateLibraryService(submitData);
      if (!model.error) {
        const {status, message} = response;
        printMessage({status,message});
        await onHandleRefreshLibaryService();
      }
    }
  };

  return (
    <Formik
      initialValues={LibraryServiceModel.getInitialValues(libraryService)}
      validationSchema={validateSchema(model)}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        errors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <LibraryServiceForm 
                  model={model}
                  libraryService={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Box mt={2}>
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={(l) => setIsAuth(l)}
                >
                  <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                </Authorize>
                <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              </Box>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  libraryService: PropTypes.object.isRequired,
  onHandleRefreshLibaryService: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
