import { useMemo, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Grid } from '@mui/material';
import {
  Formik,
  handleChange,
  handleChangeCheckBox,
  ProductModel,
  CaijButtonSubmit,
  CaijButtonReset,
  Authorize,
  ANIMATION_TIMING,
  Mode,
  ListType,
  AccessPermissions,
  btnSubmit,
  AccessModel,
  SubscriptionModel
} from 'src/common';
import type { 
  ProductResource, 
  ProductResourceForCreate, 
  ProductResourceForEdit,
  Error,
  CreateResponse,
  SubscriptionProductResource
} from 'src/common/types';
import ProductForm from 'src/components/product/Forms';
import { CSSTransition } from 'react-transition-group';
import AccessTypeForm from 'src/components/access/AccessTypeForm';
import SubscriptionList from 'src/components/requiredParameter';
import validateSchema from '../Schema';

export interface ProductEditFormProps {
  model: ProductModel;
  product: ProductResource;
  onSubmit?: (values: ProductResource) => void;
}

async function redirect(model: ProductModel, response: Error | CreateResponse) : Promise<void> {
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

export interface Subscription {
  subscriptions: SubscriptionProductResource[];
  isLoading: boolean;
};

const ProductEditForm: FC<ProductEditFormProps> = ({ model, product, onSubmit }) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<{ ids: number[]; selected: boolean }>({
    ids: [],
    selected: false,
  });
  const [selectedParameters, setSelectedParameters] = useState<Map<number, string>>();
  const accessSubscription = new AccessModel(SubscriptionModel.getInstance().ResourceCode);

  const handleSubmit = async (values: ProductResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new ProductModel();
    let submitData = values as ProductResourceForEdit;
    if (submitData && submitData.id) {
      model.SelectedSubscriptionParameters = selectedParameters;
      model.setSubmitListData(submitData, selectedSubscriptions);
      const response = await model.updateProduct(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    } else {
      const newValues = {...values};
      const submitData : ProductResourceForCreate = {
        ...values,
        nameFr: newValues.nameFr,
        nameEn: newValues.nameEn,
        code: newValues.code.toUpperCase()
      }
      delete submitData.id;
      model.SelectedSubscriptionParameters = selectedParameters;
      model.setSubmitListData(submitData, selectedSubscriptions);
      const response = await model.insertProduct(submitData);
      if (!model.error) {
        await redirect(model, response);
      }
    }
  };
  
  return (
    <Formik
      initialValues={ProductModel.getInitialValues(product)}
      validationSchema={validateSchema(model)}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Card sx={{ background: theme => theme.palette.background.paper }}>
            <CardContent>
              <Grid container spacing={2}>
                <ProductForm
                  model={model}
                  product={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  onHandleChange={handleChange}
                  onHandleChangeCheckBox={handleChangeCheckBox}
                  setFieldValue={setFieldValue}
                />
                <Grid item md={6} xs={12}>
                  <Box>
                    <AccessTypeForm
                      data={values}
                      errors={errors}
                      touched={touched}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    <Box mt={2}>
                      <Authorize
                        resourceCode={model.ResourceCode}
                        mode={product.id ? Mode.edit : Mode.add}
                        onIsAuth={l => setIsAuth(l)}
                      >
                        <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                      </Authorize>
                      <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
                    </Box>
                  </Box>
                </Grid>
                { accessSubscription.Edition && (
                    <CSSTransition
                      in={values.access === AccessPermissions.Private}
                      timeout={ANIMATION_TIMING}
                      classNames='fade'
                      unmountOnExit
                      mountOnEnter
                    >
                      <Grid item md={6} xs={12}>
                        { useMemo(() => 
                          <SubscriptionList
                            listType={ListType.Subscription}
                            lists={values?.subscriptions}
                            subscriptionGroupProducts={values?.subscriptionGroupProducts}
                            isParametersRequired={values.parametersRequired || false}
                            onHandleSelectedGroupItems={(l) => setFieldValue('subscriptionGroupProducts', l)}
                            onHandleItemsSelected={l =>
                              setSelectedSubscriptions({
                                ...selectedSubscriptions,
                                ids: l,
                                selected: true,
                              })}
                            onHandleRequireParmeters={l => setSelectedParameters(l)}
                          />,[values.parametersRequired, values.subscriptions, values.subscriptionGroupProducts])
                        }
                      </Grid>
                    </CSSTransition>
                  )
                }
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ProductEditForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

ProductEditForm.defaultProps = {
  onSubmit: null
};

export default ProductEditForm;
