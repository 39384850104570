import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { PartnerResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
  Link
} from '@mui/material';
import { PartnersModel, link, tableRow } from 'src/common';
import CaijImage from 'src/components/image/CaijImage';
import { tableCell } from 'src/styles/contentPublishers';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow, CaijTableRowImage } from 'src/components/table/CaijTableRow';

export interface PartnersFrInfoProps {
  model: PartnersModel;
  partner: PartnerResource;
}

const PartnersFrInfo: FC<PartnersFrInfoProps> = ({
  model,
  partner
}) => {
  const { nameFr, logoUrlFr, homeUrlFr, contentUrlFr } = partner;
  const { NameFr, HomeUrlFr, ContentUrlFr, LogoUrlFr} = model;
  return (
    <MyCard>
      <CardHeaderDetail title="Partenaire" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableCell={tableCell} label={NameFr.Label}>
            {nameFr}
          </CaijTableRow>
          <CaijTableRowImage sxTableCell={tableCell} label={LogoUrlFr.Label} logoUrl={logoUrlFr} />
          <CaijTableRow sxTableCell={tableCell} label={HomeUrlFr.Label}>
            { homeUrlFr && (
                <Link
                  underline="none"
                  href={homeUrlFr}
                  sx={{ ...link, color: 'text.secondary' }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {homeUrlFr}
                </Link>
              )
            }
          </CaijTableRow>
          <CaijTableRow sxTableRow={tableRow} sxTableCell={tableCell} label={ContentUrlFr.Label}>
            { contentUrlFr && (
                  <Link
                    underline="none"
                    href={contentUrlFr}
                    sx={{ ...link, color: 'text.secondary' }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {contentUrlFr}
                  </Link>
                )
              }
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

PartnersFrInfo.propTypes = {
  partner: PropTypes.object.isRequired
};

export default PartnersFrInfo;
