import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  fetchTribunal,
  TribunalModel, 
  useLocation,
  useParams,
  _docCollection,
  _subscription,
  _tribunal,
  root
} from 'src/common';
import type { TribunalResource } from 'src/common/types';
import Page from 'src/components/Page';
import { TribunalProvider } from 'src/contexts/TribunalContext';
import Details from './Details';
import DetailsEditForm from './Forms/DetailsEditForm';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';

interface TabsTribunal {
  value: string, label: string
};

const tabs: TabsTribunal[] = [
  { value: 'details', label: 'Détails' }
];

const TribunalDetailsView: FC = () => {
  const model = TribunalModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_tribunal, { tribunal: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();
  
  const getTribunalById = useCallback(async () : Promise<void> => {
    if (!id || isNaN(+id)) {
      await model.redirect(model.Path.Home);
    }else if(access.canRead()){
      const model = new TribunalModel();
      model.PathName = location.pathname;
      const tribunal = await model.getTribunalById(id);
      if (!model.error) {
        dispatch(fetchTribunal(tribunal));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      await getTribunalById();
    })();
  }, []);

  const { tribunal } = state;

  if (Object.keys(tribunal).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = `${tribunal.labelFr} - ${tribunal.codeFr}`;

  const setEditModeTribunal = (isEdit: boolean, value?: string) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean) => {
    setEditModeTribunal(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<HTMLInputElement>, value: string): void => {
    setCurrentTab(value);
    setEditModeTribunal(false, value);
  };

  const handleRefreshTribunal = async (tribunal: TribunalResource, value?: string) => {
    dispatch(fetchTribunal(tribunal));
    setEditModeTribunal(false, value);
  };

  const editTribunal = () => {
    setEditModeTribunal(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <TribunalProvider>
              <DetailsEditForm
                model={model}
                tribunal={tribunal}
                onHandleRefreshTribunal={handleRefreshTribunal}
                onSwitchMode={(l) => switchMode(l)}
              />
            </TribunalProvider>
          );
        }
        return (
          <Details model={model} tribunal={tribunal} />
        );
      default:
        return '';
    }
  };

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={tribunal.labelFr} 
          titleBadge={{label: tribunal.codeFr}} 
          editMode={editMode.details}
          actions={['editDetail','delete']}
          onEditDetail={() => editTribunal()}
        />
        <Box mt={2}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default TribunalDetailsView;
