import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import type { ChangeEvent, FC } from 'react';
import {
  CaijCheckbox, 
  CaijInput, 
  CaijInputEmail,
  CaijSelect, 
  EmployeeModel, 
  EmployeeRoleLabel, 
  FormikErrors, 
  FormikTouched, 
  labelConfig
} from 'src/common';
import type { EmployeesResource } from 'src/common/types';

interface EmployeeFormProps {
  model: EmployeeModel;
  employee: EmployeesResource;
  errors: FormikErrors<EmployeesResource>;
  touched: FormikTouched<EmployeesResource>;
  handleBlur: (e: any) => void;
  handleChange: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  handleChangeCheckBox: (event: ChangeEvent<any>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const EmployeeForm: FC<EmployeeFormProps> = ({
  model,
  employee,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleChangeCheckBox,
  setFieldValue
}) => {
  const {
    role, firstname, lastname, email, card, title, active
  } = employee;
  const { Firstname, Lastname, Email, Card, Titre } = model;
  return (
    <>
      <Grid
        item
        md={6}
        xs={12}
      >
        <CaijInput
          name={Firstname.Name}
          id={Firstname.Name}
          required
          error={Boolean(touched.firstname && errors.firstname)}
          helperText={touched.firstname && errors.firstname}
          label={Firstname.Label}
          value={firstname}
          inputAttr={{maxLength: Firstname.MaxLength, 'data-testid': Firstname.Name }}
          InputLabelProps={{ shrink: true }}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <CaijInput
          name={Lastname.Name}
          id={Lastname.Name}
          required
          error={Boolean(touched.lastname && errors.lastname)}
          helperText={touched.lastname && errors.lastname}
          label={Lastname.Label}
          value={lastname}
          inputAttr={{maxLength: Lastname.MaxLength, 'data-testid': Lastname.Name}}
          InputLabelProps={{ shrink: true, required: true }}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <CaijInput
          name={Titre.Name}
          id={Titre.Name}
          label={Titre.Label}
          error={Boolean(touched.title && errors.title)}
          helperText={touched.title && errors.title}
          value={title}
          inputAttr={{maxLength: Titre.MaxLength, 'data-testid': Titre.Name}}
          InputLabelProps={{ shrink: true }}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
          margin="none"
        />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <CaijSelect
          name="role"
          id="role"
          required
          label={labelConfig.role}
          error={Boolean(touched.role && errors.role)}
          helperText={touched.role && errors.role}
          value={role}
          labelId="role-native-required"
          inputProps={{ 'aria-label': 'role', 'data-testid': 'role' }}
          onHandleBlur={handleBlur}
          onHandleChangeSelect={handleChange}
          setFieldValue={setFieldValue}
          sx={{margin:0}}
        >
          <option value='' disabled>Sélectionner un rôle</option>
          {
            Object.keys(EmployeeRoleLabel).map((key) => (
              <option value={key} key={key}>
                {EmployeeRoleLabel[key]}
              </option>
            ))
          }
        </CaijSelect>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <CaijInputEmail
          name={Email.Name}
          id={Email.Name}
          required
          value={email}
          error={Boolean(touched.email && errors.email)}
          label={Email.Label}
          helperText={touched.email && errors.email}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: Email.MaxLength, 'data-testid': Email.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={handleChange}
          setFieldValue={setFieldValue}
          margin="none"
        />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
         <CaijInput
            name={Card.Name}
            id={Card.Name}
            label={Card.Label}
            error={Boolean(touched.card && errors.card)}
            helperText={touched.card && errors.card}
            value={card}
            inputAttr={{maxLength: Card.MaxLength, 'data-testid': Card.Name}}
            InputLabelProps={{ shrink: true }}
            onHandleBlur={handleBlur}
            onHandleChange={handleChange}
            setFieldValue={setFieldValue}
            margin="none"
          />
      </Grid>
      <Grid
        item
        md={12}
        xs={12}
      >
        <CaijCheckbox
          name="active"
          checked={active}
          value={active}
          label={labelConfig.active}
          onHandleChangeCheckBox={handleChangeCheckBox}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </>
  );
};

EmployeeForm.propTypes = {
  employee: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChangeCheckBox: PropTypes.func.isRequired
};

export default EmployeeForm;
