import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { CustomerPendingChangeDto, CustomerResource } from 'src/common/types';
import { Button, SvgIcon} from '@mui/material';
import { 
  AccessModel,
  CardRequestPendingModel,
  CustomerModel, 
  PendingChangeType, 
  useNavigate
} from 'src/common';
import { Trash2, CreditCard as CardIcon } from 'react-feather';
import { assignAction, deleteAction } from 'src/styles/customer';
import printMessage from 'src/views/errors/MessageError';

export interface AccessCardRequestProps {
  customer: CustomerResource, 
  modifyCaijCard: (value: string) => void
}

interface IAccessCardRequest {
  accessCarteCaijModel: AccessModel,
  accessCustomerModel: AccessModel,
  customer: CustomerResource, 
  modifyCaijCard: (value: string) => void,
  submitCardRequest: (customer:CustomerResource) => Promise<void>
}

const checkCardReplacePendingChange = (customerPendingChanges: CustomerPendingChangeDto[]) => customerPendingChanges?.findIndex(({changeType}) => changeType === CustomerModel.getPendingChangeTypeByVal(PendingChangeType.CardRequest) || changeType === CustomerModel.getPendingChangeTypeByVal(PendingChangeType.CardReplace)) != -1;

const renderCardReplace = (accessCardRequest: IAccessCardRequest) : JSX.Element => {
  const { accessCustomerModel, accessCarteCaijModel, customer, modifyCaijCard } = accessCardRequest;
  if(accessCustomerModel.UserAdmin || (accessCustomerModel.UserUser && accessCarteCaijModel.Edition || accessCarteCaijModel.Gestion)){
    return (
      <Button
        color='secondary'
        sx={assignAction}
        startIcon={
          <SvgIcon fontSize='small'>
            <CardIcon />
          </SvgIcon>
        }
        onClick={customer.card ? () => modifyCaijCard('replace') : () => modifyCaijCard('assign')}
      >
        {customer.card ? 'Remplacer la carte' : 'Assigner une carte'}
      </Button>
    )
  }
}

const renderCardRequested = (accessCardRequest: IAccessCardRequest) : JSX.Element => {
  const { customer, submitCardRequest } = accessCardRequest;
  if(!checkCardReplacePendingChange(customer.customerPendingChanges) && customer.card === null){
    return (
      <Button
        color='success'
        sx={assignAction}
        startIcon={
          <SvgIcon fontSize='small'>
            <CardIcon />
          </SvgIcon>
        }
        onClick={() => submitCardRequest(customer)}
      >
        Demander une carte
      </Button>
    )
  }
}

const renderCardDeleted = (accessCardRequest: IAccessCardRequest) : JSX.Element => {
  const { accessCustomerModel, accessCarteCaijModel, modifyCaijCard } = accessCardRequest;
  if(accessCustomerModel.UserAdmin || (accessCustomerModel.UserUser && accessCarteCaijModel.Gestion)){
    return (
      <Button
        startIcon={
          <SvgIcon fontSize='small'>
            <Trash2 />
          </SvgIcon>
        }
        sx={deleteAction}
        onClick={() => modifyCaijCard('delete')}
      >
        Supprimer la carte
      </Button>
    )
  }
}

const AccessCardRequest: FC<AccessCardRequestProps> = ({
  customer,
  modifyCaijCard
}) => {
  const navigate = useNavigate();
  const accessCarteCaij = AccessModel.accessCarteCaij(customer);
  const model = CustomerModel.getInstance();
  const cardRequestPendingModel = CardRequestPendingModel.getInstance();

  const submitCardRequest = async (customer: CustomerResource) => {
    const model = new CustomerModel();
    const response = await model.requestCard(customer.id);
    if(!model.error){
      printMessage({
        status: response.status,
        message: response.message
      });
      navigate(cardRequestPendingModel.Path.Home);
    }
  }
  
  const cardRequest: IAccessCardRequest = {
    accessCarteCaijModel: new AccessModel(cardRequestPendingModel.ResourceCode),
    accessCustomerModel: new AccessModel(model.ResourceCode),
    customer,
    modifyCaijCard,
    submitCardRequest
  };

  return (
    <>
      { accessCarteCaij && (
         <>
          { AccessModel.accessLibrary(customer) && renderCardReplace(cardRequest) }
          { renderCardRequested(cardRequest) }
          { customer.card !== null && renderCardDeleted(cardRequest) }
         </>
        )
      }
    </>
  );
};

AccessCardRequest.propTypes = {
  customer:PropTypes.object.isRequired,
  modifyCaijCard: PropTypes.func.isRequired
};

export default AccessCardRequest;
