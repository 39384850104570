import type { MessagePlaceholderResource } from 'src/common/types';
import * as actionTypes from './ActionTypes';

export const fetchMessagePlaceholders = (
  placeholders: MessagePlaceholderResource[],
  isLoading: boolean,
  isEmptyList: boolean,
): {
  type: 'FETCH_MESSAGE_PLACEHOLDERS';
  payload: {
    placeholders: MessagePlaceholderResource[];
    isLoading?: boolean;
    isEmptyList?: boolean;
  };
} => ({
  type: actionTypes.FETCH_MESSAGE_PLACEHOLDERS,
  payload: {
    placeholders,
    isLoading,
    isEmptyList
  },
});

export const fetchMessagePlaceholder = (
  placeholder: MessagePlaceholderResource,
): {
  type: 'FETCH_MESSAGE_PLACEHOLDER';
  payload: {
    placeholder: MessagePlaceholderResource;
  };
} => ({
  type: actionTypes.FETCH_MESSAGE_PLACEHOLDER,
  payload: {
    placeholder,
  },
});

export const fetchMessagePlaceholderDetails = (
  placeholder: MessagePlaceholderResource,
): {
  type: 'FETCH_DETAILS_MESSAGE_PLACEHOLDER';
  payload: {
    placeholder: MessagePlaceholderResource;
  };
} => ({
  type: actionTypes.FETCH_DETAILS_MESSAGE_PLACEHOLDER,
  payload: {
    placeholder,
  },
});
