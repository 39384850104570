import {
  Box,
  CardContent,
  Grid
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC } from 'react';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  AccessPermissions,
  ANIMATION_TIMING, 
  Authorize, 
  CaijButtonReset, 
  CaijButtonSubmit,
  Formik,
  handleChange, 
  labelConfig, 
  Mode,
  ProductModel,
  handleChangeCheckBox,
  CaijCheckbox,
  ListType,
  btnSubmit
} from 'src/common';
import type { 
  ProductResource, 
  ProductResourceForEdit
} from 'src/common/types';
import AccessTypeForm from 'src/components/access/AccessTypeForm';
import printMessage from 'src/views/errors/MessageError';
import RequireParameterList from 'src/components/requiredParameter';
import MyCard from 'src/components/card/MyCard';

interface SubscriptionFormProps {
  model: ProductModel;
  product: ProductResource;
  onSwitchMode: (isCancel: boolean) => void;
  onHandleRefreshProduct: (value: ProductResource) => Promise<void>;
  onSubmit?: (values: ProductResource) => void;
}

const SubscriptionForm: FC<SubscriptionFormProps> = ({
  model,
  product,
  onSwitchMode,
  onHandleRefreshProduct,
  onSubmit
 }) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<{ ids: number[]; selected: boolean }>({
    ids: [],
    selected: false,
  });
  const [selectedParameters, setSelectedParameters] = useState<Map<number, string>>();
  
  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };
  
  const handleSubmit = async (values: ProductResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new ProductModel();
    const submitData = values as ProductResourceForEdit;
    const productId = submitData.id;
    if (submitData && productId) {
      model.SelectedSubscriptionParameters = selectedParameters;
      model.setSubmitListData(submitData, selectedSubscriptions);
      const response = await model.updateProduct(submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await onHandleRefreshProduct(await model.getRefreshProduct(productId.toString()));
      } 
    }
  };

  return (
    <Formik
      initialValues={ProductModel.getInitialValues(product)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleSubmit, setFieldValue, values, isSubmitting, touched }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <Box>
                    <CaijCheckbox
                      name="parametersRequired"
                      label={labelConfig.requiredParameter}
                      checked={values.parametersRequired}
                      value={values.parametersRequired}
                      onHandleChangeCheckBox={handleChangeCheckBox}
                      setFieldValue={setFieldValue}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box>
                    <AccessTypeForm
                      data={values}
                      errors={errors}
                      touched={touched}
                      onHandleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                    <Box mt={2}>
                      <Authorize resourceCode={model.ResourceCode} mode={Mode.edit} onIsAuth={l => setIsAuth(l)}>
                        <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
                      </Authorize>
                      <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
                    </Box>
                  </Box>
                </Grid>
                <CSSTransition
                  in={values.access === AccessPermissions.Private}
                  timeout={ANIMATION_TIMING}
                  classNames='fade'
                  unmountOnExit
                  mountOnEnter
                >
                  <Grid item md={6} xs={12}>
                    <RequireParameterList
                      listType={ListType.Subscription}
                      lists={values?.subscriptions}
                      isParametersRequired={values.parametersRequired || false}
                      onHandleItemsSelected={l =>
                        setSelectedSubscriptions({
                          ...selectedSubscriptions,
                          ids: l,
                          selected: true,
                        })}
                      onHandleRequireParmeters={l => setSelectedParameters(l)}
                    />
                  </Grid>
                </CSSTransition>
              </Grid>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

SubscriptionForm.propTypes = {
  product: PropTypes.object.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onHandleRefreshProduct: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

SubscriptionForm.defaultProps = {
  onSubmit: null
};

export default SubscriptionForm;
