import { EmailTemplateModel } from 'src/common';
import type { EmailTemplateDto } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchEmailTemplatesAction = {
    type: 'FETCH_EMAIL_TEMPLATES';
    payload: {
      emailTemplates: EmailTemplateDto[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
      model?: EmailTemplateModel;
    }
}

type FetchEmailTemplateAction = {
  type: 'FETCH_EMAIL_TEMPLATE';
  payload: {
    emailTemplate: EmailTemplateDto;
    isLoading?: boolean;
  }
};

type DeleteEmailTemplateAction = {
  type: 'DELETE_EMAIL_TEMPLATE';
  payload: {
    id: number;
  }
};

type FetchTagsAction = {
  type: 'FETCH_TAGS',
  payload: {
    tags: Array<string>,
  }
};

type Action = FetchEmailTemplatesAction |
              FetchEmailTemplateAction |
              FetchTagsAction |
              DeleteEmailTemplateAction;

export interface EmailTemplateReturnState {
  model?: EmailTemplateModel;
  emailTemplates?: EmailTemplateDto[];
  emailTemplate?: EmailTemplateDto;
  isEmptyList?: boolean;
  isLoading?: boolean;
  isAuthorize?: boolean;
  tags?: Array<string>;
}

interface EmailTemplateState {
  model: EmailTemplateModel;
  emailTemplates: EmailTemplateDto[];
  emailTemplate: EmailTemplateDto;
  isEmptyList: boolean;
  isLoading: boolean;
  isAuthorize: boolean;
  tags: Array<string>;
}

const reducer = (state: EmailTemplateState, action: Action): EmailTemplateReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_EMAIL_TEMPLATES: {
      const { emailTemplates, isEmptyList, isLoading, isAuthorize, model } = action.payload;
      return {
        ...state,
        emailTemplates,
        isLoading,
        isEmptyList,
        isAuthorize,
        model
      };
    }
    case actionTypes.FETCH_EMAIL_TEMPLATE: {
      const { emailTemplate, isLoading } = action.payload;
      return {
        ...state,
        emailTemplate,
        isLoading
      };
    }
    case actionTypes.DELETE_EMAIL_TEMPLATE: {
      return {
        ...state,
        emailTemplates: [...state.emailTemplates].filter(({id}) => id !== action.payload.id)
      };
    }
    case actionTypes.FETCH_TAGS: {
      const { tags } = action.payload;
      return {
        ...state,
        tags
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer

