import React, { ChangeEvent, FC, useEffect, useState } from "react";
import * as Yup from 'yup';
import { 
  AccessModel, 
  CustomerModel, 
  Formik, 
  FormikHelpers, 
  dialog, 
  dialogTitle 
} from "src/common";
import { AddressDto, CustomerAddressDto, CustomerResource } from "src/common/types";
import printMessage from 'src/views/errors/MessageError';
import { 
  Box, 
  Button, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Link, 
  SvgIcon, 
  Typography 
} from "@mui/material";
import { 
  User as UserIcon, 
  XSquare as CancelIcon, 
  CheckSquare as SaveIcon 
} from 'react-feather';
import CarteCaij from "src/components/customer/customer/Details/CarteCaij";
import CaijCardForm from "src/components/customer/customer/Forms/CaijCardForm";

interface CardRequestPendingEditFormProps {
  customer: CustomerResource; 
  accessCarteCaijModel: AccessModel; 
  handleClose:() => void;
  reload:() => Promise<void>
  customerModel: CustomerModel;
  address?: CustomerAddressDto;
};

const root = {
  display:'flex',
  justifyContent:'space-between',
  alignItems: 'center',
  marginBottom: '10px',
  
}

const profile ={
  display:'flex',
  justifyContent:'space-between',
  alignItems: 'center',
  paddingBottom: '5px'
}

const CardRequestPendingEditForm: FC<CardRequestPendingEditFormProps> = ({
  customer,
  accessCarteCaijModel,
  handleClose,
  reload,
  customerModel,
  address
}) => {
  const handleSubmit = async (customer: CustomerResource,formikHelpers: FormikHelpers<typeof customer>): Promise<void> => {
    formikHelpers.validateForm(customer);
    const model = new CustomerModel();
    if (customer && customer.id) {
      const response = await model.assignCard(customer.id, customer.card);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message,
        });
        handleClose();
        reload();
      }
    }
  }; 
  
  return (
    <Formik
      initialValues={CustomerModel.getInitialValues(customer)}
      validationSchema={Yup.object().shape({
        card: customerModel.MemberCard.required(true)
      })}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
       {({ handleSubmit, handleBlur, handleChange, values, touched, errors, setFieldValue, isSubmitting}) => (
        <form onSubmit={handleSubmit}>
            <DialogTitle sx={dialogTitle}>Assignation de la carte de {customer.fullname} - {customer.subscription.name}</DialogTitle> 
            <DialogContent sx={dialog} dividers>
               <Box sx={root}>
                 <Box />
                 <a style={{textDecoration: 'none', color: '#8a85ff'}} href={`${customerModel.Path.getDetail(customer.id)}#profile`} target='_blank'>
                    <Box sx={profile}>
                      <Typography variant='body2'>Voir le profil</Typography>&nbsp;&nbsp;
                      <SvgIcon fontSize='small'>
                        <UserIcon />
                      </SvgIcon> 
                    </Box>
                  </a>
              </Box>
              { address && (
                  <>
                    <Typography variant="subtitle1">Adresse d'envoi de la carte</Typography>
                    <address>
                      <Box flexDirection="column" mb={2}>
                        <Typography variant='body1' sx={{ fontStyle: "italic" }}>{address.name || "Temporaire"}</Typography>
                        {CustomerModel.getCustomerAddress(address).map((address: string, index: number) => <Typography key={index}>{address}</Typography>)}
                      </Box>
                    </address>
                  </>
                )
              }
              <CarteCaij>
                <CaijCardForm 
                  model={customerModel}
                  customer={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </CarteCaij>
            </DialogContent>
          <DialogActions sx={dialog}>
            <Button 
                type='reset' 
                onClick={() => handleClose()} 
                color='secondary' 
                variant='outlined'
                startIcon={(
                  <SvgIcon fontSize="small">
                    <CancelIcon />
                  </SvgIcon>
                )}
              >
                Fermer
              </Button>
              <Button
                type='submit'
                disabled={(!accessCarteCaijModel.Edition && !accessCarteCaijModel.Gestion) || isSubmitting}
                color='secondary'
                variant='contained'
                startIcon={(
                  <SvgIcon fontSize="small">
                    <SaveIcon />
                  </SvgIcon>
                )}
              >
                  Assigner une carte
              </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  )
}

export default CardRequestPendingEditForm;