import {
  Box,
  InputAdornment,
  SvgIcon
} from '@mui/material';
import * as React from 'react';
import { 
  ChangeEvent, 
  FC, 
  useEffect, 
  useRef, 
  useState 
} from 'react';
import { Search as SearchIcon } from 'react-feather';
import {
  CustomerModel,
  TranslationModel,
  LibraryModel,
  ListType,
  TribunalModel,
  useNavigate,
  EmailTemplateModel,
  LawModel,
  DoorAccessProfileModel,
  LawDomainFieldModel,
  DocumentModel,
  CaijButton,
  CaijInput,
  labelConfig,
  UxpertiseModel,
  FIRST_PAGE,
  SearchEngineModel,
  ReperageModel,
  DocCollectionsModel
} from 'src/common';
import PropTypes from 'prop-types';
import { SearchFilter } from 'src/common/types';
import { NavigateFunction, useLocation } from 'react-router';
import ContentPublicationsModel from 'src/model/content/ContentPublications';
import CaijSearchAction from './CaijSearchAction';

interface SearchProps {
  listType: ListType;
  filter?: SearchFilter;
  placeholder?: string;
  width?: string;
  path?: string;
  sx?: any;
  size?: 'small' | 'medium';
  label?: string;
  colName?: string;
  collections?: JSX.Element[];
  isAuthorize?: boolean;
  searchValue?: string;
  onlyFilterColumn?: boolean;
}

interface SearchParams {
  searchTerm?: string, 
  filter?: SearchFilter, 
  navigate?: NavigateFunction, 
  path?: string,
  colName?: string;
};

const params: SearchParams = {};

type IDocument = {
  Id: string, 
  Title: string, 
  Collection: string
};

const clearTerm = (f: SearchFilter, model: any, pos: number, navigate: NavigateFunction, hash?: string) => {
  model.getConfigParameters(f, pos, '');
  navigate(model.getUrlEncode.apply(model, model.getParams()) + (hash || ""));
};

const setTerm = (searchTerm: string, f: SearchFilter, model: any, pos: number, navigate: NavigateFunction, hash?: string) => {
  model.resetPageValue(f);
  model.getConfigParameters(f, pos, searchTerm);
  navigate(model.getUrlEncode.apply(model, model.getParams()) + (hash || ""));
};

function getSearch(model: any, params: SearchParams, hash?: string): void {
  const { searchTerm, navigate, filter, path } = params;
  if (searchTerm) {
    if (model instanceof CustomerModel) {
      model.resetPageValue(filter);
      model.getConfigParameters(filter, 2, searchTerm);
      navigate(model.getUrlEncodeMembreSubscription.apply(model, model.getParams()));
    } else {
      setTerm(searchTerm, filter, model, 2, navigate, hash);
    }
  } else {
    navigate(path + (hash || ""));
  }
}

function getCustomers(model: CustomerModel, params: SearchParams) : void {
  const { searchTerm, navigate, colName, filter : f } = params;
  if(colName){
    f.query = '';
    switch (colName) {
      case model.ColName.firstName:
        setTerm(searchTerm, f, model, 14, navigate);
        break;
      case model.ColName.lastName:
        setTerm(searchTerm, f, model, 15, navigate);
        break;
      case model.ColName.extIdentifier:
        setTerm(searchTerm, f, model, 16, navigate);
        break;
      case model.ColName.card:
        setTerm(searchTerm, f, model, 17, navigate);
        break;
      case model.ColName.email:
        setTerm(searchTerm, f, model, 18, navigate);
        break;
      case model.ColName.noCaij:
        setTerm(searchTerm, f, model, 20, navigate);
        break;
    }
  }else if(searchTerm){
    f.firstName = f.lastName = f.email = f.noCaij = f.extIdentifier = '';
    setTerm(searchTerm, f, model, 2, navigate);
  }else{
    clearTerm(f, model, 2, navigate);
  }
};

function searchByTerm<M>(value: string, searchFilter: SearchFilter, model: M, navigate: NavigateFunction){
  if(value){
    setTerm(value, searchFilter, model, 2, navigate);
  }else{
    clearTerm(searchFilter, model, 2, navigate);
  }
}

function getTribunals(model: TribunalModel, params: SearchParams) : void {
  searchByTerm<TribunalModel>(params.searchTerm, params.filter, model, params.navigate);
}

function getLaws(model: LawModel, params: SearchParams) : void {
  searchByTerm<LawModel>(params.searchTerm, params.filter, model, params.navigate);
};

function getDocumentCollections(model: DocCollectionsModel, params: SearchParams) : void {
  searchByTerm<DocCollectionsModel>(params.searchTerm, params.filter, model, params.navigate);
};

function getContentPublication(model: ContentPublicationsModel, params: SearchParams) : void {
  searchByTerm<ContentPublicationsModel>(params.searchTerm, params.filter, model, params.navigate);
}

function getCoveoSource(model: SearchEngineModel, params: SearchParams) : void {
  const { searchTerm, navigate, colName, filter : f } = params;
  if(colName){
    f.query = '';
    switch(colName){
      case model.Name.Label:
        setTerm(searchTerm,f,model,23,navigate);
        break;
      case model.Collection.Label:
        setTerm(searchTerm,f,model,4,navigate);
        break;
      case model.CoveoIdentifier.Label:
        setTerm(searchTerm,f,model,24,navigate);
        break;
    }
  }
};

function getCoveoTaskHistory(model: SearchEngineModel, params: SearchParams) : void {
  const { searchTerm, navigate, colName, filter : f } = params;
  if(colName){
    switch(colName){
      case model.Name.Label:
        setTerm(searchTerm,f,model,23,navigate);
        break;
      case model.Collection.Label:
        setTerm(searchTerm,f,model,4,navigate);
        break;
      case model.CoveoIdentifier.Label:
        setTerm(searchTerm,f,model,24,navigate);
        break;
      case model.Status:
        setTerm(searchTerm,f,model,3,navigate);
        break;
      case model.Task:
        setTerm(searchTerm,f,model,25,navigate);
        break;
    }
  }
};

const searchIcon = (
  <InputAdornment position='start'>
    <SvgIcon fontSize='small' color='action'>
      <SearchIcon />
    </SvgIcon>
  </InputAdornment>
);

const Search: FC<SearchProps> = ({
  listType,
  filter,
  placeholder,
  width,
  path,
  size,
  colName,
  collections,
  isAuthorize,
  searchValue,
  onlyFilterColumn
}) => {
  const [count, setCount] = useState<number>(0);
  const [value, setValue] = useState<string | undefined>();
  const [showEnterIcon, setShowEnterIcon] = useState<boolean>(value !== '');
  const [document, setDocument] = useState<IDocument>({Id: '', Title: '', Collection: ''});
  const inputRef = useRef(null);
  const docTitleRef = useRef(null);
  params.path = path;
  params.navigate = useNavigate();
  const location = useLocation();
  const { hash } = location;

  const doSearch = () => {
    switch(listType){
      case ListType.Customer:
        params.colName = colName;
        getCustomers(new CustomerModel(), params);
        break;
      case ListType.Traduction:
        getSearch(new TranslationModel(),params);
        break;
      case ListType.Tribunal:
        getTribunals(new TribunalModel(), params);
        break;
      case ListType.Library:
        getSearch(new LibraryModel(), params);
        break;
      case ListType.EmailTemplate:
        getSearch(new EmailTemplateModel(), params);
        break;
      case ListType.Law:
        getLaws(new LawModel(), params);
        break;
      case ListType.DoorAccessProfile:
        getSearch(new DoorAccessProfileModel(), params);
        break;
      case ListType.LawDomainField:
        getSearch(new LawDomainFieldModel(), params);
        break;
      case ListType.Publication:
        getContentPublication(new ContentPublicationsModel(), params);
        break;
      case ListType.MemberSubscription: 
        getSearch(new CustomerModel(), params);
        break;
      case ListType.Uxpertise: 
        getSearch(new UxpertiseModel(), params);
        break;
      case ListType.CoveoSource:
        params.colName = colName;
        getCoveoSource(new SearchEngineModel, params);
        break;
      case ListType.CoveoTaskHistory:
        params.colName = colName;
        getCoveoTaskHistory(new SearchEngineModel, params);
        break;
      case ListType.Reperage:
        getSearch(new ReperageModel(), params, hash);
        break;
      case ListType.Collections:
        getDocumentCollections(new DocCollectionsModel(), params);
        break;
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => { count ? doSearch() : '' },3000);
    return () => clearTimeout(timeout);
  }, [count])
  
  useEffect(() => { 
    setDocument({...document, 
      Id: filter?.docId,
      Title: filter?.docTitle,
      Collection: filter?.collection
    });
  }, [filter]);

  useEffect(() => {
    if(onlyFilterColumn)
      setValue(searchValue);
    else
      setValue(filter?.query);
  },[filter?.query,searchValue]);

  const setParams = (searchTerm: string, filter: SearchFilter) => {
    params.searchTerm = searchTerm;
    params.filter = filter;
  }

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    setParams(value, filter);
    setCount(count + 1);
    !showEnterIcon ? setShowEnterIcon(true) : '';
  };

  const empty = () => {
    setValue('');
    setParams('', filter);
    showEnterIcon ? setShowEnterIcon(false) : '';
    inputRef.current.focus();
    doSearch();
  }

  const handleQueryDocument = () => {
    const { navigate, path } = params;
    const model = DocumentModel.getInstance();
    if (document.Id || document.Title || document.Collection) { 
      model.setParams(0, FIRST_PAGE);
      model.setParams(5, document.Id);
      model.setParams(6, document.Title);
      model.setParams(4, document.Collection);
      navigate(model.getUrlEncode.apply(model, model.getParams()));
    } else {
      navigate(path, { replace: true });
    }
  }

  return (
    <>
      {listType === ListType.Document ? (
        <Box display="flex" alignItems="center">
          <CaijInput
            name="DocumentID"
            label='Document ID'
            data-testid='DocumentId'
            placeholder="Document ID"
            value={document.Id || ''}
            inputRef={inputRef}
            sx={{ marginRight: 2, width }}
            autoFocus
            inputProps={{
              startAdornment: searchIcon,
              endAdornment: document.Id && <CaijSearchAction 
                                              showDocument 
                                              handleClear={() => {
                                                setDocument({ ...document, Id: '' });
                                                inputRef.current.focus();
                                              }} 
                                            />
            }}
            onHandleChange={(e: ChangeEvent<HTMLInputElement>) => setDocument({ ...document, Id: e.target.value })}
            onKeyDownChange={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') {
                handleQueryDocument();
              }
            }}
          />
          <CaijInput
            name="Title"
            label='Titre'
            data-testid='Title'
            placeholder='Titre'
            value={document.Title || ''}
            inputRef={docTitleRef}
            sx={{ marginRight: 2, width }}
            inputProps={{
              startAdornment: searchIcon,
              endAdornment: document.Title && <CaijSearchAction 
                                                showDocument 
                                                handleClear={() => {
                                                  setDocument({ ...document, Title: '' });
                                                  docTitleRef.current.focus();
                                                }} 
                                              />
            }}
            onHandleChange={(e: ChangeEvent<HTMLInputElement>) => setDocument({ ...document, Title: e.target.value })}
            onKeyDownChange={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter') {
                handleQueryDocument();
              }
            }}
          />
          <CaijInput
            label={labelConfig.collection}
            id='collection'
            name='collection'
            disabled={!isAuthorize}
            onHandleChange={(e: ChangeEvent<HTMLInputElement>) => setDocument({ ...document, Collection: e.target.value })}
            select
            value={document.Collection || ''}
            InputLabelProps={{ shrink: true }}
          >
            <option value=''>Sélectionner une collection</option>
            {collections}
          </CaijInput>
          <CaijButton
            variant="text"
            sx={{ marginLeft: '1em' }}
            onHandleClick={handleQueryDocument}
          >
            Rechercher
          </CaijButton>
        </Box>
      ) : (
        <CaijInput
          name="search"
          id={colName || 'search'}
          label={!colName ? 'Recherche' : ''}
          data-testid='searchFilter'
          placeholder={placeholder}
          value={value || ''}
          inputRef={inputRef}
          sx={{ marginRight: 8, padding: colName ? '5px 10px' : '', width }}
          size={size}
          autoFocus
          inputProps={{
              startAdornment: searchIcon,
              endAdornment: value && <CaijSearchAction 
                                        showEnterIcon={showEnterIcon} 
                                        handleClear={() => empty()} 
                                        handleEnter={() => {
                                          setParams(value, filter);
                                          setShowEnterIcon(false);
                                          doSearch();
                                      }} />
            }}
            onHandleChange={handleQueryChange}
            onKeyDownChange={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if(e.key === 'Enter'){
                setParams(value, filter);
                doSearch();
              }
              e.stopPropagation();
            }}
          />
        )
      }
    </>
  )
}

Search.defaultProps = {
  size: 'medium',
  label: 'Recherche'
};

Search.propTypes = {
  filter: PropTypes.any,
  listType: PropTypes.oneOf([
    ListType.Customer,
    ListType.Traduction,
    ListType.Library,
    ListType.Tribunal,
    ListType.EmailTemplate,
    ListType.Law,
    ListType.DoorAccessProfile,
    ListType.LawDomainField,
    ListType.Publication,
    ListType.Document,
    ListType.MemberSubscription,
    ListType.Uxpertise,
    ListType.CoveoSource,
    ListType.CoveoTaskHistory,
    ListType.Reperage,
    ListType.Collections
  ]).isRequired,
  width: PropTypes.string,
  path: PropTypes.string
};

export default Search;
