import React from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@mui/material';

interface CaijCheckboxProps {
  value?: string | boolean | number;
  id?: string;
  checked?: boolean;
  label?: string;
  name?: string;
  error?: any;
  sx?: any;
  disabled?: boolean;
  disableRipple?: boolean;
  indeterminate?: boolean;
  inputProps?: { 'aria-labelledby'?: string; 'aria-label'?: string; 'data-testid'?: string };
  edge?: false | 'start' | 'end';
  size?: 'medium' | 'small';
  margin?: 'normal' | 'dense' | 'none';
  color?: 'default' | 'primary' | 'secondary';
  required?: boolean;
  onHandleChangeCheckBox?: (event: ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  onHandleBlurCheckBox?: (e:any) => void;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
}

const CaijCheckbox: FC<CaijCheckboxProps> = (props: CaijCheckboxProps) => {
  const {label, sx, disabled, onHandleBlurCheckBox, onHandleChangeCheckBox, setFieldValue, ...rest} = props;
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            {...rest}
            tabIndex={-1}
            onChange={event => onHandleChangeCheckBox(event, setFieldValue)}
            onBlur={onHandleBlurCheckBox}
          />
        }
        label={label}
        sx={sx}
        disabled={disabled}
      />
    </>
  );
};

CaijCheckbox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disableRipple: PropTypes.bool,
  indeterminate: PropTypes.bool,
  sx: PropTypes.any,
  inputProps: PropTypes.object,
  edge: PropTypes.oneOf([false, 'start', 'end']),
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['medium', 'small']),
  onHandleChangeCheckBox: PropTypes.func,
  onHandleBlurCheckBox: PropTypes.func,
  setFieldValue: PropTypes.func,
};

CaijCheckbox.defaultProps = {
  id: '',
  label: '',
  disabled: false,
  size: 'medium',
  required: false,
  edge: false,
  disableRipple: false,
  checked: false,
  onHandleChangeCheckBox: () => {},
  onHandleBlurCheckBox:() => {},
  setFieldValue: () => {},
  indeterminate: false,
  inputProps: { 'aria-labelledby': '', 'aria-label': '' },
};

export default CaijCheckbox;
