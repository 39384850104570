import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { LawDomainFieldResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { 
  LawDomainFieldModel, 
  tableRow
} from 'src/common';
import { tableCell } from 'src/styles/lawDomainField';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { CaijTableRow } from 'src/components/table/CaijTableRow';
import useLawDomainField from 'src/hooks/useLawDomainField';

export interface LawDomainFieldFrInfoProps {
  model: LawDomainFieldModel;
  lawDomainField: LawDomainFieldResource;
}

const LawDomainFieldFrInfo: FC<LawDomainFieldFrInfoProps> = ({
  model,
  lawDomainField
}) => {
  const { lawDomainFields, isLoading } = useLawDomainField();
  return (
    <MyCard>
      <CardHeaderDetail title="Domaine de droit" />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow sxTableCell={tableCell} label={model.NameFr.Label}>
            {lawDomainField.nameFr}
          </CaijTableRow>
          <CaijTableRow sxTableRow={tableRow} sxTableCell={tableCell} label={model.ParentId.Label}>
            {!isLoading && lawDomainFields.find(({id}) => id === lawDomainField.parentId)?.nameFr}
          </CaijTableRow>
        </TableBody>
      </Table>
    </MyCard>
  );
};

LawDomainFieldFrInfo.propTypes = {
  lawDomainField: PropTypes.object.isRequired
};

export default LawDomainFieldFrInfo;
