import type { CoveoTaskHistoryDto } from 'src/common/types';
import * as actionTypes from '../../ActionTypes';
import { Paged } from 'src/types/pagination';

export const fetchCoveoTaskHistories = (coveoTaskHistories: CoveoTaskHistoryDto[], paged?: Paged, isLoading?: boolean, isEmptyList?: boolean): {
  type: 'FETCH_COVEO_TASK_HISTORIES',
  payload: {
    coveoTaskHistories: CoveoTaskHistoryDto[],
    paged?: Paged,
    isLoading?: boolean,
    isEmptyList?: boolean
  }
} => ({
  type: actionTypes.FETCH_COVEO_TASK_HISTORIES,
  payload: {
    coveoTaskHistories,
    paged,
    isLoading,
    isEmptyList
  }
});
