import React, { useState, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import {
  labelConfig,
  EmptyList,
  jurisdictionConfig,
  ListType,
  getEmptyListSearch,
  TribunalModel,
  CaijInput,
  NavigateFunction
} from 'src/common';
import type { 
  SearchFilter, 
  TribunalResource, 
  TribunalTypeResource 
} from 'src/common/types';
import { Paged } from 'src/types/pagination';
import useTribunal from 'src/hooks/useTribunal';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { filters } from 'src/styles/tribunal';
import CaijDialogs from 'src/components/dialog';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import CaijResetFilterButton from 'src/components/buttons/CaijResetFilterButton';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';

export interface ResultsProps {
  tribunals: TribunalResource[];
  paged: Paged;
  isEmptyList: boolean;
  model: TribunalModel;
  onDeleteTribunal: (id: number) => Promise<void>;
  navigate: NavigateFunction;
  location: { search: string };
}

const Results: FC<ResultsProps> = ({
  tribunals,
  paged,
  isEmptyList,
  model,
  onDeleteTribunal,
  navigate,
  location
}) => {
  const path = model.Path;
  const { tribunalTypes } = useTribunal();
  const [selectedRow, setSelectedRow] = useState([]);
  let currentPage = paged.currentPage - 1;
  const query = new URLSearchParams(location?.search).get("query");
  const [filter, setFilter] = useState<SearchFilter>({
    query,
    jurisdiction: '',
    tribunalType: null,
    doSearch: false
  });
  const headCells = model.getHeadCells();

  useEffect(() => {
    const { Filters: { jurisdiction, tribunalType, query, page } } = model;
    const queryVal = model.getQueryParam(query);
    const pageVal = +model.getQueryParam(page);
    const jurisdictionVal = model.getQueryParam(jurisdiction);
    const tribunalTypeVal =  +model.getQueryParam(tribunalType);
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        jurisdiction: jurisdictionVal,
        tribunalType: tribunalTypeVal,
        doSearch: queryVal || jurisdictionVal || tribunalTypeVal ? true : false,
        page: pageVal
      }
    });
  },[model]);

  const handleJuridictionSortChange = (event: ChangeEvent<HTMLInputElement>) : void => {
    model.resetPageValue(filter);
    model.getConfigParameters(filter,10,event.target.value)
    navigate(model.getUrlEncode.apply(model,model.getParams()));
  };

  const handleTribunalTypeSortChange = (event: ChangeEvent<HTMLInputElement>) : void => {
    model.resetPageValue(filter);
    model.getConfigParameters(filter,11,+event.target.value);
    navigate(model.getUrlEncode.apply(model,model.getParams()));
  };

  return (isEmptyList && !filter.doSearch) ? <EmptyList /> : (
    <MyCard>
      <Box minHeight={56} display='flex' alignItems='center' justifyContent='space-between'>
        <Box sx={{margin:'0 0 20px 15px'}}>
          <Search
            listType={ListType.Tribunal}
            placeholder='Recherche le/les tribunal(aux)'
            filter={filter}
            width="400px"
            path={path.Home}
          />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between' sx={{margin:'20px 15px'}}>
          <CaijResetFilterButton path={path.Home} navigate={navigate} />
          <CaijInput
              label='Juridiction'
              name='jurisdiction'
              onHandleChange={handleJuridictionSortChange}
              select
              value={filter.jurisdiction || ''}
              sx={filters}
              InputLabelProps={{ shrink: true }}
            >
              <option value="">Tous</option>
              {
                jurisdictionConfig && Object.keys(jurisdictionConfig).map((key) => (
                  <option value={key} key={key}>
                    {jurisdictionConfig[key]}
                  </option>
                ))
              }
            </CaijInput>
            <CaijInput
              label={labelConfig.type}
              name='tribunalType'
              onHandleChange={handleTribunalTypeSortChange}
              select
              value={filter.tribunalType || ''}
              InputLabelProps={{ shrink: true }}
              sx={filters}
            >
              <option value="">Tous</option>
              {
                tribunalTypes && tribunalTypes.map((tribunalType: TribunalTypeResource) => (
                  <option value={tribunalType.id} key={tribunalType.id}>
                    {tribunalType.labelFr}
                  </option>
                ))
              }
            </CaijInput>
        </Box>
      </Box>
      <CaijTable>
        <TableHead>
          <TableRow>
            { headCells.map(({width, label, align}, index: number) => <TableCell key={index} width={width} align={align}>{label}</TableCell>) }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            tribunals.length === 0 ? getEmptyListSearch(headCells.length) :
            tribunals.map((row: TribunalResource) => {
              return (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  <CaijTableCell>{row.codeFr}</CaijTableCell>
                  <CaijTableCell>{row.codeEn}</CaijTableCell>
                  <CaijTableCell>{row.labelFr}</CaijTableCell>
                  <CaijTableCell>{row.labelEn}</CaijTableCell>
                  <CaijTableCell>{jurisdictionConfig[row.juridiction]}</CaijTableCell>
                  <CaijTableCell>{row.tribunalTypeObj?.labelFr}</CaijTableCell>
                  <CaijTableCell>{row.coverage}</CaijTableCell>
                  <CaijTableCellAction 
                    pageEditUrl={path.getEdit(row.id)}
                    resourceCode={model.ResourceCode}
                    handleToggle={() => TribunalModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                  >
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(row.id) !== -1}
                      onSubmit={async () => {
                        await onDeleteTribunal(row.id);
                        setSelectedRow([]);
                      }}
                      setSelectedRow={setSelectedRow}
                    />
                  </CaijTableCellAction>
                </CaijTableRowClickable>
              );
            })
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={paged} 
        model={model} 
        filter={filter} 
        page={currentPage}
        navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
      />
    </MyCard>
  )
};

Results.propTypes = {
  tribunals: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  onDeleteTribunal: PropTypes.func.isRequired
};

export default Results;
